import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IKBSearchResult } from 'src/app/shared/models/knowledgebase.interface';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';

@Component({
	selector: 'app-knowledge-search-result',
	templateUrl: './knowledge-search-result.component.html',
	styleUrls: ['./knowledge-search-result.component.scss']
})
export class KnowledgeSearchResultComponent implements OnInit {
	@Input('articles') public articles: IKBSearchResult[] = [];
	public searchControl: FormControl = new FormControl('');
	public isSearchPage = false;

	private unsubscriber$ = new Subject<void>();

	constructor(private matDialog: MatDialog, public router: Router, public activatedRoute: ActivatedRoute, public kbService: KnowledgebaseService) {}

	ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public showFrame(index: number): void {
		this.kbService.knowledgebaseShowFrame$.next(true);
		this.kbService.frameIndexValue$.next(index);
	}

	public shortenDescription(description: string): string {
		const strTake = description.length <= 100 ? description : `${description.substring(0, 100)}...`;
		return strTake;
	}
}
