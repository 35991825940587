<form [formGroup]="generatorForm">
	<app-text-input-with-label
		[control]="generatorForm.controls['name']"
		placeholder="Nike"
		[maxLength]="80"
		inputLabel="Product/Brand Name"
	></app-text-input-with-label>
	<app-custom-textarea-with-label
		placeholder="Welcome mail"
		[control]="generatorForm.controls['description']"
		inputLabel="Email Topic"
		[maxLength]="400"
	></app-custom-textarea-with-label>
	<div class="input" *ngIf="isContext">
		<app-custom-textarea-with-label
			class="small"
			placeholder="Customer who has added product to their cart"
			[control]="generatorForm.controls['context']"
			inputLabel="Context {{ optionalText }}"
			[maxLength]="200"
		></app-custom-textarea-with-label>
	</div>
	<div class="d-part input">
		<div class="input-box">
			<div class="label dropdown">
				<label>Output Tone</label>
				<app-tooltip text="You can type your own Tone"></app-tooltip>
			</div>
			<app-custom-drpdown-op1
				[data]="outputTone"
				(selectionChange)="changeToneSource($event)"
				[useCustom]="true"
				[dropdownFormControl]="generatorForm?.get('outputTone')"
				[placeHolder]="'Select/Enter new'"
				[noError]="true"
				[searchEnable]="true"
			></app-custom-drpdown-op1>
			<div class="error-field">
				<span *ngIf="!generatorForm.controls.outputTone.valid">This field is required</span>
			</div>
		</div>
	</div>
	<div class="input">
		<div class="input-lang">
			<div class="label dropdown">
				<label>Output Language (Optional)</label>
			</div>
			<app-custom-drpdown-op1
				[data]="outputLang"
				(selectionChange)="changeLangSource($event)"
				[dropdownFormControl]="generatorForm?.get('outputLang')"
				[useCustom]="false"
				[searchEnable]="true"
				[placeHolder]="'Select Language'"
			></app-custom-drpdown-op1>
		</div>
	</div>
	<div class="d-part input showMobile">
		<div class="input-box">
			<div class="label dropdown">
				<label>No of Outputs</label>
			</div>
			<app-custom-drpdown-op1
				[data]="maxOutputArray"
				[dropdownFormControl]="generatorForm?.get('numOutputs')"
				[useCustom]="false"
				[defaultSelectedValue]="{ name: '3 Outputs', value: 3 }"
				[hideClearButton]="true"
				[placeHolder]="'Output'"
			></app-custom-drpdown-op1>
		</div>
	</div>
</form>
