import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ModalInterface } from 'src/app/user-management/models/DatatableEnum';
import { UserPlanState, getUserPlanDetails } from 'src/app/state/shakespeare-common-state/shakespeare-common-reducer';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-no-credit-modal',
	templateUrl: './no-credit-modal.component.html',
	styleUrls: ['./no-credit-modal.component.scss']
})
export class NoCreditModalComponent implements OnInit {
	@Input() public modelDetail: any;
	private unsubscriber$ = new Subject<void>();
	isPlanPaused: boolean;
	planStatus: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<NoCreditModalComponent>,
		private router: Router,
		private userPlanStore: Store<UserPlanState>,
		private shakespeareService: ShakespeareService
	) {}

	ngOnInit(): void {
		this.userPlanStore.pipe(select(getUserPlanDetails), takeUntil(this.unsubscriber$)).subscribe(res => {
			console.log(res);
			let data = res['UserPlanCommonState'];
			this.isPlanPaused = data?.isPause;
			this.planStatus = data?.status;
		});
	}

	public back(): void {
		if (this.modelDetail.backRoute !== '') {
			this.router.navigate([this.modelDetail.backRoute]);
			this.dialogRef.close();
			return;
		}
		this.dialogRef.close();
	}

	public navigateTo(): void {
		this.dialogRef.close();
		if (this.isPlanPaused) {
			this.router.navigate([this.modelDetail.cta]);
			return;
		}
		if (this.modelDetail.customRoute) {
			window.open(this.modelDetail.cta, '_blank');
			this.dialogRef.close();
			return;
		}
		if (this.modelDetail.cta == '/settings/billing') {
			this.router.navigate([this.modelDetail.cta], { queryParams: { page: 'upgrade' } });
			return;
		}
		this.router.navigate([this.modelDetail.cta]);
	}
}
