import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
	@Input() public value: boolean = false;
	@Input() public disable: boolean = false;
	@Input() public showStatus: boolean = false;
	@Output() public toggle: EventEmitter<boolean> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	public toggleValue(): void {
		this.value = !this.value;
		this.toggle.emit(this.value);
	}
}
