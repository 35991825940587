<div class="warning-animation-section">
	<!--Animation design-->
	<div class="wrapper">
		<shakespeare-spinning-loader></shakespeare-spinning-loader>
	</div>

	<!--Animation content-->
	<div class="text-cogs-data">
		<h1>{{ titleWarningMessage }}</h1>
		<h3>{{ contentWarningMessage }}</h3>
	</div>
</div>
