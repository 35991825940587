import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-shopify-modal',
	templateUrl: './shopify-modal.component.html',
	styleUrls: ['./shopify-modal.component.scss']
})
export class ShopifyModalComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<ShopifyModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {}

	closeDialog(): void {
		this.dialogRef.close();
	}

	confirm(): void {
		this.dialogRef.close({
			confirm: true
		});
	}
}
