import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SetPassword } from '../../_models/identity-models/set-password';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { select, Store } from '@ngrx/store';
import { AuthenticationState } from '../state/authentication.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PasswordComponentEnum } from './set-password.component.enum';
import { LogInModel } from '../_models/login.model';
import { LogIn } from '../state/authentication.action';

@Component({
	selector: 'app-set-password',
	templateUrl: './set-password.component.html',
	styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
	public form: FormGroup;
	public emailFormControl: FormControl;
	public passwordFormControl: FormControl;
	public pageType: PasswordComponentEnum.Password | PasswordComponentEnum.ResetPassword;

	private token: string;
	private emailValue: string;
	private isForgotPasswordUrl: boolean;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading = false;
	public passwordForm: FormGroup;

	public passwordValidationIcon = null;
	public confirmPasswordValidationIcon = null;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private backOfficeService: BackOfficeService,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>,
		private fb: FormBuilder
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);
		});
	}

	public ngOnInit(): void {
		this.emailFormControl = new FormControl(this.emailValue);
		this.passwordFormControl = new FormControl();
		this.isForgotPasswordUrl = this.activatedRoute.snapshot.routeConfig.path?.includes('forgot-password');
		this.pageType = this.isForgotPasswordUrl ? PasswordComponentEnum.ResetPassword : PasswordComponentEnum.Password;
		this.passwordForm = this.fb.group({
			password: new FormControl(''),
			confirmPassword: new FormControl('')
		});
	}

	public updateValidators(): void {
		this.passwordForm.setControl(
			'password',
			new FormControl(this.passwordForm.controls.password.value, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(50),
					Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
				],
				updateOn: 'change'
			})
		);

		this.passwordForm.setControl(
			'confirmPassword',
			new FormControl(this.passwordForm.controls.confirmPassword.value, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(50),
					Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
				],
				updateOn: 'change'
			})
		);
		this.passwordForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			if (this.passwordForm.controls.password.value !== this.passwordForm.controls.confirmPassword.value) {
				this.passwordForm.controls.confirmPassword.setErrors({ NoPasswordMatch: true });
			}
		});
		this.passwordForm.markAllAsTouched();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onSubmit(): void {
		this.updateValidators();
		if (this.passwordFormControl.invalid) {
			return;
		}

		const newPassword: SetPassword = {
			Email: this.emailFormControl.value.trim(),
			Password: this.passwordForm.controls.password.value.trim(),
			ConfirmPassword: this.passwordForm.controls.confirmPassword.value.trim(),
			TwoFactorToken: this.token
		};

		const request$ = this.isForgotPasswordUrl ? this.backOfficeService.forgotPassword(newPassword) : this.backOfficeService.setPassword(newPassword);
		request$.pipe(takeUntil(this.unsubscriber$)).subscribe(
			() => {
				this.toastNotificationService.sendSuccessToast('Password updated successfully.');

				if (this.isForgotPasswordUrl) {
					this.router.navigate(['/authentication']);
				} else {
					//** UPDATING TO ADD REFRESH TOKEN FIX  */
					// const temporaryCredentials = {
					// 	emailAddress: this.emailFormControl.value.trim(),
					// 	password: this.passwordFormControl.value.trim()
					// };
					// localStorage.setItem('details', JSON.stringify(temporaryCredentials));
					// this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
					// 	this.router.navigate(['/authentication'], { queryParams: { [redirectUrl]: true } });
					// });
					// this.authStore
					// .select(getUserDetails)
					// .pipe(
					// 	takeUntil(this.unsubscriber$),
					// 	catchError(error => {
					// 		this.toastNotificationService.sendErrorToast('There was an error verifying your user credentials. Please contact support.');
					// 		this.loading = false;
					// 		return EMPTY;
					// 	})
					// )
					// .subscribe(user => {
					// 	if (user) {
					// 		this.loading = false;
					// 	}
					// });
					const loginPayload: LogInModel = {
						email: this.emailFormControl.value.trim(),
						password: this.passwordForm.controls.password.value.trim()
					};
					this.authStore.dispatch(new LogIn(loginPayload));
				}
			},
			error => {
				this.toastNotificationService.sendErrorToast('There was an error while creating your password.');
			}
		);
	}
}
