<div class="confirmation-dialog-container">
	<div class="text-right cursor-pointer" (click)="decline()">
		<i class="fa fa-close"></i>
	</div>
	<h3 mat-dialog-title class="text-center">{{ title | translate }}</h3>
	<mat-dialog-content>
		<div class="popup-text text-center">{{ message | translate }}</div>
	</mat-dialog-content>
	<div class="button-container" mat-dialog-actions>
		<app-button
			(onClick)="decline()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			[buttonText]="btnCancelText | translate"
		>
			{{ btnCancelText | translate }}
		</app-button>

		<app-button
			(onClick)="accept()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			class="button-confirm"
			[buttonText]="btnOkText | translate"
		>
			{{ btnOkText | translate }}
		</app-button>
	</div>
</div>
