<section
	[class.iphone-css]="iPhoneMobile"
	#mainSection
	class="side-nav closed"
	*ngIf="!noSideMenu"
	id="side-nav-main-new"
	[ngClass]="{ 'expanded-menu': expandMenu, 'full-screen-mode': isFullScreenMode, 'new-expand-menu': v2version }"
>
	<ng-container *ngIf="navShowV3">
		<mat-sidenav #sidenav mode="side" role="navigation" [opened]="!noSideMenu">
			<div
				[ngClass]="{ 'no-menu-margin': !dropdownIsVisible }"
				class="menu-list"
				*ngIf="accountState !== userStateEnum.BillPendingRestrictedAccess && !isAdmin && !currentUrl?.includes('back-office')"
			>
				<mat-list *ngIf="!v2version">
					<mat-list-item
						#headerLogo
						(click)="toggleExpandNavMenu()"
						class="mat-list-top-item-content-expanded d-mobile-none"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
					>
						<img src="assets/icons/nav-menu/side_menu_icon.svg" />
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="tobackOffice()"
						*appCheckPermissions="backOfficePermission"
						[routerLink]="'/back-office'"
						routerLinkActive="selectedItem"
					>
						<mat-icon [ngClass]="{ 'nav-selected': currentUrl?.includes('back-office') }" class="nav-icons">
							admin_panel_settings
						</mat-icon>
						<span [ngClass]="{ 'route-text-selected': currentUrl?.includes('back-office') }" class="route-text">
							BACK OFFICE
						</span>
					</mat-list-item>
				</mat-list>

				<mat-list *ngFor="let mainItem of filteredMenuList; let i = index">
					<!-- !parent menu -->
					<mat-list-item
						*ngIf="!isMobile"
						(click)="menuItemActive(i, mainItem)"
						[ngStyle]="
							(isParentActive(mainItem.route) && !mainItem.childOpen) ||
							(mainItem.childOpen && !expandMenu) ||
							(activateOverviewHeader && mainItem.title == 'Overview')
								? menuItemSelectedEffect1()
								: {}
						"
						[ngStyle]="mainItem.hoverOver && !isParentActive(mainItem.route) ? menuItemSelectedEffect() : {}"
						(mouseleave)="mouseLeaveMenu(i)"
						(mouseenter)="mouseEnterMenu(i)"
						[ngClass]="{
							'mat-list-item-content-expanded': expandMenu,
							'overview-padding': mainItem.name == 'OverView',
							'overview-v2': v2version == true
						}"
						[class.hide-settings]="mainItem.route === '/settings'"
						class="menu-item {{ 'firstActive' + mainItem.tourElement }} {{ mainItem.route === '/settings/index' ? 'showMobile' : '' }} {{
							mainItem.route === '/settings' ? 'showTabSetting' : ''
						}}"
						[ngbTooltip]="mainItem.tooltip || ''"
						placement="end"
						container="body"
					>
						<div *ngIf="v2version" style="display: flex;">
							<img
								style="width: 20px; height: 20px; margin-right: 9px; margin-top: 3px;"
								*ngIf="mainItem.hasChildren && expandMenu && !mainItem.childOpen"
								src="assets/icons/nav-menu/arrow-up.svg"
							/>
							<img
								style="width: 30px; height: 30px; margin-right: 4px; margin-top: -5px; margin-left: -1px;"
								*ngIf="mainItem.hasChildren && expandMenu && mainItem.childOpen"
								src="assets/icons/nav-menu/arrow-down.svg"
							/>
							<a
								[ngStyle]="
									(isParentActive(mainItem.route) && !mainItem.childOpen) ||
									(isParentActive(mainItem.route) && mainItem.childOpen && !expandMenu)
										? { color: '#006BFF' }
										: {}
								"
								class="route-text new-text adisable"
								[ngClass]="{
									'ai-cre': mainItem.name == 'Ai Creative' && expandMenu,
									'ai-cre': mainItem.name == 'optimization AI' && expandMenu,
									'ai-line': mainItem.name == 'AI Targeting' && expandMenu,
									'ai-int': mainItem.name == 'AI Intelligence' && expandMenu,
									'cali-line': mainItem.name == 'Calibration' && expandMenu,
									'overview-cls': mainItem.name == 'OverView' && expandMenu
								}"
								[routerLink]="mainItem.path ? mainItem.path : mainItem.path"
							>
								{{ mainItem.title }}
							</a>
							<div
								*ngIf="mainItem.icon"
								style="display: flex; position: relative; margin-left: 78px;"
								[ngClass]="{ 'ai-trg': mainItem.name == 'AI Targeting' && !expandMenu, cali: mainItem.name == 'Calibration' && !expandMenu }"
							>
								<img
									[ngClass]="{
										'frst-icon': mainItem.name == 'Ai Creative',
										'ai-cre': mainItem.name == 'optimization AI',
										'scd-icon': mainItem.name == 'AI Intelligence',
										'trd-icon': mainItem.name == 'AI Targeting',
										'fth-icon': mainItem.name == 'Calibration',
										'overview-img': mainItem.name == 'OverView'
									}"
									class="filter-dark"
									[class.no-filter]="mainItem.name == 'Shopify' || mainItem.name == 'Google'"
									[src]="mainItem.icon"
								/>
							</div>
						</div>

						<div
							*ngIf="!v2version"
							style="display: flex; position: relative;"
							[ngClass]="{ 'ai-trg': mainItem.name == 'AI Targeting' && !expandMenu, cali: mainItem.name == 'Calibration' && !expandMenu }"
						>
							<img
								[ngClass]="{
									'filter-filed-purple': (isParentActive(mainItem.route) && !mainItem.childOpen) || (mainItem.childOpen && !expandMenu),
									'frst-icon': mainItem.name == 'Ai Creative',
									'ai-cre': mainItem.name == 'optimization AI',
									'scd-icon': mainItem.name == 'AI Intelligence',
									'trd-icon': mainItem.name == 'AI Targeting',
									'fth-icon': mainItem.name == 'Calibration'
								}"
								class="filter-dark"
								[class.no-filter]="mainItem.name == 'Shopify' || mainItem.name == 'Google'"
								[src]="mainItem.icon"
							/>
						</div>

						<span
							*ngIf="!v2version"
							[ngStyle]="
								(isParentActive(mainItem.route) && !mainItem.childOpen) || (isParentActive(mainItem.route) && mainItem.childOpen && !expandMenu)
									? { color: '#006BFF' }
									: {}
							"
							class="route-text"
							[ngClass]="{
								'ai-cre': mainItem.name == 'Ai Creative' && expandMenu,
								'ai-line': mainItem.name == 'AI Targeting' && expandMenu,
								'ai-int': mainItem.name == 'AI Intelligence' && expandMenu,
								'cali-line': mainItem.name == 'Calibration' && expandMenu
							}"
						>
							{{ mainItem.title }}
						</span>
						<img
							style="width: 20px; height: 20px; margin-right: 9px; margin-top: 3px;"
							*ngIf="!v2version && mainItem.hasChildren && expandMenu && !mainItem.childOpen"
							src="assets/icons/nav-menu/arrow-up.svg"
						/>
						<img
							style="width: 30px; height: 30px; margin-right: 4px; margin-top: -5px; margin-left: -4px;"
							*ngIf="!v2version && mainItem.hasChildren && expandMenu && mainItem.childOpen"
							src="assets/icons/nav-menu/arrow-down.svg"
						/>
					</mat-list-item>

					<!-- !child menu list -->
					<ng-container *ngIf="mainItem.hasChildren && mainItem.childOpen && expandMenu && !isMobile">
						<mat-list *ngFor="let item of filteredSubMenuList2; let j = index">
							<ng-container *ngIf="item?.parentName == mainItem?.name">
								<mat-list-item
									[id]="'childMenu' + j"
									(click)="menuItemSubActive(j)"
									[ngStyle]="
										checkSubMenuSelected(item)
											? menuItemSelectedEffect()
											: item.parentName == 'Optimization AI' || 'Ai Creative'
											? borderLeft()
											: {}
									"
									[ngStyle]="item.hoverOver && !checkSubMenuSelected(item) ? mouseEnterMenuEffect() : {}"
									[class]="removeSelectionClass"
									(mouseleave)="mouseLeaveSubMenu(j)"
									(mouseenter)="mouseEnterSubMenu(j)"
									[ngClass]="{
										'mat-list-item-content-expanded': expandMenu,
										'new-sub-men': v2version,
										'per-height': item.title2 == 'Analysis'
									}"
									class="menu-item"
									[style.marginTop]="item.title == 'Performance' ? '0px' : ''"
									[style.marginBottom]="item.title == 'Performance' ? '0px' : ''"
									[style.height]="item.title == 'Performance' ? '57px !important' : ''"
									[style.height]="item.title == 'AI' ? '57px !important' : ''"
									[style.paddingTop]="
										item.parentName == 'Optimization AI' ? '0px !important' : item.parentName == 'Ai Creative' ? '19px' : '0px'
									"
									[style.marginLeft]="item.parentName == 'Optimization AI' || 'Ai Creative' ? '25px !important' : '0px !important'"
									[style.borderLeft]="item.parentName == 'Optimization AI' || 'Ai Creative' ? ' 5px solid #EFEFEF !important' : ''"
									[ngbTooltip]="item.tooltip || ''"
									placement="end"
									container="body"
								>
									<div *ngIf="!v2version">
										<span
											[ngStyle]="checkSubMenuSelected(item) ? { color: '#006BFF' } : {}"
											class="route-text"
											[ngClass]="{ 'fb-google': item.title == 'Facebook' || item.title == 'Google' }"
										>
											{{ item.title }}
										</span>
									</div>

									<div *ngIf="v2version">
										<a
											[ngStyle]="checkSubMenuSelected(item) ? { color: '#006BFF' } : {}"
											class="route-text new-text adisable"
											[ngClass]="{
												'fb-google':
													item.title == 'Facebook' ||
													item.title == 'Google' ||
													item.title == 'AI' ||
													item.title == 'Action Impact' ||
													item.title == 'Performance' ||
													item.title == 'AI Calibration'
											}"
										>
											{{ item.title }}
											<span
												[ngStyle]="checkSubMenuSelected(item) ? { color: '#006BFF' } : {}"
												class="route-text new-text fb-google"
												style="display: block; line-height: 22px !important;"
												*ngIf="item?.title2"
											>
												{{ item.title2 }}
											</span>
										</a>

										<!-- !Icon for sub item -->
										<div *ngIf="item.showIcon">
											<img *ngIf="item.showIcon && !checkSubMenuSelected(item)" class="sub-icon-new" [src]="item.icon" />
										</div>

										<div *ngIf="item.parentName == 'Ai Creative'" style="height: 20px;" [style.width]="j == 0 ? '40px' : '20px'">
											<img
												*ngIf="item.parentName == 'Ai Creative' && !checkSubMenuSelected(item)"
												class="sub-icon new-icon"
												[ngClass]="{ 'fb-icon': item.title == 'Facebook' }"
												[src]="item.icon"
											/>
											<img
												*ngIf="item.parentName == 'Ai Creative' && j > 0 && checkSubMenuSelected(item)"
												class="sub-icon new-icon"
												[ngClass]="{ 'fb-icon': item.title == 'Facebook' }"
												[src]="item.icon"
											/>
											<img
												*ngIf="item.parentName == 'Ai Creative' && j == 0 && checkSubMenuSelected(item)"
												class="sub-icon new-icon"
												[ngClass]="{ 'fb-icon': item.title == 'Facebook' }"
												[src]="item.icon"
											/>
										</div>
									</div>
								</mat-list-item>
							</ng-container>
						</mat-list>
					</ng-container>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="toggleExpandNavMenu()"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
						class="menu-item email-header showMobile showTab"
					>
						<div class="user">
							<img src="assets/templates/logged_user.svg" />
							<div class="user-info">
								<p class="user-name">{{ userFName + ' ' + userLName }}</p>
								<p class="user-email">{{ userEmail }}</p>
							</div>
						</div>
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="goToSelectedPage('profile')"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu, selected: selectedMobileSettingPage == 'profile' }"
						class="menu-item showMobile showTab"
						style="padding-top: 10.5px;"
					>
						<img class="filter-svg" [src]="'assets/icons/nav-menu/my-profile.svg'" />
						<span class="route-text">
							{{ 'My Profile' }}
						</span>
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="goToSelectedPage('teams')"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu, selected: selectedMobileSettingPage == 'teams' }"
						class="menu-item showMobile showTab"
					>
						<img class="filter-svg" [src]="'assets/icons/nav-menu/team.svg'" />
						<span class="route-text">
							{{ 'Team' }}
						</span>
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="goToSelectedPage('credit')"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu, selected: selectedMobileSettingPage == 'credit' }"
						class="menu-item showMobile showTab"
					>
						<img class="filter-svg" [src]="'assets/icons/nav-menu/credit-use.svg'" />
						<span class="route-text">
							{{ 'Credit Use' }}
						</span>
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="goToSelectedPage('billing')"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu, selected: selectedMobileSettingPage == 'billing' }"
						class="menu-item showMobile showTab"
					>
						<img class="filter-svg" [src]="'assets/icons/nav-menu/billing.svg'" />
						<span class="route-text">
							{{ 'Billing' }}
						</span>
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item
						(click)="openKnowledgeBase()"
						[ngClass]="{ 'mat-list-item-content-expanded': expandMenu }"
						class="menu-item showMobile showTab"
					>
						<img class="filter-svg" [src]="'assets/shakespeare/help_icon.svg'" />
						<span class="route-text">
							{{ 'Help & Support' }}
						</span>
					</mat-list-item>
				</mat-list>
				<mat-list>
					<mat-list-item (click)="logout()" [ngClass]="{ 'mat-list-item-content-expanded': expandMenu }" class="menu-item showMobile showTab">
						<img class="filter-svg" [src]="'assets/icons/nav-menu/signout.svg'" />
						<span class="route-text">
							{{ 'Logout' }}
						</span>
					</mat-list-item>
				</mat-list>
			</div>

			<div (click)="goToBilling()" *ngIf="!isMobile" class="sidenav-action">
				<img src="assets/icons/nav-menu/settings-dark-icon.svg" />
				<div class="text">{{ 'Settings' }}</div>
			</div>

			<div class="nav-btn">
				<button class="upgrade-btn" type="button" (click)="navigateToBilling()" *ngIf="canUpgradePlan">
					<img src="assets/shakespeare/upgrade_plan.svg" alt="" class="upgrade-icon" />
					<span class="upgrade-text">UPGRADE PLAN</span>
				</button>
			</div>
		</mat-sidenav>
	</ng-container>
</section>

<section [ngClass]="{ 'side-container-wide': expandMenu }" class="side-container closed">
	<div [class.header-wide]="expandMenu" class="header closed" [ngClass]="{ 'no-show': !headerNavbar }">
		<mat-toolbar class="header_toolbar" [ngClass]="{ open: isSubmenuOpen }">
			<div class="d-mobile-none">
				<div class="top-logo-img" style="margin-left: 20px;">
					<div class="full-screen-back-btn" (click)="exitFullScreenMode()" *ngIf="isFullScreenMode">
						<p>
							<span class="fas fa-chevron-left"></span>
							Back
						</p>
					</div>
					<img (click)="backToHome()" src="assets/shakespeare/shakespeare-logo.svg" />
				</div>
			</div>
			<div class="showMobile showTab" *ngIf="headerNavbar">
				<div *ngIf="!megaMenuOpen" class="top-logo-img">
					<div class="logged-user">
						<div class="firstActiveLogo" (click)="toggleExpandNavMenu()">
							<img src="assets/templates/logged_user.svg" />
						</div>
						<div class="route-name" (click)="toggle(isSubmenuOpen)">
							<h2>{{ routeName }}</h2>
						</div>
					</div>
					<ng-container *ngIf="showNavDropdown && !isSettingsOn">
						<div class="sub-menu-toggle" *ngIf="!isSubmenuOpen" (click)="toggle(isSubmenuOpen)">
							<img src="assets/icons/nav-menu/submenu-icon.svg" alt="" />
						</div>
						<div class="sub-menu-toggle" *ngIf="isSubmenuOpen" (click)="toggle(isSubmenuOpen)">
							<img src="assets/icons/nav-menu/submenu-icon-up.svg" alt="" />
						</div>
					</ng-container>
					<div>
						<img *ngIf="isSettingsOn" class="cross" (click)="goBack()" src="assets/icons/cross-dismiss-icon.svg" alt="" />
					</div>
					<div class="mobile-menu-display" *ngIf="!isMobile">
						<button class="upgrade-btn" type="button" (click)="navigateToBilling()" *ngIf="canUpgradePlan">
							<img src="assets/shakespeare/upgrade_plan.svg" alt="" class="upgrade-icon" />
							<span class="upgrade-text">UPGRADE PLAN</span>
							&nbsp;
						</button>
					</div>
					<div *ngIf="!isSettingsOn && (currentUrl?.includes('shopify') || routeName == 'Home') && isShopifyUser" class="shopify-con">
						<img
							(click)="this.goToShopify(!currentUrl?.includes('shopify'))"
							[class.home-icon]="currentUrl?.includes('shopify')"
							[src]="currentUrl?.includes('shopify') ? 'assets/icons/nav-menu/dashboard.svg' : 'assets/icons/nav-menu/shopify.svg'"
							alt=""
						/>
					</div>
					<div *ngIf="!isSettingsOn && routeName !== 'Home' && !currentUrl?.includes('shopify')" class="shopify-con">
						<app-tooltip
							noPlaceRight="true"
							large="true"
							[openModal]="true"
							openModal="true"
							link="Learn more"
							[categoryType]="this.subPageCategory"
							[text]="tooltipText"
						></app-tooltip>
					</div>
				</div>
				<div class="sub-menu-list" *ngIf="isSubmenuOpen">
					<ul>
						<li
							class="sub-menu-item"
							*ngFor="let item of filteredSubMenuList; let i = index"
							[ngClass]="checkSubMenuSelected(item) ? 'selected' : ''"
							(click)="menuItemSubActive(i)"
						>
							<div class="title">
								<img [src]="item?.icon" alt="" />
								<p>{{ item.title }}</p>
							</div>
							<div>
								<span *ngIf="checkSubMenuSelected(item)"><i class="fas fa-check"></i></span>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<span fxFlex></span>

			<div *ngIf="isImpersonated" class="impersonation-exit">
				<div class="impersonation-content">
					<p class="impersonation-text">You are in Impersonation Mode. Any changes you make will impact your client.</p>
				</div>
			</div>

			<div class="bonus-offer" *ngIf="isBonus && !onBillingPage && showBonus">
				<div class="con">
					<div class="skew">
						<p class="text">Limited Time Offer</p>
					</div>
				</div>
				<p class="center-text">
					Upgrade Now
					<span>Get 85% off On PRO Plan for only $9</span>
				</p>
				<button (click)="navigateToBilling()">
					View Offer
					<em class="fas fa-arrow-right"></em>
				</button>
			</div>

			<div class="header_icons">
				<div class="unpaid-invoice-btn" *ngIf="this.unpaidInvoice">
					<a [routerLink]="['/user-management/billing-and-payment']">Unpaid Invoice</a>
					<div class="unpaid-description">There is an issue with your card. We were unable to bill you. Please add a new payment.</div>
				</div>

				<!-- <div (click)="openNotification()" class="profile-icon d-mobile-none">
					<img src="assets/shakespeare/notifications_none.svg" alt="" />
				</div> -->

				<app-notifications-dialog
					*ngIf="!isShowAllNotification"
					[notifications]="notifications"
					(updateUnreadCount)="updateUnreadCountEvent($event)"
				></app-notifications-dialog>

				<div (click)="openKnowledgeBase()" class="profile-icon d-mobile-none tour-knowledge">
					<img src="assets/shakespeare/help_icon.svg" alt="" />
				</div>
				<div #settingsIcon (click)="toggleProfileAction()" class="profile-icon extre-space d-mobile-none">
					<img class="img-sty" [src]="companyDetails?.logo ? companyDetails?.logo : 'assets/templates/logged_user.svg'" alt="" />
					<span class="profile-name">
						{{ userDetailsData?.firstName ? userDetailsData?.firstName : '' }}
						<img class="down-arrow" [ngClass]="{ revert: this.profileOpen }" src="assets/templates/black-down-arrow.svg" alt="" />
					</span>
				</div>
			</div>
		</mat-toolbar>
	</div>
	<ng-container *ngIf="noSideMenu">
		<router-outlet></router-outlet>
	</ng-container>

	<div
		class="main-container closed"
		[ngClass]="{ 'sub-menu-open': isSubmenuOpen, 'no-header': !headerNavbar, 'full-screen-mode': isFullScreenMode, 'new-menu-cnt': v2version }"
	>
		<mat-sidenav-container (click)="close()">
			<mat-sidenav-content (scroll)="onScroll($event)" [class.scroll-parent]="autoScrolled$" style="background: white;">
				<ng-container *ngIf="!noSideMenu">
					<router-outlet></router-outlet>
				</ng-container>

				<shakespeare-spinning-loader [rotating]="true" class="loading-indicator" *ngIf="showSpinner"></shakespeare-spinning-loader>
			</mat-sidenav-content>
		</mat-sidenav-container>
	</div>
	<div [class.header-wide]="expandMenu" class="footer showMobile showTab" *ngIf="footerNavbar && routeName !== 'Shopify'">
		<mat-toolbar class="footer_toolbar" id="footer_toolbar">
			<div class="footer-menu-list {{ 'firstActive' + item.title }}" *ngFor="let item of filteredMenuList.slice(0, 5); let i = index">
				<div class="footer-menu-item" (click)="menuItemActive(i, item)" [ngClass]="isParentActive(item.route) ? 'selected' : null">
					<div class="menu-icon">
						<img [src]="item.icon" alt="" />
					</div>
					<div class="menu-text">
						<span>{{ item.title }}</span>
					</div>
				</div>
			</div>
		</mat-toolbar>
	</div>
</section>

<div #settingsPopup class="profile-card" (click)="$event.stopPropagation()" *ngIf="profileOpen">
	<div class="avatar-personal">
		<div class="avatar">
			<img *ngIf="companyDetails && companyDetails.logo" [src]="companyDetails.logo ? companyDetails.logo : 'assets/templates/logged_user.svg'" alt="" />
		</div>
		<div class="personal">
			<div class="top">{{ userFName + ' ' + userLName }}</div>
			<div class="bottom" title="{{ userEmail }}">{{ userEmail }}</div>
		</div>
	</div>
	<div (click)="goToBilling()" *ngIf="!isMobile" class="action">
		<img src="assets/icons/nav-menu/settings-dark-icon.svg" />
		<div class="text">{{ 'Settings' }}</div>
	</div>
	<!-- <div (click)="toIntegrations()" *ngIf="!isMobile" class="action">
		<img src="assets/icons/nav-menu/integrations-icon.svg" />
		<div class="text">{{ 'Integrations' }}</div>
	</div> -->
	<div (click)="logout()" class="action sign">
		<img src="assets/icons/nav-menu/signout-icon.svg" />
		<div class="text">{{ 'Sign out' }}</div>
	</div>
</div>

<app-toast-notification class="toast-position"></app-toast-notification>
