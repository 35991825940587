export const LANGUAGES = [
	{
		name: 'Afrikaans',
		id: 'af'
	},
	{
		name: 'Albanian',
		id: 'sq'
	},
	{
		name: 'Amharic',
		id: 'am'
	},
	{
		name: 'Arabic',
		id: 'ar'
	},
	{
		name: 'Armenian',
		id: 'hy'
	},
	{
		name: 'Azerbaijani',
		id: 'az'
	},
	{
		name: 'Basque',
		id: 'eu'
	},
	{
		name: 'Belarusian',
		id: 'be'
	},
	{
		name: 'Bengali',
		id: 'bn'
	},
	{
		name: 'Bosnian',
		id: 'bs'
	},
	{
		name: 'Bulgarian',
		id: 'bg'
	},
	{
		name: 'Catalan',
		id: 'ca'
	},
	{
		name: 'Cebuano',
		id: 'ceb'
	},
	{
		name: 'Chinese (Simplified)',
		id: 'zh-CN'
	},
	{
		name: 'Chinese (Traditional)',
		id: 'zh-TW'
	},
	{
		name: 'Corsican',
		id: 'co'
	},
	{
		name: 'Croatian',
		id: 'hr'
	},
	{
		name: 'Czech',
		id: 'cs'
	},
	{
		name: 'Danish',
		id: 'da'
	},
	{
		name: 'Dutch',
		id: 'nl'
	},
	{
		name: 'English',
		id: 'en'
	},
	{
		name: 'Esperanto',
		id: 'eo'
	},
	{
		name: 'Estonian',
		id: 'et'
	},
	{
		name: 'Finnish',
		id: 'fi'
	},
	{
		name: 'French',
		id: 'fr'
	},
	{
		name: 'Frisian',
		id: 'fy'
	},
	{
		name: 'Galician',
		id: 'gl'
	},
	{
		name: 'Georgian',
		id: 'ka'
	},
	{
		name: 'German',
		id: 'de'
	},
	{
		name: 'Greek',
		id: 'el'
	},
	{
		name: 'Gujarati',
		id: 'gu'
	},
	{
		name: 'Haitian Creole',
		id: 'ht'
	},
	{
		name: 'Hausa',
		id: 'ha'
	},
	{
		name: 'Hawaiian',
		id: 'haw'
	},
	{
		name: 'Hebrew',
		id: 'he or iw'
	},
	{
		name: 'Hindi',
		id: 'hi'
	},
	{
		name: 'Hmong',
		id: 'hmn'
	},
	{
		name: 'Hungarian',
		id: 'hu'
	},
	{
		name: 'Icelandic',
		id: 'is'
	},
	{
		name: 'Igbo',
		id: 'ig'
	},
	{
		name: 'Indonesian',
		id: 'id'
	},
	{
		name: 'Irish',
		id: 'ga'
	},
	{
		name: 'Italian',
		id: 'it'
	},
	{
		name: 'Japanese',
		id: 'ja'
	},
	{
		name: 'Javanese',
		id: 'jv'
	},
	{
		name: 'Kannada',
		id: 'kn'
	},
	{
		name: 'Kazakh',
		id: 'kk'
	},
	{
		name: 'Khmer',
		id: 'km'
	},
	{
		name: 'Kinyarwanda',
		id: 'rw'
	},
	{
		name: 'Korean',
		id: 'ko'
	},
	{
		name: 'Kurdish',
		id: 'ku'
	},
	{
		name: 'Kyrgyz',
		id: 'ky'
	},
	{
		name: 'Lao',
		id: 'lo'
	},
	{
		name: 'Latin',
		id: 'la'
	},
	{
		name: 'Latvian',
		id: 'lv'
	},
	{
		name: 'Lithuanian',
		id: 'lt'
	},
	{
		name: 'Luxembourgish',
		id: 'lb'
	},
	{
		name: 'Macedonian',
		id: 'mk'
	},
	{
		name: 'Malagasy',
		id: 'mg'
	},
	{
		name: 'Malay',
		id: 'ms'
	},
	{
		name: 'Malayalam',
		id: 'ml'
	},
	{
		name: 'Maltese',
		id: 'mt'
	},
	{
		name: 'Maori',
		id: 'mi'
	},
	{
		name: 'Marathi',
		id: 'mr'
	},
	{
		name: 'Mongolian',
		id: 'mn'
	},
	{
		name: 'Myanmar (Burmese)',
		id: 'my'
	},
	{
		name: 'Nepali',
		id: 'ne'
	},
	{
		name: 'Norwegian',
		id: 'no'
	},
	{
		name: 'Nyanja (Chichewa)',
		id: 'ny'
	},
	{
		name: 'Odia (Oriya)',
		id: 'or'
	},
	{
		name: 'Pashto',
		id: 'ps'
	},
	{
		name: 'Persian',
		id: 'fa'
	},
	{
		name: 'Polish',
		id: 'pl'
	},
	{
		name: 'Portuguese (Portugal,  Brazil)',
		id: 'pt'
	},
	{
		name: 'Punjabi',
		id: 'pa'
	},
	{
		name: 'Romanian',
		id: 'ro'
	},
	{
		name: 'Russian',
		id: 'ru'
	},
	{
		name: 'Samoan',
		id: 'sm'
	},
	{
		name: 'Scots Gaelic',
		id: 'gd'
	},
	{
		name: 'Serbian',
		id: 'sr'
	},
	{
		name: 'Sesotho',
		id: 'st'
	},
	{
		name: 'Shona',
		id: 'sn'
	},
	{
		name: 'Sindhi',
		id: 'sd'
	},
	{
		name: 'Sinhala (Sinhalese)',
		id: 'si'
	},
	{
		name: 'Slovak',
		id: 'sk'
	},
	{
		name: 'Slovenian',
		id: 'sl'
	},
	{
		name: 'Somali',
		id: 'so'
	},
	{
		name: 'Spanish',
		id: 'es'
	},
	{
		name: 'Sundanese',
		id: 'su'
	},
	{
		name: 'Swahili',
		id: 'sw'
	},
	{
		name: 'Swedish',
		id: 'sv'
	},
	{
		name: 'Tagalog (Filipino)',
		id: 'tl'
	},
	{
		name: 'Tajik',
		id: 'tg'
	},
	{
		name: 'Tamil',
		id: 'ta'
	},
	{
		name: 'Tatar',
		id: 'tt'
	},
	{
		name: 'Telugu',
		id: 'te'
	},
	{
		name: 'Thai',
		id: 'th'
	},
	{
		name: 'Turkish',
		id: 'tr'
	},
	{
		name: 'Turkmen',
		id: 'tk'
	},
	{
		name: 'Ukrainian',
		id: 'uk'
	},
	{
		name: 'Urdu',
		id: 'ur'
	},
	{
		name: 'Uyghur',
		id: 'ug'
	},
	{
		name: 'Uzbek',
		id: 'uz'
	},
	{
		name: 'Vietnamese',
		id: 'vi'
	},
	{
		name: 'Welsh',
		id: 'cy'
	},
	{
		name: 'Xhosa',
		id: 'xh'
	},
	{
		name: 'Yiddish',
		id: 'yi'
	},
	{
		name: 'Yoruba',
		id: 'yo'
	},
	{
		name: 'Zulu',
		id: 'zu'
	}
];
