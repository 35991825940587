import { Action } from '@ngrx/store';
import { GeneralSettingsRequest } from '../services/general-settings/models/api/general-settings-request.model';
import { BreakdownsResponseInterface } from '../models/breakdowns-response.interface';
import { PublishStatusInterface } from './state-entities/publish/publish-status.interface';

export enum SharedActionTypes {
	SetGlobalDate = '[Shared] Set Global Date',

	ShowGlobalSpinner = '[Shared] Show Global Spinner',
	HideGlobalSpinner = '[Shared] Hide Global Spinner',

	LoadMetrics = '[Shared] Load Metrics',
	LoadMetricsSuccess = '[Shared] Load Metrics Success',
	LoadMetricsFailure = '[Shared] Load Metrics Failure',

	LoadDimensions = '[Shared] Load Dimensions',
	LoadDimensionsSuccess = '[Shared] Load Dimensions Success',
	LoadDimensionsFailure = '[Shared] Load Dimensions Failure',

	LoadBreakdowns = '[Shared] Load Breakdowns',
	LoadBreakdownsSuccess = '[Shared] Load Breakdowns Success',
	LoadBreakdownsFailure = '[Shared] Load Breakdowns Failure',

	LoadSharedSettings = '[GeneralSettings] Load Shared Settings Slice',
	LoadSharedSettingsSuccess = '[GeneralSettings] Load Shared Settings Slice Success',
	LoadSharedSettingsFailure = '[GeneralSettings] Load Shared Settings Slice Failure',

	UpdateSharedSettings = '[GeneralSettings] Update Shared Settings Slice',
	UpdateSharedSettingsSuccess = '[GeneralSettings] Update Shared Settings Slice Success',
	UpdateSharedSettingsFailure = '[GeneralSettings] Update Shared Settings Slice Failure',

	SetSharedSettingsState = '[GeneralSettings] Set Shared Settings Slice',

	SaveUserDashboards = '[Ads Manager][Dashboard] Save User Dashboards',
	LoadUserDashboards = '[Ads Manager][Dashboard] Load User Dashboards',

	LoadReportTypes = '[ Reporting ] Load Report Types',
	LoadReportTypesSuccess = '[ Reporting ] Load Report Types Success',
	LoadReportTypesFailure = '[ Reporting ] Load Report Types Failure',

	SetCurrentAdsManagerTab = '[Shared] Set Current Ads Manager Tab',
	SetGlobalElementsView = '[Shared] Set Global Elements View',
	SetGlobalElementsViewAds = '[Shared] Set Global Elements View Ads',
	SetToggleElementsGraph = '[Shared] Set Toggle Elements Graph',
	SetToggleElementsMetrics = '[Shared] Set Toggle Elements Metrics',
	SetToggleElementsRecommendations = '[Shared] Set Toggle Elements Recommendations',
	SetSelectedElementsMetrics = '[Shared] Set Selected Elements Metrics',
	UpdatePublishStatus = '[Shared] Update Publish Status'
}

export class SetGlobalDate implements Action {
	public readonly type = SharedActionTypes.SetGlobalDate;

	constructor(public payload: any) {}
}

export class LoadSharedSettings implements Action {
	public readonly type = SharedActionTypes.LoadSharedSettings;

	constructor(public payload: number) {}
}

export class LoadSharedSettingsFailure implements Action {
	public readonly type = SharedActionTypes.LoadSharedSettingsFailure;

	constructor(public payload: string) {}
}

export class LoadMetrics implements Action {
	public readonly type = SharedActionTypes.LoadMetrics;

	constructor(public readonly payload: string) {}
}

export class LoadMetricsFailure implements Action {
	public readonly type = SharedActionTypes.LoadMetricsFailure;

	constructor(public readonly payload: string) {}
}

export class LoadDimensions implements Action {
	public readonly type = SharedActionTypes.LoadDimensions;

	constructor(public readonly payload: string) {}
}

// export class LoadDimensionsSuccess implements Action {
// 	public readonly type = SharedActionTypes.LoadDimensionsSuccess;

// 	constructor(public readonly payload: DimensionsResponseInterface) {}
// }

export class LoadDimensionsFailure implements Action {
	public readonly type = SharedActionTypes.LoadDimensionsFailure;

	constructor(public readonly payload: string) {}
}

export class LoadBreakdowns implements Action {
	public readonly type = SharedActionTypes.LoadBreakdowns;

	constructor(public readonly payload: string) {}
}

export class LoadBreakdownsSuccess implements Action {
	public readonly type = SharedActionTypes.LoadBreakdownsSuccess;

	constructor(public readonly payload: BreakdownsResponseInterface) {}
}

export class LoadBreakdownsFailure implements Action {
	public readonly type = SharedActionTypes.LoadBreakdownsFailure;

	constructor(public readonly payload: string) {}
}

// -----

export class UpdateSharedSettings implements Action {
	public readonly type = SharedActionTypes.UpdateSharedSettings;

	constructor(public payload: GeneralSettingsRequest) {}
}

export class UpdateSharedSettingsFailure implements Action {
	public readonly type = SharedActionTypes.UpdateSharedSettingsFailure;

	constructor(public payload: string) {}
}

// -----

export class ShowGlobalSpinner implements Action {
	public readonly type = SharedActionTypes.ShowGlobalSpinner;

	constructor() {}
}

export class HideGlobalSpinner implements Action {
	public readonly type = SharedActionTypes.HideGlobalSpinner;

	constructor() {}
}

export class LoadUserDashboards implements Action {
	public readonly type = SharedActionTypes.LoadUserDashboards;
	constructor() {}
}

export class LoadReportTypesFailure implements Action {
	public readonly type = SharedActionTypes.LoadReportTypesFailure;

	constructor(public readonly payload: string) {}
}

export class SetGlobalElementsView implements Action {
	public readonly type = SharedActionTypes.SetGlobalElementsView;

	constructor(public payload: string) {}
}

export class SetGlobalElementsViewAds implements Action {
	public readonly type = SharedActionTypes.SetGlobalElementsViewAds;

	constructor(public payload: string) {}
}

export class SetToggleElementsGraph implements Action {
	public readonly type = SharedActionTypes.SetToggleElementsGraph;

	constructor(public payload: boolean) {}
}

export class SetToggleElementsMetrics implements Action {
	public readonly type = SharedActionTypes.SetToggleElementsMetrics;

	constructor(public payload: boolean) {}
}

export class SetToggleElementsRecommendations implements Action {
	public readonly type = SharedActionTypes.SetToggleElementsRecommendations;

	constructor(public payload: boolean) {}
}

export type SharedActions =
	| LoadReportTypesFailure
	| LoadDimensions
	| LoadDimensionsFailure
	| LoadBreakdowns
	| LoadBreakdownsSuccess
	| LoadBreakdownsFailure
	| LoadMetrics
	| LoadMetricsFailure
	| UpdateSharedSettingsFailure
	| LoadSharedSettingsFailure
	| SetGlobalDate
	| ShowGlobalSpinner
	| HideGlobalSpinner
	| LoadSharedSettings
	| UpdateSharedSettings
	| LoadUserDashboards
	| SetGlobalElementsView
	| SetGlobalElementsViewAds
	| SetToggleElementsGraph
	| SetToggleElementsMetrics
	| SetToggleElementsRecommendations;
