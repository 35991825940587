import { ShakespeareService } from './../../shakespeare.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DropdownInterface } from 'src/app-components/interfaces/dropdown-interface';
import { takeUntil } from 'rxjs/operators';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { Subject } from 'rxjs';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import moment from 'moment';
import { CreateNewModalComponent } from 'src/app/shared/create-new-modal/create-new-modal.component';
import { NoCreditModalComponent } from '../no-credit-modal/no-credit-modal.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-social-generator',
	templateUrl: './social-generator.component.html',
	styleUrls: ['../ad-copy-generator/ad-copy-generator.component.scss']
})
export class SocialGeneratorComponent implements OnInit {
	@ViewChild('scroll') private myScrollContainer: ElementRef;
	@ViewChild('output') public outputWrapper: ElementRef;
	public outputData: any[] = [];
	templates: TemplatesModel[] = [...TEMPLATES];
	selectedTemplate: TemplatesModel;
	public generatorForm: FormGroup;
	public error = false;
	public errorText = false;
	public ToneError = false;
	public numberError = false;
	private unsubscriber$ = new Subject<void>();
	public favouriteData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	public payload: object;
	public advanceOptions = false;
	public isDataFetched = false;
	public isSaveAllowed = false;
	public optionalText = '';
	public blogKeywords = true;
	public keywordHelper = [{ placeholder: 'Enter here...' }];
	public keywordPlaceHolders = ['Enter here...', 'Enter here...', 'Enter here...', 'Enter here...', 'Enter here...'];
	public titleName = 'Product Name';
	public descName = 'Product Description';

	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max3Output = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max1Output = [{ name: '1 Output', value: 1 }];

	public outputLang = [...LANGUAGES];
	public numberOfOutputs = [
		{ name: 1, id: 1 },
		{ name: 3, id: 3 },
		{ name: 5, id: 5 }
	];
	public translate = false;
	public userFName: string;
	public min = 1;
	public max = 70;
	public largeStep = 1;
	public smallStep = 14;
	public sliderValue = [15, 29];
	public formButtonText = 'GENERATE';
	public selectedOutputLang = 'en';
	public selectedOutputTone: string;
	public oneTimeScroll = true;
	public maxOutputs = 3;
	public filedId: number;
	public emailType: string;
	public emailObj = {
		'Email Subject': 'subject',
		'Email Description': 'email',
		'Email Subject & Description': 'subject_email'
	};
	iPhoneMobile: boolean;
	isHistoryFound: any;
	isMargin: boolean;
	innerWidth: number;
	public isOutput = true;
	public isHistory = false;
	menuExpanded: any;
	public scrollToTop = false;
	public resettingForm: Subject<boolean> = new Subject<boolean>();
	public checkValidation: Subject<boolean> = new Subject<boolean>();
	public isHashTag: boolean = false;
	historyData: any;
	projectId: string;
	multiHandler = [];

	constructor(
		private formBuilder: FormBuilder,
		private userServiceApi: UserServiceApi,
		private store: Store<SharedState>,
		public activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		private userStore: Store<UserState>,
		public toastNotificationService: ToastNotificationService,
		private shakespeareService: ShakespeareService,
		private authenticationService: AuthenticationService
	) {
		// setTimeout(() => {
		// 	this.shakespeareService.triggerRoute$.next('templates'); // to make current sidenav item active
		// }, 300);
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		let credits = this.shakespeareService.userCredits$.value;
		if (credits <= 50 && credits !== null) {
			this.openCreditModal();
		}
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.createForm();
		this.generatorForm.get('name').valueChanges.subscribe(() => {
			this.formButtonText = 'GENERATE';
			if (this.generatorForm.controls.name.valid) {
				this.errorText = false;
			}
		});
		if (!this.isHashTag) {
			this.generatorForm.valueChanges.subscribe(() => {
				this.formButtonText = 'GENERATE';
			});
		}

		this.getActivatedRoute();

		if (this.selectedTemplate.template == 'subject') {
			this.generatorForm.get('context').setValidators([]); // or clearValidators() if bug in future
			this.generatorForm.get('context').updateValueAndValidity();
			this.optionalText = ' (Optional)';
		}

		switch (this.selectedTemplate.platform) {
			case 'pinterest':
				this.titleName = 'Product Name';
				this.descName = 'Describe your pin';
				break;
			case 'facebook':
				this.titleName = 'Title / Product Name';
				this.descName = 'Describe Your Post';
				break;
			case 'linkedin':
				this.titleName = 'Product / Brand Name';
				this.descName = 'Describe Your Post';
				break;
			case 'tiktok':
				this.titleName = 'Product Name';
				this.descName = 'Describe Your Topic';
				break;
			default:
				break;
		}
		if (this.selectedTemplate.title == 'HashTag Generator') {
			this.titleName = 'Topic';
			this.descName = '';
			this.isHashTag = true;
		} else {
			this.isHashTag = false;
		}
	}

	public openCreditModal(): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		if (this.shakespeareService.isUserPro$.value) {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		} else {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		}
		dialogRef.afterClosed().subscribe(obj => {
			console.log(obj);
		});
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['templateId']) {
				this.selectedTemplate = this.templates.find(template => {
					return template.id == params['templateId'];
				});
				if (this.selectedTemplate.template == 'headline') {
					this.maxOutputs = 5;
				}
			}
		});
	}

	public removeError(index: number, event, errorClass: string): void {
		if (event.target.value.length > 0) {
			document.getElementById(errorClass + index).style.display = 'none';
		}
	}

	public addMoreHandler(isLast: boolean, index: number, type: string, errorClass: string): void {
		if (isLast) {
			let element = <HTMLInputElement>document.getElementById(type + index);
			if (this.keywordPlaceHolders[index + 1] == undefined) {
				return;
			}
			element.value
				? this.keywordHelper.push({ placeholder: this.keywordPlaceHolders[index + 1] })
				: (document.getElementById(errorClass + index).style.display = 'block');

			let nextElement = <HTMLInputElement>document.getElementById(type + (index + 1));
			setTimeout(() => {
				nextElement.focus();
			}, 200); // few mili-seconds to wait for dom to build element
		} else {
			this.keywordHelper.pop();
		}
	}

	public scrollBottom(): void {
		if (this.oneTimeScroll) {
			try {
				this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
			} catch {}
		}
	}

	private createForm(): void {
		this.generatorForm = this.formBuilder.group({
			name: new FormControl(''),
			description: new FormControl(''),
			outputTone: new FormControl(null),
			outputLang: new FormControl(null),
			numOutputs: new FormControl({ name: '3 Outputs', value: 3 }, [Validators.required]),
			keywords: new FormControl(null)
		});
	}

	public setErrorMessage(): void {
		if (!this.generatorForm.controls.description.valid) {
			this.error = true;
		} else {
			this.error = false;
		}
	}

	public openCreateModal(isMobile = false): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'New Project',
			text: 'Enter a name to save.',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.value) {
				this.payload ? this.saveProjectHelper(obj.value) : this.saveProjectFromHistory(obj.value);
			}
			if (obj.id) {
				this.payload ? this.addToProjectHelper(obj.id) : this.addToProjectFromHistory(obj.id);
			}
		});
	}

	public saveProjectFromHistory(name: string): void {
		let temp = true;
		let data = this.historyData[0].projectData;
		let output = this.historyData.filter(item => item.isFav == true);
		output.forEach(element => {
			let payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					name: name || 'untitled-' + moment().format(),
					userTemplate: data.userTemplate,
					userInput: data.userInput,
					userOutput: [element]
				}
			};
			if (temp) {
				payload['data']['name'] = name || 'untitled-' + moment().format();
			} else {
				payload['data']['id'] = this.projectId;
			}
			temp ? this.saveProject(payload) : this.multiHandler.push(payload);
			temp = false;
			// this.saveProject(payload);
		});
	}

	public handleMultiple(): void {
		if (this.multiHandler.length == 0) return;
		this.multiHandler.forEach(payload => {
			payload['data']['id'] = this.projectId;
			this.addToProject(payload);
		});
	}

	public addToProjectFromHistory(id: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: id,
				userTemplate: this.payload['data'].userTemplate,
				userInput: this.payload['data'].userInput,
				userOutput: this.favouriteData
			}
		};
		this.addToProject(payload);
	}

	public saveProjectHelper(name: string): void {
		let temp = true;
		this.favouriteData.forEach(element => {
			let payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					name: name || 'untitled-' + moment().format(),
					userTemplate: this.payload['data'].userTemplate,
					userInput: this.payload['data'].userInput,
					userOutput: [element]
				}
			};
			if (temp) {
				payload['data']['name'] = name || 'untitled-' + moment().format();
			} else {
				payload['data']['id'] = this.projectId;
			}
			temp ? this.saveProject(payload) : this.multiHandler.push(payload);
			temp = false;
			// this.saveProject(payload);
		});
	}

	public addToProjectHelper(id: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: id,
				userTemplate: this.payload['data'].userTemplate,
				userInput: this.payload['data'].userInput,
				userOutput: this.favouriteData
			}
		};
		this.addToProject(payload);
	}

	public saveProject(payload: object): void {
		this.userServiceApi
			.createUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'Project with same name exists already') {
						this.toastNotificationService.sendErrorToast('Operation failed! Project name already exists');
						return;
					}
					this.projectId = response['body'].projectId;
					this.handleMultiple();
					this.toastNotificationService.sendSuccessToast('Project created');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		this.isSaveAllowed = false;
	}

	public addToProject(payload: object): void {
		this.userServiceApi
			.addToUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'project data already existed') {
						this.toastNotificationService.sendErrorToast('Operation failed! Content already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Output added!');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		this.isSaveAllowed = false;
	}

	public setErrorTextMessage(): void {
		if (!this.generatorForm.controls.name.valid) {
			this.errorText = true;
		} else {
			this.errorText = false;
		}
	}

	public onCheckToggleView(checked: boolean): void {
		this.advanceOptions = !this.advanceOptions;
		if (checked) {
			setTimeout(() => {
				this.scrollBottom();
			}, 700);
		}
	}

	public changeToneSource(event: DropdownInterface): void {
		if (event) {
			this.selectedOutputTone = event.name;
		}
		if (!this.generatorForm.controls.outputTone.valid) {
			this.ToneError = true;
		}
	}

	public changeLangSource(event): void {
		if (event) {
			this.selectedOutputLang = event.id;
		} else {
			this.selectedOutputLang = '';
		}
	}

	public checkInputValue(event, form): void {
		form.reportValidity();
		if (form.reportValidity()) {
			if (event.target.value <= this.maxOutputs) {
				this.generatorForm.patchValue({
					numOutputs: Number(event.target.value)
				});
			} else {
				this.generatorForm.patchValue({
					numOutputs: this.maxOutputs
				});
			}
		}
	}

	public updateValidators(event: boolean): void {
		if (event) {
			this.onSubmit();
		}
	}

	public onSubmit(): void {
		this.isHistory = false;
		this.isOutput = true;
		if (this.generatorForm.valid) {
			if (this.innerWidth <= 900) {
				this.outputWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				this.scrollToTop = true;
			}
			if (this.selectedTemplate.title == 'HashTag Generator') {
				this.onSubmitHashTag();
				return;
			}
			if (this.selectedTemplate.type == 'video') {
				this.onSubmitVideo();
				return;
			}
			this.formButtonText = 'GENERATE MORE';
			this.isFormSubmitted = true;
			let keywords = '';

			this.emailType = this.emailObj[this.selectedTemplate.title];
			let details = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userTemplate: {
						platform: this.selectedTemplate.platform,
						template: this.selectedTemplate.template
					},
					userInput: {
						title: this.generatorForm.controls.name.value,
						description: this.generatorForm.controls.description.value,
						tone: this.selectedOutputTone,
						outputLanguage: this.selectedOutputLang || 'en',
						translate: false,
						numOutputs: this.generatorForm.controls.numOutputs.value.value,
						keywords: (this.generatorForm.controls.keywords.value ?? '').split(',') ?? []
					}
				},
				extras: this.selectedTemplate
			};
			if (this.blogKeywords && keywords.length) {
				details.data.userInput['keywords'] = [keywords.substring(1).trim()]; // removing 1st comma and leading and trailing spaces
			}
			if (!this.advanceOptions) {
				details.data.userInput.translate = false;
			}
			if (this.selectedOutputLang !== 'en') {
				details.data.userInput.translate = true;
			}
			if (this.selectedOutputLang == '') {
				details.data.userInput.translate = false;
			}
			if (this.selectedTemplate.platform == 'Instagram') {
				delete details.data.userInput.tone;
				details.data.userInput['num_outputs'] = this.generatorForm.controls.numOutputs.value.value;
				delete details.data.userInput.numOutputs;
				delete details.user.userFiledId;
				details.user['user_filed_id'] = this.filedId.toString();
			}
			this.payload = details;
		} else {
			Object.keys(this.generatorForm.controls).forEach(key => {
				this.generatorForm.controls[key].markAsDirty();
				this.generatorForm.controls[key].markAsTouched();
			});
		}
	}

	onSubmitHashTag() {
		if (this.generatorForm.valid) {
			this.formButtonText = 'GENERATE MORE';
			this.isFormSubmitted = true;
			let keywords = '';
			let details = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userTemplate: {
						template: this.selectedTemplate.template
					},
					userInput: {
						topic: this.generatorForm.controls.name.value,
						hashtags_type: 'hashtags',
						tone: this.selectedOutputTone,
						outputLanguage: this.selectedOutputLang || 'en',
						translate: false,
						numOutputs: this.generatorForm.controls.numOutputs.value.value
					}
				},
				extras: this.selectedTemplate
			};
			if (this.blogKeywords && keywords.length) {
				details.data.userInput['keywords'] = [keywords.substring(1).trim()]; // removing 1st comma and leading and trailing spaces
			}
			if (this.selectedOutputLang !== 'en') {
				details.data.userInput.translate = true;
			}
			if (this.selectedOutputLang == '') {
				details.data.userInput.translate = false;
			}
			this.payload = details;
		} else {
			Object.keys(this.generatorForm.controls).forEach(key => {
				this.generatorForm.controls[key].markAsDirty();
			});
			this.setErrorMessage();
		}
	}

	onSubmitVideo() {
		if (this.generatorForm.valid) {
			this.formButtonText = 'GENERATE MORE';
			this.isFormSubmitted = true;
			let keywords = '';
			let details = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userTemplate: {
						platform: 'youtube',
						template: ''
						// template: this.selectedTemplate.template
					},
					userInput: {
						title: this.generatorForm.controls.name.value,
						// description: this.generatorForm.controls.description.value,
						tone: this.selectedOutputTone,
						outputLanguage: this.selectedOutputLang || 'en',
						translate: false,
						video_type: this.selectedTemplate.template,
						numOutputs: this.generatorForm.controls.numOutputs.value.value
					}
				},
				extras: this.selectedTemplate
			};
			if (this.blogKeywords && keywords.length) {
				details.data.userInput['keywords'] = [keywords.substring(1).trim()]; // removing 1st comma and leading and trailing spaces
			}
			if (this.selectedOutputLang !== 'en') {
				details.data.userInput.translate = true;
			}
			if (this.selectedOutputLang == '') {
				details.data.userInput.translate = false;
			}
			this.payload = details;
		} else {
			Object.keys(this.generatorForm.controls).forEach(key => {
				this.generatorForm.controls[key].markAsDirty();
			});
			this.setErrorMessage();
		}
	}

	public enableViewMore(isFetched: boolean): void {
		this.isDataFetched = isFetched;
		if (isFetched) {
			this.isFormSubmitted = !isFetched;
		}
	}

	public handleCallback(data): void {
		this.historyData = data;
		this.favouriteData = [];
		this.favouriteData = data.filter(template => {
			return template.isFav;
		});
		if (this.favouriteData.length) {
			this.isSaveAllowed = true;
		} else {
			this.isSaveAllowed = false;
		}
	}

	public resetForm(): void {
		this.advanceOptions = false;
		this.resettingForm.next(true);
		if (this.innerWidth <= 900) {
			this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.generatorForm.controls.numOutputs.setValue({ name: '3 Outputs', value: 3 });
	}
	public handleGeneratedData(event: any): void {
		this.outputData = event;
		this.isMargin = true;
		if (this.innerWidth <= 900) {
			setTimeout(() => {
				this.outputWrapper.nativeElement.scrollIntoView({ top: '53px', behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 300);
		}
	}

	public isHistoryData(event): void {
		this.isHistoryFound = event;
	}

	public deleteAll(): void {
		const reqObj = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				template_name: this.selectedTemplate.title
			}
		};
		this.userServiceApi.deleteTemplateHistoryItem(reqObj).subscribe(res => {
			this.toastNotificationService.sendSuccessToast('All history deleted successfully');
			this.isHistoryFound = false;
		});
	}

	checkFormValidation() {
		this.checkValidation.next(true);
	}
}
