<div class="side-container" [ngClass]="{ expanded: menuExpanded }">
	<div class="width-controller" [class.special-case]="isDropdown">
		<div class="head">
			<div class="search mobile">
				<!-- <input type="text" (input)="searchTemplate($event.target.value)" placeholder="Search by name" />
				<em class="fas fa-search icon-search"></em>
				(keyup)="onSearchEnter($event)" -->
				<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
				<div class="expand" (click)="expandProducts()">
					<img src="assets/icons/expand-page.svg" alt="" />
				</div>
			</div>
			<div class="right">
				<div class="refresh-btn header-btns" (click)="getAllProducts()" matTooltipClass="tooltipClass" [matTooltip]="'Sync Products'">
					<img src="assets/icons/refresh-icon.svg" alt="" />
				</div>
				<div class="sort-btn header-btns" mat-button [matMenuTriggerFor]="sort" matTooltipClass="tooltipClass" [matTooltip]="'Sort Products'">
					<img src="assets/icons/sort-icon.svg" alt="" />
					<span>Sort</span>
				</div>
				<div class="filter-btn header-btns" mat-button [matMenuTriggerFor]="filter" matTooltipClass="tooltipClass" [matTooltip]="'Filter Products'">
					<img src="assets/icons/filter-table.svg" alt="" />
					<span>Filters</span>
				</div>
				<!-- <div class="download-btn header-btns" mat-button [matMenuTriggerFor]="download" matTooltipClass="tooltipClass" [matTooltip]="'Download/Upload'">
					<img src="assets/icons/download-icon.svg" alt="" />
				</div> -->
				<mat-menu #filter="matMenu">
					<div class="filter-box" (click)="$event.stopPropagation()">
						<p>Product Type</p>
						<app-custom-drpdown-op1
							[data]="types"
							(click)="$event.stopPropagation()"
							[dropdownFormControl]="controlA"
							[useCustom]="false"
							(selectionChange)="getFilteredProducts('product_type', $event)"
							[placeHolder]="'Select'"
							[noError]="true"
						></app-custom-drpdown-op1>
						<p>Vendor</p>
						<app-custom-drpdown-op1
							[data]="vendor"
							(click)="$event.stopPropagation()"
							[dropdownFormControl]="controlB"
							[useCustom]="false"
							(selectionChange)="getFilteredProducts('product_vendor', $event)"
							[placeHolder]="'Select'"
							[noError]="true"
						></app-custom-drpdown-op1>
						<p>Tags</p>
						<app-custom-drpdown-op1
							(click)="$event.stopPropagation()"
							[data]="tags"
							(selectionChange)="getFilteredProducts('collection_id', $event)"
							[useCustom]="false"
							[dropdownFormControl]="controlC"
							[placeHolder]="'Select'"
							[noError]="true"
						></app-custom-drpdown-op1>
						<app-button style="width: 100%;" buttonClass="secondary" (onClick)="clearFilters()">Clear All</app-button>
					</div>
				</mat-menu>
				<mat-menu #sort="matMenu">
					<button mat-menu-item class="sort-btns" (click)="sortProducts('relevance')">Relevance</button>
					<button mat-menu-item class="sort-btns" (click)="sortProducts('desc')">Latest Created</button>
					<button mat-menu-item class="sort-btns" (click)="sortProducts('asc')">Earliest Created</button>
				</mat-menu>
				<mat-menu #download="matMenu">
					<button mat-menu-item style="display: flex; align-items: center;" (click)="bulkDownload()">
						<img style="width: 15px; margin-right: 17px;" src="assets/icons/download-icon.svg" alt="" />
						Download CSV File
					</button>
					<button mat-menu-item style="display: flex; align-items: center;" (click)="bulkUpload()">
						<img style="width: 15px; margin-right: 17px;" src="assets/icons/upload-icon.svg" alt="" />
						Upload CSV
					</button>
				</mat-menu>
			</div>
			<div class="helper-head">
				<!-- <p class="selectall-btn" (click)="checkAll()">{{ selectionText }}</p> -->
				<p class="product-count">Products - {{ products?.length }}</p>
			</div>
		</div>
		<div class="products-container">
			<div class="products-wrapper" *ngIf="isMobile">
				<form [formGroup]="radioForm">
					<shakespeare-spinning-loader class="spinner" *ngIf="productsLoading"></shakespeare-spinning-loader>
					<div class="card-con">
						<div class="product-card" (click)="selectItem(item, i)" *ngFor="let item of products | paginate: config; let i = index">
							<div class="header">
								<div class="title">
									<!-- <input type="checkbox" formControlName="radio{{ i }}" /> -->
									<div class="product">
										<img [src]="(item?.images)[0]" alt="" />
										<div class="dot" [ngClass]="{ red: item?.optimizationScore == 0 }"></div>
									</div>
									<p (click)="openEditor(item?.id)">{{ item?.title }}</p>
								</div>
								<div class="edit-view">
									<span class="fas fa-pen"></span>
									<img src="assets/icons/icon-eye.svg" alt="" />
								</div>
							</div>
							<div class="bottom">
								<div class="left">
									<p class="score">Optimization Score</p>
									<p class="value">{{ item?.optimizationScore }}</p>
								</div>
								<div class="right">
									<p class="score">Inventory</p>
									<p class="value">{{ item?.sku }}</p>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="table-wrapper" *ngIf="isTablet">
				<form [formGroup]="radioForm">
					<table>
						<thead>
							<tr>
								<th></th>
								<th>Products - {{ products?.length }}</th>
								<th class="with-tooltip">
									Optimization Score
									<app-tooltip text="Optimization Score for shopify product"></app-tooltip>
								</th>
								<th>Preview</th>
								<th>Inventory</th>
							</tr>
						</thead>
						<tbody *ngIf="products?.length">
							<tr *ngFor="let item of products | paginate: config; let i = index" [ngClass]="{ active: selectedItemIndex == i }">
								<td>
									<div class="dot" [ngClass]="{ red: item?.optimizationScore == 0 }"></div>
								</td>
								<td (click)="selectItem(item, i)">
									<div class="product">
										<img [src]="(item?.images)[0]" alt="" />
										<p>{{ item?.title }}</p>
									</div>
								</td>
								<td>{{ item?.optimizationScore }}</td>
								<td>
									<img (click)="previewProduct(item)" src="assets/icons/icon-eye.svg" alt="" />
								</td>
								<td>{{ item?.sku }}</td>
							</tr>
						</tbody>
					</table>
					<div class="footer" *ngIf="count">
						<app-button buttonClass="tertiary" (onClick)="clearSelected()" icon="fas fa-times">{{ count }} Selected</app-button>
						<app-button buttonClass="primary" (onClick)="generateDescription()">Generate Descriptions</app-button>
					</div>
				</form>
			</div>
			<div class="has-text-centered" *ngIf="products?.length > 0">
				<shakespeare-spinning-loader class="spinner" *ngIf="loading"></shakespeare-spinning-loader>
				<!-- <pagination-controls previousLabel="" nextLabel="" (pageChange)="getLibraryData($event)" id="server"></pagination-controls> -->
				<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
					<div class="custom-pagination">
						<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
							<a (click)="p.previous()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/left-icon.svg" alt="" /></a>
						</div>
						<div *ngFor="let page of p.pages" class="page" [class.current]="p.getCurrent() === page.value">
							<a (click)="getData(page.label)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
								<span>{{ page.label }}</span>
							</a>
							<div *ngIf="p.getCurrent() === page.value">
								<span>{{ page.label }}</span>
							</div>
						</div>
						<div class="pagination-next" [class.disabled]="p.isLastPage()">
							<a (click)="p.next()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/right-icon.svg" alt="" /></a>
						</div>
					</div>
				</pagination-template>
			</div>
		</div>
	</div>
</div>
