import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { UserRoleClientGuard } from './shared/guards/user-role-client-guard.service';
import { NoResourceComponent } from './shared/404/no-resource.component';
import { KnowledgeHomeComponent } from './shared/knowledge-base/component-pieces/knowledge-home/knowledge-home.component';
import { KnowledgeSearchComponent } from './shared/knowledge-base/component-pieces/knowledge-search/knowledge-search.component';
import { SubmitTicketComponent } from './shared/knowledge-base/component-pieces/submit-ticket/submit-ticket.component';
import { TicketsViewComponent } from './sidenav/tickets-view/tickets-view.component';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { SidenavGroupComponent } from './sidenav/sidenav/sidenav-group.component';
import { ActionImpactAnalysisComponent } from './shakespeare/OptimizationAI/action-impact-analysis/action-impact-analysis.component';
import { PerformanceInsightsComponent } from './shakespeare/OptimizationAI/performance-insights/performance-insights.component';
import { WelcomeShakespeareFormComponent } from './shakespeare/components/welcome-shakespeare-form/welcome-shakespeare-form.component';

const routes: Routes = [
	{ path: 'welcome-shakespeare-form', component: WelcomeShakespeareFormComponent },
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
	},
	// {
	// 	path: 'ads/image-editor',
	// 	loadChildren: () => import('./shakespeare/ads/creative-studio/project/image-editor/image-editor.module').then(m => m.ImageEditorModule)
	// },
	{
		path: '',
		component: SidenavGroupComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{
				path: '',
				canActivate: [UserBusinessOwnerGuard],
				loadChildren: () => import('./shakespeare/shakespeare.module').then(m => m.ShakespeareModule)
			},
			// {
			// 	path: '',
			// 	canActivate: [UserBusinessOwnerGuard],
			// 	loadChildren: () => import('./shakespeare/OptimizationAI/optimization-ai.module').then(m => m.OptimizationAiModule)
			// },
			{ path: 'support-tickets', canActivate: [UserRoleClientGuard], component: TicketsViewComponent }
		]
	},
	{
		path: 'home',
		pathMatch: 'full',
		canActivate: [AuthenticationGuard],
		component: KnowledgeHomeComponent,
		outlet: 'knowledge-base-modal'
	},
	{
		path: 'search',
		pathMatch: 'full',
		canActivate: [AuthenticationGuard],
		component: KnowledgeSearchComponent,
		outlet: 'knowledge-base-modal'
	},
	{
		path: 'submit-ticket',
		pathMatch: 'full',
		canActivate: [AuthenticationGuard],
		component: SubmitTicketComponent,
		outlet: 'knowledge-base-modal'
	},
	// { path: 'ActionImpactAnalysis', component: ActionImpactAnalysisComponent },
	// { path: 'PerformanceInsights', component: PerformanceInsightsComponent },
	{ path: '404', component: NoResourceComponent },
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
