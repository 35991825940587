import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditorModeEnum } from '../../documents/document-interface';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';

import { UserServiceApi } from '../../../_services/user/user.api.service';
import * as QuillEditor from 'quill';
import * as Grammarly from '@grammarly/editor-sdk';
import { fromEvent, Subject, Subscription, timer, BehaviorSubject } from 'rxjs';
import Quill from 'quill';

@Component({
	selector: 'app-custom-text-editor',
	templateUrl: './custom-text-editor.component.html',
	styleUrls: ['./custom-text-editor.component.scss']
})
export class CustomTextEditorComponent implements OnInit, AfterViewInit {
	@ViewChild('suggestTextWrapper') public suggestTextWrapper: ElementRef;
	@ViewChild('editorOutput', { static: false }) editorElementRef: any;
	@ViewChild('editorControl', { static: false }) editorControl: ElementRef;
	@ViewChild('toolbar', { static: false }) toolbarElementRef: any;
	@Input() public showGrammar: any;
	@Input() public writingText = false;
	@Input() public editorMode: EditorModeEnum;
	@Input() public suggestText: string = '';
	@Input() public filedId: number;
	@Input() public isGeneratingOutput: boolean = false;
	@Input() public showAiButton: boolean = false;
	@Input() public selectedOutputTone: string;
	@Input() public showBackButton: boolean = false;
	@Input() public sendTextToEditor: BehaviorSubject<any>;
	@Input() public deleteAllText: BehaviorSubject<any>;
	@Output() public onSaveDocument: EventEmitter<any> = new EventEmitter<any>();
	@Output() public getTextToEditorSubscriber: EventEmitter<any> = new EventEmitter<any>();
	@Output() public editorContainer: EventEmitter<any> = new EventEmitter();
	@Output() public onAutoGenerate: EventEmitter<any> = new EventEmitter();
	@Output() public getEditorControl: EventEmitter<any> = new EventEmitter();
	@Output() public onWritingStart: EventEmitter<boolean> = new EventEmitter();
	@Output() public onWritingEnd: EventEmitter<boolean> = new EventEmitter();
	@Output() public goBackHandler: EventEmitter<boolean> = new EventEmitter();
	public canActivateGrammary = false;
	public editorModeEnum = EditorModeEnum;
	public editorOutput: any;
	public grammarStyle = '';
	public scrWidth: number;
	public changeTimer$: Subscription;
	public shakespeareButtonStyle: string;
	@Input() public aiSelectionProp = { start: 0, end: 0, text: '' };
	private selectionProps: { range: any; oldRange: any; source: any };
	private unsubscriber$ = new Subject<void>();
	private cursorPosition = 0;
	public isMobile: boolean = false;
	public shortKeyName: string = 'CTRL';
	private iterator = 0;
	private typerText: string = '';
	public isGrammarlyActive: boolean = false;
	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}
	constructor(public toastNotificationService: ToastNotificationService, private userServiceApi: UserServiceApi) {}

	ngOnInit(): void {
		const icons = Quill.import('ui/icons');
		icons[
			'redo'
		] = `<svg width="24" height="24" focusable="false"><path d="M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 1 1-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 1 1 1.4-1.4l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l3.3-3.3Z" fill-rule="nonzero"></path></svg>`;
		icons[
			'undo'
		] = `<svg width="24" height="24" focusable="false"><path d="M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 0 1-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 1 1-1.4 1.4l-5-5a1 1 0 0 1 0-1.4l5-5a1 1 0 0 1 1.4 1.4L6.4 8Z" fill-rule="nonzero"></path></svg>`;
	}
	public ngAfterViewInit(): void {
		this.shortKeyName = navigator.userAgent.match('Macintosh') ? 'CMD' : 'CTRL';
		this.isMobile = this.scrWidth <= 900;
		const icons = Quill.import('ui/icons');
		icons[
			'redo'
		] = `<svg width="24" height="24" focusable="false"><path d="M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 1 1-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 1 1 1.4-1.4l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l3.3-3.3Z" fill-rule="nonzero"></path></svg>`;
		icons[
			'undo'
		] = `<svg width="24" height="24" focusable="false"><path d="M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 0 1-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 1 1-1.4 1.4l-5-5a1 1 0 0 1 0-1.4l5-5a1 1 0 0 1 1.4 1.4L6.4 8Z" fill-rule="nonzero"></path></svg>`;
		this.initializeEditor();
		this.getTextToEditorSubscriber.emit(this.sendTextToEditor);
		this.editorOutput.keyboard.addBinding({
			key: 'B',
			shortKey: true,
			handler: function (range, context) {}
		});
		this.editorOutput.keyboard.addBinding({
			key: 'S',
			shortKey: true,
			handler: (range, context) => {
				this.onAutoGenerate.emit();
			}
		});
		let saveObservable: Subscription;
		this.editorOutput.on('text-change', (delta, oldDelta, source) => {
			this.cursorPosition = delta?.ops[0].retain ?? 0;
			this.showAiButtonR();
			saveObservable?.unsubscribe();
			saveObservable = timer(2000).subscribe(res => {
				this.onSaveDocument.emit();
			});
			if (this.cursorPosition >= this.aiSelectionProp.start && this.cursorPosition < this.aiSelectionProp.end) {
				this.resetAiSelectionProp();
			}
		});
		this.editorOutput.on('selection-change', (range, oldRange, source) => {
			if (range) {
				this.selectionProps = { range, oldRange, source };
				this.cursorPosition = range.index;
				this.showAiButtonR();
			} else {
			}
		});
		const scroll = fromEvent(this.editorOutput?.root?.offsetParent, 'scroll').subscribe(event => {
			this.showAiButton = false;
		});
		this.sendTextToEditor?.subscribe(res => {
			if (res) {
				if (res?.aiEditor) {
					this.typeWriter(res?.text);
				} else {
					this.presentEditorText(res?.text);
				}
			}
		});
		this.deleteAllText?.subscribe(res => {
			if (res) {
				this.clearTextEditor();
			}
		});
	}

	private initializeEditor(): void {
		const editorHtmlRef = this.editorElementRef.nativeElement;
		this.editorOutput = new QuillEditor.default(editorHtmlRef, {
			placeholder: 'Begin writing on your own...',
			modules: {
				toolbar: {
					container: this.toolbarElementRef.nativeElement,
					handlers: {
						undo: () => {
							this.editorOutput?.history?.undo();
						},
						redo: () => {
							this.editorOutput?.history?.redo();
						}
					}
				},
				history: {
					userOnly: false
				}
			},
			scrollingContainer: '#editorOutput',
			theme: 'snow'
		});
		this.getEditorControl.emit(this.editorOutput);
	}

	goBack(): void {
		this.goBackHandler.emit(true);
	}

	public showAiButtonR(): void {
		this.changeTimer$?.unsubscribe();
		this.showAiButton = false;
		this.changeTimer$ = timer(3000).subscribe(res => {
			const position = this.editorOutput.getBounds(this.editorOutput.getSelection()?.index ?? this.editorOutput.getText().length);
			const space = this.scrWidth <= 900 ? '1px' : '72px';
			const spaceVertical = this.scrWidth <= 900 ? `calc( 29px + ${position.top}px)` : `calc( 72px + ${position.top}px)`;
			this.shakespeareButtonStyle = `top: ${spaceVertical}; position: absolute; left: calc( ${space} + ${position.left}px); right: ${position.right}px; bottom: auto`;
			this.showAiButton = true;
		});
	}
	public resetAiSelectionProp(): void {
		this.aiSelectionProp = { start: 0, end: 0, text: '' };
	}
	public cancelGrammary(): void {
		this.canActivateGrammary = false;
		this.selectionProps = null;
		this.showGrammar = false;
		this.suggestText = null;
		this.grammarStyle = '';
	}
	public clearTextEditor(): void {
		this.editorOutput.deleteText(0, this.editorOutput.getText().length, 'user');
	}
	public writingEnd(): void {
		this.iterator = 0;
		this.writingText = false;
		this.editorOutput.focus();
		this.aiSelectionProp.end = this.editorOutput.getSelection().index ?? 0;
		this.onWritingEnd.emit(true);
	}
	public writingStart(): void {
		this.iterator = 0;
		this.writingText = true;
		this.onWritingStart.emit(true);
	}

	public presentEditorText(typerText: string): void {
		const newCursorPosition = this.getCursorPosition();
		this.editorOutput.clipboard.dangerouslyPasteHTML(newCursorPosition, typerText);
		this.editorOutput.setSelection(this.getCursorPosition());
	}

	private getCursorPosition(): number {
		const selection = this.editorOutput.getSelection();
		const previousText = this.editorOutput.getText();
		return selection?.index ?? this.cursorPosition ?? previousText.length;
	}
	public typeWriter(documentText: string): void {
		if (this.iterator === 0) {
			this.writingStart();
			this.resetAiSelectionProp();
			this.writingText = true;
			this.aiSelectionProp.start = this.getCursorPosition();
		}
		if (this.iterator < documentText.length) {
			if (documentText.charAt(this.iterator) == '<') {
				// adding '<p></p>' to break line
				this.presentEditorText('<p> </p> <p> </p>');
				this.iterator += 3;
			} else if (documentText.charAt(this.iterator) == ' ') {
				this.presentEditorText('<span> </span>');
			} else {
				this.typerText = documentText.charAt(this.iterator);
				this.presentEditorText(this.typerText);
			}
			this.iterator++;
			setTimeout(() => {
				this.typeWriter(documentText);
			}, 0.001);
		} else {
			setTimeout(() => {
				this.writingEnd();
			}, 200);
		}
	}

	disableEvent(event: any) {
		if (this.writingText || this.isGeneratingOutput) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	toggleGrammarly() {
		const oldText = this.editorOutput?.root?.innerHTML;
		this.isGrammarlyActive = !this.isGrammarlyActive;
		if (this.isGrammarlyActive) {
			Grammarly.init('client_AFS44LxHpGEa3ZrLczhqD5').then((grammarly: any) => {
				const editor = document.getElementsByClassName('ql-editor').item(0);
				grammarly.addPlugin(editor);
				document.querySelectorAll('grammarly-editor-plugin').forEach((grammarlyEditor: any) => {
					grammarlyEditor.config = {
						autocomplete: 'on',
						documentDialect: 'british',
						showToneDetector: true,
						suggestionCards: 'on',
						toneDetector: 'on',
						underlines: 'on',
						userFeedback: 'on'
					};
				});
			});
		} else {
			document.querySelectorAll('grammarly-editor-plugin').forEach((grammarlyEditor: any) => {
				grammarlyEditor.disconnect();
			});
		}
		setTimeout(() => {
			this.showAiButtonR();
		}, 2);
	}
}
