<div class="all-products-container" [ngClass]="{ expanded: menuExpanded }">
	<div class="mobile-head">
		<app-button (onClick)="goBack()" class="back-button" buttonClass="icon" icon="fas fa-chevron-left"></app-button>
		<img src="assets/icons/nav-menu/shopify.svg" alt="" />
		<p>{{ pageTitle }}</p>
	</div>
	<div class="width-controller">
		<div class="head">
			<div class="search mobile">
				<!-- <input type="text" (input)="searchTemplate($event.target.value)" placeholder="Search by name" />
				<em class="fas fa-search icon-search"></em>
				(keyup)="onSearchEnter($event)" -->
				<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
			</div>
			<div class="left">
				<app-button (onClick)="goBack()" class="back-button" buttonClass="icon" icon="fas fa-chevron-left"></app-button>
				<img src="assets/icons/nav-menu/shopify.svg" alt="" />
				<p>{{ pageTitle }}</p>
			</div>
			<div class="right">
				<input
					type="file"
					style="visibility: hidden; width: 0; position: absolute;"
					#fileRef
					(click)="$event.target.value = null"
					accept=".csv"
					(change)="uploadFile($event)"
				/>
				<div class="refresh-btn header-btns" (click)="getAllProducts()" matTooltipClass="tooltipClass" [matTooltip]="'Sync Products'">
					<img src="assets/icons/refresh-icon.svg" alt="" />
				</div>
				<div
					*ngIf="!history && !newlyGenerated"
					class="sort-btn header-btns"
					mat-button
					[matMenuTriggerFor]="sort"
					matTooltipClass="tooltipClass"
					[matTooltip]="'Sort Products'"
				>
					<img src="assets/icons/sort-icon.svg" alt="" />
					<span>Sort</span>
				</div>
				<div
					*ngIf="!history && !newlyGenerated"
					class="filter-btn header-btns"
					mat-button
					[matMenuTriggerFor]="filter"
					matTooltipClass="tooltipClass"
					[matTooltip]="'Filter Products'"
				>
					<img src="assets/icons/filter-table.svg" alt="" />
					<span>Filters</span>
				</div>
				<div
					*ngIf="newlyGenerated"
					class="download-btn header-btns"
					mat-button
					[matMenuTriggerFor]="download"
					matTooltipClass="tooltipClass"
					[matTooltip]="'Download/Upload'"
				>
					<img src="assets/icons/download-icon.svg" alt="" />
				</div>
				<div class="search">
					<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
				</div>
				<div class="expanded" *ngIf="isExpanded" (click)="toEditor()">
					<img src="assets/icons/un-expand.svg" alt="" />
				</div>
				<mat-menu #filter="matMenu">
					<div class="filter-box" (click)="$event.stopPropagation()">
						<p>Product Type</p>
						<app-custom-drpdown-op1
							[data]="types"
							(click)="$event.stopPropagation()"
							[dropdownFormControl]="controlA"
							[useCustom]="false"
							(selectionChange)="getFilteredProducts('product_type', $event)"
							[placeHolder]="'Select'"
							[noError]="true"
						></app-custom-drpdown-op1>
						<p>Vendor</p>
						<app-custom-drpdown-op1
							[data]="vendor"
							(click)="$event.stopPropagation()"
							[dropdownFormControl]="controlB"
							[useCustom]="false"
							(selectionChange)="getFilteredProducts('product_vendor', $event)"
							[placeHolder]="'Select'"
							[noError]="true"
						></app-custom-drpdown-op1>
						<p>Tags</p>
						<app-custom-drpdown-op1
							(click)="$event.stopPropagation()"
							[data]="tags"
							(selectionChange)="getFilteredProducts('collection_id', $event)"
							[useCustom]="false"
							[dropdownFormControl]="controlC"
							[placeHolder]="'Select'"
							[noError]="true"
						></app-custom-drpdown-op1>
						<app-button style="width: 100%;" buttonClass="secondary" (onClick)="clearFilters()">Clear All</app-button>
					</div>
				</mat-menu>
				<mat-menu #sort="matMenu">
					<button mat-menu-item class="sort-btns" (click)="sortProducts('relevance')">Relevance</button>
					<button mat-menu-item class="sort-btns" (click)="sortProducts('desc')">Latest Created</button>
					<button mat-menu-item class="sort-btns" (click)="sortProducts('asc')">Earliest Created</button>
				</mat-menu>
				<mat-menu #download="matMenu">
					<button mat-menu-item style="display: flex; align-items: center;" (click)="bulkDownload()">
						<img style="width: 15px; margin-right: 17px;" src="assets/icons/download-icon.svg" alt="" />
						Download CSV File
					</button>
					<button mat-menu-item style="display: flex; align-items: center;" (click)="triggerFileUpload()">
						<img style="width: 15px; margin-right: 17px;" src="assets/icons/upload-icon.svg" alt="" />
						Upload CSV
					</button>
				</mat-menu>
			</div>
		</div>
		<section class="app-table">
			<app-product-table
				*ngIf="!loading && !history && !newlyGenerated"
				[products]="allProducts"
				(bulkPayload)="confirmBulkProcess($event)"
				(pageNumber)="handlePagination($event)"
			></app-product-table>
			<app-product-history *ngIf="!loading && history" [products]="allProducts" (pageNumber)="handlePagination($event)"></app-product-history>
			<app-newly-generated
				*ngIf="!loading && newlyGenerated"
				[products]="allProducts"
				(bulkPayload)="confirmBulkProcess($event)"
				(pageNumber)="handlePagination($event)"
			></app-newly-generated>
			<div *ngIf="loading" [ngStyle]="{ width: '100%' }">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
				<div *ngIf="bulkLoading" class="bulk-loader">
					<p class="p1">Please wait..., Ai is generating your descriptions.</p>
					<p>This process can take 10 to 15 minutes. We will notify you when it will complete. You can take a tour of Shakespeare features.</p>
					<app-button buttonClass="primary" (onClick)="takeTour()">Go To DashBoard</app-button>
				</div>
			</div>
		</section>
		<ng-template #noProducts>
			No data found
		</ng-template>
		<section class="app-cards">
			<app-product-card
				*ngIf="!loading"
				(bulkPayload)="confirmBulkProcess($event)"
				[products]="allProducts"
				(pageNumber)="handlePagination($event)"
			></app-product-card>
			<div *ngIf="loading" [ngStyle]="{ width: '100%' }">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
				<div *ngIf="bulkLoading" class="bulk-loader">
					<p class="p1">Please wait..., Ai is generating your descriptions.</p>
					<p>This process can take 10 to 15 minutes. We will notify you when it will complete. You can take a tour of Shakespeare features.</p>
					<app-button buttonClass="primary" (onClick)="takeTour()">Go To DashBoard</app-button>
				</div>
			</div>
		</section>
	</div>
</div>
