<div class="date-selector-wrapper" (click)="update()" *ngIf="isReload">
	<input
		type="text"
		class="date-inner-input"
		ngxDaterangepickerMd
		[(ngModel)]="selected"
		[showCustomRangeLabel]="false"
		[alwaysShowCalendars]="true"
		[ranges]="ranges"
		[linkedCalendars]="false"
		[showClearButton]="true"
		(datesUpdated)="datesUpdated($event)"
		placeholder="Please select date"
		[maxDate]="currentDate.toISOString()"
		[locale]="{ applyLabel: 'Apply', direction: 'ltr', clearLabel: 'Cancel' }"
		[autoApply]="false"
	/>

	<img src="assets/icons/calendarTime.png" alt="Icon" class="calender-align" />
</div>
