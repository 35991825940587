import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationActionEnum } from 'src/app/shared/models/notifications.model';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
	selector: 'app-notifications-dialog',
	templateUrl: './notifications-dialog.component.html',
	styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {
	@Input() notifications: any[] = [];
	// @Input() unreadCount: number = 0;
	// @Output() updateUnreadCount = new EventEmitter<number>();
	private unsubscriber$ = new Subject<void>();
	public isMenuOpened: boolean = false;
	public unreadCount: number = 0;

	constructor(public router: Router, public notificationService: NotificationsService) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	ngOnChanges(): void {
		this.updateUnreadCount();
	}

	public updateUnreadCount(): void {
		this.unreadCount = this.notifications?.filter(i => i.unreadRead).length;
		// console.log('unread count', this.unreadCount);
	}

	public menuOpened(): void {
		this.isMenuOpened = true;
	}

	public menuClosed(): void {
		this.isMenuOpened = false;
	}

	public routeToNotifications(): void {
		localStorage.setItem('notifications', JSON.stringify(this.notifications));
		this.router.navigate(['notifications']);
	}

	// public onNotificationActionClick(notification: any): void {
	//   this.implementAction(notification);
	// }

	// public implementAction(notification: any): void {
	//   switch (notification.type) {
	//     case NotificationActionEnum.BRAND_MESSAGE_RECEIVED:
	//       this.router.navigate(['messages'], { queryParams: { creatorId: notification.created_by } });
	//       break;
	//     case NotificationActionEnum.BRAND_EMAIL_RECEIVED:
	//       this.router.navigate(['messages'], { queryParams: { emailCreatorId: notification.created_by } });
	//       break;
	//     case NotificationActionEnum.BRAND_NOTIFY_PENDING_PAYMENT:
	//     case NotificationActionEnum.BRAND_PAYMENT_SUCCESSFUL:
	//     case NotificationActionEnum.BRAND_PAYMENT_FAILED:
	//       this.router.navigate(['payments']);
	//       break;
	//     case NotificationActionEnum.BRAND_JOB_ACCEPTED:
	//     case NotificationActionEnum.BRAND_JOB_DECLINED:
	//     case NotificationActionEnum.BRAND_JOB_APPLIED:
	//       this.router.navigate(['marketplace', notification.resource_id, 'view']);
	//       break;
	//     case NotificationActionEnum.BRAND_CREATIVE_UPLOADED:
	//     case NotificationActionEnum.BRAND_POST_UPLOADED:
	//       this.router.navigate(['creatives/view', notification.resource_id]);
	//       break;
	//     case NotificationActionEnum.BRAND_CRM_EMAIL_SENT:
	//       this.router.navigate(['outreach']);
	//       break;
	//     case NotificationActionEnum.BRAND_CAMPAIGN_LIMIT:
	//     case NotificationActionEnum.BRAND_JOB_LIMIT_PER_CAMPAIGN:
	//     case NotificationActionEnum.BRAND_INFLUENCER_LIMIT_PER_JOB:
	//     case NotificationActionEnum.BRAND_DISCOVERY_CREDIT_FINISH:
	//       this.router.navigate(['user-management/payment-and-billing']);
	//       break;
	//     case NotificationActionEnum.BRAND_THIRD_PARTY_CONNECTION:
	//       this.router.navigate(['campaign/setupIntegration']);
	//       break;
	//     case NotificationActionEnum.BRAND_EMAIL_CONNECTED:
	//       this.router.navigate(['user-management/integrations']);
	//       break;
	//     case NotificationActionEnum.BRAND_SUBUSER_ACCEPTED_INVITE:
	//       this.router.navigate(['user-management/teams']);
	//       break;
	//   }
	// }
}
