export enum ButtonClassEnum {
	Primary = 'primary',
	Secondary = 'secondary',
	Tertiary = 'tertiary',
	Gray = 'gray',
	LinkPrimary = 'link-primary',
	LinkSecondary = 'link-secondary',
	LinkTertiary = 'link-tertiary',
	Icon = 'icon',
	IconPrimary = 'icon-primary',
	IconSecondary = 'icon-secondary',
	IconTertiary = 'icon-tertiary',
	Reverse = 'reverse',
	FullWidthPrimaryBtn = 'fullWidthPrimaryBtn'
}
