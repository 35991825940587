import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountTypeService } from 'src/app/shared/account-type.service';

@Component({
	selector: 'app-tickets-view',
	templateUrl: './tickets-view.component.html',
	styleUrls: ['./tickets-view.component.scss']
})
export class TicketsViewComponent implements OnInit {
	public todayDate: Date = new Date();

	constructor(public router: Router, public accountService: AccountTypeService) {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {}
}
