import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { environment } from '../../../environments/environment';
import { buildGeneratedValues } from '../../../../build-generated-values';
import { Subject, Subscription, combineLatest, interval, of } from 'rxjs';
import { ChannelButton } from './sidenav-button-channel.model';
import { AccountTypeService } from '../../shared/account-type.service';
import { select, Store } from '@ngrx/store';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { getFiledId, hasBackOfficePermission, UserState } from '../../shared/state/user/user.reducer';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { UserDetails } from '../../_models/identity-models/identity-detail';
import { StorageKey } from '../../_models/local-storage-key';
import { Modules } from '../../shared/permisions/enums/modules';
import { PermissionsDirectiveInterface } from '../../shared/permisions/models/permissions-directive.interface';
import { BackofficePermissions } from '../../shared/permisions/enums/backoffice-permissions';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { HideGlobalSpinner } from '../../shared/state/shared.actions';
import { ScrollService } from '../../_services/scroll.service';
import { KnowledgebaseComponent } from '../component-pieces/knowledgebase/knowledgebase.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserRejectedReason } from 'src/app/_services/user/user-rejected-reason.enum';
import { IMainMenuGroup, IProductMenuGroup, IProductSubMenuGroup } from '../_models/nav-group.interface';
import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { RegisterUserPlan } from 'src/app/state/shakespeare-common-state/shakespeare-common-action';
import { UserPlanState } from 'src/app/state/shakespeare-common-state/shakespeare-common-reducer';
import { FirstUserService } from '../../shakespeare/first-time-user/models/first-user.service';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';
import { KonvaService } from 'src/app/shakespeare/ads/creative-studio/project/image-editor/services/konva.service';
import { OptiomizationAiService } from 'src/app/shakespeare/OptimizationAI/optiomization-ai.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
// import { ShepherdService } from 'angular-shepherd';
// import { steps as defaultSteps, defaultStepOptions } from './tour-popup-config';
@Component({
	selector: 'app-sidenav-group',
	templateUrl: './sidenav-group.component.html',
	styleUrls: ['./sidenav-group.component.scss'],
	providers: [ScrollService]
})
export class SidenavGroupComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav') public sidenav: MatSidenav;
	@ViewChild('menuTrigger') public trigger: MatMenuTrigger;

	@ViewChild('settingsPopup') settingsPopup: ElementRef;
	@ViewChild('settingsIcon') settingsIcon: ElementRef;
	@ViewChild('mainSection') mainSection: ElementRef;
	@ViewChild('headerLogo') headerLogo: ElementRef;
	activateOverviewHeader: boolean = false;
	public routeName = 'Home';
	public showToolbar = false;
	public firstTimeUser = false;
	public mainMenuGroup = new Map<number, IMainMenuGroup>();
	companyDetails: any;
	userDetailsData: any;
	noSideMenu = false;
	public productMenuGroup: Map<number, IProductMenuGroup> = new Map([
		[
			1,
			{
				parentName: 'shakespeare',
				name: 'home',
				title: 'Home',
				icon: 'assets/icons/nav-menu/dashboard.svg',
				isSelected: true,
				hasChildren: false,
				hoverOver: false,
				childOpen: false,
				route: '',
				childIndex: 0
			}
		],
		[
			2,
			{
				parentName: 'shakespeare',
				name: 'templates',
				title: 'Templates',
				icon: 'assets/icons/nav-menu/templates_icon.svg',
				isSelected: false,
				hasChildren: true,
				hoverOver: false,
				childOpen: false,
				route: '/templates',
				childIndex: 0
			}
		],
		[
			3,
			{
				parentName: 'shakespeare',
				name: 'projects',
				title: 'Projects',
				icon: 'assets/icons/nav-menu/projects_icon.svg',
				isSelected: false,
				hasChildren: true,
				hoverOver: false,
				childOpen: false,
				route: '/projects',
				childIndex: 0
			}
		],
		[
			4,
			{
				parentName: 'shakespeare',
				name: 'library',
				title: 'Library',
				icon: 'assets/icons/nav-menu/library_icon.svg',
				isSelected: false,
				hasChildren: true,
				hoverOver: false,
				childOpen: false,
				route: '/library',
				childIndex: 0
			}
		],
		[
			5,
			{
				parentName: 'shakespeare',
				name: 'Documents',
				title: 'Documents',
				icon: 'assets/icons/nav-menu/document.svg',
				isSelected: false,
				hasChildren: false,
				hoverOver: false,
				childOpen: false,
				route: '/document',
				childIndex: 0
			}
		],
		[
			6,
			{
				parentName: 'shakespeare',
				name: 'AI-Creative',
				title: 'AI Creative',
				icon: 'assets/icons/nav-menu/creative-outline.svg',
				isSelected: false,
				hasChildren: false,
				hoverOver: false,
				childOpen: false,
				route: '/creative',
				childIndex: 0
			}
		],
		[
			7,
			{
				parentName: 'shakespeare',
				name: 'Settings',
				title: 'Settings',
				icon: 'assets/icons/nav-menu/settings.svg',
				isSelected: false,
				hasChildren: false,
				hoverOver: false,
				childOpen: false,
				route: '/settings/index',
				childIndex: 0
			}
		],
		[
			8,
			{
				parentName: 'shakespeare',
				name: 'Settings',
				title: 'Settings',
				icon: 'assets/icons/nav-menu/settings.svg',
				isSelected: false,
				hasChildren: false,
				hoverOver: false,
				childOpen: false,
				route: '/settings',
				childIndex: 0
			}
		]
	]);
	public isMobile = false;

	public productSubMenuGroup: Map<number, IProductSubMenuGroup> = new Map([
		[
			1,
			{
				parentName: 'templates',
				name: 'all-templates',
				title: 'All Templates',
				icon: 'assets/icons/nav-menu/templates_icon.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			2,
			{
				parentName: 'templates',
				name: 'ad-copy',
				title: 'Ad Copy',
				icon: 'assets/icons/nav-menu/submenu-icons/ad-copy.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			3,
			{
				parentName: 'templates',
				name: 'email',
				title: 'Email',
				icon: 'assets/icons/nav-menu/submenu-icons/email.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			4,
			{
				parentName: 'templates',
				name: 'product-description',
				title: 'Product Description',
				icon: 'assets/icons/nav-menu/submenu-icons/product-description.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			5,
			{
				parentName: 'templates',
				name: 'blogs',
				title: 'Blogs',
				icon: 'assets/icons/nav-menu/submenu-icons/blogs.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			6,
			{
				parentName: 'templates',
				name: 'content-improver',
				title: 'Content Improver',
				icon: 'assets/icons/nav-menu/submenu-icons/content-improver.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			7,
			{
				parentName: 'templates',
				name: 'social',
				title: 'Social',
				icon: 'assets/icons/nav-menu/submenu-icons/social.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			8,
			{
				parentName: 'templates',
				name: 'frameworks',
				title: 'Frameworks',
				icon: 'assets/icons/nav-menu/submenu-icons/frameworks.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		// [
		// 	9,
		// 	{
		// 		parentName: 'templates',
		// 		name: 'seo',
		// 		title: 'SEO',
		// 		icon: 'assets/icons/nav-menu/submenu-icons/seo.svg',
		// 		isSelected: false,
		// 		hoverOver: false,
		// 		route: '/templates'
		// 	}
		// ],
		[
			10,
			{
				parentName: 'templates',
				name: 'video',
				title: 'Video',
				icon: 'assets/icons/nav-menu/submenu-icons/video.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			11,
			{
				parentName: 'projects',
				name: 'projects',
				title: 'Projects',
				icon: 'assets/icons/nav-menu/projects_icon.svg',
				isSelected: false,
				hoverOver: false,
				route: '/projects'
			}
		],
		[
			12,
			{
				parentName: 'projects',
				name: 'folders',
				title: 'Folders',
				icon: 'assets/folder.svg',
				isSelected: false,
				hoverOver: false,
				route: '/projects'
			}
		],
		[
			13,
			{
				parentName: 'templates',
				name: 'bio',
				title: 'Bio',
				icon: 'assets/icons/nav-menu/submenu-icons/bio.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			14,
			{
				parentName: 'library',
				name: 'Library',
				title: 'History',
				icon: 'assets/icons/nav-menu/library_icon.svg',
				isSelected: true,
				hoverOver: false,
				route: '/library'
			}
		],
		[
			15,
			{
				parentName: 'library',
				name: 'deleted-items',
				title: 'Deleted Items',
				icon: 'assets/icons/nav-menu/delete_icon.svg',
				isSelected: false,
				hoverOver: false,
				route: '/library/trash'
			}
		],
		[
			29,
			{
				parentName: 'templates',
				name: 'essay',
				title: 'Essay',
				icon: 'assets/icons/nav-menu/submenu-icons/essay.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		],
		[
			29,
			{
				parentName: 'templates',
				name: 'essay',
				title: 'Essay',
				icon: 'assets/icons/nav-menu/submenu-icons/essay.svg',
				isSelected: false,
				hoverOver: false,
				route: '/templates'
			}
		]
	]);

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public backOfficePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.BackOffice,
		permissions: [BackofficePermissions.CanAccessBackOffice]
	};
	public showSpinner = false;
	public isAdmin = false;
	public isImpersonated = false;
	public unpaidInvoice = false;
	public timestamp: string;
	public userDetails: UserDetails;
	public dropdownIsVisible: boolean;
	public selectedButton: ChannelButton;
	public accountState: number;
	public currentUrl: string;
	public userStateEnum = UserStateEnum;
	public userStatus: UserRejectedReason;
	expandMenu = false;
	filteredMenuList: IProductMenuGroup[] = Array.from(this.productMenuGroup.values());
	filteredSubMenuList: IProductSubMenuGroup[] = [];
	filteredSubMenuList2: IProductSubMenuGroup[] = [];
	openSubMenu: boolean;
	megaMenuOpen: any;
	profileOpen: any;
	activeRoute: string;
	userFName: string;
	userLName: string;
	userEmail: string;
	urlOrigin: string;
	activeParentIndex: number;
	pageLoadingRoute: boolean;
	public iPhoneMobile: any;
	public hideSideBar: number = 0;

	private expandAccountMenuSubscription = new Subscription();
	private unsubscriber$ = new Subject<void>();
	private asyncLoadCount: number;
	showBonus = true;
	private scrHeight: number;
	private scrWidth: number;
	isSettingsOn: boolean;
	selectedMobileSettingPage: string;
	autoScrolled$: any;
	isBonus: boolean;
	onBillingPage: boolean;
	tooltipText: string = '';
	isBackOffice: boolean;
	public subPageCategory: string;
	public selectedParentNav: IProductMenuGroup;
	isShopifyUser: boolean;
	isFullScreenMode: boolean;
	navShowV3: boolean = true;

	@HostListener('document:click', ['$event'])
	@HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
		this.scrHeight = window.innerHeight;
		this.scrWidth = window.innerWidth;
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (!this.settingsIcon?.nativeElement.contains(event.target)) {
			this.profileOpen = false;
		}
	}

	canUpgradePlan = false;
	public isSubmenuOpen = false;

	public innerWidth: any;
	v2version: boolean = false;
	v3version: boolean = true;

	public showNavDropdown = false;

	public headerNavbar = false;
	public footerNavbar = false;
	textMapper = {
		templates: "The easy-to-use epicenter of Shakespeare's AI",
		'ad-copy': 'Write ad copy for Google, Facebook, & LinkedIn',
		email: 'Craft catchy subject lines and engaging email copy',
		product: 'Write product descriptions for your e-commerce stores',
		blog: 'Draft full blog posts from headline to conclusion',
		content: 'Allow Shakespeare to rewrite your existing content',
		social: 'Craft captions for your social media posts',
		framework: 'Write copy that sells with these proven frameworks',
		projects: 'All of your copywriting projects in one place',
		folders: 'Organize your work into folders',
		history: 'Everything you and Shakespeare have created together',
		trash: 'Your recently deleted outputs, projects, and docs',
		documents: 'Manage all copy created using Write From Scratch'
	};

	constructor(
		public router: Router,
		private authenticationService: AuthenticationService,
		private accountTypeService: AccountTypeService,
		private config: NgSelectConfig,
		private dialog: MatDialog,
		public translate: TranslateService,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService,
		public cdRef: ChangeDetectorRef,
		private shakespeareService: ShakespeareService,
		private userPlanStore: Store<UserPlanState>,
		private firstUserService: FirstUserService,
		private route: ActivatedRoute,
		private adsService: AdsService,
		private konvaService: KonvaService,
		private aiOptimizeService: OptiomizationAiService, // private readonly _shepherdService: ShepherdService
		public notificationService: NotificationsService
	) {
		let showNewNav = localStorage.getItem('googleAllowed');
		this.konvaService.hideSideMenu$.subscribe(res => {
			this.noSideMenu = res;
			this.isFullScreenMode = res;
		});
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));

		if ((token && token.sign_up_version && token.sign_up_version == 'v2') || (token && token.sign_up_version && token.sign_up_version == 'v3')) {
			showNewNav = 'true';
		}
		// this.validateAccessToken(); // googleAds token validation
		this.getScreenSize();
		this.pageLoadingRoute = true;
		this.mainMenuGroup = new Map();
		const megaMenus = this.authenticationService.allowedMegaRoutes$.value;
		try {
			megaMenus.forEach((item, index) => {
				this.mainMenuGroup.set(index + 1, item);
			});
		} catch (e) {
			this.router.navigate(['accounts']);
		}

		this.shakespeareService.isBonusOffer$.subscribe(val => {
			this.isBonus = val;
		});
		this.shakespeareService.isSettingsOpen$.subscribe(value => {
			this.isSettingsOn = value;
		});

		this.shakespeareService.hideOffer$.subscribe(val => {
			if (val) {
				this.onBillingPage = true;
			} else {
				this.onBillingPage = false;
			}
		});
		if (token.platform_name == 'shopify') {
			this.isShopifyUser = true;
		}
		if ((token && token.sign_up_version && token.sign_up_version == 'v2') || (token && token.sign_up_version && token.sign_up_version == 'v3')) {
			this.showBonus = false;
		}
		this.shakespeareService.shopifyUser$.subscribe(val => {
			if (val) {
				this.isShopifyUser = true;
				this.productMenuGroup.set(8, {
					parentName: 'shakespeare',
					name: 'Shopify',
					title: 'Shopify',
					icon: 'assets/icons/nav-menu/shopify.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/shopify',
					childIndex: 0
				});
				this.filteredMenuList = Array.from(this.productMenuGroup.values());
			}
		});

		this.shakespeareService.bigCommerceUser$.subscribe(val => {
			if (val) {
				this.productMenuGroup.set(9, {
					parentName: 'shakespeare',
					name: 'Big Commerce',
					title: 'Big Commerce',
					icon: 'assets/icons/nav-menu/big-commerce.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/big-commerce',
					childIndex: 0
				});
				this.filteredMenuList = Array.from(this.productMenuGroup.values());
			}
		});

		this.shakespeareService.isEnterpriceUser$.next(showNewNav == 'true' ? true : false);

		// !v2 menu list
		this.shakespeareService.isEnterpriceUser$.subscribe(val => {
			if (val) {
				this.v2version = true;
				this.productMenuGroup = new Map([
					[
						1,
						{
							parentName: 'shakespeare',
							name: 'OverView',
							title: 'Overview',
							icon: 'assets/icons/nav-menu/submenu-icons/home-icon.svg',
							isSelected: true,
							hasChildren: false,
							hoverOver: false,
							childOpen: false,
							route: '/overview',
							childIndex: 0,
							path: '/overview',
							tourElement: 'overview',
							tooltip: "Get a quick snapshot of all your Google and Facebook campaigns' performance."
						}
					],
					[
						2,
						{
							parentName: 'shakespeare',
							name: 'Ai Creative',
							title: 'AI Creative',
							icon: '',
							isSelected: false,
							hasChildren: true,
							hoverOver: false,
							childOpen: true,
							route: '/ads',
							childIndex: 0,
							tourElement: 'AI-Creative',
							tooltip: 'Evaluates ad copy and creative performance while assisting in generating more ads for your campaign.'
						}
					],
					// [
					// 	3,
					// 	{
					// 		parentName: 'shakespeare',
					// 		name: 'Creative Studio',
					// 		title: 'Creative Studio',
					// 		icon: '',
					// 		isSelected: false,
					// 		hasChildren: true,
					// 		hoverOver: false,
					// 		childOpen: true,
					// 		route: '/creative-studio',
					// 		childIndex: 0
					// 	}
					// ], // to hide creative studio parent
					[
						4,
						{
							parentName: 'shakespeare',
							name: 'Optimization AI',
							title: 'Optimization AI',
							icon: '',
							isSelected: false,
							hasChildren: true,
							hoverOver: false,
							childOpen: true,
							route: '/action-impact-analysis',
							childIndex: 0,
							tourElement: 'Optimization-AI',
							tooltip:
								'Offers AI-driven optimization recommendations, Action Impact Analysis, and detailed performance insights for your ad account.'
						}
					],
					[
						5,
						{
							parentName: 'shakespeare',
							name: 'Settings',
							title: 'Settings',
							icon: 'assets/icons/nav-menu/settings.svg',
							isSelected: false,
							hasChildren: false,
							hoverOver: false,
							childOpen: false,
							route: '/settings/index',
							childIndex: 0,
							tourElement: 'settings'
						}
					],
					[
						6,
						{
							parentName: 'shakespeare',
							name: 'Settings',
							title: 'Settings',
							icon: 'assets/icons/nav-menu/settings.svg',
							isSelected: false,
							hasChildren: false,
							hoverOver: false,
							childOpen: false,
							route: '/settings',
							childIndex: 0,
							tourElement: 'settings'
						}
					],
					[
						7,
						{
							parentName: 'shakespeare',
							name: 'AI Targeting',
							title: 'AI Targeting',
							icon: '',
							isSelected: false,
							hasChildren: true,
							hoverOver: false,
							childOpen: true,
							route: '',
							childIndex: 0,
							tourElement: 'AI-Targeting'
						}
					]
				]);
				this.productSubMenuGroup = new Map([
					// [
					// 	1,
					// 	{
					// 		parentName: 'Ai Creative',
					// 		name: 'Overview',
					// 		title: 'Overview',
					// 		icon: 'assets/icons/nav-menu/submenu-icons/overview-black.svg',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/ads'
					// 	}
					// ],
					[
						1,
						{
							parentName: 'Ai Creative',
							name: 'Facebook',
							title: 'Facebook',
							icon: 'assets/icons/nav-menu/submenu-icons/facebook-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/ads/facebook/auth',
							path: '/ads/facebook/auth'
						}
					],
					[
						2,
						{
							parentName: 'Ai Creative',
							name: 'Google',
							title: 'Google',
							icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/ads/google/auth',
							path: '/ads/google/auth'
						}
					],
					// [
					// 	3,
					// 	{
					// 		parentName: 'Ai Creative',
					// 		name: 'Creative Studio',
					// 		title: 'Creative Studio',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/ads/creativeStudio/dashboard',
					// 		path: '/ads/creativeStudio/dashboard'
					// 	}
					// ],
					// [
					// 	3,
					// 	{
					// 		parentName: 'Optimization AI',
					// 		name: 'Action Impact',
					// 		title: 'Action Impact',
					// 		title2: 'Analysis',
					// 		icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/action-impact-analysis',
					// 		path: '/action-impact-analysis?filter=Action%20Impact'
					// 	}
					// ],
					// [
					// 	4,
					// 	{
					// 		parentName: 'Optimization AI',
					// 		name: 'Performance Impact',
					// 		title: 'Performance',
					// 		title2: 'Insights',
					// 		icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/performance-impact',
					// 		path: '/performance-impact?filter=Performance%20Impact'
					// 	}
					// ],
					// [
					// 	5,
					// 	{
					// 		parentName: 'Optimization AI',
					// 		name: 'AI Calibration',
					// 		title: 'AI Calibration',
					// 		icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/AiCalibration',
					// 		path: '/AiCalibration?filter=AI%20Calibration'
					// 	}
					// ],
					// [
					// 	//to hide creative studio child
					// 	3,

					// 	{
					// 		parentName: 'Creative Studio',
					// 		name: 'Create',
					// 		title: 'Create',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/creative-studio/create',
					// 		path: '/creative-studio/create'
					// 	}
					// ],
					// [
					// 	//to hide creative studio child
					// 	4,

					// 	{
					// 		parentName: 'Creative Studio',
					// 		name: 'Brands',
					// 		title: 'Brands',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/creative-studio/brands',
					// 		path: '/creative-studio/brands'
					// 	}
					// ],
					// [
					// 	5,
					// 	{
					// 		parentName: 'Creative Studio',
					// 		name: 'AdGen AI',
					// 		title: 'AdGen AI',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/creative-studio/ai-creative-start',
					// 		path: '/creative-studio/ai-creative-start'
					// 	}
					// ],
					// [
					// 	6,
					// 	{
					// 		parentName: 'Creative Studio',
					// 		name: 'Text',
					// 		title: 'Text',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/ads/creativeStudio/dashboard',
					// 		path: '/ads/creativeStudio/dashboard'
					// 	}
					// ],
					// [
					// 	7,
					// 	{
					// 		parentName: 'Creative Studio',
					// 		name: 'Social',
					// 		title: 'Social',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/ads/creativeStudio/dashboard',
					// 		path: '/ads/creativeStudio/dashboard'
					// 	}
					// ],
					// [
					// 	8,
					// 	{
					// 		parentName: 'Creative Studio',
					// 		name: 'Catalogue',
					// 		title: 'Catalogue',
					// 		icon: '',
					// 		isSelected: false,
					// 		hoverOver: false,
					// 		route: '/creative-studio/catalogue',
					// 		path: '/creativeStudio/catalogue'
					// 	}
					// ],
					[
						7,
						{
							parentName: 'Optimization AI',
							name: 'AI',
							title: 'Recommendations',
							title2: '',
							icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/optimization-ai/ai-recommendation',
							path: '/optimization-ai/ai-recommendation',
							tooltip: 'Provides AI-driven optimization recommendations for enhanced campaign performance.'
						}
					],
					[
						8,
						{
							parentName: 'Optimization AI',
							name: 'Action Impact',
							title: 'Action Impact',
							title2: 'Analysis',
							icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/optimization-ai/action-impact-analysis',
							path: '/optimization-ai/action-impact-analysis?filter=Action%20Impact',
							tooltip: "Assesses your marketing actions' impact on campaign performance for data-driven decision-making"
						}
					],
					[
						9,
						{
							parentName: 'Optimization AI',
							name: 'Performance Impact',
							title: 'Performance',
							title2: 'Insights',
							icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/optimization-ai/performance-impact',
							path: '/optimization-ai/performance-impact?filter=Performance%20Impact',
							tooltip:
								"Gain in-depth insights into your campaign's performance with this tool. It provides a detailed analysis of how your ads are progressing."
						}
					],
					[
						10,
						{
							parentName: 'Optimization AI',
							name: 'AI Calibration',
							title: 'AI Calibration',
							icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/optimization-ai/AiCalibration',
							path: '/optimization-ai/AiCalibration?filter=AI%20Calibration',
							tooltip: 'Enables you to create and manage campaign calibrations for optimal performance.'
						}
					],
					[
						11,
						{
							parentName: 'AI Targeting',
							name: 'Targeting Google',
							title: 'Google',
							showIcon: true,
							icon: 'assets/icons/nav-menu/submenu-icons/google-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/ai-targeting/google',
							path: '/ai-targeting/google'
						}
					],
					[
						12,
						{
							parentName: 'AI Targeting',
							name: 'Targeting Facebook',
							title: 'Facebook',
							showIcon: true,
							icon: 'assets/icons/nav-menu/submenu-icons/facebook-nav.svg',
							isSelected: false,
							hoverOver: false,
							route: '/ai-targeting/facebook',
							path: '/ai-targeting/facebook'
						}
					]
				]);
				this.filteredMenuList = Array.from(this.productMenuGroup.values());
				this.filteredSubMenuList = Array.from(this.productSubMenuGroup.values());
				this.filteredSubMenuList2 = Array.from(this.productSubMenuGroup.values());
				this.shakespeareService.sideMenuSelection$.subscribe(res => {
					// console.log(res, 'removeclass');
					if (res == 'addClass') {
						for (let i = 0; i < 20; i++) {
							document.getElementById('childMenu' + i).classList.add('removeSelection');
						}
					}
					if (res == 'removeClass') {
						for (let i = 0; i < 20; i++) {
							document.getElementById('childMenu' + i).classList.remove('removeSelection');
						}
					}
					// this.removeSelectionClass = '';
					// document.getElementById('childMenu').classList.remove('removeSelection');
				});
			}
		});

		this.shakespeareService.isFullScreenMode$.subscribe(value => {
			this.isFullScreenMode = value;

			if (this.showBonus) {
				this.expandMenu = false;
			} else {
				this.expandMenu = true;
			}
		});

		this.shakespeareService.autoScrolled$.subscribe(value => {
			this.autoScrolled$ = value;
		});

		this.shakespeareService.clearSettingSelection$.subscribe(value => {
			if (value) {
				this.selectedMobileSettingPage = '';
			}
		});

		this.asyncLoadCount = 0;

		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd) {
				this.currentUrl = event.url;
				// console.log('route change');
				this.getNotifications();

				const routeMainUrl = event.url.split('?')[0] ?? '';
				let parent = routeMainUrl.split('/')[1];
				if (!parent || parent.length === 0) {
					parent = 'home';
				}
				const children = routeMainUrl.split('/')[2];
				this.showToolbar = children && children !== 'index';
				this.activeRoute = routeMainUrl;
				this.generatePageName();
				if (!showNewNav) {
					if (parent == 'settings') {
						this.expandMenu = false;
						this.authenticationService.expandMenu$.next(this.expandMenu);
					}
				}
				this.isBackOffice = event.url.split('/').includes('back-office');
				if (!this.isBackOffice) {
					this.getPlanStatus();
				}
				this.urlOrigin = window.location.origin;

				if (event.id === 1) {
					this.isUserAdmin();
				}
				this.showToolbar = children && event.url.split('/')[1] !== 'settings' && event.url.split('/')[1] !== 'document';
				const routeList = ['templates', 'library', 'document', 'trash', 'shopify', 'home', 'ads', 'optimizationai']; // page that allow header and footers
				const settingsRoutes = ['/settings/profile', '/settings/teams', '/settings/credit', '/settings/billing'];

				const found = routeList.find(toMatchItem => toMatchItem === parent);

				if ((found !== undefined && !children) || parent === 'home') {
					this.headerNavbar = true;
					this.footerNavbar = true;
				} else {
					this.footerNavbar = false;
					this.headerNavbar = false;
				}

				if (event.url.includes('/projects')) {
					this.headerNavbar = true;
					this.footerNavbar = true;
				}
				if (event.url.includes('/library')) {
					this.headerNavbar = true;
					this.footerNavbar = true;
				}

				if (event.url.includes('/shopify')) {
					this.headerNavbar = true;
					this.footerNavbar = false;
				}

				if (event.url.includes('/big-commerce')) {
					this.headerNavbar = true;
					this.footerNavbar = false;
				}

				if (event.url.includes('/generate') || event.url.includes('/all-products')) {
					this.headerNavbar = false;
					this.footerNavbar = false;
				}

				if (settingsRoutes.includes(event.url)) {
					this.headerNavbar = true;
				}
				window.addEventListener('native.showkeyboard', this.keyboardShowHandler);
				window.addEventListener('native.hidekeyboard', this.keyboardHideHandler);
				if (!this.pageLoadingRoute) {
					this.checkRouteMap();
				}
			}
		});

		this.shakespeareService.uncheckAllTabs$.subscribe(value => {
			if (!value) return;
			this.filteredMenuList.forEach(item => {
				item.isSelected = false;
			});
		});

		this.shakespeareService.isSettingsOpen$.subscribe(val => {
			if (val) {
				this.headerNavbar = true;
			}
		});

		if (environment.Flags.hasTimestamp) {
			this.timestamp = buildGeneratedValues.timeStamp;
		}

		this.config.appendTo = 'body';
		// Configs for ng-select
		this.config.bindValue = 'value';
	}

	public keyboardShowHandler() {
		return (this.footerNavbar = false);
	}

	public keyboardHideHandler() {
		return (this.footerNavbar = true);
	}

	public exitFullScreenMode() {
		let route = this.shakespeareService.fullScreenBackRoute$.value;

		if (this.router.url !== '/ads/text-editor' && this.router.url !== '/ads/image-editor') {
			this.shakespeareService.isFullScreenMode$.next(false);
		}
		if (route == 'ads/facebook/variants') {
			console.log('yes', localStorage.getItem('creativeId'), localStorage.getItem('FbAccountId'));
			let creativeId = localStorage.getItem('creativeId');
			let FbAccountId = localStorage.getItem('FbAccountId');
			this.router.navigate(['ads/facebook/variants'], {
				queryParams: { creativeId: creativeId, customerId: FbAccountId }
			});
		} else if (route == 'ads/facebook/creative-variants') {
			let variant = localStorage.getItem('variants');
			let id = localStorage.getItem('variantsId');
			this.router.navigate(['ads/facebook/creative-variants'], { queryParams: { type: variant, id: id } });
		} else if (route == 'ads/facebook/variantsdpa') {
			this.router.navigate(['/ads/facebook/variantsdpa'], {
				queryParams: { campaign_id: localStorage.getItem('campaign_id'), productId: localStorage.getItem('productId') }
			});
		} else {
			if (route == 'ads/google/playground') {
				this.router.navigate([route], {
					state: {
						adAccounts: JSON.parse(localStorage.getItem('cacheAdAccounts')),
						categoryIds: JSON.parse(localStorage.getItem('cacheCategoryIds')),
						groupIds: JSON.parse(localStorage.getItem('cacheGroupIds')),
						cardDetails: JSON.parse(localStorage.getItem('cacheCardDetails'))
						// dashBoardData: JSON.parse(localStorage.getItem('cacheDashBoardData')),
						// scoreDetails: JSON.parse(localStorage.getItem('cacheScoreDetails')),
						// graphData: JSON.parse(localStorage.getItem('cacheGraphDatas'))
					}
				});
			} else {
				// this.router.navigate([route]);
				window.history.back();
			}
		}
	}

	public ngOnInit(): void {
		// if (this.router.url == '/welcome-shakespeare-form') {
		// 	this.navShowV3 = false;
		// } else {
		// 	this.navShowV3 = true;
		// }
		if (this.router.url == '/overview' || this.router.url == '/overview') {
			this.activateOverviewHeader = true;
		} else {
			this.activateOverviewHeader = false;
		}
		this.isUserAdmin();
		this.company();
		this.userData();
		this.firstUserService.showTour$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			this.firstTimeUser = res;
		});
		this.shakespeareService.settingsRouteName$.subscribe(value => {
			this.filteredMenuList.forEach(item => {
				item.isSelected = false;
				item.childOpen = true;
			});
		});

		this.hideSideBar = +localStorage.getItem('hideBar') || 0;

		if (this.selectedButton) {
			this.accountTypeService.setAccountType(this.selectedButton.sourceChannel);
		}
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userLName = storageJwt['user_lastname'];
		this.userEmail = storageJwt['sub'];

		this.innerWidth = window.innerWidth;
		if (this.innerWidth <= 1024) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
		if (this.authenticationService.isLoggedIn()) {
			// this.getAdAccounts();
			this.validateAccessToken();
		}

		// this.router.events.subscribe(res => {
		// 	console.log(res, 'route');
		// });
	}
	removeSelectionClass = '';

	company() {
		this.adsService.company().subscribe((res: any) => {
			this.companyDetails = res.data;
		});
	}

	userData() {
		this.backOfficeService
			.getUserDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.userDetailsData = response;
				}
			});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
		if (this.innerWidth <= 1024) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	validateAccessToken(): void {
		combineLatest([
			this.adsService.validateAccessToken().pipe(
				catchError(err => {
					return of([]);
				})
			),
			this.adsService.validateFbAccessToken().pipe(
				catchError(err => {
					return of([]);
				})
			)
		]).subscribe(res => {
			const google = res[0];
			const fb = res[1];

			if (google) {
				this.adsService.getGoogleAdIDs().subscribe(res => {
					this.aiOptimizeService.googleAdAccount = res;
					this.aiOptimizeService.googleAdAccount = this.aiOptimizeService.googleAdAccount.filter(data => {
						return data?.readAccess;
					});
					this.aiOptimizeService.googleAdAccount = this.aiOptimizeService.googleAdAccount.map(item => {
						item.name = item.name + ' - ' + item.id.slice(-4);
						return item;
					});
					this.aiOptimizeService.adAccountDataLoaded.next(true);
				});
			}

			if (fb) {
				this.adsService.addFbAccounts().subscribe(res => {
					this.aiOptimizeService.fbAdAccount = res.data;
					this.aiOptimizeService.adAccountDataLoaded.next(true);
				});
			}
		});
	}

	public goBack(): void {
		if (this.firstTimeUser) {
			return;
		}
		this.router.navigate(['/']);
	}

	public ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
		// if (this.router.url == '/welcome-shakespeare-form') {
		// 	this.navShowV3 = false;
		// } else {
		// 	this.navShowV3 = true;
		// }
	}

	public checkRouteMap(): void {
		/*if (this.activeRoute.includes('?')) {
			return;
		}
		this.activeSearchMapList = [];
		if (this.activeSearchMapList.length > 0) {
			const findRouteIndex = this.activeSearchMapList.findIndex(item => {
				return location.pathname === item.route;
			});
			if (findRouteIndex !== -1) {
				this.pageLoadingRoute = true;

				if (this.pageLoadingRoute) {
					setTimeout(() => {
						this.pageLoadingRoute = false;
					}, 1000);
				}
			}
		}*/
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	toggleProfileAction(): void {
		if (this.firstTimeUser) {
			return;
		}
		this.profileOpen ? (this.profileOpen = false) : (this.profileOpen = true);
	}

	public tobackOffice(): void {
		this.router.navigate(['back-office']);
	}

	public toggleExpandNavMenu(): void {
		if (!this.v2version) {
			if (this.firstTimeUser) {
				return;
			}
			if (this.expandMenu) {
				this.expandMenu = false;
				this.authenticationService.expandMenu$.next(this.expandMenu);
			} else {
				this.expandMenu = true;
				this.authenticationService.expandMenu$.next(this.expandMenu);
			}
		}
	}

	// !parent menu toggle functionality
	menuItemActive(index: number, item: any, pushRoute = true): void {
		if (item.title == 'Overview') {
			this.activateOverviewHeader = true;
		} else {
			this.activateOverviewHeader = false;
		}
		if (this.firstTimeUser) {
			return;
		}

		const selectedMenuItem = this.filteredMenuList[index];

		if (selectedMenuItem.hasChildren) {
			this.selectedParentNav = this.filteredMenuList[index];
			this.filteredMenuList[index].childOpen = !this.filteredMenuList[index].childOpen;
		}

		if (this.expandMenu == false && !this.isMobile && selectedMenuItem.hasChildren) {
			this.toggleExpandNavMenu();
		}
		// if (selectedMenuItem.name == 'Optimization AI') {
		// 	this.selectedParentNav = this.filteredMenuList[index];
		// 	this.filteredMenuList[index].childOpen = !this.filteredMenuList[index].childOpen;
		// }
		// if (selectedMenuItem.name == 'Creative Studio') {
		// 	this.selectedParentNav = this.filteredMenuList[index];
		// 	this.filteredMenuList[index].childOpen = !this.filteredMenuList[index].childOpen;
		// }
		if (selectedMenuItem.name == 'OverView') {
			this.router.navigate([this.filteredMenuList[index].route]);
		}
		// if (this.v2version) {
		// 	if (selectedMenuItem.name == 'Ai Creative') {
		// 		this.filteredMenuList[index].childOpen = !this.filteredMenuList[index].childOpen;
		// 		return;
		// 	}
		// }

		if (selectedMenuItem.name == this.selectedParentNav.name) {
			// just collapse the link
			if (selectedMenuItem.hasChildren) {
				if (!this.v2version) {
					this.filteredMenuList[index].childOpen = !this.filteredMenuList[index].childOpen;
				}
			}
			return;
		} else {
			if (!this.v2version) {
				this.filteredMenuList = this.filteredMenuList.map((item, i) => {
					item.childOpen = index === i;
					return item;
				});
			}
		}
		this.activeParentIndex = index;
		this.selectedParentNav = this.filteredMenuList[index];
		if (!selectedMenuItem.hasChildren) {
			if (!this.v2version) {
				this.filteredMenuList[index].childOpen = false;
			}
			if (this.expandMenu == true && !this.isMobile) {
				this.toggleExpandNavMenu();
			}
			this.router.navigate([this.filteredMenuList[index].route]);
		} else {
			this.filteredSubMenuList = [];
			if (!this.v2version) {
				this.filteredSubMenuList2 = [];
			}
			this.productSubMenuGroup.forEach(submenu => {
				if (submenu.parentName === this.selectedParentNav.name) {
					this.filteredSubMenuList.push(submenu);
					if (!this.v2version) {
						this.filteredSubMenuList2.push(submenu);
					}
				}
			});
			this.menuChildrenList(this.filteredMenuList[index], this.activeParentIndex, true);
		}
	}

	menuItemSubActive(index: number, pushRoute = true): void {
		if (this.firstTimeUser) {
			return;
		}
		this.activateOverviewHeader = false;
		const findParent = this.filteredMenuList.findIndex(item => {
			return item.name === this.filteredSubMenuList2[index]?.parentName;
		});
		this.activeParentIndex = findParent;

		if (this.v2version) {
			if (this.filteredSubMenuList2 && (this.activeParentIndex || this.activeParentIndex == 0)) {
				if (this.filteredSubMenuList2[index]?.parentName === 'library') {
					this.router.navigate([this.filteredSubMenuList2[index].route], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
				} else if (this.filteredSubMenuList2[index]?.parentName === 'Optimization AI') {
					this.router.navigate([this.filteredSubMenuList2[index].route], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
				} else if (this.filteredSubMenuList2[index]?.parentName === 'Creative Studio') {
					this.router.navigate([this.filteredSubMenuList2[index].route]);
				} else if (this.filteredMenuList[findParent].route === 'overview') {
					this.router.navigate([this.filteredMenuList[findParent].route + 'overview']);
				} else {
					if (this.filteredSubMenuList2[index]?.name === 'Action Impact' || 'Performance Impact' || 'AI Calibration') {
						// console.log(this.filteredSubMenuList2[index].route, 'route');
						if (this.filteredSubMenuList2[index].route == '/ads/facebook/auth') {
							if (this.showAiCategorFlow('fb')) {
								// this.router.navigate(['ads/facebook/auth'], { queryParams: { connected: true } });
								this.router.navigate(['ads/ai-category?page=facebook']);
								return;
							}
						}
						if (this.filteredSubMenuList2[index].route == '/ads/google/auth')
							if (this.showAiCategorFlow('google')) {
								this.router.navigate(['ads/ai-category?page=google']);

								// this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
								return;
							}
						this.router.navigate([this.filteredSubMenuList2[index].route], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
					} else if (this.filteredSubMenuList2[index].name == 'Facebook') {
						this.router.navigate(['/ads/facebook/auth'], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
					} else if (this.filteredSubMenuList2[index].name == 'Google') {
						this.router.navigate(['/ads/google/auth'], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
					} else if (this.filteredSubMenuList2[index].name == 'Creative Studio') {
						this.router.navigate(['/ads/creativeStudio/dashboard'], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
					} else {
						this.router.navigate([this.filteredMenuList[findParent].route + '/google/overview']);
					}
				}
			}
		} else {
			if (this.filteredSubMenuList2 && this.activeParentIndex) {
				if (this.filteredSubMenuList2[index]?.parentName === 'library') {
					this.router.navigate([this.filteredSubMenuList2[index].route], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
				} else {
					this.router.navigate([this.filteredMenuList[findParent].route], { queryParams: { filter: this.filteredSubMenuList2[index].name } });
				}
			}
		}

		this.isSubmenuOpen = false;
	}

	public menuChildrenList(list: IProductMenuGroup, parentIndex, pushRoute = true): void {
		if (this.filteredMenuList[parentIndex].hasChildren) {
			this.menuItemSubActive(0, pushRoute);
		}
	}

	public mouseEnterMenu(index: number): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMenu(index): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterSubMenu(index: number): void {
		this.filteredSubMenuList2.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredSubMenuList2.length; i++) {
			if (i === index) {
				this.filteredSubMenuList2[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveSubMenu(index): void {
		this.filteredSubMenuList2.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenuEffect(): Object {
		let styles = {
			background: !this.megaMenuOpen ? '#DADCE0' : '#fafafa'
		};
		return styles;
	}

	public menuItemSelectedEffect(): Object {
		let styles = {
			background: '#D8E7FE',
			'border-left': !this.megaMenuOpen ? '5px solid #006BFF' : '0px'
		};
		return styles;
	}

	public menuItemSelectedEffect1(): Object {
		let styles = {
			background: '#EFEFEF',
			'border-left': !this.megaMenuOpen ? '5px solid #006BFF' : '0px'
		};
		return styles;
	}

	public borderLeft(): Object {
		let styles = {
			'border-left': '5px solid #EFEFEF'
		};
		return styles;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.removeSubscriptions();
		this.removeNotificationPoll();
	}

	public onScroll(event: any): void {
		// this.scrollService.changeScrollY(event.target.scrollTop);
	}

	public openNotification() {
		console.log('Notification icon click');
	}

	public openKnowledgeBase(isMobile = false): void {
		if (this.firstTimeUser) {
			return;
		}
		this.dialog.open(KnowledgebaseComponent, {
			width: isMobile ? '100%' : '400px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
	}

	public goToBilling(): void {
		this.profileOpen = false;
		this.footerNavbar = false;
		this.router.navigate(['/settings/profile']);
	}

	public toIntegrations(): void {
		this.profileOpen = false;
		this.shakespeareService.isSettingsOpen$.next(true);
		this.shakespeareService.uncheckAllTabs$.next(true);
		this.router.navigate(['/integrations']);
	}

	public goToShopify(toShopify: boolean): void {
		if (this.firstTimeUser) {
			return;
		}
		if (toShopify) {
			setTimeout(() => {
				this.footerNavbar = false;
			}, 200);
			this.routeName = 'Dashboard';
			this.router.navigate(['shopify']);
		} else {
			this.footerNavbar = true;
			this.router.navigate(['/']);
		}
	}

	public logout(): void {
		this.profileOpen = false;
		this.authenticationService.expandMenu$.next(false);
		this.authenticationService.logout();
	}

	public isUserAdmin(): void {
		this.userStore.pipe(select(hasBackOfficePermission), takeUntil(this.unsubscriber$)).subscribe(isAdmin => {
			this.isAdmin = isAdmin;
		});
	}

	private removeSubscriptions(): void {
		this.expandAccountMenuSubscription.unsubscribe();
	}

	public backToHome(): void {
		if (this.firstTimeUser) {
			return;
		}
		this.router.navigate(['']);
		this.shakespeareService.isFullScreenMode$.next(false);
	}

	public navigateToBilling(): void {
		if (this.firstTimeUser) {
			return;
		}
		this.router.navigate(['/settings/billing'], { queryParams: { page: 'upgrade' } });
	}

	private getPlanStatus() {
		this.backOfficeService
			.userPlanDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					localStorage.setItem('planDetails', JSON.stringify(response));
					this.canUpgradePlan = response.planName == 'freePass' || response.planName == 'limitedPass' ? true : false;
					this.shakespeareService.isPaidUser$.next(!this.canUpgradePlan);
					this.userPlanStore.dispatch(new RegisterUserPlan(response));
				}
			});
	}

	public toggle(event: boolean): void {
		if (this.firstTimeUser) {
			return;
		}
		if (this.isSettingsOn || !this.selectedParentNav?.hasChildren) return;
		this.isSubmenuOpen = !event;
	}

	public goToSelectedPage(route: string): void {
		this.selectedMobileSettingPage = route;
		this.router.navigate(['settings/' + route]);
	}

	close() {
		if (this.innerWidth < 1110) {
			this.expandMenu = false;
		} else {
			return;
		}
	}

	isParentActive(route: string = '') {
		let mainTemplate = '';
		if (this.activeRoute.includes('overview')) {
			mainTemplate = this.activeRoute ? this.activeRoute.replace('/', '') : '';
		} else if (this.activeRoute.includes('creative-variants')) {
			this.expandMenu = false;
		} else {
			mainTemplate = this.activeRoute.split('/')[1] ? this.activeRoute.split('/')[1] : '';
		}
		const fRoute = route.replace('/', '');
		return fRoute == mainTemplate;
	}

	private generatePageName(name: string = null) {
		if (name) {
			this.routeName = name;
			return;
		}
		const param = this.activeRoute.split('?')[0] ?? '';
		let paramData;
		this.route.queryParams.pipe(take(1)).subscribe(param => {
			if (param && param.hasOwnProperty('filter')) {
				paramData = param['filter'];
				this.subPageCategory = paramData;
			}
		});
		const route = param.split('/');
		const parentName = route[1];
		const child = route[2];
		if (child && child.length >= 1) {
			this.routeName = child;
		} else if (parentName && parentName.length >= 1) {
			this.routeName = parentName;
		} else {
			this.routeName = 'Home';
		}
		this.selectedParentNav = this.filteredMenuList.find(item => item.route === `/${parentName ?? ''}`);
		switch (parentName) {
			case 'templates':
			case 'templates-form': {
				this.tooltipText = this.textMapper['templates'];
				break;
			}
			case 'projects-only':
			case 'projects': {
				this.tooltipText = this.textMapper['projects'];
				break;
			}
			case 'library': {
				this.tooltipText = this.textMapper['library'];
				break;
			}
			case 'document': {
				this.tooltipText = this.textMapper['document'];
				break;
			}
		}
		this.filteredSubMenuList = [];
		if (!this.v2version) {
			this.filteredSubMenuList2 = [];
		}
		if (!this.selectedParentNav) {
			this.selectedParentNav = this.filteredMenuList[0];
		} else if (this.selectedParentNav.hasChildren) {
			let filteredSubMenu: IProductSubMenuGroup[] = [];
			for (let item of this.productSubMenuGroup.values()) {
				if (item.parentName === this.selectedParentNav.name) {
					filteredSubMenu.push(item);
				}
			}
			this.filteredSubMenuList = filteredSubMenu;
			if (!this.v2version) {
				this.filteredSubMenuList2 = filteredSubMenu;
			}
			if (paramData) {
				this.routeName = paramData.replace('-', ' ');
				switch (paramData) {
					case 'templates':
					case 'all-templates':
						this.tooltipText = this.textMapper['templates'];
						break;
					case 'ad-copy':
						this.tooltipText = this.textMapper['ad-copy'];
						break;
					case 'email':
						this.tooltipText = this.textMapper['email'];
						break;
					case 'product-description':
						this.tooltipText = this.textMapper['product'];
						break;
					case 'blogs':
						this.tooltipText = this.textMapper['blog'];
						break;
					case 'content-improver':
						this.tooltipText = this.textMapper['content'];
						break;
					case 'social':
						this.tooltipText = this.textMapper['social'];
						break;
					case 'frameworks':
						this.tooltipText = this.textMapper['framework'];
						break;
					case 'projects':
						this.tooltipText = this.textMapper['projects'];
						break;
					case 'folders':
						this.tooltipText = this.textMapper['folders'];
						break;
				}
			}
		}
		this.showNavDropdown = this.selectedParentNav.hasChildren ?? false;
	}

	checkSubMenuSelected(item: IProductSubMenuGroup) {
		if (this.activeRoute.includes('overview')) {
			return item.name == 'Overview';
		} else if (this.activeRoute.includes('facebook')) {
			return item.name == 'Facebook';
		} else if (this.activeRoute.includes('google')) {
			return item.name == 'Google';
		} else if (this.activeRoute.includes('creativStudio')) {
			return item.name == 'Creative Studio';
		} else {
			return item.name == this.subPageCategory;
		}
	}

	overviewPage() {
		console.log('test');
		this.router.navigate(['ads/google/dashboard']);
	}

	getAdAccounts() {
		combineLatest([
			this.adsService.addFbAccounts().pipe(
				catchError(err => {
					return of([]);
				})
			),
			this.adsService.getGoogleAdIDs().pipe(
				catchError(err => {
					return of([]);
				})
			)
		]).subscribe(res => {
			console.log(res);
			this.aiOptimizeService.fbAdAccount = res[0].data;
			this.aiOptimizeService.googleAdAccount = res[1];
			console.log(this.aiOptimizeService.fbAdAccount, this.aiOptimizeService.googleAdAccount);

			this.aiOptimizeService.googleAdAccount = this.aiOptimizeService.googleAdAccount.filter(data => {
				return data?.readAccess;
			});
			this.aiOptimizeService.googleAdAccount = this.aiOptimizeService.googleAdAccount.map(item => {
				item.name = item.name + ' - ' + item.id.slice(-4);
				return item;
			});
			console.log(this.aiOptimizeService.googleAdAccount, this.aiOptimizeService.fbAdAccount, 'data loaded');
			this.aiOptimizeService.adAccountDataLoaded.next(null);
		});
	}

	navigateTopages(item) {
		if (item.path == '/ads/facebook/auth') {
			if (this.showAiCategorFlow('fb')) {
				this.router.navigate(['ads/facebook/auth'], { queryParams: { connected: true } });
				return;
			}
		}
		if (item.path == '/ads/google/auth') {
			if (this.showAiCategorFlow('google')) {
				this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
				return;
			}
		}
		this.router.navigateByUrl(item.path);
	}

	showAiCategorFlow(type: string) {
		let action = null;
		if (type == 'fb') {
			action = this.shakespeareService.getShowAiCategoryFlowfb();
		} else {
			action = this.shakespeareService.getShowAiCategoryFlowGoogle();
		}
		if (action == 'yes') return true;
		else return false;
	}

	// !notifications
	private notificationSubscription: Subscription;
	public notifications: any[] = [];
	public unreadCount: number = 0;
	public notificationPollInterval: number = 10000;

	public updateUnreadCountEvent(count: number): void {
		this.unreadCount = count;
	}

	get isShowAllNotification() {
		return this.router.url == '/notifications';
	}

	public pollNotifications(): void {
		interval(this.notificationPollInterval)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(x => {
				if (!this.isShowAllNotification) this.getNotifications();
			});
	}

	public getNotifications(): void {
		// this.removeNotificationPoll();
		// this.notificationSubscription = this.notificationService
		// 	.getAllNotifications()
		// 	.pipe(take(1))
		// 	.subscribe(res => {
		// 		this.notifications = res;
		// 		this.unreadCount = res?.filter(i => i.unreadRead).length;
		// 	});

		if (!this.isShowAllNotification) {
			this.notificationService.getAllNotifications().subscribe(res => {
				this.notifications = res;
				// this.unreadCount = res?.filter(i => i.unreadRead).length;
			});
		}
	}

	public removeNotificationPoll(): void {
		if (this.notificationSubscription && !this.notificationSubscription.closed) {
			this.notificationSubscription.unsubscribe();
		}
	}
	// !end
}
