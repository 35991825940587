import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewProductComponent } from '../view-product/view-product.component';

@Component({
	selector: 'app-product-table',
	templateUrl: './product-table.component.html',
	styleUrls: ['./product-table.component.scss']
})
export class ProductTableComponent implements OnInit, OnChanges {
	@Input() products = [];
	@ViewChild('p') pagination;
	private unsubscriber$ = new Subject<void>();
	@ViewChild('allChecker') allChecker;
	@Output() public pageNumber: EventEmitter<number> = new EventEmitter();
	@Output() public bulkPayload: EventEmitter<any[]> = new EventEmitter();
	radioForm: FormGroup;
	public config: PaginationInstance = {
		id: '123',
		itemsPerPage: 0,
		currentPage: 1,
		totalItems: 0
	};
	count: number;
	selectedProducts: string[];
	history: boolean;

	constructor(private router: Router, private dialog: MatDialog, private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.radioForm = new FormGroup({});
		for (let i = 0; i < this.products?.length; i++) {
			this.radioForm.addControl('radio' + i, new FormControl(false));
		}
		this.radioForm.valueChanges.subscribe(val => {
			this.count = 0;
			this.selectedProducts = [];
			for (let [key, value] of Object.entries(val)) {
				if (value) {
					this.count++;
					this.selectedProducts.push(key);
				}
			}
			let flag = true;
			for (let [key, value] of Object.entries(val)) {
				if (value) {
					flag = true;
				} else {
					flag = false;
					break;
				}
			}
			flag ? (this.allChecker.nativeElement.checked = true) : (this.allChecker.nativeElement.checked = false);
		});
		this.getActivatedRoute();
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['type'] == 'history') {
				this.history = true;
			}
		});
	}

	ngOnChanges(): void {
		this.config.totalItems = this.products?.length;
		if (this.products?.length <= 35) {
			this.config.itemsPerPage = this.products?.length;
		} else {
			this.config.itemsPerPage = 35;
		}
	}

	checkAll(event: Event): void {
		let checked = event.currentTarget['checked'];
		if (checked) {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(true);
			});
		} else {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(false);
			});
		}
	}

	public previewProduct(item: object): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '85%',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '1053px',
				height: '763px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ViewProductComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		dialogRef.componentInstance.modelDetail = {
			product: item
		};
		dialogRef.afterClosed().subscribe(obj => {
			// console.log(obj);
		});
	}

	public setPage(page: number): void {
		this.config.id = page.toString();
		this.pageNumber.emit(page);
	}

	generateDescription(): void {
		let payloadData = [];
		this.selectedProducts.forEach(item => {
			let index = item.slice(-1);
			payloadData.push(this.products[index]);
		});
		payloadData = payloadData.map(item => {
			return { product_id: item.id, title: item.title, description: item.description, images: item.images };
		});
		this.bulkPayload.emit(payloadData);
	}

	clearSelected(): void {
		this.count = 0;
		this.selectedProducts = [];
		this.radioForm.reset();
		this.allChecker.nativeElement.checked = false;
	}

	getData(page): void {
		this.pageNumber.emit(page);
	}

	openEditor(id: number): void {
		this.router.navigate(['big-commerce/generate'], { queryParams: { productId: id } });
	}
}
