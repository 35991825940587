<div
	class="editor-over-layer"
	*ngIf="this.writingText || isGeneratingOutput"
	(click)="disableEvent($event)"
	(mousedown)="disableEvent($event)"
	(mouseover)="disableEvent($event)"
	(mouseenter)="disableEvent($event)"
>
	<svg width="100%" height="100%" viewBox="0 0 297 210" version="1.1" id="svg5" xmlns="http://www.w3.org/2000/svg">
		<defs id="defs2" />
		<g id="layer1">
			<rect
				style="fill: rgba(247, 249, 250, 0.99); stroke: #000000; stroke-width: 3.1477; stroke-opacity: 0; fill-opacity: 0.03104213;"
				id="rect984"
				width="297.40454"
				height="209.90627"
				x="0.19785003"
				y="0.046867371"
				rx="5.5967846"
			/>
		</g>
	</svg>
</div>
<div id="toolbar" #toolbar>
	<span class="ql-formats" style="padding-left: 0;" *ngIf="showBackButton">
		<button
			(click)="goBack()"
			class="hover"
			value="back"
			style="width: 65px; color: #006bff;"
			matTooltipClass="tooltipClass"
			[matTooltip]="'Back (' + shortKeyName + ' + \<-)'"
		>
			<span class="fas fa-chevron-left"></span>
			Back
		</button>
	</span>
	<span class="ql-formats" style="padding-left: 0;">
		<button class="ql-undo hover" value="undo" matTooltipClass="tooltipClass" [matTooltip]="'Undo (' + shortKeyName + ' + Z)'"></button>
		<button class="ql-redo hover" value="redo" matTooltipClass="tooltipClass" [matTooltip]="'Redo (' + shortKeyName + ' + Shift + Z)'"></button>
	</span>
	<span class="ql-formats">
		<button class="ql-bold" matTooltipClass="tooltipClass" [matTooltip]="'Bold (' + shortKeyName + ' + B)'"></button>
		<button class="ql-italic" matTooltipClass="tooltipClass" [matTooltip]="'Italic (' + shortKeyName + ' + I)'"></button>
		<button class="ql-underline" matTooltipClass="tooltipClass" [matTooltip]="'Underline (' + shortKeyName + ' + U)'"></button>
		<button class="ql-strike" matTooltipClass="tooltipClass" [matTooltip]="'Strikethrough'"></button>
	</span>
	<span class="ql-formats" matTooltipClass="tooltipClass" [matTooltip]="'Headings'">
		<select class="ql-size h-btn" matTooltipClass="tooltipClass" [matTooltip]="'Headings'">
			<option value="small">H4</option>
			<option value="normal" selected>H3</option>
			<option value="large">H2</option>
			<option value="huge">H1</option>
		</select>
	</span>
	<span class="ql-formats has-select">
		<span class="custom-wrapper" matTooltipClass="tooltipClass" [matTooltip]="'Font Colors'">
			<select class="ql-color"></select>
		</span>
		<span class="custom-wrapper" matTooltipClass="tooltipClass" [matTooltip]="'Background Colors'">
			<select class="ql-background"></select>
		</span>
	</span>
	<span class="ql-formats has-select">
		<button class="ql-list" value="ordered" matTooltipClass="tooltipClass" [matTooltip]="'Ordered Lists'"></button>
		<button class="ql-list" value="bullet" matTooltipClass="tooltipClass" [matTooltip]="'Bullets Lists'"></button>

		<span class="custom-wrapper" matTooltipClass="tooltipClass" [matTooltip]="'Text Alignments'">
			<select class="ql-align">
				<option selected></option>
				<option value="center"></option>
				<option value="right"></option>
				<option value="justify"></option>
			</select>
		</span>
	</span>
	<span class="ql-formats">
		<button class="ql-code-block" matTooltipClass="tooltipClass" [matTooltip]="'Add Code'"></button>
		<button class="ql-clean" matTooltipClass="tooltipClass" [matTooltip]="'Reset Styles'"></button>
	</span>
	<span class="ql-formats">
		<button class="ql-image" matTooltipClass="tooltipClass" [matTooltip]="'Add Image'"></button>
		<button class="ql-link" matTooltipClass="tooltipClass" [matTooltip]="'Add Link'"></button>
	</span>
	<span class="ql-formats">
		<button class="ql-action" [hidden]="true">
			<svg style="width: 17px;" xmlns="http://www.w3.org/2000/svg" width="20.348" height="17.559" viewBox="0 0 20.348 17.559">
				<path
					id="Union_21"
					data-name="Union 21"
					d="M8.484,17.559l-.7-2.086H3.162l-.7,2.086H0l4.063-11.6h2.83l4.07,11.6ZM3.891,13.34H7.078L5.48,8.6Zm8.889,3.336-.826-2.045h.027a6.268,6.268,0,0,0,5.908-4.184h2.176a8.37,8.37,0,0,1-7.285,6.234Zm7.563-9.361H13.027L16.4,3.951A6.187,6.187,0,0,0,11.98,2.092,6.256,6.256,0,0,0,7.164,4.355H4.65a8.344,8.344,0,0,1,13.238-1.9L20.348,0V7.314Z"
					fill="#121212"
				/>
			</svg>
		</button>
		<button
			class="ql-action"
			matTooltipClass="tooltipClass"
			[matTooltip]="isGrammarlyActive ? 'Disable Grammarly' : 'Enable Grammarly'"
			(click)="toggleGrammarly()"
		>
			<svg
				[ngClass]="{ faint: !isGrammarlyActive }"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				width="23"
				height="23"
				viewBox="0 0 23 23"
			>
				<defs>
					<clipPath id="clip-path">
						<path
							id="Path_17692"
							data-name="Path 17692"
							d="M354,354.814a.742.742,0,0,0,.738.585h1.105l.641-.09a4.686,4.686,0,0,1-4.755,1.892,4.526,4.526,0,0,1-3.522-2.822,4.811,4.811,0,0,1,4.414-6.651,5.071,5.071,0,0,1,4.013,2.181.7.7,0,0,0,1.21-.723,6.19,6.19,0,1,0-.555,7.389l-.135.753v.682a.736.736,0,0,0,.581.738.715.715,0,0,0,.847-.7v-4.081H354.7a.718.718,0,0,0-.7.851Z"
							transform="translate(-346.419 -346.324)"
						/>
					</clipPath>
				</defs>
				<g id="Icon" transform="translate(0.121 0.006)">
					<circle id="Ellipse_6547" data-name="Ellipse 6547" cx="11.5" cy="11.5" r="11.5" transform="translate(-0.121 -0.006)" fill="#03c197" />
					<g id="Group_34578" data-name="Group 34578" transform="translate(5.176 5.173)">
						<g id="Group_34577" data-name="Group 34577" transform="translate(0 0)">
							<g id="Group_34576" data-name="Group 34576" clip-path="url(#clip-path)">
								<rect
									id="Rectangle_23431"
									data-name="Rectangle 23431"
									width="13.482"
									height="13.756"
									transform="translate(-0.656 -0.66)"
									fill="#fff"
								/>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
		<button class="ql-action" matTooltipClass="tooltipClass" [matTooltip]="'Erase'" (click)="clearTextEditor()">
			<svg xmlns="http://www.w3.org/2000/svg" width="19.918" height="17.428" viewBox="0 0 19.918 17.428">
				<path
					id="Icon_awesome-eraser"
					data-name="Icon awesome-eraser"
					d="M19.371,11.662a1.867,1.867,0,0,0,0-2.641L13.147,2.8a1.867,1.867,0,0,0-2.641,0L.547,12.756a1.867,1.867,0,0,0,0,2.641l3.735,3.735a1.867,1.867,0,0,0,1.32.547H19.451a.467.467,0,0,0,.467-.467V17.655a.467.467,0,0,0-.467-.467H13.845l5.526-5.526ZM7.6,9.226l5.344,5.344-2.619,2.619H5.86L2.748,14.076,7.6,9.226Z"
					transform="translate(0 -2.25)"
					fill="#121212"
				/>
			</svg>
		</button>
	</span>
</div>
<div class="editor-container" [ngClass]="{ assist: editorMode === editorModeEnum.Assist, editing: writingText }">
	<grammarly-editor-plugin>
		<div
			class=""
			(click)="disableEvent($event)"
			(mousedown)="disableEvent($event)"
			(mouseover)="disableEvent($event)"
			(mouseenter)="disableEvent($event)"
			#editorOutput
			id="editorOutput"
			[innerHTML]=""
		></div>
	</grammarly-editor-plugin>
	<button class="auto-complete-button" (click)="onAutoGenerate.emit()" *ngIf="showAiButton && !showGrammar" [style]="shakespeareButtonStyle">
		<img *ngIf="!isGeneratingOutput" src="assets/shakespeare/text-editor/shakespear-ai.svg" />
		<img *ngIf="isGeneratingOutput" src="assets/shakespeare/text-editor/loading.svg" class="fa fa-spin" />
	</button>
</div>
