<div class="wrapper">
	<!-- !create -->
	<div>
		<div class="title">Create</div>
		<div class="desc">Select Ad Campaign</div>

		<ng-container [ngTemplateOutlet]="campaignSelect" [ngTemplateOutletContext]="{ link: {} }" *ngIf="!showLoader"></ng-container>
		<ngx-skeleton-loader
			*ngIf="showLoader"
			appearance="line"
			style="height: 48px; width: 100%;"
			[theme]="{ width: '100%', height: '48px' }"
		></ngx-skeleton-loader>

		<div class="btn-wrapper">
			<button class="secondary-btn" (click)="close()">Cancel</button>
			<button class="primary-btn" (click)="addCalibration()" [disabled]="!form.value.campaign">Next</button>
		</div>
	</div>
</div>

<!-- !templates -->
<ng-template #campaignSelect>
	<app-custom-drpdown-op1
		[data]="campaigns"
		[dropdownFormControl]="form.get('campaign')"
		[useCustom]="false"
		[placeHolder]="'Campaign'"
		[searchEnable]="true"
		[noError]="false"
	></app-custom-drpdown-op1>
</ng-template>
