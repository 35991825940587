import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
// import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-knowledge-list',
	templateUrl: './knowledge-list.component.html',
	styleUrls: ['./knowledge-list.component.scss']
})
export class KnowledgeListComponent implements OnInit, OnDestroy {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
	selectedBaseList: any[];
	private unsubscriber$ = new Subject<void>();
	categories: any[];

	imgMap = {
		'Introducing Shakespeare': 'assets/icons/Shakespeare_icon_kb.svg',
		'Templates Guide': 'assets/shakespeare/templates_guide.svg',
		'Ad Copy': 'assets/shakespeare/ad-copy.svg',
		Emails: 'assets/shakespeare/email.svg',
		'Product Descriptions': 'assets/templates/product.svg',
		Blogs: 'assets/shakespeare/blog.svg',
		'Content Improver': 'assets/shakespeare/content.svg',
		'Social Media': 'assets/icons/social-k.svg',
		Frameworks: 'assets/icons/framework-k.svg',
		'Write From Scratch': 'assets/icons/write_from_scratch_kbase.svg',
		Projects: 'assets/icons/projects_kbase.svg',
		Library: 'assets/icons/library_kbase.svg',
		Documents: 'assets/icons/documents_kbase.svg',
		'Shakespeare Settings': 'assets/icons/settings-kbase.svg',
		'Ad Creation and Optimization': 'assets/img1.png',
		'Keyword and Audience Research': 'assets/img2.png',
		'Bidding and Budget Management': 'assets/img3.png',
		'Conversion Tracking and Analytics': 'assets/img4.png',
		Overview: 'assets/overview.png',
		'AI Optimize': 'assets/ai.png',
		'Ad Creative AI': 'assets/ads.png'
	};
	loader: boolean;

	constructor(
		private matDialog: MatDialog,
		public router: Router,
		public kbService: KnowledgebaseService,
		private toastNotificationService: ToastNotificationService
	) {}

	public shakespeareBaseList: any[] = [
		{
			title: 'Shakespeare',
			name: 'shakespeare',
			imgUrl: '',
			description: ''
		}
	];
	ngOnInit(): void {
		this.loader = true;
		this.kbService
			.getCategories()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					console.log(response);
					this.categories = response['data'];
					this.categories = this.categories.filter(item => {
						return item.name !== 'Shakespeare.ai' && item.name !== 'Templates Guide' && item.name !== "Shakespeare's Settings";
					});
					this.loader = false;
					let ver = JSON.parse(localStorage.getItem('decodedJwtIo')).sign_up_version;
					console.log(ver, 'ver');
					if (ver == 'v3' || ver == 'v2') {
						let v3Cat = ['Introducing Shakespeare', 'Overview', 'AI Optimize', 'Ad Creative AI'];
						this.categories = this.categories.filter(v => {
							return v3Cat.includes(v.name);
						});
						console.log(this.categories, 'cat');
					}
				},
				error: () => {
					this.loader = false;
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			});
		// this.selectedBaseList = this.shakespeareBaseList;
		// this.showKnowledgeView(this.selectedBaseList[0].name);
	}

	public showKnowledgeView(search?: string): void {
		this.kbService.knowledgebaseHome$.next(false);
		this.kbService.knowledgebaseShowView$.next(true);
		this.kbService.searchValue$.next(search);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.unsubscribe();
	}
}
