<div class="{{ styleClass }}">
	<app-text-input
		styleClass="icon-right {{ inputStyleClass }}"
		[control]="control"
		[maxLength]="maxLength"
		[inputPlaceholder]="inputPlaceholder"
		[inputType]="'email'"
		(keyInputEvent)="keyInputEvent.emit($event)"
	></app-text-input>
	<div class="error-container" *ngIf="control.invalid && control.touched">
		<span>{{ errorMessage }}</span>
	</div>
</div>
