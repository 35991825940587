import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class V3enterpriseService {
	welcomeModelData: any;

	constructor() {}
}
