<div class="popup-card">
	<div class="popup-content">
		<div class="icon-div">
			<button mat-dialog-close aria-label="Close" (click)="closeCommonPopup()">
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<p class="heading">Thank you for expressing your interest in our AI Creative! 🚀</p>
		<div class="sub-text-div">
			<p class="sub-text">
				We're thrilled to have you on board. You will be among the first to know about our release. We'll notify you via email with all the exciting
				details as soon as it's ready. We value your privacy and promise not to spam your inbox.
			</p>
		</div>
	</div>
</div>
