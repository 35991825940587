import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { KnowledgebaseComponent } from 'src/app/sidenav/component-pieces/knowledgebase/knowledgebase.component';
import { KnowledgebaseService } from '../services/knowledgebase.service';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
	@ViewChild('tooltipPosition') public toolPosition: ElementRef;
	@Input() text = 'Tooltip text';
	@Input() position = 'above'; // can be 'below'
	@Input() noPlaceLeft = false;
	@Input() noPlaceRight = false;
	@Input() noPlaceBottom = false;
	@Input() imageEditor = false;
	@Input() imageEditorWidth = '0';
	@Input() large = false;
	@Input() static = false;
	@Input() link = '';
	@Input() linkRoute = '';
	@Input() openModal = false;
	@Input() categoryType: string = '';
	@Input() textColor: string = '#4a4a4a';
	makeActive: boolean;
	timer: ReturnType<typeof setTimeout>;
	public width: string = '';

	constructor(private router: Router, private dialog: MatDialog, private shakespeareService: ShakespeareService, private kbService: KnowledgebaseService) {}

	ngOnInit(): void {}

	@HostListener('document:click')
	clickout() {
		this.makeActive = false;
	}

	public showKnowledgeView(search?: string): void {
		setTimeout(() => {
			this.kbService.knowledgebaseHome$.next(false);
			this.kbService.knowledgebaseShowView$.next(true);
			this.kbService.searchValue$.next(search);
		}, 300);
	}

	goTo(): void {
		if (this.openModal) {
			this.openDialog();
			return;
		}
		if (this.linkRoute) {
			console.log('this.linkRoute', this.linkRoute);
			this.router.navigate([this.linkRoute]);
		}
	}

	makeTooltipActive(): void {
		this.makeActive = true;
		const nativeElement = this.toolPosition?.nativeElement;
		const width = nativeElement?.offsetParent?.clientWidth - (nativeElement?.offsetLeft + 53);
		// this.width = `${width}px`;
	}

	inActiveTimer(): void {
		this.makeTooltipActive();
		clearTimeout(this.timer);
		if (this.link == '') {
			this.makeActive = false;
		} else {
			this.timer = setTimeout(() => {
				this.makeActive = false;
			}, 500);
		}
	}

	makeInActive(): void {
		this.makeActive = false;
	}

	openDialog(isMobile = false): void {
		this.dialog.open(KnowledgebaseComponent, {
			width: isMobile ? '100%' : '600px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
		switch (this.categoryType) {
			case 'templates':
			case 'all-templates':
				this.showKnowledgeView('Templates Guide');
				break;
			case 'ad-copy':
				this.showKnowledgeView('Ad-Copy');
				break;
			case 'email':
				this.showKnowledgeView('Emails');
				break;
			case 'product-description':
				this.showKnowledgeView('Product Descriptions');
				break;
			case 'blogs':
				this.showKnowledgeView('Blogs');
				break;
			case 'content-improver':
				this.showKnowledgeView('Content Improver');
				break;
			case 'social':
				this.showKnowledgeView('Social Media');
				break;
			case 'frameworks':
				this.showKnowledgeView('Frameworks');
				break;
			case 'projects':
				this.showKnowledgeView('Projects, Library, & Documents');
				break;
			case 'Library':
				this.showKnowledgeView('Library');
				break;
			case 'Documents':
				this.showKnowledgeView('Documents');
				break;
		}
	}
}
