import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PaginationInstance } from 'ngx-pagination';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { Subject } from 'rxjs';
import { ShopifyService } from '../shopify.service';

@Component({
	selector: 'app-side-list',
	templateUrl: './side-list.component.html',
	styleUrls: ['./side-list.component.scss']
})
export class SideListComponent implements OnInit {
	@Input() isDropdown: false;
	@Input() products = [];
	@Output() public pageNumber: EventEmitter<number> = new EventEmitter();
	@Output() public selectedItem: EventEmitter<object> = new EventEmitter();
	@Output() public sortType: EventEmitter<string> = new EventEmitter();
	radioForm: FormGroup;

	@ViewChild('p') pagination;
	public config: PaginationInstance = {
		id: '1',
		itemsPerPage: 30,
		currentPage: 1,
		totalItems: 0
	};
	menuExpanded: any;
	selectedItemIndex = 0;
	productId: number;
	filedId: number;
	private unsubscriber$ = new Subject<void>();
	loading: boolean;
	backup: any;

	controlA = new FormControl();
	controlB = new FormControl();
	controlC = new FormControl();
	tags: any[];
	types: any[];
	vendor: any[];
	collectionId = null;
	productType = null;
	productVendor = null;
	productsLoading: boolean;

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private activatedRoute: ActivatedRoute,
		private userStore: Store<UserState>,
		private shopifyService: ShopifyService
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(id => {
			this.filedId = id;
		});
		this.backup = [...this.products];
	}

	ngOnInit(): void {
		this.getFilterValues();
		this.activatedRoute.queryParams.subscribe(val => {
			this.productId = val.productId;
		});
		let selectedProduct = this.products.find((item, index) => {
			if (item.id == this.productId) {
				this.selectedItemIndex = index;
				console.log(this.selectedItemIndex, item);
				return item;
			}
		});
		this.selectedItem.emit(selectedProduct);
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
		this.radioForm = new FormGroup({
			radio1: new FormControl(false)
		});
		for (let i = 0; i < this.products?.length; i++) {
			this.radioForm.addControl('radio' + i, new FormControl(false));
		}
	}

	getFilterValues(): void {
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {}
		};
		this.shopifyService
			.getFilterValues(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.tags = this.mapFilterValues(response['data'].collection);
					this.types = this.mapArrayValues(response['data'].productTypes);
					this.vendor = this.mapArrayValues(response['data'].productVendors);
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	expandProducts(): void {
		this.router.navigate(['shopify/all-products'], { queryParams: { isExpanded: true } });
	}

	mapFilterValues(arr: any[]): any[] {
		let newArr = arr.map(item => {
			return { name: item.title, ...item };
		});
		return newArr;
	}

	mapArrayValues(arr: any[]): any[] {
		let newArr = arr.map(item => {
			return { name: item };
		});
		return newArr;
	}

	clearFilters(): void {
		this.controlA.setValue([]);
		this.controlB.setValue([]);
		this.controlC.setValue([]);
		this.collectionId = null;
		this.productType = null;
		this.productVendor = null;
		this.getAllProducts();
	}

	getAllProducts(page = 1): void {
		this.productsLoading = true;
		this.products = [];
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: page
			}
		};
		this.shopifyService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.products = response['data'];
					this.products.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.backup = [...this.products];
					this.productsLoading = false;
				},
				() => {
					this.productsLoading = false;
				},
				() => {}
			);
	}

	getFilteredProducts(type: string, value: string, page = 1): void {
		if (value == undefined) {
			value = '_cleared_';
		}
		switch (type) {
			case 'product_type':
				this.productType = value['name'];
				break;
			case 'product_vendor':
				this.productVendor = value['name'];
				break;
			case 'collection_id':
				this.collectionId = value['name'];
				break;
		}
		this.products = [];
		this.productsLoading = true;
		console.log(value);
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: page
			}
		};
		this.productType !== '_cleared_' && (payload.data['product_type'] = this.productType);
		this.productVendor !== '_cleared_' && (payload.data['product_vendor'] = this.productVendor);
		this.collectionId !== '_cleared_' && (payload.data['collection_id'] = this.collectionId);

		this.shopifyService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.products = response['data'];
					this.products.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.backup = [...this.products];
					this.productsLoading = false;
				},
				() => {
					this.productsLoading = false;
				},
				() => {}
			);
	}

	checkAll(event: Event): void {
		let checked = event.currentTarget['checked'];
		if (checked) {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(true);
			});
		} else {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(false);
			});
		}
	}

	public setPage(page: number): void {
		this.config.id = page.toString();
		this.pageNumber.emit(page);
	}

	getData(): void {}

	sortProducts(type: string): void {
		this.sortType.emit(type);
	}

	searchTemplate(event: Event): void {
		this.products = this.backup;
		this.products = this.products.filter(item => {
			return item.title.toLowerCase().includes(event);
		});
	}

	selectItem(item: object, index: number): void {
		this.selectedItemIndex = index;
		this.selectedItem.emit(item);
	}

	openEditor(): void {
		this.router.navigate(['shopify/generate']);
	}
}
