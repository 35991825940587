import { takeUntil } from 'rxjs/operators';
import { UserServiceApi } from './../../_services/user/user.api.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ModalInterface } from 'src/app/user-management/models/DatatableEnum';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { UserState, getFiledId } from '../state/user/user.reducer';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
	selector: 'app-create-new-modal',
	templateUrl: './create-new-modal.component.html',
	styleUrls: ['./create-new-modal.component.scss']
})
export class CreateNewModalComponent implements OnInit {
	@Input() public modelDetail: any;
	public error: boolean;
	public name: string;
	public foldersArray = [];
	private unsubscriber$ = new Subject<void>();
	public filedId: number;
	public projectId: string;
	public folderId: string;
	public generatorForm: FormGroup;
	public projectForm: FormGroup;
	iPhoneMobile: boolean;

	constructor(
		private dialogRef: MatDialogRef<CreateNewModalComponent>,
		private userStore: Store<UserState>,
		private userServiceApi: UserServiceApi,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
	}

	ngOnInit(): void {
		this.projectForm = this.fb.group({
			project: new FormControl()
		});

		if (this.modelDetail?.title == 'folder') {
			this.getFolders();
		} else {
			this.getProjects();
		}
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
	}

	ngOnChanges(): void {}

	public closeDialog(): void {
		this.dialogRef.close(false);
	}

	public onChange(event): void {
		if (this.modelDetail?.title == 'folder') {
			this.folderId = event?.id;
		} else {
			this.projectId = event?.id;
		}
	}

	public getFolders(): void {
		this.foldersArray = [];
		this.data.selectedProject.folder.forEach(ele => {
			this.foldersArray.push({ id: ele.folderId, name: ele.folderName });
		});
		// let payload = {
		// 	user: {
		// 		userFiledId: this.filedId
		// 	},
		// 	data: {
		// 		project_id: this.data.selectedProjectId
		// 	}
		// };
		// this.userServiceApi
		// 	.getUserFolders(payload)
		// 	.pipe(takeUntil(this.unsubscriber$))
		// 	.subscribe(
		// 		response => {
		// 			this.foldersArray = [...response['body']['data']];
		// 			for (let item of this.foldersArray) {
		// 				item['isFav'] = false;
		// 				item.id = item.folderId;
		// 				item.name = item.folderName;
		// 			}
		// 		},
		// 		() => { },
		// 		() => { }
		// 	);
	}

	public getProjects(): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				folderId: ''
			}
		};
		this.userServiceApi
			.getUserProjects(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.foldersArray = [...response['body']['projects']];
					for (let item of this.foldersArray) {
						item['isFav'] = false;
					}
				},
				() => {},
				() => {}
			);
	}

	public onSubmit(): void {
		if (!this.name || this.name?.length == 0) {
			this.error = true;
		} else {
			this.dialogRef.close({ value: this.name });
		}
	}

	public onSubmitExisting(): void {
		if (this.modelDetail?.title == 'folder') {
			this.dialogRef.close({ id: this.folderId });
		} else {
			if (!this.projectId || this.projectId?.length == 0) {
				this.error = true;
			} else {
				this.dialogRef.close({ id: this.projectId });
			}
		}
	}

	public actionButtonClick(): void {
		this.dialogRef.close();
	}
}
