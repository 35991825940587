<div class="shopify-container" [ngClass]="{ expanded: menuExpanded }">
	<p class="main-title">Dashboard</p>
	<div *ngIf="prodLoading || optLoading || historyLoading || newlyLoading || perfLoading" [ngStyle]="{ width: '100%' }">
		<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
	</div>
	<div class="wrapper" *ngIf="!prodLoading && !optLoading && !historyLoading && !newlyLoading && !perfLoading">
		<div class="box box-1">
			<div class="head">
				<p class="title">Published Descriptions</p>
				<app-button buttonClass="link-primary" (onClick)="viewProducts('optimized')">View All</app-button>
			</div>
			<div class="content progress">
				<app-circular-progress [value]="Math.round((optimizedProducts / totalProducts) * 100)" color="#006BFF"></app-circular-progress>
			</div>
		</div>
		<div class="box box-2">
			<div class="head">
				<p class="title">Un-Optimized</p>
				<app-button buttonClass="link-primary" (onClick)="viewProducts('unoptimized')">View All</app-button>
			</div>
			<div class="content progress">
				<app-circular-progress [value]="Math.round((unOptimizedProducts / totalProducts) * 100)" color="#DA69F5"></app-circular-progress>
			</div>
		</div>
		<div class="box box-3 special">
			<div class="upper">
				<div class="header">
					<p class="title">Top Performing</p>
					<p class="value">{{ totalPerforming }}</p>
				</div>
				<div class="range">
					<div class="value" [style.width]="(totalPerforming / totalProducts) * 100 + '%'"></div>
				</div>
			</div>
			<div class="lower">
				<div class="header">
					<p class="title">Non-Performing</p>
					<p class="value">{{ totalNonPerforming }}</p>
				</div>
				<div class="range">
					<div class="value" [style.width]="(totalNonPerforming / totalProducts) * 100 + '%'"></div>
				</div>
			</div>
		</div>
		<div class="box box-4">
			<div class="head">
				<p class="title">All Products</p>
				<app-button buttonClass="link-primary" (onClick)="viewAllProducts()">View All</app-button>
			</div>
			<div class="content">
				<p class="empty-array" *ngIf="!allProducts.length">No items found</p>
				<div class="item" *ngFor="let item of allProducts | slice: 0:4" (click)="openProduct(item?.id)">
					<img [src]="item.images[0]" alt="" />
					<p class="item-name">{{ item.title }}</p>
				</div>
			</div>
		</div>
		<div class="box box-5">
			<div class="head">
				<p class="title">Newly Generated</p>
				<app-button buttonClass="link-primary" (onClick)="viewAllNewProducts()">View All</app-button>
			</div>
			<div class="content">
				<p class="empty-array" *ngIf="!newItems.length && !flagLoading">No items found</p>
				<div *ngIf="flagLoading">
					<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
					<div class="flag-text">
						<p class="p1">Please wait, Ai is generating your new descriptions.</p>
						<p class="p2">This process can take 10 to 15 minutes.</p>
					</div>
				</div>
				<div class="item" *ngFor="let item of newItems | slice: 0:4" (click)="openProduct(item?.productId)">
					<img [src]="item.images[0]" alt="" />
					<p class="item-name">{{ item.generatedData.title }}</p>
				</div>
			</div>
		</div>
		<!-- <div class="box box-6">
			<div class="head">
				<p class="title disabled">Recommendation</p>
				<app-button buttonClass="link-primary">View All</app-button>
			</div>
			<div class="content">
				<p class="empty-array coming-soon" *ngIf="!otherItems.length">Coming soon...</p>
				<div class="item" *ngFor="let item of otherItems | slice: 0:4">
					<img [src]="item.img" alt="" />
					<p class="item-name dark">{{ item.name }}</p>
				</div>
			</div>
		</div> -->
		<div class="box box-7">
			<div class="head">
				<p class="title">History</p>
				<app-button buttonClass="link-primary" (onClick)="showHistory()">View All</app-button>
			</div>
			<div class="content">
				<p class="empty-array" *ngIf="!historyData.length">No items found</p>
				<div class="item" *ngFor="let item of historyData | slice: 0:4" (click)="openProduct(item?.productId)">
					<img [src]="(item?.images)[0]" alt="" />
					<p class="item-name">{{ item?.title }}</p>
				</div>
			</div>
		</div>
	</div>
</div>
