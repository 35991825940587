<form [formGroup]="generatorForm">
	<app-text-input-with-label
		[control]="generatorForm.controls['details']"
		[maxLength]="200"
		placeholder="Enter here ..."
		inputLabel="Details"
	></app-text-input-with-label>

	<div class="d-part input">
		<div class="input-box">
			<div class="label dropdown">
				<label>Output Tone</label>
				<app-tooltip text="You can type your own Tone"></app-tooltip>
			</div>
			<app-custom-drpdown-op1
				[data]="outputTone"
				(selectionChange)="changeToneSource.emit($event)"
				[useCustom]="true"
				[dropdownFormControl]="generatorForm?.get('outputTone')"
				[placeHolder]="'Select/Enter new'"
				[searchEnable]="true"
				[noError]="true"
			></app-custom-drpdown-op1>
			<div class="error-field">
				<span *ngIf="!generatorForm.controls.outputTone.valid">This field is required</span>
			</div>
		</div>
	</div>
	<div class="d-part input showMobile">
		<div class="input-box">
			<div class="label dropdown">
				<label>No of Outputs</label>
			</div>
			<app-custom-drpdown-op1
				[data]="maxOutputArray"
				[dropdownFormControl]="generatorForm?.get('numOutputs')"
				[useCustom]="false"
				[defaultSelectedValue]="{ name: '3 Outputs', value: 3 }"
				[hideClearButton]="true"
				[placeHolder]="'Output'"
			></app-custom-drpdown-op1>
		</div>
	</div>
	<div class="input radio-input" *ngIf="selectedTemplate.title == 'Full Blog'">
		<div class="label">
			<label>Length</label>
		</div>
		<div class="custom-radio-btn">
			<div class="radio-btn" (click)="onChangeLength('small')" [ngClass]="{ selected: blogLength === 'small' }">Small</div>
			<div class="radio-btn" (click)="onChangeLength('medium')" [ngClass]="{ selected: blogLength === 'medium' }">Medium</div>
			<div class="radio-btn" (click)="onChangeLength('long')" [ngClass]="{ selected: blogLength === 'long' }">Long</div>
		</div>
	</div>
	<div class="input" *ngIf="selectedTemplate.title == 'Blog Ideas' || selectedTemplate.title == 'Blog Outline'">
		<div class="input-lang">
			<div class="label dropdown">
				<label>Output Language (Optional)</label>
			</div>
			<app-custom-drpdown-op1
				[data]="outputLang"
				(selectionChange)="changeLangSource($event)"
				[dropdownFormControl]="generatorForm?.get('outputLang')"
				[useCustom]="false"
				[searchEnable]="true"
				[placeHolder]="'Select Language'"
			></app-custom-drpdown-op1>
		</div>
	</div>
	<div class="input toggle" *ngIf="selectedTemplate.title != 'Blog Outline' && selectedTemplate.title != 'Blog Ideas'">
		<div class="d-flex">
			<div class="label">
				<label>Advance Option</label>
				<app-tooltip noPlaceBottom="true" text="Describe more about your blog for better results"></app-tooltip>
			</div>
		</div>
		<app-switch (toggle)="checkToggleValue($event)" [value]="advanceOptions"></app-switch>
	</div>
	<div *ngIf="advanceOptions" class="advance">
		<div class="input spec">
			<div class="d-flex align-items-center title">
				<div class="label">
					<label>Keywords</label>
					<app-tooltip text="Add keywords to generate SEO-optimized content"></app-tooltip>
				</div>
			</div>
			<app-input-with-add-more
				[inputPlaceholders]="keywordPlaceHolders"
				[control]="generatorForm.get('keywords')"
				errorMessage="Please fill this field."
				[maxInput]="5"
			></app-input-with-add-more>
		</div>
		<div class="input">
			<div class="input-lang">
				<div class="label dropdown">
					<label>Output Language (Optional)</label>
				</div>
				<app-custom-drpdown-op1
					[data]="outputLang"
					(selectionChange)="changeLangSource($event)"
					[dropdownFormControl]="generatorForm?.get('outputLang')"
					[useCustom]="false"
					[placeHolder]="'Select Language'"
				></app-custom-drpdown-op1>
			</div>
		</div>
	</div>
</form>
