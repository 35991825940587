import { Component, OnInit } from '@angular/core';
import { OverviewApiService } from '../../services/overview-api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { OverviewDataService } from '../../services/overview-data.service';

@Component({
	selector: 'app-google-overview-ad-accounts',
	templateUrl: './google-overview-ad-accounts.component.html',
	styleUrls: ['./google-overview-ad-accounts.component.scss']
})
export class GoogleOverviewAdAccountsComponent implements OnInit {
	localStorageValue: any = JSON.parse(localStorage.getItem('decodedJwtIo'));
	overallDataList: any;
	googleADaccountData: any;
	fromDate: any;
	toDate: any;
	showLoader: boolean = false;
	// range: FormGroup;
	constructor(
		private overviewApiService: OverviewApiService,
		private router: Router,
		private fb: FormBuilder,
		private overviewDataService: OverviewDataService
	) {
		// const oneMonthAgo = new Date();
		// oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
		// this.range = this.fb.group({
		// 	start: [oneMonthAgo],
		// 	end: [new Date()]
		// });
		// this.range.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
		// 	this.getOverViewCards();
		// });
	}

	ngOnInit(): void {
		if (this.overviewDataService.overviewGoogleDataList) {
			this.overallDataList = JSON.parse(JSON.stringify(this.overviewDataService.overviewGoogleDataList));
			this.googleADaccountData = JSON.parse(JSON.stringify(this.overviewDataService.googleAdAccount));
		} else {
			this.getGoogleAdAccount();
		}
	}

	getOverViewCards() {
		this.showLoader = true;
		let payload = {
			user: {
				user_filed_id: this.localStorageValue.user_filed_id
			},
			data: {
				num_accounts: 'ALL',
				date_range: {
					from_date: this.fromDate,
					to_date: this.toDate
				}
			}
		};
		this.overviewApiService.getCardsData(payload).subscribe(
			res => {
				this.overallDataList = res.data;
				this.mergeAdAccountwithoverallDataList();
			},
			error => {
				this.showLoader = false;
			}
		);
	}

	getGoogleAdAccount() {
		this.overviewApiService.getGoogleAdAccount().subscribe(res => {
			this.googleADaccountData = res;
			this.mergeAdAccountwithoverallDataList();
		});
	}

	mergeAdAccountwithoverallDataList() {
		if (this.overallDataList && this.googleADaccountData) {
			for (let ad of this.googleADaccountData) {
				for (let ovl of this.overallDataList) {
					if (ad.id == ovl.adAccountId) {
						ovl.adAccountName = ad.name;
						ovl.currency = ad.currency;
						ovl.currencySymbol = this.overviewDataService.currencySymbol[ad.currency];
					}
				}
			}
			this.showLoader = false;
			this.overviewDataService.overviewGoogleDataList = JSON.parse(JSON.stringify(this.overallDataList));
		}
	}

	goBack() {
		this.router.navigate(['overview']);
	}

	formatDate(date: Date): string {
		// Get the date components in the "YYYY-MM-DD" format
		const year = date?.getFullYear();
		const month = String(date?.getMonth() + 1).padStart(2, '0');
		const day = String(date?.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	}

	// hello(type: any, event: any) {
	// 	if (this.range.value.start && this.range.value.end) {
	// 		this.getOverViewCards();
	// 	}
	// }

	initialiseStartDate(startDate: any) {
		console.log('fromOverView=>StartDate', startDate);
		this.fromDate = startDate;
	}

	initialiseEndDate(endDate: any) {
		console.log('fromOverView=>endDate', endDate);
		this.toDate = endDate;
		this.dateChange();
	}

	dateChange() {
		if (this.fromDate != localStorage.getItem('fromDate') && this.toDate != localStorage.getItem('fromDate')) {
			localStorage.setItem('fromDate', this.fromDate);
			localStorage.setItem('toDate', this.toDate);
			this.getOverViewCards();
			this.overviewDataService.insideCardComponentData = [];
		}

		if (!this.overviewDataService.overviewGoogleDataList) {
			if (this.fromDate && this.toDate) {
				this.getOverViewCards();
			}
		}
	}
}
