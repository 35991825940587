<div class="notification-body">
	<ng-container *ngFor="let notification of notifications; let i = index">
		<div class="menu-list-item-notif {{ notification?.unreadRead ? 'unread' : '' }}">
			<div class="d-flex flex-column w-100 notification-col">
				<div class="title w-100 d-flex align-items-center justify-content-between">
					<div class="info-wrapper">
						<div class="icon">
							<!-- <div class="blank-icon" *ngIf="!notification.adAcc"></div> -->
							<img [src]="platformImage(notification.platform)" class="platform" />
							<img [src]="actionImage(notification)" class="stats" *ngIf="notification.adAcc === true" />
						</div>
						<div class="message-wrapper">
							<div class="message">
								{{ notification?.message }}
							</div>
							<div class="date">
								{{ notification?.createdOn | date: 'mediumDate' }}
								{{ (notification?.createdOn | date: 'shortTime') + '(UTC)' }}
							</div>
						</div>
					</div>

					<!-- !action menu -->
					<div class="d-flex align-items-center justify-content-between" *ngIf="notification?.unreadRead">
						<div class="more-btn" [matMenuTriggerFor]="actionsMenu" #actionsMenuTrigger="matMenuTrigger">
							<img class="icon-btn" src="assets\settings\notifications\more-horizontal.svg" />
						</div>
						<mat-menu #actionsMenu="matMenu" class="edit-notification-menu" [overlapTrigger]="true" xPosition="before">
							<div class="edit-body-wrapper">
								<div class="edit-menu-item" (click)="markAsRead(notification, i); $event.stopPropagation(); actionsMenuTrigger.closeMenu()">
									<img class="edit-icon mr-s" src="assets\settings\notifications\Icon awesome-glasses.svg" />
									<div class="d-flex w-100 flex-column">
										<span class="txt-b">Mark as read</span>
									</div>
								</div>
							</div>
						</mat-menu>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
