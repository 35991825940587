import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CancelModalService } from '../cancel-modal.service';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
	public feedback = '';
	iPhoneMobile: boolean;

	constructor(private dialogRef: MatDialogRef<FeedbackComponent>, @Inject(MAT_DIALOG_DATA) public data, private cancelModalService: CancelModalService) {}

	ngOnInit(): void {
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
	}

	public closeModal(): void {
		this.dialogRef.close();
	}

	public proceedToCancel(type: string): void {
		this.cancelModalService.cancelReason$.next(this.feedback);
		this.dialogRef.close({ type: type });
	}

	public changeStepType(type: string): void {
		this.dialogRef.close({ type: type });
	}
}
