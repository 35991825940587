import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { BigCommerceService } from '../big-commerce.service';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
	private unsubscriber$ = new Subject<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private bigCommerceService: BigCommerceService,
		private store: Store<AuthenticationState>,
		private ngZone: NgZone
	) {}

	ngOnInit(): void {
		this.getActivatedRoute();
	}

	getActivatedRoute() {
		this.activatedRoute.queryParams.subscribe(params => {
			let paramToken = params['token'];
			this.verifyToken(paramToken);
		});
	}

	verifyToken(token: string): void {
		this.bigCommerceService
			.verifyToken(token)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					if (response) {
						this.ngZone.run(() => {
							this.store.dispatch(new LogInSuccess(response));
						});
					}
				}
			});
	}
}
