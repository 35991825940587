<div class="wrapper">
	<div class="header-wrapper">
		<div class="header-title">
			<button class="back-button" routerLink="/templates">
				<img src="assets/mobile-back-button.svg" alt="back button" />
			</button>
			<img class="hide-mobile" src="assets/templates/wizard.svg" alt="" />
			<span>Blog Wizard</span>
		</div>
		<!-- <div class="stepper">
			<div class="horizontal-stepper">
				<ng-container *ngFor="let step of steps; let i = index">
					<div (click)="stepClick(step.id)" [class.step-active]="step?.id === activeStep" [class.step-complete]="step?.id < activeStep" class="step">
						<div class="circle-wrapper">
							<div class="circle">
								<span>{{ step.id }}</span>
							</div>
						</div>
						<div class="title">
							<span>{{ step.label }}</span>
						</div>
					</div>
				</ng-container>
			</div>
		</div> -->
	</div>
	<section [class.editing-text]="!isEditOff">
		<div class="container">
			<!--generate-->
			<div class="card" [class.selected-card]="activeStep == 1">
				<div class="card-header k-pb-0">
					<h4 class="card-title-cs" [class.active]="activeStep == 1">Generate Headline</h4>
				</div>
				<div class="form">
					<div class="form-con">
						<form [formGroup]="blogForm">
							<app-text-input-with-label
								inputLabel="Topic"
								[control]="blogForm.controls['topic']"
								[maxLength]="80"
								[placeholder]="'Enter here ...'"
							></app-text-input-with-label>
							<div class="input">
								<div class="input-box">
									<div class="label dropdown">
										<label>Output Tone</label>
									</div>
									<app-custom-drpdown-op1
										[data]="outputTone"
										[useCustom]="true"
										[dropdownFormControl]="blogForm.get('tone')"
										[placeHolder]="'Select/Enter new'"
									></app-custom-drpdown-op1>
								</div>
								<div *ngIf="toneError" class="error-field">
									<span>This field is required.</span>
								</div>
							</div>
							<div class="input spec">
								<div class="d-flex align-items-center my-1">
									<div class="label">
										<label>Keywords</label>
										<app-tooltip text="Add the keyword which you want in your content for seo"></app-tooltip>
									</div>
								</div>
								<div *ngFor="let item of keywordHelper; let i = index; let last = last" class="mb-3">
									<div class="input-con">
										<input
											type="text"
											class="custom-input"
											[placeholder]="item.placeholder"
											[id]="'keyword' + i"
											autocomplete="off"
											(input)="removeError(i, $event, 'keywordError')"
											(keyup.enter)="addMoreHandler(last, i, 'keyword', 'keywordError')"
										/>
										<div
											*ngIf="i < 2"
											[ngClass]="last == true ? 'add-input' : 'remove-input'"
											(click)="addMoreHandler(last, i, 'keyword', 'keywordError')"
										>
											<i [ngClass]="last == true ? 'fas fa-plus' : 'fas fa-times'"></i>
										</div>
									</div>
									<div [id]="'keywordError' + i" class="error-field none">
										<span>Please fill this field.</span>
									</div>
								</div>
							</div>
							<div class="lang-split">
								<div class="input">
									<div class="input-box">
										<div class="label dropdown">
											<label>Output Language (Optional)</label>
										</div>
										<app-custom-drpdown-op1
											[data]="outputLang"
											[useCustom]="false"
											[dropdownFormControl]="blogForm.get('outputLanguage')"
											[placeHolder]="'Select Language'"
										></app-custom-drpdown-op1>
									</div>
								</div>
								<div class="input">
									<div class="input-box">
										<div class="label dropdown">
											<label>No. of Outputs</label>
										</div>
										<app-custom-drpdown-op1
											[data]="maxOutputArray"
											[dropdownFormControl]="blogForm.get('numOutputs')"
											[useCustom]="false"
											[placeHolder]="'Select no. of outputs'"
										></app-custom-drpdown-op1>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="card-footer no-wrap">
					<app-button (onClick)="resetForm()" buttonClass="secondary" icon="k-icon k-i-close" iconPlace="before">CLEAR INPUTS</app-button>
					<app-button (onClick)="generateHeadline()" [disabled]="blogForm.invalid" buttonClass="primary" icon="fas fa-arrow-right">
						GENERATE HEADLINE
					</app-button>
				</div>
			</div>
			<!--headline-->
			<div class="card" id="headlineScroll" [class.selected-card]="activeStep == 2" [class.disable]="headlineSubmitted">
				<div class="card-header">
					<h4 class="card-title-cs" [class.active]="activeStep == 2">Headline</h4>
				</div>
				<div class="card-body">
					<form [formGroup]="headlineForm">
						<div class="spin-holder" *ngIf="isLoadingData && loadingStep == 1">
							<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
							<p class="wait-text" *ngIf="waitTextShow">Please give Shakespeare a moment to think</p>
						</div>
						<div class="card-form-wrapper" *ngFor="let headline of headlines; let i = index">
							<div class="form-check radio">
								<input
									type="radio"
									[attr.name]="'headline' + i"
									formControlName="headline"
									[value]="headline"
									class="form-check-input"
									[id]="'headlineRadio' + i"
								/>
								<label class="form-check-label" [id]="'headline' + i" [for]="'headlineRadio' + i">{{ headline }}</label>
							</div>
							<div class="card-action">
								<button
									class="btn btn-primary"
									matTooltip="Edit"
									[matTooltipPosition]="'above'"
									(click)="editText('headline', i)"
									type="button"
								>
									<em [ngClass]="currentEditIndex === i && editId == 'headline' ? checkIcon : editIcon"></em>
								</button>
								<button
									class="btn btn-primary"
									[matTooltip]="isCopied ? 'Copied' : 'Copy'"
									[matTooltipPosition]="'above'"
									(click)="copyText('headline' + i, $event)"
									type="button"
								>
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="card-footer" *ngIf="activeStep == 2">
					<div class="clear" [ngClass]="{ hideSkip: skipFuncBtn[skipStep + 1] == 'FINISH' }">
						<!-- <button (click)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData">{{ skip[skipStep + 1] }}</button> -->
						<app-button (onClick)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData" buttonClass="link-tertiary">
							{{ skip[skipStep + 1] }}
						</app-button>
					</div>
					<div class="footer-right">
						<app-button (onClick)="generateHelper('headline')" [disabled]="waitTextShow || isLoadingData" buttonClass="secondary">
							GENERATE MORE
						</app-button>
						<app-button
							*ngIf="!introSkipped"
							(onClick)="generateHelper('introduction')"
							[disabled]="checkLastWizSelcted() || isLoadingData"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							GENERATE INTRODUCTION
						</app-button>
						<app-button
							*ngIf="introSkipped"
							(onClick)="generateHelper(skipFunc[skipStep], true)"
							[disabled]="checkLastWizSelcted()"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							{{ skipFuncBtn[skipStep + 1] }}
						</app-button>
					</div>
				</div>
			</div>
			<!--Introduction-->
			<div class="card" id="introScroll" [class.selected-card]="activeStep == 3" *ngIf="!introSkipped" [class.disable]="introSubmitted">
				<div class="card-header">
					<h4 class="card-title-cs" [class.active]="activeStep == 3">Introduction</h4>
				</div>
				<div class="card-body">
					<form [formGroup]="introForm">
						<div class="spin-holder" *ngIf="isLoadingData && loadingStep == 2">
							<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
							<p class="wait-text" *ngIf="waitTextShow">Please give Shakespeare a moment to think</p>
						</div>
						<div class="card-form-wrapper" *ngFor="let intro of intros; let i = index">
							<div class="form-check radio">
								<input
									type="radio"
									[attr.name]="'intro' + i"
									formControlName="intro"
									[value]="intro"
									class="form-check-input"
									[id]="'introRadio' + i"
								/>
								<label class="form-check-label" [id]="'intro' + i" [for]="'introRadio' + i" [innerHTML]="intro"></label>
							</div>
							<div class="card-action">
								<!-- <button class="btn btn-primary" (click)="editText('intro', i)" type="button">
									<em [ngClass]="currentEditIndex === i && editId == 'intro' ? checkIcon : editIcon"></em>
								</button>
								<button class="btn btn-primary" (click)="copyText('intro' + i)" type="button">
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button> -->
								<button class="btn btn-primary" matTooltip="Edit" [matTooltipPosition]="'above'" (click)="editText('intro', i)" type="button">
									<em [ngClass]="currentEditIndex === i && editId == 'intro' ? checkIcon : editIcon"></em>
								</button>
								<button
									class="btn btn-primary"
									[matTooltip]="isCopied ? 'Copied' : 'Copy'"
									[matTooltipPosition]="'above'"
									(click)="copyText('intro' + i, $event)"
									type="button"
								>
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="card-footer" *ngIf="activeStep == 3">
					<div class="clear" [ngClass]="{ hideSkip: skipFuncBtn[skipStep + 1] == 'FINISH' }">
						<app-button (onClick)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData" buttonClass="link-tertiary">
							{{ skip[skipStep + 1] }}
						</app-button>
						<!-- <button (click)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData">{{ skip[skipStep + 1] }}</button> -->
					</div>
					<div class="footer-right">
						<app-button (onClick)="generateHelper('introduction')" [disabled]="waitTextShow || isLoadingData" buttonClass="secondary">
							GENERATE MORE
						</app-button>
						<app-button
							*ngIf="!outlineSkipped"
							(onClick)="generateHelper('outline')"
							[disabled]="checkLastWizSelcted() || isLoadingData"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							GENERATE OUTLINE
						</app-button>
						<app-button
							*ngIf="outlineSkipped"
							(onClick)="generateHelper(skipFunc[skipStep], true)"
							[disabled]="checkLastWizSelcted()"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							{{ skipFuncBtn[skipStep + 1] }}
						</app-button>
					</div>
				</div>
			</div>
			<!--Outline-->
			<div class="card" id="outlineScroll" [class.selected-card]="activeStep == 4" *ngIf="!outlineSkipped" [class.disable]="outlineSubmitted">
				<div class="card-header">
					<h4 class="card-title-cs" [class.active]="activeStep == 4">Outline</h4>
				</div>
				<div class="card-body">
					<div class="spin-holder" *ngIf="isLoadingData && loadingStep == 3">
						<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
						<p class="wait-text" *ngIf="waitTextShow">Please give Shakespeare a moment to think</p>
					</div>
					<div class="card-form-wrapper" *ngFor="let item of outline; let i = index">
						<div class="form-check" (click)="toggleValue(i)">
							<div class="custom-check-box {{ isOutlineSelected(i) < 0 ? '' : 'checked' }}">
								<span>{{ isOutlineSelected(i) + 1 }}</span>
							</div>
							<label class="form-check-label outline-l" [id]="'outline' + i" for="formCheck-7" [innerHTML]="item"></label>
						</div>
						<div class="card-action">
							<!-- <button class="btn btn-primary" (click)="editText('outline', i)" type="button">
								<em [ngClass]="currentEditIndex === i && editId == 'outline' ? checkIcon : editIcon"></em>
							</button>
							<button class="btn btn-primary" (click)="copyText('outline' + i)" type="button">
								<img alt="" src="assets/icons/card-editor/copy.svg" />
							</button> -->
							<button class="btn btn-primary" matTooltip="Edit" [matTooltipPosition]="'above'" (click)="editText('outline', i)" type="button">
								<em [ngClass]="currentEditIndex === i && editId == 'outline' ? checkIcon : editIcon"></em>
							</button>
							<button
								class="btn btn-primary"
								[matTooltip]="isCopied ? 'Copied' : 'Copy'"
								[matTooltipPosition]="'above'"
								(click)="copyText('outline' + i, $event)"
								type="button"
							>
								<img alt="" src="assets/icons/card-editor/copy.svg" />
							</button>
						</div>
					</div>
				</div>
				<div class="card-footer" *ngIf="activeStep == 4">
					<div class="clear" [ngClass]="{ hideSkip: skipFuncBtn[skipStep + 1] == 'FINISH' }">
						<app-button (onClick)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData" buttonClass="link-tertiary">
							{{ skip[skipStep + 1] }}
						</app-button>
						<!-- <button (click)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData">{{ skip[skipStep + 1] }}</button> -->
					</div>
					<div class="footer-right">
						<app-button (onClick)="generateHelper('outline')" [disabled]="waitTextShow || isLoadingData" buttonClass="secondary">
							GENERATE MORE
						</app-button>
						<app-button
							*ngIf="!paraSkipped"
							(onClick)="generateHelper('paragraph')"
							[disabled]="checkLastWizSelcted() || isLoadingData"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							GENERATE PARAGRAPH
						</app-button>
						<app-button
							*ngIf="paraSkipped"
							(onClick)="generateHelper(skipFunc[skipStep], true)"
							[disabled]="checkLastWizSelcted()"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							{{ skipFuncBtn[skipStep + 1] }}
						</app-button>
					</div>
				</div>
			</div>
			<!--Paragraph-->
			<div class="card" id="paraScroll" [class.selected-card]="activeStep == 5" *ngIf="!paraSkipped" [class.disable]="paraSubmitted">
				<div class="card-header">
					<h4 class="card-title-cs" [class.active]="activeStep == 5">Paragraph</h4>
					<div
						class="pagination"
						title="Select any item below"
						*ngIf="activeStep == 5"
						[class.disabled-pagination]="paraLoading || sortedOutline[indexHelper].index == null"
					>
						<em (click)="onBack()" [class.disable]="outlineIndex == 0" class="fas fa-chevron-left arrows"></em>
						<span>{{ outlineIndex + 1 }}/{{ selectedOutline.length }}</span>
						<em (click)="onForward()" [class.disable]="outlineIndex + 1 == selectedOutline.length" class="fas fa-chevron-right arrows right"></em>
						<div class="tooltip">
							Don't miss paragraph for other outlines
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="card-form-wrapper" *ngIf="activeStep == 5 || loadingStep == 6">
						<div class="form-check">
							<label class="form-check-label bold k-ml-0">{{ outlineIndex + 1 }}. {{ sortedOutline[indexHelper]?.title || '' }}</label>
						</div>
					</div>
					<form [formGroup]="paraForm">
						<div class="spin-holder" *ngIf="isLoadingData && loadingStep == 4">
							<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
							<p class="wait-text" *ngIf="waitTextShow">Please give Shakespeare a moment to think</p>
						</div>
						<div class="card-form-wrapper" *ngFor="let item of sortedOutline[indexHelper]?.data; let i = index">
							<div class="form-check radio" (click)="saveIndexOfSelectedPara(i)">
								<input
									type="radio"
									[attr.name]="'para' + i"
									formControlName="para"
									[value]="item"
									class="form-check-input"
									[id]="'paraRadio' + i"
								/>
								<label class="form-check-label" [id]="'para' + i" [for]="'paraRadio' + i" [innerHTML]="item.para"></label>
							</div>
							<div class="card-action">
								<!-- <button class="btn btn-primary" (click)="editText('para', i)" type="button">
									<em [ngClass]="currentEditIndex === i && editId == 'para' ? checkIcon : editIcon"></em>
								</button>
								<button class="btn btn-primary" (click)="copyText('para' + i)" type="button">
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button> -->
								<button class="btn btn-primary" matTooltip="Edit" [matTooltipPosition]="'above'" (click)="editText('para', i)" type="button">
									<em [ngClass]="currentEditIndex === i && editId == 'para' ? checkIcon : editIcon"></em>
								</button>
								<button
									class="btn btn-primary"
									[matTooltip]="isCopied ? 'Copied' : 'Copy'"
									[matTooltipPosition]="'above'"
									(click)="copyText('para' + i, $event)"
									type="button"
								>
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="card-footer" *ngIf="activeStep == 5">
					<div class="clear" [ngClass]="{ hideSkip: skipFuncBtn[skipStep + 1] == 'FINISH' }">
						<app-button (onClick)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData" buttonClass="link-tertiary">
							{{ skip[skipStep + 1] }}
						</app-button>
						<!-- <button (click)="skipBlogType(skipFunc[skipStep + 1])" [disabled]="isLoadingData">{{ skip[skipStep + 1] }}</button> -->
					</div>
					<div class="footer-right">
						<app-button (onClick)="generateHelper('paragraph')" [disabled]="waitTextShow || isLoadingData" buttonClass="secondary">
							GENERATE MORE
						</app-button>
						<app-button
							*ngIf="!conclusionSkipped"
							(onClick)="generateHelper('conclusion')"
							[disabled]="checkLastWizSelcted() || isLoadingData"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							GENERATE CONCLUSION
						</app-button>
						<app-button
							*ngIf="conclusionSkipped"
							(onClick)="generateHelper(skipFunc[skipStep], true)"
							[disabled]="checkLastWizSelcted()"
							buttonClass="primary"
							icon="fas fa-arrow-right"
						>
							{{ skipFuncBtn[skipStep + 1] }}
						</app-button>
					</div>
				</div>
			</div>
			<!--conclusion-->
			<div class="card" id="concScroll" [class.selected-card]="activeStep == 6" *ngIf="!conclusionSkipped" [class.disable]="conclusionSubmitted">
				<div class="card-header">
					<h4 class="card-title-cs" [class.active]="activeStep == 6">Conclusion</h4>
				</div>
				<div class="card-body">
					<form [formGroup]="conclusionForm">
						<div class="spin-holder" *ngIf="isLoadingData && loadingStep == 5">
							<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
							<p class="wait-text" *ngIf="waitTextShow">Please give Shakespeare a moment to think</p>
						</div>
						<div class="card-form-wrapper" *ngFor="let item of conclusion; let i = index">
							<div class="form-check radio">
								<input
									type="radio"
									[attr.name]="'con' + i"
									formControlName="con"
									[value]="item"
									class="form-check-input"
									[id]="'conRadio' + i"
								/>
								<label class="form-check-label" [id]="'con' + i" [for]="'conRadio' + i" [innerHTML]="item"></label>
							</div>
							<div class="card-action">
								<!-- <button class="btn btn-primary" (click)="editText('con', i)" type="button">
									<em [ngClass]="currentEditIndex === i && editId == 'con' ? checkIcon : editIcon"></em>
								</button>
								<button class="btn btn-primary" (click)="copyText('con' + i)" type="button">
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button> -->
								<button class="btn btn-primary" matTooltip="Edit" [matTooltipPosition]="'above'" (click)="editText('con', i)" type="button">
									<em [ngClass]="currentEditIndex === i && editId == 'con' ? checkIcon : editIcon"></em>
								</button>
								<button
									class="btn btn-primary"
									[matTooltip]="isCopied ? 'Copied' : 'Copy'"
									[matTooltipPosition]="'above'"
									(click)="copyText('con' + i, $event)"
									type="button"
								>
									<img alt="" src="assets/icons/card-editor/copy.svg" />
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="card-footer justify-content-end" *ngIf="activeStep == 6">
					<div class="flex-right">
						<app-button (onClick)="generateHelper('conclusion')" [disabled]="waitTextShow || isLoadingData" buttonClass="secondary">
							GENERATE MORE
						</app-button>
						<app-button (onClick)="finishBlog()" [disabled]="checkLastWizSelcted()" buttonClass="primary">FINISH</app-button>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
