import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-custom-textarea',
	templateUrl: './custom-textarea.component.html',
	styleUrls: ['./custom-textarea.component.scss']
})
export class CustomTextareaComponent implements OnInit {
	@Input() public control: FormControl | AbstractControl = new FormControl('');
	@Input() public inputId: string = '';
	@Input() public inputPlaceholder: string = 'enter text';
	@Input() public styleClass: string;
	@Input() public maxLength: number = 600;
	constructor() {}

	ngOnInit(): void {}
}
