import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShakespeareSpinningLoaderComponent } from './loader.component';

@NgModule({
	declarations: [ShakespeareSpinningLoaderComponent],
	imports: [CommonModule],
	exports: [ShakespeareSpinningLoaderComponent]
})
export class LoaderModule {}
