import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { StorageKey } from '../../_models/local-storage-key';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
	private readonly AUTH_TYPE = 'Bearer';

	constructor(private router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem(StorageKey.token);

		if (token) {
			request = request.clone({ headers: request.headers.set('Authorization', `${this.AUTH_TYPE} ${token}`) });
		}
		return next.handle(request).pipe(
			catchError(err => {
				// console.log(err.status);
				if (err.status == 401) {
					this.router.navigateByUrl('/authentication');
				}
				return throwError(err);
			})
		);
	}
}
