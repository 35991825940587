export interface DocumentInterface {
	createdOn?: string;
	editedOn?: string;
	id?: string;
	name: string;
	text: string;
	since?: string;
}

export enum EditorModeEnum {
	Pro = 'Pro',
	Assist = 'Assist'
}

export interface ToggleValueInterface {
	isTitleEnabled: boolean;
	isDescriptionEnabled: boolean;
	outputToneEnabled: boolean;
	isKeywordEnabled: boolean;
}
