import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class AdsService {
	public selectedCategoryId$ = new BehaviorSubject<string>('');
	public selectedGroupId$ = new BehaviorSubject<string>('');
	public selectedAdId$ = new BehaviorSubject<string>('');
	public userAdId$ = new BehaviorSubject<string>('');
	public fbUserAdId$ = new BehaviorSubject<string>('');
	public createdCategoruyId$ = new BehaviorSubject<string>('');
	public createdFbCategoruyId$ = new BehaviorSubject<string>('');
	public activeButton$ = new BehaviorSubject<string>('');
	public generatedAdId$ = new BehaviorSubject<string>('');
	public generatedTextId$ = new BehaviorSubject<string>('');
	public descriptionId$ = new BehaviorSubject<string>('');
	public cardId$ = new BehaviorSubject<string>('');
	public primaryTextId$ = new BehaviorSubject<string>('');
	public headlineId$ = new BehaviorSubject<string>('');
	public googleAdId$ = new BehaviorSubject<string>('');
	public facebookAdId$ = new BehaviorSubject<string>('');
	public createdCategory$ = new BehaviorSubject<string>('');
	public closedPopup$ = new BehaviorSubject<boolean>(false);
	public fbCampaignName$ = new BehaviorSubject<string>('');
	public fbCampaignDesc$ = new BehaviorSubject<string>('');

	public publishedHeadingList$ = new BehaviorSubject<any[]>([]);
	public publishedPrimaryList$ = new BehaviorSubject<any[]>([]);
	public publishedDescriptionList$ = new BehaviorSubject<any[]>([]);
	public editorImageVariant$ = new BehaviorSubject<any[]>([]);
	public selectedCampaings$ = new BehaviorSubject<any[]>([]);
	public creativeVariantArray$ = new BehaviorSubject<any[]>([]);
	public dpaVariant$ = new BehaviorSubject<any[]>([]);
	constructor(protected http: HttpClient, private bhttp: HttpBackend) {}

	public getGoogleAdIDs(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}GoogleAccount/customer`);
	}

	editCategory(payload: any) {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ai-category`, payload);
	}

	public validateAccessToken(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}GoogleAccount/validate/accessToken`, {});
	}

	public validateSlackAccessToken(code: string): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}SlackAccount/accessToken?code=${code}`, {});
	}

	public sendCodeTemp(code: string, email: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}GoogleAccount/accessToken?code=${code}&email=${email}`);
	}

	public getCategoriesIDs(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ai-category`);
	}
	public getCategoriesIDsOnDashboard(accountId: any): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ai-category?ad_account_id=${accountId}`);
	}

	public getFbCategoriesIDs(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ai-category`);
	}

	public getSelectedAdaccountFbCategoriesIDs(id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ai-category?ad_account_id=${id}`);
	}

	public spendMoreAds(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}subscription/allow/moreAdSpend`);
	}

	public adChannel(type: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}subscription/allow/connect/adChannel?platform=${type}`);
	}

	public getGroupsIDs(id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics?ai_category_id=${id}`);
	}

	public getFbGroupsIDs(id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics?ai_category_id=${id}`);
	}

	public getGroups(customerID, campaignID): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}GoogleAccount/adGroup/customer/${customerID}/campaigns/${campaignID}`);
	}

	public getFbGroups(customerID, campaignID): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}FBAccount/campaign/${campaignID}/adSets`);
	}

	public getDashboardScore(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics`, payload);
	}

	public getFbDashboardScore(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics`, payload);
	}

	public getGraphScore(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics`, payload);
	}

	public disconnectGoogle(id): Observable<any> {
		// return this.http.put<any>(`${BaseApiUrl.Subscription}GoogleAccount/disconnect`, {});
		return this.http.put<any>(`${BaseApiUrl.Subscription}GoogleAccount/disconnect/PlatformId/${id}`, {});
	}

	public disconnectFb(id): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}FBAccount/disconnect/PlatformId/${id}`, {});
	}

	public disconnectSlack(id): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}SlackAccount/disconnect`, {});
	}

	public getFbGraphScore(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics`, payload);
	}

	public getCardScoreAndDetails(categoryId, groupId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ad-score?ai_category_id=${categoryId}&ad_group_id=${groupId}`);
	}

	public getFbCardScoreAndDetails(categoryId, groupId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ad-score?ai_category_id=${categoryId}&ad_group_id=${groupId}`);
	}

	public generateAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/single-ads`, payload);
	}
	public singleAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/single-ads`, payload);
	}

	public scoreTrigger(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ad-score`, payload);
	}

	public scoreTriggerfb(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ad-score`, payload);
	}

	public generateMoreAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/geneate-more`, payload);
	}

	public fbGenerateMoreAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/geneate-more`, payload);
	}

	public fbDpaGenerateMoreAds(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/geneate-more`, payload);
	}

	public getGeneratedFlags(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/retrive-flags`);
	}

	public adsData(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/retrive-ads`, payload);
	}

	public publishAd(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/single-ads/publish`, payload);
	}

	public deleteAd(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/single-ads/publish`, payload);
	}

	public bulkpublishAd(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/bulk-ads/publish`, payload);
	}
	public bulkpublishAdforFb(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/bulk-ads/publish`, payload);
	}

	public declinepublishAdforFb(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/bulk-ads/publish`, payload);
	}
	public deleteSingleDPA(payload): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dpa/publish?template_id=` + payload);
	}

	public publishSingleDPA(payload): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dpa/publish?template_id=` + payload);
	}
	public getSingleAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/retrive-ads`, payload);
	}
	public retriveAds(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/retrive-ads`, payload);
	}

	public bulkDeclineAd(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/bulk-ads/publish`, payload);
	}

	public summaryAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall`, payload);
	}
	public getOverallCardsData(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall-new`, payload);
	}
	public getLineChartsData(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall`, payload);
	}

	public getHorizontalBarChartData(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall-new`, payload);
	}

	public getDoughnutChartData(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall-new`, payload);
	}

	public highlights(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall`, payload);
	}

	public history(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall`);
	}

	public metrics(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/dashboard-metrics/overall`, payload);
	}

	public fbSummaryAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall`, payload);
	}

	getFBCardsData(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall-new`, payload);
	}
	getFacebookDoughnutChartData(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall-new`, payload);
	}
	getFacebookHarizontalBarChartData(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall-new`, payload);
	}

	public fbHighlights(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall`, payload);
	}

	public fbHistory(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall`);
	}
	public getGeneratedFlagsfb(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/retrive-flags`);
	}

	public fbMetrics(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall`, payload);
	}

	public getFBLineCharts(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dashboard-metrics/overall`, payload);
	}

	public createAiCategory(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ai-category`, payload);
	}

	public createFbAiCategory(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ai-category`, payload);
	}

	public addFbAiCategory(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ai-category`, payload);
	}

	public deleteFbAiCategory(categoryId): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/ai-category?ai_category_id=${categoryId}`);
	}

	public getFbMetrics(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/playground-metrics`, payload);
	}

	public getFbCategory(customerId, creativeId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/creative?customer_id=${customerId}&creative_id=${creativeId}`);
	}

	public company(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/user/branding`);
	}

	public addAiCategory(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ai-category`, payload);
	}

	public getCampaignList(customerID): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}GoogleAccount/customer/${customerID}/campaigns`);
	}

	public getFbCampaignList(customerID): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}FBAccount/adAccount/${customerID}/campaigns`);
	}
	// facebook services //
	public sendFbCode(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}FBAccount/accessToken`, payload);
	}

	public addFbAccounts(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}FBAccount/adAccounts`);
	}

	public getFacebookAdaccounts(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}FBAccount/adAccounts`);
	}

	public getModelData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/data-modeling`);
	}

	public sendModelData(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/data-modeling`, payload);
	}

	public aiAction(customerId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-history?customer_id=${customerId}`);
	}

	public aiInsitsHistory(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations`);
	}

	public aiCalibration(accountId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/campaigns?ad_account=${accountId}`);
	}

	public applyAiPer(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations`, payload);
	}

	public declineAiPer(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations`, payload);
	}

	public locationSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models/location-settings`, payload);
	}

	public budgetSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models/budget-settings`, payload);
	}

	public bidSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models/bid-settings`, payload);
	}
	public deleteModel(modelId): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models?model_id=${modelId}`);
	}

	public getModel(accountId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models?ad_account=${accountId}`);
	}

	public getaiModelData(modelId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models?model_id=${modelId}`);
	}

	public getFBModel(accountId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models?ad_account=${accountId}`);
	}

	public getFBaiModelData(modelId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models?model_id=${modelId}`);
	}

	public addCampaign(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models/campaigns`, payload);
	}

	public addFBCampaign(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models/campaigns`, payload);
	}

	public scheduleSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/ai-models/schedule-settings`, payload);
	}

	public createName(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}	ad-intelligence-optimization/google/ai-models`, payload);
	}

	public createFBName(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}	ad-intelligence-optimization/facebook/ai-models`, payload);
	}

	public aiInsistData(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/dashboard-metrics`, payload);
	}

	public aiGoogleAction(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-history`, payload);
	}
	public postEstimatedMetrix(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/estimated-metrics`, payload);
	}

	public aiGoogleMetricPut(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/dashboard-metrics`, payload);
	}

	public aiGoogleMetric(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/dashboard-metrics`, payload);
	}

	public aiGooglePerformanceByCampaign(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/card-metrics`, payload);
	}

	public aiGooglePerformanceDevice(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/card-metrics-2`, payload);
	}

	public aiGooglePerformanceGender(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/card-metrics`, payload);
	}

	public aiGooglePerformanceAdGrp(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/card-metrics-2`, payload);
	}

	public aiGooglePerformanceAgeGrp(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/action-impact-analysis/card-metrics`, payload);
	}

	public aiGooglePerformanceInsightCampaign(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics`, payload);
	}

	public aiGooglePerformanceInsightCampaignPut(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics`, payload);
	}

	public aiGooglePerformanceInsightDevice(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics`, payload);
	}

	public aiGooglePerformanceInsightNetwork(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics2`, payload);
	}

	public aiGooglePerformanceInsightAge(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics2`, payload);
	}

	public aiGooglePerformanceInsightGender(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics2`, payload);
	}

	public aiGooglePerformanceInsightWeek(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics3`, payload);
	}

	public aiGooglePerformanceInsightCountry(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics3`, payload);
	}

	public aiGooglePerformanceInsightCity(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics3`, payload);
	}

	public aiGooglePerformanceInsightKeyword(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/performance-insights/metrics4`, payload);
	}

	public aiFBPerformanceAdGrp(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/card-metrics`, payload);
	}

	public aiFBPerformanceDevice(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/card-metrics`, payload);
	}

	public aiFBPerformanceAgeGrp(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/card-metrics`, payload);
	}

	public aiFBPerformanceGender(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/card-metrics-2`, payload);
	}

	public aiFBPerformanceByCampaign(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/card-metrics-2`, payload);
	}

	public postEstimatedFBMetrix(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/estimated-metrics`, payload);
	}

	public aiFBPerformanceInsightCampaign(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics`, payload);
	}

	public aiFBPerformanceInsightCampaignPut(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics`, payload);
	}

	public aiFBPerformanceInsightDevice(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics2`, payload);
	}

	public aiFBPerformanceInsightAge(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics`, payload);
	}

	public aiFBPerformanceInsightGender(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics2`, payload);
	}

	public aiFBPerformanceInsightCountry(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics2`, payload);
	}

	public aiFBPerformanceInsightCity(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics3`, payload);
	}

	public aiFBPerformanceDevicePlatform(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics3`, payload);
	}

	public aiFBPerformancePlatforms(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/performance-insights/metrics3`, payload);
	}

	public aiFacebookPostMetric(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/dashboard-metrics`, payload);
	}

	public aiFacebookPutMetric(payload): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/dashboard-metrics`, payload);
	}

	public getFBActionHistory(customer_id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-history?customer_id=${customer_id}`);
	}

	public getFBCampaignData(ad_account): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/campaigns?ad_account=${ad_account}`);
	}

	public getAIRecommendationData(ad_account_id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations?ad_account_id=${ad_account_id}`);
	}

	public getAiRecommendationsLength(ad_account_id): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations-fetch?ad_account_id=${ad_account_id}`, {});
	}

	public getCompletedRecommendationData(ad_account_id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations-fetch?ad_account_id=${ad_account_id}`);
	}

	public applyAiRecommendation(data): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations`, data);
	}

	public insideCardAiRecommendations(recommendationId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations-cards?recommendation_id=${recommendationId}`);
	}

	public dismissedRecommendations(data, ad_account_id): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations-fetch?ad_account_id=${ad_account_id}`, data);
	}

	public declineAiRecommendation(data): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations`, data);
	}

	public getFBAIRecommendationData(ad_account_id): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations?ad_account_id=${ad_account_id}`);
	}

	public getFBAiRecommendationsLength(ad_account_id): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations-fetch?ad_account_id=${ad_account_id}`, {});
	}

	public getFBCompletedRecommendationData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations-fetch`);
	}

	public applyFBAiRecommendation(data): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations`, data);
	}

	public dismissedFBRecommendations(data): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations-fetch`, data);
	}

	public declineFBAiRecommendation(data): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations`, data);
	}

	public FbPerformanceMeter(payload): Observable<any> {
		return this.http.patch<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/action-impact-analysis/dashboard-metrics`, payload);
	}

	public addFbAccountspg(payload): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}FBAccount/adAccounts?before=&after=${payload}`);
	}

	public validateFbAccessToken(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}FBAccount/validateAccessToken`, {});
	}

	public fbPreMadeTemplates(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/pre-made-templates`);
	}
	public generateSingleFbAd(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}ad-intelligence/creative-ai/fb/single-ads`, params);
	}
	public updateFbAd(params, name): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/edit-ads/` + name, params);
	}
	public getGeneratedFbFlags(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/retrive-flags`);
	}
	public getSingleFbAds(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/retrive-ads`, payload);
	}
	public fetchDpa(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/fetch-dpa`, params);
	}
	public fetchImagesForDpa(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dpa/generate-image`, params);
	}
	public retriveImagesForDpa(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/dpa/retrive-image`, params);
	}
	public retriveImagesforDpaStatus(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/google/retrive-flags`);
	}

	public deleteAICategory(params: any): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/ai-category?ai_category_id=` + params);
	}

	public budgetFBSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models/budget-settings`, payload);
	}

	public optimizationFBEvent(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models/bid-settings`, payload);
	}

	public scheduleFBSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models/schedule-settings`, payload);
	}

	public demographicFBSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models/demographic-settings`, payload);
	}

	public deliveryFBSettings(payload): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/ai-models/delivery-settings`, payload);
	}

	// Creative Studio

	public getAllBrands(userId): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/brand?user_filed_id=${userId}`);
	}

	public createNewBrand(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/brand`, params);
	}

	public checkWebsiteName(websiteName): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/get-client-details?url=${websiteName}`);
	}

	public getCatalogue(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/fetch-dpa`, params);
	}

	// Text Editor
	public generateText(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/generateText`, params);
	}

	//Facebook creative
	public getAnylyticData(accountId, aiCatId, fromDate, toDate, adIds) {
		// toDate = '2024-02-13';
		// fromDate = '2023-12-13';
		return this.http.get(
			`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/all-ads-dashboard-metrics?ai_category_id=${aiCatId}&ad_account_id=${accountId}&from_date=${fromDate}&to_date=${toDate}&ad_ids=${adIds}`
		);
	}
	// Text Editor
	public generateMore(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/generate-template-variants`, params);
	}

	public getFreePik(searchTerm) {
		return this.http.get(BaseApiUrl.Subscription + 'FreePick/resourses?searchTearm=' + searchTerm);
	}
	public generateImageVariantText(json): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/generate-text-data-ad-creative`, json);
	}

	public surpriseMe(params): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/surprise-me`, params);
	}

	public uploadImage(formData) {
		return this.http.post(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-studio/image`, formData);
	}
	public fbpageDetail(pageId, creativeId) {
		return this.http.get(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/fetch-page-details?pageId=${pageId}&creativeId=${creativeId}`);
	}
	public fbSaveVariant(json): Observable<any> {
		return this.http.post(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/single-ads/publish`, json);
	}
}
