import { ShakespeareService } from './../../shakespeare.service';
import { ToastNotificationService } from './../../../shared/toast-notification/toast-notification.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { LoadWizardHistory } from 'src/app/state/shakespeare-common-state/shakespeare-common-action';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { DocumentService } from '../../documents/document.service';

@Component({
	selector: 'app-blog-wizard-generator',
	templateUrl: './blog-wizard-generator.component.html',
	styleUrls: ['./blog-wizard-generator.component.scss']
})
export class BlogWizardGeneratorComponent implements OnInit {
	public selectedOutline = [];
	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 }
	];
	public maxOutputArray = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max3Output = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	public editIcon = 'fas fa-pencil-alt';
	public checkIcon = 'fas fa-check';
	public currentIcon = 'fas fa-pencil-alt';
	public isEditOff = true;
	public currentEditIndex = -1;
	public forAttrHelper: string;

	public wizardStack = [];

	public outputLang = [...LANGUAGES];
	public selectedLang = '';
	public activeStep = 1;
	public isLoadingData = false;
	public steps = [
		{
			label: 'Generate Headline',
			id: 1
		},
		{
			label: 'Headline',
			id: 2
		},
		{
			label: 'Introduction',
			id: 3
		},
		{
			label: 'Outline',
			id: 4
		},
		{
			label: 'Paragraph',
			id: 5
		},
		{
			label: 'Conclusion',
			id: 6
		}
	];
	private unsubscriber$ = new Subject<void>();

	public keywordHelper = [{ placeholder: 'Enter here...' }];
	public keywordPlaceHolders = ['Enter here...', 'Enter here...', 'Enter here...'];
	public errorText: boolean;
	public selectedOutputTone: string;
	public toneError: boolean;
	public filedId: number;
	public outlineIndex = 0;
	public editId = 'headline';

	// forms for each blog type
	public blogForm: FormGroup;
	public headlineForm: FormGroup;
	public introForm: FormGroup;
	public outlineForm: FormGroup;
	public paraForm: FormGroup;
	public conclusionForm: FormGroup;

	// data of each blog type
	public payload = {};
	public headlines = [];
	public intros = [];
	public outline = [];
	public paragraph = [];
	public conclusion = [];

	// helper variables for storing each data genearted by api
	public introsDataHolder = []; // introduction data holder
	public paraDataHolder = []; // paragraph data holder
	public conDataHolder = []; // conclusion data holder

	public introApiCalls = 0;
	public paraApiCalls = 0;
	public conApiCalls = 0;

	// helper variables for disabling previous steps
	public headlineSubmitted = false;
	public introSubmitted = false;
	public outlineSubmitted = false;
	public paraSubmitted = false;
	public conclusionSubmitted = false;

	public extractedOutlines = [];
	public sortedOutline = {};
	public indexHelper = 0;

	public blogTypeMapper = {
		headline: 'introduction',
		introduction: 'outline',
		outline: 'conclusion', // if outline is skipped, then para is skipped and conclusion is active
		paragraph: 'conclusion',
		conclusion: 'finish'
	};

	public introSkipped: boolean;
	public outlineSkipped: boolean;
	public paraSkipped: boolean;
	public conclusionSkipped: boolean;
	public outlinePara = [];
	public differ: number;

	public skip = [
		'', // keep empty
		'', // this is for mapping skip text with help of current stepNumber
		'',
		'SKIP INTRODUCTION',
		'SKIP OUTLINE',
		'SKIP PARAGRAPH',
		'SKIP CONCLUSION'
	];

	public skipFunc = ['', '', '', 'introduction', 'outline', 'paragraph', 'conclusion', 'finish'];

	public skipFuncBtn = ['', '', '', 'GENERATE INTRODUCTION', 'GENERATE OUTLINE', 'GENERATE PARAGRAPH', 'GENERATE CONCLUSION', 'FINISH'];
	public skipStep = 2;
	public loadingStep = 1;
	public language: string;
	private startTime: number;
	private endTime: number;
	private thresholdLimit = 60000; // 1 minute
	private requestCounter = 0;
	public paraLoading: boolean;

	public waitTextShow: boolean;
	isCopied: boolean;
	helperInputText: string | number | string[];

	constructor(
		private userServiceApi: UserServiceApi,
		private fb: FormBuilder,
		private userStore: Store<UserState>,
		private templateStore: Store<any>,
		private router: Router,
		private toastNotificationService: ToastNotificationService,
		private shakespeareService: ShakespeareService,
		private documentService: DocumentService
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		this.shakespeareService.autoScrollOff();
	}

	ngOnInit(): void {
		this.createForm();
		this.paraForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			console.log(value);
			console.log(this.selectedOutline);
			console.log(this.sortedOutline);
			if (this.selectedOutline.length > 0 && this.outlinePara.length == this.selectedOutline.length) {
				this.outlinePara.forEach(item => {
					if (item.outline == this.sortedOutline[this.selectedOutline[this.outlineIndex]].title) {
						item.para = this.sortedOutline[this.indexHelper].data;
						// this.sortedOutline[this.indexHelper].data
						// this.paraForm.controls.para.value?.para ||
					}
					if (this.selectedOutline.length == 1) {
						item.title = this.sortedOutline[this.indexHelper].title;
						item.para = this.sortedOutline[this.indexHelper].data[0].para;
					}
				});
				console.log(this.indexHelper);
				console.log(this.outlinePara);
				return;
			}
			if (this.differ == this.outlineIndex) {
				this.outlinePara[this.outlineIndex] = {
					outline: this.sortedOutline[this.indexHelper]?.title,
					para: this.paraForm.controls.para.value?.para,
					index: null,
					fulfilled: false
				};
			} else {
				this.outlinePara.push({
					outline: this.sortedOutline[this.indexHelper]?.title,
					para: this.paraForm.controls.para.value?.para,
					index: null,
					fulfilled: false
				});
			}
			console.log(this.outlinePara);
			this.differ = this.outlineIndex;
			let controller = true;
			if (this.outlineIndex + 1 == this.selectedOutline.length) controller = false;
			setTimeout(() => {
				if (this.outlinePara[this.outlineIndex]?.para != undefined && controller && this.outlinePara[this.outlineIndex]?.fulfilled) {
					this.onForward();
				}
			}, 300);
		});
	}

	resetForm() {
		this.blogForm.reset();
	}

	stepClick(step: number) {
		this.activeStep = step;
	}

	toggleValue(card: number) {
		const selectedValueIndex = this.isOutlineSelected(card);
		if (selectedValueIndex < 0) {
			this.selectedOutline.push(card);
			return;
		}
		this.selectedOutline.splice(selectedValueIndex, 1);
		this.selectedOutline = [...this.selectedOutline];
	}

	isOutlineSelected(card: number): number {
		return this.selectedOutline.findIndex(xd => xd === card);
	}

	public removeError(index: number, event, errorClass: string): void {
		if (event.target.value.length > 0) {
			document.getElementById(errorClass + index).style.display = 'none';
		}
	}

	public addMoreHandler(isLast: boolean, index: number, type: string, errorClass: string): void {
		if (index == 2) {
			return;
		}
		if (isLast) {
			let element = <HTMLInputElement>document.getElementById(type + index);

			element.value
				? this.keywordHelper.push({ placeholder: this.keywordPlaceHolders[index + 1] })
				: (document.getElementById(errorClass + index).style.display = 'block');

			setTimeout(() => {
				let nextElement = <HTMLInputElement>document.getElementById(type + (index + 1));
				nextElement.focus();
			}, 200); // few mili-seconds to wait for dom to build element
		} else {
			this.keywordHelper.pop();
		}
	}

	public createForm(): void {
		this.blogForm = this.fb.group({
			topic: new FormControl('', [Validators.required, Validators.maxLength(80)]),
			tone: new FormControl(null, [Validators.required]),
			keywords: new FormControl([]),
			translate: new FormControl(false),
			outputLanguage: new FormControl(),
			numOutputs: new FormControl({ name: '3 Outputs', value: 3 }, [Validators.required]),
			blog_type: new FormControl('')
		});

		this.headlineForm = this.fb.group({
			headline: new FormControl('', [Validators.required])
		});

		this.introForm = this.fb.group({
			intro: new FormControl('', [Validators.required])
		});

		this.outlineForm = this.fb.group({
			outline: new FormControl('', [Validators.required])
		});

		this.paraForm = this.fb.group({
			para: new FormControl('', [Validators.required])
		});

		this.conclusionForm = this.fb.group({
			con: new FormControl('', [Validators.required])
		});
	}

	public changeToneSource(event: any): void {
		if (event) {
			this.selectedOutputTone = event.name;
		}
		if (!this.blogForm.controls.outputTone.valid) {
			this.toneError = true;
		}
	}

	public setErrorTextMessage(): void {
		if (!this.blogForm.controls.topic.valid) {
			this.errorText = true;
		} else {
			this.errorText = false;
		}
	}

	public editText(id: string, index: number): void {
		const text = document.getElementById(id + index);
		if (!this.isEditOff) {
			this.currentEditIndex = -1;
			text.setAttribute('for', this.forAttrHelper);
			this.patchForm(id, text);
			this.editId = '';
			document.getElementById(id + index).click(); // setting current input as selected
		} else {
			this.editId = id;
			this.appendInput(id + index, id);
			this.forAttrHelper = text.getAttribute('for');
			text.removeAttribute('for');
			this.currentEditIndex = index;
			text.click();
		}
	}

	public appendInput(elementId: string, id: string): void {
		var text = $(`#${elementId}`).text();
		let height = $(`#${elementId}`).height() + 10;
		var input = $(`<textarea id="${elementId}-input" class='common-edit-box' type="text">${text}</textarea>`);
		$(`#${elementId}`).text('').append(input);
		input.trigger('focus');
		$(`#${elementId}-input`).css('width', '100%');
		$(`#${elementId}-input`).css('height', height + 'px');
		$(`#${elementId}-input`).css('margin-top', '-5px');
		$(`#${elementId}-input`).css('border', '1px solid #c8c8c8');
		$(`#${elementId}-input`).css('border-radius', '4px');
		$(`#${elementId}-input`).css('text-indent', '8px');
		$(`#${elementId}-input`).attr('autocomplete', 'false');
		$(`#${elementId}-input`).css('resize', 'horizontal');
		$(`#${elementId}-input`).css('line-height', '26px');
		this.isEditOff = false;

		input.on('blur', () => {
			document.getElementById(elementId).click();
			var text = $(`#${elementId}-input`).val();
			this.helperInputText = text;
			$(`#${elementId}-input`).parent().text(text.toString());
			$(`#${elementId}-input`).remove();
			this.patchForm(id, $(`#${elementId}`).get(0));
			this.currentEditIndex = -1;
			setTimeout(() => {
				this.isEditOff = true;
			}, 100);
		});
	}

	public patchForm(type: string, text: HTMLElement): void {
		switch (type) {
			case 'headline':
				this.headlineForm.patchValue({
					headline: text.innerText
				});
				break;
			case 'intro':
				this.introForm.patchValue({
					intro: text.innerText
				});
				break;
			case 'outline':
				this.outlineForm.patchValue({
					outline: text.innerText
				});
				break;
			case 'para':
				this.paraForm.patchValue({
					para: text.innerText
				});
				break;
			case 'conclusion':
				this.conclusionForm.patchValue({
					con: text.innerText
				});
				break;
		}
	}

	public copyWithStyle(element): string {
		const text = document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);

			selection.removeAllRanges();
			selection.addRange(range);
			let copyHelper = window.getSelection().toString();
			// document.execCommand('copy');
			window.getSelection().removeAllRanges();
			return copyHelper;
		}
	}

	public copyText(id: string, event: Event): void {
		event.stopPropagation();
		let data = this.copyWithStyle(id);
		navigator.clipboard.writeText(data);

		this.isCopied = true;
		setTimeout(() => {
			this.isCopied = false;
		}, 1000);
	}

	public skipBlogType(type: string): void {
		switch (type) {
			case 'introduction':
				this.introSkipped = true;
				this.skipStep = 3;
				this.loadingStep = 3;
				break;
			case 'outline':
				this.skipStep = 5;
				this.outlineSkipped = true;
				this.paraSkipped = true;
				this.loadingStep = 5;
				break;
			case 'paragraph':
				this.paraSkipped = true;
				this.loadingStep = 5;
				this.skipStep = 5;
				break;
			case 'conclusion':
				this.conclusionSkipped = true;
				// this.loadingStep = 6;
				this.skipStep = 6;
				break;
		}
	}

	public generateHelper(type: string, isSkip = false): void {
		if (isSkip) {
			this.generateHelper(this.blogTypeMapper[type]);
			return;
		}
		switch (type) {
			case 'headline':
				this.generateHeadline();
				this.loadingStep = 1;
				this.skipStep = 2;
				break;
			case 'introduction':
				this.generateIntro();
				this.loadingStep = 2;
				this.skipStep = 3;
				break;
			case 'outline':
				this.generateOutline();
				this.loadingStep = 3;
				this.skipStep = 4;
				break;
			case 'paragraph':
				this.generatePara();
				this.loadingStep = 4;
				this.skipStep = 5;
				break;
			case 'conclusion':
				this.generateConclusion();
				this.loadingStep = 5;
				this.skipStep = 6;
				break;
			case 'finish':
				this.finishBlog();
				break;
		}
	}

	public checkLastWizSelcted(): boolean {
		let lastWiz = this.wizardStack[this.wizardStack.length - 1];
		switch (lastWiz) {
			case 'headline':
				return !this.headlineForm.valid;
			case 'introduction':
				return !this.introForm.valid;
			case 'outline':
				return this.selectedOutline.length == 0;
			case 'para':
				return this.sortedOutline[this.indexHelper].index == null;
			// return this.outlinePara[0].para == undefined;
			case 'conclusion':
				return !this.conclusionForm.valid;
		}
	}

	public refreshAllForms(): void {
		this.loadingStep = 1; // refresh  steps
		this.skipStep = 2; // refresh  steps
		this.activeStep = 1; // refresh  steps

		// resetting forms
		this.headlineForm.reset();
		this.introForm.reset();
		this.outlineForm.reset();
		this.paraForm.reset();
		this.conclusionForm.reset();

		// resetting data
		this.headlines = this.intros = this.outline = this.paragraph = this.conclusion = this.sortedOutline = [];

		this.selectedOutline = [];

		// unsubmit all forms
		this.headlineSubmitted = false;
		this.introSubmitted = false;
		this.outlineSubmitted = false;
		this.paraSubmitted = false;
		this.conclusionSubmitted = false;

		this.introSkipped = false;
		this.outlineSkipped = false;
		this.paraSkipped = false;
		this.conclusionSkipped = false;
		this.sortedOutline = [];
	}

	public sleep(ms: number): Promise<any> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	public timeDiff(): Promise<any> {
		if (this.endTime - this.startTime <= this.thresholdLimit && this.requestCounter > 10) {
			this.waitTextShow = true;
			return this.sleep(30000);
		}
		return this.sleep(0);
	}

	public generateHeadline(): void {
		if (this.blogForm.invalid) return;
		this.startTime = performance.now();
		this.wizardStack.push('headline');
		document.getElementById('headlineScroll').scrollIntoView({ behavior: 'smooth', block: 'start' });
		this.refreshAllForms();
		this.stepClick(2);
		this.isLoadingData = true;
		let keywords = '';
		for (let i = 0; i < this.keywordHelper.length; i++) {
			let value = (<HTMLInputElement>document.getElementById('keyword' + i)).value;
			keywords += ', ' + value;
		}
		keywords = keywords.trim();
		// removing first comma
		if (keywords[0] == ',') {
			keywords = keywords.substring(1).trim();
		}
		this.payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				user_template: {
					template: 'Blog Wizard'
				},
				user_input: {
					title: this.blogForm.controls.topic.value,
					tone: this.blogForm.controls.tone.value.name,
					blog_type: 'headlines',
					num_outputs: this.blogForm.controls.numOutputs.value.value
				}
			}
		};
		if (keywords !== '') {
			this.payload['data'].user_input['keywords'] = [keywords];
		}
		let lang = this.blogForm.controls.outputLanguage.value?.id || '';
		if (lang != 'en' && lang != '') {
			this.payload['data'].user_input['outputLanguage'] = lang;
			this.payload['data'].user_input['translate'] = true;
			this.language = lang;
		}
		this.timeDiff().then(() => {
			this.waitTextShow = false;
			this.requestCounter++;
			this.userServiceApi
				.generateBlogData(this.payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					response => {
						this.endTime = performance.now();
						if (response['body'].debugMessage === 'Not enough credit') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
							return;
						}
						if (response['body'].message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.stepClick(1);
							this.wizardStack.pop();
							this.headlineSubmitted = false;
						}
						this.headlines = response['body'].data.title;
						this.stepClick(2);
					},
					error => {
						if (error.error.message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generateHeadline();
							}, 10000);
						} else {
							this.isLoadingData = true;
							this.waitTextShow = false;
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again later');
						}
					},
					() => {
						this.isLoadingData = false;
					}
				);
		});
	}

	public generateIntro(): void {
		this.wizardStack.push('intro');
		document.getElementById('introScroll').scrollIntoView({ behavior: 'smooth', block: 'start' });
		this.stepClick(3);
		this.timeDiff();
		this.headlineSubmitted = true; // disabling headline
		this.isLoadingData = true;
		this.payload['data'].user_input.title = this.headlineForm.controls.headline.value;
		this.payload['data'].user_input.blog_type = 'introduction';
		// this.payload['data'].user_input.num_outputs
		let apiCount = this.payload['data'].user_input.num_outputs;
		this.introApiCalls = 0; // reseting intro api calls
		this.intros = []; // reseting intros, adding all in last api call
		while (apiCount) {
			this.generateIntroHelper();
			apiCount--;
		}
	}

	generateIntroHelper(): void {
		this.timeDiff().then(() => {
			this.waitTextShow = false;
			const totalCalls = this.payload['data'].user_input.num_outputs;
			let payload = JSON.parse(JSON.stringify(this.payload));
			payload['data'].user_input.num_outputs = 1;
			this.requestCounter++;
			this.userServiceApi
				.generateBlogData(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: response => {
						this.introApiCalls++;
						this.endTime = performance.now();
						if (response['body'].debugMessage === 'Not enough credit') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
							return;
						}
						if (response['body'].message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.stepClick(2);
							this.wizardStack.pop();
							this.headlineSubmitted = false;
						}
						if (this.introApiCalls == totalCalls) {
							this.introsDataHolder.push(...response['body'].data.introduction);
							this.intros = [...this.introsDataHolder];
							this.stepClick(3);
							this.isLoadingData = false;
						} else {
							this.introsDataHolder.push(...response['body'].data.introduction);
						}
					},
					error: error => {
						if (error.error.message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generateIntro();
							}, 10000);
						} else {
							this.isLoadingData = true;
							this.waitTextShow = false;
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again later');
						}
					}
				});
		});
	}

	public generateOutline(): void {
		this.wizardStack.push('outline');
		setTimeout(() => {
			document.getElementById('outlineScroll').scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 100);
		this.stepClick(4);
		this.timeDiff();
		this.introSubmitted = true;
		this.isLoadingData = true;
		this.payload['data'].user_input.title = this.headlineForm.controls.headline.value;
		this.payload['data'].user_input.blog_type = 'advanced_outline';

		this.timeDiff().then(() => {
			this.waitTextShow = false;
			this.requestCounter++;
			this.userServiceApi
				.generateBlogData(this.payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					response => {
						this.endTime = performance.now();
						if (response['body'].debugMessage === 'Not enough credit') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
							return;
						}
						if (response['body'].message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.stepClick(3);
							this.wizardStack.pop();
							this.introSubmitted = false;
						}
						this.outline = response['body'].data.outline;
						this.stepClick(4);
					},
					error => {
						if (error.error.message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generateOutline();
							}, 10000);
						} else {
							this.isLoadingData = true;
							this.waitTextShow = false;
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again later');
						}
					},
					() => {
						this.isLoadingData = false;
					}
				);
		});
	}

	public handleOutline(): void {
		let temp = 0;
		this.selectedOutline.forEach(idx => {
			this.sortedOutline[idx] = {
				title: this.outline[this.selectedOutline[temp++]],
				data: [],
				index: null
			};
		});

		this.indexHelper = this.selectedOutline[this.outlineIndex];
	}

	public extractFromOutlines(): void {
		this.selectedOutline.forEach(idx => {
			this.extractedOutlines.push(
				this.outline.filter((item, index) => {
					return index == idx;
				})
			);
		});

		// array flattening
		this.extractedOutlines = [].concat.apply([], this.extractedOutlines);
	}

	public saveIndexOfSelectedPara(index: number): void {
		// if (this.outlineIndex == 0) return;
		setTimeout(() => {
			console.log(this.outlineIndex);
			this.outlinePara[this.outlineIndex].index = index;
			this.outlinePara[this.outlineIndex].fulfilled = true;
			this.sortedOutline[this.indexHelper].index = index;
			console.log(this.outlinePara);
		}, 200);
	}

	public generatePara(): void {
		this.conclusion = [];
		this.paraLoading = true;
		this.wizardStack.push('para');
		setTimeout(() => {
			document.getElementById('paraScroll').scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 100);
		this.stepClick(5);
		this.outlineSubmitted = true;
		this.isLoadingData = true;
		this.handleOutline();
		this.timeDiff();
		this.payload['data'].user_input.title = this.outline[this.selectedOutline[this.outlineIndex]];
		this.payload['data'].user_input.blog_type = 'paragraph';
		this.indexHelper = this.selectedOutline[this.outlineIndex];
		let apiCount = this.payload['data'].user_input.num_outputs;
		this.paraApiCalls = 0; // reseting intro api calls
		this.paragraph = []; // reseting intros, adding all in last api call
		this.paraDataHolder = [];
		while (apiCount) {
			this.generateParaHelper();
			apiCount--;
		}
	}

	generateParaHelper(): void {
		this.timeDiff().then(() => {
			this.waitTextShow = false;
			this.requestCounter++;
			const totalCalls = this.payload['data'].user_input.num_outputs;
			let payload = JSON.parse(JSON.stringify(this.payload));
			payload['data'].user_input.num_outputs = 1;
			this.userServiceApi
				.generateBlogData(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: response => {
						this.paraApiCalls++;
						this.paraLoading = false;
						this.conclusion = [];
						this.endTime = performance.now();
						if (response['body'].debugMessage === 'Not enough credit') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
							return;
						}
						if (response['body'].message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.stepClick(4);
							this.wizardStack.pop();
							this.outlineSubmitted = false;
						}
						if (this.paraApiCalls == totalCalls) {
							this.paraDataHolder.push(...response['body'].data.paragraphs);
							this.sortedOutline[this.indexHelper]['data'] = [...this.paraDataHolder];
							this.stepClick(5);
							this.isLoadingData = false;
						} else {
							this.paraDataHolder.push(...response['body'].data.paragraphs);
						}
						// this.sortedOutline[this.indexHelper]['data'] = response['body'].data.paragraphs;
						// // this.currentOutline[this.selectedOutline[this.outlineIndex]].title = this.outline[this.selectedOutline[this.outlineIndex]];
						// this.stepClick(5);
						// console.log(this.sortedOutline[this.indexHelper].data);
					},
					error: error => {
						if (error.error.message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generatePara();
							}, 10000);
						} else {
							this.isLoadingData = true;
							this.waitTextShow = false;
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again later');
						}
					}
				});
		});
	}

	public generateParaAgain(): void {
		// document.getElementById('paraScroll').scrollIntoView();
		this.paraLoading = true;
		this.stepClick(5);
		this.outlineSubmitted = true;
		this.isLoadingData = true;
		this.timeDiff();
		this.payload['data'].user_input.title = this.outline[this.selectedOutline[this.outlineIndex]];
		this.payload['data'].user_input.blog_type = 'paragraph';
		this.indexHelper = this.selectedOutline[this.outlineIndex];
		let apiCount = this.payload['data'].user_input.num_outputs;
		this.paraApiCalls = 0; // reseting intro api calls
		this.paragraph = []; // reseting intros, adding all in last api call
		this.paraDataHolder = [];
		while (apiCount) {
			this.generateParaAgainHelper();
			apiCount--;
		}
	}

	generateParaAgainHelper(): void {
		this.timeDiff().then(() => {
			this.waitTextShow = false;
			this.requestCounter++;
			const totalCalls = this.payload['data'].user_input.num_outputs;
			let payload = JSON.parse(JSON.stringify(this.payload));
			payload['data'].user_input.num_outputs = 1;
			this.userServiceApi
				.generateBlogData(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: response => {
						this.paraApiCalls++;
						this.paraLoading = false;
						this.endTime = performance.now();
						if (response['body'].debugMessage === 'Not enough credit') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
							return;
						}
						if (response['body'].message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generateParaAgain();
							}, 30000);
						}
						if (this.paraApiCalls == totalCalls) {
							this.paraDataHolder.push(...response['body'].data.paragraphs);
							this.sortedOutline[this.indexHelper]['title'] = this.payload['data'].user_input.title;
							this.sortedOutline[this.indexHelper]['data'].push(...this.paraDataHolder);
							this.stepClick(5);
							this.isLoadingData = false;
						} else {
							this.paraDataHolder.push(...response['body'].data.paragraphs);
						}
						// this.sortedOutline[this.indexHelper]['title'] = this.payload['data'].user_input.title;
						// this.sortedOutline[this.indexHelper]['data'] = response['body'].data.paragraphs;
						// this.stepClick(5);
						// this.currentOutline[this.selectedOutline[this.outlineIndex]].title = this.outline[this.selectedOutline[this.outlineIndex]];
					},
					error: error => {
						if (error.error.message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generateParaAgain();
							}, 10000);
						} else {
							this.isLoadingData = true;
							this.waitTextShow = false;
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again later');
						}
					}
				});
		});
	}

	public onBack(): void {
		// if (this.outlineIndex == 0) return;
		this.outlineIndex--;
		if (this.outlinePara[this.outlineIndex]) {
			console.log(this.outlinePara[this.outlineIndex].index);
			setTimeout(() => {
				let radio = document.getElementById(`para${this.outlinePara[this.outlineIndex].index}`) as HTMLInputElement;
				radio.checked = true;
				radio.click();
				console.log(radio);
			}, 300);
			console.log(`para${this.outlinePara[this.outlineIndex].index}`);
		}
		this.indexHelper = this.selectedOutline[this.outlineIndex];
	}

	public onForward(): void {
		// if (this.outlineIndex <= this.selectedOutline.length - 1) return;
		this.outlineIndex++;

		if (this.outlinePara[this.outlineIndex]) {
			console.log(this.outlinePara);
			console.log(this.outlineIndex);
			console.log(this.outlinePara[this.outlineIndex].index);
			setTimeout(() => {
				let radio = document.getElementById(`para${this.outlinePara[this.outlineIndex].index}`) as HTMLInputElement;
				radio.checked = true;
				radio.click();
			}, 300);
			console.log(`para${this.outlinePara[this.outlineIndex].index}`);
		}
		this.indexHelper = this.selectedOutline[this.outlineIndex];
		if (this.sortedOutline[this.indexHelper].data.length == 0) {
			this.generateParaAgain();
		}
	}

	public generateConclusion(): void {
		this.wizardStack.push('conclusion');
		document.getElementById('concScroll').scrollIntoView({ behavior: 'smooth', block: 'start' });
		this.stepClick(6);
		this.timeDiff();
		this.paraSubmitted = true;
		this.conclusion = [];
		this.isLoadingData = true;
		this.payload['data'].user_input.title = this.headlineForm.controls.headline.value;
		this.payload['data'].user_input.blog_type = 'conclusion';
		let apiCount = this.payload['data'].user_input.num_outputs;
		this.conApiCalls = 0; // reseting conclusion api calls
		this.conDataHolder = []; // reseting conclusion, adding all in last api call
		while (apiCount) {
			this.generateConclusionHelper();
			apiCount--;
		}
	}

	generateConclusionHelper(): void {
		this.timeDiff().then(() => {
			this.waitTextShow = false;
			this.requestCounter++;
			const totalCalls = this.payload['data'].user_input.num_outputs;
			let payload = JSON.parse(JSON.stringify(this.payload));
			payload['data'].user_input.num_outputs = 1;
			this.userServiceApi
				.generateBlogData(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: response => {
						this.endTime = performance.now();
						this.conApiCalls++;
						if (response['body'].debugMessage === 'Not enough credit') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
							return;
						}
						if (response['body'].message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = false;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.stepClick(4);
							this.wizardStack.pop();
							this.outlineSubmitted = false;
						}
						if (this.conApiCalls == totalCalls) {
							this.conDataHolder.push(...response['body'].data.conclusion);
							this.conclusion = [...this.conDataHolder];
							this.stepClick(6);
							this.isLoadingData = false;
						} else {
							this.conDataHolder.push(...response['body'].data.conclusion);
						}
					},
					error: error => {
						if (error.error.message == 'Request Limit exceeded, please retry in 10 minutes!') {
							this.isLoadingData = true;
							this.toastNotificationService.sendErrorToast('You have made too many requests, please wait for 30 seconds and try again');
							this.waitTextShow = true;
							setTimeout(() => {
								this.generateConclusion();
							}, 10000);
						} else {
							this.isLoadingData = true;
							this.waitTextShow = false;
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again later');
						}
					}
				});
		});
	}

	public finishBlog() {
		switch (this.skipFunc[this.skipStep] + 1) {
			case 'headline':
				if (!this.headlineForm.valid) return;
				break;
			case 'introduction':
				if (!this.introForm.valid) return;
				break;
			case 'outline':
				if (!this.outlineForm.valid) return;
				break;
			case 'paragraph':
				if (!this.paraForm.valid) return;
				break;
			case 'conclusion':
				if (this.selectedOutline.length == 0) return;
				break;
		}
		let fullBlog = {
			headline: this.headlineForm.value,
			introduction: this.introSkipped ? '' : this.introForm.value,
			outline: this.outlineSkipped ? '' : this.outlinePara,
			outlineStar: this.selectedOutline,
			outlineHelper: this.outline,
			conclusion: this.conclusionSkipped ? '' : this.conclusionForm.value,
			language: this.language,
			isParaSkipped: this.paraSkipped
		};
		this.templateStore.dispatch(new LoadWizardHistory(fullBlog));
		this.documentService.createNewFromScratch(this.headlineForm.value.headline);
		this.shakespeareService.fromWizardData$.next(true);
		// this.router.navigate(['texteditor']);
	}
}
