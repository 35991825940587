import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OverviewDataService } from '../../services/overview-data.service';
import { Router } from '@angular/router';
import { OptiomizationAiService } from 'src/app/shakespeare/OptimizationAI/optiomization-ai.service';
import { CreateComponent } from 'src/app/shakespeare/OptimizationAI/ai-recommendation/popups/create/create.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';
const numWords = require('num-words');

@Component({
	selector: 'app-cards-overview',
	templateUrl: './cards-overview.component.html',
	styleUrls: ['./cards-overview.component.scss']
})
export class CardsOverviewComponent implements OnInit {
	@Input() overallData: any;
	@Input() otherCardData: any;
	@Input() brandLogo: any;
	@Input() fromDate: any;
	@Input() toDate: any;
	@Input() creativeLoading: any;
	@Input() optimizeLoading: any;

	chartsOverview: any;

	public platform = [
		{ name: 'Google', id: 1 },
		{ name: 'Facebook', id: 2 }
	];

	public aiCalibrationData: any = [];

	constructor(
		private router: Router,
		private optiomizationAiService: OptiomizationAiService,
		private dialog: MatDialog,
		private toastNotification: ToastNotificationService,
		private adsService: AdsService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		// if (changes.fromDate || changes.toDate) {
		// 	if (changes.fromDate) {
		// 	}
		// 	if (changes.toDate) {
		// 	}
		// }
		if (changes.overallData.firstChange) {
			if (this.overallData.length == 1) {
				this.onArrowClicked(this.overallData[0], 0);
			}
		}

		if (changes.overallData.previousValue?.length != changes.overallData.currentValue?.length) {
			this.getAiCalibrationData(changes.overallData.currentValue?.length);
		}
	}

	ngOnInit(): void {
		// console.log(this.overallData);
	}

	private initAiCalibrationData(data): void {
		this.aiCalibrationData = data.map(overview => ({
			adAccountId: overview.adAccountId,
			loading: true,
			aiCalibration: false
		}));
	}

	private getAiCalibrationData(size: number): void {
		const data = size > 3 ? this.overallData.slice(3) : this.overallData.slice(0);
		this.initAiCalibrationData(data);

		data.forEach(overview => {
			// if (overview.aiCategories?.length)
			this.getModel(overview.adAccountId);
		});
	}

	private setCalibrationData(res, dataIndex): void {
		const data = res?.data;

		if (data && data[0]?.modelId) {
			this.aiCalibrationData[dataIndex].aiCalibration = true;
		}
	}

	private getModel(accountID: string): void {
		if (this.brandLogo == 'google') {
			const dataIndex = this.aiCalibrationData.findIndex(item => item.adAccountId == accountID);

			this.adsService
				.getModel(accountID)
				.subscribe({
					next: res => {
						this.setCalibrationData(res, dataIndex);
					},
					error: err => {}
				})
				.add(() => {
					this.aiCalibrationData[dataIndex].loading = false;
				});
		} else {
			if (accountID.includes('act')) {
				accountID = accountID.slice(4);
			}
			const dataIndex = this.aiCalibrationData.findIndex(item => item.adAccountId == accountID);

			this.adsService
				.getFBModel(accountID)
				.subscribe({
					next: res => {
						this.setCalibrationData(res, dataIndex);
					},
					error: err => {}
				})
				.add(() => {
					this.aiCalibrationData[dataIndex].loading = false;
				});
		}
	}

	public createCategory(id): void {
		if (this.brandLogo == 'google') {
			localStorage.setItem('accountId', id);
			this.router.navigate(['/ads/google/dashboard']);
		} else {
			localStorage.setItem('FbAccountId', id);
			this.router.navigate(['/ads/facebook/dashboard']);
		}
	}

	public createPopup(id): void {
		// if (!this.campaigns.length) {
		// 	this.toastNotification.sendCustomToast('No campaigns in this Ad Account select different Ad Account', ToastNotificationType.Warning, 3000, 'Oops');
		// 	return;
		// }

		let dialogRef = this.dialog.open(CreateComponent, {
			width: '500px',
			height: 'auto',
			panelClass: ['targeting-bulk', 'matdialog-no-padding'],
			data: {
				type: this.brandLogo,
				// campaigns: this.campaigns,
				accountId: id
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result?.res) {
			}
		});
	}

	public getOptimizeCount(item: any): number {
		return this.otherCardData?.optimize[this.brandLogo][item.adAccountId] || 0;
	}

	public getOptimizationScore(item: any): number {
		return this.otherCardData?.creative[this.brandLogo][item.adAccountId]?.optimizationScore || 0;
	}

	public getWordFormattedCount(item: any): string {
		return numWords(this.otherCardData?.creative[this.brandLogo][item.adAccountId]?.nonPerformingAds || 0);
	}

	onArrowClicked(selectedItem: any, i: any) {
		for (let item of this.overallData) {
			if (item.adAccountId == selectedItem.adAccountId) {
				item.isSelected = !item.isSelected;
			} else {
				item.isSelected = false;
			}
		}

		// Calculate the scroll position based on the clicked row
		const rowId = `overViewId${i}`;

		const rowElement = document.getElementById(rowId);
		if (rowElement) {
			rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	public gotoAiCreative(item: any): void {
		const id = item.adAccountId;

		if (this.brandLogo == 'google') {
			localStorage.setItem('accountId', id);
			this.router.navigate(['ads/google/auth']);
		} else {
			localStorage.setItem('FbAccountId', id);
			this.router.navigate(['ads/facebook/auth']);
		}
	}

	public gotoAiOptimize(item: any): void {
		// console.log(item);
		const id = item.adAccountId;

		if (this.brandLogo == 'google') {
			localStorage.setItem('optimizeAiSelectedPlatform', JSON.stringify(this.platform[0]));
			localStorage.setItem('accountId', id);
		} else {
			localStorage.setItem('optimizeAiSelectedPlatform', JSON.stringify(this.platform[1]));
			this.optiomizationAiService.setAdaccount({
				id,
				name: item.adAccountName
			});
		}
		this.router.navigate(['/optimization-ai/ai-recommendation']);
	}
}
