import { takeUntil } from 'rxjs/operators';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LogIn, RegisterUserEmail, RegisterUserPassword, RegisterUserToken, RegistrationDetail } from '../../state/authentication.action';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { SetPassword } from 'src/app/_models/identity-models/set-password';
import { LogInModel, UserDetailModel } from '../../_models/login.model';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public passwordControl: FormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;
	public passwordIsValid: boolean = false;
	public stepToMark: number = 0;
	public passwordForm: FormGroup;

	public minimumChar: boolean = false;
	public upperChar: boolean = false;
	public numberChar: boolean = false;
	public specialChar: boolean = false;
	public allValidated: boolean = false;

	public validColor: string;

	public registrationDetail: UserDetailModel;
	public token: string;
	public emailValue: string;

	public firstNameValidationIcon = null;
	public lastNameValidationIcon = null;
	public passwordValidationIcon = null;

	public label: string = 'Enter your password';

	public emailExist = true;

	constructor(
		private store: Store<AuthenticationState>,
		private backOfficeService: BackOfficeService,
		private sharedStore: Store<SharedState>,
		private authStore: Store<AuthenticationState>,
		private toastService: ToastNotificationService,
		private activatedRoute: ActivatedRoute
	) {
		this.checkUserExists();
	}

	public ngOnInit(): void {
		this.passwordControl = new FormControl('', [Validators.required]);

		this.passwordForm = new FormGroup({
			firstName: new FormControl(''),
			lastName: new FormControl(''),
			password: new FormControl('')
		});
		this.formChanges();
		this.store.dispatch(
			new RegistrationDetail({
				name: '',
				lastname: '',
				phone: 0,
				dialCode: '',
				email: '',
				password: '',
				plan: 0,
				duration: '',
				token: '',
				companyName: ''
			})
		);
		this.getActivatedRoute();
		this.getRegistrationDetails();
	}

	public updateValidators(): void {
		this.passwordForm.setControl(
			'firstName',
			new FormControl(this.passwordForm.controls.firstName.value, {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z]+$/)],
				updateOn: 'change'
			})
		);

		this.passwordForm.setControl(
			'lastName',
			new FormControl(this.passwordForm.controls.lastName.value, {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z]+$/)],
				updateOn: 'change'
			})
		);
		this.passwordForm.setControl(
			'password',
			new FormControl(this.passwordForm.controls.password.value, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(50),
					Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
				],
				updateOn: 'change'
			})
		);
		this.passwordForm.markAllAsTouched();
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);
			this.store.dispatch(new RegisterUserEmail(this.emailValue));
			this.store.dispatch(new RegisterUserToken(this.token));
		});
	}

	public ngOnDestroy(): void {}

	public formChanges(): void {
		this.passwordControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
	}

	public checkForInvalid(): void {
		const password = this.passwordControl.value;
		if (password === undefined || password === '') {
			this.passwordControl.setErrors({ dialCode: true });
			this.minimumChar = false;
			this.upperChar = false;
			this.numberChar = false;
			this.specialChar = false;
		}
	}

	public passwordValid(event: boolean): void {
		this.passwordIsValid = event;
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.registrationDetail = detail;
				this.passwordControl.setValue(detail.password);
			}
		});
	}

	public resetLabel(): void {
		this.label = '';
	}

	public checkUserExists(): void {
		this.backOfficeService
			.validateEmail(this.emailValue)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.emailExist = true;
				} else {
					this.emailExist = false;
				}
			});
	}

	public continueSignup(): void {
		this.updateValidators();
		if (this.passwordForm.valid) {
			this.store.dispatch(new RegisterUserPassword(this.passwordControl.value));
			this.onSubmit();
		}
	}

	public onSubmit(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.submitting = true;
		const newPassword: SetPassword = {
			FirstName: this.passwordForm.controls.firstName.value.trim(),
			LastName: this.passwordForm.controls.lastName.value.trim(),
			Email: this.registrationDetail.email,
			Password: this.passwordForm.controls.password.value.trim(),
			ConfirmPassword: this.passwordForm.controls.password.value.trim(),
			TwoFactorToken: this.registrationDetail.token
		};

		this.backOfficeService
			.setPassword(newPassword)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					const loginPayload: LogInModel = {
						email: this.registrationDetail.email,
						password: this.passwordForm.controls.password.value.trim()
					};
					localStorage.setItem('loginDetail', JSON.stringify(loginPayload));

					this.authStore.dispatch(new LogIn(loginPayload));
				},
				() => {
					this.toastService.sendCustomToast(this.errorMessage, ToastNotificationType.Error, 8000, 'Password Setup!');
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
