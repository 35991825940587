<div class="card">
	<img src="assets/shakespeare/check-circle.svg" />
	<h3>Welcome, {{ this.userFName }}.</h3>
	<div>
		<p>Thank you for joining Shakespeare.</p>
		<p class="small">Ready for the grand tour?</p>
	</div>
	<div class="foot">
		<app-button buttonClass="primary" (onClick)="closeDialog(true)">
			Let's get started
		</app-button>
	</div>
</div>
