export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back'
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel'
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next'
	},
	finish: {
		classes: 'next-button',
		text: 'Finish',
		type: 'next'
	}
};

export const defaultStepOptions = {
	classes: 'custom-shephered',
	scrollTo: true,
	cancelIcon: {
		enabled: true
	}
};

export const steps: any = [
	{
		attachTo: {
			element: '.overview-padding',
			on: 'right'
		},
		buttons: [{ text: '2/6', classes: 'text-btn' }, STEPS_BUTTONS.cancel, STEPS_BUTTONS.next],
		classes: 'intro-step',
		id: 'intro',
		title: `<div class='custom-back'><h5>Overview</h5> <span> <img src='assets/tour-chart.svg' /> </span> </div>`,
		text: `<div class='custom-text'>See the most important data for your marketing campaigns. Knowledge is power; you’ll find all the information you need.</div>`
	},
	{
		attachTo: {
			element: '.firstActiveAI-Creative',
			on: 'right'
		},
		buttons: [{ text: '3/6', classes: 'text-btn' }, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
		classes: 'step2',
		id: 'intro',
		title: `<div class='custom-back'><h5>AI Creative</h5>  <span> <img src='assets/tour-text.svg' /> </span> </div>`,
		text: `<div class='custom-text'>See the performance of your Ad Creatives. Create enhanced Adverts using AI and improve your ROI.</div>`
	},
	{
		attachTo: {
			element: '.firstActiveOptimization-AI',
			on: 'right'
		},
		buttons: [{ text: '4/6', classes: 'text-btn' }, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
		classes: 'step3',
		id: 'intro',
		title: `<div class='custom-back'><h5>AI Optimization</h5> <span> <img src='assets/tour-search.svg' /> </span></div>`,
		text: `<div class='custom-text'>See AI-based recommendations to find all the opportunities to increase performance and maximize your results.</div>`
	},
	{
		attachTo: {
			element: '.firstActiveAI-Targeting',
			on: 'right'
		},
		buttons: [{ text: '5/6', classes: 'text-btn' }, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
		classes: 'step4',
		id: 'intro',
		title: `<div class='custom-back'><h5>AI Targeting</h5> <span> <img src='assets/tour-search.svg' /> </span></div>`,
		text: `<div class='custom-text'>Find Google keywords & Facebook audiences effortlessly. Let AI guide your campaign to success!</div>`
	},
	{
		attachTo: {
			element: '.tour-knowledge',
			on: 'bottom'
		},
		buttons: [{ text: '6/6', classes: 'text-btn' }, STEPS_BUTTONS.back, STEPS_BUTTONS.finish],
		classes: 'step5',
		id: 'intro',
		title: `<div class='custom-back'><h5>Knowledge Hub</h5> <span> <img src='assets/tour-brain.svg' /> </span> </div>`,
		text: `<div  class='custom-text'>Get all the knowledge you need about Shakespeare to explore and get started with the software.</div>`
	}
	// {
	// 	attachTo: {
	// 		element: 'p',
	// 		on: 'top'
	// 	},
	// 	buttons: [
	// 		STEPS_BUTTONS.cancel,
	// 		{
	// 			...STEPS_BUTTONS.back,
	// 			text: 'Custom back button for this step',
	// 			classes: 'green-button',
	// 			secondary: false
	// 		},
	// 		STEPS_BUTTONS.next
	// 	],
	// 	classes: 'custom-class-name-1 custom-class-name-2',
	// 	id: 'installation',
	// 	title: 'Step in paragraph',
	// 	text: 'Content in paragraph at top'
	// },
	// {
	// 	attachTo: {
	// 		element: 'div',
	// 		on: 'left'
	// 	},
	// 	buttons: [STEPS_BUTTONS.next],
	// 	classes: 'custom-class-name-1 custom-class-name-2',
	// 	id: 'usage',
	// 	title: 'Step in div',
	// 	text: 'Content in div at left only with  next button',
	// 	cancelIcon: {
	// 		enabled: false
	// 	}
	// },
	// {
	// 	attachTo: {
	// 		element: 'a',
	// 		on: 'right'
	// 	},
	// 	buttons: [STEPS_BUTTONS.cancel, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
	// 	classes: 'custom-class-name-1 custom-class-name-2',
	// 	id: 'modal',
	// 	text: `Step with no title and no exit (x button) at right`,
	// 	cancelIcon: {
	// 		enabled: false
	// 	}
	// }
];
