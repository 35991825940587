<div class="container">
	<div class="left-dock">
		<div class="message">
			<span>
				Join
				<span class="bolder-text">1,000's</span>
				Of Users, Brands and Marketers from:
			</span>
		</div>
		<div class="brands">
			<img src="assets/shakespeare/google-icon.svg" alt="Google" />
			<img src="assets/shakespeare/microsoft-icon.svg" alt="microsoft" />
			<img src="assets/shakespeare/airbnb-icon.svg" alt="airbnb" />
			<img src="assets/shakespeare/appleinc-icon.svg" alt="Apple" />
			<img src="assets/shakespeare/infosys-icon.svg" alt="Infosys" />
			<img src="assets/shakespeare/amazon-icon.svg" alt="amazon" />
			<img src="assets/shakespeare/alibaba-icon.svg" alt="Alibaba" />
			<img src="assets/shakespeare/tcs-icon.svg" alt="tcs" />
		</div>
	</div>
	<div class="right-dock">
		<div [class.hide]="submitting" class="wrapper">
			<form [class.hide]="submitting" [formGroup]="signupForm" #form="ngForm">
				<div class="logo">
					<img class="logo-shakespeare" src="assets/logo/shakespeare-full-logo.svg" />
					<!-- <div class="logo-text">{{'Shakespeare'}}</div> -->
				</div>
				<div class="started-text">{{ "Let's Get Started" }}</div>
				<div class="cta-text">{{ "You'll be up & running in 2 minutes" }}</div>
				<div class="continue-btn-wrapper">
					<div id="google-btn"></div>
					<!-- <div class="btn">
						<div class="items">
							<img src="assets/images/rec-google-btn.png" />
						</div>
					</div> -->
					<div class="btn_google">
						<div class="cta">
							<!-- <img src="assets/images/rec-google-btn.png" /> -->
							<div class="google_img">
								<img src="assets/images/fav-gog-icon.svg" />
							</div>
							<div class="google_txt">
								<p class="m-0">Sign up with Google</p>
							</div>
						</div>
					</div>
				</div>
				<div class="rule-a-text">
					<hr />
					<div class="text">{{ 'Or register with your email' }}</div>
				</div>

				<div class="text-input-split">
					<!-- <div class="left">
						<label class="label">{{ 'First Name' }}</label>
						<div class="title-input">
							<app-text-input
								[inputPlaceholder]="'Enter Your First Name'"
								[control]="signupForm.get('firstName')"
								[errorMessage]="'Enter a valid first name'"
							></app-text-input>
						</div>
					</div>
					<div class="right">
						<div class="label">{{ 'Last Name' }}</div>
						<div class="title-input">
							<app-text-input
								[styleClass]="''"
								[inputPlaceholder]="'Enter Your Last Name'"
								[control]="signupForm.get('lastName')"
								[errorMessage]="'Enter a valid last name'"
							></app-text-input>
						</div>
					</div> -->

					<app-mat-input-text
						[label]="'First Name'"
						class="short"
						[errorMessage]="'Enter a first name'"
						[control]="signupForm.get('firstName')"
					></app-mat-input-text>
					<app-mat-input-text
						[label]="'Last Name'"
						class="short"
						[errorMessage]="'Enter a last name'"
						[control]="signupForm.get('lastName')"
					></app-mat-input-text>
				</div>

				<div class="full-text-input l-margin">
					<!-- <div class="label">{{ 'Email Address' }}</div>
					<app-email-input-field [showValidIcon]="true" [hideError]="false"
						(keyInputEvent)="onBlurEmail($event)" class="title-input"
						[inputPlaceholder]="'Enter your email'" [errorMessage]="emailErrorText"
						[control]="signupForm.get('email')"></app-email-input-field> -->
					<!-- <input type="text" formControlName="email" (keyup)="onBlurEmail($event)" /> -->

					<app-mat-input-text
						[label]="'Email Address'"
						[errorMessage]="emailErrorText"
						[control]="signupForm.get('email')"
						(keyUp)="onBlurEmail($event)"
					></app-mat-input-text>
				</div>

				<div class="text-input-split l-margin">
					<!--					<div class="left">
						<div class="label">{{ 'Password' }}</div>
						<app-input-field-password [showValidIcon]="true" [hideError]="false" class="title-input"
							[inputPlaceholder]="'Enter Password'" [control]="signupForm.get('password')"
							[showCustomIcon]="passwordValidationIcon"
							[errorMessage]="'Strong password required with 1 upper, 1 lowercase, 1 special character and 1 number'"
							[iconWidth]="'14px'" [marginRight]="'8px'"></app-input-field-password>
					</div>
					<div class="right">
						<div class="label c-p">{{ 'Confirm Password' }}</div>
						<app-input-field-password [showValidIcon]="true" [hideError]="false" class="title-input"
							[inputPlaceholder]="'Confirm Password'" [control]="signupForm.get('confirmPassword')"
							[showCustomIcon]="showConfirmPasswordError"
							[errorMessage]="'Confirm password is not same as password'" [iconWidth]="'12px'"
							[marginRight]="'8px'"></app-input-field-password>
						<!~~ <i class="eye-button"id="togglePassword"><img src="assets/logo/feather_lock_icon.svg" alt=""></i> ~~>
					</div>-->
					<app-mat-input-password
						[label]="'Password'"
						[control]="signupForm.get('password')"
						class="short"
						[errorMessage]="'Strong password required with 1 upper, 1 lowercase, 1 special character and 1 number'"
					></app-mat-input-password>
					<app-mat-input-password
						[label]="'Confirm Password'"
						[control]="signupForm.get('confirmPassword')"
						class="short"
						[errorMessage]="'Confirm password is not same as password'"
					></app-mat-input-password>
				</div>

				<div (click)="processRegistrationDetail()" class="signin-btn-wrapper">
					<div class="btn">
						<div class="items">
							<div class="text">{{ 'Register' }}</div>
						</div>
					</div>
				</div>

				<div class="bottom-text-action">
					<div class="dark">{{ 'Already have an account?' }}</div>
					<div class="theme">
						<a routerLink="/authentication">{{ 'Login' }}</a>
					</div>
				</div>
				<div class="bottom-text-action change-display">
					<span class="dark">{{ "By continuing, you agree to Shakespeare's" }}</span>
					<span class="theme">
						<a href="https://www.shakespeare.ai/terms-of-service/">{{ 'Terms of Service.' }}</a>
					</span>
					<span class="dark">&nbsp; {{ 'Read our ' }}</span>
					<span class="theme">
						<a href="https://www.shakespeare.ai/privacy-policy/">{{ 'Privacy Policy.' }}</a>
					</span>
				</div>
			</form>
		</div>
		<div class="spinner-wrapper" *ngIf="submitting">
			<shakespeare-spinning-loader></shakespeare-spinning-loader>
		</div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
