import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopifyRoutingModule } from './shopify-routing.module';
import { ShopifyComponent } from './shopify.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AllProductsComponent } from './all-products/all-products.component';
import { ProductTableComponent } from './product-table/product-table.component';
import { ShopifyLauncherComponent } from './shopify-launcher/shopify-launcher.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductCardComponent } from './product-card/product-card.component';
import { ShopifyGeneratorComponent } from './shopify-generator/shopify-generator.component';
import { ShopifyGeneratorFormComponent } from '../components/shopify-generator-form/shopify-generator-form.component';
import { SideListComponent } from './side-list/side-list.component';
import { ViewProductComponent } from './view-product/view-product.component';
import { ShopifyOutputComponent } from './shopify-output/shopify-output.component';
import { ShopifyHistoryComponent } from './shopify-history/shopify-history.component';
import { ProductHistoryComponent } from './product-history/product-history.component';
import { MobileDropProductsComponent } from './mobile-drop-products/mobile-drop-products.component';
import { NewlyGeneratedComponent } from './newly-generated/newly-generated.component';
import { ShopifyModalComponent } from './shopify-modal/shopify-modal.component';

@NgModule({
	declarations: [
		ShopifyComponent,
		AllProductsComponent,
		ProductTableComponent,
		ShopifyLauncherComponent,
		ProductCardComponent,
		ShopifyGeneratorComponent,
		ShopifyGeneratorFormComponent,
		SideListComponent,
		ViewProductComponent,
		ShopifyOutputComponent,
		ShopifyHistoryComponent,
		ProductHistoryComponent,
		MobileDropProductsComponent,
		NewlyGeneratedComponent,
		ShopifyModalComponent
	],
	imports: [CommonModule, ShopifyRoutingModule, SharedModule, NgxPaginationModule]
})
export class ShopifyModule {}
