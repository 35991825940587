<section class="companyForm_section">
	<div class="title">
		<p>Company Details</p>
	</div>
	<form #targetCo [formGroup]="companyForm">
		<div class="companysec">
			<!-- <div class="comlabel material_select">
				<mat-form-field>
					<mat-label color="#121212">Select Industry</mat-label>
					<mat-select formControlName="industry">
						<mat-option *ngFor="let industry of industryArr" [value]="industry.value">
							{{ industry.value }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div> -->
			<div class="indusrty_container">
				<app-custom-drpdown-op1
					[data]="industryArr"
					[dropdownFormControl]="industryControl"
					(selectionChange)="industryResult($event)"
					[useCustom]="false"
					[placeHolder]="'Industry'"
					[searchEnable]="true"
					[noError]="false"
				></app-custom-drpdown-op1>
			</div>
			<div *ngIf="companyError" class="error-industry">
				<span>This field is required.</span>
			</div>
			<div class="comlabel">
				<!-- <label>Company Name</label> -->
				<div class="input_container">
					<input
						enterkeyhint="done"
						formControlName="companyName"
						type="text"
						[ngClass]="companyError ? 'custom-input' : null"
						placeholder="Company Name"
						(change)="companyInpChange()"
					/>
					<div *ngIf="companyError" class="error-field">
						<span>This field is required.</span>
					</div>
				</div>
			</div>
			<div class="comlabel domain">
				<!-- <label>Website Domain</label> -->
				<div class="input_container">
					<input
						enterkeyhint="done"
						formControlName="domain"
						type="text"
						[ngClass]="companyDomainError ? 'custom-input' : null"
						placeholder="Website Domain"
						(change)="domainInpChange()"
					/>
					<div *ngIf="companyDomainError" class="error-field">
						<span>This field is required.</span>
					</div>
					<div *ngIf="invalidDomain" class="error-field">
						<span>Enter Valid Domain URL</span>
					</div>
				</div>
			</div>
			<div class="comlabel">
				<!-- <label class="logo">Logo</label> -->
				<div class="input_container">
					<div *ngIf="!loadingCanvas || !backgroundImage">
						<ng-container *ngIf="!logoSkeleton">
							<input type="file" [hidden]="true" #filePicker (change)="uploadFile($event)" />
							<!-- <button [class.edit]="isEditCo" class="addlogo" (click)="filePicker.click()">+Add Logo</button> -->
							<button [class.edit]="isEditCo" class="addlogo" (click)="filePicker.click()">
								<mat-icon>upload</mat-icon>
								Upload Logo
							</button>
						</ng-container>
					</div>

					<input type="file" [hidden]="true" #filePicker1 (change)="uploadFile($event)" />
					<div *ngIf="showLogoContainer" class="logobox">
						<ng-container *ngIf="loadingCanvas && backgroundImage">
							<div class="imagebox" (click)="filePicker1.click()">
								<img [src]="backgroundImage" />
							</div>
							<div class="modify">
								<div class="replace">
									<a (click)="filePicker1.click()">
										<mat-icon>refresh</mat-icon>
									</a>
								</div>
								<!-- <div class="remove">
									<a class="remove" (click)="backgroundImage = ''">
										<mat-icon>close</mat-icon>
									</a>
								</div> -->
							</div>
						</ng-container>
						<ng-container *ngIf="logoSkeleton">
							<ngx-skeleton-loader
								appearance="line"
								style="height: 77px; width: 153px; margin-left: 0.4rem;"
								[theme]="{ width: '100%', height: '100%' }"
							></ngx-skeleton-loader>
						</ng-container>
					</div>
					<div *ngIf="logoError" class="error-field">
						<span>This field is required.</span>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="submit-con">
		<app-button [buttonClass]="'primary'" (onClick)="saveBranding()" class="save-btn">Save</app-button>
	</div>
</section>
