<div class="view-product">
	<div class="header">
		<span class="k-icon k-i-close close" (click)="dialogRef.close()"></span>
		<div class="toggle-views-con">
			<div class="desktop" (click)="changeView('desktop')" [ngClass]="{ active: view == 'desktop' }">
				<img src="assets/icons/desktop-shopify-icon.svg" alt="" />
			</div>
			<div class="mobile" (click)="changeView('mobile')" [ngClass]="{ active: view == 'mobile' }">
				<img src="assets/icons/mobile-shopify-icon.svg" alt="" />
			</div>
		</div>
	</div>
	<section>
		<img [ngClass]="{ 'mobile-img': view == 'mobile' }" #mobile [src]="view == 'desktop' ? desktopImg : mobileImg" alt="" />
		<div id="desktop-content" *ngIf="view == 'desktop'">
			<div class="left">
				<img [src]="product.images[0]" height="200px" alt="" />
			</div>
			<div class="right">
				<p class="label">Brand name</p>
				<p class="name">{{ product.title }}</p>
				<p class="desc" [innerHtml]="product.description"></p>
				<p class="price">$50.00</p>
				<p class="tax">Tax included.</p>
			</div>
		</div>
		<div
			id="mobile-content"
			[style.borderBottomRightRadius]="radius + 'px'"
			[style.borderBottomLeftRadius]="radius + 'px'"
			[style.width]="mobileWidth + 'px'"
			[style.height]="mobileHeight + 'px'"
			*ngIf="view == 'mobile'"
		>
			<div class="right">
				<p class="label">Brand name</p>
				<p class="name">{{ product.title }}</p>
				<p class="desc" [innerHtml]="product.description"></p>
			</div>
			<div class="left">
				<img [src]="product.images[0]" alt="" />
			</div>
			<div class="bottom">
				<p class="price">$50.00</p>
				<p class="tax">Tax included.</p>
			</div>
		</div>
	</section>
	<div class="footer">
		<app-button class="edit-btn" buttonClass="secondary" icon="fas fa-pen" (onClick)="editDesc()" iconPlace="before">Edit</app-button>
		<app-button buttonClass="primary">Save to Big Commerce</app-button>
	</div>
</div>
