import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { CancelModalService } from './../cancel-modal.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-why-cancelling',
	templateUrl: './why-cancelling.component.html',
	styleUrls: ['./why-cancelling.component.scss']
})
export class WhyCancellingComponent implements OnInit {
	public whyForm: FormGroup;

	constructor(
		private dialogRef: MatDialogRef<WhyCancellingComponent>,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data,
		private cancelModalService: CancelModalService,
		private shakespeareService: ShakespeareService
	) {}

	ngOnInit(): void {
		this.whyForm = this.fb.group({
			why: new FormControl('Too Expensive')
		});
	}

	public closeModal(): void {
		this.dialogRef.close();
	}

	public proceedToCancel(): void {
		let value = this.whyForm.controls.why.value;
		this.cancelModalService.cancelReason$.next(value);
		switch (value) {
			case 'Too Expensive':
				if (this.shakespeareService.isTrialPlan$.value || !this.shakespeareService.isPaidUser$.value) {
					this.dialogRef.close({ type: 'contact-us' });
				} else {
					this.data.type = 'offer';
				}
				break;
			case 'Found a Better Option':
			case 'Too Complicated':
			case 'Did Not Find the Product Useful':
				this.dialogRef.close({ type: 'contact-us' });
				break;
			case 'Other':
				this.dialogRef.close({ type: 'feedback' });
				break;
		}
	}

	public changeStepType(type: string): void {
		this.data.type = type;
	}

	public cancelStep(type: string): void {
		this.dialogRef.close({ type: type });
	}

	public acceptOffer(offer: string): void {
		this.dialogRef.close({ type: offer });
	}

	public goToStep1(): void {
		this.dialogRef.close();
		this.cancelModalService.openCancelModal();
	}
}
