import { Injectable } from '@angular/core';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentInterface } from './document-interface';
import { select, Store } from '@ngrx/store';
import { getFiledId } from '../../shared/state/user/user.reducer';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {
	public baseUrl = BaseApiUrl;
	public documentUrl = `${BaseApiUrl.DexterGenesis}dexter-content-creator/document/`;
	private filedId;
	constructor(private httpClient: HttpClient, private store: Store, private router: Router) {
		this.store.pipe(select(getFiledId)).subscribe(filedId => {
			this.filedId = filedId;
		});
	}

	public createDraft(data?: DocumentInterface): Observable<DocumentInterface> {
		const postData = {
			user: {
				userFiledId: this.filedId
			},
			data: data
		};
		return this.httpClient.post<DocumentInterface>(`${this.documentUrl}create`, postData);
	}
	public updateDocument(data?: DocumentInterface): Observable<DocumentInterface> {
		const postData = {
			user: {
				userFiledId: this.filedId
			},
			data: data
		};
		return this.httpClient.post<DocumentInterface>(`${this.documentUrl}update`, postData);
	}

	public deleteDocument(documentId?: string): Observable<DocumentInterface> {
		const postData = {
			user: {
				userFiledId: this.filedId
			},
			data: { id: documentId }
		};
		return this.httpClient.post<DocumentInterface>(`${this.documentUrl}delete`, postData);
	}

	public getDocument(documentId?: string): Observable<DocumentInterface[] | DocumentInterface> {
		const postData = {
			user: {
				userFiledId: this.filedId
			},
			data: { id: documentId }
		};
		return this.httpClient.post<DocumentInterface[] | DocumentInterface>(`${this.documentUrl}retrieve`, postData);
	}

	public createNewFromScratch(docName = 'Untitled Document'): void {
		const data: DocumentInterface = {
			name: docName,
			text: ''
		};
		this.createDraft(data).subscribe(res => {
			if (res) {
				this.router.navigateByUrl(`document/template?tempID=${res.id}`);
			}
		});
	}

	public createNewWizardEditor(): void {
		const data: DocumentInterface = {
			name: 'Untitled Document',
			text: ''
		};
		this.createDraft(data).subscribe(res => {
			if (res) {
				this.router.navigateByUrl(`texteditor?tempID=${res.id}`);
			}
		});
	}
}
