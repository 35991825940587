<div
	class="container"
	*ngIf="!loadingPage"
	style="padding-bottom: env(safe-area-inset-bottom); padding-top: env(safe-area-inset-top);"
	[ngClass]="{ expanded: menuExpanded }"
>
	<div class="header" [class.iphone-css]="iPhoneMobile">
		<img class="back-icon" src="assets/mobile-back-button.svg" (click)="goBack()" alt="back button" />
		<img class="header-logo hide-mobile" src="assets/icons/nav-menu/shopify.svg" alt="" />
		<img
			class="header-logo show-mobile"
			[src]="(selectedItem?.images)[0]"
			onerror="this.onerror=null;this.src='assets/icons/nav-menu/shopify.svg';"
			alt=""
		/>
		<div class="about">
			<p class="title hide-mobile">
				All Products
			</p>
			<p class="title mobile-dropdown">
				<span>{{ selectedTitle }}</span>
				<img (click)="toggleList()" [ngClass]="{ rotate: showAllproducts }" src="assets/icons/nav-menu/submenu-icon.svg" />
			</p>
		</div>
		<div class="mode-buttons hide-mobile">
			<button [class.active]="isOutput" (click)="isHistory = false; isOutput = true">
				Outputs
			</button>
			<button [class.active]="isHistory" (click)="isOutput = false; isHistory = true">
				History
			</button>
		</div>
		<div class="erase hide-mobile" matTooltip="Erase all history" [matTooltipPosition]="'left'" *ngIf="isHistory">
			<button class="erase-btn" (click)="deleteAll()"><i class="fa-solid fa-eraser"></i></button>
		</div>
	</div>
	<div class="generator-con" [class.mg-top]="isMargin" [class.iphone-css]="iPhoneMobile" [ngClass]="{ show: !showAllproducts }">
		<div class="quick-access">
			<app-side-list
				*ngIf="!loading"
				(selectedItem)="handleSelectedItem($event)"
				[products]="allProducts"
				(pageNumber)="handlePagination($event)"
				(sortType)="sortProducts($event)"
			></app-side-list>
			<div *ngIf="loading" [ngStyle]="{ width: '100%' }">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
		</div>
		<div class="form">
			<div class="form-con" #scroll>
				<app-shopify-generator-form
					class="ad-copy-form"
					[resetValues$]="clearValues$"
					[generatorForm]="shopifyForm"
					(changeLangSourceEvent)="changeLangSource($event)"
					(changeToneSourceEvent)="changeToneSource($event)"
				></app-shopify-generator-form>
			</div>
			<div
				class="form-footer {{ outputData?.length > 0 ? 'move-up' : '' }} {{ isSaveAllowed ? 'output-footer' : '' }}"
				[ngClass]="{ 'hide-for-editor': hideFooter }"
			>
				<div class="clear">
					<!-- <button class="clear-btn"><i class="fa-regular fa-xmark"></i>&nbsp; CLEAR INPUTS</button> -->
					<app-button buttonClass="gray" (onClick)="resetForm()" [icon]="'k-icon k-i-close close'"></app-button>
				</div>
				<div class="submit">
					<div class="drp-con">
						<app-custom-drpdown-op1
							class="max-output-drp"
							[data]="maxOutputArray"
							[dropdownFormControl]="shopifyForm?.get('numOutputs')"
							[useCustom]="false"
							[defaultSelectedValue]="{ name: '3 Outputs', value: 3 }"
							[hideClearButton]="true"
							[placeHolder]="'Output'"
						></app-custom-drpdown-op1>
					</div>
					<app-button
						[disabled]="isFormSubmitted"
						buttonClass="primary"
						[icon]="innerWidth > 1600 ? 'fas fa-arrow-right' : ''"
						(onClick)="onSubmit()"
					>
						{{ formButtonText }}
					</app-button>
				</div>
			</div>
			<div class="create-project-button {{ isSaveAllowed ? 'show' : '' }}">
				<div class="clear">
					<span>{{ favouriteData.length }} Output{{ favouriteData.length > 1 ? 's' : '' }}</span>
				</div>
				<app-button buttonClass="secondary" iconPlace="after" icon="fas fa-plus" (onClick)="openCreateModal(true)">Create Project</app-button>
			</div>
		</div>
		<div class="tab-actions" *ngIf="innerWidth < 1025">
			<div class="mode-buttons">
				<button [class.active]="isOutput" (click)="isHistory = false; isOutput = true">
					<!-- <img src="assets/shakespeare/text-editor/icon-eye{{ editorMode === editorModeEnum.Pro ? '-active' : '' }}.svg" alt="" /> -->
					Outputs
				</button>
				<button [class.active]="isHistory" (click)="isOutput = false; isHistory = true">
					<!-- <img src="assets/shakespeare/text-editor/assist-tab-icon{{ editorMode === editorModeEnum.Assist ? '-active' : '' }}.svg" alt="" /> -->
					History
				</button>
			</div>
			<div class="erase" matTooltip="Erase all history" [matTooltipPosition]="'left'" *ngIf="innerWidth < 1025 && isHistory">
				<button class="erase-btn" (click)="deleteAll()"><i class="fa-solid fa-eraser"></i></button>
			</div>
		</div>
		<div class="output" #output [class.footer-on]="isSaveAllowed && !isFormSubmitted">
			<!-- <mat-tab-group [class.save-hidden]="!isSaveAllowed" [class.data-fetched]="isDataFetched"> -->
			<div [style.display]="isOutput ? 'block' : 'none'" style="height: calc(100% - 100px);">
				<app-shopify-output
					(callbackData)="handleCallback($event)"
					(isDataFetched)="enableViewMore($event)"
					(generateDataComplete)="handleGeneratedData($event)"
					[formData]="payload"
					[selectedProduct]="selectedItem"
					[clearOutput$]="clearOutput$"
					(editorOn)="handleEditorLayout($event)"
				></app-shopify-output>
				<div *ngIf="!isFormSubmitted && !isDataFetched" class="div-flex out-no-data">
					<img src="assets/shakespeare/text-editor/smiley.svg" alt="" style="margin-bottom: 21px;" />
					<p class="excited-text">
						<!-- Hello {{ userFName }},
					<br /> -->
						I am excited to work out some new content for you…
					</p>
					<p class="result-show">Your results will show here once Shakespeare finished working.</p>
				</div>
			</div>
			<div [style.display]="isHistory ? 'block' : 'none'">
				<div [style.display]="isHistoryFound ? 'block' : 'none'">
					<app-shopify-history
						(isDataFound)="isHistoryData($event)"
						(callbackData)="handleCallback($event)"
						[template]="selectedTemplate"
						[formData]="payload"
						[selectedProduct]="selectedItem"
						[customEdit]="customEdit$"
						[customDesc]="customEditDesc$"
						(editorOn)="handleEditorLayout($event)"
					></app-shopify-history>
				</div>
				<div *ngIf="!isHistoryFound" class="div-flex out-no-data">
					<p>
						No History Found
					</p>
				</div>
			</div>
			<!-- </mat-tab-group> -->
			<div class="form-footer saver hide-mobile" *ngIf="isSaveAllowed && !isFormSubmitted">
				<p class="fav-length">{{ favouriteData.length }} {{ favouriteData.length == 1 ? 'Output' : 'Outputs' }} Added to Favorite</p>
				<div *ngIf="isSaveAllowed" class="add-project">
					<app-button buttonClass="primary" icon="fas fa-plus" (onClick)="openCreateModal()">Create Project</app-button>
					<span class="tooltiptext"></span>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="{ show: showAllproducts }" class="mobile-products">
		<!-- <app-side-list
			[isDropdown]="true"
			*ngIf="!loading"
			[products]="allProducts"
			(sortType)="sortProducts($event)"
			(selectedItem)="handleSelectedItem($event)"
			(pageNumber)="handlePagination($event)"
		></app-side-list> -->
		<app-mobile-drop-products
			[isDropdown]="true"
			*ngIf="!loading"
			[products]="allProducts"
			(selectedItem)="handleSelectedItem($event)"
			(sortType)="sortProducts($event)"
			(pageNumber)="handlePagination($event)"
		></app-mobile-drop-products>
		<div *ngIf="loading" [ngStyle]="{ width: '100%' }">
			<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
		</div>
	</div>
</div>

<div *ngIf="loadingPage" [ngStyle]="{ width: '100%' }">
	<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
</div>
