import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CancelOfferComponent } from '../cancel-offer/cancel-offer.component';

@Component({
	selector: 'app-cancelled',
	templateUrl: './cancelled.component.html',
	styleUrls: ['./cancelled.component.scss']
})
export class CancelledComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<CancelOfferComponent>, @Inject(MAT_DIALOG_DATA) public data, private router: Router) {}

	ngOnInit(): void {}

	public closeModal(): void {
		this.dialogRef.close(true);
	}

	public navigateToBilling(): void {
		this.dialogRef.close();
		this.router.navigate(['/settings/billing']);
	}

	public proceedToCancel(): void {
		this.dialogRef.close(true);
	}
}
