import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LANGUAGES } from '../../../shared/models/languages.const';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-ad-copy-form',
	templateUrl: './ad-copy-form.component.html',
	styleUrls: ['./ad-copy-form.component.scss']
})
export class AdCopyFormComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public generatorForm: FormGroup = new FormGroup({});
	@Input() public advanceOptions: boolean = false;
	@Input() public selectedTemplate: any;
	@Input() public resettingForm: Subject<boolean> = new Subject<boolean>();
	@Input() public validate: Subject<boolean> = new Subject<boolean>();
	@Output() public validateChange: EventEmitter<boolean> = new EventEmitter();
	@Output() public genderValueChanged: EventEmitter<any> = new EventEmitter();
	@Output() public changeAgeSource: EventEmitter<any> = new EventEmitter();
	@Output() public changeToneSource: EventEmitter<any> = new EventEmitter();
	@Output() public changeLangSource: EventEmitter<any> = new EventEmitter();
	@Output() public onCheckToggleViewEmoji: EventEmitter<any> = new EventEmitter();
	@Output() public onCheckToggleView: EventEmitter<any> = new EventEmitter();
	private unSubscriber$ = new Subject<void>();
	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public ageArray = [
		{ name: 'All', value: '0' },
		{ name: '5-12', value: '5-12' },
		{ name: '13-19', value: '13-19' },
		{ name: '20-39', value: '20-39' },
		{ name: '40-59', value: '40-59' },
		{ name: '60', value: '60' }
	];

	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	public outputLang = [...LANGUAGES];

	public menuExpanded: any;
	constructor(private authenticationService: AuthenticationService) {
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	ngOnInit(): void {
		this.validate.subscribe(res => {
			if (res) {
				this.checkValidation();
			}
		});
		this.resettingForm.subscribe(res => {
			if (res) {
				this.resetForm();
			}
		});
	}
	public resetForm(): void {
		this.removeValidators();
		this.generatorForm.reset();
		this.advanceOptions = false;
		this.generatorForm.controls.numOutputs.setValue({ name: '3 Outputs', value: 3 });
	}
	ngOnChanges(): void {}
	ngOnDestroy(): void {
		this.unSubscriber$.next();
		this.unSubscriber$.complete();
	}

	public onRadioButtonClick(value: string): void {
		this.genderValueChanged.emit(value);
	}

	public checkToggleValue(event) {
		this.advanceOptions = event;
		this.onCheckToggleView.emit(event);
	}

	public checkValidation(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);

		this.generatorForm.setControl(
			'description',
			new FormControl(this.generatorForm.controls.description.value, {
				validators: [Validators.required, Validators.maxLength(400)]
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: [Validators.required]
			})
		);
		this.generatorForm.markAllAsTouched();
		this.generatorForm.updateValueAndValidity();
		this.validateChange.emit(this.generatorForm.valid);
	}

	public removeValidators(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: []
			})
		);

		this.generatorForm.setControl(
			'description',
			new FormControl(this.generatorForm.controls.description.value, {
				validators: []
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: []
			})
		);
	}
}
