import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, HostBinding } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ErrorMessageService } from 'src/app/shared/form-input/error-message.service';
@Component({
	selector: 'app-custom-drpdown-op1',
	templateUrl: './custom-drpdown-op1.component.html',
	styleUrls: ['./custom-drpdown-op1.component.scss']
})
export class CustomDrpdownOp1Component implements OnInit, OnDestroy {
	@ViewChild('ngSelect') ngSelect: NgSelectComponent;
	@Input() data: any[];
	@Input() placeHolder: string;
	@Input() noDataFoundLabel: string;
	@Input() dropdownFormControl: FormControl | AbstractControl = new FormControl();
	@Input() compareFunction: any;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() bindProperty = 'id';
	@Input() labelProperty = 'name';
	@Input() hideClearButton = false;
	@Input() enableAllActions = true;
	@Input() searchEnable = false;
	@Input() readOnly: boolean;
	@Input() pixelEventId: string = '';
	@Input() toolTip: boolean;
	@Input() rightCheckbox: boolean;
	@Input() fromOptimise: boolean;
	@Input() defaultSelectedValue: number;
	@Input() useCustom = false;
	@Input() custValidator: boolean = true;
	@Input() selectableGroup = false;
	@Input() multiple = false;
	@Input() isBilling = false;
	@Input() userPlan: any;
	@Input() attachTo = 'ng-select';
	@Input() noError = false;
	@Output() selectionChange = new EventEmitter<any>();
	@Output() selectionClick = new EventEmitter<any>();
	@Output() onClose = new EventEmitter();
	@Output() filters = new EventEmitter();

	public dropdownDataBuffer: any[];
	public bufferSize = 500;
	public numberOfItemsFromEndBeforeFetchingMore = 10;
	public loading = false;
	public errorMessage: string;
	public isDestinationFocused: boolean;

	public selectedValue: number;
	public helper = true;
	@Input() borderColor: string;
	private unsubscriber$: Subject<void> = new Subject<void>();
	iPhoneMobile: boolean;
	// @HostBinding('style.border') get borderStyle(): string {
	// 	return this.borderColor ? `2px solid ${this.borderColor}` : null;
	// }
	constructor(public errorMessageService: ErrorMessageService) {}
	ngOnInit() {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownFormControl);
		if (this.defaultSelectedValue !== null && this.defaultSelectedValue !== undefined) {
			this.selectedValue = this.defaultSelectedValue;
		}
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
	}
	// removeElement() {
	// 	let element: any = document.querySelector('.ng-dropdown-panel-items').lastChild;
	// 	element.lastElementChild.remove();
	// }

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}
	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.dropdownFormControl);
	}

	public onChange(event): void {
		this.dropdownFormControl.setValue(event);
		this.selectionChange.emit(event);
		(document.activeElement as HTMLElement).blur();
		// console.log(this.placeHolder, 'placeholder');
		// this.setDropdownPropertiesToLocalStorage(event);
	}
	setDropdownPropertiesToLocalStorage(e) {
		// console.log(this.dropdownFormControl);
		// if(e?.name){
		// 	if(e.name.toLowerCase() == 'google'){
		// 		localStorage.setItem('platform','')
		// 	}
		// }
	}

	// [addTag] function should be a function expression
	public createNew = (item: string): void => {
		if (this.useCustom) {
			let value = { name: item, value: item };
			this.dropdownFormControl.setValue(value);
			this.selectionChange.emit(value);
			this.data.push(value);
			// this.dropdownFormControl.setValue(value);
			this.dropdownFormControl.setErrors(null);
			this.ngSelect.close();
			(document.activeElement as HTMLElement).blur();
		} else {
			(document.activeElement as HTMLElement).setAttribute('inputmode', 'none');
		}
	};

	public compare = (item, selected) => {
		// if (item.name) {
		return item.name == selected;
		// }
	};
	public dropdownOpened(event: boolean): void {
		this.selectionClick.emit(event);
	}

	public onDropdownClose(): void {
		this.onClose.emit();
	}

	public onScrollToEnd(): void {
		this.increaseBuffer();
	}

	public onScroll(index: { start: number; end: number }): void {
		if (this.loading || this.data.length <= this.dropdownDataBuffer.length) {
			return;
		}

		if (index.end + this.numberOfItemsFromEndBeforeFetchingMore >= this.data.length) {
			this.increaseBuffer();
		}
	}

	public onResize($event: any, select: NgSelectComponent): void {
		select.close();
	}
	private increaseBuffer(): void {
		if (this.dropdownDataBuffer && this.dropdownDataBuffer.length) {
			const len = this.dropdownDataBuffer.length;
			const more = this.data.slice(len, this.bufferSize + len);

			this.loading = true;
			// simulate backend API delay
			setTimeout(() => {
				this.loading = false;
				this.dropdownDataBuffer = this.dropdownDataBuffer.concat(more);
			}, 50);
		}
	}

	getControl() {
		return this.dropdownFormControl as FormControl;
	}
	checkKey(event) {
		console.log(event.target.value);

		if (event.target.value != '') {
			// console.log(event)
			// this.removeElement()
		}
	}
}
