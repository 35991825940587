<div class="main-container">
	<div *ngIf="!loading" class="chevron-links">
		<h6>{{ kbArticles[0].subcategory }}</h6>
	</div>
	<div *ngIf="!loading" class="main-cards">
		<ng-container *ngFor="let sub of kbArticles; let i = index">
			<mat-card (click)="showSubView(sub)">
				<div class="section">
					<div class="section-description">
						<h5 [innerHTML]="sub.title"></h5>
					</div>
				</div>
			</mat-card>
		</ng-container>

		<div *ngIf="!kbArticles || kbArticles.length < 1">
			<h6>There are no articles in this category</h6>
		</div>
	</div>
	<div class="loader" *ngIf="loading">
		<shakespeare-spinning-loader></shakespeare-spinning-loader>
	</div>
</div>
