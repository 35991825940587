import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-mat-input-text',
	templateUrl: './mat-input-text.component.html',
	styleUrls: ['./mat-input-text.component.scss']
})
export class MatInputTextComponent implements OnInit {
	@Input('class') public class: string = '';
	@Input('label') public label: string = '';
	@Input('errorMessage') public errorMessage: string = '';
	@Input('control') public control: FormControl = new FormControl();
	@Output() public keyUp = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	public onKeyUp(event): void {
		this.keyUp.emit(event);
	}
}
