import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { CommonPopupWelcomeComponent } from '../common-popup-welcome/common-popup-welcome.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-google-ads-popup',
	templateUrl: './google-ads-popup.component.html',
	styleUrls: ['./google-ads-popup.component.scss']
})
export class GoogleAdsPopupComponent implements OnInit {
	loading: boolean = false;
	constructor(public dialogRef: MatDialogRef<GoogleAdsPopupComponent>, private router: Router, private backOfficeService: BackOfficeService) {}

	ngOnInit(): void {}

	closeGooglePopup() {
		this.dialogRef.close(false);
	}

	openCommonPopup() {
		// this.loading = true;
		let payload = 'google';
		this.backOfficeService.defaultSelectedPlatform(payload).subscribe(res => {
			// console.log(res);
			this.connectToGoogle();
		});
		// this.router.navigate(['google/auth-connect']);
		// this.dialogRef.close(true);
	}

	connectToGoogle(): void {
		this.saveCookie('googleRedirectType', 'AiIntelligence');
		const url = environment.Flags.production ? 'app.shakespeare.ai' : 'dev.shakespeare.ai';
		const id = environment.GOOGLE_ADS_ACCOUNT_ID;
		if (url && id) {
			// window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`;

			this.createOauthWindow(
				`https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`
			);
		}
		localStorage.setItem('GoogleLoader', 'true');
	}

	createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
		if (url == null) {
			return null;
		}
		const CLIENT_URL = environment.Flags.production ? 'app.shakespeare.ai' : 'dev.shakespeare.ai';
		left = screen.width / 2 - width / 2;
		top = screen.height / 2 - height / 2;
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		const popup = window.open(url, name, options);
		const checkPopup = setInterval(() => {
			// if (popup.window.location.href.includes(CLIENT_URL)) {
			if (popup.window.location.href.includes(CLIENT_URL + '/ads/google/auth?connected=true')) {
				popup.close();
				clearInterval(checkPopup);
				this.router.navigate(['/ads/google/auth'], {
					queryParams: {
						connected: true
					}
				});
			}
			if (popup.window.location.href.includes(CLIENT_URL + 'ads/google/dashboard')) {
				popup.close();
				clearInterval(checkPopup);
				this.router.navigate(['/ads/google/dashboard']);
			}
			if (!popup || !popup.closed) return;
			clearInterval(checkPopup);
		}, 1000);
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}
}
