<div class="bonus-offer" *ngIf="isBonus && showBonus">
	<div class="con">
		<div class="skew">
			<p class="text">Limited Time Offer</p>
		</div>
	</div>
	<div class="button-con">
		<p class="center-text">
			Upgrade Now
			<span>Get 85% off On PRO Plan for only $9</span>
		</p>
		<button (click)="navigateToBilling()">
			View Offer
			<em class="fas fa-arrow-right"></em>
		</button>
	</div>
</div>
<div class="scroll-wrapper" [ngClass]="{ expanded: menuExpanded }" [class.iphone-css]="iPhoneMobile">
	<div class="parent">
		<div class="container">
			<div class="header-con">
				<p *ngIf="!showFolderData && innerWidth > 1024">
					<em *ngIf="backBtn" (click)="goBack()" class="fas fa-chevron-left" style="color: #006bff; cursor: pointer;">&nbsp;&nbsp;</em>
					<span class="page-title">{{ toggleHelper + ' ' }}</span>
					<app-tooltip large="true" static="true" openModal="true" link="Learn more" [text]="tooltipText"></app-tooltip>
				</p>
				<p *ngIf="showFolderData">
					<em *ngIf="backBtn" (click)="goBack()" class="fas fa-chevron-left" style="color: #006bff; cursor: pointer;">&nbsp;&nbsp;</em>
					<span class="folder-page-title">{{ project.name }} &nbsp; > &nbsp; {{ folder.name }}</span>
				</p>
				<div class="search">
					<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
				</div>
			</div>

			<div *ngIf="notFound && !loading" class="no-templates">
				<h2>No {{ selectedTitle === 'projects' ? 'Projects' : 'Folders' }} found matching your search input!</h2>
			</div>

			<div class="body-con">
				<dts-select-container
					[dragOverItems]="true"
					[selectOnDrag]="true"
					[selectOnClick]="false"
					[disableRangeSelection]="false"
					[(selectedItems)]="selectedItemTemplates"
					(select)="onSelectProjects($event)"
				>
					<div class="spinner-wrapper" *ngIf="loading">
						<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
					</div>
					<div class="folders" *ngIf="selectedTitle == 'folders' && !loading">
						<div class="no-folders" *ngIf="folderDataArray.length === 0 && !loading && !notFound">
							<h2 class="text">You haven't created any folders yet.</h2>
						</div>
						<div class="folder-body" *ngIf="selectedTitle == 'folders' && !loading">
							<div
								class="folder-card"
								[ngClass]="{ renaming: isEditing === folder.folderId }"
								(click)="openFolder(folder.projectId, folder)"
								*ngFor="let folder of folderDataArray; let i = index"
							>
								<div class="check-form">
									<div class="folder-items">
										<img src="assets/shakespeare/projects/folder-icon.svg" alt="" />
										<div class="title">
											<span
												class="folder-name"
												[title]="folder.folderName"
												#folderEditor
												[style.display]="isEditing === folder.folderId ? 'none' : 'block'"
											>
												{{ folder.folderName }}
											</span>
											<form *ngIf="isEditing === folder.folderId">
												<input
													#folderEditorControl
													id="folderEditor-{{ i }}"
													class="r-click"
													[ngModel]="editedValue"
													[autofocus]="true"
													[ngModelOptions]="{ standalone: true }"
													(keydown)="setTextValue($event, i, $event.target.value, folder.projectId)"
												/>
											</form>
											<!-- </ng-container> -->
											<span
												class="project-name"
												[title]="folder.projectName"
												[style.display]="isEditing === folder.folderId ? 'none' : 'block'"
											>
												<em class="fas fa-chevron-right forward-icon"></em>
												{{ folder.projectName }}
											</span>
										</div>
										<!-- </ng-container> -->
									</div>
									<div class="actions">
										<span
											[style.display]="isEditing === folder.folderId ? 'none' : 'block'"
											(click)="addToFav($event, i, 'folders')"
											[matTooltip]="folder?.isFav ? 'Remove From Favorite' : 'Add To Favorite'"
											[matTooltipPosition]="'above'"
											[ngClass]="folder?.isFav ? 'fav' : 'heart'"
										></span>
										<div
											(click)="$event.stopPropagation()"
											[matMenuTriggerFor]="menu"
											aria-label="Menu"
											*ngIf="this.isEditing !== folder.folderId"
										>
											<mat-icon class="menu">more_vert</mat-icon>
										</div>
										<div
											*ngIf="this.isEditing === folder.folderId"
											class="save-button"
											(click)="completeRename(i, editedValue, 'folders', $event)"
										>
											<img class="check-icon" src="assets/templates/actions/check.svg" />
										</div>
										<mat-menu #menu="matMenu" class="mat-elevation-z2">
											<button
												class="r-click"
												name="rename"
												mat-menu-item
												(click)="rename(folder, folderEditor.innerHTML, 'folderEditor-' + i, 'folders')"
											>
												<span class="menu-icon r-click" style="margin-right: 9px;">
													<img class="r-click" src="assets/shakespeare/projects/rename-icon.svg" alt="" />
												</span>
												<span class="menu-title r-click">Rename</span>
											</button>
											<button mat-menu-item (click)="onDelete($event, folder, 'folders', folder.projectId)">
												<span class="menu-icon" style="margin-right: 9px;">
													<img src="assets/shakespeare/projects/delete-icon.svg" alt="" />
												</span>
												<span class="menu-title">Delete</span>
											</button>
										</mat-menu>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="projects" *ngIf="selectedTitle == 'projects' && !loading">
						<div class="no-projects" *ngIf="projectsArray.length === 0 && !notFound && !loading">
							<h2 class="text">You haven't created any projects yet.</h2>
						</div>
						<div class="projects-body">
							<div
								class="projects-card"
								[dtsSelectItem]="{ temp: project, index: i }"
								*ngFor="let project of projectsArray; let i = index"
								(click)="openProject(project.id)"
								[ngClass]="{ 'menu-open': project.isMenuOpen, renaming: isEditing === project.id }"
							>
								<div *ngIf="toggleHelper == 'projects'" [formGroup]="checkForm" class="check-form">
									<div class="project-items {{ isEditing == project.id ? 'editable' : '' }}">
										<img src="assets/icons/nav-menu/projects_icon.svg" alt="" />
										<span class="projects-title" #editor [style.display]="isEditing === project.id ? 'none' : 'block'">
											{{ project.name }}
										</span>
										<form *ngIf="isEditing === project.id">
											<input
												#editorControl
												class="r-click"
												id="editor-{{ i }}"
												[ngModel]="editedValue"
												[autofocus]="true"
												[ngModelOptions]="{ standalone: true }"
												(keydown)="setTextValue($event, i, $event.target.value)"
											/>
										</form>
									</div>
									<div class="actions">
										<span
											[style.display]="isEditing === project.id ? 'none' : 'block'"
											(click)="addToFav($event, i, 'projects')"
											[matTooltip]="project?.isFav ? 'Remove From Favorite' : 'Add To Favorite'"
											[matTooltipPosition]="'above'"
											[ngClass]="project?.isFav ? 'fav' : 'heart'"
										></span>
										<div (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" aria-label="Menu" *ngIf="isEditing !== project.id">
											<mat-icon class="menu">more_vert</mat-icon>
										</div>
										<div class="save-button" *ngIf="isEditing === project.id" (click)="completeRename(i, editedValue, 'projects', $event)">
											<img class="check-icon" src="assets/templates/actions/check.svg" />
										</div>
										<mat-menu #menu="matMenu" class="mat-elevation-z2">
											<button
												class="r-click"
												name="rename"
												mat-menu-item
												(click)="rename(project, editor.innerHTML, 'editor-' + i, 'projects')"
											>
												<span class="menu-icon r-click" style="margin-right: 9px;">
													<img class="r-click" src="assets/shakespeare/projects/rename-icon.svg" alt="" />
												</span>
												<span class="menu-title r-click">Rename</span>
											</button>
											<button mat-menu-item (click)="onDelete($event, project, 'projects')">
												<span class="menu-icon" style="margin-right: 9px;">
													<img src="assets/shakespeare/projects/delete-icon.svg" alt="" />
												</span>
												<span class="menu-title">Delete</span>
											</button>
										</mat-menu>
									</div>
								</div>
								<div *ngIf="toggleHelper !== 'projects'" class="check-form">
									<img src="assets/icons/nav-menu/projects_icon.svg" alt="" />
									<p class="projects-name">{{ project.name }}</p>
									<div class="actions">
										<em
											(click)="addToFav($event, i, 'projects')"
											class="heart fav"
											[ngClass]="project?.isFav ? filledHeartClass : heartClass"
										></em>
										<em (click)="onDelete($event, project, 'projects')" class="far fa-trash-alt"></em>
									</div>
								</div>
							</div>
						</div>
					</div>
				</dts-select-container>
			</div>
		</div>
	</div>
	<div class="footer" *ngIf="isFooter && selectedTitle === 'projects'">
		<div class="footer-con">
			<div class="left">
				<app-button buttonClass="tertiary" (onClick)="deselectAll()" icon="k-icon k-i-close">{{ checkedCount }} Selected</app-button>
			</div>
			<div class="right">
				<div class="move">
					<app-button buttonClass="primary" (onClick)="openCreateModal()">+ Create Folder</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
