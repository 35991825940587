import { FreeTrialComponent } from './../shakespeare/free-trial/free-trial.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { EmailRegisterComponent } from './new-signup/email-register/email-register.component';
import { EmailVerificationComponent } from './new-signup/email-verification/email-verification.component';
import { PasswordComponent } from './new-signup/password/password.component';
import { PaymentConfirmation } from './new-signup/payment-confirmation/payment-confirmation.component';
import { PaymentPageComponent } from './new-signup/payment-page/payment-page.component';
import { PersonalWelcomeComponent } from './new-signup/personal-welcome/personal-welcome.component';
import { AuthenticationRouteGuard } from '../shared/guards/authentication-route.guard';
import { ResetPasswordWithEmailComponent } from './reset-password-with-email/reset-password-with-email.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { AwesomeComponent } from './new-signup/awesome/awesome.component';
import { ShopifyLauncherComponent } from '../shakespeare/shopify/shopify-launcher/shopify-launcher.component';
import { ShopifyBillingComponent } from '../shakespeare/shopify-billing/shopify-billing.component';
import { AuthComponent } from '../shakespeare/big-commerce/auth/auth.component';
import { EcommercePlansComponent } from '../shakespeare/ecommerce-plans/ecommerce-plans.component';
import { EnterpriseComponent } from '../shakespeare/enterprise/enterprise.component';
import { V3enterpriseComponent } from './v3enterprise/v3enterprise.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { UpgradePlansComponent } from './upgrade-plans/upgrade-plans.component';

export const routes: Routes = [
	{ path: '', component: SigninComponent },
	{ path: 'free-trial', component: FreeTrialComponent },
	{ path: 'enterprise', component: EnterpriseComponent },
	{ path: 'reset-password-with-email', component: ResetPasswordWithEmailComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'forgot-password', component: SetPasswordComponent },
	{ path: 'register-email', component: EmailRegisterComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'verify-email', component: EmailVerificationComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-password', component: PasswordComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'set-first-password', component: PasswordComponent },
	{ path: 'personal-welcome', component: PersonalWelcomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'payment', component: PaymentPageComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'payment/success', component: PaymentConfirmation, canActivate: [] },
	{ path: 'awesome', component: AwesomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'shopify/app_verify', component: ShopifyLauncherComponent },
	{ path: 'shopify/auth', component: ShopifyLauncherComponent },
	{ path: 'settings/s/billing', component: ShopifyBillingComponent },
	{ path: 'bigcommerce-auth', component: AuthComponent },
	{ path: 'shopify-plans', component: EcommercePlansComponent },
	{ path: 'v3enterprise', component: V3enterpriseComponent },
	{ path: 'upgradePlans', component: UpgradePlansComponent }
	// { path: 'welcome-page', component: WelcomePageComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
