<div class="container" style="padding-bottom: env(safe-area-inset-bottom);" [ngClass]="{ expanded: menuExpanded }">
	<div class="header" [class.iphone-css]="iPhoneMobile">
		<button class="back-button" routerLink="/templates">
			<img src="assets/mobile-back-button.svg" alt="back button" />
		</button>
		<img [src]="selectedTemplate?.img" alt="" />
		<div class="about">
			<p class="title">{{ selectedTemplate?.title }}</p>
		</div>
		<div class="mode-buttons hide-mobile">
			<button [class.active]="isOutput" (click)="isHistory = false; isOutput = true">
				Outputs
			</button>
			<button [class.active]="isHistory" (click)="isOutput = false; isHistory = true">
				History
			</button>
		</div>
		<div class="erase hide-mobile" matTooltip="Erase all history" [matTooltipPosition]="'left'" *ngIf="innerWidth >= 1025 && isHistory">
			<button class="erase-btn" (click)="deleteAll()"><i class="fa-solid fa-eraser"></i></button>
		</div>
	</div>
	<div class="generator-con" [class.mg-top]="isMargin" [class.iphone-css]="iPhoneMobile">
		<div class="form">
			<div class="form-con" #scroll>
				<app-blog-generator-form
					[generatorForm]="generatorForm"
					[blogKeywords]="true"
					[resettingForm]="resettingForm"
					(validateChange)="updateValidators($event)"
					[validate]="checkValidation"
					[selectedTemplate]="selectedTemplate"
					(changeToneSource)="changeToneSource($event)"
					(advanceOptionChanged)="onCheckToggleView($event)"
					(changeSelectedLangSource)="changeLangSource($event)"
				></app-blog-generator-form>
			</div>
			<div class="form-footer {{ outputData?.length > 0 ? 'move-up' : '' }}">
				<div class="clear">
					<app-button buttonClass="gray" (onClick)="resetForm()" icon="k-icon k-i-close" iconPlace="before">
						{{
							(innerWidth > 578 && innerWidth < 900) || (innerWidth > 1200 && innerWidth < 1474 && !menuExpanded) || innerWidth > 1800
								? 'CLEAR INPUTS'
								: ''
						}}
					</app-button>
				</div>
				<div class="submit">
					<div class="drp-con" *ngIf="selectedTemplate.title != 'Full Blog'">
						<app-custom-drpdown-op1
							class="max-output-drp"
							[data]="maxOutputArray"
							[dropdownFormControl]="generatorForm?.get('numOutputs')"
							[useCustom]="false"
							[hideClearButton]="true"
							[defaultSelectedValue]="{ name: '3 Outputs', value: 3 }"
							[placeHolder]="'Output'"
						></app-custom-drpdown-op1>
					</div>
					<app-button [disabled]="isFormSubmitted" buttonClass="primary" icon="fas fa-arrow-right" (onClick)="checkValidations()">
						{{ formButtonText }}
					</app-button>
				</div>
			</div>
			<div class="create-project-button {{ isSaveAllowed ? 'show' : '' }}">
				<div class="clear">
					<span>{{ favouriteData.length }} Output{{ favouriteData.length > 1 ? 's' : '' }}</span>
				</div>
				<app-button buttonClass="primary" (onClick)="openCreateModal(true)">+ Create Project</app-button>
			</div>
		</div>

		<div class="tab-actions" *ngIf="innerWidth < 1025">
			<div class="mode-buttons">
				<button [class.active]="isOutput" (click)="isHistory = false; isOutput = true">
					Outputs
				</button>
				<button [class.active]="isHistory" (click)="isOutput = false; isHistory = true">
					History
				</button>
			</div>
			<div class="erase" matTooltip="Erase all history" [matTooltipPosition]="'left'" *ngIf="innerWidth < 1025 && isHistory">
				<button class="erase-btn" (click)="deleteAll()"><i class="fa-solid fa-eraser"></i></button>
			</div>
		</div>
		<div class="output" #output>
			<div [style.display]="isOutput ? 'block' : 'none'" class="data">
				<app-generator-output
					[hasHistory]="isHistoryFound"
					(callbackData)="handleCallback($event)"
					(isDataFetched)="enableViewMore($event)"
					(generateDataComplete)="handleGeneratedData($event)"
					[formData]="payload"
				></app-generator-output>
				<div *ngIf="isHistoryFound && !isFormSubmitted && !isDataFetched" class="div-flex out-no-data">
					<img src="assets/shakespeare/text-editor/smiley.svg" alt="" style="margin-bottom: 21px;" />
					<font04>
						I am excited to work out some new content for you…
					</font04>
					<font06>Your results will show here once Shakespeare finished working.</font06>
				</div>
			</div>
			<div [style.display]="isHistory ? 'block' : 'none'" class="data">
				<div [style.display]="isHistoryFound ? 'block' : 'none'">
					<app-generator-history
						(isDataFound)="isHistoryData($event)"
						(callbackData)="handleCallback($event)"
						[template]="selectedTemplate"
						[formData]="payload"
					></app-generator-history>
				</div>
				<div *ngIf="!isHistoryFound" class="div-flex out-no-data">
					<font04>
						No History Found
					</font04>
				</div>
			</div>
			<div class="form-footer hide-mobile {{ favouriteData.length > 0 ? 'create' : null }}" *ngIf="isSaveAllowed && !isFormSubmitted">
				<p class="fav-length">{{ favouriteData.length }} {{ favouriteData.length == 1 ? 'Output' : 'Outputs' }} Added to Favorite</p>
				<div *ngIf="isSaveAllowed" class="add-project">
					<app-button buttonClass="primary" (onClick)="openCreateModal()">+ Create Project</app-button>
					<!-- <span class="tooltiptext"></span> -->
				</div>
			</div>
		</div>
	</div>
</div>
