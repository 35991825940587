<div class="container">
	<div class="nav">
		<img class="for-desktop" src="../../../assets/logo/shakespeare-full-logo.svg" />
		<img class="for-mobile" src="../../../assets/logo/shakespeare-icon.svg" />
		<p (click)="signOut()">
			<img src="assets/icons/signout.svg" alt="" />
			Sign out
		</p>
	</div>
	<section class="pricing-header">
		<div class="section-container">
			<div id="pricing-header-wrap">
				<div id="pricing-header-text">
					<h1>
						Select your plan
						<br />
						and start your 7-day free trial
					</h1>
					<!-- <p class="p11">Try Shakespeare free for 7 days</p> -->
				</div>
			</div>
		</div>
	</section>

	<section id="pricing_btn" class="">
		<div class="section-container">
			<div class="button_gropu_tab">
				<div class="btn_group_inner">
					<button class="btn_monthly custom_button_bg" (click)="onCheckToggleViewMonthly(true)" [class.active]="isMonthlyActive">
						Pay Monthly
					</button>
					<button class="btn_yearly custom_button_bg" (click)="onCheckToggleViewYearly(false)" [class.active]="isYearlyActive">
						Pay Yearly (Save 20%)
					</button>
				</div>
			</div>
		</div>
	</section>
	<div class="offer-con">
		<!-- *standard plan card -->
		<div class="offer-box" *ngIf="true" [class.loader-css]="loading" (click)="changePlan(planEnum.standard)">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div *ngIf="standardPlans && !loading">
				<div class="standard">
					<p class="plan-name">{{ isIndiaPrice ? 'Standard' : 'Basic' }}</p>
					<p class="offer" *ngIf="offerOn">
						{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}
					</p>
				</div>
				<div class="offer-sub-title" id="standard-text">
					<p class="box-sub-title">For individuals & small businesses</p>
					<p class="box-sub-text">Everything you need to improve your marketing using Ai</p>
				</div>
				<div class="offer-value-wrap" *ngIf="!offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
						</span>
						<!-- <span class="text-6xl font-bold price_val price_val1" *ngIf="indianPlans.length">
							${{ planDuration === planDurationEnum.Monthly ? standardIndianPrice?.price : Math.round(standardIndianPrice?.price / 12) }}
						</span> -->
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<div class="offer-value-wrap" *ngIf="offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{
								planDuration === planDurationEnum.Monthly
									? Math.round(selectedPlan?.price / 2)
									: Math.round((selectedPlan?.price * (100 - 25)) / 100 / 12)
							}}
						</span>
						<!-- <span class="text-6xl font-bold price_val price_val1" *ngIf="indianPlans.length">
							${{
								planDuration === planDurationEnum.Monthly
									? Math.round(standardIndianPrice?.price / 2)
									: Math.round((standardIndianPrice?.price * (100 - 25)) / 100 / 12)
							}}
						</span> -->
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<p class="striked" *ngIf="offerOn">
					<del>${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}</del>
					<!-- <del *ngIf="indianPlans.length">
						${{ planDuration === planDurationEnum.Monthly ? standardIndianPrice?.price : Math.round(standardIndianPrice?.price / 12) }}
					</del> -->
				</p>
				<button
					type="submit"
					form="form-offer-0"
					value="Submit"
					class="cta cta-primary cta-black cta-shakespeare"
					(click)="submit('standard')"
					style="width: 100%; margin-top: 0px;"
				>
					<span>{{ isShopifyUser ? 'START NOW' : 'START FREE TRIAL' }}</span>
				</button>

				<div class="offer-slider" style="height: 99px;">
					<input
						type="range"
						id="slider"
						[style.background]="defaultStyle"
						(input)="changeBackground($event)"
						[min]="0"
						[max]="standardPlans.length - 1"
						[step]="1"
						[value]="standardStep"
						(change)="onSlide($event)"
					/>
					<p class="ad_detail ad_detail1">
						Monthly Ad Spend
						{{ getAdDetailText(planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12), 'basic') }}
					</p>
				</div>
				<div style="margin-bottom: 7.3rem;">
					<p class="desc">
						<strong style="font-weight: 700;">Ad Spend Fees</strong>
					</p>
					<ul class="percentage_mk">
						<li>5% of Monthly Ad Spend</li>
					</ul>
					<p class="desc2"><strong style="font-weight: 700;">What's included on Basic</strong></p>
					<ul class="item-lists">
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							1 Platform Either Google or Facebook
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							AI Optimization
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							AI Creative Studio
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							Basic Email Support
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- *pro plan card -->
		<div class="offer-box" [class.loader-css]="loading" (click)="changePlan(planEnum.pro)" [class.selected]="true" style="position: relative;">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div class="offer-badge">
				<p>Most Popular</p>
			</div>
			<div *ngIf="proPlans && !loading">
				<div>
					<div class="pro">
						<p class="plan-name">{{ isIndiaPrice ? 'Pro' : 'Professional' }}</p>
						<!-- <p class="popular">Most Popular</p> -->
						<p class="offer" *ngIf="offerOn">
							{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}
						</p>
					</div>
					<div class="offer-sub-title">
						<p class="box-sub-title">FOR SMALL BUSINESSES</p>
						<p class="box-sub-text">Level up your marketing with professional Ai to automate and optimize your work</p>
					</div>
				</div>
				<div class="offer-value-wrap" *ngIf="!offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
						</span>
						<!-- <span class="text-6xl font-bold price_val price_val1" *ngIf="indianPlans.length">
							${{ planDuration === planDurationEnum.Monthly ? proIndianPrice?.price : Math.round(proIndianPrice?.price / 12) }}
						</span> -->
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<div class="offer-value-wrap" *ngIf="offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{
								planDuration === planDurationEnum.Monthly
									? Math.round(selectedProPlan?.price / 2)
									: Math.round((selectedProPlan?.price * (100 - 25)) / 100 / 12)
							}}
						</span>
						<!-- <span class="text-6xl font-bold price_val price_val1" *ngIf="indianPlans.length">
							${{
								planDuration === planDurationEnum.Monthly
									? Math.round(proIndianPrice?.price / 2)
									: Math.round((proIndianPrice?.price * (100 - 25)) / 100 / 12)
							}}
						</span> -->
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<p class="striked" *ngIf="offerOn">
					<del>${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}</del>
					<!-- <del *ngIf="indianPlans.length">
						${{ planDuration === planDurationEnum.Monthly ? proIndianPrice?.price : Math.round(proIndianPrice?.price / 12) }}
					</del> -->
				</p>
				<button
					type="submit"
					form="form-offer-0"
					value="Submit"
					class="cta cta-primary cta-black cta-shakespeare"
					(click)="submit('pro')"
					style="width: 100%; margin-top: 0px;"
				>
					<span>{{ isShopifyUser ? 'START NOW' : 'START FREE TRIAL' }}</span>
				</button>
				<div class="offer-slider" style="height: 99px;">
					<input
						type="range"
						id="sliderPro"
						[style.background]="defaultProStyle"
						(input)="changeProBackground($event)"
						[min]="0"
						[max]="proPlans.length - 1"
						[step]="1"
						[value]="proStep"
						(change)="onSlidePro($event)"
					/>
					<p class="ad_detail ad_detail1">
						Monthly Ad Spend
						{{
							getAdDetailText(planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12), 'pro')
						}}
					</p>
				</div>
				<div style="margin-bottom: 2rem;">
					<p class="desc">
						<strong style="font-weight: 700;">Ad Spend Fees</strong>
					</p>
					<ul class="percentage_mk">
						<li *ngIf="isIndiaPrice">5% of Monthly Ad Spend</li>
						<li *ngIf="!isIndiaPrice">3% of Monthly Ad Spend</li>
					</ul>
					<p class="desc2"><strong style="font-weight: 700;">What's included on Professional</strong></p>
					<ul class="item-lists">
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							2 Platform : Google & Facebook
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							AI Optimization
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							AI Creative Studio
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							Priority Ticket Support
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							5 Users
						</li>
						<li>
							<em class="k-icon k-i-check k-i-checkmark"></em>
							Notifications
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- *enterprise plan card -->
		<div class="offer-box" [class.loader-css]="loading">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div class="offer-box-inner" *ngIf="!loading">
				<div class="standard">
					<p class="plan-name">Enterprise</p>
					<p class="offer" *ngIf="offerOn">
						{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}
					</p>
				</div>
				<div class="offer-sub-title">
					<p class="box-sub-title">FOR MEDIUM TO LARGE BUSINESSES</p>
					<p class="box-sub-text">Get the best of Shakespeare Ai with custom tools and dedicated support</p>
				</div>
				<div style="margin-top: 4rem;">
					<p></p>
					<p class="custmize_plan">Customised Plan</p>
					<p></p>
					<a href="https://www.shakespeare.ai/lp/enterprise-contact-us/">
						<button type="submit" form="form-offer-0" value="Submit" class="cta cta-primary cta-black cta-shakespeare">
							<span style="font-size: 16px;">Contact Us</span>
						</button>
					</a>
				</div>
			</div>
		</div>
	</div>

	<!-- !compare plans -->
	<section class="compair_plan">
		<div class="section-container">
			<div class="compair_plan_btn" (click)="onClickComparePlan()">
				<p class="compair_plan_button">Compare Plans</p>
				<img src="assets/icons/arrow-down (2).svg" alt="" [ngClass]="comparePlan ? 'close-plan' : 'open-plan'" />
			</div>
			<div class="plan_main" id="plan_main" [ngStyle]="comparePlan ? { display: 'block' } : { display: 'none' }">
				<div class="plan_inner_main">
					<div class="plan_inner no_bdr desktop">
						<fieldset class="radio-switch">
							<input type="radio" value="monthly" id="monthly" checked="" [(ngModel)]="selectedOption" (click)="onCheckToggleViewMonthly(true)" />
							<label for="monthly" class="lb_monthly">
								Monthly
							</label>

							<input type="radio" value="annually" id="annually" [(ngModel)]="selectedOption" (click)="onCheckToggleViewYearly(false)" />
							<label for="annually" class="lb_annually">
								Annually
							</label>
						</fieldset>
						<h4>Features</h4>
					</div>
					<div class="plan_inner no_bdr mobile">
						<h4>Features</h4>
					</div>
					<div class="plan_inner monthly-1" *ngIf="selectedOption === 'monthly'">
						<h3>Basic</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('standard')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner clr_perpl monthly-2" *ngIf="selectedOption === 'monthly'">
						<h3>Professional</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('pro')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner monthly-3" *ngIf="selectedOption === 'monthly'">
						<h3>Enterprise</h3>
						<p class="table-heading-value">Custom</p>
						<a href="https://www.shakespeare.ai/lp/sign-up-for-a-demo/" target="blank">Contact Us</a>
					</div>
					<div class="plan_inner annually-1" *ngIf="selectedOption === 'annually'">
						<h3>Basic</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('standard')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner clr_perpl annually-2" *ngIf="selectedOption === 'annually'">
						<h3>Professional</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('pro')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner annually-3" *ngIf="selectedOption === 'annually'">
						<h3>Enterprise</h3>
						<p>Custom</p>
						<a href="https://www.shakespeare.ai/lp/sign-up-for-a-demo/" target="blank">Contact Us</a>
					</div>
				</div>
				<div class="pricing_table">
					<table style="width: 100%;">
						<tbody>
							<tr>
								<td>Platform: Google, Facebook</td>
								<td>Any one</td>
								<td>Both</td>
								<td>Both</td>
							</tr>
							<tr>
								<td>Number of platforms</td>
								<td>1</td>
								<td>2</td>
								<td>2</td>
							</tr>
							<tr>
								<td>Number of team users</td>
								<td>1</td>
								<td>5</td>
								<td>20</td>
							</tr>
							<tr>
								<td>Slack Notifications</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Expanded content creation tools</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Priority Support</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Onboarding & Training Sessions</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Team Functionality</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>24 hr ticket support</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Dedicated Customer Sucess</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>AI Predictive Text</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Premium Technical Support</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Language Translations</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>

	<!-- <div class="info">
		<p>We will run an authorization to verify your card - this charge will be dropped after 2-3 business days</p>
	</div> -->

	<!-- !faq -->
	<div class="faq">
		<p class="title">
			Frequently Asked
			<br />
			Questions
		</p>
		<div class="box-con">
			<mat-accordion>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp1>
					<mat-expansion-panel-header [class.opened]="drp1.expanded">
						<mat-panel-title class="panel-title">
							What is Shakespeare AI and what do you offer?
						</mat-panel-title>
						<em *ngIf="!drp1.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp1.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Shakespeare AI is an AI-driven marketing tool provider. We offer a suite of AI-powered solutions to enhance your Google and Facebook
						advertising campaigns, including optimization, audience targeting, and data-driven insights.
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp2>
					<mat-expansion-panel-header [class.opened]="drp2.expanded">
						<mat-panel-title>
							How can AI improve my advertising results?
						</mat-panel-title>
						<em *ngIf="!drp2.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp2.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						AI analyzes vast amounts of data in real-time to provide insights and recommendations that humans might overlook. By leveraging AI, you
						can make data-driven decisions, optimize your ad spend, and achieve higher ROI.
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp3>
					<mat-expansion-panel-header [class.opened]="drp3.expanded">
						<mat-panel-title>
							Is Shakespeare AI suitable for small businesses and large enterprises alike?
						</mat-panel-title>
						<em *ngIf="!drp3.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp3.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Yes, our AI marketing tools are designed to be scalable and adaptable. Whether you're a small business looking to maximize your budget
						or a large enterprise aiming to improve efficiency, our solutions can be tailored to meet your specific needs.
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp4>
					<mat-expansion-panel-header [class.opened]="drp4.expanded">
						<mat-panel-title>
							Do I need technical expertise to use your tools?
						</mat-panel-title>
						<em *ngIf="!drp4.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp4.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						No, you don't need technical expertise. Our tools are user-friendly and designed for marketers of all skill levels. We provide
						comprehensive support and resources to ensure you get the most out of our platform.
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp5>
					<mat-expansion-panel-header [class.opened]="drp5.expanded">
						<mat-panel-title>
							How can I get started with a demo?
						</mat-panel-title>
						<em *ngIf="!drp5.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp5.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Getting started is easy! Simply fill out the demo request form on this page, and one of our experts will contact you to schedule a
						personalized demo. It's a great opportunity to see our tools in action and address any questions you may have.
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp5>
					<mat-expansion-panel-header [class.opened]="drp5.expanded">
						<mat-panel-title>
							Is my data safe with Shakespeare AI?
						</mat-panel-title>
						<em *ngIf="!drp5.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp5.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Absolutely. We take data security seriously. Your data is handled with the utmost care and is protected by robust security measures. We
						comply with all relevant data protection regulations to ensure your information is secure.
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp5>
					<mat-expansion-panel-header [class.opened]="drp5.expanded">
						<mat-panel-title>
							What sets Shakespeare AI apart from other marketing tool providers?
						</mat-panel-title>
						<em *ngIf="!drp5.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp5.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Shakespeare AI combines the power of AI with a team of dedicated experts. We provide not only top-tier software but also strategic
						support to help you achieve your marketing goals. Our tools are continuously updated to stay ahead of industry trends, ensuring you
						always have a competitive edge.
					</p>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</div>
<div *ngIf="globalLoader" class="global-spinner">
	<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
</div>
