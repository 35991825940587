import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { AppState } from 'src/app/state/app.state';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';

@Component({
	selector: 'app-shopify-launcher',
	templateUrl: './shopify-launcher.component.html',
	styleUrls: ['./shopify-launcher.component.scss']
})
export class ShopifyLauncherComponent implements OnInit {
	loading = false;
	private unsubscriber$ = new Subject<void>();
	url: string;

	constructor(
		private backOfficeApiService: BackOfficeService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AppState>,
		private notificationService: ToastNotificationService
	) {
		this.url = this.router.url.split('/')[3].split('?')[0];
	}

	ngOnInit(): void {
		if (this.url == 'app_verify') {
			let obj = {};
			this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
				if (params) {
					obj['hmac'] = params['hmac'];
					obj['host'] = params['host'];
					obj['shop'] = params['shop'];
					obj['timestamp'] = params['timestamp'];
					this.verifyShopify(params);
				}
			});
		} else {
			let obj = {};
			this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
				if (params) {
					obj['code'] = params['code'];
					obj['shop'] = params['shop'];
					this.callUrl(obj);
				}
			});
		}
	}

	verifyShopify(obj: object): void {
		this.loading = true;
		this.backOfficeApiService.verifyShopify(obj['hmac'], obj['host'], obj['shop'], obj['timestamp']).subscribe(
			response => {
				if (response) {
					let val = Object.keys(response)[0];
					switch (val) {
						case 'redirectUrl':
							window.open(response['redirectUrl'], '_self');
							break;
						case 'jwtToken':
							this.store.dispatch(new LogInSuccess(response['jwtToken']));
							break;
						default:
							this.notificationService.sendErrorToast('Unauthorized Access');
							break;
					}
				}
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	callUrl(params: object): void {
		this.loading = true;
		this.backOfficeApiService.shopifyAuth(params['code'], params['shop']).subscribe(
			response => {
				if (response) {
					let val = Object.keys(response)[0];
					switch (val) {
						case 'jwtToken':
							this.store.dispatch(new LogInSuccess(response['jwtToken']));
							break;
						default:
							this.notificationService.sendErrorToast('Unauthorized Access');
							break;
					}
				}
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}
}
