<div class="container">
	<div class="close-header">
		<i class="k-icon k-i-close close" (click)="back()"></i>
	</div>
	<div class="body">
		<p class="title">{{ modelDetail.title }}</p>
		<p class="sub-title" [class.spacing]="modelDetail.title == ''">{{ modelDetail.text }}</p>
		<!-- <button [class.disabled]="planStatus === 'canceled'" class="cta" [class.spacing]="modelDetail.title !== ''" (click)="navigateTo()">
			{{ modelDetail.actionBtnText }}
		</button> -->
		<app-button [class.spacing]="modelDetail.title !== ''" class="cta" (onClick)="navigateTo()">{{ modelDetail.actionBtnText }}</app-button>
	</div>
</div>
