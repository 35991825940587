import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllProductsComponent } from './all-products/all-products.component';
import { BcGeneratorComponent } from './bc-generator/bc-generator.component';
import { BigCommerceComponent } from './big-commerce.component';

const routes: Routes = [
	{ path: '', component: BigCommerceComponent },
	{ path: 'big-commerce/all-products', component: AllProductsComponent },
	{ path: 'generate', component: BcGeneratorComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BigCommerceRoutingModule {}
