export interface BillingPlanInterface {
	priceMonth: number;
	priceAnnual: number;
	totalPriceAnnual: number;
	words: number;
	plan: PlanEnum;
	id: number;
}
export enum PlanEnum {
	standard = 'Standard Version',
	pro = 'Pro Version'
}
export enum PlanDurationEnum {
	Annual = 'year',
	Monthly = 'month'
}
