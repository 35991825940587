import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-mat-input-password',
	templateUrl: './mat-input-password.component.html',
	styleUrls: ['./mat-input-password.component.scss']
})
export class MatInputPasswordComponent implements OnInit {
	@Input('class') public class: string = '';
	@Input('label') public label: string = '';
	@Input('errorMessage') public errorMessage: string = '';
	@Input('control') public control: FormControl = new FormControl();
	public showPassword: boolean = false;

	constructor() {}

	ngOnInit(): void {}
}
