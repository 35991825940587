import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPageComponent } from 'src/app/shakespeare/error-page/error-page.component';

@Injectable({
	providedIn: 'root'
})
export class ErrorPopupInterceptor implements HttpInterceptor {
	isOpen = 1;
	constructor(private matDialog: MatDialog) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				console.log('error is occur, showing error popup');
				if (this.isOpen) {
					// this.matDialog.open(ErrorPageComponent, { minWidth: '40em', data: error });
					this.isOpen = 0;
					return throwError(error);
				}

				// if (error.status == 500) {
				// 	console.log('error is occur, showing error popup');
				// 	if (this.isOpen) {
				// 		this.matDialog.open(ErrorPageComponent, { width: '40em' });
				// 		this.isOpen = 0;
				// 		return throwError(error);
				// 	}
				// }
			})
		);
	}
}
