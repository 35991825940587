<div class="wrapper">
	<div class="nav">
		<img class="for-desktop" src="../../../assets/logo/shakespeare-full-logo.svg" />
		<p (click)="signOut()">
			<img src="assets/icons/signout.svg" alt="" />
			Sign out
		</p>
	</div>
	<div class="main" *ngIf="!loading">
		<div class="page-index">
			<div class="page" [ngClass]="{ 'first-page': stepPage === 1 || stepPage > 1 }"></div>
			<div class="page" [ngClass]="{ 'first-page': stepPage > 1 }"></div>
		</div>
		<div class="page-title">
			<label class="main-heading" *ngIf="stepPage == 1">Welcome to Shakespeare</label>
			<label class="main-heading" *ngIf="stepPage == 2">Begin your Journey</label>
		</div>
		<div class="page-sub-title">
			<label class="sub-heading" *ngIf="stepPage === 1">Unlock Your Marketing Potential with AI</label>
			<label class="sub-heading" *ngIf="stepPage === 2">Let's Connect Your Ad Platform.</label>
		</div>

		<!-- !step 1 -->
		<div class="welcome-box" *ngIf="stepPage === 1">
			<div class="welcome-box-inside">
				<p class="welcome-box-title">
					Company Details
				</p>
			</div>
			<div class="welcome-drp-fst">
				<form [formGroup]="companyForm">
					<app-custom-drpdown-op1
						[data]="industryArr"
						[dropdownFormControl]="gf.industry"
						(selectionChange)="industryResult($event)"
						[useCustom]="false"
						[placeHolder]="'Select Industry'"
						[searchEnable]="true"
						[noError]="false"
					></app-custom-drpdown-op1>

					<div class="welcome-budgInp1" *ngIf="showOtherIndustry">
						<input
							enterkeyhint="done"
							class="welcome-input-tpe"
							formControlName="otherIndustry"
							type="text"
							placeholder="Enter Industry Type"
							[ngClass]="
								otherCompanyError || (companyForm.controls['otherIndustry'].dirty && companyForm.controls['otherIndustry'].invalid)
									? 'custom-input'
									: null
							"
							(change)="otherCompanyChange()"
						/>
						<div *ngIf="otherCompanyError" class="error-field">
							<span>This field is required.</span>
						</div>
						<div
							*ngIf="!otherCompanyError && companyForm.controls['otherIndustry'].dirty && companyForm.controls['otherIndustry'].invalid"
							class="error-field"
						>
							<span>Enter valid industry type.</span>
						</div>
					</div>

					<div class="welcome-budgInp1">
						<input
							enterkeyhint="done"
							class="welcome-input-tpe"
							formControlName="companyName"
							type="text"
							placeholder="Company Name"
							[ngClass]="
								companyError || (companyForm.controls['companyName'].dirty && companyForm.controls['companyName'].invalid)
									? 'custom-input'
									: null
							"
							(change)="companyInpChange()"
						/>
						<div *ngIf="companyError" class="error-field">
							<span>This field is required.</span>
						</div>
						<div
							*ngIf="!companyError && companyForm.controls['companyName'].dirty && companyForm.controls['companyName'].invalid"
							class="error-field"
						>
							<span>Enter valid company name.</span>
						</div>
					</div>

					<div class="welcome-budgInp1">
						<div class="welcome-input-tpe d-flex align-items-center" [ngClass]="companyDomainError ? 'custom-input' : null">
							<label class="info" for="domain">https://</label>
							<input class="" enterkeyhint="done" formControlName="domain" type="text" id="domain" (input)="checkDomain($event.target.value)" />
						</div>
						<!-- <input
							class="welcome-input-tpe"
							enterkeyhint="done"
							formControlName="domain"
							type="text"
							[ngClass]="companyDomainError ? 'custom-input' : null"
							placeholder="https://"
							(input)="checkDomain($event.target.value)"
						/> -->
						<!-- <div *ngIf="companyDomainError" class="error-field">
							<span>This field is required.</span>
						</div> -->
						<!-- <div *ngIf="invalidDomain" class="error-field">
							<span>Enter Valid Domain URL</span>
						</div> -->
						<div class="error-field" *ngIf="companyDomainError">
							Enter Valid Domain URL
						</div>
					</div>

					<div class="welcome-budgInp1" *ngIf="!gf.brandLogo.value">
						<input
							type="file"
							id="uploadBrand"
							#filePicker
							class="welcome-input-tpe"
							[ngClass]="logoError ? 'custom-input' : null"
							(change)="onFileChange($event)"
							placeholder="Upload Your Brand Logo (Optional)"
						/>
						<label class="upload-brand" (click)="filePicker.click()">
							<span>Upload Your Brand Logo (Optional)</span>
							<img class="upload-icon" src="../../../../../../../assets/creative-studio/Icon feather-upload.svg" alt="" srcset="" />
						</label>
					</div>
					<div *ngIf="logoError" class="error-field">
						<span>This field is required.</span>
					</div>
					<div class="welcome-budgInp1" *ngIf="gf.brandLogo.value">
						<div class="brand-section">
							<span class="upload-brand uploaded">
								<img [src]="url" alt="Uploaded Logo" width="40px" />

								<img
									class="delete-icon"
									src="../../../../../../../assets/creative-studio/delete_outline.svg"
									alt=""
									srcset=""
									(click)="onDeleteLogo()"
								/>
							</span>
						</div>
					</div>
					<!-- <div class="welcome-budgInp1">
					<div *ngIf="!loadingCanvas || !backgroundImage">
						<ng-container *ngIf="!logoSkeleton">
							<input type="file" class="welcome-input-tpe" #filePicker (change)="uploadFile($event)" />
							<button [class.edit]="isEditCo" class="addlogo" (click)="filePicker.click()">
								<mat-icon>upload</mat-icon>
								Upload Logo
							</button>
						</ng-container>
					</div>

					<input type="file" [hidden]="true" #filePicker1 (change)="uploadFile($event)" />
					<div *ngIf="showLogoContainer" class="logobox">
						<ng-container *ngIf="loadingCanvas && backgroundImage">
							<div class="imagebox" (click)="filePicker1.click()">
								<img [src]="backgroundImage" />
							</div>
							<div class="modify">
								<div class="replace">
									<a (click)="filePicker1.click()">
										<mat-icon>refresh</mat-icon>
									</a>
								</div>
								<!-- <div class="remove">
									<a class="remove" (click)="backgroundImage = ''">
										<mat-icon>close</mat-icon>
									</a>
								</div> 
							</div>
						</ng-container>
						<ng-container *ngIf="logoSkeleton">
							<ngx-skeleton-loader
								appearance="line"
								style="height: 77px; width: 153px; margin-left: 0.4rem;"
								[theme]="{ width: '100%', height: '100%' }"
							></ngx-skeleton-loader>
						</ng-container>
					</div>
					<div *ngIf="logoError" class="error-field">
						<span>This field is required.</span>
					</div>
				</div> -->
				</form>
				<div class="welcome-next-button">
					<button
						class="welcome-appy-cls"
						[ngStyle]="companyForm.valid ? { opacity: '1', cursor: 'pointer' } : { opacity: '0.5' }"
						(click)="saveBranding()"
						[disabled]="companyForm.invalid"
					>
						Next
					</button>
				</div>
			</div>
		</div>

		<!-- !step 2 -->
		<div class="connect-box" *ngIf="stepPage === 2">
			<div class="connect-box-inside">
				<!-- <img class="img-sty" [src]="'assets/shakespeare/logo.svg'" alt="" /> -->
				<!-- <div class="skip-block" (click)="navigateToOverview()">
					<a href="/">Skip</a>

				</div> -->
			</div>
			<div class="flex-connect">
				<div class="ai-opt-connect">
					<div class="ai-opt-next-button">
						<img src="assets/googleAd.svg" class="google-img" alt="" height="110px" width="110px" />
						<button class="ai-opt-appy-cls" (click)="openGooglePopup()">
							<div class="wrapper" style="margin-right: 15px;">
								<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/google-nav.svg" alt="google logo" />
								<span>Connect Google Ads</span>
							</div>
						</button>
					</div>
				</div>
				<div class="mid-line">
					<div class="border-line"></div>
					<div class="mid-text"></div>
					<div class="border-line"></div>
				</div>
				<div class="ai-opt-connect">
					<div class="ai-opt-next-button" (click)="openFacebookPopup()">
						<img src="assets/meta.svg" alt="" width="110px" height="110px" />
						<button class="ai-opt-appy-cls">
							<div class="wrapper">
								<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/facebook-nav.svg" alt="facebook logo" />
								<span>Connect Facebook Ads</span>
							</div>
						</button>
					</div>
					<!-- <div class="page-title">
						<label class="connect-heading">AI Creative</label>
					</div>
					<div class="page-sub-title">
						<label class="connect-sub-heading">Elevate your creativity with AI-driven solutions.</label>
					</div>
					<div class="ai-creative-button" (click)="openCreativePopup()">
						<button class="ai-creative-appy-cls">
							<span>Notify Me</span>
						</button>
					</div> -->
				</div>
			</div>
		</div>
	</div>

	<div [class.hide]="!loading" class="spinner-wrapper" *ngIf="loading">
		<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
	</div>
</div>

<app-toast-notification></app-toast-notification>
