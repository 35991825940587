import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
	selector: 'app-mat-input-chip',
	templateUrl: './mat-input-chip.component.html',
	styleUrls: ['./mat-input-chip.component.scss']
})
export class MatInputChipComponent implements OnInit {
	@Input() placeholder: string;
	@Input() selectedKeywords: any;
	@Input() tags: any;

	public keywords: string[] = [];
	// public selectedKeywords: any;

	// config for chips input
	public visible: boolean = true;
	public selectable: boolean = false;
	public removable: boolean = true;
	public addOnBlur: boolean = false;
	public separatorKeysCodes: any[] = [ENTER, COMMA, TAB];

	constructor() {}

	ngOnInit(): void {
		this.keywords = this.tags;
	}

	public add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add
		if ((value || '').trim()) {
			this.keywords.push(value.trim());
		}
		// Reset the input value
		if (input) {
			input.value = '';
		}
		this.selectedKeywords = null;
	}

	public remove(keyword: any): void {
		const index = this.keywords.indexOf(keyword);

		if (index >= 0) {
			this.keywords.splice(index, 1);
		}
	}

	public onCustomFocus(): void {}
}
