import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
// import { AccountTypeService } from 'src/app/shared/account-type.service';
import { IKBSearchResult, IKBSearchResultData } from 'src/app/shared/models/knowledgebase.interface';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-knowledge-view',
	templateUrl: './knowledge-view.component.html',
	styleUrls: ['./knowledge-view.component.scss']
})
export class KnowledgeViewComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

	public kbArticles: IKBSearchResult[];
	public loading = false;

	constructor(
		private matDialog: MatDialog,
		public router: Router,
		public kbService: KnowledgebaseService,
		private toastNotificationService: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.kbService
			.searchKnowledgebase(this.kbService.searchValue$.value)
			.pipe(
				take(1),
				catchError(error => {
					this.toastNotificationService.sendErrorToast('There was an error loading the knowledge base articles, please contact support.');
					this.loading = false;
					return EMPTY;
				})
			)
			.subscribe(articles => {
				console.log(articles);
				this.kbArticles = articles;
				this.loading = false;
			});
	}

	openUrl(url): void {
		window.location.href = url;
	}

	public showSubView(articleData: IKBSearchResultData[], index = 0): void {
		this.kbService.knowledgebaseShowView$.next(true); // was false
		this.kbService.knowledgebaseShowSub$.next(false);
		this.kbService.articleDataValue$.next(articleData);
		window.open(articleData['url'], '_black');
		this.kbService.knowledgebaseShowFrame$.next(false); // was true
		this.kbService.frameIndexValue$.next(index);
	}
}
