import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { ShopifyService } from '../shopify.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ShopifyModalComponent } from '../shopify-modal/shopify-modal.component';
import saveAs from 'file-saver';
import { ShakespeareService } from '../../shakespeare.service';

@Component({
	selector: 'app-all-products',
	templateUrl: './all-products.component.html',
	styleUrls: ['./all-products.component.scss']
})
export class AllProductsComponent implements OnInit {
	@ViewChild('fileRef') fileRef: ElementRef;
	menuExpanded: boolean;
	private unsubscriber$ = new Subject<void>();
	filedId: number;
	allProducts: any;
	backup: any[];
	loading = false;

	controlA = new FormControl();
	controlB = new FormControl();
	controlC = new FormControl();
	tags: any[];
	types: any[];
	vendor: any[];
	collectionId = null;
	productType = null;
	productVendor = null;
	history = false;
	private readonly searchSubject = new Subject<string | undefined>();
	isExpanded: boolean;
	selectedItems: any[];
	optimized: boolean;
	unoptimized: boolean;
	newlyGenerated: boolean;
	scrWidth: number;
	bulkLoading: boolean;
	pageTitle = 'All Products';
	newlyBackup: any[];

	constructor(
		private authenticationService: AuthenticationService,
		private shopifyService: ShopifyService,
		private userStore: Store<UserState>,
		private toastNotificationService: ToastNotificationService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private shakespeareService: ShakespeareService
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(id => {
			this.filedId = id;
		});
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.scrWidth = window.innerWidth;
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
		this.getFilterValues();
		this.getActivatedRoute();
		this.searchSubject
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				switchMap(async searchQuery => this.searchApiCall(searchQuery))
			)
			.subscribe(res => {
				console.log('ss');
			});
	}

	getFlags(): void {
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {}
		};
		this.shopifyService
			.bulkFlagRetrive(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.loading = response['flags'].generatedFlag;
					this.shopifyService.canGenerate$.next(!this.loading);
					if (!this.loading) {
						this.getNewlyGenerated();
						this.resetFlag();
					} else {
						setTimeout(() => {
							this.getFlags();
						}, 20000);
					}
				},
				() => {
					this.loading = false;
				}
			);
	}

	resetFlag(): void {
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {}
		};
		this.shopifyService
			.bulkFlagReset(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {},
				() => {}
			);
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['type'] == 'history') {
				this.history = true;
				this.pageTitle = 'History';
			}
			if (params['isExpanded']) {
				this.isExpanded = true;
				this.pageTitle = 'History';
			}
			if (params['type'] == 'optimized') {
				this.optimized = true;
				this.pageTitle = 'Optimized Products';
			}
			if (params['type'] == 'unoptimized') {
				this.unoptimized = true;
				this.pageTitle = 'Unoptimized Products';
			}
			if (params['type'] == 'newly-generated') {
				this.newlyGenerated = true;
				this.pageTitle = 'Newly Generated';
			}
		});
		if (this.history) {
			this.getProductsHistory();
		} else if (this.newlyGenerated) {
			this.getFlags();
		} else {
			this.getAllProducts();
		}
	}

	getProductsHistory(page = 1): void {
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				page_number: page
			}
		};
		this.shopifyService
			.getProductsHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.loading = false;
					console.log(response);
					this.allProducts = response['data'].history;
					this.allProducts.forEach(item => {
						item['description'] = item.mainDescription;
					});
					this.backup = [...this.allProducts];
				},
				() => {
					this.loading = false;
				},
				() => {
					this.loading = false;
				}
			);
	}

	getNewlyGenerated(page = 1): void {
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: page
			}
		};
		this.shopifyService
			.getNewlyGenerated(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.allProducts = response['data'].generatedData;
					this.allProducts.forEach(item => {
						item['description'] = item['generatedData'].description;
					});
					this.backup = [...this.allProducts];
					this.loading = false;
					this.bulkLoading = false;
				},
				() => {
					this.loading = false;
				},
				() => {
					this.loading = false;
				}
			);
	}

	toEditor(): void {
		this.router.navigate(['shopify/generate']);
	}

	sortProducts(type: string): void {
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: 1,
				order: type,
				sort: 'created_at'
			}
		};

		if (type == 'relevance') {
			delete payload.data.order;
			delete payload.data.sort;
		}
		this.shopifyService
			.getSortedProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.allProducts = response['data'];
					if (this.optimized) {
						this.allProducts = this.allProducts.filter(item => {
							return item.optimizationScore > 0;
						});
					}
					if (this.unoptimized) {
						this.allProducts = this.allProducts.filter(item => {
							return item.optimizationScore == 0;
						});
					}
					this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	getFilterValues(): void {
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {}
		};
		this.shopifyService
			.getFilterValues(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.tags = this.mapFilterValues(response['data'].collection);
					this.types = this.mapArrayValues(response['data'].productTypes);
					this.vendor = this.mapArrayValues(response['data'].productVendors);
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	bulkDownload(): void {
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {}
		};
		this.shopifyService
			.downloadBulk(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.downloadFile(response['data']);
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	downloadFile(data: any) {
		const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
		const header = Object.keys(data[0]);
		let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
		csv.unshift(header.join(','));
		let csvArray = csv.join('\r\n');

		var blob = new Blob([csvArray], { type: 'text/csv' });
		saveAs(blob, 'shakespeare.csv');
	}

	uploadFile(event: Event): void {
		const element = event.currentTarget as HTMLInputElement;
		let fileList: FileList | null = element.files;
		if (fileList && fileList[0].type == 'text/csv') {
			this.bulkUpload(fileList[0]);
		}
	}

	triggerFileUpload(): void {
		this.fileRef.nativeElement.click();
	}

	bulkUpload(file: File): void {
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {}
		};
		let formData = new FormData();
		formData.append('data', JSON.stringify(payload));
		formData.append('file', file);
		// payload.append('user', )
		let ids = [];
		this.selectedItems?.forEach(item => {
			ids.push(item?.id);
		});
		this.shopifyService
			.uploadBulk(formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['data'] == 'file uploaded successfully') {
						this.toastNotificationService.sendSuccessToast('File uploaded!');
						this.getNewlyGenerated();
					} else {
						this.toastNotificationService.sendErrorToast('Format not supported');
					}
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	handleTags(item: object): void {
		console.log(item);
	}

	mapFilterValues(arr: any[]): any[] {
		let newArr = arr.map(item => {
			return { name: item.title, ...item };
		});
		return newArr;
	}

	mapArrayValues(arr: any[]): any[] {
		let newArr = arr.map(item => {
			return { name: item };
		});
		return newArr;
	}

	handlePagination(page: number): void {
		this.getAllProducts(page);
	}

	goBack(): void {
		this.router.navigate(['shopify']);
	}

	getAllProducts(page = 1): void {
		if (this.history) {
			this.getProductsHistory(page);
			return;
		}
		if (this.newlyGenerated) {
			this.getNewlyGenerated(page);
			return;
		}
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: page
			}
		};
		this.shopifyService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.allProducts = response['data'];
					this.allProducts.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.backup = [...this.allProducts];
					this.loading = false;
					if (this.optimized) {
						this.allProducts = this.allProducts.filter(item => {
							return item.optimizationScore > 0;
						});
					}
					if (this.unoptimized) {
						this.allProducts = this.allProducts.filter(item => {
							return item.optimizationScore == 0;
						});
					}
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	confirmBulkProcess(payloadData: any[]): void {
		this.selectedItems = payloadData;
		let allowed = {
			title: 'Are you sure?',
			desc: `This process will take 15 to 20 minutes and ${this.selectedItems.length * 70} credits.<br>Do you want to continue?`,
			isCancel: true,
			img: 'assets/icons/big-i.svg',
			cta: 'Continue'
		};
		let notAllowed = {
			title: 'Please wait',
			desc: 'Shakespeare is generating content for you wait till previous task is done',
			isCancel: false,
			img: 'assets/icons/big-i.svg',
			cta: 'Okay'
		};
		let isAllowed = this.shopifyService.canGenerate$.value;
		let modalConfig;
		let isMobile = this.scrWidth < 780 ? true : false;
		if (isMobile) {
			modalConfig = {
				width: '90%',
				height: '328px',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '436px',
				height: '328px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ShopifyModalComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			disableClose: true,
			data: isAllowed ? allowed : notAllowed
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.confirm && isAllowed) {
				this.generateBulk();
			}
		});
	}

	generateBulk(): void {
		this.loading = true;
		this.bulkLoading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: this.selectedItems
		};
		this.shopifyService
			.generateBulk(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.router.navigate(['shopify/all-products'], { queryParams: { type: 'newly-generated' } });
					if (response['debugMessage'] == 'Not enough credit') {
						this.toastNotificationService.sendInfoToast("You don't have enough credit");
					} else {
						this.toastNotificationService.sendSuccessToast('Process started...');
					}
					this.getFlags();
					this.bulkLoading = true;
				},
				() => {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
	}

	takeTour(): void {
		this.loading = false;
		this.bulkLoading = false;
		this.router.navigate(['shopify']);
	}

	clearFilters(): void {
		this.controlA.setValue([]);
		this.controlB.setValue([]);
		this.controlC.setValue([]);
		this.collectionId = null;
		this.productType = null;
		this.productVendor = null;
		this.getAllProducts();
	}

	getFilteredProducts(type: string, value: string, page = 1): void {
		if (value == undefined) {
			value = '_cleared_';
		}
		switch (type) {
			case 'product_type':
				this.productType = value['name'];
				break;
			case 'product_vendor':
				this.productVendor = value['name'];
				break;
			case 'collection_id':
				this.collectionId = value['name'];
				break;
		}
		this.loading = true;
		console.log(value);
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: page
			}
		};
		this.productType !== '_cleared_' && (payload.data['product_type'] = this.productType);
		this.productVendor !== '_cleared_' && (payload.data['product_vendor'] = this.productVendor);
		this.collectionId !== '_cleared_' && (payload.data['collection_id'] = this.collectionId);

		this.shopifyService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.allProducts = response['data'];
					this.allProducts.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.backup = [...this.allProducts];
					if (this.optimized) {
						this.allProducts = this.allProducts.filter(item => {
							return item.optimizationScore > 0;
						});
					}
					if (this.unoptimized) {
						this.allProducts = this.allProducts.filter(item => {
							return item.optimizationScore == 0;
						});
					}
					this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	public debounce(callback, delay: number = 1000) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				// callback(...args);
			}, delay);
		};
	}

	searchTemplate(searchQuery: string): void {
		if (this.pageTitle == 'All Products') {
			this.searchSubject.next(searchQuery?.trim());
			return;
		}
		if (this.newlyGenerated) {
			this.allProducts = [...this.backup];
			this.allProducts = this.allProducts.filter(item => {
				return (
					item.generatedData.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item.generatedData.description.toLowerCase().includes(searchQuery.toLowerCase())
				);
			});
		} else if (this.history) {
			this.allProducts = [...this.backup];
			this.allProducts = this.allProducts.filter(item => {
				return item.title.toLowerCase().includes(searchQuery.toLowerCase()) || item.mainDescription.toLowerCase().includes(searchQuery.toLowerCase());
			});
		} else {
			this.allProducts = [...this.backup];
			this.allProducts = this.allProducts.filter(item => {
				return item.title.toLowerCase().includes(searchQuery.toLowerCase());
			});
		}
	}

	searchApiCall(value: string): void {
		if (value == '') {
			this.allProducts = [...this.backup];
			return;
		}
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 1,
				search: value
			}
		};
		this.shopifyService
			.seacrchProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.allProducts = response['data'];
					this.allProducts.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}
}
