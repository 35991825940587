import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { DexterWelcomeComponent } from './dexter-welcome/dexter-welcome.component';
import { EmailRegisterComponent } from './email-register/email-register.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
// import { LandingPageComponent } from './landing-page/landing-page.component';
import { PasswordComponent } from './password/password.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { PaymentConfirmation } from './payment-confirmation/payment-confirmation.component';
// import { PersonalDetailComponent } from './personal-detail/personal-detail.component';
import { PersonalWelcomeComponent } from './personal-welcome/personal-welcome.component';
// import { LunchComponents } from './lunch/lunch.component';
import { AuthenticationRouteGuard } from 'src/app/shared/guards/authentication-route.guard';
import { ResetPasswordWithEmailComponent } from '../reset-password-with-email/reset-password-with-email.component';
import { SigninComponent } from '../signin/signin.component';
import { V3enterpriseComponent } from '../v3enterprise/v3enterprise.component';
// import { NewSignupWelcomePopupComponent } from './new-signup-welcome-popup/new-signup-welcome-popup.component';

const routes: Routes = [
	{ path: '', component: SigninComponent },
	{ path: 'reset-password-with-email', component: ResetPasswordWithEmailComponent, canActivate: [AuthenticationRouteGuard] },
	// { path: 'sign-up', component: LandingPageComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-email', component: EmailRegisterComponent, canActivate: [AuthenticationRouteGuard] },
	// { path: 'personal-detail', component: PersonalDetailComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'verify-email', component: EmailVerificationComponent, canActivate: [AuthenticationRouteGuard] },
	// { path: 'dexter-welcome', component: DexterWelcomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'register-password', component: PasswordComponent, canActivate: [AuthenticationRouteGuard] },
	// { path: 'set-first-password', component: DexterWelcomeComponent },
	{ path: 'personal-welcome', component: PersonalWelcomeComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'payment', component: PaymentPageComponent, canActivate: [AuthenticationRouteGuard] },
	{ path: 'payment/success', component: PaymentConfirmation }
	// { path: 'launch', component: LunchComponents, canActivate: [AuthenticationRouteGuard] }
	// { path: 'v3enterprise', component: V3enterpriseComponent, canActivate: [AuthenticationRouteGuard] }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NewSignupRoutingModule {}
