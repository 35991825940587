<!-- <div class="facebook-auth-container" *ngIf="clickedPlatform === 'facebook' || platformDefault === 'facebook'"> -->
<div class="facebook-auth-container" *ngIf="platformDefault === 'facebook' || platformDefault === 'fullAccess'">
	<div class="stepper" *ngIf="activeStep == 1 && !connecting">
		<div class="step step-1" [ngClass]="{ active: activeStep == 1 || activeStep == 2 }"></div>
		<div class="step step-2" [ngClass]="{ active: activeStep == 2 }"></div>
		<div class="step step-2" [ngClass]="{ active: activeStep == 3 }"></div>
	</div>

	<!-- <shakespeare-spinning-loader style="height: 100%;" *ngIf="connecting"></shakespeare-spinning-loader> -->
	<!-- !step 1 -->
	<div *ngIf="activeStep == 1 && !connecting" class="step1-content">
		<div class="header" *ngIf="showButton">
			<p class="title">Link Facebook Account</p>
			<p class="sub-title">Connect your Facebook Ad account to improve ad campaign performance</p>
		</div>
		<div class="header" *ngIf="!showButton">
			<p class="sub-title">In order to use this function please speak to your account manager to upgrade your plan.</p>
		</div>
		<div class="connect-card-holder" *ngIf="showButton">
			<div class="connect-card auto">
				<!-- <p class="title">Connect with Google</p>
				<p class="sub-title">Link your email and in the following step, sync the relevant Google Ads account</p> -->
				<div class="cta" (click)="connectFacebook()">
					<img src="assets/icons/facebook-icon2.svg" alt="" />
					Link with Facebook
				</div>
				<!-- <div *ngIf="isLoggedIn" class="cta" (click)="fbLogout()">
					<img src="assets/icons/facebook-icon.svg" alt="" />
					Logout with Facebook
				</div> -->

				<!-- <app-button (onClick)="goToDashboard()" [width]="100" buttonClass="primary">Go To Dashboard</app-button> -->
			</div>
			<!-- <span>or</span> -->
			<!-- <div class="connect-card manual">
				<p class="title">Link manually</p>
				<p class="sub-title">Submit your Google Ads account or MCC and access request to get connected</p>
				<div class="cta">
					<input
						type="text"
						(keyup)="addHyphen()"
						(keyup.enter)="onManualConnect()"
						[ngClass]="{ error: inputError || (googleInput.invalid && googleInput.touched) }"
						[formControl]="googleInput"
						placeholder="123-456-7890"
					/>
					<app-button (onClick)="onManualConnect()" buttonClass="primary">Connect Google Ads Account</app-button>
				</div>
			</div> -->
		</div>
	</div>

	<!-- !step 2 -->
	<div *ngIf="activeStep == 2" class="step2-content">
		<!-- <span *ngIf="!buttonClicks" style="font-weight: bold;">Congratulations! Your Facebook Ad Account Connected Successfully.</span>
		<br />

		<app-button *ngIf="!buttonClicks" (onClick)="buttonclick()" [width]="100" buttonClass="primary">
			<span *ngIf="!setloading">
				Select Ad Accounts
			</span>
			<span *ngIf="setloading">
				Loading....
			</span>
		</app-button> -->

		<!-- <div *ngIf="buttonClicks"> -->
		<!-- <div class="header">
			<p class="title">Select Ad Accounts</p>
		</div>
		<div class="content">
			<div class="search-box">
				<app-search-input (input)="onSearch($event.target.value)" inputPlaceholder="Search Ad Accounts..."></app-search-input>
			</div>
			<div class="list-con">
				
				<ngx-skeleton-loader
					count="2"
					appearance="line"
					[theme]="{ width: '100%', height: '112px' }"
					*ngIf="adLoading"
					style="width: '100%'; height: '112px';"
				></ngx-skeleton-loader>

				<ng-container *ngIf="!adLoading">
					<div class="list-item" *ngFor="let item of fbCustomerArray">
						<input [id]="item.id" class="checkbox" type="checkbox" [checked]="item.selected" (click)="checkCheckboxs(item.id)" />
						<div class="details">
							<p class="name">{{ item.name }}</p>

							<p class="id">{{ item.id }}</p>
						</div>
						<img class="fb-icon" src="assets/icons/facebook-icon.svg" alt="" />
					</div>
				</ng-container>
			</div>
			<app-button [disabled]="selectedcount < 1" (onClick)="navigateToNextPage()" [width]="100" buttonClass="primary">Connect</app-button>
		</div> -->
		<!-- </div> -->
	</div>
</div>

<!-- !not connected -->
<div
	class="connect-container"
	*ngIf="((platformDefault === 'fullAccess' && clickedPlatform === 'google') || (platformDefault === 'fullAccess' && !clickedPlatform)) && !loadingPlatform"
>
	<div class="connect-video-div">
		<video src="assets/facebookbounce-1.mp4" type="video/mp4" width="502" height="230" loop autoplay></video>
	</div>
	<div class="connect-span-1">
		<span>You haven’t connected Facebook yet</span>
	</div>
	<div class="connect-span-2">
		<span>With Shakespeare AI you can easily start improving your Facebook Ads performance.</span>
	</div>
	<div class="connect-button-div">
		<button class="connect-button" (click)="connectFacebook()">
			<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/facebook-nav.svg" alt="facebook logo" />
			<span>Connect Facebook</span>
		</button>
	</div>
</div>

<!-- !upgrade plan -->
<div class="connect-container" *ngIf="platformDefault !== 'facebook' && platformDefault !== 'fullAccess' && !loadingPlatform">
	<div class="connect-video-div">
		<video src="assets/facebookbounce-1.mp4" type="video/mp4" width="502" height="230" loop autoplay></video>
	</div>
	<div class="connect-span-1">
		<span>Upgrade to activate Facebook.</span>
	</div>
	<div class="connect-span-2">
		<span>To connect Facebook Ads and start improving performance upgrade to the Pro Plan</span>
	</div>
	<div class="connect-button-div">
		<button class="connect-button" (click)="upgradePlan()">
			<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/facebook-nav.svg" alt="facebook logo" />
			<span>Upgrade Plan</span>
		</button>
	</div>
</div>

<div class="loader-container">
	<shakespeare-spinning-loader *ngIf="loadingPlatform"></shakespeare-spinning-loader>
</div>
