import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-table-tabs-items',
	templateUrl: './table-tabs-items.component.html',
	styleUrls: ['./table-tabs-items.component.scss']
})
export class TableTabsItemsComponent implements OnInit {
	@Input() tabs: any[];
	@Output() onClick: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	public selectTab(selected: any): void {
		this.onClick.emit(selected);
	}
}
