<div class="container">
	<div class="nav">
		<img class="for-desktop" src="../../../assets/logo/shakespeare-full-logo.svg" />
		<img class="for-mobile" src="../../../assets/logo/shakespeare-icon.svg" />
		<p (click)="signOut()">
			<img src="assets/icons/signout.svg" alt="" />
			Sign out
		</p>
	</div>
	<div class="switch-con">
		<div class="custom-switch">
			<label class="{{ planDuration === planDurationEnum.Monthly ? 'active' : '' }}">Monthly</label>
			<app-toggle-switch [value]="planDuration !== planDurationEnum.Monthly" (toggle)="onCheckToggleView($event)"></app-toggle-switch>
			<label class="{{ planDuration === planDurationEnum.Annual ? 'active' : '' }}">Annually</label>
		</div>
		<p [class.annual]="planDuration === planDurationEnum.Annual" class="save">Save 20% with annual plans</p>
	</div>
	<div class="offer-con">
		<div class="offer-box one" *ngIf="true" [class.loader-css]="loading">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div *ngIf="standardPlans && !loading">
				<div class="standard">
					<p class="plan-name2">Free</p>
					<p class="offer" *ngIf="offerOn">{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}</p>
				</div>
				<p class="price" *ngIf="!offerOn" [class.offer-on]="offerOn">
					$0
					<span class="duration">per month</span>
				</p>
				<app-button width="100" style="width: 100%;" (onClick)="getShopifyFree()" [buttonClass]="'secondary'">
					GET STARTED
				</app-button>
				<p class="desc">Quickest and easiest way to try our product.</p>
				<ul class="item-lists">
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						2,000 Free Words
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Access to Shakespeare's Vast Template Library
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						World-Class Customer Service
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Access to Shopify Dashboard
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Generate Descriptions in Bulk
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						CSV Upload
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Multi-language Support
					</li>
				</ul>
			</div>
		</div>
		<div class="offer-box two selected" *ngIf="true" [class.loader-css]="loading">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div class="most-popular">
				<p>Most Popular</p>
			</div>
			<div *ngIf="shopifyUnlimitedPlans && !loading">
				<div class="standard">
					<p class="plan-name2">Shopify Unlimited</p>
					<p class="offer" *ngIf="offerOn">{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}</p>
				</div>
				<p class="price" *ngIf="!offerOn" [class.offer-on]="offerOn">
					${{ planDuration === planDurationEnum.Monthly ? selectedShopifyPlan?.price : Math.round(selectedShopifyPlan?.price / 12) }}
					<span class="duration">per month</span>
				</p>
				<app-button width="100" style="width: 100%;" (onClick)="buyShopifyUnlimited()" [buttonClass]="'primary'">
					START NOW
				</app-button>
				<p class="desc">All the goodies you need for your Shopify store</p>
				<ul class="item-lists">
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Shopify Access Only
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						No word limit for Shopify
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Access to Shopify dashboard
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Generate Descriptions in Bulk
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						CSV Upload
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						CSV Upload
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						World-Class Customer Service
					</li>
				</ul>
			</div>
		</div>
		<div class="offer-box" *ngIf="true" [class.loader-css]="loading" (click)="changePlan(planEnum.standard)">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div *ngIf="standardPlans && !loading">
				<div class="standard">
					<p class="plan-name">Shakespeare Standard</p>
					<p class="plan-name2">+ Shopify Unlimited</p>
					<p class="offer" *ngIf="offerOn">{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}</p>
				</div>
				<p class="price" *ngIf="!offerOn" [class.offer-on]="offerOn">
					${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
					<span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month' }}</span>
				</p>
				<p class="price" *ngIf="offerOn" [class.offer-on]="offerOn">
					${{
						planDuration === planDurationEnum.Monthly
							? Math.round(selectedPlan?.price / 2)
							: Math.round((selectedPlan?.price * (100 - 25)) / 100 / 12)
					}}
					<span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month' }}</span>
				</p>
				<p class="striked" *ngIf="offerOn">
					<del>${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}</del>
				</p>
				<div class="slider-label">
					<p>{{ kFormatter(standardPlans[0]?.credit) || '' }}</p>
					<p>{{ kFormatter(standardPlans[standardPlans.length - 1]?.credit) || '' }}+</p>
				</div>
				<input
					type="range"
					id="slider"
					[style.background]="defaultStyle"
					(input)="changeBackground($event)"
					[min]="0"
					[max]="standardPlans.length - 1"
					[step]="1"
					[value]="standardStep"
					(change)="onSlide($event)"
				/>
				<!-- <p class="slider-value">{{ sliderValue || '0K' }} Words</p> -->
				<app-button width="100" style="width: 100%;" (onClick)="submit('standard')" [buttonClass]="'secondary'">
					START NOW
				</app-button>
				<p class="desc">All the same goodies as our free and Shopify versions, plus...</p>
				<ul class="item-lists">
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						40+ Copywriting Templates
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Target User Personas
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Add More Words Anytime
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Write in 100+ Languages
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Team Functionality
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Priority Support
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						No word limit for Shopify
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Access to Shopify dashboard
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Generate Descriptions in Bulk
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						CSV Upload
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Multi-Language Support
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						World-Class Customer Service
					</li>
				</ul>
			</div>
		</div>
		<div class="offer-box" [class.loader-css]="loading" (click)="changePlan(planEnum.pro)">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div *ngIf="proPlans && !loading">
				<div class="pro">
					<p class="plan-name">Shakespeare Pro</p>
					<p class="plan-name2">+ Shopify Unlimited</p>
					<p class="offer" *ngIf="offerOn">{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}</p>
				</div>
				<p class="price" *ngIf="!offerOn" [class.offer-on]="offerOn">
					${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
					<span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month' }}</span>
				</p>
				<p class="price" *ngIf="offerOn" [class.offer-on]="offerOn">
					${{
						planDuration === planDurationEnum.Monthly
							? Math.round(selectedProPlan?.price / 2)
							: Math.round((selectedProPlan?.price * (100 - 25)) / 100 / 12)
					}}
					<span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month' }}</span>
				</p>
				<p class="striked" *ngIf="offerOn">
					<del>${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}</del>
				</p>
				<div class="slider-label">
					<p>{{ kFormatter(proPlans[0]?.credit) || '' }}</p>
					<p>{{ kFormatter(proPlans[proPlans.length - 1]?.credit) || '' }}+</p>
				</div>
				<input
					type="range"
					id="sliderPro"
					[style.background]="defaultProStyle"
					(input)="changeProBackground($event)"
					[min]="0"
					[max]="proPlans.length - 1"
					[step]="1"
					[value]="proStep"
					(change)="onSlidePro($event)"
				/>
				<app-button width="100" style="width: 100%;" (onClick)="submit('pro')" [buttonClass]="'secondary'">
					START NOW
				</app-button>
				<p class="desc">All the same goodies as our Standard Version, plus…</p>
				<ul class="item-lists">
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Access to Shakespeare's Blog Wizard
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						AI Predictive Text
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Expanded Content Creation Tools
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Up to 800K Words Per Month
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Onboarding & Training Sessions
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Premium Technical Support
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						No word limit for Shopify
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Access to Shopify dashboard
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Generate Descriptions in Bulk
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						CSV Upload
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						Multi-Language Support
					</li>
					<li>
						<em class="k-icon k-i-check k-i-checkmark"></em>
						World-Class Customer Service
					</li>
				</ul>
			</div>
		</div>
	</div>
	<p class="compare-toggler" (click)="compareToggle()" *ngIf="!loading">
		Compare Plans
		<i class="fas {{ compare ? 'fa-angle-up' : 'fa-angle-down' }}"></i>
	</p>
	<div class="custom-switch" class="for-mobile" *ngIf="compare">
		<label class="{{ planDuration === planDurationEnum.Monthly ? 'active' : '' }}">Monthly</label>
		<app-toggle-switch [value]="planDuration !== planDurationEnum.Monthly" (toggle)="onCheckToggleView($event)"></app-toggle-switch>
		<label class="{{ planDuration === planDurationEnum.Annual ? 'active' : '' }}">Annually</label>
	</div>
	<div class="table-holder" *ngIf="compare">
		<table>
			<thead>
				<tr>
					<th>
						<div class="left-con">
							<div class="custom-switch hide-mobile">
								<label class="{{ planDuration === planDurationEnum.Monthly ? 'active' : '' }}">Monthly</label>
								<app-toggle-switch [value]="planDuration !== planDurationEnum.Monthly" (toggle)="onCheckToggleView($event)"></app-toggle-switch>
								<label class="{{ planDuration === planDurationEnum.Annual ? 'active' : '' }}">Annually</label>
							</div>
							<p class="features">Features</p>
						</div>
					</th>
					<th>
						<div class="plan-card">
							<p class="text1">Shopify Unlimited</p>
							<p class="text2">
								${{ planDuration === planDurationEnum.Monthly ? selectedShopifyPlan?.price : Math.round(selectedShopifyPlan?.price / 12) }}
								<span>/mo</span>
							</p>
							<app-button buttonClass="secondary">START NOW</app-button>
						</div>
					</th>
					<th>
						<div class="plan-card">
							<p class="text1">Standard</p>
							<p class="text2">
								${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
								<span>/mo</span>
							</p>
							<app-button buttonClass="secondary">START NOW</app-button>
						</div>
					</th>
					<th>
						<div class="plan-card">
							<p class="text1">Shopify Unlimited & Pro</p>
							<p class="text2">
								${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
								<span>/mo</span>
							</p>
							<app-button buttonClass="secondary">START NOW</app-button>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="feature b-radius-top-left">
						<div class="table-head">
							<p>Shopify Integration</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td class="b-radius-top-right"><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Unlimited Shopify Product Description</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Generate Bulk Description</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Multi-Language Support</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>CSV Download/Upload</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Priority Support</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Exclusive Templates</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>5000 Content-Creation Credits Every Month</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Expanded Content Creation</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Add More Credits Anytime</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Bring the Whole Team</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Priority Support</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>40+ Copywriting Tools and Templates</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Blog Wizard</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Personas</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>AI- Assisted Writing</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature">
						<div class="table-head">
							<p>Write in 25+ Languages</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em></em></td>
					<td><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
				<tr>
					<td class="feature b-radius-bottom-left">
						<div class="table-head">
							<p>Even More Content-Creation Credits</p>
							<app-tooltip text="lorem ipsum"></app-tooltip>
						</div>
					</td>
					<td><em></em></td>
					<td><em></em></td>
					<td class="b-radius-bottom-right"><em class="k-icon k-i-check k-i-checkmark"></em></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div *ngIf="globalLoader" class="global-spinner">
	<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
</div>
