<ng-container>
	<button
		class="outlined-icon {{ unreadCount ? 'new' : '' }} {{ isMenuOpened ? 'open' : '' }}"
		(menuOpened)="menuOpened()"
		(menuClosed)="menuClosed()"
		[matMenuTriggerFor]="notificationsMenu"
		#notificationsMenuTrigger="matMenuTrigger"
	>
		<img src="assets/shakespeare/notifications_none.svg" />
		<div class="new-dot">
			{{ unreadCount }}
		</div>
	</button>

	<mat-menu #notificationsMenu="matMenu" class="notification-menu">
		<div class="menu-body-wrapper" (click)="$event.stopPropagation()">
			<div class="notification-header">
				<div class="notification-title">Notifications</div>
			</div>

			<ng-container *ngIf="notifications?.length > 0">
				<app-notifications-list [notifications]="notifications | slice: 0:4" (updateUnread)="(updateUnreadCount)"></app-notifications-list>

				<div class="notification-footer">
					<a class="footer-text" (click)="routeToNotifications(); notificationsMenuTrigger.closeMenu()">View All</a>
				</div>
			</ng-container>

			<!-- !no notification -->
			<ng-container *ngIf="!notifications || notifications.length <= 0">
				<div class="no-data">
					<span>
						No Notifications Yet
					</span>
				</div>
			</ng-container>
		</div>
	</mat-menu>
</ng-container>
