export const TEMPLATES = [
	{
		id: 44,
		img: 'assets/templates/draft.svg',
		title: 'Write From Scratch',
		sub: 'Use AI predicted text to write alongside Shakespeare in real time',
		template: 'Blog',
		category: 'blog',
		isFav: true,
		pro: true
	},
	{
		id: 41,
		img: 'assets/templates/wizard.svg',
		title: 'Blog Wizard',
		sub: 'Try out the worlds most powerful blog writing tool',
		template: 'Blog',
		category: 'blog',
		isFav: true,
		pro: true
	},
	{
		id: 1,
		img: 'assets/templates/facebook.svg',
		title: 'Facebook Ad Headline',
		sub: 'Create snazzy and business relevant primary text',
		isFav: false,
		template: 'headline',
		category: 'ad-copy',
		platform: 'facebook',
		pro: false
	},
	{
		id: 2,
		img: 'assets/templates/facebook.svg',
		title: 'Facebook Ad Copies',
		sub: 'Generate high quality Headline and Primary text for Facebook Ads',
		isFav: false,
		template: 'ad_copy',
		category: 'ad-copy',
		platform: 'facebook',
		pro: false
	},
	{
		id: 3,
		img: 'assets/templates/google-icon.svg',
		title: 'Google Ad Headline',
		sub: 'Generate crisp and converting headlines for Google Ads',
		isFav: false,
		template: 'headline',
		category: 'ad-copy',
		platform: 'google',
		pro: false
	},
	{
		id: 4,
		img: 'assets/templates/google-icon.svg',
		title: 'Google Ad Description',
		sub: 'Create a highly clickable description for Google Ads',
		isFav: false,
		template: 'description',
		category: 'ad-copy',
		platform: 'google',
		pro: false
	},
	{
		id: 5,
		img: 'assets/templates/paragraph.svg',
		title: 'Blog Paragraph',
		sub: 'Let Shakespeare write a high engaging paragraph for your blog',
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	{
		id: 6,
		img: 'assets/templates/linkedin.png',
		title: 'LinkedIn Ad Headline',
		sub: 'Generate crisp and high converting headlines for LinkedIn',
		isFav: false,
		template: 'headline',
		category: 'ad-copy',
		platform: 'linkedin',
		pro: false
	},
	{
		id: 7,
		img: 'assets/templates/linkedin.png',
		title: 'LinkedIn Ad Description',
		sub: 'Generate top-notch, business-relevant descriptions for your LinkedIn Ads',
		isFav: false,
		template: 'description',
		category: 'ad-copy',
		platform: 'linkedin',
		pro: false
	},
	{
		id: 8,
		img: 'assets/templates/linkedin.png',
		title: 'LinkedIn Ad Copies',
		sub: 'Craft world-class, highly engaging introductory text for your LinkedIn Ads',
		isFav: false,
		template: 'ad_copy',
		category: 'ad-copy',
		platform: 'linkedin',
		pro: false
	},
	{
		id: 9,
		img: 'assets/templates/introduction.svg',
		title: 'Blog Introduction',
		sub: 'Hook your audience in with a carefully crafted intro',
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	{
		id: 10,
		img: 'assets/templates/headline.svg',
		title: 'Blog Headlines',
		sub: 'Generate attention-grabbing headlines that will draw readers to your blog',
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	{
		id: 11,
		img: 'assets/templates/email.svg',
		title: 'Email Subject',
		sub: 'Increase your email open rate with high quality subjects',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 12,
		img: 'assets/templates/email.svg',
		title: 'Email Description',
		sub: 'Generate high-converting email copy that works',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 13,
		img: 'assets/templates/content-improver.svg',
		title: 'Content Improver',
		sub: 'Optimise your existing content to create engaging copies',
		template: 'content',
		type: 'default',
		category: 'content',
		isFav: false,
		pro: false
	},
	{
		id: 14,
		img: 'assets/templates/conclusion.svg',
		title: 'Blog Conclusion',
		sub: 'Summarize your blog with topic relevant paragraphs',
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	{
		id: 15,
		img: 'assets/templates/amazon.png',
		title: 'Amazon Product Description',
		sub: 'Write highly engaging, A+ descriptions for your Amazon listings',
		platform: 'amazon',
		template: 'ProductDescription',
		category: 'product-description',
		isFav: false,
		pro: false
	},
	{
		id: 16,
		img: 'assets/templates/outline.svg',
		title: 'Blog Outline',
		sub: 'Generate high-quality listicles and how-to bullet points for your blog post',
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	{
		id: 17,
		img: 'assets/templates/email.svg',
		title: 'Email Subject & Description',
		sub: 'Generate a full email with Shakespeare from top to bottom',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 18,
		img: 'assets/templates/google-icon.svg',
		title: 'Google Ad Copies',
		sub: 'Generate high-converting headlines & descriptions for your Google Ads',
		isFav: false,
		template: 'ad_copy',
		category: 'ad-copy',
		platform: 'google',
		pro: false
	},
	{
		id: 19,
		img: 'assets/templates/product.svg',
		title: 'Product Description',
		sub: 'Write a more engaging and SEO optimized description for your product',
		template: 'ProductDescription',
		platform: 'default',
		category: 'product-description',
		isFav: false,
		pro: false
	},
	{
		id: 20,
		img: 'assets/templates/shopify.png',
		title: 'Shopify Product Description',
		sub: 'Generate high-quality descriptions for all your Shopify products',
		platform: 'shopify',
		template: 'ProductDescription',
		category: 'product-description',
		isFav: false,
		pro: false
	},
	{
		id: 21,
		img: 'assets/templates/full_blog.svg',
		title: 'Full Blog',
		sub: 'Let Shakespeare generate everything for your blog post from introduction to conclusion',
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	// {
	// 	id: 22,
	// 	img: 'assets/templates/website-design.svg',
	// 	title: 'SEO Website Copy',
	// 	sub: 'Generate SEO optimized content for your website',
	// 	template: '',
	// 	isFav: false
	// },
	// {
	// 	id: 23,
	// 	img: 'assets/templates/website-design.svg',
	// 	title: 'Landing Page Copy',
	// 	sub: 'Generate high converting copy for your landing page',
	// 	template: '',
	// 	isFav: false
	// },
	// {
	// 	id: 24,
	// 	img: 'assets/templates/website-design.svg',
	// 	title: 'Website Meta Description',
	// 	sub: 'Generate high-quality description that ranks',
	// 	template: '',
	// 	isFav: false
	// },
	// {
	// 	id: 25,
	// 	img: 'assets/templates/website-design.svg',
	// 	title: 'Website Bullet Points',
	// 	sub: 'Generate important headlines for your web-page',
	// 	template: '',
	// 	isFav: false
	// },
	// {
	// 	id: 26,
	// 	img: 'assets/templates/website-design.svg',
	// 	title: 'Unique Selling Points',
	// 	sub: 'Generate crisp points that convert',
	// 	template: '',
	// 	isFav: false
	// },
	{
		id: 27,
		img: 'assets/templates/facebook.svg',
		title: 'Facebook Ad Primary Text',
		sub: 'Create snazzy and business relevant primary text for Facebook Ads',
		isFav: false,
		template: 'description',
		category: 'ad-copy',
		platform: 'facebook',
		pro: false
	},
	{
		id: 28,
		img: 'assets/templates/ideas.svg',
		title: 'Blog Ideas',
		sub: "Generate a year's worth of blog post ideas in a matter of seconds",
		template: 'Blog',
		category: 'blog',
		isFav: false,
		pro: false
	},
	{
		id: 29,
		img: 'assets/templates/pinterest.png',
		title: 'Pinterest Title',
		sub: 'Generate a catch & highly engaging title for your Pinterest Pin',
		template: 'title',
		category: 'social',
		platform: 'pinterest',
		isFav: false,
		pro: false
	},
	{
		id: 30,
		img: 'assets/templates/pinterest.png',
		title: 'Pinterest Description',
		sub: 'Generate engaging copy for your Pinterest Pins',
		template: 'description',
		category: 'social',
		platform: 'pinterest',
		isFav: false,
		pro: false
	},
	{
		id: 31,
		img: 'assets/templates/pinterest.png',
		title: 'Pinterest Title & Description',
		sub: 'Engage your Pinterest audience with attention grabbing titles and riveting descriptions',
		template: 'title_description',
		platform: 'pinterest',
		category: 'social',
		isFav: false,
		pro: false
	},
	{
		id: 32,
		img: 'assets/templates/facebook.svg',
		title: 'Facebook Post Caption',
		sub: "Generate a brief and engaging caption to catch people's attention",
		template: 'title_description',
		platform: 'facebook',
		category: 'social',
		isFav: false,
		pro: false
	},
	{
		id: 33,
		img: 'assets/templates/linkedin.png',
		title: 'LinkedIn Post',
		sub: 'Make the most of those LinkedIn connections by generating engaging copy for your posts',
		template: 'title_description',
		platform: 'linkedin',
		category: 'social',
		isFav: false,
		pro: false
	},
	{
		id: 34,
		img: 'assets/templates/tiktok_logo.svg',
		title: 'Tiktok Caption',
		sub: "Go viral by pairing your video content with one of Shakespeare's world-class captions",
		template: 'title_description',
		platform: 'tiktok',
		category: 'social',
		isFav: false,
		pro: false
	},
	{
		id: 35,
		img: 'assets/templates/hashtag.svg',
		title: 'HashTag Generator',
		sub: 'Generate trending hashtags for all your social media posts',
		template: 'Hashtags',
		category: 'social',
		isFav: false,
		pro: false
	},
	{
		id: 36,
		img: 'assets/templates/aida.svg',
		title: 'AIDA Framework',
		sub: 'Generate sales copy using the AIDA Model (Attention-Interest-Desire-Action)',
		template: 'Framework',
		type: 'aida',
		category: 'framework',
		isFav: false,
		pro: false
	},
	{
		id: 37,
		img: 'assets/templates/pas.svg',
		title: 'PAS Framework',
		sub: 'Craft sales copy that converts in the PAS format (Problem-Agitate-Solution)',
		template: 'Framework',
		type: 'pas',
		category: 'framework',
		isFav: false,
		pro: false
	},
	{
		id: 38,
		img: 'assets/templates/bab.svg',
		title: 'Before After Bridge',
		sub: 'Generate engaging and creative copies in BAB (Before-After-Bridge) Format',
		template: 'Framework',
		type: 'bab',
		category: 'framework',
		isFav: false,
		pro: false
	},
	{
		id: 39,
		img: 'assets/templates/ppp.svg',
		title: 'Problem Promise Proof Proposal',
		sub: "Generate marketing plans for your business using the 4P's",
		template: 'Framework',
		type: 'pppp',
		category: 'framework',
		isFav: false,
		pro: false
	},
	{
		id: 40,
		img: 'assets/templates/ftb.svg',
		title: 'Feature To Benefits',
		sub: 'Convert leads into customers by generating Feature to Benefits sales pitches',
		template: 'Framework',
		type: 'ftb',
		category: 'framework',
		isFav: false,
		pro: false
	},
	// {
	// 	id: 46,
	// 	img: 'assets/templates/blog_seo.svg',
	// 	title: 'Blog SEO',
	// 	sub: 'Generate highly optimised title & meta description for your blog',
	// 	template: 'seo',
	// 	type: 'blog',
	// 	category: 'seo',
	// 	isFav: false,
	// 	pro: false
	// },
	// {
	// 	id: 47,
	// 	img: 'assets/templates/homepage_seo.svg',
	// 	title: 'Homepage SEO',
	// 	sub: 'Generate highly optimised title & meta description for your Homepage',
	// 	template: 'seo',
	// 	type: 'homepage',
	// 	category: 'seo',
	// 	isFav: false,
	// 	pro: false
	// },
	// {
	// 	id: 48,
	// 	img: 'assets/templates/seo_product.svg',
	// 	title: 'SEO Product',
	// 	sub: 'Generate highly optimised title & meta description for your products',
	// 	template: 'seo',
	// 	type: 'product',
	// 	category: 'seo',
	// 	isFav: false,
	// 	pro: false
	// },
	// {
	// 	id: 49,
	// 	img: 'assets/templates/seo_service.svg',
	// 	title: 'SEO Service',
	// 	sub: 'Generate highly optimised title & meta description to improve your engagement',
	// 	template: 'seo',
	// 	type: 'service',
	// 	category: 'seo',
	// 	isFav: false,
	// 	pro: false
	// },
	{
		id: 50,
		img: 'assets/templates/company_bio.svg',
		title: 'Company Bio',
		sub: 'Create eye-catching bios for your company',
		template: 'bio',
		type: 'company_bio',
		category: 'bio',
		isFav: false,
		pro: false
	},
	{
		id: 51,
		img: 'assets/templates/personal_bio.svg',
		title: 'Personal Bio',
		sub: 'Write a creative personal bio that captures attention',
		template: 'bio',
		type: 'personal_bio',
		category: 'bio',
		isFav: false,
		pro: false
	},
	{
		id: 52,
		img: 'assets/templates/social_media_bio.svg',
		title: 'Social Media Bio',
		sub: 'Write creative and engaging bios for your social media handles',
		template: 'bio',
		type: 'social_media_bio',
		category: 'bio',
		isFav: false,
		pro: false
	},
	{
		id: 53,
		img: 'assets/templates/linkedin_bio.svg',
		title: 'LinkedIn Bio',
		sub: "Create an effective bio that's perfect for your LinkedIn profile",
		template: 'bio',
		type: 'linkedin_bio',
		category: 'bio',
		isFav: false,
		pro: false
	},
	{
		id: 54,
		img: 'assets/templates/introduction.svg',
		title: 'Essay Introduction',
		sub: 'Generate high-quality and unique introductions for your essays',
		template: 'Essay',
		category: 'essay',
		isFav: false
	},
	{
		id: 55,
		img: 'assets/templates/introduction.svg',
		title: 'Essay Outline',
		sub: 'Generate a well defined & planned outline for your essay',
		template: 'Essay',
		category: 'essay',
		isFav: false
	},
	{
		id: 56,
		img: 'assets/templates/introduction.svg',
		title: 'Full Essay',
		sub: 'Write relevant and professional essays in no time',
		template: 'Essay',
		category: 'essay',
		isFav: false
	},
	{
		id: 59,
		img: 'assets/templates/content-rephrase.svg',
		title: 'Content Rephraser',
		sub: 'Generate unique & fresh copies by rephrasing existing content',
		template: 'content',
		type: 'rephrase',
		category: 'content',
		isFav: false,
		pro: false
	},
	{
		id: 60,
		img: 'assets/templates/content-grammar.svg',
		title: 'Grammar Check',
		sub: 'Perfect your content copies by reviewing your grammar and punctuation errors',
		template: 'content',
		type: 'grammar',
		category: 'content',
		isFav: false,
		pro: false
	},
	{
		id: 61,
		img: 'assets/templates/content-summarize.svg',
		title: 'Content Summarizer',
		sub: 'Create brief & quality summaries that summarize your text perfectly',
		template: 'content',
		type: 'summarize',
		category: 'content',
		isFav: false,
		pro: false
	},
	{
		id: 62,
		img: 'assets/templates/content-expand.svg',
		title: 'Content Expander',
		sub: 'Create a detailed piece of content that expands on the summaries you provide',
		template: 'content',
		type: 'expand',
		category: 'content',
		isFav: false,
		pro: false
	},
	{
		id: 63,
		img: 'assets/templates/content-bullets.svg',
		title: 'Bullet Point Generator',
		sub: 'Create to-the-point bullets that are relevant & easy-to-understand',
		template: 'content',
		type: 'bullet',
		category: 'content',
		isFav: false,
		pro: false
	},
	{
		id: 64,
		img: 'assets/templates/video-title.svg',
		title: 'Youtube Video Title',
		type: 'title',
		sub: 'Generate SEO friendly titles for your YouTube videos',
		template: 'video_title',
		category: 'video',
		platform: 'youtube',
		isFav: false,
		pro: false
	},
	{
		id: 45,
		img: 'assets/templates/video-description.svg',
		title: 'Youtube Video Description',
		type: 'description',
		sub: 'Summarize your YouTube content with copy that connects',
		template: 'video_description',
		category: 'video',
		platform: 'youtube',
		isFav: false,
		pro: false
	},
	{
		id: 66,
		img: 'assets/templates/video-outline.svg',
		title: 'Youtube Video Outline',
		type: 'outline',
		sub: 'Discover creative & interesting video ideas for your YouTube channel',
		template: 'video_outline',
		category: 'video',
		platform: 'youtube',
		isFav: false,
		pro: false
	},
	{
		id: 67,
		img: 'assets/templates/video-ideas.svg',
		title: 'Youtube Video Ideas',
		type: 'ideas',
		sub: 'Discover engaging & interesting ideas for your videos',
		template: 'video_ideas',
		category: 'video',
		platform: 'youtube',
		isFav: false,
		pro: false
	},
	{
		id: 68,
		img: 'assets/templates/video-script.svg',
		title: 'Youtube Video Script',
		type: 'script',
		sub: 'Generate engaging video scripts that will keep your viewers hooked',
		template: 'video_script',
		category: 'video',
		platform: 'youtube',
		isFav: false,
		pro: false
	},
	{
		id: 69,
		img: 'assets/templates/video-ideas.svg',
		title: 'TikTok Video Ideas',
		type: 'ideas',
		sub: 'Discover trending video ideas for your TikTok channel',
		template: 'video_ideas',
		category: 'video',
		platform: 'tiktok',
		isFav: false,
		pro: false
	},
	{
		id: 70,
		img: 'assets/templates/email.svg',
		title: 'Cold Email Outreach',
		sub: 'Create highly personalised cold emails that that convert',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 71,
		img: 'assets/templates/email.svg',
		title: 'Product Update',
		sub: 'Write descriptive product update emails that highlight the features accurately',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 72,
		img: 'assets/templates/email.svg',
		title: 'Newsletter',
		sub: "Keep your customer's up to date with a weekly newsletter",
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 73,
		img: 'assets/templates/email.svg',
		title: 'Abandoned Cart',
		sub: 'Convert those abandoned carts into sales with friendly checkout reminders',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 74,
		img: 'assets/templates/email.svg',
		title: 'Welcome Letter',
		sub: 'Greet your new customers with a warm welcome email',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	{
		id: 75,
		img: 'assets/templates/email.svg',
		title: 'Follow-Up',
		sub: 'Circle back on a previous email with a friendly follow-up',
		template: 'email',
		category: 'email',
		isFav: false,
		pro: false
	},
	// {
	// 	id: 76,
	// 	img: 'assets/templates/instagram-icon.svg',
	// 	title: 'Instagram Description',
	// 	sub: 'Generate a catchy & highly engaging description for your Instagram post',
	// 	template: 'description',
	// 	category: 'social',
	// 	platform: 'Instagram',
	// 	isFav: false,
	// 	pro: false
	// },
	{
		id: 77,
		img: 'assets/templates/introduction.svg',
		title: 'Essay Headlines',
		sub: 'Generate high-quality and unique introductions for your essays',
		template: 'Essay',
		category: 'essay',
		isFav: false
	},
	{
		id: 78,
		img: 'assets/templates/introduction.svg',
		title: 'Essay Conclusion',
		sub: 'Generate high-quality and unique introductions for your essays',
		template: 'Essay',
		category: 'essay',
		isFav: false
	},
	{
		id: 79,
		img: 'assets/templates/introduction.svg',
		title: 'Essay Paragraph',
		sub: 'Generate high-quality and unique introductions for your essays',
		template: 'Essay',
		category: 'essay',
		isFav: false
	},
	{
		id: 80,
		img: 'assets/templates/introduction.svg',
		title: 'Essay Ideas',
		sub: 'Generate high-quality and unique introductions for your essays',
		template: 'Essay',
		category: 'essay',
		isFav: false
	}
];
