import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { IKBSearchResult, IKBSearchResultData, IKBSubmitTicketResponse, ITicketResponse } from '../models/knowledgebase.interface';

@Injectable({
	providedIn: 'root'
})
export class KnowledgebaseService {
	public knowledgebaseHomeVisited$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseHome$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public knowledgebaseShowView$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseShowSub$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseShowFrame$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseShowTickets$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public searchValue$: BehaviorSubject<string> = new BehaviorSubject('');
	public searchQuery$: BehaviorSubject<string> = new BehaviorSubject('');
	public articleDataValue$: BehaviorSubject<IKBSearchResultData[]> = new BehaviorSubject([]);
	public searchDataValue$: BehaviorSubject<any[]> = new BehaviorSubject([]);
	public frameIndexValue$: BehaviorSubject<number> = new BehaviorSubject(0);

	constructor(protected http: HttpClient) {}

	public searchKnowledgebase(categoryId: string): Observable<IKBSearchResult[]> {
		return this.http.get<IKBSearchResult[]>(`${BaseApiUrl.FiledSupport}knowledge-base/search/${categoryId}`);
	}

	public submitTicket(data: object): Observable<IKBSubmitTicketResponse> {
		return this.http.post<IKBSubmitTicketResponse>(`${BaseApiUrl.FiledSupport}ticket/create`, data);
	}

	public getUserTickets(userEmail: string): Observable<ITicketResponse[]> {
		return this.http.get<ITicketResponse[]>(`${BaseApiUrl.FiledSupport}ticket/search/${userEmail}`);
	}

	public getCategories(): Observable<any[]> {
		return this.http.get<any[]>(`${BaseApiUrl.FiledSupport}knowledge-base/articles/categories`);
	}
}
