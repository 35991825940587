import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LANGUAGES } from '../../../shared/models/languages.const';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-content-generator-form',
	templateUrl: './content-generator-form.component.html',
	styleUrls: ['./content-generator-form.component.scss']
})
export class ContentGeneratorFormComponent implements OnInit {
	@Input() public generatorForm: FormGroup = new FormGroup({});
	@Input() public resettingForm: Subject<boolean> = new Subject<boolean>();
	@Input() public validate: Subject<boolean> = new Subject<boolean>();
	@Output() public validateChange: EventEmitter<boolean> = new EventEmitter();
	@Output() public ToneError = false;
	@Output() selectedLangSource = new EventEmitter<any>();
	@Output() public selectedOutputToneEvent = new EventEmitter<any>();
	public selectedOutputTone: any;
	public selectedOutputLang: any = 'en';
	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];
	public outputLang = [...LANGUAGES];
	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	constructor() {}

	ngOnInit(): void {
		this.validate.subscribe(res => {
			if (res) {
				this.checkValidation();
			}
		});
		this.resettingForm.subscribe(res => {
			if (res) {
				this.resetForm();
			}
		});
	}

	private checkValidation(): void {
		this.generatorForm.setControl(
			'type',
			new FormControl(this.generatorForm.controls.type.value, {
				validators: [Validators.required, Validators.maxLength(400)]
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: [Validators.required]
			})
		);
		this.generatorForm.markAllAsTouched();
		this.generatorForm.updateValueAndValidity();
		this.validateChange.emit(this.generatorForm.valid);
	}

	public changeToneSource(event): void {
		this.selectedOutputToneEvent.emit(event);
	}

	public changeLangSource(event): void {
		this.selectedLangSource.emit(event);
	}
	public removeValidators(): void {
		this.generatorForm.setControl(
			'type',
			new FormControl(this.generatorForm.controls.type.value, {
				validators: []
			})
		);

		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: []
			})
		);
	}

	private resetForm() {
		this.removeValidators();
		this.generatorForm.reset();
	}
}
