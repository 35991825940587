import { PaymentService } from './../../user-management/components/payment.service';
import { PlanEnum } from './../settings/model/billing-plan-interface';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { PlanDurationEnum } from '../settings/model/billing-plan-interface';
import { Subject } from 'rxjs';
import { CURRENCIES } from 'src/app/shared/models/currencies.const';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-ecommerce-plans',
	templateUrl: './ecommerce-plans.component.html',
	styleUrls: ['./ecommerce-plans.component.scss']
})
export class EcommercePlansComponent implements OnInit {
	public planDurationEnum = PlanDurationEnum;
	public planDuration: PlanDurationEnum = PlanDurationEnum.Monthly;
	private unsubscriber$ = new Subject<void>();
	private allPlans = [];
	public proPlans = [];
	public standardPlans = [];
	private userCurrency: string;
	public currencySymbol = '';
	public currencies = [...CURRENCIES];
	public monthlyStandard = [];
	public monthlyPro = [];
	public yearlyStandard = [];
	public yearlyPro = [];
	public sliderValue: string;
	public sliderProValue: string;
	public Math = Math;
	public selectedPlan: any;
	public selectedProPlan: any;
	public loading = false;
	public activePlan: PlanEnum = PlanEnum.pro;
	public planEnum = PlanEnum;
	public productId: string;
	public globalLoader = false;
	defaultStyle: string;
	defaultProStyle: string;
	isShopifyUser: boolean;
	standardStep = 2;
	proStep = 1;
	haveToggled = false;
	offerOn: boolean;
	slideHandler: number;
	slideProHandler: number;
	unlimitedPlan: boolean;
	freePlan: boolean;
	compare = false;
	shopifyUnlimitedPlans: any;
	monthlyShopifyUnlimited: any[];
	yearlyShopifyUnlimited: any[];
	selectedShopifyPlan: any;

	constructor(
		private backOfficeApiService: BackOfficeService,
		private paymentService: PaymentService,
		private authenticationService: AuthenticationService,
		private cdRef: ChangeDetectorRef,
		private authStore: Store<AuthenticationState>,
		private toastNotificationService: ToastNotificationService
	) {
		let loginParam = this.getCookie('loginSrc');
		if (loginParam == 'santa2022') {
			this.offerOn = true;
		}
	}

	ngOnInit(): void {
		window['dataLayer'].push({
			event: 'on_freetrial_screen',
			pageUrl: window.location.href,
			pageTitle: 'Free Trial'
		}); // invoking google tag manager code
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token.platform_name == 'shopify') {
			this.isShopifyUser = true;
		}
		this.getVersion();
	}

	compareToggle(): void {
		this.compare = !this.compare;
	}

	getVersion(): void {
		this.loading = true;
		this.backOfficeApiService
			.getVersion()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(val => {
				if (val == 'quickSignup2') {
					this.planDuration = this.planDurationEnum.Annual;
				}
				this.authenticationService.signupVersion$.next(val);
				this.getPlans();
			});
	}

	public signOut(): void {
		this.authenticationService.logout();
	}

	public onCheckToggleView(event: boolean): void {
		this.haveToggled = true;
		this.planDuration = !event ? PlanDurationEnum.Monthly : PlanDurationEnum.Annual;
		this.filterData();
	}

	public changePlan(plan: PlanEnum): void {
		this.activePlan = plan;
		this.allPlans.forEach(plan => {
			if (this.activePlan == PlanEnum.pro) {
				if (plan.productName.toString().toLowerCase().includes('pro') && !plan.isTrial) this.productId = plan.productId;
			} else {
				if (plan.productName.toString().toLowerCase().includes('standar') && !plan.isTrial) this.productId = plan.productId;
			}
		});
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	public buyShopifyUnlimited(): void {
		const loginParam = this.getCookie('loginSrc');
		const token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		let plan = null;
		this.globalLoader = true;
		setTimeout(() => {
			plan = this.selectedShopifyPlan;
			this.allPlans.forEach(plan => {
				if (plan.productName.toString() == 'Shopify Unlimited' && plan.isTrial) this.productId = plan.productId;
			});
			let loginParam = this.getCookie('loginSrc');
			let payload = {
				productId: this.productId,
				priceId: plan.priceId,
				email: token.filed_email,
				UserId: token.user_filed_id
			};
			if (loginParam !== '') {
				payload['src'] = loginParam;
			}
			let shopifyPayload = {
				priceId: plan.priceId
			};
			// shopify endpoint only
			this.backOfficeApiService
				.buyShopifyPlan(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: response => {
						if (response) {
							window.location.href = response;
						}
					},
					error: () => {
						this.toastNotificationService.sendErrorToast('Something went wrong, please try again');
						this.globalLoader = false;
						this.loading = false;
					}
				});
		}, 500);
	}

	public submit(type: string): void {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		let plan = null;
		this.globalLoader = true;
		setTimeout(() => {
			if (type == 'standard') {
				plan = this.selectedPlan;
			} else {
				plan = this.selectedProPlan;
			}
			this.allPlans.forEach(plan => {
				if (type == 'pro') {
					if (plan.productName.toString().toLowerCase().includes('pro') && !plan.isTrial) this.productId = plan.productId;
				} else {
					if (plan.productName.toString().toLowerCase().includes('standar') && !plan.isTrial) this.productId = plan.productId;
				}
			});
			let loginParam = this.getCookie('loginSrc');
			let payload = {
				productId: this.productId,
				priceId: plan.priceId,
				email: token.filed_email,
				UserId: token.user_filed_id
			};
			if (loginParam !== '') {
				payload['src'] = loginParam;
			}
			let shopifyPayload = {
				priceId: plan.priceId
			};
			if (this.isShopifyUser) {
				this.backOfficeApiService
					.buyShopifyPlan(payload)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe({
						next: response => {
							if (response) {
								window.location.href = response;
							}
						},
						error: () => {
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again');
							this.globalLoader = false;
							this.loading = false;
						}
					});
			} else {
				this.backOfficeApiService
					.buyPlan(payload)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe({
						next: response => {
							if (response) {
								this.paymentService.redirectToCheckout(response);
							}
						},
						error: () => {
							this.toastNotificationService.sendErrorToast('Something went wrong, please try again');
							this.globalLoader = false;
							this.loading = false;
						}
					});
			}
		}, 500);
	}

	public changeBackground(event): void {
		let slider = <HTMLInputElement>document.getElementById('slider');
		let value = (((slider.value as any) - (slider.min as any)) / ((slider.max as any) - (slider.min as any))) * 100;
		slider.style.background = 'linear-gradient(to right, #006bff 0%, ' + value + '%,#ECECEC ' + value + '%, #ECECEC 100%)';
	}

	public changeProBackground(event): void {
		let slider = <HTMLInputElement>document.getElementById('sliderPro');
		let value = (((slider.value as any) - (slider.min as any)) / ((slider.max as any) - (slider.min as any))) * 100;
		slider.style.background = 'linear-gradient(to right, #006bff 0%, ' + value + '%,#ECECEC ' + value + '%, #ECECEC 100%)';
	}

	public getShopifyFree(): void {
		this.freePlan = true;
		this.unlimitedPlan = false;
		this.backOfficeApiService
			.getShopifyFree()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: () => this.refreshToken(),
				error: () => {
					this.toastNotificationService.sendErrorToast('Something went wrong!');
				}
			});
	}

	public refreshToken(): void {
		this.backOfficeApiService
			.refreshUserToken()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.authStore.dispatch(new LogInSuccess(result));
			});
	}

	public getPlans(): void {
		this.loading = true;
		this.backOfficeApiService
			.getAllOffers()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.loading = false;
						response.forEach(plan => {
							if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('pro')) {
								this.proPlans = plan.productPrices;
								this.allPlans.push(plan);
							}
							if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('standard')) {
								this.standardPlans = plan.productPrices;
								this.allPlans.push(plan);
							}
							if (plan.isTrial && plan.productName == 'Shopify Unlimited') {
								this.shopifyUnlimitedPlans = plan.productPrices;
								this.allPlans.push(plan);
							}
						});
						this.filterData();
						this.changePlan(PlanEnum.pro);
						this.userCurrency = response[0].currency;
						this.currencySymbol = this.currencies.find(cur => this.userCurrency.toUpperCase() == cur.name).symbol;
					}
				},
				() => {
					this.loading = false;
				}
			);
	}

	public kFormatter(num: number): string {
		try {
			let formatter = Intl.NumberFormat(
				'en',
				// @ts-ignore,
				{ notation: 'compact' }
			);
			return formatter.format(num);
		} catch (e) {
			console.warn(e);
			Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1) as any) + 'K' : Math.sign(num) * Math.abs(num) + 'K';
		}
	}

	public filterData(): void {
		this.allPlans.forEach(plan => {
			if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('pro')) {
				this.proPlans = plan.productPrices;
				this.allPlans.push(plan);
			}
			if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('standard')) {
				this.standardPlans = plan.productPrices;
				this.allPlans.push(plan);
			}
			if (plan.isTrial && plan.productName == 'Shopify Unlimited') {
				this.shopifyUnlimitedPlans = plan.productPrices;
				this.allPlans.push(plan);
			}
		});
		this.monthlyStandard = [];
		this.yearlyStandard = [];
		this.monthlyPro = [];
		this.yearlyPro = [];

		this.monthlyShopifyUnlimited = [];
		this.yearlyShopifyUnlimited = [];

		this.standardPlans.forEach(plan => {
			if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && !plan.isTrial) {
				this.monthlyStandard.push(plan);
			}
			if (plan.interval == PlanDurationEnum.Annual && !plan.isTrial) {
				this.yearlyStandard.push(plan);
			}
		});
		this.proPlans.forEach(plan => {
			if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && !plan.isTrial) {
				this.monthlyPro.push(plan);
			}
			if (plan.interval == PlanDurationEnum.Annual && !plan.isTrial) {
				this.yearlyPro.push(plan);
			}
		});
		this.shopifyUnlimitedPlans.forEach(plan => {
			if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && plan.isTrial) {
				this.monthlyShopifyUnlimited.push(plan);
			}
			if (plan.interval == PlanDurationEnum.Annual && plan.isTrial) {
				this.yearlyShopifyUnlimited.push(plan);
			}
		});

		this.standardPlans = [];
		this.proPlans = [];
		if (this.planDuration == this.planDurationEnum.Monthly) {
			this.standardPlans = this.monthlyStandard;
			this.proPlans = this.monthlyPro;
			this.shopifyUnlimitedPlans = this.monthlyShopifyUnlimited;
		} else {
			this.standardPlans = this.yearlyStandard;
			this.proPlans = this.yearlyPro;
			this.shopifyUnlimitedPlans = this.yearlyShopifyUnlimited;
		}

		this.sortPlans(this.standardPlans);
		this.sortPlans(this.proPlans);
		let signupVersion = this.authenticationService.signupVersion$.value;
		this.selectedShopifyPlan = this.shopifyUnlimitedPlans[0];

		if (signupVersion == 'quickSignup3') {
			this.sliderValue = this.kFormatter(this.standardPlans[1]?.credit);
			this.standardStep = 1;
			this.proStep = 1;
			this.selectedPlan = this.standardPlans[1];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * 100;
			let defaultSelectedProVal = ((1 - 0) / (this.proPlans.length - 1) - 0) * 100;
			console.log('v3');
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
			this.selectedProPlan = this.proPlans[1];
			this.defaultProStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedProVal + '%, #ECECEC ' + defaultSelectedProVal + '%, #ECECEC 100%)';
			this.sliderProValue = this.kFormatter(this.proPlans[1]?.credit);
		} else if (signupVersion == 'quickSignup2') {
			this.sliderValue = this.kFormatter(this.standardPlans[1]?.credit);
			this.standardStep = 1;
			this.proStep = 0;
			console.log('v2');
			this.selectedPlan = this.standardPlans[1];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * 100;
			let defaultSelectedProVal = ((1 - 0) / (this.proPlans.length - 1) - 0) * 0;
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
			this.selectedProPlan = this.proPlans[0];
			this.defaultProStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedProVal + '%, #ECECEC ' + defaultSelectedProVal + '%, #ECECEC 100%)';
			this.sliderProValue = this.kFormatter(this.proPlans[0]?.credit);
		} else {
			this.sliderValue = this.kFormatter(this.standardPlans[1]?.credit);
			this.standardStep = 1;
			this.proStep = 0;
			this.selectedPlan = this.standardPlans[1];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * 100;
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
			this.selectedProPlan = this.proPlans[0];
			this.sliderProValue = this.kFormatter(this.proPlans[0]?.credit);
			console.log('v1');
		}

		// slider retains state after toggle
		if (this.slideHandler) {
			// for standard plans
			this.standardStep = this.slideHandler;
			this.sliderValue = this.kFormatter(this.standardPlans[this.slideHandler]?.credit);
			this.selectedPlan = this.standardPlans[this.slideHandler];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * (this.slideHandler * 100);
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
		}
		if (this.slideProHandler) {
			// for pro plans
			this.proStep = this.slideProHandler;
			this.sliderProValue = this.kFormatter(this.proPlans[this.slideProHandler]?.credit);
			this.selectedProPlan = this.proPlans[this.slideProHandler];
			let defaultSelectedProStandardVal = ((1 - 0) / (this.proPlans.length - 1) - 0) * (this.slideProHandler * 100);
			this.defaultProStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedProStandardVal + '%, #ECECEC ' + defaultSelectedProStandardVal + '%, #ECECEC 100%)';
		}
	}

	public sortPlans(plan): void {
		plan.sort((a, b) => {
			return a.price - b.price;
		});
		this.cdRef.detectChanges();
	}

	public onSlide(event): void {
		let value = event.target.value;
		this.slideHandler = value;
		this.activePlan = this.planEnum.standard;
		this.sliderValue = this.kFormatter(this.standardPlans[value].credit);
		this.selectedPlan = this.standardPlans[value];
	}

	public onSlidePro(event): void {
		let value = event.target.value;
		this.slideProHandler = value;
		this.activePlan = this.planEnum.pro;
		this.sliderProValue = this.kFormatter(this.proPlans[value].credit);
		this.selectedProPlan = this.proPlans[value];
	}
}
