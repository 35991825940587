import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
	selector: 'app-google-connect-model',
	templateUrl: './google-connect-model.component.html',
	styleUrls: ['./google-connect-model.component.scss']
})
export class GoogleConnectModelComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<GoogleConnectModelComponent>) {}

	ngOnInit(): void {}

	public closeModal(): void {
		this.dialogRef.close();
	}
}
