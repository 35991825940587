<div class="form">
	<div [formGroup]="inputForm">
		<div formArrayName="inputs">
			<div class="input-wrapper" *ngFor="let inputField of inputFields.controls; let i = index; let last = last">
				<app-text-input
					[inputId]="generateId(i)"
					(keydown.enter)="addMore(i)"
					[inputPlaceholder]="getPlaceholder(i)"
					styleClass="{{ showError ? ' ng-valid' : '' }}"
					[control]="inputField"
				></app-text-input>
				<button [disabled]="disabled" type="button" *ngIf="last && i < maxInput - 1; else remove" (click)="addMore(i)">
					<i class="fas fa-plus"></i>
				</button>
				<ng-template #remove>
					<button [disabled]="disabled" class="removed" type="button" (click)="removeInput(i)"><i class="fas fa-times"></i></button>
				</ng-template>
			</div>
			<div class="errors">
				<span *ngIf="showError">{{ errorMessage }}</span>
			</div>
		</div>
	</div>
</div>
