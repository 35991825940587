import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { HourSelectorModule } from './hour-selector/hour-selector.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdBlockerComponent } from './ad-blocker/ad-blocker.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { UserServiceApi } from '../_services/user/user.api.service';
import { ErrorMessageService } from './form-input/error-message.service';
import { GenericTabsComponent } from './generic-tabs/generic-tabs.component';
import { CheckPermissionsDirective } from './permisions/check-permissions.directive';
import { PermissionsService } from './permisions/permissions.service';
import { TablePermissionService } from './permisions/table-permissions/table-permission-service';
import { FormatTextPipe } from './pipes/format-text-pipe';
import { LimitLengthPipe } from './pipes/limit-length.pipe';
import { sharedReducer } from './state/shared.reducer';
import { userReducer } from './state/user/user.reducer';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { TruncateDirective } from './truncate/truncate.directive';
import { DropdownIconComponent } from './dropdown-icon/dropdown-icon.component';
import { DragDropDirective } from '../_directives/drag-drop.directive';
import { ButtonRowComponent } from './button-row/button-row.component';
import { ClickOutsideDirective } from './click-outside-directive/click-outside.directive';
import { DropdownSearchSelectComponent } from './dropdown-search-select/dropdown-search-select.component';
import { GenericDropdownComponent } from './form-input/generic-dropdown/generic-dropdown.component';
import { InputCardNumberComponent } from './form-input/input-card-number/input-card-number.component';
import { InputCvcComponent } from './form-input/input-cvc/input-cvc.component';
import { InputBillingDateComponent } from './form-input/input-date/input-billing-date.component';
import { InputNumberCardDirective } from './form-input/input-number-card.directive';
import { InputNumberValueDirective } from './form-input/input-number-value.directive';
import { InputNumberComponent } from './form-input/input-number/input-number.component';
import { InputTextComponent } from './form-input/input-text/input-text.component';
import { LineInputTextComponent } from './form-input/line-input-text/line-input-text.component';
import { HoverSelectDirective } from './hover-select-directive/hover-select.directive';
import { InfoHoverComponent } from './info/info-hover/info-hover.component';
import { InlineSelectorComponent } from './inline-selector/inline-selector.component';
import { InputDisabledComponent } from './input-disabled/input-disabled.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { CustomDrpdownOp1Component } from './input-styles/custom-drpdown-op1/custom-drpdown-op1.component';
import { KnowledgeFrameComponent } from './knowledge-base/component-pieces/knowledge-frame/knowledge-frame.component';
import { KnowledgeHeaderComponent } from './knowledge-base/component-pieces/knowledge-header/knowledge-header.component';
import { KnowledgeHomeComponent } from './knowledge-base/component-pieces/knowledge-home/knowledge-home.component';
import { KnowledgeListComponent } from './knowledge-base/component-pieces/knowledge-list/knowledge-list.component';
import { KnowledgeSearchResultComponent } from './knowledge-base/component-pieces/knowledge-search-result/knowledge-search-result.component';
import { KnowledgeSearchComponent } from './knowledge-base/component-pieces/knowledge-search/knowledge-search.component';
import { KnowledgeSubComponent } from './knowledge-base/component-pieces/knowledge-sub/knowledge-sub.component';
import { KnowledgeViewComponent } from './knowledge-base/component-pieces/knowledge-view/knowledge-view.component';
import { SubmitTicketComponent } from './knowledge-base/component-pieces/submit-ticket/submit-ticket.component';
import { ViewTicketComponent } from './knowledge-base/component-pieces/view-ticket/view-ticket.component';
import { KnowledgeBasePopUpComponent } from './knowledge-base/knowledge-base-pop-up/knowledge-base-pop-up.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { TablePermissionsComponent } from './permisions/table-permissions/table-permissions.component';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { CamelCaseToTextPipe } from './pipes/humanize';
import { ArraySortPipe } from './pipes/sort';
import { ScrollOutsideDirective } from './scroll-outside-directive/scroll-outside.directive';
import { TableTabsItemsComponent } from './table-tabs-items/table-tabs-items.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { WarningAnimationMessageComponent } from './warning-animation-message/warning-animation-message.component';
import { WarningMessageNoDataComponent } from './warning-message-no-data/warning-message-no-data.component';
import { NoResourceComponent } from './404/no-resource.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { ButtonComponent } from './button-component/button.component';
import { GenericSearchComponent } from './generic-search/generic-search.component';
import { InfoBoxComponent } from './info/info-box/info-box.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { ContainsPipe } from './pipes/contains';
import { FilterTextPipe } from './pipes/filterText';
import { SanitizeHtmlPipe } from './pipes/sanitize-html';
import { SnakeCaseToHuman } from './pipes/snake-case-to-human.pipe';
import { RoundedImageContainerComponent } from './rounded-image-container/rounded-image-container.component';
import { TreeChecklistComponent } from './tree-checklist/tree-checklist.component';
import { InputFieldPasswordComponent } from './input-field-password/input-field-password.component';
import { CreateNewModalComponent } from './create-new-modal/create-new-modal.component';
import { DropdownWithInputComponent } from './custom-dropdowns/dropdown-with-input/dropdown-with-input.component';
import { DropdownWoInputComponent } from './custom-dropdowns/dropdown-wo-input/dropdown-wo-input.component';
import { TextInputComponent } from './shakespeare/text-input/text-input.component';
import { TextInputWithRadioComponent } from './shakespeare/text-input-with-radio/text-input-with-radio.component';
import { SwitchComponent } from './shakespeare/switch/switch.component';
import { TextInputWithLabelComponent } from './shakespeare/text-input-with-label/text-input-with-label.component';
import { TooltipComponent } from './tootltip/tooltip.component';
import { CustomTextareaComponent } from './shakespeare/custom-textarea/custom-textarea.component';
import { CustomTextareaWithRadioComponent } from './shakespeare/custom-textarea-with-radio/custom-textarea-with-radio.component';
import { CustomTextareaWithLabelComponent } from './shakespeare/custom-textarea-with-label/custom-textarea-with-label.component';
import { InputWithAddMoreComponent } from './shakespeare/input-with-add-more/input-with-add-more.component';
import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DropDownWithImageComponent } from './shakespeare/drop-down-with-image/drop-down-with-image.component';
import { SearchInputComponent } from './shakespeare/search-input/search-input.component';
import { ToggleSwitchComponent } from './shakespeare/toggle-switch/toggle-switch.component';
import { EmailInputFieldComponent } from './shakespeare/email-input-field/email-input-field.component';
import { CustomTextEditorComponent } from '../shakespeare/components/custom-text-editor/custom-text-editor.component';
import { TourOverlayerCardComponent } from './shakespeare/tour-overlayer-card/tour-overlayer-card.component';
import { LoaderModule } from './loader/loader.module';
import { NgChartsModule } from 'ng2-charts';
import { OverviewComponent } from './overview/pages/overview.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CardsOverviewComponent } from './overview/components/cards-overview/cards-overview.component';
import { InsideCardContainerComponent } from './overview/components/inside-card-container/inside-card-container.component';
import { GoogleOverviewAdAccountsComponent } from './overview/components/google-overview-ad-accounts/google-overview-ad-accounts.component';
import { FacebookOverviewAdAccountsComponent } from './overview/components/facebook-overview-ad-accounts/facebook-overview-ad-accounts.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OverviewDataService } from './overview/services/overview-data.service';
import { PrimaryCalenderComponent } from './overview/components/primary-calender/primary-calender.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OverviewHeaderComponent } from './overview/components/overview-header/overview-header.component';
import { TourPopupComponent } from './overview/components/tour-popup/tour-popup.component';
import { MatInputPasswordComponent } from './mat-input-password/mat-input-password.component';
import { MatInputTextComponent } from './mat-input-text/mat-input-text.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { MatInputChipComponent } from './mat-input-chip/mat-input-chip.component';
@NgModule({
	imports: [
		MaterialModule,
		CommonModule,
		StoreModule.forFeature('shared', sharedReducer),
		StoreModule.forFeature('user', userReducer),
		MatButtonToggleModule,
		NgSelectModule,
		TranslateModule,
		NgbDatepickerModule,
		HourSelectorModule,
		DragDropModule,
		MatMenuModule,
		NgxEchartsModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgxMatSelectSearchModule,
		MatDialogModule,
		MatSnackBarModule,
		ScrollingModule,
		RouterModule,
		MatSortModule,
		LoaderModule,
		NgChartsModule,
		NgxSkeletonLoaderModule,
		NgbDropdownModule,
		NgbTooltipModule,
		NgxDaterangepickerMd.forRoot()
	],
	declarations: [
		CheckPermissionsDirective,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		ButtonComponent,
		InfoBoxComponent,
		NoResourceComponent,
		AddButtonComponent,
		GenericSearchComponent,
		TreeChecklistComponent,
		FilterTextPipe,
		ContainsPipe,
		ConfirmationDialogComponent,
		InformationDialogComponent,
		AdBlockerComponent,
		RoundedImageContainerComponent,
		FormatTextPipe,
		WarningMessageNoDataComponent,
		CamelCaseToTextPipe,
		ToastNotificationComponent,
		InputTextComponent,
		ArraySortPipe,
		ButtonRowComponent,
		InfoHoverComponent,
		InlineSelectorComponent,
		InputNumberComponent,
		InputNumberValueDirective,
		LineInputTextComponent,
		DropdownSearchSelectComponent,
		DropdownIconComponent,
		InputCardNumberComponent,
		InputNumberCardDirective,
		InputCvcComponent,
		InputBillingDateComponent,
		DragDropDirective,
		HoverSelectDirective,
		ClickOutsideDirective,
		ScrollOutsideDirective,
		FilterArrayPipe,
		WarningAnimationMessageComponent,
		TablePermissionsComponent,
		TableTabsItemsComponent,
		LimitLengthPipe,
		KnowledgeBaseComponent,
		KnowledgeBasePopUpComponent,
		KnowledgeHeaderComponent,
		KnowledgeHomeComponent,
		KnowledgeSearchComponent,
		KnowledgeSearchResultComponent,
		SubmitTicketComponent,
		KnowledgeListComponent,
		KnowledgeViewComponent,
		KnowledgeSubComponent,
		KnowledgeFrameComponent,
		ViewTicketComponent,
		GenericDropdownComponent,
		GenericTabsComponent,
		SafeURLPipe,
		InputFieldComponent,
		InputFieldPasswordComponent,
		CustomDrpdownOp1Component,
		InputDisabledComponent,
		TruncateDirective,
		CreateNewModalComponent,
		DropdownWithInputComponent,
		DropdownWoInputComponent,
		TextInputComponent,
		TextInputWithRadioComponent,
		SwitchComponent,
		TextInputWithLabelComponent,
		TooltipComponent,
		ButtonComponent,
		CustomTextareaComponent,
		CustomTextareaWithRadioComponent,
		CustomTextareaWithLabelComponent,
		CircularProgressComponent,
		CustomTextareaWithLabelComponent,
		InputWithAddMoreComponent,
		CheckboxComponent,
		DropDownWithImageComponent,
		SearchInputComponent,
		ToggleSwitchComponent,
		EmailInputFieldComponent,
		CustomTextEditorComponent,
		TourOverlayerCardComponent,
		OverviewComponent,
		CardsOverviewComponent,
		InsideCardContainerComponent,
		GoogleOverviewAdAccountsComponent,
		FacebookOverviewAdAccountsComponent,
		PrimaryCalenderComponent,
		OverviewHeaderComponent,
		TourPopupComponent,
		MatInputPasswordComponent,
		MatInputTextComponent,
		NotificationsListComponent,
		MatInputChipComponent
	],
	exports: [
		CommonModule,
		MatInputModule,
		MatNativeDateModule,
		MatRippleModule,
		MatDatepickerModule,
		CheckPermissionsDirective,
		SanitizeHtmlPipe,
		SnakeCaseToHuman,
		NoResourceComponent,
		TranslateModule,
		NgbDatepickerModule,
		AddButtonComponent,
		GenericSearchComponent,
		MatButtonToggleModule,
		TreeChecklistComponent,
		NgxEchartsModule,
		FilterTextPipe,
		ContainsPipe,
		CamelCaseToTextPipe,
		RoundedImageContainerComponent,
		FormatTextPipe,
		WarningMessageNoDataComponent,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		NgxMatSelectSearchModule,
		MatDialogModule,
		MatSnackBarModule,
		ScrollingModule,
		ToastNotificationComponent,
		InputTextComponent,
		ArraySortPipe,
		ButtonRowComponent,
		InfoBoxComponent,
		InfoHoverComponent,
		ButtonComponent,
		InlineSelectorComponent,
		InputNumberComponent,
		LineInputTextComponent,
		DropdownSearchSelectComponent,
		NgSelectModule,
		DropdownIconComponent,
		InputCardNumberComponent,
		InputCvcComponent,
		InputBillingDateComponent,
		DragDropDirective,
		ClickOutsideDirective,
		FilterArrayPipe,
		HoverSelectDirective,
		WarningAnimationMessageComponent,
		TableTabsItemsComponent,
		TablePermissionsComponent,
		LimitLengthPipe,
		KnowledgeBaseComponent,
		KnowledgeHeaderComponent,
		KnowledgeHomeComponent,
		KnowledgeSearchComponent,
		KnowledgeSearchResultComponent,
		KnowledgeListComponent,
		KnowledgeViewComponent,
		KnowledgeSubComponent,
		KnowledgeFrameComponent,
		ViewTicketComponent,
		GenericDropdownComponent,
		GenericTabsComponent,
		SafeURLPipe,
		MatSortModule,
		InputFieldComponent,
		InputFieldPasswordComponent,
		CustomDrpdownOp1Component,
		InputDisabledComponent,
		TruncateDirective,
		DropdownWithInputComponent,
		DropdownWoInputComponent,
		TextInputComponent,
		TextInputWithRadioComponent,
		SwitchComponent,
		TextInputWithLabelComponent,
		TooltipComponent,
		ButtonComponent,
		CustomTextareaWithLabelComponent,
		CustomTextareaWithRadioComponent,
		CircularProgressComponent,
		DropDownWithImageComponent,
		SearchInputComponent,
		InputWithAddMoreComponent,
		ToggleSwitchComponent,
		EmailInputFieldComponent,
		CustomTextEditorComponent,
		TourOverlayerCardComponent,
		LoaderModule,
		NgChartsModule,
		ConfirmationDialogComponent,
		InformationDialogComponent,
		ImageCropperModule,
		OverviewComponent,
		CardsOverviewComponent,
		InsideCardContainerComponent,
		GoogleOverviewAdAccountsComponent,
		FacebookOverviewAdAccountsComponent,
		PrimaryCalenderComponent,
		OverviewHeaderComponent,
		NgbDropdownModule,
		MatInputPasswordComponent,
		MatInputTextComponent,
		MatInputChipComponent,
		NotificationsListComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		TablePermissionService,
		PermissionsService,
		ErrorMessageService,
		TitleCasePipe,
		DecimalPipe,
		UserServiceApi,
		CheckPermissionsDirective,
		InterceptorsProvider,
		OverviewDataService
	]
})
export class SharedModule {}
