import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-cancel-offer',
	templateUrl: './cancel-offer.component.html',
	styleUrls: ['./cancel-offer.component.scss']
})
export class CancelOfferComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<CancelOfferComponent>, @Inject(MAT_DIALOG_DATA) public data, private router: Router) {}

	ngOnInit(): void {}

	public closeModal(): void {
		this.dialogRef.close();
	}

	public navigateToBilling(): void {
		this.dialogRef.close();
		this.router.navigate(['/settings/billing'], { queryParams: { page: 'upgrade' } });
	}

	public proceedToCancel(): void {
		this.dialogRef.close(true);
	}
}
