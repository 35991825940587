import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateNewModalComponent } from 'src/app/shared/create-new-modal/create-new-modal.component';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { ShakespeareService } from '../shakespeare.service';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { ViewTemplateComponent } from '../view-template/view-template.component';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
	@ViewChild(SelectContainerComponent) selectContainer: SelectContainerComponent;
	@ViewChild('editorControl') public editorControl: ElementRef;
	@ViewChild('folderEditorControl') public folderEditorControl: ElementRef;
	public checkForm: FormGroup;
	public heartClass = 'far fa-heart';
	public filledHeartClass = 'fas fa-heart filled';
	private unsubscriber$ = new Subject<void>();
	private filedId: number;
	public toggleHelper = 'projects';
	public selectedProjects = [];
	public dataLen = 0;

	public projectsArray = [];
	public foldersArray = [];
	public isFooter: boolean;
	public checkedCount: number;
	public selectedFolderId: string;
	public selectedFolderName: string;
	public selectedTitle: string;
	public backBtn = false;
	public keys: any[];
	public selectedItemTemplates = [];

	public backupFolder = [];
	public backupProject = [];
	notFound: boolean;

	public showFolderData = false;
	public selectedFolder = [];
	public templates = [...TEMPLATES];
	public blogTitleMapper = {
		blog: 'Full Blog',
		headlines: 'Blog Headlines',
		introduction: 'Blog Introduction',
		conclusion: 'Blog Conclusion',
		paragraph: 'Blog Paragraph',
		outline: 'Blog Outline',
		ideas: ' Blog Ideas'
	};
	public fullBlogCase: boolean;
	public project = {
		id: '',
		name: ''
	};
	public folder = {
		id: '',
		name: ''
	};

	public loading = false;

	public innerWidth: any;
	iPhoneMobile: boolean;
	isBonus: boolean;
	menuExpanded: boolean;
	showBonus = true;

	public folderDataArray = [];

	public showInput = false;
	public editedValue = null;
	public isEditing: string = null;
	tooltipText: string;

	constructor(
		private userServiceApi: UserServiceApi,
		private userStore: Store<UserState>,
		private shakespeareService: ShakespeareService,
		public toastNotificationService: ToastNotificationService,
		private router: Router,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private fb: FormBuilder,
		private ref: ChangeDetectorRef,
		private authenticationService: AuthenticationService,
		private renderer: Renderer2
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token && token.sign_up_version && token.sign_up_version == 'v2') {
			this.showBonus = false;
		}
		this.shakespeareService.isBonusOffer$.subscribe(val => {
			this.isBonus = val;
		});
	}

	ngOnInit(): void {
		this.ref.detectChanges();
		this.renderer.listen('window', 'click', (e: any) => {
			const targetId: string = (e.target?.id ?? '').toString();
			setTimeout(() => {
				if (this.isEditing && e.target.name !== 'rename' && !e.target.className.includes('save-button') && !e.target.className.includes('r-click')) {
					this.cancelNameEditing();
				}
			}, 200);
		});
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			const type = params['filter'] ?? 'projects';
			this.toggleHelper = type;
			this.selectedTitle = type;
			this.notFound = false;
			if (type == 'projects') {
				this.tooltipText = 'All of your copywriting projects in one place';
			} else {
				this.tooltipText = 'Organize your work into folders';
			}
			if (params && params.folderId && params.folderName) {
				this.selectedFolderId = params.folderId;
				this.selectedFolderName = params.folderName;
				this.getFolderData(this.selectedFolderId);
				this.tooltipText = 'All of your copywriting projects in one place';
			} else {
				this.getProjects();
				this.tooltipText = 'Organize your work into folders';
			}
			if (params && params.folders == true) {
				this.selectedTitle = 'folders';
				this.getFolderData(this.selectedFolderId);
			}
		});
	}
	public cancelNameEditing(): void {
		let document;
		if (this.toggleHelper == 'projects') {
			document = this.projectsArray.find(d => d.id === this.isEditing);
			this.editorControl.nativeElement.value = document.name;
		} else {
			document = this.folderDataArray.find(d => d.folderId === this.isEditing);
			this.folderEditorControl.nativeElement.value = document.folderName;
		}
		this.isEditing = null;
		this.editedValue = null;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	public navigateToBilling(): void {
		this.router.navigate(['/settings/billing'], { queryParams: { page: 'upgrade' } });
	}

	public getProjects(): void {
		this.loading = true;
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				folderId: ''
			}
		};
		this.userServiceApi
			.getUserProjects(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					// let newArr = [];
					this.folderDataArray = [];
					this.projectsArray = [...response['body']['projects']];
					for (let i = 0; i < this.projectsArray.length; i++) {
						if (this.projectsArray[i].folder !== undefined) {
							this.projectsArray[i]?.folder.forEach(ele => {
								ele.projectId = this.projectsArray[i].id;
								ele.projectName = this.projectsArray[i].name;
								ele.showInput = false;
								ele.isFav = false;
								ele.renamed = false;
								// newArr.push(ele);
								this.folderDataArray.push(ele);
							});
						}
					}
					this.backupFolder = [...this.folderDataArray];
					this.dataLen = this.projectsArray.length;
					this.initForm();
					this.changeDetection();
					for (let item of this.projectsArray) {
						item['isFav'] = false;
						item.isMenuOpen = false;
						item.showInput = false;
						item.renamed = false;
					}
					this.backupProject = [...this.projectsArray];
					this.retrieveFavorites('projects');
					if (!this.showFolderData) {
						this.retrieveFavorites('folders');
						this.retrieveFavorites('library');
					}
					// this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	public getFolderData(id: string): void {
		this.loading = true;
		this.selectedTitle = 'projects';
		this.backBtn = true;
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				folderId: id
			}
		};
		// getUserFolder works same for retreiving data inside a folder
		this.userServiceApi
			.getUserFolders(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.projectsArray = [...response['body']['data']];
					this.toggleHelper = this.selectedFolderName;
					this.loading = false;
					this.ref.detectChanges();
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	public onSelectProjects(event): void {
		if (this.selectedItemTemplates.length <= 0) {
			return;
		}
		this.selectedProjects = this.selectedItemTemplates.map(value => {
			this.checkForm.get('val' + value.index).setValue(true);
			return value.temp;
		});
		this.isFooter = event.length || this.checkedCount ? true : false;
		this.checkedCount = event.length == 0 ? this.selectedProjects.length : event.length;
	}

	public searchTemplate(_input: string): void {
		let inputVal = _input.toLowerCase();
		this.debounce(this.filterTemplates(inputVal));
	}

	public filterTemplates(value: string) {
		let temp = this.selectedTitle == 'folders' ? this.backupFolder : this.backupProject;
		if (value === '') {
			temp = this.selectedTitle == 'folders' ? this.backupFolder : this.backupProject;
			if (this.selectedTitle == 'folders') {
				this.folderDataArray = temp;
			} else {
				this.projectsArray = temp;
			}
			this.notFound = false;
			return;
		}
		let filteredData;
		if (this.selectedTitle === 'projects') {
			filteredData = temp.filter(template => {
				return template.name.toLowerCase().includes(value);
			});
		} else {
			filteredData = temp.filter(template => {
				return template.folderName.toLowerCase().includes(value);
			});
		}
		if (filteredData.length) {
			temp = filteredData;
			this.notFound = false;
		} else {
			temp = [];
			this.notFound = true;
		}
		if (this.selectedTitle == 'folders') {
			this.folderDataArray = temp;
			this.sortDataFolder();
		} else {
			this.projectsArray = temp;
			this.sortData();
		}
	}

	public debounce(callback, delay: number = 500) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				callback(...args);
			}, delay);
		};
	}

	changeDetection(): void {
		this.checkForm.valueChanges.subscribe(() => {
			this.checkedCount = 0;
			let flag = false;
			this.keys = [];
			for (const key of Object.keys(this.checkForm.controls)) {
				// check if any one value true, then make footer true and count all checked boxes
				if (this.checkForm.controls[key].value) {
					this.keys.push(key);
					flag = true;
					this.checkedCount++;
				}
				this.isFooter = flag;
			}
		});
	}

	public initForm(): void {
		this.checkForm = this.fb.group({});
		for (let i = 0; i < this.dataLen; i++) {
			this.checkForm.addControl('val' + i, new FormControl(false));
		}
	}

	public openCreateModal(type = ''): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'folder',
			text: 'Enter Folder Name',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			if (!obj) return;
			if (obj.value) {
				this.createFolder(obj.value, type);
			} else {
				this.addToFolder(obj.id, type);
			}
		});
	}

	public deselectAll(): void {
		this.selectedProjects = [];
		this.selectContainer.clearSelection();
		this.checkForm.reset();
	}

	public openProject(id: string): void {
		if (this.isEditing === id) {
			return;
		}
		this.router.navigate(['/projects/templates'], { queryParams: { projectId: id } });
	}

	public openFolder(projectId: string, folder: any): void {
		if (this.isEditing == folder?.folderId) {
			return;
		}
		this.router.navigate(['/projects/templates'], { queryParams: { projectId: projectId, folderId: folder.folderId, folderName: folder.folderName } });
	}

	public createFolder(name: string, type: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				projects: [],
				name: name
			}
		};
		this.selectedProjects.forEach(project => {
			payload.data.projects.push(project.id);
		});
		this.userServiceApi
			.createFolder(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					// this.getFolders();
					this.isFooter = false;
					this.checkForm.reset();
					this.toastNotificationService.sendSuccessToast('Folder created');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				},
				() => {}
			);
	}

	public addToFolder(folderId: string, type: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				projects: [],
				folderId: folderId
			}
		};
		if (type) {
			this.selectedProjects.forEach(project => {
				payload.data.projects.push(project.id);
			});
		}
		this.userServiceApi
			.addToFolder(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.getProjects();
					// this.getFolders();
					this.isFooter = false;
					this.toastNotificationService.sendSuccessToast('Project added');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				},
				() => {}
			);
	}

	public addToFav(event: Event, idx: number, type: string): void {
		event.stopPropagation();
		if (type === 'projects') {
			if (this.projectsArray[idx].isFav === true) {
				this.projectsArray[idx].isFav = false;
				if (event.target['classList'].contains('fav')) {
					event.target['classList'].remove('fav');
				}
				event.target['classList'].add('heart');
				this.removeItemFromFavorites(this.projectsArray[idx].id, type);
			} else {
				this.projectsArray[idx].isFav = true;
				if (event.target['classList'].contains('heart')) {
					event.target['classList'].remove('heart');
				}
				event.target['classList'].add('fav');
				this.addItemToFavorites(this.projectsArray[idx].id, type);
			}
			this.sortData();
		} else if (type === 'folders') {
			if (this.folderDataArray[idx].isFav === true) {
				this.folderDataArray[idx].isFav = false;
				if (event.target['classList'].contains('fav')) {
					event.target['classList'].remove('fav');
				}
				event.target['classList'].add('heart');
				this.removeItemFromFavorites(this.folderDataArray[idx].folderId, type);
			} else {
				this.folderDataArray[idx].isFav = true;
				if (event.target['classList'].contains('heart')) {
					event.target['classList'].remove('heart');
				}
				event.target['classList'].add('fav');
				this.addItemToFavorites(this.folderDataArray[idx].folderId, type);
			}
			this.sortDataFolder();
		}
	}

	retrieveFavorites(type: string) {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				type: type
			}
		};
		// this.loading = true;
		this.userServiceApi
			.retrieveFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (type === 'projects') {
						let newArr = [];
						response['data']?.forEach(ele => {
							newArr.push(ele.id);
						});
						this.projectsArray?.forEach(template => {
							if (newArr.includes(template.id)) {
								template.isFav = true;
							} else {
								template.isFav = false;
							}
						});
						this.sortData();
					} else if (type === 'folders') {
						let newArr = [];
						response['data']?.forEach(ele => {
							newArr.push(ele.id);
						});
						// for (let i = 0; i < this.projectsArray.length; i++) {
						// 	if (this.projectsArray[i].folder !== undefined) {
						this.folderDataArray?.forEach(ele => {
							if (newArr.includes(ele.folderId)) {
								ele.isFav = true;
							} else {
								ele.isFav = false;
							}
						});
						// 	}
						// }
						this.sortDataFolder();
					}
					this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	public sortData(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.projectsArray) {
			if (template.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.projectsArray = [...sortedList];
	}

	public sortDataFolder(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.folderDataArray) {
			if (template.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.folderDataArray = [...sortedList];
	}

	public onDelete(event: Event, item: object, type: string, projectId?: string): void {
		event.stopPropagation();
		this.loading = true;
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {}
		};
		if (type === 'projects') {
			payload.data['projectId'] = item['id'];
			this.userServiceApi
				.deleteProject(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {
						this.getProjects();
						this.isFooter = false;
						this.loading = false;
						this.toastNotificationService.sendSuccessToast('Project Deleted');
					},
					() => {
						this.loading = false;
						this.toastNotificationService.sendErrorToast('Error, Please try again');
					}
				);
		} else {
			payload.data['project_id'] = projectId;
			payload.data['folder_id'] = item['folderId'];

			this.userServiceApi
				.deleteFolder(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {
						this.getProjects();
						this.toastNotificationService.sendSuccessToast('Folder Deleted');
						this.loading = false;
						this.isFooter = false;
						this.showFolderData = false;
						// for (let i = 0; i < this.projectsArray.length; i++) {
						// 	if (this.projectsArray[i].folder === undefined) {
						// 		this.noFolderFound = true;
						// 	}
						// }
					},
					() => {
						this.loading = false;
						this.toastNotificationService.sendErrorToast('Error, Please try again');
					}
				);
		}
	}

	public deleteOutput(event: Event, outputId: string): void {
		event.stopPropagation();
		this.loading = true;
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				projectId: this.project.id,
				folderId: this.folder.id,
				user_input_output_data_id: outputId
			}
		};
		this.userServiceApi
			.deleteFromFolder(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Output removed from folder');
					const found = this.selectedFolder[0].inputOutputData?.findIndex(ele => ele.id === outputId);
					if (found >= 0) {
						this.selectedFolder[0].inputOutputData.splice(found, 1);
					}
					this.showFolderData = true;
					this.loading = false;
					// this.goBack();
				},
				() => {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('Something went wrong, please try later');
				},
				() => {}
			);
	}

	public addItemToFavorites(id: string, type: string) {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				heart_data: [{ id: id }],
				type: type
			}
		};
		this.userServiceApi
			.addFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Added to favorites');
				},
				() => {},
				() => {}
			);
	}

	public removeItemFromFavorites(id: string, type: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				id: id,
				type: type
			}
		};
		this.userServiceApi
			.removeFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Removed from favorites');
				},
				() => {},
				() => {}
			);
	}

	public goBack(): void {
		this.location.back();
		this.selectedTitle = 'folders';
		this.toggleHelper = 'Folders';
		this.backBtn = false;
		this.showFolderData = false;
	}

	canBeEdited(itemId: string): boolean {
		if (this.toggleHelper === 'projects') {
			return this.isEditing === itemId;
		} else {
		}
	}

	public openMenu(event: Event, index: number, project?: any) {
		event.stopPropagation();
		this.projectsArray[index].isMenuOpen = true;
		if (project && project.folder !== undefined) {
			project.folder[index].isMenuOpen = true;
		}
	}

	public getMenuValue(event: string, index: number, project?: any) {
		this.projectsArray[index].isMenuOpen = false;
		if (project && project.folder !== undefined) {
			project.folder[index].isMenuOpen = false;
		}
	}

	public rename(item: any, initialValue: any, editorId: string, type: string) {
		if (type === 'projects') {
			this.isEditing = item.id;
			item.showInput = true;
		} else {
			this.isEditing = item.folderId;
			item.showInput = true;
		}
		this.editedValue = initialValue;
		setTimeout(() => {
			var range = document.createRange();
			var sel = window.getSelection();
			if (type === 'projects') {
				this.editorControl.nativeElement.focus();
			} else {
				this.folderEditorControl.nativeElement.focus();
			}
			document.getElementById(editorId.toString()).focus();
		}, 200);
	}

	setTextValue(event: any, id: number, editedText: string, selectedProject?: any): void {
		let type = this.toggleHelper;
		if (event.keyCode == 13 || event.key === 'Enter') {
			event?.preventDefault();
			setTimeout(() => {
				this.completeRename(id, editedText, type, event);
			}, 20);
			return;
		}
		this.editedValue = editedText;
	}

	public completeRename(index: number, value: any = '', type: string, event?: Event): void {
		if (event) {
			event?.stopPropagation();
		}
		let reqObj;
		let renamedFolder;
		if (value.length <= 0) {
			return;
		}
		if (type === 'projects') {
			const foundProject = this.projectsArray.find(d => d.id === this.isEditing);
			foundProject.name = this.editorControl.nativeElement.value ?? value;
			reqObj = {
				user: {
					user_filed_id: this.filedId
				},
				data: {
					project_id: this.projectsArray[index].id,
					name: foundProject.name
				}
			};
		} else {
			const foundFolder = this.folderDataArray.find(d => d.folderId === this.isEditing);
			foundFolder.folderName = this.folderEditorControl.nativeElement.value ?? value;
			reqObj = {
				user: {
					user_filed_id: this.filedId
				},
				data: {
					projectId: foundFolder.projectId,
					folderId: this.folderDataArray[index].folderId,
					name: foundFolder.folderName
				}
			};
		}

		this.userServiceApi.renameProjectOrFolder(reqObj).subscribe((res: any) => {
			if (res.debugMessage) {
				this.isEditing = null;
				this.editedValue = null;
				if (type === 'projects') {
					this.projectsArray[index].showInput = false;
					this.projectsArray[index].renamed = true;
					setTimeout(() => {
						this.projectsArray[index].renamed = false;
					}, 3000);
				} else {
					this.folderDataArray[index].showInput = false;
					this.folderDataArray[index].renamed = true;
					setTimeout(() => {
						this.folderDataArray[index].renamed = false;
					}, 5000);
					// }
				}
			}
		});
	}
}
