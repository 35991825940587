import { initialAuthState } from './../authentication/state/authentication.reducer';
import { initialSharedState, sharedReducer, SharedState } from '../shared/state/shared.reducer';
import { authenticationReducer, AuthenticationState } from '../authentication/state/authentication.reducer';
import { initialUserState, userReducer, UserState } from '../shared/state/user/user.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AppAction, AppTypes } from './app.actions';
import {
	TemplateCommonState,
	ShakespeareCommonReducer,
	TemplateState,
	UserPlanCommonState,
	UserPlanState
} from './shakespeare-common-state/shakespeare-common-reducer';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');

export interface AppState {
	shared: SharedState;
	authentication: AuthenticationState;
	user: UserState;
	shakespeare: TemplateState;
	userPlan: UserPlanState;
}

export const initialAppState: AppState = {
	shared: initialSharedState,
	user: initialUserState,
	shakespeare: TemplateCommonState,
	authentication: initialAuthState,
	userPlan: UserPlanCommonState
};

export const reducers: ActionReducerMap<AppState> = {
	// userManagement: userManagementReducer,
	shared: sharedReducer,
	authentication: authenticationReducer,
	user: userReducer,
	shakespeare: ShakespeareCommonReducer,
	userPlan: ShakespeareCommonReducer
};

export enum AppStateSlices {
	UserManagement = 'UserManagement',
	Shared = 'Shared',
	Authentication = 'Authentication',
	User = 'User',
	Shakespeare = 'Shakespeare'
}

export function clearStateMetaReducer(reducer: any) {
	return function (state: AppState, action: AppAction) {
		if (action.type === AppTypes.ResetAppState) {
			state = initialAppState;
		}
		return reducer(state, action);
	};
}
