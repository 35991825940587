import { BackofficeComponent } from './backoffice/backoffice.component';
import { TrashComponent } from './trash/trash.component';
import { EssayGeneratorComponent } from './templates/essay-generator/essay-generator.component';
import { FrameworkGeneratorComponent } from './templates/framework-generator/framework-generator.component';
import { SocialGeneratorComponent } from './templates/social-generator/social-generator.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LibraryComponent } from './library/library.component';
import { ProjectsComponent } from './projects/projects.component';
import { AdCopyGeneratorComponent } from './templates/ad-copy-generator/ad-copy-generator.component';
import { BlogsGeneratorComponent } from './templates/blogs-generator/blogs-generator.component';
import { ContentGeneratorComponent } from './templates/content-generator/content-generator.component';
import { EmailGeneratorComponent } from './templates/email-generator/email-generator.component';
import { ProductDescGeneratorComponent } from './templates/product-desc-generator/product-desc-generator.component';
import { ProjectDataComponent } from './project-data/project-data.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { BlogWizardGeneratorComponent } from './templates/blog-wizard-generator/blog-wizard-generator.component';
import { FreeTrialComponent } from './free-trial/free-trial.component';
import { FolderDataComponent } from './folder-data/folder-data.component';
import { ShopifyBillingComponent } from './shopify-billing/shopify-billing.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { VideoGeneratorComponent } from './templates/video-generator/video-generator.component';
import { SeoGeneratorComponent } from './templates/seo-generator/seo-generator.component';
import { BioGeneratorComponent } from './templates/bio-generator/bio-generator.component';
import { CreativeComponent } from './creative/creative.component';
import { GoogleGlobalAuthComponent } from './google-global-auth/google-global-auth.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { V3enterpriseComponent } from '../authentication/v3enterprise/v3enterprise.component';
import { WelcomeShakespeareFormComponent } from './components/welcome-shakespeare-form/welcome-shakespeare-form.component';
import { AuthComponent as GoogleAuthComponent } from './ads/google/auth/auth.component';
import { AuthComponent as FacebookAuthComponent } from './ads/facebook/auth/auth.component';
import { OverviewComponent } from '../shared/overview/pages/overview.component';
import { GoogleOverviewAdAccountsComponent } from '../shared/overview/components/google-overview-ad-accounts/google-overview-ad-accounts.component';
import { FacebookOverviewAdAccountsComponent } from '../shared/overview/components/facebook-overview-ad-accounts/facebook-overview-ad-accounts.component';
import { SlackGlobalAuthComponent } from './slack-global-auth/slack-global-auth.component';
import { NotificationComponent } from './notification/notification.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
	// {
	// 	path: '',
	// 	loadChildren: () => {
	// 		return localStorage.getItem('googleAllowed') == 'true'
	// 			? import('./ads/ads.module').then(m => m.AdsModule)
	// 			: import('./home/home.module').then(m => m.HomeModule);
	// 	}
	// },
	// {
	// 	path: '',
	// 	loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
	// },
	{
		path: '',
		component: OverviewComponent
	},
	{ path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
	{
		path: 'templates',
		loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule)
	},
	{ path: 'back-office', component: BackofficeComponent },
	{ path: 'templates/ad-copy', component: AdCopyGeneratorComponent },
	{ path: 'templates/email', component: EmailGeneratorComponent },
	{ path: 'templates/product-description', component: ProductDescGeneratorComponent },
	{ path: 'templates/blog', component: BlogsGeneratorComponent },
	{ path: 'templates/blog-wizard', component: BlogWizardGeneratorComponent },
	{ path: 'templates/content', component: ContentGeneratorComponent },
	{ path: 'templates/social', component: SocialGeneratorComponent },
	{ path: 'templates/framework', component: FrameworkGeneratorComponent },
	{ path: 'templates/essay', component: EssayGeneratorComponent },
	{ path: 'templates/seo', component: SeoGeneratorComponent },
	{ path: 'templates/bio', component: BioGeneratorComponent },
	{ path: 'library', component: LibraryComponent },
	{ path: 'projects', component: ProjectsComponent },
	{ path: 'projects/templates', component: ProjectDataComponent },
	{ path: 'folders/templates', component: FolderDataComponent },
	{ path: 'texteditor', component: TextEditorComponent },
	{ path: 'library/trash', component: TrashComponent },
	{ path: 'enterprise', component: EnterpriseComponent },
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
	},
	{
		path: 'document',
		loadChildren: () => import('./documents/document.module').then(m => m.DocumentsModule)
	},
	{ path: 'free-trial', component: FreeTrialComponent },
	{ path: 'v3enterprise', component: V3enterpriseComponent },
	{ path: 'google/auth-connect', component: GoogleAuthComponent },
	{ path: 'facebook/auth-connect', component: FacebookAuthComponent },
	// { path: 'welcome-shakespeare-form', component: WelcomeShakespeareFormComponent },
	{ path: 'shopify', loadChildren: () => import('./shopify/shopify.module').then(m => m.ShopifyModule) },
	{ path: 'settings/s/billing', component: ShopifyBillingComponent },
	{ path: 'integrations', component: IntegrationsComponent },
	{ path: 'notifications', component: NotificationComponent },
	{ path: 'templates/video', component: VideoGeneratorComponent },
	{ path: 'big-commerce', loadChildren: () => import('./big-commerce/big-commerce.module').then(m => m.BigCommerceModule) },
	{ path: 'creative', component: CreativeComponent },
	{ path: 'ads', loadChildren: () => import('./ads/ads.module').then(m => m.AdsModule) },
	{ path: 'googleAuth', component: GoogleGlobalAuthComponent },
	{ path: 'slackAuth', component: SlackGlobalAuthComponent },
	{ path: 'creative-studio', loadChildren: () => import('./creative-studio/creative-studio.module').then(m => m.CreativeStudioModule) },
	{ path: 'optimization-ai', loadChildren: () => import('./OptimizationAI/optimization-ai.module').then(m => m.OptimizationAiModule) },
	{ path: 'overview', component: OverviewComponent },
	{ path: 'overview/google-adAccount', component: GoogleOverviewAdAccountsComponent },
	{ path: 'overview/facebook-adAccount', component: FacebookOverviewAdAccountsComponent },
	// { path: 'login', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },

	{
		path: 'ai-targeting',
		loadChildren: () => import('./targeting/targeting.module').then(m => m.TargetingModule)
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ShakespeareRoutingModule {}
