<div class="nav-wrapper">
	<button class="back-button" (click)="goBack()">
		<img src="assets/mobile-back-button.svg" alt="back button" class="icon" />
		<p>Back</p>
	</button>
</div>

<div class="wrapper">
	<div class="header">
		Notifications
	</div>
	<div class="main">
		<app-notifications-list [notifications]="notifications"></app-notifications-list>

		<div class="more"></div>

		<div class="loader-wrapper" *ngIf="loading" style="margin-top: 10px;">
			<ngx-skeleton-loader
				appearance="line"
				style="height: 44px; width: 100%;"
				[theme]="{ width: '100%', height: '44px' }"
				count="10"
			></ngx-skeleton-loader>
		</div>
	</div>
</div>
