import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-view-template',
	templateUrl: './view-template.component.html',
	styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent implements OnInit, AfterViewInit {
	@ViewChild('text') element: ElementRef;

	public libraryData: any;
	public showInputs = false;
	public inputToggleBtn = 'Show Input';
	public category: string;
	public extractedData: any;
	public copyHelper: string;
	public isCopied = false;
	public idCounter = 1;
	public fullBlogCase = false;
	public isMobile = false;
	template: any;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ViewTemplateComponent>) {}

	ngOnInit(): void {
		this.libraryData = this.data.template.data;
		if (this.libraryData === undefined) {
			this.libraryData = this.data.template;
		}
		this.isMobile = this.data.isMobile;
		for (let item of this.libraryData.userOutput) {
			item['id'] = this.idCounter++;
			item['isFav'] = false;
			item['isLiked'] = false;
		}
		this.category = this.libraryData.templateData?.category;
		this.template = this.libraryData.userTemplate?.template;
		this.extractedData = this.libraryData.userOutput;
		if (this.libraryData.userInput.blogType == 'blog' || this.libraryData.userInput.essayType == 'essay') {
			this.fullBlogCase = true;
		}
	}

	public toObject(array: []) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	public toggleInput(): void {
		this.showInputs = !this.showInputs;
		this.showInputs ? (this.inputToggleBtn = 'Hide Input') : (this.inputToggleBtn = 'Show Input');
	}

	ngAfterViewInit(): void {
		this.element.nativeElement.setAttribute('spellcheck', 'false');
	}

	public copyWithStyle(element): string {
		const text = <HTMLElement>document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);

			selection.removeAllRanges();
			selection.addRange(range);
			this.copyHelper = window.getSelection().toString();
			// document.execCommand('copy');
			window.getSelection().removeAllRanges();
			return this.copyHelper;
		}
	}

	public addToCopied(id): void {
		let data = this.copyWithStyle(id);
		navigator.clipboard.writeText(data);

		this.isCopied = true;
		setTimeout(() => {
			this.isCopied = false;
		}, 3000);
	}
}
