<form class="example-form">
	<mat-label class="label">{{ dropdownLabel }}</mat-label>
	<mat-form-field class="example-full-width" appearance="outline">
		<input type="text" [placeholder]="inputPlaceholder" matInput [formControl]="inputControl" [matAutocomplete]="auto" />
		<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
			<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
				{{ option.name }}
			</mat-option>
		</mat-autocomplete>
		<span matSuffix class="fa fa-caret-down"></span>
		<!-- <button *ngIf="showAddButton && inputControl.value" matSuffix mat-button mat-icon-button (click)="addOption()">
			<mat-icon matTooltip="Add &quot;{{ inputControl.value }}&quot;">add</mat-icon>
		</button> -->
	</mat-form-field>
</form>
