import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { MatDialog } from '@angular/material/dialog';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { NoCreditModalComponent } from '../no-credit-modal/no-credit-modal.component';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-generator-output',
	templateUrl: './generator-output.component.html',
	styleUrls: ['./generator-output.component.scss']
})
export class GeneratorOutputComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public formData: object;
	@Input() public hasHistory: any;
	@Output() public callbackData = new EventEmitter<any>();
	@Output() public isDataFetched = new EventEmitter<boolean>();
	@Output() public generateDataComplete: EventEmitter<any> = new EventEmitter();
	public generatedData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	private unsubscriber$ = new Subject<void>();
	public idCounter = 1;
	public filedId: number;
	public category: string;
	public isCopied = false;
	public currentCopiedEl: number;
	public copyHelper = '';
	fullBlogCase: boolean;
	public noCredits = false;
	public dynamicText = 'Ai is writing the best outputs for you.';
	public userFName: string;
	selectedTemplate: TemplatesModel;
	timer: NodeJS.Timeout;
	myInterval: NodeJS.Timeout;
	public menuExpanded = false;
	public showVideoPlayer;
	public videoLinkId: SafeResourceUrl;

	constructor(
		private userServiceApi: UserServiceApi,
		public toastNotificationService: ToastNotificationService,
		private userStore: Store<UserState>,
		private dialog: MatDialog,
		private shakespeareService: ShakespeareService,
		private backOfficeApiService: BackOfficeService,
		private authenticationService: AuthenticationService,
		public sanitizer: DomSanitizer
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.shakespeareService.autoScrollOff();
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	ngOnInit(): void {
		this.setCategory();
		if (this.formData) {
			this.getData();
		}
		if (this.hasHistory) {
			this.showVideoPlayer = false;
		}
	}

	ngOnChanges(): void {
		if (this.formData) {
			this.getData();
		}
	}

	public setCategory(): void {
		let url = window.location.href;
		let urlArray = url.split('/');
		this.category = urlArray[urlArray.length - 1].split('?')[0]; // getting last part of url without any queryParams
		let videoId = '';
		switch (this.category) {
			case 'ad-copy':
				videoId = 'bUzy10u8m0w';
				break;
			case 'email':
				videoId = 'b_RyktCiDbg';
				break;
			case 'product-description':
				videoId = 'YFhXX_mTgtA';
				break;
			case 'blog':
				videoId = 'tkSTMcbig4s';
				break;
			case 'content':
				videoId = 'JN7IdJqCwJc';
				break;
			case 'social':
				videoId = 'bX6opVnhwdo';
				break;
			case 'framework':
				break;
			case 'seo':
				break;
			case 'bio':
				break;
			case 'video':
				break;
			case 'essay':
				break;
			default:
				break;
		}
		this.videoLinkId = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + videoId);
		this.showVideoPlayer = true;
	}

	public getData(): void {
		switch (this.category) {
			case 'ad-copy':
				this.getAdCopyData();
				this.videoLinkId = 'bUzy10u8m0w';
				break;
			case 'email':
				this.getEmailData();
				this.videoLinkId = 'b_RyktCiDbg';
				break;
			case 'product-description':
				this.getDescriptionData();
				this.videoLinkId = 'YFhXX_mTgtA';
				break;
			case 'blog':
				this.generateBlogData();
				this.videoLinkId = 'tkSTMcbig4s';
				break;
			case 'content':
				this.generateContentData();
				this.videoLinkId = 'JN7IdJqCwJc';
				break;
			case 'social':
				this.generateSocialData();
				this.videoLinkId = 'bX6opVnhwdo';
				break;
			case 'framework':
				this.generateFrameworkData();
				break;
			case 'seo':
				this.generateSeoData();
				break;
			case 'bio':
				this.generateBioData();
				break;
			case 'video':
				this.generateVideoData();
				break;
			case 'essay':
				this.generateEssayData();
				break;
			default:
				break;
		}
		let dynamicTextArray = [
			// 'Hello ' + this.userFName + ', Shakespeare reporting for duty',
			"As soon as I'm finished writing, your results will be shown here.",
			'Choose the output tone that best aligns with your brand or the purpose of your product.',
			"If you're targeting a specific gender, you may select one here.",
			'While the default is English, Shakespeare can generate copy in over 100+ languages.',
			'Use blog wizard to write the blog in seconds.',
			"Paste the content you'd like Shakespeare to improve or rewrite in content improver."
		];
		let i = 0;
		clearInterval(this.myInterval);
		this.myInterval = setInterval(() => {
			if (i == dynamicTextArray.length) {
				i = 0;
			}
			this.dynamicText = dynamicTextArray[i];
			i++;
		}, 3000);
	}

	public refreshToken(): void {
		this.backOfficeApiService
			.refreshUserToken()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {},
				error => {
					if (error.error[0]['code'] == 'Lolly__Domain__NA__User__IsNotFoundByCriteria') {
						this.authenticationService.logout();
					}
				}
			);
	}

	public openCreditModal(): void {
		this.refreshToken();
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		if (this.shakespeareService.isUserPro$.value) {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		} else {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		}
		dialogRef.afterClosed().subscribe(obj => {});
	}

	public resetFav(data: any): void {
		data.forEach(element => {
			element.isFav = false;
		});
	}

	public getAdCopyData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateAdCopyData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isDataFetched.emit(true);
						this.isLoadingData = false;
						let data = [...response['body']['output']];
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isDisliked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}
	public onNoCreditsLeft() {
		this.noCredits = true;
	}

	public getEmailData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateEmailData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						this.isDataFetched.emit(true);
						let data = [...response['body']['data']];
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isDisliked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public getDescriptionData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateProductDescriptionData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data']];
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isDisliked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateBlogData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateBlogData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let type = this.formData['data']['userInput']['blog_type'];
						this.isDataFetched.emit(true);
						let data = this.getBlogTypeResult(type, response);
						if (type == 'blog') {
							this.fullBlogCase = true;
							data = [data];
						} else {
							this.fullBlogCase = false;
							if (typeof data[0] == 'string') {
								data = this.toObject(data);
							}
						}
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isDisliked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateEssayData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateEssayData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let type = this.formData['data']['user_input']['essay_type'];
						this.isDataFetched.emit(true);
						let data = this.getEssayTypeResult(type, response);
						if (type == 'essay') {
							this.fullBlogCase = true;
							data = [data];
						} else {
							this.fullBlogCase = false;
							if (typeof data[0] == 'string') {
								data = this.toObject(data);
							}
						}
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isDisliked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].user_input.num_outputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateContentData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateContentData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						this.isDataFetched.emit(true);
						let data = [...response['body']['output']];
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isDisliked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateSocialData(): void {
		this.isFormSubmitted = true;
		if (this.formData['data'].userTemplate.template == 'Hashtags') {
			this.generateHashTagData();
			return;
		}
		if (this.formData['data'].userTemplate.platform == 'Instagram') {
			this.generateInstagramData();
			return;
		}
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateSocialData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data']];
						this.isDataFetched.emit(true);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateInstagramData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		delete this.formData['data'].userInput.keywords;
		this.userServiceApi
			.generateInstagramData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data']];
						this.isDataFetched.emit(true);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateHashTagData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateHashTagData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data']];
						this.isDataFetched.emit(true);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	public generateVideoData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateVideoData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data'][this.selectedTemplate.type]];
						this.isDataFetched.emit(true);
						data = this.toObject(data);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				error: () => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				complete: () => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			});
	}

	public generateFrameworkData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateFrameworkData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data']];
						this.isDataFetched.emit(true);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}
	public generateSeoData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateSeoData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['data']];
						this.isDataFetched.emit(true);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}
	public generateBioData(): void {
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		this.selectedTemplate = this.formData['extras'];
		delete this.formData['extras'];
		this.userServiceApi
			.generateBioData(this.formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage === 'Not enough credit') {
							this.openCreditModal();
							return;
						}
						this.isLoadingData = false;
						let data = [...response['body']['output']];
						this.isDataFetched.emit(true);
						let patchData = [...data, ...temp];
						for (let item of patchData) {
							item['id'] = this.idCounter++;
							item['isFav'] = false;
							item['isLiked'] = false;
							item['isNew'] = false;
						}
						let count = this.formData['data'].userInput.numOutputs;
						for (let item of patchData) {
							if (count == 0) break;
							item['isNew'] = true;
							count--;
						}
						this.generatedData.push(...patchData);
						this.callbackData.emit(this.generatedData);
						this.sortData();
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				() => {
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				}
			);
	}

	getBlogTypeResult(type: string, response: any) {
		// this type is blog_type word.toLowerCase() of selected template
		switch (type) {
			case 'blog':
				return response['body']['data'];
			case 'paragraph':
				return response['body']['data']['paragraphs'];
			case 'outline':
				return response['body']['data']['outline'];
			case 'conclusion':
				return response['body']['data']['conclusion'];
			case 'headlines':
				return response['body']['data']['title'];
			case 'ideas':
				return response['body']['data']['blogIdeas'];
			case 'introduction':
				return response['body']['data']['introduction'];
			default:
				break;
		}
	}

	getEssayTypeResult(type: string, response: any) {
		// this type is blog_type word.toLowerCase() of selected template
		switch (type) {
			case 'essay':
				return response['body']['data'];
			case 'paragraph':
				return response['body']['data']['paragraphs'];
			case 'outline':
				return response['body']['data']['outline'];
			case 'conclusion':
				return response['body']['data']['conclusion'];
			case 'headlines':
				return response['body']['data']['title'];
			case 'ideas':
				return response['body']['data']['essayIdeas'];
			case 'introduction':
				return response['body']['data']['introduction'];
			default:
				break;
		}
	}

	public toObject(array: any[]) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	public addToFav(idx: number): void {
		if (this.generatedData[idx]['isFav'] === true) {
			this.generatedData[idx]['isFav'] = false;
		} else {
			this.generatedData[idx]['isFav'] = true;
		}
		this.callbackData.emit(this.generatedData);
		this.sortData(false);
	}

	public sortData(emitDataGenerated = true): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.generatedData) {
			if (template?.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.generatedData = [...sortedList];
		if (emitDataGenerated) {
			this.generateDataComplete.emit(this.generatedData);
		}
	}

	public addToHistory(data: any, name: string): void {
		// delete data.id;
		let feedbackData = {
			user: {
				userFiledId: this.filedId?.toString()
			},
			data: {
				template_data: data,
				template_name: name
			}
		};
		this.userServiceApi
			.addTemplateHistory(feedbackData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {},
				() => {},
				() => {}
			);
	}

	public addToLiked(id: string, item: any, idx: number): void {
		if (this.generatedData[idx]['isLiked'] === true) {
			this.generatedData[idx]['isLiked'] = false;
		} else {
			this.generatedData[idx]['isLiked'] = true;
			this.generatedData[idx]['isDisliked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'like'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					response => {},
					() => {},
					() => {}
				);
		});
	}

	public addToDisliked(id: string, item: any, idx: number): void {
		if (this.generatedData[idx]['isDisliked'] === true) {
			this.generatedData[idx]['isDisliked'] = false;
		} else {
			this.generatedData[idx]['isDisliked'] = true;
			this.generatedData[idx]['isLiked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'dislike'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public async copyWithStyle(element: string): Promise<void> {
		const text = <HTMLElement>document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);
			selection.removeAllRanges();
			selection.addRange(range);
		}
		document.execCommand('copy');
		this.copyHelper = await navigator.clipboard.readText();
		window.getSelection().removeAllRanges();
	}

	public addToCopied(id: string, item: any, index: number): void {
		this.copyWithStyle(id).then(() => {
			clearTimeout(this.timer);
			this.isCopied = true;
			this.currentCopiedEl = index;
			this.timer = setTimeout(() => {
				this.isCopied = false;
			}, 3000);
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'copy'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	ngOnDestroy(): void {
		if (this.generatedData && this.generatedData.length) {
			let temp = JSON.parse(JSON.stringify(this.generatedData));
			let data = temp.map((item, index) => {
				item.projectData = JSON.parse(JSON.stringify(this.formData['data']));
				let output = this.generatedData[index];
				item.helperId = JSON.parse(JSON.stringify(item.id));
				delete item.id;
				item.projectData['userOutput'] = output;
				return item;
			});
			this.addToHistory(data, this.selectedTemplate?.title);
		}
		this.isDataFetched.emit(false);
	}

	public delete(id: string, item: any) {
		const found = this.generatedData.findIndex(ele => ele.id === item.id);
		if (found >= 0) {
			this.generatedData.splice(found, 1);
			this.callbackData.emit(this.generatedData);
		}
		if (this.generatedData.length === 0) {
			this.isDataFetched.emit(false);
		}
		// });
	}
}
