import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FiledLogoComponent } from '../shared/filed-logo/filed-logo.component';
import { KnowledgebaseComponent } from './component-pieces/knowledgebase/knowledgebase.component';
import { TicketsViewComponent } from './tickets-view/tickets-view.component';
import { SidenavGroupComponent } from './sidenav/sidenav-group.component';
import { NavFilterPipe } from './sidenav/nav-filter.pipe';
import { NewSignupModule } from '../authentication/new-signup/new-signup.module';
import { ShakespeareModule } from '../shakespeare/shakespeare.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsDialogComponent } from './component-pieces/notifications-dialog/notifications-dialog.component';
import { NotificationsListComponent } from '../shared/notifications-list/notifications-list.component';

@NgModule({
	declarations: [SidenavGroupComponent, FiledLogoComponent, KnowledgebaseComponent, TicketsViewComponent, NavFilterPipe, NotificationsDialogComponent],
	imports: [SharedModule, TranslateModule, BrowserModule, RouterModule, NewSignupModule, ShakespeareModule, NgbTooltipModule],
	providers: []
})
export class SidenavModule {}
