import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
	@Input() public control: FormControl = new FormControl();
	@Input() public inputPlaceholder: string = '';
	@Output() public searchButtonClicked: EventEmitter<string> = new EventEmitter<string>();
	@Input() public maxLength: any;
	@Input() public styleClass: string = '';
	@Input() public inputStyleClass: string = '';
	@Input() public searchIconUrl: string;

	constructor() {
		this.inputPlaceholder = this.inputPlaceholder ? this.inputPlaceholder : 'Search by name';
	}

	ngOnInit(): void {}

	public searchClick($event: any): void {
		this.searchButtonClicked.emit(this.control.value);
	}
}
