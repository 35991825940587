import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, getIsFirstTimeCustomer } from './authentication.reducer';
import { AuthenticationActionType, LogIn, LogInFailure, LogInSuccess, ShowOtpInput } from './authentication.action';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { of } from 'rxjs';
import { TokenService } from '../../_services/token.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { UserState } from '../../shared/state/user/user.reducer';
import { SetUserDetails } from '../../shared/state/user/user.actions';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { AppStateSlices } from '../../state/app.state';
import { ErrorsLoggingService } from '../../_services/errors-logging/errors-logging.service';
import { AuthenticationEffectsEnum } from './effects.enum';
import { UserRejectedReason } from '../../_services/user/user-rejected-reason.enum';
import { MiscellaneousPermissions } from '../../shared/permisions/enums/miscellaneous-permissions';
import { Modules } from '../../shared/permisions/enums/modules';
// import { GenericPopupComponent } from 'src/app/shared/pop-ups/generic-popup/generic-popup.component';
import { PopUpInterface } from 'src/app/shared/models/generic-popup-interface';
import { MatDialog } from '@angular/material/dialog';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';

@Injectable()
export class AuthenticationEffects {
	private launchHandler: NodeJS.Timeout;
	public constructor(
		private router: Router,
		private actions$: Actions,
		private userServiceApi: UserServiceApi,
		private tokenService: TokenService,
		private authenticationService: AuthenticationService,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>,
		private userStore: Store<UserState>,
		private errorsLoggingService: ErrorsLoggingService,
		private matDialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private shakespeareService: ShakespeareService
	) {}

	logIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LogIn>(AuthenticationActionType.LogIn),
			map(action => action.payload),
			mergeMap(payload =>
				payload
					? this.userServiceApi.authenticate(payload.email, payload.password, payload.code).pipe(
							map(result => new LogInSuccess(result)),
							catchError(error => {
								this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
								return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							})
					  )
					: this.userServiceApi.refreshUserToken().pipe(
							map(result => new LogInSuccess(result)),
							catchError(error => {
								this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
								return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							})
					  )
			)
		)
	);

	logInSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogInSuccess>(AuthenticationActionType.LogInSuccess),
				map(action => action),
				mergeMap(payload => this.runOp(payload))
			),
		{ dispatch: false }
	);

	private runOp(payload): any {
		if (payload.payload === 'True') {
			this.authStore.dispatch(new ShowOtpInput(true));
			return;
		}

		this.shakespeareService.autoScrolled$.next(false);
		if (payload.payload === 'False') {
			this.authenticationService.logout();
			this.toastNotificationService.sendErrorToast(
				'There was an issue with sending out a message. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
			);
			return;
		}
		const token = payload.payload;
		localStorage.setItem(StorageKey.token, token);
		const decodedJwtIo = this.tokenService.decodeToken(token);
		const userDetails = this.authenticationService.getUserDetailsFromJwt(decodedJwtIo);

		if (userDetails?.RejectedReason === UserRejectedReason.FrontOfficeUserWithZeroAdAccounts) {
			this.authenticationService.logout();
			this.toastNotificationService.sendErrorToast(
				'There was an issue with syncing your data. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
			);
			return;
		}

		localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(decodedJwtIo));
		this.userStore.dispatch(new SetUserDetails(userDetails));
		switch (userDetails.AccountState) {
			case UserStateEnum.Active:
				localStorage.removeItem('details');
				let hasBackOfficePermission = false;
				let backOfficeDefaultUrl = 'back-office';
				userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
					module.permissions.forEach(permission => {
						if (
							permission === MiscellaneousPermissions.IsAdmin ||
							permission === MiscellaneousPermissions.IsAccountManagerAdmin ||
							permission === MiscellaneousPermissions.IsSales ||
							permission === MiscellaneousPermissions.IsSalesAdmin ||
							permission === MiscellaneousPermissions.IsAccountManager
						) {
							if (permission === MiscellaneousPermissions.IsSales) {
								backOfficeDefaultUrl = 'back-office/role';
							}
							hasBackOfficePermission = true;
						}
					});
				});

				let isAdmin = userDetails.Permissions.filter(module => module.module === Modules.BackOffice);
				if (isAdmin.length > 0) {
					this.router.navigate(['back-office']);
					break;
				}

				const bigCommerceReload = this.authenticationService.bigCommerceAction$.value;

				if (hasBackOfficePermission) {
					// this.router.navigate([backOfficeDefaultUrl]);
					this.router.navigate(['back-office']);
				} else if (bigCommerceReload) {
					// this.bigCommerceReloadAction();
					this.authenticationService.bigCommerceAction$.next(null);
				} else {
					this.authStore.pipe(select(getIsFirstTimeCustomer), take(1)).subscribe(isFirstTimeCustomer => {
						const checkSignUpGetStarted = this.backOfficeService.signUpGetStartedActive$.value;
						if (checkSignUpGetStarted !== 'active') {
							this.router.navigate(['']);
						} else if (checkSignUpGetStarted === 'active') {
							this.backOfficeService.signUpGetStartedActive$.next('running');
						}
					});
				}
				if (userDetails.shopifyDetailAvailable == 'true') {
					this.shakespeareService.shopifyUser$.next(true);
					userDetails.PlatformName == 'shopify' ? this.router.navigate(['shopify']) : this.router.navigate(['']);
				}
				if (userDetails.BigCommerceDetailAvailable == 'true') {
					this.shakespeareService.bigCommerceUser$.next(true);
					this.router.navigate(['big-commerce']);
				}
				break;
			case UserStateEnum.NoBusinessOwner:
				this.router.navigate(['']);
				break;
			case UserStateEnum.NoCreditCard:
				this.router.navigate(['authentication/payment']);
				break;
			case UserStateEnum.FreemiumExpiredNoCreditCard:
				this.router.navigate(['authentication/trial-end']);
				break;
			case UserStateEnum.FreeTrialExpiredNoCreditCard:
				this.router.navigate(['authentication/payment']);
				break;
			case UserStateEnum.BillPendingRestrictedAccess:
				this.router.navigate(['']);
				break;
			case UserStateEnum.BillPendingUnrestrictedAccess:
				let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
				if (token && token.sign_up_version && token.sign_up_version == 'v2' && token.user_account_state == '7') {
					this.router.navigate(['/authentication/enterprise']);
				} else if (token && token.sign_up_version && token.sign_up_version == 'v3' && token.user_account_state == '7') {
					this.router.navigate(['/authentication/v3enterprise']);
				} else {
					this.router.navigate(['/authentication/free-trial']);
				}
				// for 7 day free trial users
				// this.router.navigate(['authentication/free-trial']);
				break;
			default:
				this.toastNotificationService.sendErrorToast('Something went wrong! Please log in again');
				localStorage.removeItem('details');
				this.authenticationService.logout();
		}
	}

	logInFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogInFailure>(AuthenticationActionType.LogInFailure),
				tap(err => {
					if (err.payload === 'Lolly__Domain__NA__UserAccount__IsNotVerified') {
						this.toastNotificationService.sendErrorToast('Please check your email and verify your account first.');
					} else {
						this.toastNotificationService.sendErrorToast('Invalid email and password combination');
					}
				})
			),
		{ dispatch: false }
	);

	// private bigCommerceReloadAction(): void {
	// 	this.launchHandler = setTimeout(() => {
	// 		this.router.navigate(['']);
	// 		window.open(window.location.origin + '/accounts', '_blank').focus();
	// 		this.matDialog.closeAll();
	// 	}, 10000);
	// 	this.matDialog
	// 		.open<GenericPopupComponent, PopUpInterface>(GenericPopupComponent, {
	// 			data: {
	// 				message: 'Your Filed dashboard will launch in the next tab in 10 seconds, please allow popup from www.filed.com',
	// 				actionAnswer: 'Launch now',
	// 				noActionAnswer: '',
	// 				header: 'Setup Installation Successful',
	// 				image: '/assets/icons/success.svg'
	// 			},
	// 			panelClass: ['generic-popup'],
	// 			disableClose: true
	// 		})
	// 		.afterClosed()
	// 		.subscribe(clicked => {
	// 			if (clicked) {
	// 				clearTimeout(this.launchHandler);
	// 				this.router.navigate(['']);
	// 				window.open(window.location.origin + '/accounts', '_blank').focus();
	// 				this.matDialog.closeAll();
	// 			}
	// 		});
	// }
}
