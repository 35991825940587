<div class="grid-section">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-gap-left"></div>
		<div class="grid-section-two-inner container">
			<div class="reset-form-outside">
				<div class="filed-logo">
					<!-- <img alt="Lolly" src="assets/logo/lolly-logo.svg" /> -->
					<img class="logo-shakespeare" src="assets/logo/shakespeare-full-logo.svg" />
				</div>
				<div [ngSwitch]="switchDivSection">
					<div *ngSwitchDefault>
						<div class="text-description">
							<h2 class="">Reset Your Password</h2>
							<p class="margin-bottom-spacing">
								Please enter the email address you'd like your password reset information sent to.
							</p>
						</div>
						<form>
							<div class="full-text-input">
								<!-- <div class="label">{{ 'Email Address' }}</div>
								<app-text-input [inputPlaceholder]="'Enter your email'"
									[control]="resetEmailFormGroup.get('resetEmailFormControl')"
									[errorMessage]="'Enter a valid email'" class="title-input"></app-text-input> -->
								<app-mat-input-text
									[label]="'Email Address'"
									[errorMessage]="'Enter a valid email'"
									[control]="resetEmailFormGroup.get('resetEmailFormControl')"
								></app-mat-input-text>
							</div>
							<div (click)="onSubmit()" class="signin-btn-wrapper">
								<div class="btn">
									<div class="items">
										<div class="text">{{ 'Reset Password' }}</div>
									</div>
								</div>
							</div>
							<a routerLink="/authentication">
								<p class="back-arrow-flex backLink">
									<i class="fas fa-angle-left back-arrow"></i>
									<span>Back to Log In</span>
								</p>
							</a>
						</form>
					</div>
					<div *ngSwitchCase="'resendEmailVerification'">
						<div class="text-description">
							<h2 class="margin-bottom-spacing">Check your email</h2>
							<!-- <div class="mail-icon"><img src="assets/icons/mail-icon.svg" /></div> -->
							<p class="margin-bottom-spacing">
								We sent an email to
								<span class="font-bold">{{ resetEmailInput }}</span>
								with a link to get back into your account.
							</p>
							<!-- <p class="margin-bottom-spacing">	If you are registered as Lolly user, you will receive an email with further instructions on how to reset your password</p> -->
							<p class="margin-bottom-spacing">
								<a class="link mr-5px" (click)="goTo('resetEmailAddress')">Click here</a>
								to change the provided email
							</p>
							<form>
								<div class="margin-bottom-spacing">
									<div (click)="resendOnsubmit()" class="signin-btn-wrapper">
										<div class="btn">
											<div class="items">
												<div class="text">{{ 'Send again' }}</div>
											</div>
										</div>
									</div>
								</div>
							</form>
							<a [routerLink]="'/'">
								<p class="back-arrow-flex backLink">
									<i class="fas fa-angle-left back-arrow"></i>
									<span>Back to Log In</span>
								</p>
							</a>
						</div>
					</div>
					<div *ngSwitchCase="'resetEmailAddress'">
						<div class="text-description">
							<h2 class="margin-bottom-spacing">Change your email</h2>
							<!-- <div class="mail-icon">
																																																																														<img src="assets/icons/mail-icon.svg" />
																																																																													</div> -->
							<p class="margin-bottom-spacing">
								We sent an email to
								<span class="font-bold">{{ resetEmailInput }}</span>
							</p>
							<!-- <p class="margin-bottom-spacing">If you did not receive any confirmation email, try again in a minute or introduceanother email address below.</p> -->
							<form>
								<div class="margin-bottom-spacing">
									<tb02
										[control]="resetEmailFormGroup.get('resetEmailFormControl')"
										label="Email"
										class="input-box"
										placeHolderTextBox="Enter your email address"
									></tb02>
								</div>
								<div class="margin-bottom-spacing">
									<app-button buttonClass="primary" (onClick)="onSubmit()">Send</app-button>
								</div>
							</form>

							<a href="authentication">
								<p class="filed-blue-text back-arrow-flex backLink">
									<i class="fas fa-angle-left back-arrow"></i>
									<span>Back to Log In</span>
								</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="grid-gap-right"></div>
	</div>
</div>
<app-toast-notification></app-toast-notification>
