import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { CommonPopupWelcomeComponent } from '../common-popup-welcome/common-popup-welcome.component';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';
import { CancelModalService } from 'src/app/shakespeare/settings/modals/cancel-modal.service';
declare const FB: any;

@Component({
	selector: 'app-facebook-ads-popup',
	templateUrl: './facebook-ads-popup.component.html',
	styleUrls: ['./facebook-ads-popup.component.scss']
})
export class FacebookAdsPopupComponent implements OnInit {
	loading: boolean = false;

	activeStep = 1;
	selectedcount = 0;
	// googleInput = new FormControl();
	inputError = false;
	// loading = true;
	showButton = true;
	connecting = true;
	fbCustomerArray: { id: any; name: string; selected: boolean }[];
	backup: { id: string; name: string; selected: boolean }[];
	extensionValues: { adAccount: string; aiCategory: string } = { adAccount: '', aiCategory: '' };
	fromExtension = false;
	selectedGoogleId: string;
	categories: any;
	navigateToWelcomePage: boolean;
	isLoggedIn: boolean = false;
	buttonClicks: boolean = false;
	setloading: boolean = false;
	platformDefault: string;
	clickedPlatform: any;
	hideUpgrade: boolean = false;
	loadingPlatform: boolean;

	constructor(
		public dialogRef: MatDialogRef<FacebookAdsPopupComponent>,
		private router: Router,
		private backOfficeService: BackOfficeService,
		private adsService: AdsService,
		private cancelModalService: CancelModalService
	) {}

	ngOnInit(): void {}

	closeFacebookPopup() {
		this.dialogRef.close(false);
	}

	openCommonPopup() {
		// this.loading = true;
		let payload = 'facebook';
		this.backOfficeService.defaultSelectedPlatform(payload).subscribe(res => {
			// console.log(res);
			// this.connectFacebook();

			this.dialogRef.close(true);
		});
	}

	public connectFacebook() {
		FB.login(
			(response: any) => {
				this.isLoggedIn = true;
				if (response.status === 'connected') {
					// this.validateFbAccessToken(response);
					this.statusChangeCallback(response);
					// this.isLoggedIn = true;
				}
			},
			// {
			// 	scope: 'catalog_management,ads_management,business_management,pages_manage_ads'
			// }
			{
				scope: 'catalog_management,ads_management,ads_read,business_management,email'
			}
		);
	}

	private statusChangeCallback(response: any) {
		this.activeStep = 2;
		if (response.authResponse === null) {
			this.isLoggedIn = false;
			return;
		}
		this.isLoggedIn = true;
		if (response.status !== 'connected') return;
		let payload = {
			AccessToken: response.authResponse.accessToken,
			ExpireAt: response.authResponse.expiresIn,
			SignedRequest: response.authResponse.signedRequest,
			UserId: response.authResponse.userID
		};

		this.adsService.sendFbCode(payload).subscribe({
			next: (res: any) => {
				this.adsService.validateFbAccessToken().subscribe({
					next: (res: any) => {
						if (!res) {
							// this.loading = false;
							this.connecting = false;
							this.adChannel();
							// this.activeStep = 1;
						} else {
							this.getAdCategory();
							this.getFbAdIDs();
						}
					},
					error: err => {
						// this.loading = false;
						this.connecting = false;
						if (err.error[0].code == 'Lolly__Domain__NA__User_Bill__IsNullOrEmpty') {
							this.cancelModalService.openCancelModaldummy('Your plan has been expired, Please contact your product manager.');
						}
						console.log(err);
					}
				});
			}
		});
	}

	adChannel() {
		this.adsService.adChannel('facebook').subscribe({
			next: (res: any) => {
				if (res) {
					this.showButton = true;
					this.activeStep = 1;
				} else {
					this.showButton = false;
					this.activeStep = 1;
				}
			}
		});
	}

	getAdCategory() {
		this.adsService.getFbCategoriesIDs().subscribe({
			next: (res: any) => {
				this.connecting = false;
				if (res) {
					if (res.data.length == 0) {
						this.navigateToWelcomePage = true;
						// this.router.navigate(['/ads/facebook/welcome']);
					} else {
						this.categories = res.data;
						if (localStorage.getItem('FbAccountId')) {
							this.router.navigate(['/ads/facebook/dashboard']);
						}
					}
				}
			}
		});
	}

	getFbAdIDs(): void {
		// this.loading = true;
		this.adsService.addFbAccounts().subscribe({
			next: res => {
				// this.adaccountPagination(res.paging.cursors.after);
				this.fbCustomerArray = [];
				let dummyArray = [];

				if (res.data) {
					res.data.forEach(item => {
						dummyArray.push({
							id: item.id.slice(4),
							name: item.name
						});
					});
				}
				this.fbCustomerArray = dummyArray;
				this.fbCustomerArray.forEach(item => {
					item['selected'] = false;
				});
				dummyArray.forEach(item => {
					item['selected'] = false;
				});
				this.backup = dummyArray;
				// this.loading = false;
			},
			error: err => {
				if (err.error[0].code == 'Lolly__Api__User_Subscription__AlreadyCanceled') {
					this.cancelModalService.openCancelModaldummy('In order to use this function please speak to your account manager to upgrade your plan.');
					this.router.navigate(['/settings/billing']);
				}
				console.error(err);
			}
		});
	}
}
