<div [ngClass]="{ container: !isSmall, 'container-small': isSmall }" class="center">
	<div class="icon-container" *ngIf="!iconClass">
		<img src="assets/icons/!-filed-coral.svg" alt="icon" />
	</div>
	<div class="icon-container" *ngIf="iconClass">
		<i class="{{ iconClass }}"></i>
	</div>
	<div class="warning-title" *ngIf="name">{{ name | translate }}</div>
	<div class="audience-description">{{ description | translate }}</div>
	<div class="btn-custom">
		<div fxLayout="row wrap">
			<app-button
				(buttonClicked)="button.functionality()"
				*ngFor="let button of buttons"
				[buttonClass]="buttonClassEnum.Primary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="{{ button.name | translate }}"
			></app-button>
		</div>
	</div>
</div>
