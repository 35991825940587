import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-folder-data',
	templateUrl: './folder-data.component.html',
	styleUrls: ['./folder-data.component.scss']
})
export class FolderDataComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
