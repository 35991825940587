<!--dropdown select -->
<ng-select
	#ngSelect
	(window:resize)="onResize($event, ngSelect)"
	[isOpen]="!data?.length ? false : null"
	[formControl]="getControl()"
	[readonly]="readOnly"
	class="ng-select custom"
	[items]="data"
	(scroll)="onScroll($event)"
	(scrollToEnd)="onScrollToEnd()"
	[searchable]="searchEnable"
	[bindLabel]="labelProperty"
	[clearSearchOnAdd]="false"
	[closeOnSelect]="true"
	[clearable]="!hideClearButton"
	(focus)="autocompleteFocus()"
	(focusout)="setErrorMessage()"
	(change)="onChange($event)"
	(open)="dropdownOpened($event)"
	[multiple]="multiple"
	(close)="onDropdownClose()"
	addTagText="Add "
	[addTag]="createNew"
	[compareWith]="compare"
	[class.no-typing]="!useCustom"
	[style.border]="borderColor ? '1px solid ' + borderColor : null"
	[style.box-shadow]="borderColor ? '0 0 0 4px #feb9c7' : 'none'"
	[style.border-radius]="borderColor ? '0.25rem' : null"
	[class.iphone-css]="iPhoneMobile"
	[ngClass]="{
		'input-error-message': !dropdownFormControl.value && dropdownFormControl.touched,
		'input-success-message': dropdownFormControl.valid && dropdownFormControl.touched
	}"
	[ngClass]="{ fromOptimise: fromOptimise, custom: !fromOptimise, 'multiple-input-custom': true }"
	notFoundText="{{ noDataFoundLabel }}"
	[placeholder]="placeHolder"
>
	<div>
		<ng-template (mouseleave)="onResize($event, ngSelect)" let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
			<div class="right-check" [class.iphone-css]="iPhoneMobile">
				<span [style.color]="dropdownFormControl?.value === item ? '#006bff' : '#3c4043'" class="dropdown-label">{{ item?.name }}</span>
				<span *ngIf="dropdownFormControl?.value === item" style="color: #006bff;"><i class="fas fa-check"></i></span>
			</div>
		</ng-template>
	</div>
	<!-- <div *ngIf="multiple">
		<ng-template ng-multi-label-tmp let-items="items">
			<div class="ng-value" *ngIf="items.length > 0">
				<span class="ng-value-label">{{items.length}}</span>
			</div>
		</ng-template>
		<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
			<input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
			{{item.name}}
		</ng-template>
	</div> -->
</ng-select>
