import { EssayGeneratorComponent } from './templates/essay-generator/essay-generator.component';
import { FrameworkGeneratorComponent } from './templates/framework-generator/framework-generator.component';
import { SocialGeneratorComponent } from './templates/social-generator/social-generator.component';
import { ProductDescGeneratorComponent } from './templates/product-desc-generator/product-desc-generator.component';
import { GeneratorOutputComponent } from './templates/generator-output/generator-output.component';
import { GeneratorHistoryComponent } from './templates/generator-history/generator-history.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShakespeareRoutingModule } from './shakespeare-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TemplatesComponent } from './templates/templates.component';
import { StoreModule } from '@ngrx/store';
import { ShakespeareCommonReducer } from '../state/shakespeare-common-state/shakespeare-common-reducer';
import { IconsModule } from '@progress/kendo-angular-icons';
import { AdCopyGeneratorComponent } from './templates/ad-copy-generator/ad-copy-generator.component';
import { EmailGeneratorComponent } from './templates/email-generator/email-generator.component';
import { LibraryComponent } from './library/library.component';
import { ViewTemplateComponent } from './view-template/view-template.component';
import { BlogsGeneratorComponent } from './templates/blogs-generator/blogs-generator.component';
import { ContentGeneratorComponent } from './templates/content-generator/content-generator.component';
import { ProjectsComponent } from './projects/projects.component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { ProjectDataComponent } from './project-data/project-data.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { BlogWizardGeneratorComponent } from './templates/blog-wizard-generator/blog-wizard-generator.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TrashComponent } from './trash/trash.component';
import { FreeTrialComponent } from './free-trial/free-trial.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AdCopyFormComponent } from './components/ad-copy-form/ad-copy-form.component';
import { BlogGeneratorFormComponent } from './components/blog-generator-form/blog-generator-form.component';
import { ContentGeneratorFormComponent } from './components/content-generator-form/content-generator-form.component';
import { EmailGeneratorFormComponent } from './components/email-generator-form/email-generator-form.component';
import { FrameworkFormComponent } from './components/framework-form/framework-form.component';
import { SocialFormComponent } from './components/social-form/social-form.component';
import { ProductDescFormComponent } from './components/product-desc-form/product-desc-form.component';
import { FolderDataComponent } from './folder-data/folder-data.component';
import { ShopifyModule } from './shopify/shopify.module';
import { QuillModule } from 'ngx-quill';
import { ShopifyBillingComponent } from './shopify-billing/shopify-billing.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { OfferModalComponent } from './templates/offer-modal/offer-modal.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { SeoGeneratorComponent } from './templates/seo-generator/seo-generator.component';
import { SeoFormComponent } from './components/seo-form/seo-form.component';
import { BioGeneratorComponent } from './templates/bio-generator/bio-generator.component';
import { BioGeneratorFormComponent } from './components/bio-generator-form/bio-generator-form.component';
import { VideoGeneratorComponent } from './templates/video-generator/video-generator.component';
import { VideoFormComponent } from './components/video-form/video-form.component';
import { BigCommerceModule } from './big-commerce/big-commerce.module';
import { EcommercePlansComponent } from './ecommerce-plans/ecommerce-plans.component';
import { CreativeComponent } from './creative/creative.component';
import { AdsComponent } from './ads/ads.component';
import { GoogleGlobalAuthComponent } from './google-global-auth/google-global-auth.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { WelcomeShakespeareFormComponent } from './components/welcome-shakespeare-form/welcome-shakespeare-form.component';
import { V3enterpriseComponent } from '../authentication/v3enterprise/v3enterprise.component';
import { GoogleAdsPopupComponent } from './components/welcome-shakespeare-form/google-ads-popup/google-ads-popup.component';
import { FacebookAdsPopupComponent } from './components/welcome-shakespeare-form/facebook-ads-popup/facebook-ads-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonPopupWelcomeComponent } from './components/welcome-shakespeare-form/common-popup-welcome/common-popup-welcome.component';
import { SlackGlobalAuthComponent } from './slack-global-auth/slack-global-auth.component';
import { NotificationComponent } from './notification/notification.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorPageComponent } from './error-page/error-page.component';

@NgModule({
	declarations: [
		TemplatesComponent,
		EmailGeneratorComponent,
		AdCopyGeneratorComponent,
		GeneratorHistoryComponent,
		GeneratorOutputComponent,
		ProductDescGeneratorComponent,
		SeoGeneratorComponent,
		LibraryComponent,
		ViewTemplateComponent,
		BlogsGeneratorComponent,
		ContentGeneratorComponent,
		ProjectsComponent,
		SocialGeneratorComponent,
		FrameworkGeneratorComponent,
		ProjectDataComponent,
		TextEditorComponent,
		BlogWizardGeneratorComponent,
		EssayGeneratorComponent,
		TrashComponent,
		FreeTrialComponent,
		BackofficeComponent,
		AdCopyFormComponent,
		BlogGeneratorFormComponent,
		ContentGeneratorFormComponent,
		EmailGeneratorFormComponent,
		FrameworkFormComponent,
		SocialFormComponent,
		ProductDescFormComponent,
		SeoFormComponent,
		FolderDataComponent,
		ShopifyBillingComponent,
		IntegrationsComponent,
		OfferModalComponent,
		VideoGeneratorComponent,
		VideoFormComponent,
		BioGeneratorComponent,
		BioGeneratorFormComponent,
		OfferModalComponent,
		EcommercePlansComponent,
		CreativeComponent,
		AdsComponent,
		GoogleGlobalAuthComponent,
		EnterpriseComponent,
		WelcomeShakespeareFormComponent,
		V3enterpriseComponent,
		GoogleAdsPopupComponent,
		FacebookAdsPopupComponent,
		CommonPopupWelcomeComponent,
		SlackGlobalAuthComponent,
		NotificationComponent,
		ErrorPageComponent
	],
	exports: [
		GeneratorOutputComponent,
		GeneratorHistoryComponent,
		TextEditorComponent,
		AdCopyFormComponent,
		EmailGeneratorFormComponent,
		BlogGeneratorFormComponent,
		ContentGeneratorFormComponent,
		FrameworkFormComponent,
		SocialFormComponent,
		ProductDescFormComponent,
		VideoFormComponent,
		SeoFormComponent,
		BioGeneratorComponent,
		BioGeneratorFormComponent,
		CreativeComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		ShakespeareRoutingModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		CarouselModule,
		IconsModule,
		StoreModule.forFeature('templates', ShakespeareCommonReducer),
		DragToSelectModule,
		NgxPaginationModule,
		MatTableModule,
		MatTableExporterModule,
		ShopifyModule,
		QuillModule,
		DatePickerModule,
		BigCommerceModule,
		MatIconModule,
		NgxSkeletonLoaderModule
	]
})
export class ShakespeareModule {}
