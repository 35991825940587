<div class="popup-section">
	<div class="main-section" [class.trial]="dialogData?.isTrial">
		<div class="payment-container">
			<img src="assets/payment.svg" alt="" />
		</div>
		<p class="title">{{ dialogData?.isTrial ? 'Welcome to Shakespeare' : 'Thank you for your payment' }}</p>
		<p class="receipt" style="font-size: 16px; margin-top: 25px; color: #4e4e4e;">
			{{
				dialogData?.isTrial
					? 'Your 7 day free trial has begun, Step into the Future of Marketing!'
					: 'A Receipt for this transaction has been sent via email for your records'
			}}
		</p>
		<p class="total" *ngIf="!dialogData?.isTrial">Total Payment Amount</p>
		<p style="font-size: 36px;" *ngIf="!dialogData?.isTrial">${{ dialogData?.price }}</p>
		<p style="color: #3a3a3a; margin-top: 1.5rem;" *ngIf="!dialogData?.isTrial">Payment Message</p>
		<!-- <p class="ref">Payment Processed {{ ' ' + dialogData?.reference }}</p> -->
		<p class="redirect" *ngIf="!dialogData?.isTrial">You will be redirected in 5 seconds, please wait.</p>
	</div>
</div>
