<div class="spinning-loader">
	<svg class="circular" [class.rotating]="rotating" viewBox="25 25 50 50">
		<circle class="path" cx="50" cy="50" r="18" fill="none" stroke-width="3" stroke-miterlimit="10" />
	</svg>
	<svg id="shakespeare-icon" xmlns="http://www.w3.org/2000/svg" width="240.333" height="235.566" viewBox="0 0 240.333 235.566">
		<g id="Group_34247" data-name="Group 34247" transform="translate(-725.573 -25.579)">
			<path
				id="Polygon_119"
				data-name="Polygon 119"
				d="M105.2,0A32,32,0,0,1,132.97,16.092l22.917,40a32,32,0,0,1,0,31.815l-22.917,40A32,32,0,0,1,105.2,144H59.8A32,32,0,0,1,32.03,127.908l-22.917-40a32,32,0,0,1,0-31.815l22.917-40A32,32,0,0,1,59.8,0Z"
				transform="translate(738 122.896) rotate(-30)"
				fill="#fff"
			/>
			<path
				id="Union_29"
				data-name="Union 29"
				d="M9768.128,24313.381l-38.183-22.295a30.777,30.777,0,0,1-15.239-26.488l-.146-44.328a30.789,30.789,0,0,1,15.331-26.75l37.676-21.832a30.571,30.571,0,0,1,30.748.055l38.182,22.289a30.8,30.8,0,0,1,15.24,26.494l.146,44.328a30.771,30.771,0,0,1-15.332,26.744l-37.675,21.832a30.556,30.556,0,0,1-30.748-.049Z"
				transform="translate(-8937.774 -24098.561)"
				fill="#006bff"
			/>
			<path
				id="Polygon_124"
				data-name="Polygon 124"
				d="M70.737,2A37,37,0,0,0,38.655,20.569l-25.76,44.84a37,37,0,0,0,0,36.862l25.76,44.84a37,37,0,0,0,32.083,18.569h51.184A37,37,0,0,0,154,147.11l25.76-44.84a37,37,0,0,0,0-36.862L154,20.569A37,37,0,0,0,121.921,2H70.737m0-2h51.184a39,39,0,0,1,33.817,19.573l25.76,44.84a39,39,0,0,1,0,38.855l-25.76,44.84a39,39,0,0,1-33.817,19.573H70.737A39,39,0,0,1,36.92,148.106l-25.76-44.84a39,39,0,0,1,0-38.855l25.76-44.84A39,39,0,0,1,70.737,0Z"
				transform="translate(804.236 22.591) rotate(30)"
				fill="#006bff"
			/>
			<path
				id="Path_17917"
				data-name="Path 17917"
				d="M-17.126-25.549q.8,5.671,3.153,8.479,4.3,5.11,14.735,5.11a31.7,31.7,0,0,0,10.148-1.348q7.4-2.583,7.4-9.6a7.074,7.074,0,0,0-3.623-6.345q-3.624-2.19-11.492-3.875L-5.77-35.1q-13.21-2.92-18.151-6.345-8.367-5.728-8.367-17.913A23.789,23.789,0,0,1-24.1-77.827q8.186-7.356,24.045-7.356,13.244,0,22.6,6.935t9.806,20.131H15.723q-.461-7.468-6.678-10.613a22.871,22.871,0,0,0-10.3-2.078q-6.851,0-10.938,2.7a8.528,8.528,0,0,0-4.087,7.524,7.1,7.1,0,0,0,4.03,6.626q2.59,1.46,11,3.425l14.525,3.425q9.549,2.246,14.315,6.008,7.4,5.84,7.4,16.9A23.749,23.749,0,0,1,26.214-5.363q-8.769,7.5-24.774,7.5-16.346,0-25.71-7.384t-9.365-20.3Z"
				transform="translate(845.447 186.396)"
				fill="#fff"
			/>
		</g>
	</svg>
</div>

<!-- <div class="custom-loader"></div>
<svg id="shakespeare-icon" xmlns="http://www.w3.org/2000/svg" class="logo" width="240.333" height="235.566"
	viewBox="0 0 240.333 235.566">
	<g id="Group_34247" data-name="Group 34247" transform="translate(-725.573 -25.579)">
		<path id="Polygon_119" data-name="Polygon 119"
			d="M105.2,0A32,32,0,0,1,132.97,16.092l22.917,40a32,32,0,0,1,0,31.815l-22.917,40A32,32,0,0,1,105.2,144H59.8A32,32,0,0,1,32.03,127.908l-22.917-40a32,32,0,0,1,0-31.815l22.917-40A32,32,0,0,1,59.8,0Z"
			transform="translate(738 122.896) rotate(-30)" fill="#fff" />
		<path id="Union_29" data-name="Union 29"
			d="M9768.128,24313.381l-38.183-22.295a30.777,30.777,0,0,1-15.239-26.488l-.146-44.328a30.789,30.789,0,0,1,15.331-26.75l37.676-21.832a30.571,30.571,0,0,1,30.748.055l38.182,22.289a30.8,30.8,0,0,1,15.24,26.494l.146,44.328a30.771,30.771,0,0,1-15.332,26.744l-37.675,21.832a30.556,30.556,0,0,1-30.748-.049Z"
			transform="translate(-8937.774 -24098.561)" fill="#006bff" />
		<path id="Polygon_124" data-name="Polygon 124"
			d="M70.737,2A37,37,0,0,0,38.655,20.569l-25.76,44.84a37,37,0,0,0,0,36.862l25.76,44.84a37,37,0,0,0,32.083,18.569h51.184A37,37,0,0,0,154,147.11l25.76-44.84a37,37,0,0,0,0-36.862L154,20.569A37,37,0,0,0,121.921,2H70.737m0-2h51.184a39,39,0,0,1,33.817,19.573l25.76,44.84a39,39,0,0,1,0,38.855l-25.76,44.84a39,39,0,0,1-33.817,19.573H70.737A39,39,0,0,1,36.92,148.106l-25.76-44.84a39,39,0,0,1,0-38.855l25.76-44.84A39,39,0,0,1,70.737,0Z"
			transform="translate(804.236 22.591) rotate(30)" fill="#006bff" />
		<path id="Path_17917" data-name="Path 17917"
			d="M-17.126-25.549q.8,5.671,3.153,8.479,4.3,5.11,14.735,5.11a31.7,31.7,0,0,0,10.148-1.348q7.4-2.583,7.4-9.6a7.074,7.074,0,0,0-3.623-6.345q-3.624-2.19-11.492-3.875L-5.77-35.1q-13.21-2.92-18.151-6.345-8.367-5.728-8.367-17.913A23.789,23.789,0,0,1-24.1-77.827q8.186-7.356,24.045-7.356,13.244,0,22.6,6.935t9.806,20.131H15.723q-.461-7.468-6.678-10.613a22.871,22.871,0,0,0-10.3-2.078q-6.851,0-10.938,2.7a8.528,8.528,0,0,0-4.087,7.524,7.1,7.1,0,0,0,4.03,6.626q2.59,1.46,11,3.425l14.525,3.425q9.549,2.246,14.315,6.008,7.4,5.84,7.4,16.9A23.749,23.749,0,0,1,26.214-5.363q-8.769,7.5-24.774,7.5-16.346,0-25.71-7.384t-9.365-20.3Z"
			transform="translate(845.447 186.396)" fill="#fff" />
	</g>
</svg> -->
