import { takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
	selector: 'app-backoffice',
	templateUrl: './backoffice.component.html',
	styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent implements OnInit {
	private unsubscriber$ = new Subject<void>();
	public users = [];
	public backup = [];
	public loading = true;

	sortType = 'all';
	displayedColumns: string[] = [
		'userid',
		'userEmail',
		'accountState',
		'firstName',
		'lastName',
		'createdAt',
		'lastLogin',
		'currentPlan',
		'LTV',
		'MRR',
		'creditUsed',
		'firstPayment',
		'firstPaymentDate',
		'lastPayment',
		'lastPaymentDate',
		'marketingSource',
		'paymentPlatformCustomerId',
		'country',
		'paymentDetailAdded',
		'referenceLink',
		'UtmCampaign',
		'UtmMedium',
		'UtmSource',
		'UtmTerm',
		'ipLocation'
	];
	dataSource: any;
	startDate = new Date();
	endDate = new Date();

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	apiCount = 0;
	apiCallFinished = 0;
	totalRecords = 0;

	constructor(private backOfficeApiService: BackOfficeService) {}

	ngOnInit(): void {
		this.getUsers();
	}

	public getUsers(start = 1, end = 1000): void {
		this.loading = true;
		this.backOfficeApiService
			.getBackOfficeUsers(start, end)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.users.push(...response);
						this.backup.push(...response);
						this.totalRecords = response[0].totalRecords;
						this.apiCount = Math.ceil(this.totalRecords / 1000);
						this.apiCallFinished++;
						if (this.apiCount == this.apiCallFinished) {
							this.loading = false;
							this.dataSource = new MatTableDataSource<any>(this.users);
							this.dataSource.paginator = this.paginator;
							this.dataSource.sort = this.sort;
							return;
						}
						let end = 1000;
						this.getUsers(this.apiCallFinished * 1000 + 1, this.apiCallFinished * 1000 + end);
					}
				},
				() => {
					this.loading = false;
				}
			);
	}

	sortData(type: string): void {
		this.users = this.backup;
		switch (type) {
			case 'all':
				this.sortType = 'all';
				break;
			case 'today':
				this.sortType = 'today';
				this.users = this.users.filter(account => {
					let date = new Date(account.createdAt);
					let today = new Date();
					if (date.toDateString() === today.toDateString()) return account;
				});
				break;
			case 'yesterday':
				this.sortType = 'yesterday';
				this.users = this.users.filter(account => {
					let date = new Date(account.createdAt);
					let yesterday = new Date();
					yesterday.setDate(yesterday.getDate() - 1);
					if (date.toDateString() === yesterday.toDateString()) return account;
				});
				break;
			case 'week':
				this.sortType = 'week';
				this.users = this.users.filter(account => {
					let date = new Date(account.createdAt);
					let week = new Date();
					week.setDate(week.getDate() - 7);
					if (date.getTime() > week.getTime()) return account;
				});
				break;
			case 'month':
				this.sortType = 'month';
				this.users = this.users.filter(account => {
					let date = new Date(account.createdAt);
					let dateObj = new Date();
					let month = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
					if (date.getTime() > month.getTime()) return account;
				});
				break;
			case 'last_month':
				this.sortType = 'last_month';
				this.users = this.users.filter(account => {
					let date = new Date(account.createdAt);
					let month = new Date();
					month.setDate(month.getDate() - 30);
					if (date.getTime() > month.getTime()) return account;
				});
				break;
		}

		this.dataSource = new MatTableDataSource<any>(this.users);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	public filterData(): void {
		this.users = this.backup;
		this.sortType = '';
		this.users = this.users.filter(account => {
			let date = new Date(account.createdAt);
			if (this.startDate.getTime() <= date.getTime() && this.endDate.getTime() >= date.getTime()) return account;
		});
		this.dataSource = new MatTableDataSource<any>(this.users);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
