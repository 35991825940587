import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-bc-modal',
	templateUrl: './bc-modal.component.html',
	styleUrls: ['./bc-modal.component.scss']
})
export class BcModalComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<BcModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {}

	closeDialog(): void {
		this.dialogRef.close();
	}

	confirm(): void {
		this.dialogRef.close({
			confirm: true
		});
	}
}
