<div class="form-input" id="input-form-component">
	<kendo-formfield>
		<kendo-textbox
			[placeholder]="placeHolderTextBox"
			[disabled]="disabled"
			[readonly]="readOnly"
			[formControl]="inputControl"
			[showSuccessIcon]="inputControl.valid"
			[showErrorIcon]="'initial'"
			[style.width]="'100%'"
			[maxlength]="maxLength"
			(inputBlur)="onBlur($event)"
		>
			<ng-template *ngIf="showCustomIcon" kendoTextBoxSuffixTemplate>
				<img [ngStyle]="showIconStyle()" [src]="showCustomIcon" />
			</ng-template>
			<ng-template *ngIf="inputControl.valid && showValidIcon" kendoTextBoxSuffixTemplate></ng-template>
		</kendo-textbox>
		<kendo-formerror *ngIf="!hideError">{{ errorMessage }}</kendo-formerror>
	</kendo-formfield>
</div>

<!-- (valueChange)="kendoValuChangeEvent($event)" -->
