import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-circular-progress',
	templateUrl: './circular-progress.component.html',
	styleUrls: ['./circular-progress.component.scss']
})
export class CircularProgressComponent implements OnInit {
	@Input() value = 0;
	@Input() color = '#006BFF';
	Math = Math;
	constructor() {}

	ngOnInit(): void {}
}
