import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllProductsComponent } from './all-products/all-products.component';
import { ShopifyGeneratorComponent } from './shopify-generator/shopify-generator.component';
import { ShopifyComponent } from './shopify.component';

const routes: Routes = [
	{ path: '', component: ShopifyComponent },
	{ path: 'shopify/all-products', component: AllProductsComponent },
	{ path: 'generate', component: ShopifyGeneratorComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ShopifyRoutingModule {}
