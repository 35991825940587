import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { ViewProductComponent } from '../view-product/view-product.component';

@Component({
	selector: 'app-product-history',
	templateUrl: './product-history.component.html',
	styleUrls: ['./product-history.component.scss']
})
export class ProductHistoryComponent implements OnInit, OnChanges {
	@Input() products = [];
	@ViewChild('p') pagination;
	@Output() public pageNumber: EventEmitter<number> = new EventEmitter();
	radioForm: FormGroup;
	public config: PaginationInstance = {
		id: '1',
		itemsPerPage: 0,
		currentPage: 1,
		totalItems: 0
	};

	constructor(private router: Router, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.radioForm = new FormGroup({
			radio0: new FormControl(false)
		});
		for (let i = 0; i < this.products?.length; i++) {
			this.radioForm.addControl('radio' + i, new FormControl(false));
		}
	}

	ngOnChanges(): void {
		this.config.totalItems = this.products?.length;
		if (this.products?.length <= 35) {
			this.config.itemsPerPage = this.products?.length;
		} else {
			this.config.itemsPerPage = 35;
		}
		console.log(this.config);
		// console.log(this.pagination.pages)
	}

	checkAll(event: Event): void {
		let checked = event.currentTarget['checked'];
		if (checked) {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(true);
			});
		} else {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(false);
			});
		}
	}

	public previewProduct(item: object): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '85%',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '1053px',
				height: '763px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ViewProductComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		dialogRef.componentInstance.modelDetail = {
			product: item
		};
		dialogRef.afterClosed().subscribe(obj => {
			// console.log(obj);
		});
	}

	public setPage(page: number): void {
		this.config.id = page.toString();
		this.pageNumber.emit(page);
	}

	getData(page): void {
		console.log(page, typeof page);
		this.pageNumber.emit(page);
	}

	openEditor(id: number): void {
		this.router.navigate(['shopify/generate'], { queryParams: { productId: id, type: 'history' } });
	}
}
