import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { steps as defaultSteps, defaultStepOptions, STEPS_BUTTONS } from './tour-popup-config';
import { MatDialog } from '@angular/material/dialog';
@Component({
	selector: 'app-tour-popup',
	templateUrl: './tour-popup.component.html',
	styleUrls: ['./tour-popup.component.scss']
})
export class TourPopupComponent implements OnInit, AfterViewInit {
	constructor(private readonly _shepherdService: ShepherdService, private dialog: MatDialog) {}

	ngOnInit(): void {}

	start() {
		this.startTour();
	}

	ngAfterViewInit() {
		this._shepherdService.defaultStepOptions = defaultStepOptions;
		this._shepherdService.modal = true;

		this._shepherdService.addSteps(defaultSteps);
	}
	startTour() {
		this._shepherdService.start();
	}

	openTour() {
		this.dialog.open(TourPopupComponent, { width: '460px', height: '500px' });
	}
}
