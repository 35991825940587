<div class="main-container">
	<div *ngIf="!loading" class="search-results">
		<div *ngIf="tickets && tickets.length > 0" class="result-repeat">
			<ng-container *ngFor="let ticket of tickets; let i = index">
				<mat-divider></mat-divider>
				<div class="result">
					<div class="ticket-head">
						<h5>{{ ticket.subject }}</h5>
						<h6>
							{{ ticket.created_date | date: 'medium' }}
						</h6>
					</div>
					<div class="content">
						{{ shortenDescription(ticket.content) }}
					</div>
					<div class="chips">
						<mat-chip>Status: {{ ticket.status }}</mat-chip>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="loader" *ngIf="loading">
		<shakespeare-spinning-loader></shakespeare-spinning-loader>
	</div>
	<div *ngIf="!loading && (!tickets || tickets.length < 1)">
		<h6>**You do not have support tickets</h6>
	</div>
</div>
