import { BackOfficeService } from './../../../../_services/back-office/back-office.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { User } from 'src/app/_models/user-models/user';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Component({
	selector: 'app-submit-ticket',
	templateUrl: './submit-ticket.component.html',
	styleUrls: ['./submit-ticket.component.scss']
})
export class SubmitTicketComponent implements OnInit {
	@ViewChild(RouterOutlet) outlet: RouterOutlet;
	public searchControl: FormControl = new FormControl('');
	public isSearchPage = false;

	private unsubscriber$ = new Subject<void>();

	public emailForm: FormGroup;
	public user: User;

	public loading = false;
	public submitSuccess = false;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public kbService: KnowledgebaseService,
		private toastNotificationService: ToastNotificationService,
		public fb: FormBuilder,
		private backOfficeApiService: BackOfficeService
	) {
		this.emailForm = this.fb.group({
			subject: ['', Validators.required],
			description: ['', Validators.required],
			emailId: ['', Validators.required],
			files: [null]
		});
	}

	ngOnInit(): void {
		// this.getAccountDetails();
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.emailForm.patchValue({
			emailId: token.filed_email
		});
	}

	public getAccountDetails(): void {
		this.backOfficeApiService
			.getUserDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.emailForm.patchValue({
						emailId: response.email
					});
				}
			});
	}

	public searchKnowledgeBase(): void {
		return;
	}

	public dropDownValueChanges($event: any): void {}

	public submit(): void {
		this.loading = true;
		var formData: any = new FormData();
		formData.append('subject', this.emailForm.get('subject').value);
		formData.append('description', this.emailForm.get('description').value);
		formData.append('emailId', this.emailForm.get('emailId').value);
		// formData.append('files', this.emailForm.get('files').value);
		let payload = {
			subject: this.emailForm.get('subject').value,
			description: this.emailForm.get('description').value,
			email_id: this.emailForm.get('emailId').value
		};
		this.kbService
			.submitTicket(payload)
			.pipe(
				take(1),
				catchError(error => {
					this.toastNotificationService.sendErrorToast('There was an error submitting this ticket, please try again.');
					this.loading = false;
					return EMPTY;
				})
			)
			.subscribe(data => {
				this.loading = false;
				this.submitSuccess = true;
			});
	}

	uploadFile(event: any) {
		let file = (event.target as HTMLInputElement).files[0];
		if (file.size > 10000000) {
			this.toastNotificationService.sendErrorToast('File size is greater that 10MB, please attach a file with size not greater than 10MB.');
			return;
		} else if (file.size <= 10000000) {
			this.emailForm.patchValue({
				files: file
			});
			this.emailForm.get('files').updateValueAndValidity();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
