<form [formGroup]="generatorForm">
	<app-custom-textarea-with-label
		placeholder="Paste the content that you want to improve or rewrite"
		[maxLength]="400"
		[control]="generatorForm.controls['type']"
		inputLabel="Content"
	></app-custom-textarea-with-label>
	<!-- </div> -->
	<div class="twoinrow">
		<div class="input-box">
			<div class="label dropdown" style="display: flex;">
				<label>Output Tone</label>
				<app-tooltip text="You can type your own Tone"></app-tooltip>
			</div>
			<app-custom-drpdown-op1
				[data]="outputTone"
				(selectionChange)="changeToneSource($event)"
				[useCustom]="true"
				[dropdownFormControl]="generatorForm?.get('outputTone')"
				[placeHolder]="'Select/Enter new'"
				[searchEnable]="true"
				[noError]="true"
			></app-custom-drpdown-op1>
			<div class="error-field">
				<span *ngIf="!generatorForm.controls.outputTone.valid">This field is required.</span>
			</div>
		</div>
		<div class="input-lang input-box">
			<div class="label dropdown">
				<label>Output Language (Optional)</label>
			</div>
			<app-custom-drpdown-op1
				[data]="outputLang"
				(selectionChange)="changeLangSource($event)"
				[dropdownFormControl]="generatorForm?.get('outputLang')"
				[useCustom]="false"
				[searchEnable]="true"
				[placeHolder]="'Select Language'"
			></app-custom-drpdown-op1>
		</div>
	</div>
</form>
