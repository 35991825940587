import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Konva from 'konva';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';
@Injectable({
	providedIn: 'root'
})
export class KonvaService {
	isEditorFirstTimeOpen = false;
	undo = new BehaviorSubject(null);
	undo$ = this.undo.asObservable();
	redo = new BehaviorSubject(null);
	redo$ = this.redo.asObservable();
	del = new BehaviorSubject(null);
	del$ = this.del.asObservable();
	hideSideMenu = new BehaviorSubject(false);
	hideSideMenu$ = this.hideSideMenu.asObservable();
	GUIDELINE_OFFSET = 5;
	selectionTab = new BehaviorSubject(0);
	selectionTab$ = this.selectionTab.asObservable();
	canvasResize = new BehaviorSubject(0);
	canvasResize$ = this.canvasResize.asObservable();
	anonymous = false;

	// initial state
	state = [];

	// our history
	appHistory = [this.state];
	appHistoryStep = 0;
	layer: any;
	stage: any;
	transformer: any = null;
	selectedElement: any;
	stage2: any;
	layer2: any;
	guides = [];
	constructor(private adsService: AdsService) {
		this.handleUndoRedo();
	}
	handleUndoRedo() {}
	initialize(width = 500, height = 500) {
		console.log('stage initialize', width, height);
		this.stage = new Konva.Stage({
			container: 'myCanvas',
			width: width,
			height: height
		});

		this.layer = new Konva.Layer();

		this.stage.add(this.layer);
		this.layer.on('dragmove', e => {
			// clear all previous lines on the screen
			this.layer.find('.guid-line').forEach(l => l.destroy());

			// find possible snapping lines
			var lineGuideStops = this.getLineGuideStops(e.target);
			// find snapping points of current object
			var itemBounds = this.getObjectSnappingEdges(e.target);

			// now find where can we snap current object
			var guides = this.getGuides(lineGuideStops, itemBounds);

			// do nothing of no snapping
			if (!guides.length) {
				return;
			}

			this.drawGuides(guides);

			var absPos = e.target.absolutePosition();
			// now force object position
			guides.forEach(lg => {
				switch (lg.snap) {
					case 'start': {
						switch (lg.orientation) {
							case 'V': {
								absPos.x = lg.lineGuide + lg.offset;
								break;
							}
							case 'H': {
								absPos.y = lg.lineGuide + lg.offset;
								break;
							}
						}
						break;
					}
					case 'center': {
						switch (lg.orientation) {
							case 'V': {
								absPos.x = lg.lineGuide + lg.offset;
								break;
							}
							case 'H': {
								absPos.y = lg.lineGuide + lg.offset;
								break;
							}
						}
						break;
					}
					case 'end': {
						switch (lg.orientation) {
							case 'V': {
								absPos.x = lg.lineGuide + lg.offset;
								break;
							}
							case 'H': {
								absPos.y = lg.lineGuide + lg.offset;
								break;
							}
						}
						break;
					}
				}
			});
			e.target.absolutePosition(absPos);
		});

		this.layer.on('dragend', e => {
			// clear all previous lines on the screen
			this.layer.find('.guid-line').forEach(l => l.destroy());
		});
		this.handleStageEvent();
	}

	addTransformLayer(node, index) {
		// this.transformer?.nodes([]);
		if (this.state.length - 1 == index) {
			this.transformer = new Konva.Transformer();
			console.log(node);
			this.layer.add(this.transformer);
			console.log(this.transformer, 'transformer initialized');
			this.transformer.nodes([node]);
		}

		// console.log(this.transformer.nodes());
	}
	addTransformTextLayer(node, index) {
		// this.transformer?.nodes([]);
		if (this.state.length - 1 == index) {
			this.transformer = new Konva.Transformer({
				node: node,
				keepRatio: true,

				rotationSnaps: [0, 90, 180, 270],

				// set minimum width of text
				boundBoxFunc: function (oldBox, newBox) {
					newBox.width = Math.max(30, newBox.width);
					return newBox;
				}
			});
			console.log(node);
			this.layer.add(this.transformer);
			console.log(this.transformer, 'transformer initialized');
			this.transformer.nodes([node]);
		}

		// console.log(this.transformer.nodes());
	}

	addImage(url) {
		this.state.push({
			type: 'image',
			url: url,
			name: 'item-' + this.state.length,
			x: 10 * (Math.random() * 10),
			y: 10 * (Math.random() * 10),
			id: 'img' + this.state.length,
			scaleX: 1,
			scaleY: 1
		});
		this.create();
	}

	imageRender(item, index) {
		var imageObj = new Image();
		var node = new Konva.Image({
			draggable: true,
			name: 'item-' + index,
			scaleX: 1,
			scaleY: 1,
			image: imageObj,
			width: 200,
			height: 200,
			type: 'image'
		});
		// if(a)
		imageObj.crossOrigin = 'Anonymous';
		imageObj.src = item.url;
		this.layer.add(node);
		// console.log(this.transformer.nodes(), 'transformer');
		console.log(index, 'idx');
		this.addTransformLayer(node, index);
		this.del.next('');
		console.log(this.appHistory, this.state);
		node.on('dragend', e => {
			// make new state

			this.state = this.state.slice();
			// update object data
			this.state[index] = Object.assign({}, this.state[index], {
				x: node.x(),
				y: node.y()
			});
			// save it into history
			this.saveStateToHistory(this.state);
			// don't need to call update here
			// because changes already in node
			console.log(this.appHistory, this.state);
			this.transformer.nodes([e.target]);
			this.del.next('');
		});
		node.on('dragstart', e => {
			this.transformer.nodes([e.target]);
			this.del.next('');
		});
		node.on('transformend', () => {
			// make new state

			this.state = this.state.slice();
			// update object data
			this.state[index] = Object.assign({}, this.state[index], {
				scaleX: node.scaleX(),
				scaleY: node.scaleY()
			});
			// save it into history
			this.saveStateToHistory(this.state);
			// don't need to call update here
			// because changes already in node
			console.log(this.appHistory, this.state);
		});

		node.on('click', e => {
			console.log(e.target, '======');
			this.transformer.nodes([e.target]);
			this.del.next('');
		});
		node.on('mouseover', e => {
			node.setAttrs({
				stroke: '#006bff',
				strokeWidth: 2
			});
			// this.transformer.nodes([e.target]);
		});
		node.on('mouseleave', e => {
			node.setAttrs({
				stroke: '',
				strokeWidth: 0
			});
			// this.transformer.nodes([e.target]);
		});
		node.on('keydown', e => {
			console.log(e);
			// this.transformer.nodes([e.target]);
		});
	}

	renderText(item, index) {
		var textnode = new Konva.Text({
			text: item.text,
			x: item.x,
			y: item.y,
			fontSize: item.fontSize,
			draggable: true,
			width: item.width,
			fontFamily: item.fontFamily,
			name: 'item-' + index,
			type: 'text'
		});

		this.layer.add(textnode);
		// console.log(this.transformer.nodes(), 'transformer');
		console.log(index, 'idx');
		this.addTransformTextLayer(textnode, index);
		this.del.next('');
		console.log(this.appHistory, this.state);

		textnode.on('dragend', e => {
			// make new state

			this.state = this.state.slice();
			// update object data
			this.state[index] = Object.assign({}, this.state[index], {
				x: textnode.x(),
				y: textnode.y()
			});
			// save it into history
			this.saveStateToHistory(this.state);
			// don't need to call update here
			// because changes already in node
			console.log(this.appHistory, this.state);
			this.transformer.nodes([e.target]);
			this.del.next('');
		});
		textnode.on('dragstart', e => {
			this.transformer.nodes([e.target]);
			this.del.next('');
		});
		textnode.on('transform', () => {
			// textnode.setAttrs({
			// 	width: textnode.width() * textnode.scaleX(),
			// 	scaleX: 1
			// });
		});
		textnode.on('dblclick dbltap', () => {
			textnode.hide();
			this.transformer.hide();
			var textPosition = textnode.absolutePosition();

			// so position of textarea will be the sum of positions above:
			var areaPosition = {
				x: this.stage.container().offsetLeft + textPosition.x,
				y: this.stage.container().offsetTop + textPosition.y
			};

			// create textarea and style it
			var textarea: any = document.createElement('textarea');
			document.body.appendChild(textarea);
			textarea.value = textnode.text();
			textarea.style.position = 'absolute';
			textarea.style.top = areaPosition.y + 'px';
			textarea.style.left = areaPosition.x + 'px';
			textarea.style.width = textnode.width() - textnode.padding() * 2 + 'px';
			textarea.style.height = textnode.height() - textnode.padding() * 2 + 5 + 'px';
			textarea.style.fontSize = textnode.fontSize() + 'px';
			textarea.style.border = 'none';
			textarea.style.padding = '0px';
			textarea.style.margin = '0px';
			textarea.style.overflow = 'hidden';
			textarea.style.background = 'none';
			textarea.style.outline = 'none';
			textarea.style.resize = 'none';
			textarea.style.lineHeight = textnode.lineHeight();
			textarea.style.fontFamily = textnode.fontFamily();
			textarea.style.transformOrigin = 'left top';
			textarea.style.textAlign = textnode.align();
			textarea.style.color = textnode.fill();
			let rotation = textnode.rotation();
			var transform = '';
			if (rotation) {
				transform += 'rotateZ(' + rotation + 'deg)';
			}

			var px = 0;
			// also we need to slightly move textarea on firefox
			// because it jumps a bit
			var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
			if (isFirefox) {
				px += 2 + Math.round(textnode.fontSize() / 20);
			}
			transform += 'translateY(-' + px + 'px)';

			textarea.style.transform = transform;

			// reset height
			textarea.style.height = 'auto';
			// after browsers resized it we can set actual value
			textarea.style.height = textarea.scrollHeight + 3 + 'px';

			textarea.focus();

			function removeTextarea() {
				textarea.parentNode.removeChild(textarea);
				window.removeEventListener('click', handleOutsideClick);
				textnode.show();
				this.transform.show();
				this.transform.forceUpdate();
			}

			function setTextareaWidth(newWidth) {
				if (!newWidth) {
					// set width for placeholder
					newWidth = textnode['placeholder'].length * textnode.fontSize();
				}
				// some extra fixes on different browsers
				var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
				var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
				if (isSafari || isFirefox) {
					newWidth = Math.ceil(newWidth);
				}

				var isEdge = document['documentMode'] || /Edge/.test(navigator.userAgent);
				if (isEdge) {
					newWidth += 1;
				}
				textarea.style.width = newWidth + 'px';
			}

			textarea.addEventListener('keydown', function (e) {
				// hide on enter
				// but don't hide on shift + enter
				if (e.keyCode === 13 && !e.shiftKey) {
					textnode.text(textarea.value);
					removeTextarea();
				}
				// on esc do not set value back to node
				if (e.keyCode === 27) {
					removeTextarea();
				}
			});

			textarea.addEventListener('keydown', function (e) {
				let scale = textnode.getAbsoluteScale().x;
				setTextareaWidth(textnode.width() * scale);
				textarea.style.height = 'auto';
				textarea.style.height = textarea.scrollHeight + textnode.fontSize() + 'px';
			});

			function handleOutsideClick(e) {
				if (e.target !== textarea) {
					textnode.text(textarea.value);
					removeTextarea();
				}
			}
			setTimeout(() => {
				window.addEventListener('click', handleOutsideClick);
			});
		});
		textnode.on('transformend', () => {
			// make new state

			this.state = this.state.slice();
			// update object data
			// this.state[index] = Object.assign({}, this.state[index], {
			// 	scaleX: textnode.scaleX(),
			// 	scaleY: textnode.scaleY()
			// });
			// save it into history
			this.saveStateToHistory(this.state);
			// don't need to call update here
			// because changes already in node
			console.log(this.appHistory, this.state);
		});

		textnode.on('click', e => {
			console.log(e.target, '======');
			this.transformer.nodes([e.target]);
			this.del.next('');
		});
		textnode.on('mouseover', e => {
			console.log(e);

			// var rect = new Konva.Rect({
			// 	x: textnode.x(),
			// 	y: textnode.y(),
			// 	stroke: '#555',
			// 	strokeWidth: 2,
			// 	fill: '#ddd',
			// 	width: 300,
			// 	height: textnode.height(),
			// 	shadowColor: 'black',
			// 	cornerRadius: 10
			// });

			// this.transformer.nodes([e.target]);
		});
		textnode.on('mouseleave', e => {
			// this.transformer.nodes([e.target]);
		});
		textnode.on('keydown', e => {
			console.log(e);
			// this.transformer.nodes([e.target]);
		});
	}
	create() {
		this.layer.destroyChildren();
		this.state.forEach((item, index) => {
			if (item.type == 'image') {
				this.imageRender(item, index);
			}
			if (item.type == 'text') {
				// var imageObj = new Image();
				this.renderText(item, index);
			}
			if (item.type == 'background') {
				const backgroundRect = new Konva.Rect({
					width: this.stage.width(),
					height: this.stage.height(),
					fill: item.fill, // Set the background color here,
					name: 'bg',
					type: 'background'
				});
				this.layer.add(backgroundRect);
				backgroundRect.moveToBottom();
			}
			if (item.type == 'transparent') {
				Konva.Image.fromURL(item.url, darthNode => {
					darthNode.setAttrs({
						name: 'bg',
						width: this.stage.width(),
						height: this.stage.height(),
						type: 'transparent'
					});
					this.layer.add(darthNode);
					darthNode.moveToBottom();
				});
			}
			if (item.type == 'imageBackground') {
				Konva.Image.fromURL(item.url, darthNode => {
					darthNode.setAttrs({
						name: 'bg',
						width: this.stage.width(),
						height: this.stage.height(),
						type: 'imageBackground'
					});
					this.layer.add(darthNode);
					darthNode.moveToBottom();
				});
			}
		});
		this.update();
	}

	update() {
		this.state.forEach((item, index) => {
			console.log('.' + item.name, 'item name');
			var node = this.stage.findOne('.' + item.name);
			console.log(node, 'node update');
			if (node.attrs.type == 'image') {
				node.setAttrs({
					x: item?.x || 0,
					y: item?.y || 0,
					scaleX: item?.scaleX || 1,
					scaleY: item?.scaleY || 1
				});
			}
		});
	}
	saveStateToHistory(state) {
		this.appHistory = this.appHistory.slice(0, this.appHistoryStep + 1);
		this.appHistory = this.appHistory.concat([state]);
		this.appHistoryStep += 1;
	}

	handleStageEvent() {
		this.stage.on('click', e => {
			// e.target is a clicked Konva.Shape or current stage if you clicked on empty space
			// console.log('clicked on', e.target);
			// console.log('usual click on ' + JSON.stringify(this.stage.getPointerPosition()));
			if (e.target === this.stage) {
				this.removeTransformerLayer();

				console.log(this.transformer.nodes());
				this.del.next('');
				console.log(this.layer, this.stage);
				console.log(this.stage.find('Image'), 'finding....');
				return;
			}
			// if (e.target.hasName('.item')) {
			// 	console.log('clicked on image');
			// 	return;
			// }
		});
	}
	removeTransformerLayer() {
		this.transformer?.nodes([]);
	}

	removeFromState() {
		console.log(this.state);
		if (this.transformer) {
			let [item] = this.transformer?.nodes();
			console.log(item, 'imte');
			if (item) {
				this.state = this.state.slice();
				console.log(this.state);

				this.state.splice(
					this.state.findIndex(i => i.name == item.attrs.name),
					1
				);
				console.log(this.state, 'state after splice');
				this.saveStateToHistory(this.state);
				this.transformer.nodes([]);
				item.remove();
				this.create();
			}
			// this.layer.remove(this.selectedElement);
		}
	}

	getLineGuideStops(skipShape) {
		// we can snap to stage borders and the center of the stage
		var vertical: any = [0, this.stage.width() / 2, this.stage.width()];
		var horizontal: any = [0, this.stage.height() / 2, this.stage.height()];

		// and we snap over edges and center of each object on the canvas
		this.stage.find('Image').forEach(guideItem => {
			if (guideItem === skipShape) {
				return;
			}
			var box = guideItem.getClientRect();
			// and we can snap to all edges of shapes
			vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
			horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
		});
		return {
			vertical: vertical.flat(),
			horizontal: horizontal.flat()
		};
	}

	getObjectSnappingEdges(node) {
		var box = node.getClientRect();
		var absPos = node.absolutePosition();

		return {
			vertical: [
				{
					guide: Math.round(box.x),
					offset: Math.round(absPos.x - box.x),
					snap: 'start'
				},
				{
					guide: Math.round(box.x + box.width / 2),
					offset: Math.round(absPos.x - box.x - box.width / 2),
					snap: 'center'
				},
				{
					guide: Math.round(box.x + box.width),
					offset: Math.round(absPos.x - box.x - box.width),
					snap: 'end'
				}
			],
			horizontal: [
				{
					guide: Math.round(box.y),
					offset: Math.round(absPos.y - box.y),
					snap: 'start'
				},
				{
					guide: Math.round(box.y + box.height / 2),
					offset: Math.round(absPos.y - box.y - box.height / 2),
					snap: 'center'
				},
				{
					guide: Math.round(box.y + box.height),
					offset: Math.round(absPos.y - box.y - box.height),
					snap: 'end'
				}
			]
		};
	}

	getGuides(lineGuideStops, itemBounds) {
		var resultV = [];
		var resultH = [];

		lineGuideStops.vertical.forEach(lineGuide => {
			itemBounds.vertical.forEach(itemBound => {
				var diff = Math.abs(lineGuide - itemBound.guide);
				// if the distance between guild line and object snap point is close we can consider this for snapping
				if (diff < this.GUIDELINE_OFFSET) {
					resultV.push({
						lineGuide: lineGuide,
						diff: diff,
						snap: itemBound.snap,
						offset: itemBound.offset
					});
				}
			});
		});

		lineGuideStops.horizontal.forEach(lineGuide => {
			itemBounds.horizontal.forEach(itemBound => {
				var diff = Math.abs(lineGuide - itemBound.guide);
				if (diff < this.GUIDELINE_OFFSET) {
					resultH.push({
						lineGuide: lineGuide,
						diff: diff,
						snap: itemBound.snap,
						offset: itemBound.offset
					});
				}
			});
		});

		var guides = [];

		// find closest snap
		var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
		var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
		if (minV) {
			guides.push({
				lineGuide: minV.lineGuide,
				offset: minV.offset,
				orientation: 'V',
				snap: minV.snap
			});
		}
		if (minH) {
			guides.push({
				lineGuide: minH.lineGuide,
				offset: minH.offset,
				orientation: 'H',
				snap: minH.snap
			});
		}
		return guides;
	}
	drawGuides(guides) {
		guides.forEach(lg => {
			if (lg.orientation === 'H') {
				var line = new Konva.Line({
					points: [-6000, 0, 6000, 0],
					stroke: 'rgb(0, 161, 255)',
					strokeWidth: 1,
					name: 'guid-line',
					dash: [4, 6]
				});
				this.layer.add(line);
				line.absolutePosition({
					x: 0,
					y: lg.lineGuide
				});
			} else if (lg.orientation === 'V') {
				var line = new Konva.Line({
					points: [0, -6000, 0, 6000],
					stroke: 'rgb(0, 161, 255)',
					strokeWidth: 1,
					name: 'guid-line',
					dash: [4, 6]
				});
				this.layer.add(line);
				line.absolutePosition({
					x: lg.lineGuide,
					y: 0
				});
			}
		});
	}

	handleLayerPosition(type) {
		let [item] = this.transformer?.nodes();
		if (!item) return;

		this.state = this.state.slice();
		let [obj] = this.state.splice(
			this.state.findIndex(i => i.name == item.name()),
			1
		);
		if (type == 'toforward') {
			console.log(item.name());
			item.moveToTop();

			console.log(obj, 'obj');
			this.state.unshift(obj);
		}
		if (type == 'up') {
			console.log(item.name());
			item.moveUp();

			console.log(obj, 'obj');
			this.state.unshift(obj);
		}
		if (type == 'down') {
			item.moveDown();

			console.log(obj, 'obj');
			this.state.push(obj);
		}

		if (type == 'tobottom') {
			item.moveToBottom();

			console.log(obj, 'obj');
			this.state.push(obj);
		}

		this.saveStateToHistory(this.state);
		console.log(this.state, 'state');
	}

	handlePosition(type) {
		let [item] = this.transformer?.nodes();
		if (!item) return;
		if (type == 'left') {
			console.log(this.stage.height(), 'canvas height', item.y(), item.x());
			item.setAttrs({
				x: 0,
				y: item.y()
			});
		}
		if (type == 'right') {
			item.setAttrs({
				x: this.stage.width() - item.width(),
				y: item.y()
			});
		}
		if (type == 'center') {
			const canvasCenterX = this.stage.width() / 2;
			const canvasCenterY = this.stage.height() / 2;
			item.setAttrs({
				x: canvasCenterX - item.width() / 2,
				y: item.y()
			});
		}
		if (type == 'top') {
			item.setAttrs({
				x: item.x(),
				y: 0
			});
		}
		if (type == 'middle') {
			const canvasCenterY = this.stage.height() / 2;
			item.setAttrs({
				x: item.x(),
				y: canvasCenterY - item.height() / 2
			});
		}
		if (type == 'bottom') {
			const canvasBottomY = this.stage.height();
			item.setAttrs({
				x: item.x(),
				y: canvasBottomY - item.height()
			});
		}

		this.saveStateToHistory(this.state);
	}

	addbackGround(color) {
		let bg = this.layer.find('.bg');
		console.log(bg, 'bg', this.state, this.appHistory);
		if (bg.length) {
			bg[0].remove();
			this.state.splice(
				this.state.findIndex(t => t.name == 'bg'),
				1
			);
		}
		const backgroundRect = new Konva.Rect({
			width: this.stage.width(),
			height: this.stage.height(),
			fill: color, // Set the background color here,
			name: 'bg',
			type: 'background'
		});
		this.state.push({
			width: this.stage.width(),
			height: this.stage.height(),
			fill: color, // Set the background color here,
			name: 'bg',
			type: 'background'
		});
		this.layer.add(backgroundRect);
		backgroundRect.moveToBottom();
		this.state = this.state.slice();
		this.saveStateToHistory(this.state);
	}

	addImageBackground(url) {
		let bg = this.layer.find('.bg');
		console.log(bg, 'bg', this.state, this.appHistory);
		if (bg.length) {
			bg[0].remove();
			this.state.splice(
				this.state.findIndex(t => t.name == 'bg'),
				1
			);
		}
		Konva.Image.fromURL(url, darthNode => {
			darthNode.setAttrs({
				name: 'bg',
				width: this.stage.width(),
				height: this.stage.height(),
				type: 'imageBackground'
			});
			this.state.push({
				name: 'bg',
				width: this.stage.width(),
				height: this.stage.height(),
				type: 'imageBackground',
				url: url
			});
			this.layer.add(darthNode);
			darthNode.moveToBottom();
			this.state = this.state.slice();
			this.saveStateToHistory(this.state);
		});
	}

	removeBackground() {
		let bg = this.layer.find('.bg');
		console.log(bg, 'bg', this.state, this.appHistory);
		if (bg.length) {
			bg[0].remove();
			this.state.splice(
				this.state.findIndex(t => t.name == 'bg'),
				1
			);
		}
		Konva.Image.fromURL('/assets/editor/transparent.jpg', darthNode => {
			darthNode.setAttrs({
				name: 'bg',
				width: this.stage.width(),
				height: this.stage.height(),
				type: 'transparent'
			});
			this.state.push({
				name: 'bg',
				width: this.stage.width(),
				height: this.stage.height(),
				type: 'transparent',
				url: '/assets/editor/transparent.jpg'
			});
			this.layer.add(darthNode);
			darthNode.moveToBottom();
			this.state = this.state.slice();
			this.saveStateToHistory(this.state);
		});
	}

	//Brand Kit
	checkWebsite(websiteName) {
		return this.adsService.checkWebsiteName(websiteName);
	}
}
