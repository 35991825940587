import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdsService } from '../../ads.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { PublishComponent } from '../publish/publish.component';
import { CancelModalService } from 'src/app/shakespeare/settings/modals/cancel-modal.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
	activeStep = 0;
	showButton = true;
	googleInput = new FormControl();
	inputError = false;
	loading = true;
	connecting = false;
	selected: boolean = false;
	googleCustomerIds: any;
	backup: { id: string; name: string }[];
	extensionValues: { adAccount: string; aiCategory: string } = { adAccount: '', aiCategory: '' };
	fromExtension = false;
	selectedGoogleId: string;
	navigateToWelcomePage: boolean;

	scrWidth: number;
	platformDefault: string;
	clickedPlatform: string;
	hideUpgrade: boolean = false;
	loadingPlatform: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private adsService: AdsService,
		private router: Router,
		private authService: AuthenticationService,
		private cancelModalService: CancelModalService,
		private backOffice: BackOfficeService,
		private toastNotificationService: ToastNotificationService
	) {
		// this.googleCustomerIds = [
		// 	{
		// 		name: 'Abstract E',
		// 		id: '7020708821'
		// 	},
		// 	{
		// 		name: 'Abstract E',
		// 		id: '7020708821'
		// 	},
		// ]
	}

	ngOnInit(): void {
		if (this.authService.googleAdAccountConnected$.value == true) {
			this.connecting = false;
		} else {
			this.connecting = true;
		}

		this.activatedRoute.queryParams.subscribe(params => {
			if (params['error'] == 'access_denied') {
				this.router.navigate(['ads/google/auth']);
			}
			if (params['connected'] == 'true') {
				this.connecting = false;
				this.activeStep = 2;
				this.getAdCategory();
				this.getGoogleAdIDs();
			} else {
				this.adsService.validateAccessToken().subscribe({
					next: (res: any) => {
						if (!res) {
							this.saveCookie('googleRedirectType', 'AiIntelligence');
							this.connecting = false;
							this.loading = false;
							//this.connectToGoogle();
							this.adChannel();
						} else {
							// this.getAdCategory();
							this.getAdCategory2();

							// this.getGoogleAdIDs();
							// this.router.navigate(['/ads/google/dashboard']);
							// this.activeStep = 2;
							// this.getGoogleAdIDs();
						}
						this.authService.googleAdAccountConnected$.next(res);
					},
					error: err => {
						this.connecting = false;
						if (err.error[0].code == 'Lolly__Domain__NA__User_Bill__IsNullOrEmpty') {
							this.cancelModalService.openCancelModaldummy('Your plan has been expired, Please contact your product manager.');
						} else {
							this.router.navigate(['/ads/google/auth']);
						}
						console.log('error');
					}
				});
			}
			if (params['adAccount'] && params['aiCategory']) {
				this.fromExtension = true;
				this.extensionValues['adAccount'] = params['adAccount'];
				this.extensionValues['aiCategory'] = params['aiCategory'];
			}
		});

		this.loadingPlatform = true;
		this.backOffice.getDefaultSelectedPlatform().subscribe(res => {
			this.platformDefault = res;
			// this.clickedPlatform = localStorage.getItem('connectChoice');
			this.clickedPlatform = 'google';
			setTimeout(() => {
				this.loadingPlatform = false;
			}, 3000);
		});
	}

	adChannel() {
		this.adsService.adChannel('google').subscribe({
			next: (res: any) => {
				if (res) {
					this.showButton = true;
					this.activeStep = 1;
				} else {
					this.showButton = false;
					this.activeStep = 1;
				}
			}
		});
	}

	getAdCategory() {
		this.adsService.getCategoriesIDs().subscribe({
			next: (res: any) => {
				this.connecting = false;

				if (res) {
					if (res.data.length == 0) {
						this.navigateToWelcomePage = true;
					} else {
						if (localStorage.getItem('GoogleLoader') == 'true') {
							localStorage.setItem('GoogleLoader', 'false');
							this.router.navigate(['/ads/google/dashboard'], { queryParams: { loader: true } });
						} else {
							localStorage.setItem('GoogleLoader', 'false');
							this.router.navigate(['/ads/google/dashboard']);
						}
					}
				}
			}
		});
	}

	getAdCategory2() {
		this.adsService.getCategoriesIDs().subscribe({
			next: (res: any) => {
				if (res) {
					this.connecting = false;
					this.loading = false;
					if (res.data.length == 0) {
						if (this.authService.googleAdAccountConnected$.value == true) {
							this.router.navigate(['/ads/google/dashboard']);
						} else {
							// this.router.navigate(['/ads/google/welcome']);
							this.router.navigate(['/ads/ai-category?page=google']);
						}
					} else {
						if (localStorage.getItem('GoogleLoader') == 'true') {
							localStorage.setItem('GoogleLoader', 'false');

							this.router.navigate(['/ads/google/dashboard'], { queryParams: { loader: true } });
						} else {
							localStorage.setItem('GoogleLoader', 'false');
							this.router.navigate(['/ads/google/dashboard']);
						}
					}
				}
			},
			error: () => {
				this.connecting = false;
				this.loading = false;
			}
		});
	}

	// validateAccessToken(): void {
	// 	console.log("hey2");
	// 	this.connecting = true;
	// 	this.adsService.validateAccessToken().subscribe({
	// 		next: (res: any) => {
	// 			if (!res) {
	// 				this.saveCookie('googleRedirectType', 'AiIntelligence');
	// 				this.connecting = false;
	// 				//this.connectToGoogle();
	// 				this.activeStep = 1;
	// 			} else {
	// 				this.getAdCategory();
	// 				this.connecting = true;
	// 				// this.router.navigate(['/ads/google/dashboard']);
	// 				// this.activeStep = 2;
	// 				// this.getGoogleAdIDs();
	// 			}
	// 		},
	// 		error: () => {
	// 			this.connecting = false;
	// 			console.log('error');
	// 			this.router.navigate(['/ads/google/auth']);
	// 		}
	// 	});
	// }

	public googleAdLoading: boolean = false;
	getGoogleAdIDs(): void {
		this.loading = true;
		this.googleAdLoading = true;
		this.adsService
			.getGoogleAdIDs()
			.subscribe({
				next: res => {
					this.loading = false;
					let showPopup = res.every((data: any) => {
						return data?.customMessage === 'deleteMyToken';
					});
					if (showPopup) {
						this.modalPopup();
					} else {
						this.googleCustomerIds = res || [];
						this.googleCustomerIds.map((e: any) => {
							e.checked = false;
						});
						this.backup = this.googleCustomerIds;
					}
				},
				error: err => {
					if (err.error[0].code == 'Lolly__Api__User_Subscription__AlreadyCanceled') {
						this.cancelModalService.openCancelModaldummy(
							'In order to use this function please speak to your account manager to upgrade your plan.'
						);
						this.router.navigate(['/settings/billing']);
					}
					if (err.error[0].code == 'Lolly__Domain__Google_AdAccount__IsNotFoundByCriteria') {
						this.cancelModalService.openCancelModaldummy('You do not have any AdAccount in it. Please use another Gmail Account.');
						this.router.navigate(['/ads/google/auth']);
					}
					// console.error(err);
				}
			})
			.add(() => {
				this.googleAdLoading = false;
			});
	}

	modalPopup() {
		let modalConfig;
		let isMobile = this.scrWidth < 780 ? true : false;
		if (isMobile) {
			modalConfig = {
				width: '90%',
				height: '328px',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '498px',
				height: '328px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(PublishComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			data: {
				// title: 'Please ask admin to give write access?',
				title:
					"Oops! Looks like you don't have the write access. Please ask your admin to give you the write access or try connecting another email account.",
				cta: 'Try another account',
				isCancel: true,
				cancelText: 'Reload'
			},
			disableClose: true,
			cancelCallBack: resolve => {
				this.adsService.getGoogleAdIDs().subscribe({
					next: res => {
						if (res.length) {
							resolve();
						}
					},
					error: err => {}
				});
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.confirm) {
				// this.router.navigate(['/ads/google/auth']);
				this.router.navigate(['/settings/integrations']);
			}
			if (res.cancel) {
			}
		});
	}

	checkbox(element: any) {
		this.googleCustomerIds?.map((item: any) => {
			if (item.id !== element.id) {
				item.checked = false;
			} else {
				item.checked = true;
			}
		});
		this.googleCustomerIds?.some((e: any) => {
			return e.checked == true;
		})
			? (this.selected = true)
			: (this.selected = false);
		console.log(this.selected);
	}

	navigateToNextPage(): void {
		let checkboxes = document.querySelectorAll('.checkbox');
		let selected = [];
		checkboxes.forEach((checkbox: any) => {
			if (checkbox.checked) {
				selected.push(checkbox.id);
			}
		});

		this.selectedGoogleId = selected[0]; // selecting 1st id as there is no option to select multiple ids
		localStorage.setItem('accountId', this.selectedGoogleId);
		localStorage.setItem('categoryId', '');
		localStorage.setItem('groudId', '');
		this.adsService.userAdId$.next(this.selectedGoogleId);
		if (this.navigateToWelcomePage) {
			// this.router.navigate(['/ads/google/welcome']);
			this.router.navigate(['/ads/ai-category?page=google']);

			// this.router.navigate(['/ads/google/dashboard'], { queryParams: { adAccount: this.extensionValues.adAccount, aiCategory: this.extensionValues.aiCategory } });
			return;
		}
		this.router.navigate(['/ads/google/connect']);
	}

	googleRedirectUrl = environment.Flags.production ? 'app.shakespeare.ai' : 'dev.shakespeare.ai';
	// googleRedirectUrl = 'localhost:4200';
	connectToGoogle(): void {
		this.saveCookie('googleRedirectType', 'AiIntelligence');
		const url = this.googleRedirectUrl;
		const id = environment.GOOGLE_ADS_ACCOUNT_ID;
		if (url && id) {
			// window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`;

			this.createOauthWindow(
				`https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`
			);
		}
		localStorage.setItem('GoogleLoader', 'true');
	}

	createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
		if (url == null) {
			return null;
		}
		const CLIENT_URL = this.googleRedirectUrl;
		left = screen.width / 2 - width / 2;
		top = screen.height / 2 - height / 2;
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		const popup = window.open(url, name, options);
		const checkPopup = setInterval(() => {
			// if (popup.closed) {
			// 	clearInterval(checkPopup);
			// }

			let connected = localStorage.getItem('googleConnected');
			if (connected) {
				console.log(connected);
				clearInterval(checkPopup);
				popup.close();
				localStorage.removeItem('googleConnected');

				if (connected == 'true') {
					this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
				} else this.toastNotificationService.sendErrorToast('Gmail account connection failed!');
			}
			if (!popup || !popup.closed) return;
			clearInterval(checkPopup);
		}, 1000);
	}

	upgradePlan() {
		this.router.navigate(['settings/billing']);
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}

	onManualConnect(): void {
		if (this.googleInput.value === '' || this.googleInput.value === null) {
			this.inputError = true;
			return;
		}
		if (!RegExp(/\d{3}\-\d{3}\-\d{4}/).test(this.googleInput.value)) {
			this.inputError = true;
			return;
		}
		this.inputError = false;
		this.activeStep = 2;
		this.getGoogleAdIDs();
	}

	addHyphen() {
		let text = this.googleInput.value;
		text = text.replace(/([^\d])/g, '').replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '$1$2$3-$4$5$6-$7$8$9$10');
		this.googleInput.setValue(text);
	}

	onSearch(input: string): void {
		this.googleCustomerIds = this.backup.filter((item: { id: string; name: string }) => {
			return item.name.toLowerCase().includes(input.toLowerCase()) || item.id.toLowerCase().includes(input.toLowerCase());
		});
	}

	public goToDashboard() {
		this.router.navigate(['/ads/google/connect']);
	}
}
