import { Component, OnInit } from '@angular/core';
import { saveCookie } from 'src/app/shared/utils';
import { AdsService } from '../ads/ads.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-slack-global-auth',
	templateUrl: './slack-global-auth.component.html',
	styleUrls: ['./slack-global-auth.component.scss']
})
export class SlackGlobalAuthComponent implements OnInit {
	public loading: boolean = true;

	constructor(private activatedRoute: ActivatedRoute, private router: Router, private adsService: AdsService) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['code']) {
				saveCookie('slackCode', params['code']);
				this.sendToken(params['code']);
			} else if (params['error'] == 'access_denied') {
				// this.router.navigate(['ads/google/auth']);
				localStorage.setItem('slackConnected', 'false');
			}
		});
	}

	private sendToken(token: string): void {
		// let decodedJwtIo = JSON.parse(localStorage.getItem('decodedJwtIo'));
		this.adsService
			.validateSlackAccessToken(token)
			.subscribe(
				(res: any) => {
					if (res) {
						localStorage.setItem('slackConnected', 'true');
					} else {
						localStorage.setItem('slackConnected', 'false');
					}
				},
				err => {
					localStorage.setItem('slackConnected', 'false');
				}
			)
			.add(() => {
				// alert();
				this.loading = false;
			});
	}
}
