import { Observable } from 'rxjs';
import { ManageAccount } from '../../_models/manage-account';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
// import { ShareInvoice } from '../../user-management/models/share-invoice';

@Injectable()
export class UserServiceSubscription {
	private readonly subscriptionBaseURL = BaseApiUrl.Subscription;

	constructor(protected http: HttpClient) {}

	public getInvoiceDetails(): Observable<object> {
		return this.http.get(`${this.subscriptionBaseURL}billings/my/invoices`);
	}

	// public shareInvoiceByEmail(email: ShareInvoice): Observable<object> {
	// 	return this.http.put(`${this.subscriptionBaseURL}billings/my/invoices/share-invoice`, email);
	// }

	public getManageAccount(userId: any): Observable<ManageAccount> {
		return this.http.get<ManageAccount>(`${this.subscriptionBaseURL}billings/account/${userId}`);
	}
}
