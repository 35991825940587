<div class="output ad-copy" *ngIf="isAdCopyData">
	<div *ngFor="let item of historyData; let i = index" class="group" [id]="'adcopy-h' + item?.id">
		<div class="content" *ngIf="item?.title">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('adcopy-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.title" [ngClass]="item?.description ? 'boldTitle' : ''"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('adcopy-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('adcopy-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('adcopy-h' + item?.id, item)"></span>
			</div>
		</div>
		<div class="content desc" *ngIf="item?.description">
			<div class="text-left">
				<div class="actions" *ngIf="!item?.title">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('adcopy-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.description"></div>
			</div>
			<div class="other-actions" *ngIf="!item?.title">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('adcopy-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('adcopy-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('adcopy-h' + item?.id, item)"></span>
			</div>
		</div>
		<div class="content" *ngIf="item?.cta">
			<div class="text" [innerHTML]="item?.cta"></div>
		</div>
	</div>
</div>

<div class="output email" *ngIf="isEmailData">
	<div *ngFor="let item of historyData; let i = index" [id]="'email-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.subject">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('email-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text boldTitle" [innerHTML]="item?.subject"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('email-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('email-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('email-h' + item?.id, item)"></span>
			</div>
		</div>
		<div class="content">
			<div class="text-left">
				<div class="actions" *ngIf="!item?.subject">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('email-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.email"></div>
			</div>
			<div class="other-actions" *ngIf="!item?.subject">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('email-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('email-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('email-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isDescriptionData">
	<div *ngFor="let item of historyData; let i = index" [id]="'desc-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.mainDescription">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('desc-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.mainDescription"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('desc-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('desc-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('desc-h' + item?.id, item)"></span>
			</div>
		</div>
		<div *ngFor="let subItem of item.uspDescription">
			<div class="content-2" *ngIf="subItem?.description">
				<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
				<span class="text" [innerHTML]="subItem?.description"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isBlogData">
	<div *ngIf="!fullBlogCase" class="case-default">
		<div *ngFor="let item of historyData; let i = index" [id]="'blog-h' + item?.id" class="group">
			<div class="content desc" *ngIf="item?.para">
				<div class="text-left">
					<div class="actions">
						<span
							(click)="addToFav(i, $event)"
							matTooltip="Add To Favorite"
							[matTooltipPosition]="'above'"
							[ngClass]="item?.isFav ? 'fav' : 'heart'"
						></span>
						<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog-h' + item?.id, item, i)">
							<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
						</span>
					</div>
					<div class="text" [innerHTML]="item?.para"></div>
				</div>
				<div class="other-actions">
					<span
						[ngClass]="item?.isLiked ? 'liked' : 'like'"
						matTooltip="Like"
						[matTooltipPosition]="'above'"
						(click)="addToLiked('blog-h' + item?.id, item, i)"
					></span>
					<span
						[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
						matTooltip="Dislike"
						[matTooltipPosition]="'above'"
						(click)="addToDisliked('blog-h' + item?.id, item, i)"
					></span>
					<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog-h' + item?.id, item)"></span>
				</div>
			</div>
			<div class="content desc" *ngIf="item.text">
				<div class="text-left">
					<div class="actions">
						<span
							(click)="addToFav(i, $event)"
							matTooltip="Add To Favorite"
							[matTooltipPosition]="'above'"
							[ngClass]="item?.isFav ? 'fav' : 'heart'"
						></span>
						<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog-h' + item?.id, item, i)">
							<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
						</span>
					</div>
					<div class="text" [innerHTML]="item.text"></div>
				</div>
				<div class="other-actions">
					<span
						[ngClass]="item?.isLiked ? 'liked' : 'like'"
						matTooltip="Like"
						[matTooltipPosition]="'above'"
						(click)="addToLiked('blog-h' + item?.id, item, i)"
					></span>
					<span
						[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
						matTooltip="Dislike"
						[matTooltipPosition]="'above'"
						(click)="addToDisliked('blog-h' + item?.id, item, i)"
					></span>
					<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog-h' + item?.id, item)"></span>
				</div>
			</div>
			<div class="content desc" *ngIf="item.title">
				<div class="text-left">
					<div class="actions">
						<span
							(click)="addToFav(i, $event)"
							matTooltip="Add To Favorite"
							[matTooltipPosition]="'above'"
							[ngClass]="item?.isFav ? 'fav' : 'heart'"
						></span>
						<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog-h' + item?.id, item, i)">
							<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
						</span>
					</div>
					<div class="text" [innerHTML]="item.title"></div>
				</div>
				<div class="other-actions">
					<span
						[ngClass]="item?.isLiked ? 'liked' : 'like'"
						matTooltip="Like"
						[matTooltipPosition]="'above'"
						(click)="addToLiked('blog-h' + item?.id, item, i)"
					></span>
					<span
						[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
						matTooltip="Dislike"
						[matTooltipPosition]="'above'"
						(click)="addToDisliked('blog-h' + item?.id, item, i)"
					></span>
					<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog-h' + item?.id, item)"></span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="fullBlogCase" class="full-blog">
		<!-- title, intro, paragraph(title desc) conclusion -->
		<div class="">
			<div *ngFor="let item of historyData; let i = index" class="full-blog-data group" [id]="'blog-h' + item.id">
				<div class="content desc blog-title" *ngIf="item.title">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog-h' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text boldTitle" [innerHTML]="item.title"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('blog-h' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('blog-h' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog-h' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
					<div class="text" [innerHTML]="subitem"></div>
				</div>
				<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
					<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
					<div class="text" [innerHTML]="subitem.para"></div>
				</div>
				<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
					<div class="text" [innerHTML]="subitem"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isContentData">
	<div *ngFor="let item of historyData; let i = index" [id]="'content-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.improvedContent">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('content-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.improvedContent"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('content-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('content-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('content-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isSocialData">
	<div *ngFor="let item of historyData; let i = index" [id]="'social-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.title">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.title"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('social-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('social-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social-h' + item?.id, item)"></span>
			</div>
		</div>
		<div class="content desc" *ngIf="item?.hashtag">
			<!-- special case for hashtags which is part of social templates -->
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.hashtag"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('social-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('social-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social-h' + item?.id, item)"></span>
			</div>
		</div>
		<div class="content desc">
			<div class="text-left">
				<div class="actions" *ngIf="!item?.title && !item?.hashtag">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.caption"></div>
			</div>
			<div class="other-actions" *ngIf="!item?.title && !item?.hashtag">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('social-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('social-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isFrameworkData">
	<div *ngFor="let item of historyData; let i = index" [id]="'framework-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.text">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('framework-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.text"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('framework-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('framework-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('framework-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isBioData">
	<div *ngFor="let item of historyData; let i = index" [id]="'bio-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.bio">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('bio-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.bio"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('bio-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('bio-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('bio-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isSeoData">
	<div *ngFor="let item of historyData; let i = index" [id]="'seo-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.text">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('seo-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.text"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('seo-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('seo-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('seo-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>

<div class="output" *ngIf="isVideoData">
	<div *ngFor="let item of historyData; let i = index" [id]="'video-h' + item?.id" class="group">
		<div class="content desc" *ngIf="item?.text">
			<div class="text-left">
				<div class="actions">
					<span
						(click)="addToFav(i, $event)"
						matTooltip="Add To Favorite"
						[matTooltipPosition]="'above'"
						[ngClass]="item?.isFav ? 'fav' : 'heart'"
					></span>
					<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('video-h' + item?.id, item, i)">
						<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
					</span>
				</div>
				<div class="text" [innerHTML]="item?.text"></div>
			</div>
			<div class="other-actions">
				<span
					[ngClass]="item?.isLiked ? 'liked' : 'like'"
					matTooltip="Like"
					[matTooltipPosition]="'above'"
					(click)="addToLiked('video-h' + item?.id, item, i)"
				></span>
				<span
					[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
					matTooltip="Dislike"
					[matTooltipPosition]="'above'"
					(click)="addToDisliked('video-h' + item?.id, item, i)"
				></span>
				<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('video-h' + item?.id, item)"></span>
			</div>
		</div>
	</div>
</div>
