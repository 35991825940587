<ng-select
	[closeOnSelect]="false"
	[dropdownPosition]="'bottom'"
	[formControl]="dropdownIconFormControl"
	[searchable]="searchEnable"
	[ngClass]="{ 'input-error-message': dropdownIconFormControl.invalid && dropdownIconFormControl.touched }"
	[multiple]="false"
	(focus)="autocompleteFocus()"
	(focusout)="setErrorMessage()"
	class="custom-icon"
	notFoundText="{{ noDataFoundLabel }}"
	placeholder="{{ dropdownPlaceholder }}"
>
	<ng-option *ngFor="let data of dropdownData" value="data.value">
		{{ data.displayName }}
		<i class="{{ data.icon }}"></i>
	</ng-option>
</ng-select>

<!--Error message -->
<span class="error-message" *ngIf="dropdownIconFormControl.invalid">{{ errorMessage }}</span>
