import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-overview-header',
	templateUrl: './overview-header.component.html',
	styleUrls: ['./overview-header.component.scss']
})
export class OverviewHeaderComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
