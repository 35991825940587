import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'app-common-popup-welcome',
	templateUrl: './common-popup-welcome.component.html',
	styleUrls: ['./common-popup-welcome.component.scss']
})
export class CommonPopupWelcomeComponent implements OnInit {
	connectChoice: string;
	constructor(public dialogRef: MatDialogRef<CommonPopupWelcomeComponent>) {}

	ngOnInit(): void {}

	closeCommonPopup() {
		this.dialogRef.close();
	}
}
