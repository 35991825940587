<section class="cards-overview-container">
	<div class="cards" *ngFor="let overview of overallData; let i = index" id="overViewId{{ i }}">
		<div class="main-title">
			<img class="brand-logo" *ngIf="brandLogo == 'google'" src="assets/icons/nav-menu/submenu-icons/google-nav.svg" alt="" srcset="" />
			<img class="brand-logo" *ngIf="brandLogo == 'facebook'" src="assets/icons/nav-menu/submenu-icons/facebook-nav.svg" alt="" srcset="" />
			<label for="">{{ overview.adAccountName }}</label>
		</div>

		<div class="card-wrapper">
			<div class="content">
				<section>
					<div class="charts-overview">
						<span class="charts-key">Cost</span>
						<span class="charts-value">
							{{
								overview.currencySymbol +
									(overview.summaryData.spend.current > 0
										? (overview.summaryData.spend.current | number: '1.2-2')
										: overview.summaryData.spend.current)
							}}
						</span>
						<span
							class="charts-changePercentage"
							*ngIf="overview.summaryData.spend.changePercentage !== 0 && overview.summaryData.spend.changePercentage !== -100"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.spend.changePercentage > 1 ? 'green content-open' : 'red content-close'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span class="changePercentage-value" [ngClass]="overview.summaryData.spend.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ overview.summaryData.spend.changePercentage + '%' }}
							</span>
						</span>
					</div>
					<div class="charts-overview">
						<div class="bar"></div>
						<span class="charts-key">Impr</span>
						<span class="charts-value">{{ overview.summaryData.impressions.current | number: '1.0-0' }}</span>
						<span
							class="charts-changePercentage"
							*ngIf="overview.summaryData.impressions.changePercentage !== 0 && overview.summaryData.impressions.changePercentage !== -100"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.impressions.changePercentage > 1 ? 'green content-open' : 'red content-close'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span class="changePercentage-value" [ngClass]="overview.summaryData.impressions.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ overview.summaryData.impressions.changePercentage + '%' }}
							</span>
						</span>
					</div>
					<div class="charts-overview">
						<div class="bar"></div>
						<span class="charts-key">Clicks</span>
						<span class="charts-value">{{ overview.summaryData.clicks.current }}</span>
						<span
							class="charts-changePercentage"
							*ngIf="overview.summaryData.clicks.changePercentage !== 0 && overview.summaryData.clicks.changePercentage !== -100"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.clicks.changePercentage > 1 ? 'green content-open' : 'red content-close'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span class="changePercentage-value" [ngClass]="overview.summaryData.clicks.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ overview.summaryData.clicks.changePercentage + '%' }}
							</span>
						</span>
					</div>
					<!-- <div class="charts-overview">
						<div class="bar"></div>
						<span class="charts-key">Avg CPC</span>
						<span class="charts-value">
							{{
								overview.currencySymbol +
									(overview.summaryData.avgCpc.current > 0
										? (overview.summaryData.avgCpc.current | number: '1.2-2')
										: overview.summaryData.avgCpc.current)
							}}
						</span>
						<span
							class="charts-changePercentage"
							*ngIf="overview.summaryData.avgCpc.changePercentage !== 0 && overview.summaryData.avgCpc.changePercentage !== -100"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.avgCpc.changePercentage > 1 ? 'red content-close' : 'green content-open'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span class="changePercentage-value" [ngClass]="overview.summaryData.avgCpc.changePercentage > 1 ? 'red-text' : 'green-text'">
								{{ overview.summaryData.avgCpc.changePercentage + '%' }}
							</span>
						</span>
					</div> -->
					<div class="charts-overview">
						<div class="bar"></div>
						<span class="charts-key">Conv</span>
						<span class="charts-value">{{ overview.summaryData.conversions.current }}</span>
						<span
							class="charts-changePercentage"
							*ngIf="overview.summaryData.conversions.changePercentage !== 0 && overview.summaryData.conversions.changePercentage !== -100"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.conversions.changePercentage > 1 ? 'green content-open' : 'red content-close'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span class="changePercentage-value" [ngClass]="overview.summaryData.conversions.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ overview.summaryData.conversions.changePercentage + '%' }}
							</span>
						</span>
					</div>
					<div class="charts-overview">
						<div class="bar"></div>
						<span class="charts-key">CPA</span>
						<span class="charts-value">
							{{
								overview.currencySymbol +
									(overview.summaryData.costPerConversion.current > 0
										? (overview.summaryData.costPerConversion.current | number: '1.2-2')
										: overview.summaryData.costPerConversion.current)
							}}
						</span>
						<span
							class="charts-changePercentage"
							*ngIf="
								overview.summaryData.costPerConversion.changePercentage !== 0 &&
								overview.summaryData.costPerConversion.changePercentage !== -100
							"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.costPerConversion.changePercentage > 1 ? 'red content-close' : 'green content-open'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span
								class="changePercentage-value"
								[ngClass]="overview.summaryData.costPerConversion.changePercentage > 1 ? 'red-text' : 'green-text'"
							>
								{{ overview.summaryData.costPerConversion.changePercentage + '%' }}
							</span>
						</span>
					</div>
					<div class="charts-overview" style="width: fit-content;">
						<div class="bar"></div>
						<span class="charts-key">Conv. rate</span>
						<span class="charts-value">
							{{
								(overview.summaryData.conversionRate.current > 0
									? (overview.summaryData.conversionRate.current | number: '1.2-2')
									: overview.summaryData.conversionRate.current) + '%'
							}}
						</span>
						<span
							class="charts-changePercentage"
							*ngIf="overview.summaryData.conversionRate.changePercentage !== 0 && overview.summaryData.conversionRate.changePercentage !== -100"
						>
							<img
								class="changePercentage-arrow"
								[ngClass]="overview.summaryData.conversionRate.changePercentage > 1 ? 'green content-open' : 'red content-close'"
								src="assets/icons/arrow-dropdown.svg"
								alt=""
								srcset=""
							/>
							<span
								class="changePercentage-value"
								[ngClass]="overview.summaryData.conversionRate.changePercentage > 1 ? 'green-text' : 'red-text'"
							>
								{{ overview.summaryData.conversionRate.changePercentage + '%' }}
							</span>
						</span>
					</div>
				</section>
			</div>
		</div>

		<!-- !info cards -->
		<div class="info-card-wrapper">
			<div class="cards-wrapper">
				<!-- *AI Category -->
				<ng-container>
					<ngx-skeleton-loader
						*ngIf="creativeLoading"
						appearance="line"
						style="height: 155px; width: 345px;"
						[theme]="{ width: '345px', height: '155px' }"
					></ngx-skeleton-loader>

					<div class="card" *ngIf="!creativeLoading && overview.aiCategories?.length">
						<div class="card-title">
							<div class="img-wrapper orange">
								<img src="assets\overview\ri_arrow-up-down-fill.svg" alt="" />
							</div>
							<span class="title">
								Creative Performance
							</span>
						</div>
						<div class="card-main">
							<span class="blank"></span>
							<p>
								Your optimization score is {{ getOptimizationScore(overview) }}%, {{ getWordFormattedCount(overview) }} ads are underperforming.
							</p>
						</div>
						<div class="card-bottom">
							<span class="link" (click)="gotoAiCreative(overview)">View</span>
						</div>
					</div>

					<div class="card" *ngIf="!creativeLoading && !overview.aiCategories?.length">
						<div class="card-title">
							<div class="img-wrapper blue">
								<img src="assets\overview\gridicons_create.svg" alt="" />
							</div>
							<span class="title">
								Create AI Category
							</span>
						</div>
						<div class="card-main">
							<span class="blank"></span>
							<p>
								This is your first step to see how your current ads are performing
							</p>
						</div>
						<div class="card-bottom">
							<button class="secondary-btn" (click)="createCategory(overview.adAccountId)">Create</button>
						</div>
					</div>
				</ng-container>

				<!-- *AI Calibration -->
				<ng-container>
					<ngx-skeleton-loader
						*ngIf="aiCalibrationData[i]?.loading"
						appearance="line"
						style="height: 155px; width: 345px;"
						[theme]="{ width: '345px', height: '155px' }"
					></ngx-skeleton-loader>

					<div class="card" *ngIf="!aiCalibrationData[i]?.loading && aiCalibrationData[i]?.aiCalibration">
						<div class="card-title">
							<div class="img-wrapper purple">
								<img src="assets\overview\mdi_graph-line.svg" alt="" />
							</div>
							<span class="title">
								Optimize Your Campaigns
							</span>
						</div>
						<div class="card-main">
							<span class="blank"></span>
							<p>
								To improve your campaign performance check {{ getOptimizeCount(overview) }}
								recommendations.
							</p>
						</div>
						<div class="card-bottom">
							<span class="link" (click)="gotoAiOptimize(overview)">View</span>
						</div>
					</div>

					<div class="card" *ngIf="!aiCalibrationData[i]?.loading && !aiCalibrationData[i]?.aiCalibration">
						<div class="card-title">
							<div class="img-wrapper blue">
								<img src="assets\overview\gridicons_create.svg" alt="" />
							</div>
							<span class="title">
								Create AI Calibration
							</span>
						</div>
						<div class="card-main">
							<span class="blank"></span>
							<p>
								This is your first step to see how your current ads are performing
							</p>
						</div>
						<div class="card-bottom">
							<button
								class="secondary-btn"
								(click)="createPopup(overview.adAccountId)"
								[disabled]="!overview.aiCategories?.length"
								placement="auto"
								[ngbTooltip]="!overview.aiCategories?.length ? 'Create ai category to create ai calibration' : ''"
							>
								Create
							</button>
						</div>
					</div>
				</ng-container>
			</div>

			<!-- <span class="arrow-button" (click)="onArrowClicked(overview, i)">
				<img src="assets/icons/arrow-dropdown.svg" alt="" [ngClass]="overview.isSelected ? 'content-open' : 'content-close'" />
			</span> -->
			<span class="link toggle-btn" (click)="onArrowClicked(overview, i)">
				{{ overview.isSelected ? 'Collapse' : 'Expand' }}
				<img src="assets\overview\mdi_chevron-down.svg" alt="" [ngClass]="overview.isSelected ? 'content-open' : 'content-close'" />
			</span>
		</div>

		<section class="inside-card-container" *ngIf="overview.isSelected">
			<app-inside-card-container
				[currencySymbol]="overview.currencySymbol"
				[summaryData]="overview.summaryData"
				[adAccountId]="overview.adAccountId"
				[brandLogo]="brandLogo"
				[fromDate]="fromDate"
				[toDate]="toDate"
			></app-inside-card-container>
		</section>
	</div>
</section>

<section class="cards-full-overview-container">
	<!-- <img class="logo-img" src="assets/icons/nav-menu/submenu-icons/facebook-nav.svg" alt="facebook logo" /> -->
	<!-- <img src="assets/icons/arrow-dropdown.svg" alt=""> -->
</section>
