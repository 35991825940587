import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';

@Injectable({
	providedIn: 'root'
})
export class OverviewApiService {
	constructor(private adsService: AdsService, protected http: HttpClient) {}

	public getGoogleOptimizeData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/google/recommendations-overview`);
	}

	public getGooglePerformanceData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/overview/recommendation-count`);
	}

	public getFacebookOptimizeData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Optimization}ad-intelligence-optimization/facebook/recommendations-overview`);
	}

	public getFacebookPerformanceData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.AdIntelligence}ad-intelligence/creative-ai/fb/overview/recommendation-count`);
	}

	getCardsData(payload) {
		return this.adsService.getOverallCardsData(payload);
	}

	getGoogleAdAccount() {
		return this.adsService.getGoogleAdIDs();
	}

	getDoughnutChart(payload) {
		return this.adsService.getDoughnutChartData(payload);
	}

	getLineChartsData(payload) {
		return this.adsService.getLineChartsData(payload);
	}

	getHorizontalBarChartData(payload) {
		return this.adsService.getHorizontalBarChartData(payload);
	}

	getGoogleHistory() {
		return this.adsService.history();
	}

	getGoogleHighlights(payload) {
		return this.adsService.highlights(payload);
	}

	//facebook

	getFBCardsData(payload) {
		return this.adsService.getFBCardsData(payload);
	}

	getFbLineChartsData(payload) {
		return this.adsService.getFBLineCharts(payload);
	}

	getFacebookAdAccount() {
		return this.adsService.getFacebookAdaccounts();
	}

	getFacebookDoughnutChart(payload) {
		return this.adsService.getFacebookDoughnutChartData(payload);
	}

	getFacebookHistory() {
		return this.adsService.fbHistory();
	}

	getFacebookHorizontalBarChartData(payload) {
		return this.adsService.getFacebookHarizontalBarChartData(payload);
	}

	getFaceBookHighlights(payload) {
		return this.adsService.fbHighlights(payload);
	}
}
