import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-video-form',
	templateUrl: './video-form.component.html',
	styleUrls: ['./video-form.component.scss']
})
export class VideoFormComponent implements OnInit {
	templates: TemplatesModel[] = [...TEMPLATES];
	@Input() public selectedTemplate: TemplatesModel;
	@Input() public generatorForm: FormGroup = new FormGroup({});
	@Input() public advanceOptions: boolean = false;
	@Input() public resettingForm: Subject<boolean> = new Subject<boolean>();
	@Input() public validate: Subject<boolean> = new Subject<boolean>();
	public isHashTag = false;
	@Output() public validateChange: EventEmitter<boolean> = new EventEmitter();
	@Output() public changeToneSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public changeLangSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public advanceOptionChanged: EventEmitter<any> = new EventEmitter();
	public outputLang = [...LANGUAGES];
	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];
	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	public maxOutputs = 3;
	public blogKeywords = true;
	public keywordPlaceHolders = ['Enter here...', 'Enter here...', 'Enter here...', 'Enter here...', 'Enter here...'];
	public titleName = 'Product Name';
	public descName = 'Product Description';
	charLimit = 80;
	public menuExpanded: any;

	private unsubscriber$ = new Subject<void>();

	constructor(private authenticationService: AuthenticationService, public activatedRoute: ActivatedRoute) {
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	ngOnInit(): void {
		this.getActivatedRoute();
		switch (this.selectedTemplate.platform) {
			case 'pinterest':
				this.titleName = 'Product Name';
				this.descName = 'Describe your pin';
				break;
			case 'facebook':
				this.titleName = 'Title / Product Name';
				this.descName = 'Describe Your Post';
				break;
			case 'linkedin':
				this.titleName = 'Product / Brand Name';
				this.descName = 'Describe Your Post';
				break;
			case 'tiktok':
				this.titleName = 'Product Name';
				this.descName = 'Describe Your Topic';
				break;
			default:
				break;
		}
		if (this.selectedTemplate.title == 'HashTag Generator') {
			this.isHashTag = true;
		}
		this.titleName = this.isHashTag ? 'Topic' : 'Product Name';
		if (this.selectedTemplate.title == 'Youtube Video Description') {
			this.titleName = 'Tell us about your video';
			this.charLimit = 400;
		}
		this.validate.subscribe(res => {
			if (res) {
				this.checkValidation();
			}
		});
		this.resettingForm.subscribe(res => {
			if (res) {
				this.resetForm();
			}
		});
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['templateId']) {
				this.selectedTemplate = this.templates.find(template => {
					return template.id == params['templateId'];
				});
				if (this.selectedTemplate.template == 'headline') {
					this.maxOutputs = 5;
				}
			}
		});
	}
	public checkToggleValue(event) {
		this.advanceOptions = event;
	}

	public changeToneSource(event) {
		this.changeToneSourceEvent.emit(event);
	}

	public changeLangSource(event): void {
		this.changeLangSourceEvent.emit(event);
	}

	private resetForm() {
		this.removeValidators();
		this.advanceOptions = false;
		this.generatorForm.reset();
	}

	private checkValidation(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: [Validators.required]
			})
		);
		this.generatorForm.markAllAsTouched();
		this.generatorForm.updateValueAndValidity();
		this.validateChange.emit(this.generatorForm.valid);
	}
	public removeValidators(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: []
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: []
			})
		);
		this.generatorForm.reset();
	}
}
