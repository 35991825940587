<!--<div *ngIf="isToastVisible" class="toast-wrapper">
	<div class="toast-container">
		<div
			class="notification-content"
			[ngClass]="{
				'toast-info': toastModel.type === toastTypes.Info,
				'toast-error': toastModel.type === toastTypes.Error,
				'toast-warning': toastModel.type === toastTypes.Warning,
				'toast-success': toastModel.type === toastTypes.Success
			}"
		>
			<!~~ info~~>
			<div class="information-wrapper">
				<div [ngSwitch]="toastModel.type" class="toast-icon">
					<i *ngSwitchCase="toastTypes.Info" class="fas fa-info-circle info"></i>
					<i *ngSwitchCase="toastTypes.Success" class="fas fa-check-circle success"></i>
					<i
						*ngSwitchDefault
						[ngClass]="{ error: toastModel.type === toastTypes.Error, warning: toastModel.type === toastTypes.Warning }"
						class="fas fa-exclamation-triangle"
					></i>
				</div>
				<div class="content-toast">
					<h6
						[ngClass]="{
							info: toastModel.type === toastTypes.Info,
							error: toastModel.type === toastTypes.Error,
							warning: toastModel.type === toastTypes.Warning,
							success: toastModel.type === toastTypes.Success
						}"
					>
						{{ toastModel.customTitle }}
					</h6>
					<span>{{ toastModel.message }}</span>
				</div>
			</div>
			<!~~close button~~>
			<button (click)="onClick()" class="remove-button">
				<i class="fas fa-times"></i>
			</button>
		</div>
	</div>
</div>
-->

<div *ngIf="isToastVisible" class="toast-wrapper">
	<div class="backdrop-toast" (click)="dismiss()">
		<div class="toast-container" (click)="$event.stopPropagation()">
			<div class="notification-content">
				<div class="information-wrapper">
					<div [ngSwitch]="toastModel.type" class="toast-icon">
						<img src="assets/icons/toast/info.svg" *ngSwitchCase="toastTypes.Info" />
						<img src="assets/icons/toast/success.svg" *ngSwitchCase="toastTypes.Success" />
						<img src="assets/icons/toast/warning.svg" *ngSwitchCase="toastTypes.Warning" />
						<img src="assets/icons/toast/error.svg" *ngSwitchDefault />
					</div>
					<div class="content-toast">
						<h6
							[ngClass]="{
								info: toastModel.type === toastTypes.Info,
								error: toastModel.type === toastTypes.Error,
								warning: toastModel.type === toastTypes.Warning,
								success: toastModel.type === toastTypes.Success
							}"
						>
							{{ toastModel.customTitle }}
						</h6>
						<span>{{ toastModel.message }}</span>
						<span class="text-sm" *ngIf="toastModel.footerMessage != ''">{{ toastModel.footerMessage }}</span>
					</div>
				</div>
				<!-- <mat-icon class="close-btn" (click)="dismiss()">clear</mat-icon> -->
			</div>
		</div>
	</div>
</div>
