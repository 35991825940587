<mat-form-field class="example-chip-list custom-tag-input">
	<mat-chip-list #chipList [disabled]="false">
		<mat-chip *ngFor="let keyword of keywords" [selectable]="selectable" [removable]="removable" (removed)="remove(keyword)">
			{{ keyword }}
			<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
		</mat-chip>
		<input
			[placeholder]="placeholder"
			#keywordInput
			[(ngModel)]="selectedKeywords"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			[matChipInputAddOnBlur]="addOnBlur"
			(matChipInputTokenEnd)="add($event)"
			(focus)="onCustomFocus()"
		/>
	</mat-chip-list>
</mat-form-field>
