<button
	class="wrapper-button"
	[disabled]="loading || disabled"
	(click)="buttonClickedHandler($event)"
	[style.width]="buttonWidth"
	[ngClass]="{
		'primary-button': buttonClass === buttonClassEnum.Primary,
		'reverse-button': buttonClass === buttonClassEnum.Reverse,
		'secondary-button': buttonClass === buttonClassEnum.Secondary,
		'tertiary-button': buttonClass === buttonClassEnum.Tertiary,
		'gray-button': buttonClass === buttonClassEnum.Gray,
		'link-primary': buttonClass === buttonClassEnum.LinkPrimary,
		'link-secondary': buttonClass === buttonClassEnum.LinkSecondary,
		'link-tertiary': buttonClass === buttonClassEnum.LinkTertiary,
		'icon-button': buttonClass === buttonClassEnum.Icon,
		'icon-primary': buttonClass === buttonClassEnum.IconPrimary,
		'icon-secondary': buttonClass === buttonClassEnum.IconSecondary,
		'icon-tertiary': buttonClass === buttonClassEnum.IconTertiary,
		'disabled-button': disabled,
		'waiting-button': waiting,
		'full-width': width == '100',
		'space-before': icon !== '' && iconPlace == 'before' && buttonText !== undefined && buttonText !== '',
		'space-after': icon !== '' && iconPlace == 'after' && buttonText !== undefined && buttonText !== '',
		'full-width-primary': buttonClass === buttonClassEnum.FullWidthPrimaryBtn
	}"
>
	<ng-container>
		<span *ngIf="icon !== '' && iconPlace == 'before'" class="custom-icon {{ icon }} {{ iconPlace }}"></span>
		<img *ngIf="img !== '' && iconPlace == 'before'" class="custom-icon {{ iconPlace }}" [src]="img" />
		<span class="btn-text" #content>
			<ng-content
				[ngClass]="{ 'text-icon-btn': buttonType === buttonTypeEnum.Icon, 'dropdown-text-btn': buttonType === buttonTypeEnum.Dropdown }"
				class="text-button"
			></ng-content>
		</span>
		<span *ngIf="icon !== '' && iconPlace == 'after'" class="custom-icon {{ icon }} {{ iconPlace }}"></span>
		<img *ngIf="img !== '' && iconPlace == 'after'" class="custom-icon {{ iconPlace }}" [src]="img" />
	</ng-container>
	<div class="loader" *ngIf="loading"><img src="assets/shakespeare/text-editor/loading.svg" class="fa fa-spin" /></div>
</button>
