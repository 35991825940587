<div class="card-container">
	<form [formGroup]="radioForm">
		<div class="helper-head">
			<p *ngIf="!history" class="selectall-btn" (click)="checkAll()">{{ selectionText }}</p>
			<p class="product-count">Products - {{ products?.length }}</p>
		</div>
		<div class="card-con">
			<div class="product-card" *ngFor="let item of products | paginate: config; let i = index">
				<div class="header">
					<div class="title">
						<input *ngIf="!history" type="checkbox" formControlName="radio{{ i }}" />
						<div class="product">
							<img [src]="(item?.images)[0]" alt="" />
							<div class="dot" [ngClass]="{ red: item?.optimizationScore == 0 }"></div>
						</div>
						<p *ngIf="!newlyGenerated" (click)="openEditor(item?.id)">{{ item?.title }}</p>
						<p *ngIf="item?.generatedData?.title" (click)="openEditor(item?.id)">{{ item.generatedData.title }}</p>
					</div>
					<div class="edit-view">
						<span *ngIf="newlyGenerated" (click)="editDescription(item)" class="fas fa-pen"></span>
						<img (click)="previewProduct(item)" src="assets/icons/icon-eye.svg" alt="" />
					</div>
				</div>
				<div class="bottom">
					<div class="left" *ngIf="history || newlyGenerated">
						<p class="score">Description</p>
						<p class="value" [innerHtml]="item?.description"></p>
					</div>
					<div class="left" *ngIf="!history && !newlyGenerated">
						<p class="score">Optimization Score</p>
						<p class="value">{{ Math.round(item?.optimizationScore) }}</p>
					</div>
					<div class="right" *ngIf="item?.sku">
						<p class="score">Inventory</p>
						<p class="value">{{ item?.sku }}</p>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="footer" *ngIf="count && !newlyGenerated">
		<app-button buttonClass="tertiary" (onClick)="clearSelected()" icon="fas fa-times">{{ count }} Selected</app-button>
		<app-button buttonClass="primary" (onClick)="generateDescription()">Generate Descriptions</app-button>
	</div>
	<div class="footer" *ngIf="count && newlyGenerated">
		<app-button buttonClass="secondary" (onClick)="generateDescription()">Re-Generate</app-button>
		<app-button buttonClass="primary" (onClick)="confirmBulkSave()">Save to Big Commerce</app-button>
	</div>
	<div class="has-text-centered" *ngIf="products?.length > 0">
		<shakespeare-spinning-loader class="spinner" *ngIf="loading"></shakespeare-spinning-loader>
		<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
			<div class="custom-pagination">
				<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
					<a (click)="p.previous()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/left-icon.svg" alt="" /></a>
				</div>
				<div *ngFor="let page of p.pages" class="page" [class.current]="p.getCurrent() === page.value">
					<a (click)="getData(page.label)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
						<span>{{ page.label }}</span>
					</a>
					<div *ngIf="p.getCurrent() === page.value">
						<span>{{ page.label }}</span>
					</div>
				</div>
				<div class="pagination-next" [class.disabled]="p.isLastPage()">
					<a (click)="p.next()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/right-icon.svg" alt="" /></a>
				</div>
			</div>
		</pagination-template>
	</div>
</div>
