import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { TEMPLATES } from '../../shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { Subject } from 'rxjs';
import { ShakespeareService } from '../shakespeare.service';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { NoCreditModalComponent } from './no-credit-modal/no-credit-modal.component';
import { DocumentService } from '../documents/document.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { getFavoriteTemplates, UserPlanState } from 'src/app/state/shakespeare-common-state/shakespeare-common-reducer';
import { RegisterFavoriteTemplates } from 'src/app/state/shakespeare-common-state/shakespeare-common-action';
import { FormControl } from '@angular/forms';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Component({
	selector: 'app-templates',
	templateUrl: './templates.component.html',
	styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, OnDestroy {
	public hoveredIndex: number;

	public templates = [...TEMPLATES];
	private unsubscriber$ = new Subject<void>();

	public templateData: TemplatesModel[];
	public noTemplatesFound = false;
	public templateObj = {
		'all-templates': 'all-templates',
		'ad-copy': 'ad-copy',
		'product-description': 'product-description',
		blogs: 'blog',
		email: 'email',
		'website-pages': 'website-pages',
		'sales-copy': 'sales-copy',
		'content-improver': 'content',
		social: 'social',
		video: 'video',
		frameworks: 'framework',
		essay: 'essay',
		seo: 'seo',
		bio: 'bio'
	};

	public labelMapper = {
		'all-templates': 'All Templates',
		'ad-copy': 'Ad Copy',
		'product-description': 'Product Description',
		blogs: 'Blogs',
		email: 'Email',
		'website-pages': 'Website Pages',
		'sales-copy': 'Sales Copy',
		'content-improver': 'Content Improver',
		social: 'Social',
		video: 'Video',
		frameworks: 'Frameworks',
		essay: 'Essay',
		seo: 'SEO',
		bio: 'Bio'
	};
	public currentType: string = 'all-templates';
	public filedId: number;
	public isProUser = false;

	// public regularHeart = 'far fa-heart icon-heart';
	public filledHeartClass = 'fas fa-heart isFav icon-heart';

	public innerWidth: any;

	public searchCtrl = new FormControl('');

	customOptions: OwlOptions = {
		loop: false,
		mouseDrag: false,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		items: 4,
		nav: false
	};

	public favoriteTemplates = [];
	menuExpanded: any;
	loading: boolean;
	favoriteIds: string[];
	iPhoneMobile: boolean;
	isBonus: boolean;
	tooltipText: any;
	showBonus = true;
	textMapper = {
		templates: 'Choose a template to start writing',
		'ad-copy': 'Write ad copy for Google, Facebook, & LinkedIn',
		email: 'Generate email subject lines and copy',
		product: 'Write product descriptions for your e-commerce stores',
		blog: 'Draft full blog posts from headline to conclusion',
		content: 'Allow Shakespeare to rewrite your existing content',
		social: 'Craft captions for your social media posts',
		video: 'Find everything you need to make engaging videos',
		framework: 'Write sales copy using these proven frameworks',
		seo: 'Generate optimised title & meta descriptions to improve your SEO score',
		essay: 'Write optimised & complete essays from introduction to conclusion',
		bio: 'Create captivating bios for all your needs',
		projects: 'All of your copywriting projects in one place',
		folders: 'Organize your work into folders',
		history: 'Everything you and Shakespeare have created together',
		trash: 'Your recently deleted outputs, projects, and docs',
		documents: 'Manage all content created with Write From Scratch & Blog Wizard'
	};
	private _filterTemplates: string;

	constructor(
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private shakespeareService: ShakespeareService,
		private userStore: Store<UserState>,
		private documentService: DocumentService,
		private userApiService: UserServiceApi,
		private dialog: MatDialog,
		private backOfficeApiService: BackOfficeService,
		private authenticationService: AuthenticationService,
		private toastNotificationService: ToastNotificationService,
		private userPlanStore: Store<UserPlanState>
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token && token.sign_up_version && token.sign_up_version == 'v2') {
			this.showBonus = false;
		}
		this.shakespeareService.isBonusOffer$.subscribe(val => {
			this.isBonus = val;
		});
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	ngOnInit(): void {
		this.proUserStatus();
		this.getStateData();
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.shakespeareService.isUserPro$.subscribe(isPro => (this.isProUser = isPro));
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.activatedRoute.queryParams.subscribe(res => {
			const type = res['filter'];
			this.filterTemplatesBasedOnCategory(this.templateObj[type]);
			this._filterTemplates = type;
			this.currentType = type || 'all-templates';
			switch (type) {
				case 'templates':
				case 'all-templates':
					this.tooltipText = this.textMapper['templates'];
					break;
				case 'ad-copy':
					this.tooltipText = this.textMapper['ad-copy'];
					break;
				case 'email':
					this.tooltipText = this.textMapper['email'];
					break;
				case 'product-description':
					this.tooltipText = this.textMapper['product'];
					break;
				case 'blogs':
					this.tooltipText = this.textMapper['blog'];
					break;
				case 'content-improver':
					this.tooltipText = this.textMapper['content'];
					break;
				case 'social':
					this.tooltipText = this.textMapper['social'];
					break;
				case 'video':
					this.tooltipText = this.textMapper['video'];
					break;
				case 'seo':
					this.tooltipText = this.textMapper['seo'];
					break;
				case 'bio':
					this.tooltipText = this.textMapper['bio'];
					break;
				case 'essay':
					this.tooltipText = this.textMapper['essay'];
					break;
				case 'frameworks':
					this.tooltipText = this.textMapper['framework'];
					break;
			}
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	public navigateToBilling(): void {
		this.router.navigate(['/settings/billing'], { queryParams: { page: 'upgrade' } });
	}

	getStateData(): void {
		this.userPlanStore.pipe(select(getFavoriteTemplates), takeUntil(this.unsubscriber$)).subscribe(res => {
			if (res['FavoriteTemplates'] == undefined) {
				this.retrieveFavorites();
				return;
			}
			// else {
			this.favoriteIds = res['FavoriteTemplates'];
			this.templateData?.forEach(template => {
				if (this.favoriteIds?.includes(template.id.toString())) {
					template.isFav = true;
				}
			});
		});
	}

	retrieveFavorites() {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			}
		};
		this.loading = true;
		this.userApiService
			.getFavTemplates(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.templates.forEach(item => (item.isFav = false));
					this.templates.forEach(item => {
						if (item.pro) item.isFav = true;
					});
					this.templateData.forEach(template => {
						if (response['data'].includes(template.id.toString())) {
							template.isFav = true;
						}
					});
					this.userPlanStore.dispatch(new RegisterFavoriteTemplates(response['data']));
					this.sortData(true);
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	private proUserStatus() {
		let payload = {
			user: {
				userFiledId: this.filedId
			}
		};
		this.userApiService
			.getProUserStatus(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					response['isPro'] ? this.shakespeareService.isUserPro$.next(true) : this.shakespeareService.isUserPro$.next(false);
					this.isProUser = response['isPro'];
				},
				() => {},
				() => {}
			);
	}

	public addHeart(idx: number): void {
		this.hoveredIndex = idx;
	}

	public removeHeart(): void {
		this.hoveredIndex = null;
	}

	public addToFav(idx: number, event: Event, templateId: number): void {
		event.stopPropagation();
		if (templateId == 41 || templateId == 44) return;
		let curTemplate = this.templates.find(item => {
			return item.id == templateId;
		});
		if (curTemplate.isFav == true) {
			this.templateData.forEach(template => {
				if (template.id == templateId) {
					template.isFav = false;
					return;
				}
			});
			if (event.target['classList'].contains('fav')) {
				event.target['classList'].remove('fav');
			}
			this.favoriteIds = this.favoriteIds.filter(id => id != templateId.toString());
			event.target['classList'].add('icon-heart');
			this.removeTemplateFromFavorites(templateId);
		} else {
			this.templateData = this.templateData.map(template => {
				if (template.id == templateId) {
					template.isFav = true;
				}
				return template;
			});
			if (event.target['classList'].contains('icon-heart')) {
				event.target['classList'].remove('icon-heart');
			}
			event.target['classList'].add('fav');
			this.favoriteIds.push(templateId.toString());
			this.addTemplateToFavorites(templateId);
		}
		this.userPlanStore.dispatch(new RegisterFavoriteTemplates(this.favoriteIds));
		this.sortData();
	}

	public addTemplateToFavorites(id: number): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				template_id: id
			}
		};
		this.userApiService
			.addFavTemplates(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Added to favorites');
				},
				() => {},
				() => {}
			);
	}

	public removeTemplateFromFavorites(id: number): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				template_id: id
			}
		};
		this.userApiService
			.removeFavTemplates(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Removed from favorites');
				},
				() => {},
				() => {}
			);
	}

	public sortData(allTemplates = true): void {
		// sort list based on favourite templates
		let sortedList = [];
		this.favoriteTemplates = [];
		for (let template of this.templateData) {
			if (template.isFav || template.pro) {
				sortedList.unshift(template);
				this.favoriteTemplates.push(template);
			} else {
				sortedList.push(template);
			}
		}
		sortedList.sort((a, b) => b.pro - a.pro);
		this.templateData = [...sortedList];
		setTimeout(() => {
			this.loading = false;
		}, 500);
	}

	public searchTemplate(_input: string): void {
		let inputVal = _input.toLowerCase();
		this.debounce(this.filterTemplates(inputVal));
	}

	public filterTemplatesBasedOnCategory(type: string): void {
		this.searchCtrl.setValue('');
		console.log(type);
		if (!type || type === 'all-templates' || type == '') {
			this.templateData = this.templates;
			this.sortData();
			return;
		}
		let filteredData = this.templates.filter(template => {
			return template.category === type;
		});
		if (filteredData.length) {
			this.templateData = filteredData;
			this.sortData(false);
		} else {
			this.templateData = [];
			this.noTemplatesFound = true;
		}
	}

	public filterTemplates(value: string) {
		if (value === '') {
			this.templateData = this.templates;
			return;
		}
		let filteredData = this.templates.filter(template => {
			return template.title.toLowerCase().includes(value);
		});
		if (filteredData.length) {
			this.templateData = filteredData;
			this.sortData();
		} else {
			this.templateData = [];
			this.noTemplatesFound = true;
		}
	}

	public debounce(callback, delay: number = 500) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				callback(...args);
			}, delay);
		};
	}

	public openProCreditModal(): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Oops!',
			text: 'This feature is for Pro subscribers only.',
			actionBtnText: 'Upgrade to Pro',
			backRoute: '/home',
			cta: '/settings/billing',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			console.log(obj);
		});
	}

	public generateData(id: number): void {
		let template = this.templates.find(temp => {
			return temp.id === id;
		}).category;
		if (id === 44 && !this.isProUser) {
			this.openProCreditModal();
			return;
		}
		if (id === 44) {
			this.documentService.createNewFromScratch();
			return;
		}
		if (id === 41 && this.isProUser) {
			this.router.navigate([`templates/blog-wizard`]);
			return;
		}
		if (id === 41 && !this.isProUser) {
			this.openProCreditModal();
			this.router.navigate([`templates/blog-wizard`]);
			return;
		}
		this.router.navigate([`templates/${template}`], { queryParams: { templateId: id } });
	}

	ngOnDestroy(): void {
		this.unsubscriber$.unsubscribe();
	}
}
