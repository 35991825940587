import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { DropdownInterface } from 'src/app-components/interfaces/dropdown-interface';
import { takeUntil } from 'rxjs/operators';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { Subject, Subscription } from 'rxjs';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import moment from 'moment';
import { CreateNewModalComponent } from 'src/app/shared/create-new-modal/create-new-modal.component';
import { NoCreditModalComponent } from '../no-credit-modal/no-credit-modal.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-ad-copy-generator',
	templateUrl: './ad-copy-generator.component.html',
	styleUrls: ['./ad-copy-generator.component.scss'] // all generator components will use this scss file
})
export class AdCopyGeneratorComponent implements OnInit, OnDestroy {
	@ViewChild('scroll') private myScrollContainer: ElementRef;
	@ViewChild('output') public outputWrapper: ElementRef;
	templates: TemplatesModel[] = [...TEMPLATES];
	selectedTemplate: TemplatesModel;
	public outputData: any[] = [];
	public generatorForm: FormGroup;
	public error = false;
	public errorText = false;
	public ToneError = false;
	public numberError = false;
	private unsubscriber$ = new Subject<void>();
	public favouriteData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	public payload: object;
	public advanceOptions = false;
	public isDataFetched = false;
	public isSaveAllowed = false;
	public currentOutput = 3;

	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public ageArray = [
		{ name: 'All', value: '0' },
		{ name: '5-12', value: '5-12' },
		{ name: '13-19', value: '13-19' },
		{ name: '20-39', value: '20-39' },
		{ name: '40-59', value: '40-59' },
		{ name: '60', value: '60' }
	];

	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	public outputLang = [...LANGUAGES];
	public translate = false;
	public userFName: string;
	public min = 1;
	public max = 70;
	public largeStep = 1;
	public smallStep = 14;
	public sliderValue = [15, 29];
	public formButtonText = 'GENERATE';
	public selectedOutputLang = 'en';
	public selectedOutputTone: string;
	public oneTimeScroll = true;
	public maxOutputs = 3;
	public filedId: number;
	public selectedAge = '0';
	public shakespeareSub$: Subscription;
	iPhoneMobile: boolean;
	isHistoryFound: boolean;
	isMargin: boolean;
	innerWidth: number;
	public scrollToTop = false;
	historyData: any;
	projectId: string;
	multiHandler = [];
	hasHistory: any;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}
	public isOutput = true;
	public isHistory = false;
	menuExpanded: any;
	public resettingForm: Subject<boolean> = new Subject<boolean>();
	public validateForm: Subject<boolean> = new Subject<boolean>();

	constructor(
		private formBuilder: FormBuilder,
		private userServiceApi: UserServiceApi,
		private store: Store<SharedState>,
		public activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		private userStore: Store<UserState>,
		public toastNotificationService: ToastNotificationService,
		private shakespeareService: ShakespeareService,
		private authenticationService: AuthenticationService
	) {
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		// setTimeout(() => {
		// 	this.shakespeareService.triggerRoute$.next('templates'); // to make current sidenav item active
		// }, 300);
		let credits = this.shakespeareService.userCredits$.value;
		if (credits <= 50 && credits !== null) {
			this.openCreditModal();
		}

		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}
	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.createForm();
		this.generatorForm.get('name').valueChanges.subscribe(x => {
			this.formButtonText = 'GENERATE';
			if (this.generatorForm.controls.name.valid) {
				this.errorText = false;
			}
		});
		this.generatorForm.get('description').valueChanges.subscribe(x => {
			this.formButtonText = 'GENERATE';
			if (this.generatorForm.controls.description.valid) {
				this.error = false;
			}
		});
		this.generatorForm.get('outputTone').valueChanges.subscribe(x => {
			this.formButtonText = 'GENERATE';
		});
		this.getActivatedRoute();
		if (this.selectedTemplate.template == 'headline') {
			this.maxOutputArray = this.max5Output;
			this.generatorForm.patchValue({
				numOutputs: { name: '5 Outputs', value: 5 }
			});
		}
	}

	public openCreditModal(): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		if (this.shakespeareService.isUserPro$.value) {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		} else {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		}
		dialogRef.afterClosed().subscribe(obj => {});
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['templateId']) {
				this.selectedTemplate = this.templates.find(template => {
					return template.id == params['templateId'];
				});
				if (this.selectedTemplate.template == 'headline') {
					this.maxOutputArray = this.max5Output;
				}
			}
		});
	}

	public changeAgeSource(event): void {
		if (event) {
			this.selectedAge = event.value;
		}
	}

	public scrollBottom(): void {
		if (this.oneTimeScroll) {
			try {
				this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
			} catch {}
		}
	}

	private createForm(): void {
		this.generatorForm = this.formBuilder.group({
			name: new FormControl(''),
			description: new FormControl(''),
			outputTone: new FormControl(null),
			outputLang: new FormControl(null),
			numOutputs: new FormControl({ name: '3 Outputs', value: 3 }),
			persona: new FormControl(false),
			gender: new FormControl(''),
			age: new FormControl(),
			emoji: new FormControl(false)
		});
	}

	public setErrorMessage(): void {
		this.error = !this.generatorForm.controls.description.valid;
	}

	public setErrorTextMessage(): void {
		this.errorText = !this.generatorForm.controls.name.valid;
	}

	public changeToneSource(event: DropdownInterface): void {
		if (event) {
			this.selectedOutputTone = event.name;
		}
		if (!this.generatorForm.controls.outputTone.valid) {
			this.ToneError = true;
		}
	}

	public changeLangSource(event): void {
		if (event) {
			this.selectedOutputLang = event.id;
		} else {
			this.selectedOutputLang = '';
		}
	}

	public onRadioButtonClick(value: string): void {
		let gender = [value];
		if (value === 'nomatter') {
			gender = ['male', 'female'];
		}
		this.generatorForm.patchValue({
			gender: gender
		});
	}

	public checkInputValue(event, form): void {
		form.reportValidity();
		if (form.reportValidity()) {
			if (event.target.value <= this.maxOutputs) {
				this.generatorForm.patchValue({
					numOutputs: Number(event.target.value)
				});
				this.currentOutput = Number(event.target.value);
			} else {
				this.generatorForm.patchValue({
					numOutputs: this.maxOutputs
				});
				this.currentOutput = this.maxOutputs;
			}
		}
	}

	public updateValidators(): void {
		this.validateForm.next(true);
	}

	public onSubmit(): void {
		this.isHistory = false;
		this.isOutput = true;
		if (this.generatorForm.valid) {
			if (this.innerWidth <= 900) {
				this.outputWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				this.scrollToTop = true;
			}
			this.formButtonText = 'GENERATE MORE';
			this.isFormSubmitted = true;
			let details = {
				data: {
					userTemplate: {
						platform: this.selectedTemplate.platform,
						template: this.selectedTemplate.template,
						businessOwnerId: '1234'
					},
					userInput: {
						title: this.generatorForm.controls.name.value,
						description: this.generatorForm.controls.description.value,
						tone: this.selectedOutputTone,
						outputLanguage: this.selectedOutputLang || 'en',
						translate: false,
						numOutputs: this.generatorForm.controls.numOutputs.value.value,
						persona: this.generatorForm.controls.persona.value,
						gender: this.generatorForm.controls.gender.value ? this.generatorForm.controls.gender.value : ['male'],
						emoji: this.generatorForm.controls.emoji.value ? true : false,
						age: []
					}
				},
				extras: this.selectedTemplate
			};
			if (this.selectedAge !== '0') {
				details.data.userInput['age'] = [this.selectedAge];
			}
			if (this.selectedOutputLang !== 'en') {
				details.data.userInput.translate = true;
			}
			if (this.selectedOutputLang == '') {
				details.data.userInput.translate = false;
			}
			if (!this.advanceOptions) {
				details.data.userInput['age'] = [];
				details.data.userInput.translate = false;
			}
			this.payload = details;
		} else {
			Object.keys(this.generatorForm.controls).forEach(key => {
				this.generatorForm.controls[key].markAsDirty();
				this.generatorForm.controls[key].markAsTouched();
			});
		}
	}

	public openCreateModal(isMobile = false): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'New Project',
			text: 'Enter a name to save.',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.value) {
				this.payload ? this.saveProjectHelper(obj.value) : this.saveProjectFromHistory(obj.value);
			}
			if (obj.id) {
				this.payload ? this.addToProjectHelper(obj.id) : this.addToProjectFromHistory(obj.id);
			}
		});
	}

	public saveProjectFromHistory(name: string): void {
		let temp = true;
		let data = this.historyData[0].projectData;
		let output = this.historyData.filter(item => item.isFav == true);
		output.forEach(element => {
			let payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					name: name || 'untitled-' + moment().format(),
					userTemplate: data.userTemplate,
					userInput: data.userInput,
					userOutput: [element]
				}
			};
			if (temp) {
				payload['data']['name'] = name || 'untitled-' + moment().format();
			} else {
				payload['data']['id'] = this.projectId;
			}
			temp ? this.saveProject(payload) : this.multiHandler.push(payload);
			temp = false;
			// this.saveProject(payload);
		});
	}

	public handleMultiple(): void {
		if (this.multiHandler.length == 0) return;
		this.multiHandler.forEach(payload => {
			payload['data']['id'] = this.projectId;
			this.addToProject(payload);
		});
	}

	public addToProjectFromHistory(id: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: id,
				userTemplate: this.payload['data'].userTemplate,
				userInput: this.payload['data'].userInput,
				userOutput: this.favouriteData
			}
		};
		this.addToProject(payload);
	}

	public saveProjectHelper(name: string): void {
		let temp = true;
		this.favouriteData.forEach(element => {
			let payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					name: name || 'untitled-' + moment().format(),
					userTemplate: this.payload['data'].userTemplate,
					userInput: this.payload['data'].userInput,
					userOutput: [element]
				}
			};
			if (temp) {
				payload['data']['name'] = name || 'untitled-' + moment().format();
			} else {
				payload['data']['id'] = this.projectId;
			}
			temp ? this.saveProject(payload) : this.multiHandler.push(payload);
			temp = false;
			// this.saveProject(payload);
		});
	}

	public addToProjectHelper(id: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: id,
				userTemplate: this.payload['data'].userTemplate,
				userInput: this.payload['data'].userInput,
				userOutput: this.favouriteData
			}
		};
		this.addToProject(payload);
	}

	public saveProject(payload: object): void {
		this.userServiceApi
			.createUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'Project with same name exists already') {
						this.toastNotificationService.sendErrorToast('Operation failed! Project name already exists');
						return;
					}
					this.projectId = response['body'].projectId;
					this.handleMultiple();
					this.toastNotificationService.sendSuccessToast('Project created');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		this.isSaveAllowed = false;
	}

	public addToProject(payload: object): void {
		this.userServiceApi
			.addToUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'project data already existed') {
						this.toastNotificationService.sendErrorToast('Operation failed! Content already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Output added!');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		this.isSaveAllowed = false;
	}

	public onCheckToggleView(checked: boolean): void {
		this.advanceOptions = checked;
		this.generatorForm.patchValue({
			persona: checked,
			age: ['All']
		});
		if (checked) {
			setTimeout(() => {
				this.scrollBottom();
			}, 700);
		}
	}

	public enableViewMore(isFetched: boolean): void {
		this.isDataFetched = isFetched;
		if (isFetched) {
			this.isFormSubmitted = !isFetched;
		}
	}

	public handleCallback(data): void {
		this.historyData = data;
		this.favouriteData = [];
		this.favouriteData = data.filter(template => {
			return template.isFav == true;
		});
		if (this.favouriteData.length > 0) {
			this.isSaveAllowed = true;
		} else {
			this.isSaveAllowed = false;
		}
	}

	public resetForm(): void {
		this.generatorForm.reset();
		this.advanceOptions = false;
		this.resettingForm.next(true);
		if (this.innerWidth <= 900) {
			this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.generatorForm.controls.numOutputs.setValue({ name: '3 Outputs', value: 3 });
		this.generatorForm.controls.persona.setValue(false);
	}

	public onCheckToggleViewEmoji(checked: boolean): void {
		this.generatorForm.patchValue({
			emoji: checked
		});
	}

	public handleGeneratedData(event: any): void {
		this.outputData = event;
		this.isMargin = true;
		if (this.innerWidth <= 900) {
			setTimeout(() => {
				this.outputWrapper.nativeElement.scrollIntoView({ top: '53px', behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 300);
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber$.unsubscribe();
	}

	public isHistoryData(event): void {
		this.isHistoryFound = event;
	}

	public deleteAll(): void {
		const reqObj = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				template_name: this.selectedTemplate.title
			}
		};
		this.userServiceApi.deleteTemplateHistoryItem(reqObj).subscribe(res => {
			this.toastNotificationService.sendSuccessToast('All history deleted successfully');
			this.isHistoryFound = false;
		});
	}
	public onValidateInput(event: boolean): boolean {
		if (event) {
			this.onSubmit();
		} else {
			if (this.innerWidth <= 900) {
				this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
		return false;
	}
}
