<div *ngIf="!isTextEditor">
	<div class="output" *ngIf="isDescriptionData">
		<div *ngFor="let item of historyData; let i = index" [id]="'desc-h' + item?.id" class="group">
			<div class="content desc" *ngIf="item?.mainDescription">
				<div class="text-left">
					<div class="actions other-actions">
						<div class="left-actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Create Project"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('desc' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
							<span
								[ngClass]="item?.isLiked ? 'liked' : 'like'"
								matTooltip="Like"
								[matTooltipPosition]="'above'"
								(click)="addToLiked('desc' + item?.id, item, i)"
							></span>
							<span
								[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
								matTooltip="Dislike"
								[matTooltipPosition]="'above'"
								(click)="addToDisliked('desc' + item?.id, item, i)"
							></span>
							<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('desc' + item?.id, item)"></span>
						</div>
						<div class="right-actions">
							<span class="view" matTooltip="View" (click)="previewProduct(item)" [matTooltipPosition]="'above'"></span>
							<button class="edit-btn" (click)="switchToEditor(item)">
								<span class="edit fas fa-pen"></span>
								Edit
							</button>
							<app-button class="shopify-btn" buttonClass="primary" [loading]="isSaving" (onClick)="confirmSave(item)">
								Save to Big Commerce
							</app-button>
							<app-button class="shopify-btn-mobile" buttonClass="link-primary" [loading]="isSaving" (onClick)="confirmSave(item)">
								Save to Big Commerce
							</app-button>
						</div>
					</div>
					<div class="text" [innerHTML]="item?.mainDescription"></div>
				</div>
			</div>
			<div *ngFor="let subItem of item.uspDescription">
				<div class="content-2" *ngIf="subItem?.description">
					<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
					<span class="text" [innerHTML]="subItem?.description"></span>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="isTextEditor" class="text-editor-con">
	<app-custom-text-editor
		[editorMode]="editorMode"
		[filedId]="filedId"
		[isGeneratingOutput]="isGeneratingOutput"
		(onAutoGenerate)="onSubmitEditor()"
		(onSaveDocument)="saveDocument()"
		(getEditorControl)="getEditorControl($event)"
		[showGrammar]="showGrammar"
		[aiSelectionProp]="aiSelectionProp"
		(onWritingStart)="writingStart($event)"
		(onWritingEnd)="writingEnd($event)"
		[sendTextToEditor]="presentEditorText$"
		[showBackButton]="true"
		[deleteAllText]="isDeleteAllText$"
		(goBackHandler)="handleBackButton($event)"
	></app-custom-text-editor>
	<div class="bottom-assist">
		<div class="con">
			<div class="left-btns">
				<app-button buttonClass="secondary" (onClick)="aiAssist()">Ai Assist</app-button>
				<div (click)="deleteAll()" class="erase hide-mobile" matTooltipClass="tooltipClass" [matTooltip]="'Erase all history'">
					<button class="erase-btn"><i class="fa-solid fa-eraser"></i></button>
				</div>
			</div>
			<app-button buttonClass="primary" (onClick)="saveEditorTextToBc()">Save to Big Commerce</app-button>
		</div>
	</div>
</div>
