<div class="cancel-container">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title">Would you like to put Shakespeare on pause?</p>
		<div class="box">
			<p class="title">
				By Canceling Shakespeare, you will lose:
			</p>
			<p class="point">
				<em class="k-icon k-i-close k-i-x"></em>
				All Previous Projects & Outputs
			</p>
			<p class="point">
				<em class="k-icon k-i-close k-i-x"></em>
				Team Functionality
			</p>
			<p class="point">
				<em class="k-icon k-i-close k-i-x"></em>
				Access to Bonus Features
			</p>
			<p class="point">
				<em class="k-icon k-i-close k-i-x"></em>
				Ability to Write World-Class Copy in Minutes
			</p>
		</div>
		<p class="sub-title">By pausing, you can circle back when the time is right and pick up where you left off.</p>
		<p class="pause-title">Pause your subscription for 1 month.</p>
		<div class="pause-con">
			<!-- <div class="month">1 month</div> -->
			<!-- pointer events set to none -->
			<button (click)="pausePlan()">Pause Shakespeare</button>
		</div>
	</div>
	<div class="footer">
		<button class="mind" (click)="closeModal()">Changed My Mind</button>
		<button class="no" (click)="proceedToCancel()">No, Thank You</button>
	</div>
</div>
