import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LANGUAGES } from '../../../shared/models/languages.const';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-blog-generator-form',
	templateUrl: './blog-generator-form.component.html',
	styleUrls: ['./blog-generator-form.component.scss']
})
export class BlogGeneratorFormComponent implements OnInit {
	@Input() public generatorForm: FormGroup = new FormGroup({});
	@Input() public advanceOptions: boolean = false;
	@Input() public blogKeywords: boolean = false;
	@Input() selectedTemplate: any;
	@Input() public resettingForm: Subject<boolean> = new Subject<boolean>();
	@Input() public validate: Subject<boolean> = new Subject<boolean>();
	@Output() public validateChange: EventEmitter<boolean> = new EventEmitter();
	@Output() public changeToneSource: EventEmitter<any> = new EventEmitter();
	@Output() public changeSelectedLangSource: EventEmitter<any> = new EventEmitter();
	@Output() public changeBlogLength: EventEmitter<any> = new EventEmitter();
	@Output() public advanceOptionChanged: EventEmitter<any> = new EventEmitter();
	public keywordPlaceHolders = ['Enter here...', 'Enter here...', 'Enter here...', 'Enter here...', 'Enter here...'];
	public blogLength = 'medium';
	public outputData: any[] = [];
	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public outputLang = [...LANGUAGES];
	private selectedOutputLang = 'en';
	blogCTA: boolean = false;
	public oneTimeScroll = true;
	@ViewChild('scroll') private myScrollContainer: ElementRef;

	constructor() {}

	ngOnInit(): void {
		this.blogCTA =
			this.selectedTemplate.title === 'Full Blog' || this.selectedTemplate.title === 'Blog Conclusion' || this.selectedTemplate.template === 'Essay';
		this.validate.subscribe(res => {
			if (res) {
				this.checkValidation();
			}
		});
		this.resettingForm.subscribe(res => {
			if (res) {
				this.resetForm();
			}
		});
	}

	public changeLangSource(event): void {
		if (event) {
			this.selectedOutputLang = event.id;
		} else {
			this.selectedOutputLang = '';
		}
		this.changeSelectedLangSource.emit(this.selectedOutputLang);
	}

	public onChangeLength(length: string): void {
		this.blogLength = length;
		this.changeBlogLength.emit(this.blogLength);
	}

	public checkToggleValue(event) {
		this.advanceOptions = event;
		this.advanceOptionChanged.emit(this.advanceOptions);
		if (event) {
			setTimeout(() => {
				this.scrollBottom();
			}, 700);
		} else {
		}
	}

	public scrollBottom(): void {
		if (this.oneTimeScroll) {
			try {
				this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
			} catch {}
		}
	}
	public checkValidation(): void {
		this.generatorForm.setControl(
			'topic',
			new FormControl(this.generatorForm.controls.topic.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: [Validators.required]
			})
		);
		this.generatorForm.markAllAsTouched();
		this.generatorForm.updateValueAndValidity();
		this.validateChange.emit(this.generatorForm.valid);
	}
	public resetForm(): void {
		this.removeValidators();
		this.generatorForm.reset();
		this.advanceOptions = false;
		this.generatorForm.controls.numOutputs.setValue({ name: '3 Outputs', value: 3 });
	}
	public removeValidators(): void {
		this.generatorForm.setControl(
			'topic',
			new FormControl(this.generatorForm.controls.topic.value, {
				validators: []
			})
		);

		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: []
			})
		);
	}
}
