import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
	selector: 'app-primary-calender',
	templateUrl: './primary-calender.component.html',
	styleUrls: ['./primary-calender.component.scss']
})
export class PrimaryCalenderComponent implements OnInit {
	currentDate = new Date();
	selected: any;
	alwaysShowCalendars: boolean;
	// sendStartDate: any;
	step = 0;
	isReload = true;

	@Input() public defaultStartDate?: any;
	@Input() public defaultEndDate?: any;
	@Output() sendStartDate: EventEmitter<any> = new EventEmitter();
	@Output() sendEndDate: EventEmitter<any> = new EventEmitter();

	ranges: any = {
		Today: [moment(), moment()],
		Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('M'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
		'This Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
		'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')]
	};
	updateRange() {
		return {
			Today: [moment(), moment()],
			Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			'Last 7 Days': [moment().subtract(6, 'days'), moment()],
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			'This Month': [moment().startOf('month'), moment().endOf('month')],
			'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
			'This Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
			'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')]
		};
	}
	constructor() {
		// this.selected = {
		// 	startDate: this.currentDate,
		// 	endDate: new Date(this.currentDate.getTime() - 29 * 24 * 60 * 60 * 1000)
		// };
	}

	ngOnInit(): void {
		if (this.step == 0) {
			// this.selected = { startDate: this.defaultStartDate || moment().subtract(29, 'days'), endDate: this.defaultEndDate || moment() };
			this.selected = { startDate: this.defaultStartDate || moment(), endDate: this.defaultEndDate || moment() };
			this.step = 1;
		}

		let startDate = this.selected.startDate.toISOString().split('T')[0];
		let endDate = this.selected.endDate.toISOString().split('T')[0];

		if (!localStorage.getItem('fromDate') || !localStorage.getItem('toDate')) {
			localStorage.setItem('fromDate', startDate);
			localStorage.setItem('toDate', endDate);
			console.log(localStorage.getItem('fromDate'), localStorage.getItem('toDate'));
		} else {
		}

		this.sendStartDate.emit(startDate);
		this.sendEndDate.emit(endDate);
	}

	datesUpdated(event) {
		if (event.startDate && event.endDate) {
			let startDate = event.startDate.toISOString().split('T')[0];

			let endDate = event.endDate.toISOString().split('T')[0];

			this.sendStartDate.emit(startDate);
			this.sendEndDate.emit(endDate);
			this.isReload = false;
			setTimeout(() => {
				this.isReload = !this.isReload;
			}, 100);
		}
	}

	update() {
		this.selected = this.selected;

		// this.selected = { startDate: moment(), endDate: moment() };
		// console.log(this.step, 'updated', this.selected);

		// this.updateRange();
	}
}
