import { PaymentService } from 'src/app/user-management/components/payment.service';
import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { LogInSuccess, RegisterUserEmail, RegisterUserLastName, RegisterUserName, RegisterUserPassword } from '../../state/authentication.action';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { UserDetailModel } from '../../_models/login.model';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { TokenService } from 'src/app/_services/token.service';

declare var google: any;

@Component({
	selector: 'app-email-register',
	templateUrl: './email-register-new.component.html',
	styleUrls: ['./email-register-new.component.scss']
})
export class EmailRegisterComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('form') formRef: NgForm;
	public errorMessage: string;
	public emControl: FormControl;
	public src: any;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;
	public emailErrorText = 'Enter a valid email';
	public passFormControl = new FormControl('', {
		validators: [
			Validators.required,
			Validators.minLength(3),
			Validators.maxLength(50),
			Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
		],
		updateOn: 'blur'
	});
	public confirmpassFormControl = new FormControl('', {
		validators: [
			Validators.required,
			Validators.minLength(3),
			Validators.maxLength(50),
			Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
		],
		updateOn: 'change'
	});
	registrationDetais: UserDetailModel;
	public subscribeData;
	error: boolean;
	showConfirmPasswordError = null;
	public productId: string;
	public priceID: string;
	public credits: number;
	private param;

	private googleCientId: string;

	public signupForm: FormGroup;
	public passwordValidationIcon = null;
	public emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	private decodedToken: any;
	private firstPromoterRef = null;
	referralLink: any;
	utmSource: string;
	utmMedium: string;
	utmTerm: string;
	utmCampaign: string;
	hsaKw: string;
	googleAllowed: any = false;

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private user: UserServiceApi,
		private tokenService: TokenService,
		private toastNotificationService: ToastNotificationService,
		private sharedStore: Store<SharedState>,
		private activatedRoute: ActivatedRoute,
		private backOfficeService: BackOfficeService,
		private paymentService: PaymentService,
		private fb: FormBuilder,
		private ref: ChangeDetectorRef,
		private ngZone: NgZone,
		private authenticationService: AuthenticationService
	) {
		this.googleCientId = environment.GOOGLE_CLIENT_ID;
		this.router;
	}

	public ngOnInit(): void {
		window.onload = () => {
			google.accounts.id.initialize({
				client_id: this.googleCientId,
				callback: res => this.signUpWithGoogle(res)
			});
			google.accounts.id.renderButton(
				document.getElementById('google-btn'),
				{ theme: 'outline', size: 'large' } // customization attributes
			);
			google.accounts.id.prompt(); // also display the One Tap dialog
		};
		this.changeUrl();
		this.emControl = new FormControl('', {
			validators: [Validators.required, Validators.maxLength(70), Validators.pattern(this.emailValidator)],
			updateOn: 'blur'
		});
		this.initForm();
		this.formChanges();
		this.getRegistrationDetails();
	}

	public initForm(): void {
		this.signupForm = this.fb.group({
			firstName: new FormControl(''),
			lastName: new FormControl(''),
			email: new FormControl('', {
				validators: [Validators.required, Validators.maxLength(70), Validators.pattern(this.emailValidator)]
				// updateOn: 'blur'
			}),
			password: new FormControl(''),
			confirmPassword: new FormControl('')
		});
		this.updateValidators();

		this.signupForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			if (this.signupForm.controls.password.value !== this.signupForm.controls.confirmPassword.value) {
				this.signupForm.controls.confirmPassword.setErrors({ NoPasswordMatch: true });
			} else {
				this.signupForm.controls.confirmPassword.setErrors(null);
			}
		});
	}

	public updateValidators(): void {
		this.signupForm.setControl(
			'firstName',
			new FormControl(this.signupForm.controls.firstName.value, {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z\s]+$/)],
				updateOn: 'change'
			})
		);

		this.signupForm.setControl(
			'lastName',
			new FormControl(this.signupForm.controls.lastName.value, {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z\s]+$/)],
				updateOn: 'change'
			})
		);

		this.signupForm.setControl(
			'email',
			new FormControl(this.signupForm.controls.email.value, {
				validators: [Validators.required, Validators.maxLength(70), Validators.pattern(this.emailValidator)],
				updateOn: 'change'
			})
		);

		this.signupForm.setControl(
			'password',
			new FormControl(this.signupForm.controls.password.value, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(50),
					Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
				],
				updateOn: 'change'
			})
		);

		this.signupForm.setControl(
			'confirmPassword',
			new FormControl(this.signupForm.controls.confirmPassword.value, {
				validators: [
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(50),
					Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
				],
				updateOn: 'change'
			})
		);
		this.signupForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			if (this.signupForm.controls.password.value !== this.signupForm.controls.confirmPassword.value) {
				this.signupForm.controls.confirmPassword.setErrors({ NoPasswordMatch: true });
			} else {
				this.signupForm.controls.confirmPassword.setErrors(null);
			}
		});
	}

	public ngOnDestroy(): void {}

	public changeUrl(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params) {
				this.productId = params['productId'] || this.getCookie('productId') || 'quickSignup';
				this.priceID = params['priceID'] || this.getCookie('priceID') || '';
				this.credits = params['credits'] || '10000';
				this.firstPromoterRef = params['fpr'] || this.getCookie('firstPromoterRef') || null;
				this.referralLink = params['lp' || this.getCookie('referralLink')] || 'undetected';
				this.utmSource = params['utm_source'] || this.getCookie('utmSource') || 'undetected';
				this.utmMedium = params['utm_medium'] || this.getCookie('utmMedium') || 'undetected';
				this.utmTerm = params['utm_term'] || this.getCookie('utmTerm') || 'undetected';
				this.utmCampaign = params['utm_campaign'] || this.getCookie('utmCampaign') || 'undetected';
				// this.googleAllowed = params['app'] || 'false';
				this.src = params['src'] || '';
				this.hsaKw = null;
				// if (this.googleAllowed == 'v2') {
				// 	localStorage.setItem('googleAllowed', 'true');
				// } else {
				// 	localStorage.setItem('googleAllowed', 'false');
				// }
				this.saveParams();
				localStorage.setItem('urlSrc', this.src);
			}
		});
	}

	saveParams(): void {
		this.setCookie('utmSource', this.utmSource);
		this.setCookie('utmMedium', this.utmMedium);
		this.setCookie('utmTerm', this.utmTerm);
		this.setCookie('utmCampaign', this.utmCampaign);
		this.setCookie('referralLink', this.referralLink);
		this.setCookie('productId', this.productId);
		this.setCookie('priceID', this.priceID);
		this.setCookie('firstPromoterRef', this.firstPromoterRef);
		this.setCookieAcrossDomains('googleAllowed', this.googleAllowed);
	}

	setCookieAcrossDomains(name: string, value: any): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}

	public formChanges(): void {
		this.signupForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			if (this.signupForm.controls.firstName.valid) {
				this.store.dispatch(new RegisterUserName(this.signupForm.controls.firstName.value));
			}
			if (this.signupForm.controls.lastName.valid) {
				this.store.dispatch(new RegisterUserLastName(this.signupForm.controls.lastName.value));
			}
			if (this.signupForm.controls.email.valid) {
				this.store.dispatch(new RegisterUserEmail(this.signupForm.controls.email.value));
			}
			if (this.signupForm.controls.password.valid) {
				this.store.dispatch(new RegisterUserPassword(this.signupForm.controls.password.value));
			}
		});
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), take(1)).subscribe(detail => {
			if (detail) {
				this.registrationDetais = detail;
				this.signupForm.controls.email.setValue(detail.email);
				this.signupForm.controls.firstName.setValue(detail.name);
				this.signupForm.controls.lastName.setValue(detail.lastname);
			}
		});
	}
	public sleep(ms: number): Promise<any> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	ngAfterViewInit(): void {
		// debugger;
		// console.log(this.googleCientId);
		// google?.accounts?.id.initialize({
		// 	client_id: this.googleCientId,
		// 	callback: (response: any) => {
		// 		debugger;
		// 		this.signUpWithGoogle(response)
		// 	},
		// 	ux_mode: 'popup'
		// });
		// google.accounts.id.renderButton(
		// 	document.getElementById('google-btn'),
		// 	{ size: 'rectangular', width: '424', height: '48' } // customization attributes
		// );
		// google.accounts.id.prompt();
	}

	// signinWithGoogle() {
	// 	google?.accounts?.id.initialize({
	// 		client_id: this.googleCientId,
	// 		callback: (response: any) => this.signUpWithGoogle(response),
	// 		ux_mode: 'redirect'
	// 	});
	// 	google?.accounts?.id.renderButton(
	// 		document.getElementById('google-btn'),
	// 		{ size: 'rectangular', width: '424', height: '48' } // customization attributes
	// 	);
	// }
	public decodeToken(token): void {
		let base64Url = token.credential.split('.')[1];
		let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		let jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		this.decodedToken = JSON.parse(jsonPayload);
	}

	public setCookie(cname, cvalue) {
		// far future expiry date
		let expires = 'expires=' + new Date(2147483647 * 1000).toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	}

	signUpWithGoogle(token): void {
		// debugger;
		this.decodeToken(token);
		this.submitting = true;
		let ref = this.getCookie('skpr_ref_source');
		this.ref.detectChanges();
		let payload = {
			offerId: this.productId, // offerId is productId
			priceId: this.priceID,
			credit: this.credits,
			idToken: token.credential,
			Reference: ref || '',
			ReferenceLink: this.referralLink,
			utmSource: this.utmSource,
			utmMedium: this.utmMedium,
			utmTerm: this.utmTerm,
			utmCampaign: this.utmCampaign,
			hsaKw: this.hsaKw
		};

		if (this.src) {
			payload['src'] = this.src;
		}

		if (this.productId != 'freePass') {
			payload['subUsers'] = 5;
		}
		this.setCookie('from', 'Google');
		if (token) {
			let emailId = this.decodedToken.email;
			this.activatedRoute.queryParams.subscribe(params => {
				if (params['app'] == '2v') {
					if (params['priceId']) {
						payload['priceId'] = params['priceId'];
					}
					this.user
						.googleV2Signup(payload)
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(
							response => {
								if (response && response.isSuccess) {
									this.submitting = false;
									window['dataLayer'].push({
										event: 'successful_registration',
										userEmail: emailId,
										pageUrl: window.location.href
									}); // invoking google tag manager code
									if (payload.offerId == 'quickSignup') {
										this.ngZone.run(() => {
											this.store.dispatch(new LogInSuccess(response.jwtToken));
										});
									}
									if (payload.offerId == 'freePass') {
										this.ngZone.run(() => {
											this.store.dispatch(new LogInSuccess(response.jwtToken));
										});
										window['fpr']('referral', { email: emailId }); // invoking first promoter code
										window['lpr']('referral', { email: emailId }); // invoking first promoter code
									} else {
										if (response.sessionId !== '') {
											this.paymentService.redirectToCheckout(response.sessionId);
										}
										this.ngZone.run(() => {
											this.store.dispatch(new LogInSuccess(response.jwtToken));
										});
									}
									// this.ref.detectChanges();
									// this.submitting = false;
									return;
								}
								this.submitting = false;
								if (response['error'][0].code == 'Lolly__Domain__NA__User__ExistsWithSameCriteria') {
									this.toastNotificationService.sendErrorToast('User already exist, please try Signup with different Email');
								} else {
									this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
								}
								// this.ref.detectChanges();
								this.submitting = false;
							},
							error => {
								this.submitting = false;
								if (error['error'][0].code == 'Lolly__Domain__NA__User__ExistsWithSameCriteria') {
									this.toastNotificationService.sendErrorToast('User already exist, please try Signup with different Email');
								} else {
									this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
								}
								this.ref.detectChanges();
							}
						);
				} else {
					this.user
						.googleSignup(payload)
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(
							response => {
								if (response && response.isSuccess) {
									this.submitting = false;
									window['dataLayer'].push({
										event: 'successful_registration',
										userEmail: emailId,
										pageUrl: window.location.href
									}); // invoking google tag manager code
									if (payload.offerId == 'quickSignup') {
										this.ngZone.run(() => {
											this.store.dispatch(new LogInSuccess(response.jwtToken));
										});
									}
									if (payload.offerId == 'freePass') {
										this.ngZone.run(() => {
											this.store.dispatch(new LogInSuccess(response.jwtToken));
										});
										window['fpr']('referral', { email: emailId }); // invoking first promoter code
										window['lpr']('referral', { email: emailId }); // invoking first promoter code
									} else {
										if (response.sessionId !== '') {
											this.paymentService.redirectToCheckout(response.sessionId);
										}
										this.ngZone.run(() => {
											this.store.dispatch(new LogInSuccess(response.jwtToken));
										});
									}
									// this.ref.detectChanges();
									// this.submitting = false;
									return;
								}
								this.submitting = false;
								if (response['error'][0].code == 'Lolly__Domain__NA__User__ExistsWithSameCriteria') {
									this.toastNotificationService.sendErrorToast('User already exist, please try Signup with different Email');
								} else {
									this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
								}
								// this.ref.detectChanges();
								this.submitting = false;
							},
							error => {
								this.submitting = false;
								if (error['error'][0].code == 'Lolly__Domain__NA__User__ExistsWithSameCriteria') {
									this.toastNotificationService.sendErrorToast('User already exist, please try Signup with different Email');
								} else {
									this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
								}
								this.ref.detectChanges();
							}
						);
				}
			});
		}
	}

	public emptyFormFields(): boolean {
		return (
			this.signupForm.controls.firstName.value === true &&
			this.signupForm.controls.lastName.value === true &&
			this.signupForm.controls.email.value === true &&
			this.signupForm.controls.password.value === true &&
			this.signupForm.controls.confirmPassword.value === true
		);
	}

	public onBlurEmail(event): void {
		const value = event?.target?.value;
		if (this.signupForm.controls.email.valid) {
			this.backOfficeService
				.validateEmail(value)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(response => {
					if (!response) {
						this.signupForm.controls.email.setErrors({ incorrect: true });
						this.emailErrorText = 'Email already exists';
					}
					//  else {
					// 	this.emailErrorText = 'Enter a valid email';
					// }
				});
		} else {
			if (!this.signupForm.controls.email.hasError('incorrect')) this.emailErrorText = 'Enter a valid email';
			// this.signupForm.controls.email.setErrors({ incorrect: null });
		}
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	public processRegistrationDetail(): void {
		if (!this.formFieldsValid() || this.emptyFormFields()) {
			this.signupForm.markAllAsTouched();
			return;
		} else {
			this.submitting = true;
			let ref = this.getCookie('skpr_ref_source');
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			this.subscribeData = {
				offerId: this.productId, // offerId is productId
				priceId: this.priceID,
				firstName: this.signupForm.controls.firstName.value,
				lastName: this.signupForm.controls.lastName.value,
				email: this.signupForm.controls.email.value,
				credit: this.credits,
				password: this.signupForm.controls.password.value,
				idToken: '',
				Reference: ref || '',
				ReferenceLink: this.referralLink,
				utmSource: this.utmSource,
				utmMedium: this.utmMedium,
				utmTerm: this.utmTerm,
				utmCampaign: this.utmCampaign,
				hsaKw: this.hsaKw
			};

			if (this.src) {
				this.subscribeData['src'] = this.src;
			}
			if (this.productId != 'freePass') {
				this.subscribeData['subUsers'] = 5;
			}

			this.error = false;
			this.setCookie('from', 'platform');
			this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
				if (params['app'] || params['priceId']) {
					if (params['priceId']) {
						this.subscribeData['priceId'] = params['priceId'];
					}
					this.backOfficeService.subscribeTosecondOfer(this.subscribeData).subscribe(
						response => {
							this.sharedStore.dispatch(new HideGlobalSpinner());
							if (response.isSuccess) {
								window['dataLayer'].push({
									event: 'successful_registration',
									userEmail: this.signupForm.controls.email.value,
									pageUrl: window.location.href
								}); // invoking google tag manager code
								this.tokenService.decodeToken(response.jwtToken);
								let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));

								if (token && token.sign_up_version && token.sign_up_version == 'v2') {
									localStorage.setItem('googleAllowed', 'true');
									if (response.sessionId) {
										this.paymentService.redirectToCheckout(response.sessionId);
									}
								} else {
									localStorage.setItem('googleAllowed', 'false');
								}
								switch (this.productId) {
									case 'quickSignup':
										this.authenticationService.signupVersion$.next(this.productId);
										break;
									case 'quickSignup2':
										this.authenticationService.signupVersion$.next(this.productId);
										break;
									case 'quickSignup3':
										this.authenticationService.signupVersion$.next(this.productId);
										break;
								}
								if (response.jwtToken) {
									window['fpr']('referral', { email: this.subscribeData.email }); // invoking first promoter code
									window['lpr']('referral', { email: this.subscribeData.email });

									this.store.dispatch(new LogInSuccess(response.jwtToken));

									if (this.productId !== 'freePass' && this.productId != '') {
										try {
											this.paymentService.redirectToCheckout(this.productId);
										} catch (e) {
											console.warn('invalid sessionId: ', e);
										}
									}
								} else {
									this.submitting = false;
									this.toastNotificationService.sendErrorToast('Invalid response');
								}

								if (token?.sign_up_version == 'v3' && token?.user_account_state == '7') {
									this.router.navigate(['/authentication/v3enterprise']);
								}
							} else {
								this.submitting = false;
								this.toastNotificationService.sendErrorToast('Invalid response');
							}
						},
						error => {
							this.error = true;
							if (error['error'] && error['error'][0].code === 'Lolly__Domain__NA__User__ExistsWithSameCriteria') {
								this.toastNotificationService.sendErrorToast('A user already exists with this email');
							} else {
								this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
							}
							this.submitting = false;
							this.sharedStore.dispatch(new HideGlobalSpinner());
							return;
						}
					);
				} else {
					this.backOfficeService.subscribeToOfer(this.subscribeData).subscribe(
						response => {
							this.sharedStore.dispatch(new HideGlobalSpinner());
							if (response.isSuccess) {
								window['dataLayer'].push({
									event: 'successful_registration',
									userEmail: this.signupForm.controls.email.value,
									pageUrl: window.location.href
								}); // invoking google tag manager code
								switch (this.productId) {
									case 'quickSignup':
										this.authenticationService.signupVersion$.next(this.productId);
										break;
									case 'quickSignup2':
										this.authenticationService.signupVersion$.next(this.productId);
										break;
									case 'quickSignup3':
										this.authenticationService.signupVersion$.next(this.productId);
										break;
								}
								if (response.jwtToken) {
									window['fpr']('referral', { email: this.subscribeData.email }); // invoking first promoter code
									window['lpr']('referral', { email: this.subscribeData.email });

									this.store.dispatch(new LogInSuccess(response.jwtToken));

									if (this.productId !== 'freePass' && this.productId != '') {
										try {
											this.paymentService.redirectToCheckout(this.productId);
										} catch (e) {
											console.warn('invalid sessionId: ', e);
										}
									}
								} else {
									this.submitting = false;
									this.toastNotificationService.sendErrorToast('Invalid response');
								}
							} else {
								this.submitting = false;
								this.toastNotificationService.sendErrorToast('Invalid response');
							}
						},
						error => {
							this.error = true;
							if (error['error'] && error['error'][0].code === 'Lolly__Domain__NA__User__ExistsWithSameCriteria') {
								this.toastNotificationService.sendErrorToast('A user already exists with this email');
							} else {
								this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
							}
							this.submitting = false;
							this.sharedStore.dispatch(new HideGlobalSpinner());
							return;
						}
					);
				}
			});
		}
	}

	public formFieldsValid(): boolean {
		// this.updateValidators();
		let validity = false;

		if (this.signupForm.valid && this.signupForm.controls.password.value === this.signupForm.controls.confirmPassword.value) {
			validity = true;
		}
		return validity;
	}
}
