import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-drop-down-with-image',
	templateUrl: './drop-down-with-image.component.html',
	styleUrls: ['./drop-down-with-image.component.scss']
})
export class DropDownWithImageComponent implements OnInit {
	@Input() public options: any[] = [];
	@Input() selectedOption: any;
	@Output() public changeOptions: EventEmitter<any> = new EventEmitter();
	public expanded = false;
	public searchControl: FormControl = new FormControl('');
	public filteredTemplate: any[] = [];
	private wasInside = false;

	@HostListener('click')
	clickInside() {
		this.wasInside = true;
	}

	@HostListener('document:click')
	clickout() {
		if (!this.wasInside) {
			this.expanded = false;
		}
		this.wasInside = false;
	}
	constructor() {}

	ngOnInit(): void {
		this.filteredTemplate = this.options;
	}

	toggle() {
		this.expanded = !this.expanded;
	}
	public filterTemplates(value: string) {
		if (!value || value.length === 0) {
			this.filteredTemplate = this.options;
			return;
		}
		let filteredData = this.options.filter(template => {
			return template.title.toLowerCase().includes(value?.toLowerCase() ?? '');
		});
		if (filteredData.length) {
			this.filteredTemplate = filteredData;
		} else {
			this.filteredTemplate = [];
		}
	}
}
