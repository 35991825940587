import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AdsService } from '../ads/ads.service';
import { ShakespeareService } from '../shakespeare.service';

@Component({
	selector: 'app-creative',
	templateUrl: './creative.component.html',
	styleUrls: ['./creative.component.scss']
})
export class CreativeComponent implements OnInit {
	googleConnected = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private shakespeareService: ShakespeareService,
		private router: Router,
		private adsService: AdsService
	) {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['connected'] == 'true') {
				// this.connectToCreative();
			}
		});
	}

	ngOnInit(): void {
		// this.connectToCreative();
		this.validateAccessToken();
	}

	validateAccessToken(): void {
		this.adsService.validateAccessToken().subscribe({
			next: (res: any) => {
				if (res) {
					this.connectToCreative();
				} else {
					this.connectToGoogle();
				}
			}
		});
	}

	connectToGoogle(): void {
		const code = this.getCookie('googleCode');
		if (code) {
			this.connectToCreative();
			return;
		}
		const url = environment.Flags.production ? 'app.shakespeare.ai' : 'dev.shakespeare.ai';
		const id = environment.GOOGLE_ADS_ACCOUNT_ID;
		this.saveCookie('googleRedirectType', 'creative');
		window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth&client_id=${id}`;
	}

	connectToCreative(): void {
		// sending localstorage token to devcreative.shakespeare.ai with redirect to /login
		let domain = environment.CREATIVE_URL;
		let token = localStorage.getItem('token');
		if (token && domain) {
			let url = `https://${domain}/authentication/auth?token=${token}`;
			// to simulate user-initiated event adding setTimeout
			setTimeout(() => {
				this.openCreativeHelper(url);
			}, 10);
			this.router.navigate(['']);
		}
	}

	openCreativeHelper(url: string): void {
		window.open(url, '_blank');
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}
}
