import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentConfirmPopupComponent } from 'src/app/authentication/new-signup/payment-confirm-popup/payment-confirm-popup.component';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';

@Component({
	selector: 'app-shopify-billing',
	templateUrl: './shopify-billing.component.html',
	styleUrls: ['./shopify-billing.component.scss']
})
export class ShopifyBillingComponent implements OnInit, OnDestroy {
	private unsubscriber$ = new Subject<void>();
	chargeId: number;
	loading: boolean;
	amount: number;
	transactionId: string;
	contentCredit: number;

	constructor(
		private activatedRoute: ActivatedRoute,
		private backOfficeService: BackOfficeService,
		private matDialog: MatDialog,
		private authStore: Store<AuthenticationState>
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params && params.charge_id) {
				this.chargeId = params.charge_id;
				console.log(this.chargeId);
				let isUpgrading = window.location.href.split('/').includes('authentication');
				this.chargeBillingId(!isUpgrading);
			}
		});
	}

	chargeBillingId(isUpgrading = false): void {
		this.loading = true;
		this.backOfficeService
			.chargeBillingId(this.chargeId, isUpgrading)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				this.loading = false;
				this.amount = response.amount;
				this.transactionId = response.transactionId;
				this.contentCredit = response.contentCredit;
				if (response.billingStatusValue == 'active') {
					this.backOfficeService.refreshUserToken().subscribe(token => {
						this.matDialog
							.open(PaymentConfirmPopupComponent, {
								height: '550px',
								width: '650px',
								data: {
									price: this.amount,
									reference: this.transactionId,
									isTrial: false
								},
								disableClose: true
							})
							.afterClosed();
						setTimeout(() => {
							this.authStore.dispatch(new LogInSuccess(token));
						}, 2000);
					});
				} else {
					this.backOfficeService.validateCharge().subscribe(response => {
						console.log(response);
					});
				}
			});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.unsubscribe();
	}
}
