import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdsService } from '../../ads.service';

@Component({
	selector: 'app-publish',
	templateUrl: './publish.component.html',
	styleUrls: ['./publish.component.scss']
})
export class PublishComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<PublishComponent>, @Inject(MAT_DIALOG_DATA) public data, private adsService: AdsService) {}

	ngOnInit(): void {}

	closeDialog(): void {
		if (this.data.cancelCallBack) {
			this.data.cancelCallBack(() => {
				this.dialogRef.close({
					cancel: true
				});
			});
		} else {
			this.dialogRef.close({
				cancel: true
			});
		}
	}

	confirm(): void {
		this.dialogRef.close({
			confirm: true
		});
	}
}
