import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdsService } from 'src/app/shakespeare/ads/ads.service';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-create',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
	public nextStep: number = 0;

	public campaigns: any[];
	public campaignData: any[];
	// public adGroups: any[];
	// public adSets: any[];
	public accountId: any;
	// public aiCategoryId: any;
	public providerType: any;
	public form: FormGroup;
	public showLoader: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<CreateComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public dataFromModal: any,
		private toastNotification: ToastNotificationService,
		private fb: FormBuilder,
		private adsService: AdsService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.campaigns = this.dataFromModal.campaigns;
		this.accountId = this.dataFromModal.accountId;
		this.providerType = this.dataFromModal.type.toLowerCase();

		if (!this.campaigns) {
			if (this.providerType == 'google') this.getCampaigns();
			else this.getFbCampaignList();
		}

		this.initForm();
		this.getCampaignData();
	}

	private initForm(): void {
		this.form = this.fb.group({
			campaign: new FormControl(null, [])
			// adGroup: new FormControl(null, []),
			// adSet: new FormControl(null, [])
		});
	}

	public close(): void {
		this.dialogRef.close({ data: false });
	}

	public addCalibration(): void {
		let selectedArray = [];
		const selectedCampaign = this.campaignData.find(data => data.camapignId == this.form.value.campaign.id);
		selectedCampaign['uid'] = this.uuidv4();
		selectedCampaign['selected'] = 'radio1';
		selectedArray.push(selectedCampaign);
		this.adsService.selectedCampaings$.next(selectedArray);
		this.close();

		if (this.providerType == 'google') {
			this.router.navigate(['optimization-ai/create-ai']);
		} else {
			this.router.navigate(['optimization-ai/facebook-ai']);
		}
	}

	private uuidv4(): string {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	private getCampaignData(): void {
		if (this.providerType == 'google') this.getGoogleCampaignData(this.accountId);
		else this.getFBCampaignData(this.accountId);
	}

	private getGoogleCampaignData(data): void {
		this.showLoader = true;
		this.adsService
			.aiCalibration(data)
			.subscribe(
				res => {
					this.campaignData = res?.campaigns;
				},
				err => {}
			)
			.add(() => {
				this.showLoader = false;
			});
	}

	private getFBCampaignData(data): void {
		this.showLoader = true;
		this.adsService
			.getFBCampaignData(data)
			.subscribe(
				res => {
					this.campaignData = res?.campaigns;
				},
				err => {}
			)
			.add(() => {
				this.showLoader = false;
			});
	}

	private getCampaigns(): void {
		this.adsService.getCampaignList(this.accountId).subscribe({
			next: (res: any) => {
				this.setData(res);
			},
			error: err => {}
		});
	}

	private getFbCampaignList(): void {
		this.adsService.getFbCampaignList(this.accountId).subscribe({
			next: (res: any) => {
				this.setData(res);
			},
			error: err => {
				// console.log('error');
			}
		});
	}

	private setData(res): void {
		if (res) {
			this.campaigns = res
				.filter((item: any) => {
					if (item.status !== 'REMOVED' && item.status !== 'PAUSED') return { id: item.id, name: item.name, status: item.status };
				})
				.sort((a, b) => (a.status.toLocaleUpperCase() < b.status.toLocaleUpperCase() ? -1 : 1));

			if (!this.campaigns?.length) {
				this.toastNotification.sendCustomToast(
					'No campaigns in this Ad Account select different Ad Account',
					ToastNotificationType.Warning,
					3000,
					'Oops'
				);
			}

			this.setDefaultCampaign();
		}
	}

	private setDefaultCampaign(): void {
		this.form.patchValue({
			campaign: this.campaigns[0]
		});
	}
}
