import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router, RouterOutlet } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';

@Component({
	selector: 'app-knowledgebase',
	templateUrl: './knowledgebase.component.html',
	styleUrls: ['./knowledgebase.component.scss']
})
export class KnowledgebaseComponent implements OnInit {
	@ViewChild(RouterOutlet) outlet: RouterOutlet;
	public kBaseUrl = 'https://knowledge.lolly.com/';
	public kBaseIFrame = `<iframe width="100%" height="100%" src="${this.kBaseUrl}"></iframe>`;
	// knowledgeBaseItemIterface[]
	public shakespeareBaseList = [
		{
			title: 'Shakespeare',
			name: 'shakespeare',
			imgUrl: '',
			description: ''
		}
	];

	public homeVisited = false;
	selectedBaseList: { title: string; name: string; imgUrl: string; description: string }[];
	constructor(
		public dialogRef: MatDialogRef<KnowledgebaseComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public dataFromModal: any,
		public router: Router,
		public kbService: KnowledgebaseService
	) {}

	ngOnInit(): void {
		this.dialogRef.afterClosed().subscribe(() => {
			this.router.navigate(['']);
		});
		this.router.events.subscribe(e => {
			if (e instanceof ActivationStart && e.snapshot.outlet === 'knowledge-base-modal') this.outlet.deactivate();
		});
		this.selectedBaseList = this.shakespeareBaseList;
		this.showKnowledgeView(this.selectedBaseList[0].name);
	}

	public showKnowledgeView(search?: string): void {
		this.kbService.knowledgebaseHome$.next(false);

		this.kbService.knowledgebaseShowView$.next(true);

		this.kbService.searchValue$.next(search);
	}

	public cancelModal(): void {
		this.dialogRef.close({ data: false });
	}

	public onActivate(event: any): void {}

	public ngOnDestroy(): void {}
}
