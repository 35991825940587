<div class="modalContainer">
	<div>
		<div class="header">
			<img [src]="data?.img || 'assets/icons/big-i.svg'" alt="" />
			<!-- <i class="k-icon k-i-close close" (click)="closeDialog()"></i> -->
		</div>
		<div class="body">
			<div class="content">
				<p class="title">{{ data?.title }}</p>
				<p class="desc" [innerHTML]="data?.desc"></p>
			</div>
		</div>
	</div>
	<div class="btns" [ngClass]="{ 'space-btw': data.isCancel }">
		<button *ngIf="data.isCancel" class="dec-cls" (click)="closeDialog()">{{ data.cancelText || 'Cancel' }}</button>

		<button class="appy-cls" (click)="confirm()">{{ data?.cta }}</button>
	</div>
</div>
