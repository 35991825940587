<div class="cancel-container" [class.iphone-css]="iPhoneMobile">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title">Share your feedback.</p>
		<p class="sub-title">Please let us know what we could have done better.</p>
		<textarea [(ngModel)]="feedback" type="text"></textarea>
	</div>
	<div class="footer">
		<button class="mind" (click)="changeStepType('why-options')">Back</button>
		<button class="no" [disabled]="feedback.length == 0" (click)="proceedToCancel('cancel-confirm')">Next</button>
	</div>
</div>
