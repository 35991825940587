import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { DropdownInterface } from 'src/app-components/interfaces/dropdown-interface';
import { takeUntil } from 'rxjs/operators';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import moment from 'moment';
import { CreateNewModalComponent } from 'src/app/shared/create-new-modal/create-new-modal.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NoCreditModalComponent } from '../../templates/no-credit-modal/no-credit-modal.component';
import { ShopifyService } from '../shopify.service';
import { Location } from '@angular/common';
import { DocumentInterface, EditorModeEnum } from '../../documents/document-interface';
import { DocumentService } from '../../documents/document.service';

@Component({
	selector: 'app-shopify-generator',
	templateUrl: './shopify-generator.component.html',
	styleUrls: ['./shopify-generator.component.scss']
})
export class ShopifyGeneratorComponent implements OnInit, OnDestroy {
	public editorMode: EditorModeEnum = EditorModeEnum.Pro;
	public isGeneratingOutput = false;
	@ViewChild('scroll') private myScrollContainer: ElementRef;
	@ViewChild('output') public outputWrapper: ElementRef;
	templates: TemplatesModel[] = [...TEMPLATES];
	clearOutput$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	clearValues$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	selectedTemplate: TemplatesModel;
	public outputData: any[] = [];
	public shopifyForm: FormGroup;
	public error = false;
	public errorText = false;
	public ToneError = false;
	public numberError = false;
	private unsubscriber$ = new Subject<void>();
	public favouriteData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	public payload: object;
	public advanceOptions = false;
	public isDataFetched = false;
	public isSaveAllowed = false;
	public currentOutput = 3;

	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public ageArray = [
		{ name: 'All', value: '0' },
		{ name: '5-12', value: '5-12' },
		{ name: '13-19', value: '13-19' },
		{ name: '20-39', value: '20-39' },
		{ name: '40-59', value: '40-59' },
		{ name: '60', value: '60' }
	];

	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	public outputLang = [...LANGUAGES];
	public translate = false;
	public userFName: string;
	public min = 1;
	public max = 70;
	public largeStep = 1;
	public smallStep = 14;
	public sliderValue = [15, 29];
	public formButtonText = 'GENERATE';
	public selectedOutputLang = 'en';
	public selectedOutputTone: string;
	public oneTimeScroll = true;
	public maxOutputs = 3;
	public filedId: number;
	selectedAge = 0;
	public shakespeareSub$: Subscription;
	iPhoneMobile: boolean;
	isHistoryFound: boolean;
	isMargin: boolean;
	innerWidth: number;
	loading: boolean;
	allProducts: any;
	productId: string;
	selectedItem;
	writingText: boolean;
	hideFooter: boolean;
	isEdit: boolean;
	loadingPage: boolean;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}
	public isOutput = true;
	public isHistory = false;
	menuExpanded: any;
	showAllproducts = false;
	public isEditingTitle = false;
	public editedValue: string;
	public editorOutput: any;
	public document: DocumentInterface = {
		createdOn: '1661781115.4748454',
		editedOn: '1661865565.3414576',
		id: 'be5402ec-27a1-11ed-8193-06ddfa98e15f',
		name: 'New Documents',
		text: ''
	};
	public documentId: string;
	public showGrammar = false;
	public aiSelectionProp = { start: 0, end: 0, text: '' };
	public presentEditorText$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	selectedTitle = 'All Products';
	customEdit$ = new BehaviorSubject<boolean>(false);
	customEditDesc$ = new BehaviorSubject<string>('');

	constructor(
		private formBuilder: FormBuilder,
		private userServiceApi: UserServiceApi,
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private userStore: Store<UserState>,
		private toastNotificationService: ToastNotificationService,
		private shakespeareService: ShakespeareService,
		private authenticationService: AuthenticationService,
		private shopifyService: ShopifyService,
		private location: Location,
		private documentService: DocumentService
	) {
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		let credits = this.shakespeareService.userCredits$.value;
		if (credits <= 50 && credits !== null) {
			this.openCreditModal();
		}

		this.activatedRoute.queryParams.subscribe(val => {
			this.productId = val.productId;
		});

		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	ngOnInit(): void {
		this.getAllProducts();
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.shopifyService.refreshProducts$.subscribe(val => {
			if (val) {
				console.log('updating');
				this.getAllProducts();
			}
		});
		this.createForm();
		this.shopifyForm.get('name').valueChanges.subscribe(x => {
			this.formButtonText = 'GENERATE';
			if (this.shopifyForm.controls.name.valid) {
				this.errorText = false;
			}
		});
		this.shopifyForm.get('description').valueChanges.subscribe(x => {
			this.formButtonText = 'GENERATE';
			if (this.shopifyForm.controls.description.valid) {
				this.error = false;
			}
		});
		this.shopifyForm.get('outputTone').valueChanges.subscribe(x => {
			this.formButtonText = 'GENERATE';
		});
		this.getActivatedRoute();
	}

	public writingStart(event?: any): void {
		this.writingText = true;
	}

	public writingEnd(event?: any): void {
		this.writingText = false;
	}

	toggleList(): void {
		this.showAllproducts = !this.showAllproducts;
	}

	private sendToEditor(aiEditor = false, text = ''): void {
		this.presentEditorText$.next({ aiEditor, text });
	}

	public getEditorControl(event: any): void {
		this.editorOutput = event;
	}

	public saveDocument(_document: DocumentInterface = this.document): void {
		_document.text = this.editorOutput?.root?.innerHTML;
		this.documentService.updateDocument(_document).subscribe(res => {
			this.isEditingTitle = null;
			this.editedValue = null;
		});
	}

	handleSelectedItem(selectedItem: object): void {
		this.clearValues$.next(true);
		this.clearOutput$.next(true);
		this.payload = null;
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.selectedItem = selectedItem;
		this.productId = this.selectedItem['id'];
		this.shopifyForm.patchValue({
			name: this.selectedItem['title'],
			description: this.selectedItem['description'].substring(0, 1200),
			brandName: token.sub.split('.')[0]
		});
		this.selectedTitle = this.selectedItem['title'];
		this.formButtonText = 'GENERATE';
		this.showAllproducts = false;
	}

	getAllProducts(page = 1): void {
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: page
			}
		};
		this.shopifyService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.loading = true;
					this.allProducts = response['data'];
					this.allProducts.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.selectedItem = this.allProducts[0];
					if (this.isEdit) {
						console.warn('In editing Mode');
						this.selectedItem = this.allProducts.find(item => item.id == this.productId);
						setTimeout(() => {
							this.loadingPage = false;
						}, 2000);
						setTimeout(() => {
							this.loading = false;
							this.isHistory = true;
							this.isOutput = false;
							this.customEdit$.next(true);
						}, 3000);
					} else {
						this.loading = false;
					}
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	sortProducts(type: string): void {
		this.loading = true;
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				limit: 35,
				page: 1,
				order: type,
				sort: 'created_at'
			}
		};

		if (type == 'relevance') {
			delete payload.data.order;
			delete payload.data.sort;
		}
		this.shopifyService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.allProducts = response['data'];
					this.allProducts.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.selectedItem = this.allProducts[0];
					this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	goBack(): void {
		this.location.back();
	}

	handlePagination(page: number): void {
		this.getAllProducts(page);
	}

	public openCreditModal(): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		if (this.shakespeareService.isUserPro$.value) {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		} else {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		}
		dialogRef.afterClosed().subscribe(obj => {
			console.log(obj);
		});
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['templateId']) {
				this.selectedTemplate = this.templates.find(template => {
					return template.id == params['templateId'];
				});
				if (this.selectedTemplate.template == 'headline') {
					this.maxOutputArray = this.max5Output;
				}
			} else {
				this.selectedTemplate = this.templates[0];
			}
			if (params['type'] == 'history') {
				this.isHistory = true;
				this.isOutput = false;
			}
			if (params['edit'] && params['productId']) {
				this.loading = true;
				console.log('editing');
				this.isEdit = true;
				this.loadingPage = true;
				this.productId = params['productId'];
			}
			this.selectedTemplate = {
				id: 0,
				img: '',
				title: 'Shopify Product Description',
				sub: '',
				template: 'Shopify',
				type: '',
				category: 'product-description',
				isFav: false,
				pro: false
			};
		});
	}

	public changeAgeSource(event): void {
		if (event) {
			this.selectedAge = event.value;
		}
	}

	public scrollBottom(): void {
		if (this.oneTimeScroll) {
			try {
				this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
			} catch {}
		}
	}

	private createForm(): void {
		this.shopifyForm = this.formBuilder.group({
			name: new FormControl(''),
			brandName: new FormControl(''),
			description: new FormControl(''),
			outputTone: new FormControl({ name: 'Excited', id: 1 }),
			outputLang: new FormControl(null),
			numOutputs: new FormControl({ name: '3 Outputs', value: 3 }),
			persona: new FormControl(false),
			gender: new FormControl(''),
			usps: new FormControl(''),
			specifications: new FormControl(''),
			age: new FormControl()
		});
	}

	handleEditorLayout(event: boolean): void {
		this.hideFooter = event;
	}

	public setErrorMessage(): void {
		if (!this.shopifyForm.controls.description.valid) {
			this.error = true;
		} else {
			this.error = false;
		}
	}

	public setErrorTextMessage(): void {
		if (!this.shopifyForm.controls.name.valid) {
			this.errorText = true;
		} else {
			this.errorText = false;
		}
	}

	public changeToneSource(event: DropdownInterface): void {
		console.log(event);
		if (event) {
			this.selectedOutputTone = event.name;
		}
		if (!this.shopifyForm.controls.outputTone.valid) {
			this.ToneError = true;
		}
	}

	public changeLangSource(event): void {
		if (event) {
			this.selectedOutputLang = event.id;
		} else {
			this.selectedOutputLang = '';
		}
	}

	public changeNumberSource(event): void {
		if (event) {
			this.shopifyForm.patchValue({
				numOutputs: event.id
			});
		}
		if (!this.shopifyForm.controls.numOutputs.valid) {
			this.numberError = true;
		}
	}

	public onRadioButtonClick(value: string): void {
		let gender = [value];
		if (value === 'nomatter') {
			gender = ['male', 'female'];
		}
		this.shopifyForm.patchValue({
			gender: gender
		});
	}

	public checkInputValue(event, form): void {
		form.reportValidity();
		if (form.reportValidity()) {
			if (event.target.value <= this.maxOutputs) {
				this.shopifyForm.patchValue({
					numOutputs: Number(event.target.value)
				});
				this.currentOutput = Number(event.target.value);
			} else {
				this.shopifyForm.patchValue({
					numOutputs: this.maxOutputs
				});
				this.currentOutput = this.maxOutputs;
			}
		}
	}

	public updateValidators(): void {
		this.shopifyForm.setControl(
			'name',
			new FormControl(this.shopifyForm.controls.name.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);

		this.shopifyForm.setControl(
			'brandName',
			new FormControl(this.shopifyForm.controls.brandName.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);

		this.shopifyForm.setControl(
			'description',
			new FormControl(this.shopifyForm.controls.description.value, {
				validators: [Validators.required, Validators.maxLength(1200)]
			})
		);
		this.shopifyForm.setControl(
			'outputTone',
			new FormControl(this.shopifyForm.controls.outputTone.value, {
				validators: [Validators.required]
			})
		);
	}

	public removeValidators(): void {
		this.shopifyForm.setControl(
			'name',
			new FormControl(this.shopifyForm.controls.name.value, {
				validators: []
			})
		);

		this.shopifyForm.setControl(
			'brandName',
			new FormControl(this.shopifyForm.controls.brandName.value, {
				validators: []
			})
		);

		this.shopifyForm.setControl(
			'description',
			new FormControl(this.shopifyForm.controls.description.value, {
				validators: []
			})
		);
		this.shopifyForm.setControl(
			'outputTone',
			new FormControl(this.shopifyForm.controls.outputTone.value, {
				validators: []
			})
		);
	}

	public onSubmit(): void {
		console.log(this.shopifyForm);
		this.isHistory = false;
		this.isOutput = true;
		this.updateValidators();
		if (this.shopifyForm.valid) {
			console.log(this.shopifyForm);
			if (this.innerWidth <= 900) {
				this.outputWrapper.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			this.formButtonText = 'GENERATE MORE';
			this.isFormSubmitted = true;
			let details = {
				user: {
					userFiledId: this.filedId
				},
				data: {
					product_id: this.productId,
					title: this.shopifyForm.controls.name.value,
					description: this.shopifyForm.controls.description.value,
					usps: '',
					specifications: '',
					images: [...this.selectedItem['images']],
					tone: this.shopifyForm.controls.outputTone.value.name,
					outputLanguage: this.selectedOutputLang || 'en',
					translate: false,
					numOutputs: this.shopifyForm.controls.numOutputs.value.value
				},
				extras: this.productId
			};
			if (this.shopifyForm.controls.usps.value) {
				details.data.usps = this.shopifyForm.controls.usps.value;
			}
			if (this.shopifyForm.controls.specifications.value) {
				details.data.specifications = this.shopifyForm.controls.specifications.value;
			}
			if (this.selectedOutputLang !== 'en') {
				details.data.translate = true;
			}
			if (this.selectedOutputLang == '') {
				details.data.translate = false;
			}
			this.payload = JSON.parse(JSON.stringify(details));
			// this.getAllProducts();
			// this.generateShopify(details)
		} else {
			Object.keys(this.shopifyForm.controls).forEach(key => {
				this.shopifyForm.controls[key].markAsDirty();
				this.shopifyForm.controls[key].markAsTouched();
			});
		}
	}

	public openCreateModal(isMobile = false): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'New Project',
			text: 'Enter a name to save.',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.value) {
				this.saveProjectHelper(obj.value);
			}
			if (obj.id) {
				this.addToProjectHelper(obj.id);
			}
		});
	}

	public saveProjectHelper(name: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				name: name || 'untitled-' + moment().format(),
				userTemplate: this.payload['data'].userTemplate,
				userInput: this.payload['data'].userInput,
				userOutput: this.favouriteData
			}
		};
		this.saveProject(payload);
	}

	public addToProjectHelper(id: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: id,
				userTemplate: this.payload['data'].userTemplate,
				userInput: this.payload['data'].userInput,
				userOutput: this.favouriteData
			}
		};
		this.addToProject(payload);
	}

	public saveProject(payload: object): void {
		this.userServiceApi
			.createUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'Project with same name exists already') {
						this.toastNotificationService.sendErrorToast('Operation failed! Project name already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Project created');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		this.isSaveAllowed = false;
	}

	public addToProject(payload: object): void {
		this.userServiceApi
			.addToUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'project data already existed') {
						this.toastNotificationService.sendErrorToast('Operation failed! Content already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Output added!');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		this.isSaveAllowed = false;
	}

	public onCheckToggleView(checked: boolean): void {
		this.advanceOptions = !this.advanceOptions;
		this.shopifyForm.patchValue({
			persona: checked,
			age: ['All']
		});
		if (checked) {
			setTimeout(() => {
				this.scrollBottom();
			}, 700);
		}
	}

	public enableViewMore(isFetched: boolean): void {
		console.log(isFetched);
		this.isDataFetched = isFetched;
		if (isFetched) {
			this.isFormSubmitted = !isFetched;
		}
	}

	public handleCallback(data): void {
		this.removeValidators();
		this.favouriteData = [];
		this.favouriteData = data.filter(template => {
			return template.isFav == true;
		});
		if (this.favouriteData.length) {
			this.isSaveAllowed = true;
		} else {
			this.isSaveAllowed = false;
		}
	}

	public resetForm(): void {
		this.shopifyForm.controls.name.setValidators(null);
		this.shopifyForm.controls.description.setValidators(null);
		this.shopifyForm.controls.outputTone.setValidators(null);
		this.shopifyForm.reset();
		this.advanceOptions = false;
		if (this.innerWidth <= 900) {
			this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.shopifyForm.controls.numOutputs.setValue({ name: '3 Outputs', value: 3 });
	}

	public onCheckToggleViewEmoji(checked: boolean): void {
		this.shopifyForm.patchValue({
			emoji: checked
		});
	}

	public handleGeneratedData(event: any): void {
		console.log(event);
		this.outputData = event;
		this.isMargin = true;
		if (this.innerWidth <= 900) {
			setTimeout(() => {
				this.outputWrapper.nativeElement.scrollIntoView({ top: '53px', behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 300);
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber$.unsubscribe();
	}

	public isHistoryData(event): void {
		if (this.isEdit) {
			this.isHistoryFound = true;
			this.isHistory = true;
			return;
		}
		this.isHistoryFound = event;
	}

	public deleteAll(): void {
		const reqObj = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				id: '',
				template_name: 'shopify-product-description'
			}
		};
		this.userServiceApi.deleteTemplateHistoryItem(reqObj).subscribe(res => {
			console.log(res);
		});
	}
}
