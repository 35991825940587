import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FirstUserService {
	public showTour$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	constructor() {}
}
