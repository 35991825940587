import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-framework-form',
	templateUrl: './framework-form.component.html',
	styleUrls: ['./framework-form.component.scss']
})
export class FrameworkFormComponent implements OnInit {
	@Input() public generatorForm: FormGroup = new FormGroup({});
	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];
	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];
	public outputLang = [...LANGUAGES];

	@Output() public changeToneSourceEvent = new EventEmitter<any>();
	@Output() public changeLangSourceEvent = new EventEmitter<any>();
	@Input() public resettingForm: Subject<boolean> = new Subject<boolean>();
	@Input() public validate: Subject<boolean> = new Subject<boolean>();
	@Output() public validateChange: EventEmitter<boolean> = new EventEmitter();
	public menuExpanded: any;

	constructor(private authenticationService: AuthenticationService) {
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	ngOnInit(): void {
		this.validate.subscribe(res => {
			if (res) {
				this.checkValidation();
			}
		});
		this.resettingForm.subscribe(res => {
			if (res) {
				this.resetForm();
			}
		});
	}

	public changeToneSource(event) {
		this.changeToneSourceEvent.emit(event);
	}

	public changeLangSource(event) {
		this.changeLangSourceEvent.emit(event);
	}

	private resetForm() {
		this.removeValidators();
		this.generatorForm.reset();
	}

	private checkValidation(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);

		this.generatorForm.setControl(
			'description',
			new FormControl(this.generatorForm.controls.description.value, {
				validators: [Validators.required, Validators.maxLength(400)]
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: [Validators.required]
			})
		);
		this.generatorForm.markAllAsTouched();
		this.generatorForm.updateValueAndValidity();
		this.validateChange.emit(this.generatorForm.valid);
	}
	public removeValidators(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: []
			})
		);

		this.generatorForm.setControl(
			'description',
			new FormControl(this.generatorForm.controls.description.value, {
				validators: []
			})
		);
		this.generatorForm.setControl(
			'outputTone',
			new FormControl(this.generatorForm.controls.outputTone.value, {
				validators: []
			})
		);
	}
}
