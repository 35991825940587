import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OverviewChartsService } from '../../services/overview-charts.service';
import { OverviewApiService } from '../../services/overview-api.service';
import { ChartConfiguration, ChartOptions, ChartType, Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { OverviewDataService } from '../../services/overview-data.service';
import { FormControl } from '@angular/forms';
// import  * as  Chart from 'chart.js'
@Component({
	selector: 'app-inside-card-container',
	templateUrl: './inside-card-container.component.html',
	styleUrls: ['./inside-card-container.component.scss']
})
export class InsideCardContainerComponent implements OnInit {
	@Input() summaryData: any;
	@Input() adAccountId: any;
	@Input() brandLogo: any;
	@Input() fromDate: any;
	@Input() toDate: any;
	@Input() currencySymbol: any;

	localStorageValue: any = JSON.parse(localStorage.getItem('decodedJwtIo'));
	highlightText = '';
	desktop: any = 0;
	mobile: any = 0;
	tablet: any = 0;
	historyArray = [];
	previousformattedDates: any;
	currentformattedDates: any;
	currentDates: any;
	prevDates: any;
	currentClicksData: any;
	previousClicksData: any;
	isLineGraphLoaded: boolean = false;
	showLoader: boolean = false;
	lineChartsDropdownData: any[] = [];
	private subscriptions: Subscription[] = [];
	lineChartsResponse: any;
	doughnutChartResponse: any;
	horizontalBarChartResponse: any;
	historyResponse: any;
	hightlightTextResponse: any;
	sharedResponse: any;
	controlA = new FormControl();
	controlB = new FormControl();

	//horizontalBarCharts
	horizontalBarChartLegend: any;
	horizontalBarChartPlugins: any;
	horizontalBarChartData: any;
	horizontalBarChartOptions: any;

	//line-charts
	lineChartLegend: any;
	lineChartLegendSecond: any;
	lineChartDataSecond: any;
	lineChartOptionsSecond: any;
	lineChart1: any;
	lineChart2: any;

	//doughnut-charts
	doughnutChartLabels: any;
	doughnutChartDatasets: any;
	doughnutChartOptions: any;

	constructor(
		private overviewChartsService: OverviewChartsService,
		private overviewApiService: OverviewApiService,
		private overviewDataService: OverviewDataService
	) {
		this.lineChartsDropdownData = this.overviewDataService.lineChartsDropdownData;
		//horizontalBarCharts
		this.horizontalBarChartLegend = this.overviewChartsService.horizontalBarChartLegend;
		this.horizontalBarChartPlugins = this.overviewChartsService.horizontalBarChartPlugins;
		this.horizontalBarChartData = this.overviewChartsService.horizontalBarChartData;
		this.horizontalBarChartOptions = this.overviewChartsService.horizontalBarChartOptions;

		//line-charts
		this.lineChartLegend = this.overviewChartsService.lineChartLegend;
		this.lineChartLegendSecond = this.overviewChartsService.lineChartLegendSecond;
		this.lineChartDataSecond = this.overviewChartsService.lineChartDataSecond;
		this.lineChartOptionsSecond = this.overviewChartsService.lineChartOptionsSecond;

		//doughnut-charts
		this.doughnutChartLabels = this.overviewChartsService.doughnutChartLabels;
		this.doughnutChartDatasets = this.overviewChartsService.doughnutChartDatasets;
		this.doughnutChartOptions = this.overviewChartsService.doughnutChartOptions;
	}

	ngOnChanges(changes: SimpleChanges) {
		// console.log('fromDate', this.fromDate);
		// console.log('toDate', this.toDate);
	}

	ngOnInit(): void {
		let temp = this.overviewDataService.insideCardComponentData;
		this.sharedResponse = temp.find(obj => obj.adAccountId == this.adAccountId);

		this.controlA.patchValue(this.overviewDataService.lineChartsDropdownData[0]);
		this.controlB.patchValue(this.overviewDataService.lineChartsDropdownData[5]);
		if (this.brandLogo == 'google') {
			this.getGoogleHistory();
		}
		if (this.brandLogo == 'facebook') {
			this.getFacebookHistory();
		}

		// console.log('fromDate', this.fromDate);
		// console.log('toDate', this.toDate);
		this.getDoughnutCharts();
		this.getLineChartsData();
		this.getHorizontalBarChartData();
		this.getHighlightText();
		this.onSelectFirstDropdown(this.overviewDataService.lineChartsDropdownData[0]);
		this.onSelectSecondDropdown(this.overviewDataService.lineChartsDropdownData[5]);
	}

	public getGoogleHistory() {
		if (this.sharedResponse) {
			let res = this.sharedResponse.history;
			this.historyArray = res;
		} else {
			this.overviewApiService.getGoogleHistory().subscribe((res: any) => {
				this.historyArray = res;
				this.historyResponse = res;
				this.patchValues();
			});
		}
	}

	public getFacebookHistory() {
		if (this.sharedResponse) {
			let res = this.sharedResponse.history;
			this.historyArray = res;
		} else {
			this.overviewApiService.getFacebookHistory().subscribe((res: any) => {
				this.historyArray = res;
				this.historyResponse = res;
				this.patchValues();
			});
		}
	}

	getDoughnutCharts() {
		let subscription;
		if (this.brandLogo == 'google') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.doughnutChart;
				let total = res.DESKTOP + res.MOBILE + res.TABLET;
				this.desktop = res.DESKTOP > 0 ? (res.DESKTOP / total) * 100 : 0;
				this.tablet = res.TABLET > 0 ? (res.TABLET / total) * 100 : 0;
				this.mobile = res.MOBILE > 0 ? (res.MOBILE / total) * 100 : 0;
				this.doughnutChartDatasets[0].data = [];
				this.doughnutChartDatasets[0].data = [
					this.desktop > 0 ? this.desktop : 33,
					this.tablet > 0 ? this.tablet : 33,
					this.mobile > 0 ? this.mobile : 33
				];
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				subscription = this.overviewApiService.getDoughnutChart(payload).subscribe(res => {
					// console.log('doughnut=>', res);
					if (Object.keys(res).length) {
						let total = res.DESKTOP + res.MOBILE + res.TABLET;
						this.desktop = res.DESKTOP > 0 ? (res.DESKTOP / total) * 100 : 0;
						this.tablet = res.TABLET > 0 ? (res.TABLET / total) * 100 : 0;
						this.mobile = res.MOBILE > 0 ? (res.MOBILE / total) * 100 : 0;
						this.doughnutChartDatasets[0].data = [];
						this.doughnutChartDatasets[0].data = [
							this.desktop > 0 ? this.desktop : 33,
							this.tablet > 0 ? this.tablet : 33,
							this.mobile > 0 ? this.mobile : 33
						];
						// console.log("dataset=>",this.doughnutChartDatasets);
						this.doughnutChartResponse = res;
						this.patchValues();
					}
				});
			}
		}
		if (this.brandLogo == 'facebook') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.doughnutChart;
				let total = res.DESKTOP + res.MOBILE + res.TABLET;
				this.desktop = res.DESKTOP > 0 ? (res.DESKTOP / total) * 100 : 0;
				this.tablet = res.TABLET > 0 ? (res.TABLET / total) * 100 : 0;
				this.mobile = res.MOBILE > 0 ? (res.MOBILE / total) * 100 : 0;
				this.doughnutChartDatasets[0].data = [];
				this.doughnutChartDatasets[0].data = [
					this.desktop > 0 ? this.desktop : 33,
					this.tablet > 0 ? this.tablet : 33,
					this.mobile > 0 ? this.mobile : 33
				];
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				subscription = this.overviewApiService.getFacebookDoughnutChart(payload).subscribe(res => {
					// console.log('doughnut=>', res);
					if (Object.keys(res).length) {
						let total = res.DESKTOP + res.MOBILE + res.TABLET;
						this.desktop = res.DESKTOP > 0 ? (res.DESKTOP / total) * 100 : 0;
						this.tablet = res.TABLET > 0 ? (res.TABLET / total) * 100 : 0;
						this.mobile = res.MOBILE > 0 ? (res.MOBILE / total) * 100 : 0;
						this.doughnutChartDatasets[0].data = [];
						this.doughnutChartDatasets[0].data = [
							this.desktop > 0 ? this.desktop : 33,
							this.tablet > 0 ? this.tablet : 33,
							this.mobile > 0 ? this.mobile : 33
						];
					}
					this.doughnutChartResponse = res;
					this.patchValues();
					// console.log("dataset=>",this.doughnutChartDatasets);
				});
			}
		}

		this.subscriptions.push(subscription);
	}

	getLineChartsData() {
		let subscription;
		if (this.brandLogo == 'google') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.lineChart;
				this.lineChartsResponse = res;
				this.onSelectFirstDropdown(this.overviewDataService.lineChartsDropdownData[0]);
				this.onSelectSecondDropdown(this.overviewDataService.lineChartsDropdownData[5]);
				this.isLineGraphLoaded = true;
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				subscription = this.overviewApiService.getLineChartsData(payload).subscribe(res => {
					if (res) {
						this.lineChartsResponse = res;
						this.onSelectFirstDropdown(this.overviewDataService.lineChartsDropdownData[0]);
						this.onSelectSecondDropdown(this.overviewDataService.lineChartsDropdownData[5]);
						this.isLineGraphLoaded = true;
						this.patchValues();
					}
				});
			}
		}

		if (this.brandLogo == 'facebook') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.lineChart;
				this.lineChartsResponse = res;
				this.onSelectFirstDropdown(this.overviewDataService.lineChartsDropdownData[0]);
				this.onSelectSecondDropdown(this.overviewDataService.lineChartsDropdownData[5]);
				this.isLineGraphLoaded = true;
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				subscription = this.overviewApiService.getFbLineChartsData(payload).subscribe(res => {
					if (res) {
						this.lineChartsResponse = res;
						this.onSelectFirstDropdown(this.overviewDataService.lineChartsDropdownData[0]);
						this.onSelectSecondDropdown(this.overviewDataService.lineChartsDropdownData[5]);
						this.isLineGraphLoaded = true;
						this.patchValues();
					}
				});
			}
		}

		this.subscriptions.push(subscription);
	}

	getHorizontalBarChartData() {
		let subscription;
		if (this.brandLogo == 'google') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.horizontalBar;
				if (res?.length) {
					this.horizontalBarChartData = {
						labels: [res[0].campaignName, res[1].campaignName, res[2].campaignName],
						datasets: [
							{
								data: [res[0]?.clicks, res[1]?.clicks, res[2]?.clicks],
								label: 'Clicks',
								backgroundColor: '#006BFF',
								borderRadius: 5
							},
							{
								data: [res[0]?.impressions, res[1]?.impressions, res[2]?.impressions],
								label: 'Impressions',
								backgroundColor: '#D5F5E1',
								borderRadius: 5
							},
							{
								data: [res[0]?.spend, res[1]?.spend, res[2]?.spend],
								label: 'Spend',
								backgroundColor: '#FFBB4C',
								borderRadius: 5
							}
						]
					};
					this.showLoader = true;
				}
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				subscription = this.overviewApiService.getHorizontalBarChartData(payload).subscribe(res => {
					if (res?.length) {
						this.horizontalBarChartData = {
							labels: [res[0].campaignName, res[1].campaignName, res[2].campaignName],
							datasets: [
								{
									data: [res[0]?.clicks, res[1]?.clicks, res[2]?.clicks],
									label: 'Clicks',
									backgroundColor: '#006BFF',
									borderRadius: 5
								},
								{
									data: [res[0]?.impressions, res[1]?.impressions, res[2]?.impressions],
									label: 'Impressions',
									backgroundColor: '#D5F5E1',
									borderRadius: 5
								},
								{
									data: [res[0]?.spend, res[1]?.spend, res[2]?.spend],
									label: 'Spend',
									backgroundColor: '#FFBB4C',
									borderRadius: 5
								}
							]
						};
						this.showLoader = true;
						this.horizontalBarChartResponse = res;
						this.patchValues();
					} else {
						this.horizontalBarChartResponse = [];
					}
				});
			}
		}
		if (this.brandLogo == 'facebook') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.horizontalBar;
				if (res?.length) {
					this.horizontalBarChartData.datasets = [
						{
							data: [res?.campaignData[0]?.clicks, res?.campaignData[1]?.clicks, res?.campaignData[2]?.clicks],
							label: 'Clicks',
							backgroundColor: '#006BFF',
							borderRadius: 5
						},
						{
							data: [res?.campaignData[0]?.impressions, res?.campaignData[1]?.impressions, res?.campaignData[2]?.impressions],
							label: 'Impressions',
							backgroundColor: '#121212',
							borderRadius: 5
						},
						{
							data: [res?.campaignData[0]?.spend, res?.campaignData[1]?.spend, res?.campaignData[2]?.spend],
							label: 'Spend',
							backgroundColor: '#FFBB4C',
							borderRadius: 5
						}
					];
				}
				this.showLoader = true;
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				subscription = this.overviewApiService.getFacebookHorizontalBarChartData(payload).subscribe(res => {
					if (res?.length) {
						this.horizontalBarChartData.datasets = [
							{
								data: [res?.campaignData[0]?.clicks, res?.campaignData[1]?.clicks, res?.campaignData[2]?.clicks],
								label: 'Clicks',
								backgroundColor: '#006BFF',
								borderRadius: 5
							},
							{
								data: [res?.campaignData[0]?.impressions, res?.campaignData[1]?.impressions, res?.campaignData[2]?.impressions],
								label: 'Impressions',
								backgroundColor: '#121212',
								borderRadius: 5
							},
							{
								data: [res?.campaignData[0]?.spend, res?.campaignData[1]?.spend, res?.campaignData[2]?.spend],
								label: 'Spend',
								backgroundColor: '#FFBB4C',
								borderRadius: 5
							}
						];
						this.showLoader = true;
						this.horizontalBarChartResponse = res;
						this.patchValues();
					} else {
						this.horizontalBarChartResponse = [];
					}
				});
			}
		}

		this.subscriptions.push(subscription);
	}

	getFormattedDates(currentDates: any) {
		return currentDates?.map(date => {
			const year = date.substr(0, 4);
			const month = date.substr(4, 2);
			const day = date.substr(6, 2);
			const formattedDate = new Date(`${year}-${month}-${day}`);
			const monthName = formattedDate.toLocaleString('default', { month: 'short' });
			return `${monthName} ${day}`;
		});
	}

	onSelectFirstDropdown(selectedFirstdd: any) {
		// console.log(selectedFirstdd);
		if (this.lineChart1) {
			this.lineChart1.destroy();
		}
		let temp = selectedFirstdd.value;
		let res = this.lineChartsResponse;

		let currentClick;
		let previousClick;

		if (res?.currentPeriod) {
			this.currentDates = Object.keys(res.currentPeriod);
			currentClick = this.currentDates.map(dates => res.currentPeriod[dates][temp]);
		}

		if (res?.previousPeriod) {
			this.prevDates = Object.keys(res.previousPeriod);
			previousClick = this.prevDates.map(dates => res.previousPeriod[dates][temp]);
		}

		// console.log('crDates', this.currentDates, 'this.currentClickedData', this.currentClicksData);
		this.currentformattedDates = this.getFormattedDates(this.currentDates);
		this.previousformattedDates = this.getFormattedDates(this.prevDates);

		// console.log('this.currentformattedDates=>', this.currentformattedDates);
		// console.log('this.previousformattedDates=>', this.previousformattedDates);

		if (!this.currentformattedDates?.length) {
			this.currentformattedDates = [1, 2, 3, 4, 5];
		}

		if (!this.previousformattedDates?.length) {
			this.previousformattedDates = [1, 2, 3, 4, 5];
		}
		if (!currentClick?.length) {
			currentClick = [0, 0, 0, 0, 0];
		}

		if (!previousClick?.length) {
			previousClick = [0, 0, 0, 0, 0];
		}

		let data1 = {
			labels: this.previousformattedDates,
			datasets: [
				{
					label: '',
					data: currentClick,
					// fillColor: "#1B7AF0",
					// strokeColor: "#1B7AF0",
					// pointColor: "#1B7AF0",
					// pointStrokeColor: "#fff",
					// pointHighlightFill: "#fff",
					// pointHighlightStroke: "rgba(151,187,205,1)",
					fill: false,
					tension: 0.4,
					borderColor: '#1B7AF0',
					backgroundColor: '#ffffff',
					pointBackgroundColor: '#1B7AF0'
				},
				{
					label: '',
					data: previousClick,
					// fillColor: "rgba(151,187,205,0.2)",
					// strokeColor: "rgba(151,187,205,1)",
					// pointColor: "rgba(200,10,10,1)",
					// pointStrokeColor: "#fff",
					// pointHighlightFill: "#fff",
					// pointHighlightStroke: "rgba(151,187,205,1)",
					fill: false,
					tension: 0.4,
					borderColor: '#FFBB4C',
					backgroundColor: '#ffffff',
					pointBackgroundColor: '#FFBB4C'
				}
			]
		};

		var ctx1: any = document.getElementById('myChart1');
		ctx1 = ctx1.getContext('2d');

		this.lineChart1 = new Chart(ctx1, {
			type: 'line',

			data: data1,

			options: this.overviewChartsService.lineChartOptionsSecond
		});
	}

	onSelectSecondDropdown(selectedSeconddd: any) {
		// console.log(selectedSeconddd);
		if (this.lineChart2) {
			this.lineChart2.destroy();
		}
		let temp = selectedSeconddd.value;
		let res = this.lineChartsResponse;

		let currentCtr;
		let previousCtr;

		if (res?.currentPeriod) {
			this.currentDates = Object.keys(res.currentPeriod);
			currentCtr = this.currentDates.map(dates => res.currentPeriod[dates][temp]);
		}

		if (res?.previousPeriod) {
			this.prevDates = Object.keys(res.previousPeriod);
			previousCtr = this.prevDates.map(dates => res.previousPeriod[dates][temp]);
		}

		// console.log('crDates', this.currentDates, 'this.currentClickedData', this.currentClicksData);
		this.currentformattedDates = this.getFormattedDates(this.currentDates);
		this.previousformattedDates = this.getFormattedDates(this.prevDates);

		// console.log('this.currentformattedDates=>', this.currentformattedDates);
		// console.log('this.previousformattedDates=>', this.previousformattedDates);

		if (!this.currentformattedDates?.length) {
			this.currentformattedDates = [1, 2, 3, 4, 5];
		}

		if (!this.previousformattedDates?.length) {
			this.previousformattedDates = [1, 2, 3, 4, 5];
		}
		if (!currentCtr?.length) {
			currentCtr = [0, 0, 0, 0, 0];
		}

		if (!previousCtr?.length) {
			previousCtr = [0, 0, 0, 0, 0];
		}

		let data2 = {
			labels: this.previousformattedDates,
			datasets: [
				{
					label: '',
					data: currentCtr,
					// fillColor: "#1B7AF0",
					// strokeColor: "#1B7AF0",
					// pointColor: "#1B7AF0",
					// pointStrokeColor: "#fff",
					// pointHighlightFill: "#fff",
					// pointHighlightStroke: "rgba(151,187,205,1)",
					fill: false,
					tension: 0.4,
					borderColor: '#1B7AF0',
					backgroundColor: '#ffffff',
					pointBackgroundColor: '#1B7AF0'
				},
				{
					label: '',
					data: previousCtr,
					// fillColor: "rgba(151,187,205,0.2)",
					// strokeColor: "rgba(151,187,205,1)",
					// pointColor: "rgba(200,10,10,1)",
					// pointStrokeColor: "#fff",
					// pointHighlightFill: "#fff",
					// pointHighlightStroke: "rgba(151,187,205,1)",
					fill: false,
					tension: 0.4,
					borderColor: '#FFBB4C',
					backgroundColor: '#ffffff',
					pointBackgroundColor: '#FFBB4C'
				}
			]
		};

		var ctx2: any = document.getElementById('myChart2');
		ctx2 = ctx2.getContext('2d');

		this.lineChart2 = new Chart(ctx2, {
			type: 'line',

			data: data2,

			options: this.overviewChartsService.lineChartOptionsSecond
		});
	}

	getHighlightText() {
		if (this.brandLogo == 'facebook') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.highlightText;
				this.highlightText = res;
				this.showLoader = true;
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				this.overviewApiService.getFaceBookHighlights(payload).subscribe(res => {
					this.highlightText = res?.highlightText;
					this.showLoader = true;
					this.hightlightTextResponse = res?.highlightText;
					this.patchValues();
				});
			}
		}
		if (this.brandLogo == 'google') {
			if (this.sharedResponse) {
				let res = this.sharedResponse.highlightText;
				this.highlightText = res;
				this.showLoader = true;
			} else {
				let payload = {
					user: {
						user_filed_id: this.localStorageValue.user_filed_id
					},
					data: {
						ad_account_id: this.adAccountId,
						date_range: {
							from_date: this.fromDate,
							to_date: this.toDate
						}
					}
				};
				this.overviewApiService.getGoogleHighlights(payload).subscribe(res => {
					this.highlightText = res?.highlightText;
					this.showLoader = true;
					this.hightlightTextResponse = res?.highlightText;
					this.patchValues();
				});
			}
		}
	}
	patchValues() {
		let temp = {
			adAccountId: this?.adAccountId,
			lineChart: this?.lineChartsResponse,
			doughnutChart: this?.doughnutChartResponse,
			horizontalBar: this?.horizontalBarChartResponse,
			history: this?.historyResponse,
			highlightText: this?.hightlightTextResponse
		};
		if (temp.adAccountId && temp.lineChart && temp.doughnutChart && temp.horizontalBar && temp.history && temp.highlightText) {
			const temp = this.overviewDataService.insideCardComponentData;
			const existingObject = temp.find(obj => obj.adAccountId === this.adAccountId);

			if (existingObject === undefined) {
				this.overviewDataService.insideCardComponentData.push({
					adAccountId: this?.adAccountId,
					lineChart: this?.lineChartsResponse,
					doughnutChart: this?.doughnutChartResponse,
					horizontalBar: this?.horizontalBarChartResponse,
					history: this?.historyResponse,
					highlightText: this?.hightlightTextResponse
				});
			}
		}
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions to prevent memory leaks
		// this.subscriptions.forEach(subscription => subscription.unsubscribe());
		console.log('inside=>', this.overviewDataService.insideCardComponentData);
	}
}
