import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class OverviewDataService {
	googleAdAccount: any;
	facebookAdAccount: any;
	overviewGoogleDataList: any;
	overviewFacebookDataList: any;
	fromDate: any;
	toDate: any;
	insideCardComponentData: any[] = [];
	lineChartsDropdownData = [
		{ id: 0, name: 'Clicks', value: 'clicks' },
		{ id: 1, name: 'Conversion', value: 'conversion' },
		{ id: 2, name: 'Conversion Rate', value: 'conversionRate' },
		{ id: 3, name: 'CostMicros', value: 'costMicros' },
		{ id: 4, name: 'Cost Per Conversion', value: 'costPerConversion' },
		{ id: 5, name: 'CTR', value: 'ctr' },
		{ id: 6, name: 'Impressions', value: 'impressions' }
	];

	currencySymbol = {
		TRY: '₺',
		GBP: '£', // Pound symbol (£) for GBP
		INR: '₹', // Indian Rupee symbol (₹) for INR
		USD: '$', // US Dollar symbol ($) for USD
		AUD: 'A$' // Australian Dollar symbol (A$) for AUD
		// Add more currency codes and symbols as needed
	};

	constructor() {}
}
