<div class="main-container">
	<div class="search-results">
		<div class="result-repeat">
			<ng-container *ngFor="let sub of articles; let i = index">
				<div *ngIf="sub.category == 'Shakespeare'">
					<mat-divider></mat-divider>
					<div (click)="showFrame(i)" class="result">
						<h5>{{ sub.subcategory }}</h5>
						<div class="content">
							<div [innerHTML]="shortenDescription(sub.description)"></div>
						</div>
						<div class="chips">
							<mat-chip>
								{{ sub.type }}
							</mat-chip>
							<mat-chip>
								{{ sub.category }}
							</mat-chip>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
