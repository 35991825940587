<div class="scroll-wrapper" [class.iphone-css]="iPhoneMobile">
	<div class="parent" [ngClass]="{ 'selected-con': isFooter }">
		<div class="container">
			<div class="header-con" *ngIf="!loading">
				<div class="title">
					<em (click)="goBack()" class="fas fa-chevron-left back-btn" *ngIf="!hideProject">&nbsp;</em>
					<em (click)="goBackToProject()" class="fas fa-chevron-left back-btn" *ngIf="hideProject">&nbsp;</em>
					<span class="page-title">{{ selectedProject?.name }}</span>

					<ng-container *ngIf="hideProject">
						<em class="fas fa-chevron-left forward-icon">&nbsp;</em>
						<span class="folder-name-path">{{ folderName }}</span>
					</ng-container>
				</div>
				<div class="header-items" [ngClass]="{ folder: hideProject }">
					<div class="search">
						<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
					</div>
				</div>
			</div>
			<!-- <div > -->
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>

			<div class="folder-list" *ngIf="foldersArray?.length > 0 && !hideProject && !loading">
				<dts-select-container class="folder-body" [selectOnClick]="false" [disableRangeSelection]="false">
					<div
						class="folder-card"
						[ngClass]="{ renaming: folder.showInput }"
						(click)="hideProject = true; navigateToFolderData(folder)"
						*ngFor="let folder of foldersArray; let i = index"
					>
						<div class="check-form">
							<div class="project-items {{ isEditing == folder.folderId ? 'editable' : '' }}">
								<img src="assets/shakespeare/projects/folder-icon.svg" alt="" />
								<span #editor class="projects-name" [style.display]="folder.showInput ? 'none' : 'block'">{{ folder.folderName }}</span>
								<form *ngIf="isEditing === folder.folderId && folder.showInput">
									<input
										#editorControl
										id="editor-{{ i }}"
										[ngModel]="editedValue"
										[autofocus]="true"
										[ngModelOptions]="{ standalone: true }"
										(keydown)="setTextValue($event, i, $event.target.value)"
									/>
								</form>
							</div>
							<div class="actions">
								<span
									[style.display]="folder.showInput ? 'none' : 'block'"
									(click)="addToFav($event, i, 'folders')"
									[matTooltip]="folder?.isFav ? 'Remove From Favorite' : 'Add To Favorite'"
									[matTooltipPosition]="'above'"
									[ngClass]="folder?.isFav ? 'fav' : 'heart'"
								></span>
								<div class="menu" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" aria-label="Menu" *ngIf="!folder.showInput">
									<mat-icon>more_vert</mat-icon>
								</div>
								<div *ngIf="folder.showInput" (click)="completeRename(i, editedValue, $event)">
									<img class="check-icon" src="assets/templates/actions/check.svg" />
								</div>
								<mat-menu #menu="matMenu" class="mat-elevation-z2">
									<button mat-menu-item (click)="rename(folder, editor.innerHTML, 'editor-' + i)">
										<span class="menu-icon" style="margin-right: 9px;">
											<img src="assets/shakespeare/projects/rename-icon.svg" alt="" />
										</span>
										<span class="menu-title">Rename</span>
									</button>
									<button mat-menu-item (click)="onDelete($event, folder, 'folders')">
										<span class="menu-icon" style="margin-right: 9px;">
											<img src="assets/shakespeare/projects/delete-icon.svg" alt="" />
										</span>
										<span class="menu-title">Delete</span>
									</button>
								</mat-menu>
							</div>
						</div>
					</div>
				</dts-select-container>
			</div>
			<div class="no-templates" *ngIf="foldersArray?.length === 0 && selectedProject?.inputOutputData.length === 0 && !loading && !hideProject">
				<h2 class="text">No data found.</h2>
			</div>
			<div class="no-templates" *ngIf="notFound && !loading && !hideProject && selectedProject?.inputOutputData.length > 0">
				<h2 class="text">No templates found matching your search input!</h2>
			</div>
			<div class="no-templates" *ngIf="selectedFolder[0]?.inputOutputData.length === 0 && !loading && hideProject">
				<h2 class="text">You haven't added any templates yet.</h2>
			</div>
			<dts-select-container
				*ngIf="!hideProject && !loading && libraryAlteredData.length > 0"
				class="card-body"
				(select)="onSelectTemplates($event)"
				[dragOverItems]="true"
				[selectOnDrag]="true"
				[selectOnClick]="false"
				[disableRangeSelection]="false"
				[(selectedItems)]="selectedTemplates"
				[disabled]="false"
			>
				<div class="body-con {{ isFooter ? 'is-footer' : null }}">
					<div
						class="card"
						[dtsSelectItem]="{ temp: template, index: i }"
						[ngClass]="{ selected: checkForm?.get('val' + i)?.value }"
						[class.selected]="checkForm?.get('val' + i)?.value"
						*ngFor="let template of libraryAlteredData; let i = index"
						(click)="viewTemplate(template)"
					>
						<form [formGroup]="checkForm" class="check-form">
							<div class="header">
								<div class="name" *ngIf="template?.userTemplate.template !== 'Blog Wizard'">
									<img [src]="template?.templateData?.img" alt="" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" />
									<p class="title">{{ template?.templateData?.title || 'Write From Scratch' }}</p>
								</div>

								<div class="name" *ngIf="template?.userTemplate.template === 'Blog Wizard'">
									<img src="assets/templates/wizard.svg" alt="" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" />
									<p class="title">Blog Wizard ({{ template?.templateData?.title || 'Write From Scratch' }})</p>
								</div>
								<mat-checkbox
									formControlName="val{{ i }}"
									[id]="'toggle' + i"
									(click)="toggleControl($event, { temp: template, index: i })"
								></mat-checkbox>
							</div>
						</form>
						<div class="about" [id]="'selector' + i">
							<!-- special advance generator -->
							<div *ngIf="template?.userTemplate?.template == 'advanced completion'" class="title">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content" *ngIf="item?.advancedCompletion">
										<div class="text" [innerHTML]="item?.advancedCompletion"></div>
									</div>
								</div>
							</div>

							<!-- ad-copy -->
							<div *ngIf="template?.templateData?.category == 'ad-copy'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content" *ngIf="item?.title">
										<div class="text" [innerHTML]="item?.title" [ngClass]="item?.description ? 'boldTitle' : ''"></div>
									</div>
									<div class="content desc" *ngIf="item?.description">
										<div class="text" [innerHTML]="item?.description"></div>
									</div>
									<div class="content" *ngIf="item?.cta">
										<div class="text" [innerHTML]="item?.cta"></div>
									</div>
								</div>
							</div>

							<!-- email -->
							<div *ngIf="template?.templateData?.category == 'email'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.subject">
										<div class="text boldTitle" [innerHTML]="item?.subject"></div>
									</div>
									<div class="content">
										<div class="text" [innerHTML]="item?.email"></div>
									</div>
								</div>
							</div>

							<!-- product-desc -->
							<div *ngIf="template?.templateData?.category == 'product-description'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.mainDescription">
										<div class="text" [innerHTML]="item?.mainDescription"></div>
									</div>
									<div *ngFor="let subItem of item.uspDescription">
										<div class="content-2" *ngIf="subItem?.description">
											<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
											<span class="text" [innerHTML]="subItem?.description"></span>
										</div>
									</div>
								</div>
							</div>

							<!-- blogs -->
							<div *ngIf="template?.templateData?.category == 'blog' || template?.userTemplate.template === 'Blog Wizard'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div *ngIf="!fullBlogCase" class="case-default">
										<div class="content desc" *ngIf="item?.para" id="para">
											<div class="text" [innerHTML]="item?.para"></div>
										</div>
										<div class="content desc" *ngIf="item.text" id="blog-global">
											<div class="text" [innerHTML]="item.text"></div>
										</div>
										<div class="content desc" *ngIf="item.title">
											<div class="text" [innerHTML]="item.title"></div>
										</div>
									</div>
									<div *ngIf="fullBlogCase" class="full-blog">
										<div class="group">
											<div class="content desc blog-title" *ngIf="item.title">
												<div class="text boldTitle" [innerHTML]="item.title"></div>
											</div>
											<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
											<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
												<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
												<div class="text" [innerHTML]="subitem.para"></div>
											</div>
											<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- content -->
							<div *ngIf="template?.templateData?.category == 'content'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.improvedContent">
										<div class="text" [innerHTML]="item?.improvedContent"></div>
									</div>
								</div>
							</div>

							<!-- social -->
							<div *ngIf="template?.templateData?.category == 'social'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.title">
										<div class="text" [innerHTML]="item?.title"></div>
									</div>
									<div class="content desc" *ngIf="item?.hashtag">
										<!-- special case for hashtags which is part of social templates -->
										<div class="text" [innerHTML]="item?.hashtag"></div>
									</div>
									<div class="content desc">
										<div class="text" [innerHTML]="item?.caption"></div>
									</div>
								</div>
							</div>

							<!-- framework -->
							<div *ngIf="template?.templateData?.category == 'framework'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>

							<!-- video -->
							<div *ngIf="template?.templateData?.category == 'video'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>
							<!-- bio -->
							<div *ngIf="template?.templateData?.category == 'bio'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.bio">
										<div class="text" [innerHTML]="item?.bio"></div>
									</div>
								</div>
							</div>
							<!-- seo -->
							<div *ngIf="template?.templateData?.category == 'seo'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>

							<!-- essay -->
							<div *ngIf="template?.templateData?.category == 'essay'" class="title blogs">
								<div *ngFor="let item of template?.userOutput; let i = index" class="group">
									<div *ngIf="!fullBlogCase" class="case-default">
										<div class="content desc" *ngIf="item?.para" id="para">
											<div class="text" [innerHTML]="item?.para"></div>
										</div>
										<div class="content desc" *ngIf="item.text" id="blog-global">
											<div class="text" [innerHTML]="item.text"></div>
										</div>
										<div class="content desc" *ngIf="item.title">
											<div class="text" [innerHTML]="item.title"></div>
										</div>
									</div>
									<div *ngIf="fullBlogCase" class="full-blog">
										<div class="group">
											<div class="content desc blog-title" *ngIf="item.title">
												<div class="text boldTitle" [innerHTML]="item.title"></div>
											</div>
											<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
											<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
												<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
												<div class="text" [innerHTML]="subitem.para"></div>
											</div>
											<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="actions">
							<div class="icons">
								<span
									(click)="addToFav($event, i, 'project-library')"
									[matTooltip]="template?.isFav ? 'Remove From Favorite' : 'Add To Favorite'"
									[matTooltipPosition]="'above'"
									[ngClass]="template?.isFav ? 'fav' : 'heart'"
								></span>
								<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="deleteOutput($event, template.id)"></span>
							</div>
						</div>
					</div>
				</div>
			</dts-select-container>

			<div class="no-templates" *ngIf="notFound && !loading && hideProject && selectedFolder[0]?.inputOutputData.length > 0">
				<h2 class="text">No templates found matching your search input!</h2>
			</div>
			<div class="body-con" *ngIf="hideProject && !loading && folderLibraryAlteredData.length > 0">
				<div class="card" *ngFor="let template of folderLibraryAlteredData; let i = index" (click)="viewTemplate(template)">
					<form [formGroup]="checkForm" class="check-form">
						<div class="header">
							<div class="name" *ngIf="template?.userTemplate.template !== 'Blog Wizard'">
								<img [src]="template?.templateData?.img" alt="" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" />
								<p class="title">{{ template?.templateData?.title || 'Write From Scratch' }}</p>
							</div>
							<div class="name" *ngIf="template?.userTemplate.template === 'Blog Wizard'">
								<img src="assets/templates/wizard.svg" alt="" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" />
								<p class="title">Blog Wizard ({{ template?.templateData?.title || 'Write From Scratch' }})</p>
							</div>
						</div>
					</form>
					<div class="about" [id]="'selector' + i">
						<!-- special advance generator -->
						<div *ngIf="template?.userTemplate?.template == 'advanced completion'" class="title">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content" *ngIf="item?.advancedCompletion">
									<div class="text" [innerHTML]="item?.advancedCompletion"></div>
								</div>
							</div>
						</div>

						<!-- ad-copy -->
						<div *ngIf="template?.templateData?.category == 'ad-copy'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content" *ngIf="item?.title">
									<div class="text" [innerHTML]="item?.title" [ngClass]="item?.description ? 'boldTitle' : ''"></div>
								</div>
								<div class="content desc" *ngIf="item?.description">
									<div class="text" [innerHTML]="item?.description"></div>
								</div>
								<div class="content" *ngIf="item?.cta">
									<div class="text" [innerHTML]="item?.cta"></div>
								</div>
							</div>
						</div>

						<!-- email -->
						<div *ngIf="template?.templateData?.category == 'email'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.subject">
									<div class="text boldTitle" [innerHTML]="item?.subject"></div>
								</div>
								<div class="content">
									<div class="text" [innerHTML]="item?.email"></div>
								</div>
							</div>
						</div>

						<!-- product-desc -->
						<div *ngIf="template?.userTemplate?.template == 'ProductDescription'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.mainDescription">
									<div class="text" [innerHTML]="item?.mainDescription"></div>
								</div>
								<div *ngFor="let subItem of item.uspDescription">
									<div class="content-2" *ngIf="subItem?.description">
										<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
										<span class="text" [innerHTML]="subItem?.description"></span>
									</div>
								</div>
							</div>
						</div>

						<!-- blogs -->
						<div *ngIf="template?.templateData?.category == 'blog' || template?.userTemplate.template === 'Blog Wizard'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div *ngIf="!fullBlogCase" class="case-default">
									<div class="content desc" *ngIf="item?.para" id="para">
										<div class="text" [innerHTML]="item?.para"></div>
									</div>
									<div class="content desc" *ngIf="item.text" id="blog-global">
										<div class="text" [innerHTML]="item.text"></div>
									</div>
									<div class="content desc" *ngIf="item.title">
										<div class="text" [innerHTML]="item.title"></div>
									</div>
								</div>
								<div *ngIf="fullBlogCase" class="full-blog">
									<div class="group">
										<div class="content desc blog-title" *ngIf="item.title">
											<div class="text boldTitle" [innerHTML]="item.title"></div>
										</div>
										<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
											<div class="text" [innerHTML]="subitem"></div>
										</div>
										<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
											<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
											<div class="text" [innerHTML]="subitem.para"></div>
										</div>
										<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
											<div class="text" [innerHTML]="subitem"></div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- content -->
						<div *ngIf="template?.templateData?.category == 'content'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.improvedContent">
									<div class="text" [innerHTML]="item?.improvedContent"></div>
								</div>
							</div>
						</div>

						<!-- social -->
						<div *ngIf="template?.templateData?.category == 'social'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.title">
									<div class="text" [innerHTML]="item?.title"></div>
								</div>
								<div class="content desc" *ngIf="item?.hashtag">
									<!-- special case for hashtags which is part of social templates -->
									<div class="text" [innerHTML]="item?.hashtag"></div>
								</div>
								<div class="content desc">
									<div class="text" [innerHTML]="item?.caption"></div>
								</div>
							</div>
						</div>

						<!-- framework -->
						<div *ngIf="template?.templateData?.category == 'framework'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.text">
									<div class="text" [innerHTML]="item?.text"></div>
								</div>
							</div>
						</div>
						<!-- bio -->
						<div *ngIf="template?.templateData?.category == 'bio'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.text">
									<div class="text" [innerHTML]="item?.text"></div>
								</div>
							</div>
						</div>
						<!-- seo -->
						<div *ngIf="template?.templateData?.category == 'seo'" class="title blogs">
							<div *ngFor="let item of template?.userOutput; let i = index" class="group">
								<div class="content desc" *ngIf="item?.text">
									<div class="text" [innerHTML]="item?.text"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="actions">
						<div class="icons">
							<span
								(click)="addToFav($event, i, 'folder-library')"
								[matTooltip]="template?.isFav ? 'Remove From Favorite' : 'Add To Favorite'"
								[matTooltipPosition]="'above'"
								[ngClass]="template?.isFav ? 'fav' : 'heart'"
							></span>
							<span
								class="delete"
								matTooltip="Delete"
								[matTooltipPosition]="'above'"
								(click)="deleteOutputFromFolder($event, template.id)"
							></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="footer" *ngIf="checkedCount > 0 && isFooter && !loading">
		<div class="footer-con">
			<div class="left">
				<app-button buttonClass="tertiary" (onClick)="deselectAll()" icon="k-icon k-i-close">{{ checkedCount }} Selected</app-button>
			</div>
			<div class="right">
				<div class="move">
					<app-button buttonClass="primary" (onClick)="openFolderCreateModal()">+ Create Folder</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
