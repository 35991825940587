<div class="form-group-wrapper">
	<div class="label-wrapper">
		<label>{{ inputLabel }}</label>
		<!--<div class="tooltip-container">
			<img alt="" class="ii" src="assets/shakespeare/info-tooltip.svg" />
			<p class="tooltip">
				<span>What are you planning to write?</span>
			</p>
		</div>-->
		<div>
			<div class="counter">
				<span>{{ control?.value ? control?.value?.length : 0 }}/{{ maxLength }}</span>
			</div>
		</div>
	</div>
	<app-text-input [control]="control" [maxLength]="maxLength" [inputPlaceholder]="placeholder"></app-text-input>
</div>
