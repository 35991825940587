import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OptiomizationAiService {
	budgetAiRecommendations: any = '';
	bidAiRecommendations: any = '';
	AdAccountCampaign: any;
	AdAccountId: any;

	googleAdAccount = null;
	fbAdAccount = null;
	adAccountDataLoaded = new BehaviorSubject(false);
	adAccountDataLoaded$ = this.adAccountDataLoaded.asObservable();
	recomendations = null;
	calibrationData = null;
	performanceData = null;
	actionImpaceData = null;
	constructor() {}

	setAdaccount(data) {
		localStorage.setItem('aiOptimiseSelectedAccount', JSON.stringify(data));
	}

	getAdaccount() {
		try {
			let item = JSON.parse(localStorage.getItem('aiOptimiseSelectedAccount'));
			if (item) {
				return item;
			} else return null;
		} catch (e) {
			return null;
		}
	}

	resetAdaccount() {
		localStorage.removeItem('aiOptimiseSelectedAccount');
	}
}
