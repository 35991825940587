<section class="wrapper">
	<section class="inside-container1">
		<div class="left-container" [ngStyle]="isLineGraphLoaded ? { visibility: 'visible' } : { visibility: 'hidden' }">
			<div class="header">
				<span class="left">Metrics</span>
				<span class="right">
					<span class="con1">
						<span class="clr bl"></span>
						<span class="title">Current period</span>
					</span>
					<span class="con2">
						<span class="clr or"></span>
						<span class="title">Previous period</span>
					</span>
				</span>
			</div>
			<div class="graph-container">
				<div class="container1">
					<div class="drop-down">
						<app-custom-drpdown-op1
							class="dropdown"
							[data]="lineChartsDropdownData"
							[dropdownFormControl]="controlA"
							(selectionChange)="onSelectFirstDropdown($event)"
							[useCustom]="false"
							[placeHolder]="'Select/Enter new'"
							[searchEnable]="false"
							[noError]="false"
							[defaultSelectedValue]="[]"
						></app-custom-drpdown-op1>
					</div>
					<div class="graph">
						<canvas id="myChart1" width="100%" height="245"></canvas>
						<!-- <canvas id="myChart" baseChart width="100%" height="245" [type]="'line'" [data]="lineChartDataSecond"
							[options]="lineChartOptionsSecond" [legend]="lineChartLegend"></canvas> -->
					</div>
				</div>
				<div class="container2">
					<div class="drop-down">
						<app-custom-drpdown-op1
							class="dropdown"
							[data]="lineChartsDropdownData"
							[dropdownFormControl]="controlB"
							(selectionChange)="onSelectSecondDropdown($event)"
							[useCustom]="false"
							[placeHolder]="'Select/Enter new'"
							[searchEnable]="false"
							[noError]="false"
							[defaultSelectedValue]="[]"
						></app-custom-drpdown-op1>
					</div>
					<div class="graph">
						<canvas id="myChart2" width="100%" height="245"></canvas>
					</div>
				</div>
			</div>
		</div>
		<div class="loader" *ngIf="!isLineGraphLoaded">
			<ngx-skeleton-loader count="1" appearance="line" style="width: '60%';" [theme]="{ width: '60%', height: '100%' }"></ngx-skeleton-loader>
		</div>
		<div class="right-container" *ngIf="showLoader">
			<div class="highlights">
				<p>
					Highlight of the week
				</p>
				<div>
					<!-- <p *ngIf="!highlightText" class="highlights-week">
						No significant changes were observed.
					</p> -->
				</div>
				<div>
					<p *ngIf="highlightText" class="highlights-week">
						{{ highlightText }}
					</p>
				</div>
			</div>

			<div class="indicators-box">
				<p class="heading_highlitghter">
					Key Indicators
				</p>
				<div class="inc-ctr">
					<p class="grp">
						<span class="width-same">{{ brandLogo == 'google' ? 'Clicks' : 'Reach' }}</span>
						<ng-container *ngIf="summaryData.clicks.changePercentage !== 0 && summaryData.clicks.changePercentage !== -100">
							<img
								class="change-percentage"
								src="assets/icons/arrow-dropdown.svg"
								[ngClass]="summaryData.clicks.changePercentage > 1 ? 'green' : 'red'"
							/>
							<span class="change-percentage-value" [ngClass]="summaryData.clicks.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ summaryData.clicks.changePercentage + '%' }}
							</span>
						</ng-container>
					</p>

					<p class="change-current" [ngClass]="summaryData.clicks.current > 0 ? 'green-text' : 'red-text'">
						{{ summaryData.clicks.current | number: '1.0-0' }}
					</p>
					<!-- {{ summaryData?.ctr?.current ? (summaryData?.ctr?.current | number: '1.2-2') : '0' }}% -->
				</div>
				<div class="inc-ctr cr">
					<p class="grp">
						<span class="width-same">Impressions</span>
						<ng-container *ngIf="summaryData.impressions.changePercentage !== 0 && summaryData.impressions.changePercentage !== -100">
							<img
								class="change-percentage"
								src="assets/icons/arrow-dropdown.svg"
								[ngClass]="summaryData.impressions.changePercentage > 1 ? 'green' : 'red'"
							/>
							<span class="change-percentage-value" [ngClass]="summaryData.impressions.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ summaryData.impressions.changePercentage + '%' }}
							</span>
						</ng-container>
					</p>
					<p class="change-current" [ngClass]="summaryData.impressions.current > 0 ? 'green-text' : 'red-text'">
						{{ summaryData.impressions.current | number: '1.0-0' }}
					</p>
				</div>
				<div class="inc-ctr">
					<p class="grp">
						<span class="width-same">Click Through Rate (CTR)</span>
						<ng-container *ngIf="summaryData.ctr.changePercentage !== 0 && summaryData.ctr.changePercentage !== -100">
							<img
								class="change-percentage"
								src="assets/icons/arrow-dropdown.svg"
								[ngClass]="summaryData.ctr.changePercentage > 1 ? 'green' : 'red'"
							/>
							<span class="change-percentage-value" [ngClass]="summaryData.ctr.changePercentage > 1 ? 'green-text' : 'red-text'">
								{{ summaryData.ctr.changePercentage + '%' }}
							</span>
						</ng-container>
					</p>
					<p class="change-current" [ngClass]="summaryData.ctr.current > 0 ? 'green-text' : 'red-text'">
						{{ summaryData.ctr.current | number: '1.0-0' }}%
					</p>
				</div>
			</div>
		</div>
		<ngx-skeleton-loader
			count="1"
			*ngIf="!showLoader"
			appearance="line"
			style="width: '100%';"
			[theme]="{ width: '100%', height: '100%' }"
		></ngx-skeleton-loader>
	</section>
	<section class="inside-container2">
		<div class="left-container" *ngIf="showLoader">
			<div class="chart-ctr">
				<div class="chart-child-ctr">
					<div class="antys-ctr">
						<p>
							Campaign Performance
						</p>
					</div>
				</div>
				<div class="h-bar-chart">
					<div class="horizontal-bar-chart">
						<canvas
							height="230px"
							baseChart
							[data]="horizontalBarChartData"
							[options]="horizontalBarChartOptions"
							[plugins]="horizontalBarChartPlugins"
							[legend]="horizontalBarChartLegend"
							[type]="'bar'"
						></canvas>
					</div>
				</div>
				<div class="clr-ctr">
					<div class="clr-child-ctr">
						<span class="clr-crl"></span>
						<p class="clr-txt">CTR</p>
					</div>
					<div class="clr-child-ctr">
						<span class="clr-crl red-light"></span>
						<p class="clr-txt">Conversion Rate</p>
					</div>
					<div class="clr-child-ctr">
						<span class="clr-crl black"></span>
						<p class="clr-txt">Impressions</p>
					</div>
				</div>
			</div>
		</div>
		<ngx-skeleton-loader
			count="1"
			*ngIf="!showLoader"
			appearance="line"
			style="width: '100%';"
			[theme]="{ width: '100%', height: '429px' }"
		></ngx-skeleton-loader>

		<div class="middle-container" *ngIf="showLoader">
			<div class="campaing-box">
				<p>
					Performance by Device
				</p>

				<div class="charts">
					<canvas
						baseChart
						height="216"
						width="216"
						[labels]=""
						[datasets]="doughnutChartDatasets"
						[options]="doughnutChartOptions"
						[legend]="true"
						[type]="'doughnut'"
					></canvas>
				</div>

				<div class="performance-ration-section">
					<div class="desktop-and-mobile">
						<img class="desk" src="assets/images/desktop-icon.svg" />
						<div class="content-sec">
							<p class="title">Desktop</p>
							<p class="percentage">{{ desktop | number: '1.0-0' }}%</p>
						</div>
					</div>
					<div class="desktop-and-mobile">
						<img class="tab" src="assets/images/tab-icon.svg" />
						<div class="content-sec">
							<p class="title">Tab</p>
							<p class="percentage">{{ tablet | number: '1.0-0' }}%</p>
						</div>
					</div>
					<div class="desktop-and-mobile">
						<img class="mobile" src="assets/images/mobile-ovr.svg" />
						<div class="content-sec">
							<p class="title">Mobile</p>
							<p class="percentage">{{ mobile | number: '1.0-0' }}%</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ngx-skeleton-loader
			count="1"
			*ngIf="!showLoader"
			appearance="line"
			style="width: '100%';"
			[theme]="{ width: '100%', height: '429px' }"
		></ngx-skeleton-loader>
		<div class="right-container" *ngIf="showLoader">
			<div class="history-box">
				<p>
					AI Change History
				</p>
				<div class="history-head">
					<p>
						Action/Date
					</p>
					<p>
						Item Changed
					</p>
				</div>
				<div *ngIf="historyArray && historyArray.length; else noHistory">
					<div *ngFor="let item of historyArray" class="history-content">
						<p>
							{{ item?.action ? item?.action : 'Action' }}
							<span class="date">
								{{ item?.date ? item?.date : 'Date' }}
							</span>
						</p>
						<p>
							{{ item?.adgroupName ? item?.adgroupName : 'Date' }}
						</p>
					</div>
				</div>

				<ng-template #noHistory>
					<div class="no-records-content">
						<p class="heading_highlitghter">No Records Found</p>
					</div>
				</ng-template>
			</div>
		</div>
		<ngx-skeleton-loader
			count="1"
			*ngIf="!showLoader"
			appearance="line"
			style="width: '100%';"
			[theme]="{ width: '100%', height: '429px' }"
		></ngx-skeleton-loader>
	</section>
</section>
