import { getFiledId, UserState } from './../../shared/state/user/user.reducer';
import { ToastNotificationService } from './../../shared/toast-notification/toast-notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription, timer } from 'rxjs';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PaymentService } from 'src/app/user-management/components/payment.service';
import { TemplateState, getWizardHistory } from 'src/app/state/shakespeare-common-state/shakespeare-common-reducer';
import { select, Store } from '@ngrx/store';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import Quill, * as QuillEditor from 'quill';
import { DocumentInterface } from '../documents/document-interface';
import { DocumentService } from '../documents/document.service';
import { ClipboardService } from 'ngx-clipboard';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-text-editor',
	templateUrl: './text-editor.component.html',
	styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit, AfterViewInit {
	@ViewChild('scroll') private myScrollContainer: ElementRef;
	@ViewChild('output') public outputWrapper: ElementRef;
	@ViewChild('suggestTextWrapper') public suggestTextWrapper: ElementRef;
	@ViewChild('editorOutput', { static: false }) editorElementRef: any;
	@ViewChild('editorControl', { static: false }) editorControl: ElementRef;
	@ViewChild('toolbar', { static: false }) toolbarElementRef: any;
	public html = '';
	public editorForm: FormGroup;
	public priceId = '';
	private unsubscriber$ = new Subject<void>();
	public wizardData: string;
	public isWizardData = false;
	public filedId: number;
	public iterator = 0;
	public typerText = '';
	public language = 'en';
	public editor: any;
	public showGrammar = false;
	showAiButton = false;
	public scrWidth: number;
	public canActivateGrammary = false;
	private selectionProps: { range: any; oldRange: any; source: any };
	grammarStyle = '';
	suggestText = '';
	public isGeneratingOutput = false;
	editorOutput: any;
	public shortKeyName: string = 'CTRL';
	autoCompleteResponse: any;
	public document: DocumentInterface = {
		createdOn: '1661781115.4748454',
		editedOn: '1661865565.3414576',
		id: 'be5402ec-27a1-11ed-8193-06ddfa98e15f',
		name: 'New Blog-Wizard data',
		text: ''
	};
	public isEditingTitle = false;
	public editedValue: string;
	shakespeareButtonStyle: string;
	public changeTimer$: Subscription;
	public baseUrl = BaseApiUrl;
	public documentUrl = `${BaseApiUrl.DexterGenesis}dexter-content-creator/document/`;
	documentId: any;
	backUrl: string;
	private cursorPosition = 0;

	constructor(
		private fb: FormBuilder,
		private paymentService: PaymentService,
		private templateStore: Store<TemplateState>,
		private userServiceApi: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private userStore: Store<UserState>,
		private documentService: DocumentService,
		private clipboardService: ClipboardService,
		private httpClient: HttpClient,
		private activatedRoute: ActivatedRoute
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
	}

	ngOnInit(): void {
		this.createForm();
		this.scrWidth = window.innerWidth;
		this.shortKeyName = navigator.userAgent.match('Macintosh') ? 'CMD' : 'CTRL';
		const icons = Quill.import('ui/icons');
		icons[
			'redo'
		] = `<svg width="24" height="24" focusable="false"><path d="M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 1 1-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 1 1 1.4-1.4l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l3.3-3.3Z" fill-rule="nonzero"></path></svg>`;
		icons[
			'undo'
		] = `<svg width="24" height="24" focusable="false"><path d="M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 0 1-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 1 1-1.4 1.4l-5-5a1 1 0 0 1 0-1.4l5-5a1 1 0 0 1 1.4 1.4L6.4 8Z" fill-rule="nonzero"></path></svg>`;
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}

	public createNewFromScratch(text: string): void {
		const data: DocumentInterface = {
			name: 'untitled Document',
			text: text
		};
		this.createDraft(data);
	}

	public createDraft(data?: DocumentInterface): void {
		const postData = {
			user: {
				userFiledId: this.filedId
			},
			data: data
		};
		this.httpClient.post<DocumentInterface>(`${this.documentUrl}create`, postData);
	}

	public ngAfterViewInit(): void {
		this.editorOutput = new QuillEditor.default(this.editorElementRef.nativeElement, {
			placeholder: 'Begin writing on your own...',
			modules: {
				toolbar: {
					container: this.toolbarElementRef.nativeElement,
					handlers: {
						undo: () => {
							this.editorOutput?.history?.undo();
						},
						redo: () => {
							this.editorOutput?.history?.redo();
						}
					}
				},
				history: {
					userOnly: false
				}
			},
			theme: 'snow'
		});
		this.editorOutput.keyboard.addBinding({
			key: 'B',
			shortKey: true,
			handler: function (range, context) {}
		});
		this.editorOutput.keyboard.addBinding({
			key: 'S',
			shortKey: true,
			handler: (range, context) => {
				this.generateText();
			}
		});
		let saveObservable: Subscription;
		this.editorOutput.on('text-change', (delta, oldDelta, source) => {
			this.showAiButtonR();
			this.cursorPosition = delta?.ops[0].retain ?? 0;
			saveObservable?.unsubscribe();
			saveObservable = timer(2000).subscribe(res => {
				this.saveDocument(this.document);
			});
		});
		this.editorOutput.on('selection-change', (range, oldRange, source) => {
			if (range) {
				if (range.length == 0) {
					this.cancelGrammary();
				} else {
					this.canActivateGrammary = true;
				}
				this.selectionProps = { range, oldRange, source };
				this.cursorPosition = range.index;
				this.showAiButtonR();
			} else {
			}
		});
		this.getWizardData();
	}

	private saveDocument(_document: DocumentInterface): void {
		_document.text = this.editorOutput?.root?.innerHTML;
		console.log(_document.text);
		this.documentService.updateDocument(_document).subscribe(res => {
			this.isEditingTitle = null;
			this.editedValue = null;
		});
	}

	public showAiButtonR(): void {
		this.changeTimer$?.unsubscribe();
		this.showAiButton = false;
		this.changeTimer$ = timer(3000).subscribe(res => {
			const position = this.editorOutput.getBounds(this.editorOutput.getSelection()?.index ?? this.editorOutput.getText().length);
			const space = this.scrWidth <= 900 ? '1px' : '20px';
			const spaceVertical = this.scrWidth <= 900 ? `calc( 29px + ${position.top}px)` : `calc( 42px + ${position.top}px)`;
			this.shakespeareButtonStyle = `top: ${spaceVertical}; position: absolute; left: calc( ${space} + ${position.left}px); right: ${position.right}px; bottom: auto`;
			this.showAiButton = true;
		});
	}

	setGrammer(): void {
		this.showAiButton = false;
		if (!this.canActivateGrammary) {
			return;
		}
		const selectedText = this.editorOutput.getText(this.selectionProps.range.index, this.selectionProps.range.length);
		if (selectedText.length >= 200) {
			this.toastNotificationService.sendErrorToast('Please select less than 200 words.');
			return;
		}
		if (this.isGeneratingOutput) {
			return;
		}
		const details = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				userTemplate: {
					template: 'content'
				},
				userInput: {
					content: selectedText,
					tone: '',
					num_outputs: 1,
					contentType: 'grammar'
				}
			}
		};
		this.isGeneratingOutput = true;
		this.userServiceApi
			.getContentImprover(details)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.isGeneratingOutput = false;
					if (response['body'].debugMessage === 'Not enough credit') {
						this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
						return '';
					}
					this.suggestText = response['body']['output'][0].improvedContent;
					this.showGrammar = true;
					const position = this.editorOutput.getBounds(this.selectionProps.range.index);
					const space = this.scrWidth <= 900 ? '1px' : '72px';
					const spaceVertical = this.scrWidth <= 900 ? `calc( 29px + ${position.top}px)` : `calc( 72px + ${position.top}px)`;
					if (this.scrWidth <= 900) {
						this.grammarStyle = `top: ${spaceVertical}; position: absolute; left: 0; right: 0; bottom: auto`;
					} else {
						const suggestTextLength = this.suggestText.length > 65;
						const left = suggestTextLength ? 'auto' : `calc( ${space} + ${position.left}px)`;
						const right = suggestTextLength ? 'auto' : `${position.right}px`;
						this.grammarStyle = `top: ${spaceVertical}; position: absolute; left: ${left}; right: ${right}; bottom: auto`;
					}
				},
				() => {
					this.isGeneratingOutput = false;
					this.toastNotificationService.sendErrorToast('There was as error, Please try after some time.');
				}
			);
	}

	replaceText() {
		this.editorOutput.deleteText(this.selectionProps?.range.index, this.selectionProps.range.length);
		this.editorOutput.clipboard.dangerouslyPasteHTML(this.selectionProps?.range.index, this.suggestText);
		this.editorOutput.setSelection(this.editorOutput.getText()?.length);
		this.cancelGrammary();
	}

	public copyText(): void {
		this.clipboardService.copy(this.suggestTextWrapper.nativeElement.innerText);
		this.toastNotificationService.sendSuccessToast('Copied to clipboard');
		this.cancelGrammary();
	}

	public cancelGrammary(): void {
		this.canActivateGrammary = false;
		this.selectionProps = null;
		this.showGrammar = false;
		this.suggestText = null;
		this.grammarStyle = '';
	}

	public presentEditorText(typerText: string): void {
		const selection = this.editorOutput.getSelection();
		const previousText = this.editorOutput.getText();
		const newCursorPosition = selection?.index ?? this.cursorPosition ?? previousText.length;
		this.editorOutput.clipboard.dangerouslyPasteHTML(newCursorPosition, typerText);
	}

	public generateText(): void {
		const space = this.scrWidth <= 900 ? '1px' : '20px';
		const spaceVertical = this.scrWidth <= 900 ? `calc( 29px + ${0}px)` : `calc( 42px + ${0}px)`;
		this.shakespeareButtonStyle = `top: ${spaceVertical}; position: absolute; left: calc( ${space} + ${0}px); right: ${0}px; bottom: auto`;
		this.showAiButton = true;
		this.isGeneratingOutput = true;
		const selection = this.editorOutput.getSelection();
		const previousText = this.editorOutput.getText();
		let selectText = previousText;
		if (selection && selection?.index > 0) {
			selectText = previousText.slice(0, selection.index);
		}
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				userTemplate: {
					template: 'advanced_completion'
				},
				userInput: {
					text: selectText ?? '',
					compeltion_type: 'default',
					length: 'small' // medium, long,
				}
			}
		};
		if (this.language !== 'en') {
			payload['data'].userInput['outputLanguage'] = this.language;
			payload['data'].userInput['translate'] = true;
		}
		this.userServiceApi
			.getAutoCompletion(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.isGeneratingOutput = false;
					if (response['body'].debugMessage === 'Not enough credit') {
						this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
						return '';
					}
					this.autoCompleteResponse = response['body']['output'][0].advancedCompletion;
					this.iterator = 0;
					this.typerText = '';

					// this.presentEditorText(this.autoCompleteResponse);
					this.typeWriter();
				},
				() => {
					this.toastNotificationService.sendErrorToast('There was as error, Please try after some time.');
				}
			);
	}

	save() {
		console.log(this.editor);
		this.editor.editorCommands.commands.exec.mceprint();
	}

	public typeWriter() {
		if (this.iterator < this.autoCompleteResponse.length) {
			if (this.autoCompleteResponse.charAt(this.iterator) == '<') {
				// adding '<p></p>' to break line
				this.presentEditorText('<p></p>');
				this.iterator += 3;
			} else if (this.autoCompleteResponse.charAt(this.iterator) == ' ') {
				this.presentEditorText('<span> </span>');
			} else {
				this.typerText = this.autoCompleteResponse.charAt(this.iterator);
				this.presentEditorText(this.typerText);
			}
			this.iterator++;
			setTimeout(() => {
				this.typeWriter();
			}, 5);
		}
	}

	public getWizardData(): void {
		this.templateStore.pipe(select(getWizardHistory), takeUntil(this.unsubscriber$)).subscribe(data => {
			if (data['templateData']) {
				this.isWizardData = true;
				this.populateWizardData(data['templateData']);
			}
		});
	}

	public populateWizardData(data: object): void {
		console.log(data);

		let i = 1;
		this.language = data['language'];
		this.wizardData = '<b>' + data['headline'].headline + '</b><br><br>';
		if (data['introduction'] != '') {
			this.wizardData += data['introduction'].intro || '' + '<br><br><br>';
		}
		if (!data['outlineStar']) {
			data['outline'].forEach(item => {
				this.wizardData += '<br><br><b>' + i++ + '. ' + item.outline + '</b><br>';
				this.wizardData += item.para || '';
				this.wizardData += '<br>';
			});
		} else {
			let outlineArr = this.handleOutline(data['outlineHelper'], data['outlineStar']);
			let flag = false;
			outlineArr.forEach(item => {
				this.wizardData += '<br><br><b>' + i++ + '. ' + item + '</b>';
				if (data['outline'].length > 0) {
					data['outline'].forEach(outline => {
						if (outline.outline == item) {
							this.wizardData += '<br>' + outline.para;
							flag = true;
						} else {
							flag = false;
						}
					});
				}
				if (!flag) {
					this.wizardData += '<br><br>-<br><br>';
				}
			});
		}

		this.wizardData += data['conclusion'].con || '';

		this.editorForm.patchValue({
			data: this.wizardData
		});
		this.presentEditorText(this.wizardData);
	}

	public handleOutline(outlines, index): string[] {
		let outlineArray = [];
		index.forEach(idx => {
			outlineArray.push(outlines[idx]);
		});
		return outlineArray;
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['tempID']) {
				this.documentId = params['tempID'];
				this.fetchDocumentDetail(this.documentId);
			}
			if (params['prd'] == true) {
				this.backUrl = '/document';
			}
		});
	}

	private fetchDocumentDetail(tempId: string) {
		this.documentService.getDocument(tempId).subscribe(res => {
			this.document = res as DocumentInterface;
			this.presentEditorText(this.document.text);
		});
	}

	setup(editor) {
		editor.ui.registry.addMenuButton('compasdasdose', {
			text: 'sadasd',
			onAction: function () {
				// editor.activeEditor.execCommand('mceSave');
			}
		});
	}

	public createForm(): void {
		this.editorForm = this.fb.group({
			data: new FormControl('hello<br><b>world<b>')
		});
	}
}
