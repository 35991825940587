import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import { SharedActions, SharedActionTypes } from './shared.actions';
import { FiledUsersApiCodesEnum } from './state-entities/errors/api-codes.enum';
import { FiledSubscriptionApiValidationCodesEnum, FiledUsersApiValidationCodesEnum } from './state-entities/errors/api-validation.enum';
import { FiledApiModels } from './state-entities/errors/errors.model';
import { DataInterface, NullDataInterface } from './state-entities/data-interface.model';
import { BreakdownsResponseInterface } from '../models/breakdowns-response.interface';

export interface SharedState {
	globalDateSelection: any;
	globalSpinnerShown: boolean;
	filedApiEnums: FiledApiModels;
	measureSettings: {
		breakdowns: DataInterface<BreakdownsResponseInterface>;
	};
	globalElementsView: string;
	globalElementsViewAds: string;
	toggleElementsGraph: boolean;
	toggleElementsMetrics: boolean;
	toggleElementsRecommendations: boolean;
	selectedMetrics: {
		isLoading: boolean;
	};
	selectedAdsManagerTab: string;
}

export const initialSharedState: SharedState = {
	globalDateSelection: '',
	globalSpinnerShown: false,
	filedApiEnums: {
		filedApiUsersState: {
			filedUsersApiCodesState: [
				{
					code: FiledUsersApiCodesEnum.InitialPasswordWasAlreadySetup,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.Filed__Domain__NA__SetPassword__IsInvalidPasswordMismatch,
					message: 'Password mismatch'
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresDigit,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresLower,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresNonAlphanumeric,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresUniqueChars,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordRequiresUpper,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SetPassword_PasswordTooShort,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityIsLockedOut,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityIsNotAllowed,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.SignIn_IdentityRequiresTwoFactor,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_HasUserWithNoRolesDefined,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_IsDifferentInRouteAndJwt,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserId_IsNotClientEmployeeForCurrentUser,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ByBusinessOwnerFacebookId,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ByEmail,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWasNotFound_ById,
					message: ''
				},
				{
					code: FiledUsersApiCodesEnum.UserWithSameEmail_AlreadyExists,
					message: ''
				}
			],
			filedUsersApiValidationCodesState: [
				{
					code: FiledUsersApiValidationCodesEnum.BusinessOwnerFacebookId_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.BusinessOwnerGoogleId_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeeFirstName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeeLastName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeePermissions_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.ClientEmployeePhoneNumber_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.InputFile_HasNoContent,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.InputFile_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileContentType_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileContentType_IsNotImageOrVideo,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.FileName_IsNullOrEmpty,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_CurrentPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_NewPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_ConfirmNewPassword_IsNull,
					message: ''
				},
				{
					code: FiledUsersApiValidationCodesEnum.SetPassword_NewPassword_DiffersFomConfirmNewPassword,
					message: ''
				}
			]
		},
		filedSubscriptionApi: {
			filedSubscriptionApiModel: [
				{
					code: FiledSubscriptionApiValidationCodesEnum.Filed__Domain__NA__PhoneNumber_ContactPhoneNumber__AreDifferent,
					message: 'The phone number entered does not match the phone number of the account'
				},
				{
					code: FiledSubscriptionApiValidationCodesEnum.Filed__Domain__NA__Contact_RemoveAt__IsNotNull,
					message: 'This account subscription is already cancelled'
				}
			]
		}
	},
	measureSettings: {
		breakdowns: _.cloneDeep(NullDataInterface)
	},
	toggleElementsGraph: true,
	toggleElementsMetrics: true,
	toggleElementsRecommendations: true,
	selectedMetrics: {
		isLoading: false
	},
	globalElementsView: '',
	globalElementsViewAds: '',
	selectedAdsManagerTab: ''
};

export const getSharedState = createFeatureSelector<SharedState>('shared');

export const getApiEnums = createSelector(getSharedState, state => state.filedApiEnums);
export const getGlobalSpinnerStatus = createSelector(getSharedState, state => state.globalSpinnerShown);

export function sharedReducer(state = initialSharedState, action: SharedActions): SharedState {
	switch (action.type) {
		case SharedActionTypes.SetGlobalElementsView: {
			return {
				...state,
				globalElementsView: action.payload
			};
		}

		case SharedActionTypes.SetGlobalElementsViewAds: {
			return {
				...state,
				globalElementsViewAds: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsGraph: {
			return {
				...state,
				toggleElementsGraph: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsMetrics: {
			return {
				...state,
				toggleElementsMetrics: action.payload
			};
		}

		case SharedActionTypes.SetToggleElementsRecommendations: {
			return {
				...state,
				toggleElementsRecommendations: action.payload
			};
		}
		case SharedActionTypes.LoadBreakdowns: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						isLoading: true
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdownsSuccess: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						data: action.payload,
						isLoading: false,
						isLoaded: true
					}
				}
			};
		}

		case SharedActionTypes.LoadBreakdownsFailure: {
			return {
				...state,
				measureSettings: {
					...state.measureSettings,
					breakdowns: {
						...state.measureSettings.breakdowns,
						errorCode: action.payload
					}
				}
			};
		}

		case SharedActionTypes.ShowGlobalSpinner: {
			return {
				...state,
				globalSpinnerShown: true
			};
		}
		case SharedActionTypes.HideGlobalSpinner: {
			return {
				...state,
				globalSpinnerShown: false
			};
		}

		case SharedActionTypes.SetGlobalDate: {
			return {
				...state,
				globalDateSelection: action.payload
			};
		}

		default:
			return state;
	}
}
