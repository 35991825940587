<div class="back-office">
	<div *ngIf="loading" class="loader-wrapper">
		<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
	</div>
	<div class="data-table">
		<div style="margin-bottom: 16px; display: flex; gap: 8px; flex-wrap: wrap;">
			<app-button buttonClass="secondary" [class.active]="sortType == 'all'" (onClick)="sortData('all')">All</app-button>
			<app-button buttonClass="secondary" [class.active]="sortType == 'today'" (onClick)="sortData('today')">Today</app-button>
			<app-button buttonClass="secondary" [class.active]="sortType == 'yesterday'" (onClick)="sortData('yesterday')">Yesterday</app-button>
			<app-button buttonClass="secondary" [class.active]="sortType == 'week'" (onClick)="sortData('week')">Last 7 Days</app-button>
			<app-button buttonClass="secondary" [class.active]="sortType == 'month'" (onClick)="sortData('month')">This Month</app-button>
			<app-button buttonClass="secondary" [class.active]="sortType == 'last_month'" (onClick)="sortData('last_month')">Last Month</app-button>
		</div>
		<div class="date-selector-wrapper">
			<kendo-datepicker format="MMM dd, yyyy" [(value)]="startDate"></kendo-datepicker>
			<span>to</span>
			<kendo-datepicker format="MMM dd, yyyy" [(value)]="endDate"></kendo-datepicker>
			<app-button buttonClass="secondary" (onClick)="filterData()">Filter Data</app-button>
		</div>
		<div class="download" *ngIf="!loading">
			<button class="btn" (click)="exporter.exportTable('csv', { fileName: 'Shakespeare_Backoffice' })">Csv</button>
			<button
				class="btn"
				(click)="exporter.exportTable('xlsx', { fileName: 'Shakespeare_Backoffice', sheet: 'backoffice', Props: { Author: 'Shakespeare' } })"
			>
				Excel
			</button>
			<button class="btn" (click)="exporter.exportTable('json', { fileName: 'Shakespeare_Backoffice' })">Json</button>
			<button class="btn" (click)="exporter.exportTable('txt', { fileName: 'Shakespeare_Backoffice' })">Txt</button>
		</div>
		<div class="mat-elevation-z8" style="overflow: auto;">
			<table mat-table matTableExporter [dataSource]="dataSource" matSort #exporter="matTableExporter">
				<ng-container matColumnDef="userid">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
					<td mat-cell *matCellDef="let element">{{ element.userid }}</td>
				</ng-container>

				<ng-container matColumnDef="userEmail">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
					<td mat-cell *matCellDef="let element">{{ element.userEmail }}</td>
				</ng-container>
				<ng-container matColumnDef="accountState">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Account State</th>
					<td mat-cell *matCellDef="let element">{{ element.accountState }}</td>
				</ng-container>
				<ng-container matColumnDef="firstName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
					<td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
				</ng-container>
				<ng-container matColumnDef="lastName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
					<td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
				</ng-container>
				<ng-container matColumnDef="createdAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
					<td mat-cell *matCellDef="let element">{{ element.createdAt | date }}</td>
				</ng-container>
				<ng-container matColumnDef="lastLogin">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</th>
					<td mat-cell *matCellDef="let element">{{ element.lastLogin | date }}</td>
				</ng-container>
				<ng-container matColumnDef="currentPlan">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Current Plan</th>
					<td mat-cell *matCellDef="let element">{{ element.currentPlan }}</td>
				</ng-container>
				<ng-container matColumnDef="LTV">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>LTV</th>
					<td mat-cell *matCellDef="let element">{{ element.LTV }}</td>
				</ng-container>
				<ng-container matColumnDef="MRR">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>MRR</th>
					<td mat-cell *matCellDef="let element">{{ element.MRR }}</td>
				</ng-container>
				<ng-container matColumnDef="creditUsed">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Credit Used</th>
					<td mat-cell *matCellDef="let element">{{ element.creditUsed }}</td>
				</ng-container>
				<ng-container matColumnDef="firstPayment">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>First Payment</th>
					<td mat-cell *matCellDef="let element">{{ element.firstPayment }}</td>
				</ng-container>
				<ng-container matColumnDef="firstPaymentDate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>First Payment Date</th>
					<td mat-cell *matCellDef="let element">{{ element.firstPaymentDate | date }}</td>
				</ng-container>
				<ng-container matColumnDef="lastPayment">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Payment</th>
					<td mat-cell *matCellDef="let element">{{ element.lastPayment }}</td>
				</ng-container>
				<ng-container matColumnDef="lastPaymentDate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Payment Date</th>
					<td mat-cell *matCellDef="let element">{{ element.lastPaymentDate | date }}</td>
				</ng-container>
				<ng-container matColumnDef="marketingSource">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Marketing Source</th>
					<td mat-cell *matCellDef="let element">{{ element.marketingSource }}</td>
				</ng-container>
				<ng-container matColumnDef="paymentPlatformCustomerId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Platform Customer Id</th>
					<td mat-cell *matCellDef="let element">{{ element.paymentPlatformCustomerId }}</td>
				</ng-container>
				<ng-container matColumnDef="country">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
					<td mat-cell *matCellDef="let element">{{ element.country }}</td>
				</ng-container>
				<ng-container matColumnDef="paymentDetailAdded">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Detail Added</th>
					<td mat-cell *matCellDef="let element">{{ element.paymentDetailAdded }}</td>
				</ng-container>
				<ng-container matColumnDef="referenceLink">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Reference Link</th>
					<td mat-cell *matCellDef="let element">{{ element.referenceLink }}</td>
				</ng-container>
				<ng-container matColumnDef="UtmCampaign">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Utm Campaign</th>
					<td mat-cell *matCellDef="let element">{{ element.UtmCampaign }}</td>
				</ng-container>
				<ng-container matColumnDef="UtmMedium">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Utm Medium</th>
					<td mat-cell *matCellDef="let element">{{ element.UtmMedium }}</td>
				</ng-container>
				<ng-container matColumnDef="UtmSource">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Utm Source</th>
					<td mat-cell *matCellDef="let element">{{ element.UtmSource }}</td>
				</ng-container>
				<ng-container matColumnDef="UtmTerm">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Utm Term</th>
					<td mat-cell *matCellDef="let element">{{ element.UtmTerm }}</td>
				</ng-container>
				<ng-container matColumnDef="ipLocation">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>IP Location</th>
					<td mat-cell *matCellDef="let element">{{ element.ipLocation }}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>

			<mat-paginator
				[pageSizeOptions]="[5, 10, 20, 50, 1000]"
				[pageSize]="50"
				showFirstLastButtons
				aria-label="Select page of periodic elements"
			></mat-paginator>
		</div>
	</div>
</div>
