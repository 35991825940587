import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { MatDialog } from '@angular/material/dialog';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NoCreditModalComponent } from '../../templates/no-credit-modal/no-credit-modal.component';
import { ViewProductComponent } from '../view-product/view-product.component';
import { DocumentInterface, EditorModeEnum, ToggleValueInterface } from '../../documents/document-interface';
import { DocumentService } from '../../documents/document.service';
import { FormGroup } from '@angular/forms';
import { BcModalComponent } from '../bc-modal/bc-modal.component';
import { BigCommerceService } from '../big-commerce.service';

@Component({
	selector: 'app-bc-output',
	templateUrl: './bc-output.component.html',
	styleUrls: ['./bc-output.component.scss']
})
export class BcOutputComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public formData: object;
	@Input() public selectedProduct: object;
	@Input() public clearOutput$: BehaviorSubject<boolean>;
	@Output() public callbackData = new EventEmitter<any>();
	@Output() public isDataFetched = new EventEmitter<boolean>();
	@Output() public generateDataComplete: EventEmitter<any> = new EventEmitter();
	@Output() public editorOn = new EventEmitter<any>();
	public editorMode: EditorModeEnum = EditorModeEnum.Pro;
	public isGeneratingOutput = false;
	public generatedData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	private unsubscriber$ = new Subject<void>();
	public idCounter = 1;
	public filedId: number;
	public category: string;
	public isCopied = false;
	public currentCopiedEl: number;
	public copyHelper = '';
	fullBlogCase: boolean;
	public noCredits = false;
	public dynamicText = 'Ai is writing the best outputs for you.';
	public userFName: string;
	billPending: boolean;
	billLink: string;
	selectedTemplate: TemplatesModel;
	timer: NodeJS.Timeout;
	myInterval: NodeJS.Timeout;
	public templateName = '';
	public menuExpanded = false;
	mouseEntered = false;
	isTextEditor: boolean;

	public isEditingTitle = false;
	public editedValue: string;
	public editorOutput: any;
	public document: DocumentInterface = {
		createdOn: '1661781115.4748454',
		editedOn: '1661865565.3414576',
		id: 'be5402ec-27a1-11ed-8193-06ddfa98e15f',
		name: 'New Documents',
		text: ''
	};
	public documentId: string;
	public showGrammar = false;
	public aiSelectionProp = { start: 0, end: 0, text: '' };
	public presentEditorText$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public isDeleteAllText$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	writingText: boolean;
	public toggleValues: ToggleValueInterface = {
		isTitleEnabled: true,
		isDescriptionEnabled: true,
		outputToneEnabled: true,
		isKeywordEnabled: true
	};
	public generatorForm: FormGroup;
	keywordAsArray = [];
	selectedOutputLang: string;
	advanceOptions: any;
	iterator: number;
	isSaving: boolean;
	scrWidth: number;
	selectedProductId: any;
	numOutputs: any;

	constructor(
		private userServiceApi: UserServiceApi,
		public toastNotificationService: ToastNotificationService,
		private userStore: Store<UserState>,
		private dialog: MatDialog,
		private shakespeareService: ShakespeareService,
		private backOfficeApiService: BackOfficeService,
		private authenticationService: AuthenticationService,
		private bigCommerceService: BigCommerceService,
		private documentService: DocumentService
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.shakespeareService.autoScrollOff();
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.scrWidth = window.innerWidth;
		if (this.formData) {
			this.getData();
		}
		if (this.editorMode === EditorModeEnum.Pro) {
			this.generatorForm?.get('keywords')?.valueChanges?.subscribe(result => {
				if (result) {
					this.keywordAsArray = result.toString()?.split(',') ?? [];
				}
			});
		}
		// this.getPendingBillStatus();
		this.clearOutput$.subscribe(val => {
			if (val) {
				let temp = [...this.generatedData];
				this.manualHistorySave(temp);
				this.generatedData = [];
			}
		});
	}

	public saveDocument(_document: DocumentInterface = this.document): void {
		_document.text = this.editorOutput?.root?.innerHTML;
		this.documentService.updateDocument(_document).subscribe(res => {
			this.isEditingTitle = null;
			this.editedValue = null;
		});
	}

	public writingStart(event?: any): void {
		this.writingText = true;
	}

	public writingEnd(event?: any): void {
		this.writingText = false;
	}

	public getEditorControl(event: any): void {
		this.editorOutput = event;
	}

	public saveEditorTextToBc(): void {
		this.isSaving = true;
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				product_id: this.formData['data'].product_id,
				description: this.editorOutput?.root?.innerHTML
			}
		};
		this.bigCommerceService
			.saveProductDescription(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isSaving = false;
						if (response['debugMessage'] === 'Product description saved successfully') {
							this.toastNotificationService.sendSuccessToast('Product Description saved!');
						}
					}
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	public getPendingBillStatus(): void {
		this.backOfficeApiService
			.getBillStatus()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.billPending = true;
					this.billLink = response?.hostedInvoiceURL;
				}
			});
	}

	public onSubmitEditor(): void {
		this.isFormSubmitted = true;
		if (this.isGeneratingOutput || this.writingText) {
			return;
		}
		let details = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				userTemplate: {
					template: 'advanced completion'
				},
				userInput: {
					title: this.formData['data'].title || '',
					text: '',
					description: this.formData['data'].description || '',
					tone: this.formData['data'].tone || '',
					input_language: this.formData['data'].outputLanguage || 'en',
					outputLanguage: this.formData['data'].outputLanguage || 'en',
					translate: this.formData['data'].translate || false,
					length: 'medium',
					compeltion_type: 'default',
					keywords: ''
				}
			}
		};
		this.generate(details);
	}

	public generate(details?: any): void {
		this.isGeneratingOutput = true;
		const selection = this.editorOutput.getSelection();
		const previousText = this.editorOutput.getText();
		this.writingStart();
		let selectText = previousText;
		if (selection && selection?.index > 0) {
			selectText = previousText.slice(0, selection?.index);
		}
		details.data.userInput.text = selectText ?? '';
		this.userServiceApi
			.getAutoCompletion(details)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.isGeneratingOutput = false;
					if (response['body'].debugMessage === 'Not enough credit') {
						this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
						this.writingEnd();
						return '';
					}
					let autoCompleteResponse = response['body']['output'][0].advancedCompletion;
					this.iterator = 0;
					let typerText = '';
					typerText = autoCompleteResponse;
					var temporalDivElement = document.createElement('div');
					temporalDivElement.innerHTML = typerText;
					this.aiSelectionProp.text = temporalDivElement.innerText;
					this.sendToEditor(true, typerText);
				},
				() => {
					this.writingEnd();
					this.isGeneratingOutput = false;
					this.toastNotificationService.sendErrorToast('There was as error, Please try after some time.');
				}
			);
	}

	private sendToEditor(aiEditor = false, text = ''): void {
		this.presentEditorText$.next({ aiEditor, text });
	}

	confirmSave(item: object): void {
		let modalConfig;
		let isMobile = this.scrWidth < 780 ? true : false;
		if (isMobile) {
			modalConfig = {
				width: '90%',
				height: '328px',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '436px',
				height: '328px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(BcModalComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			disableClose: true,
			data: {
				title: 'Please Confirm',
				desc: 'This process will update your current Big Commerce product description immediately.',
				isCancel: true,
				cta: 'Update'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.confirm) {
				this.saveDescription(item);
			}
		});
	}

	saveDescription(item: object): void {
		this.isSaving = true;
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				product_id: this.formData['data'].product_id,
				description: item['mainDescription']
			}
		};
		this.bigCommerceService
			.saveProductDescription(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isSaving = false;
						if (response['debugMessage'] === 'Product description saved successfully') {
							this.toastNotificationService.sendSuccessToast('Product Description saved!');
						}
					}
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	aiAssist(): void {
		this.onSubmitEditor();
	}

	deleteAll(): void {
		this.isDeleteAllText$.next(true);
		setTimeout(() => {
			this.isDeleteAllText$.next(false);
		}, 2000);
	}

	public previewProduct(item: object): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '85%',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '1053px',
				height: '763px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ViewProductComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		this.selectedProduct['description'] = item?.['mainDescription'];
		dialogRef.componentInstance.modelDetail = {
			product: this.selectedProduct
		};
		dialogRef.afterClosed().subscribe(obj => {
			// console.log(obj);
		});
	}

	switchToEditor(item: object): void {
		this.isTextEditor = true;
		this.editorOn.emit(true);
		this.presentEditorText$.next({ text: item['mainDescription'] });
	}

	handleBackButton(event: boolean): void {
		this.isTextEditor = !event;
		this.editorOn.emit(!event);
	}

	ngOnChanges(): void {
		if (this.formData) {
			// this.numOutputs = this.formData['data'].numOutputs;
			// let flag = this.formData['data'].numOutputs;
			// while (flag) {
			// 	this.generateBc();
			// 	flag--;
			// }
			this.generateBc();
		}
	}

	public generateBc(): void {
		this.category = 'bc-description';
		this.isFormSubmitted = true;
		let temp = [...this.generatedData];
		this.generatedData = []; // clearing data and re-adding after response
		this.isLoadingData = true;
		this.isDataFetched.emit(false);
		delete this.formData['extras'];
		let payload = JSON.parse(JSON.stringify(this.formData));
		// payload['data'].numOutputs = 1;
		this.userServiceApi
			.BcGenerator(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					if (response) {
						this.tempResponseHandler(response, temp);
					}
				},
				error: () => {
					this.toastNotificationService.sendErrorToast('An error occurred, please try again later');
					this.isLoadingData = false;
					this.isDataFetched.emit(true);
				},
				complete: () => {
					this.isDataFetched.emit(true);
				}
			});
	}

	tempResponseHandler(response, oldRes): void {
		if (response['body'].debugMessage === 'Not enough credit') {
			this.openCreditModal();
			this.isLoadingData = false;
			return;
		}
		let data = [...response['body']['data']];
		let patchData = [...data, ...oldRes];
		for (let item of patchData) {
			item['id'] = this.idCounter++;
			item['isNew'] = false;
		}
		let count = this.formData['data'].numOutputs;
		for (let item of patchData) {
			if (count == 0) break;
			item['isNew'] = true;
			count--;
		}
		this.generatedData.push(...patchData);
		this.sortData();
		this.isLoadingData = false;
		this.isDataFetched.emit(true);
		this.callbackData.emit(this.generatedData);
	}

	responseHandler(response, oldRes): void {
		if (response['body'].debugMessage === 'Not enough credit') {
			this.openCreditModal();
			this.isLoadingData = false;
			return;
		}
		let data = [...response['body']['data']];
		let patchData = [...data, ...oldRes];
		for (let item of patchData) {
			item['id'] = this.idCounter++;
			item['isNew'] = false;
		}
		let count = this.formData['data'].numOutputs;
		for (let item of patchData) {
			if (count == 0) break;
			item['isNew'] = true;
			count--;
		}
		this.generatedData.push(...patchData);
		this.sortData();
		if (this.generatedData.length == this.numOutputs) {
			this.isLoadingData = false;
			this.isDataFetched.emit(true);
			this.callbackData.emit(this.generatedData);
		}
	}

	public openBillPayModal(): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		dialogRef.componentInstance.modelDetail = {
			title: '',
			text: 'Your bill is pending',
			actionBtnText: 'Pay',
			backRoute: '',
			cta: this.billLink,
			closeBtn: true,
			actionBtn: true,
			customRoute: true
		};
		dialogRef.afterClosed().subscribe(obj => {});
	}

	public getData(): void {
		// check for different endpoints for different templates
		let url = window.location.href;
		let urlArray = url.split('/');
		this.category = urlArray[urlArray.length - 1].split('?')[0]; // getting last part of url without any queryParams
		let dynamicTextArray = [
			// 'Hello ' + this.userFName + ', Shakespeare reporting for duty',
			"As soon as I'm finished writing, your results will be shown here.",
			'Choose the output tone that best aligns with your brand or the purpose of your product.',
			"If you're targeting a specific gender, you may select one here.",
			'While the default is English, Shakespeare can generate copy in over 100+ languages.',
			'Use blog wizard to write the blog in seconds.',
			"Paste the content you'd like Shakespeare to improve or rewrite in content improver."
		];
		let i = 0;
		clearInterval(this.myInterval);
		this.myInterval = setInterval(() => {
			if (i == dynamicTextArray.length) {
				i = 0;
			}
			this.dynamicText = dynamicTextArray[i];
			i++;
		}, 3000);
	}

	public refreshToken(): void {
		this.backOfficeApiService
			.refreshUserToken()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {},
				error => {
					if (error.error[0]['code'] == 'Lolly__Domain__NA__User__IsNotFoundByCriteria') {
						this.authenticationService.logout();
					}
				}
			);
	}

	public openCreditModal(): void {
		this.refreshToken();
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '280px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '390px',
				height: '280px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(NoCreditModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		if (this.shakespeareService.isUserPro$.value) {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		} else {
			dialogRef.componentInstance.modelDetail = {
				title: '',
				text: 'You have maxed out your credits. At a loss for words?',
				actionBtnText: 'Upgrade',
				backRoute: '',
				cta: '/settings/billing',
				closeBtn: true,
				actionBtn: true
			};
		}
		dialogRef.afterClosed().subscribe(obj => {});
	}

	public resetFav(data: any): void {
		data.forEach(element => {
			element.isFav = false;
		});
	}

	public onNoCreditsLeft() {
		this.noCredits = true;
	}

	getBlogTypeResult(type: string, response: any) {
		// this type is blog_type word.toLowerCase() of selected template
		switch (type) {
			case 'blog':
				return response['body']['data'];
			case 'paragraph':
				return response['body']['data']['paragraphs'];
			case 'outline':
				return response['body']['data']['outline'];
			case 'conclusion':
				return response['body']['data']['conclusion'];
			case 'headlines':
				return response['body']['data']['title'];
			case 'ideas':
				return response['body']['data']['blogIdeas'];
			case 'introduction':
				return response['body']['data']['introduction'];
			default:
				break;
		}
	}

	public toObject(array: []) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	public addToFav(idx: number): void {
		if (this.generatedData[idx]['isFav'] === true) {
			this.generatedData[idx]['isFav'] = false;
		} else {
			this.generatedData[idx]['isFav'] = true;
		}
		this.callbackData.emit(this.generatedData);
		this.sortData(false);
	}

	public sortData(emitDataGenerated = true): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.generatedData) {
			if (template?.isNew || template?.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.generatedData = [...sortedList];
		if (emitDataGenerated) {
			this.generateDataComplete.emit(this.generatedData);
		}
	}

	public addToHistory(data: any, name: string): void {
		// delete data.id;
		let feedbackData = {
			user: {
				userFiledId: this.filedId?.toString()
			},
			data: {
				template_data: data,
				template_name: name
			}
		};
		this.userServiceApi
			.addTemplateHistory(feedbackData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
					}
				},
				() => {},
				() => {}
			);
	}

	public addToLiked(id: string, item: any, idx: number): void {
		if (this.generatedData[idx]['isLiked'] === true) {
			this.generatedData[idx]['isLiked'] = false;
		} else {
			this.generatedData[idx]['isLiked'] = true;
			this.generatedData[idx]['isDisliked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'like'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					response => {},
					() => {},
					() => {}
				);
		});
	}

	public addToDisliked(id: string, item: any, idx: number): void {
		if (this.generatedData[idx]['isDisliked'] === true) {
			this.generatedData[idx]['isDisliked'] = false;
		} else {
			this.generatedData[idx]['isDisliked'] = true;
			this.generatedData[idx]['isLiked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'dislike'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public async copyWithStyle(element: string): Promise<void> {
		const text = <HTMLElement>document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);
			selection.removeAllRanges();
			selection.addRange(range);
		}
		document.execCommand('copy');
		this.copyHelper = await navigator.clipboard.readText();
		window.getSelection().removeAllRanges();
	}

	public addToCopied(id: string, item: any, index: number): void {
		this.copyWithStyle(id).then(() => {
			clearTimeout(this.timer);
			this.isCopied = true;
			this.currentCopiedEl = index;
			this.timer = setTimeout(() => {
				this.isCopied = false;
			}, 3000);
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'copy'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	manualHistorySave(generatedData: any): void {
		if (generatedData && generatedData.length) {
			let temp = JSON.parse(JSON.stringify(generatedData));
			let data = temp.map((item, index) => {
				item.projectData = JSON.parse(JSON.stringify(this.formData['data']));
				let output = generatedData[index];
				item.helperId = JSON.parse(JSON.stringify(item.id));
				item.projectData['userOutput'] = output;
				return item;
			});

			this.addToHistory(data, 'big-commerce-product-description');
		}
		this.isDataFetched.emit(false);
	}

	ngOnDestroy(): void {
		if (this.generatedData && this.generatedData.length) {
			let temp = JSON.parse(JSON.stringify(this.generatedData));
			let data = temp.map((item, index) => {
				item.projectData = JSON.parse(JSON.stringify(this.formData['data']));
				let output = this.generatedData[index];
				item.helperId = JSON.parse(JSON.stringify(item.id));
				item.projectData['userOutput'] = output;
				return item;
			});

			this.addToHistory(data, 'big-commerce-product-description');
		}
		this.isDataFetched.emit(false);
	}

	public delete(id: string, item: any) {
		const found = this.generatedData.findIndex(ele => ele.id === item.id);
		if (found >= 0) {
			this.generatedData.splice(found, 1);
			this.callbackData.emit(this.generatedData);
		}
		if (this.generatedData.length === 0) {
			this.isDataFetched.emit(false);
		}
		// });
	}
}
