import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiUrl } from '../base-api-urls';
import { BaseOffersDto, Country, IBillingInfo } from './back-office.models';
import { StorageKey } from 'src/app/_models/local-storage-key';
// import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
// import { SetPassword } from 'src/app/_models/identity-models/set-password';
// import { IChannelRequest, IChannelsInstalled } from 'src/app/user-management/components/integrations/models/integration.models';

@Injectable({
	providedIn: 'root'
})
export class BackOfficeService {
	private readonly baseURL = `${BaseApiUrl.Users}`;
	private readonly baseURLEcommerce = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}`;
	// public curency = new BehaviorSubject<SimpleCurrency[]>(null);
	// public castcurency = this.curency.asObservable();

	public billingUpdatedSuccess$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public signUpGetStartedActive$: BehaviorSubject<string> = new BehaviorSubject('idle');

	constructor(protected http: HttpClient) {}

	// public getSettings(id: number): Observable<UserSetting> {
	// 	return this.http.get<UserSetting>(`${this.baseURL}userSettings/get/${id}`);
	// }

	// public editSettings(request: UserSetting): Observable<void> {
	// 	const body = {
	// 		id: request.id,
	// 		languageName: request.language,
	// 		dataSharing: request.dataSharing,
	// 		notificationDelivery: +request.notificationDelivery,
	// 		notificationFrequency: +request.notificationFrequency
	// 	};

	// 	return this.http.put<void>(`${this.baseURL}userSettings/edit`, body as any);
	// }

	// public getAllCards(): Observable<FiledCreditCard[]> {
	// 	return this.http.get<FiledCreditCard[]>(`${BaseApiUrl.Subscription}paymentcards/my`);
	// }

	public makeCardPrimary(cardId: string): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}paymentCards/${cardId}/default`, {});
	}

	public deletePaymentCard(cardId: string): Observable<void> {
		return this.http.delete<void>(`${BaseApiUrl.Subscription}paymentCards/${cardId}`, {});
	}

	public addNewPaymentCard(cardToken: string, makePrimary: boolean): Observable<boolean> {
		return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards`, { SourceCardToken: cardToken, MakePrimary: makePrimary });
	}

	// public getBillingPlan(): Observable<BillingPlan> {
	// 	return this.http.get<BillingPlan>(`${BaseApiUrl.Subscription}billings/my/billing-plan`);
	// }

	// public getAccountManagers(): Observable<AccountManagersInterface[]> {
	// 	return this.http.get<AccountManagersInterface[]>(`${this.baseURL}backOfficeUsers/accountManagers`);
	// }

	// public getUnmanagedBOs(id: number): Observable<AccountManagersInterface[]> {
	// 	return this.http.get<AccountManagersInterface[]>(`${this.baseURL}backOfficeUsers/accountManagers/${id}/unmanagedBOs`);
	// }

	// public getManagedBOs(id: number): Observable<AccountManagersInterface[]> {
	// 	return this.http.get<AccountManagersInterface[]>(`${this.baseURL}backOfficeUsers/accountManagers/${id}/managedBOs`);
	// }

	// public updateAssignedBOs(id: number, BOs: AssignedBOsInterface): Observable<void> {
	// 	return this.http.put<void>(`${this.baseURL}backOfficeUsers/accountManagers/${id}`, BOs);
	// }

	// public getContactId(contactId: number): Observable<ClientModel> {
	// 	return this.http.get<ClientModel>(`${this.baseURL}contacts?contactId=${contactId}`);
	// }

	// public createOffer(attach: OfferModel): Observable<void> {
	// 	return this.http.post<void>(`${this.baseURL}offers`, attach);
	// }

	// public addCardForCurrentAccount(cardToken: Card): Observable<void> {
	// 	const body = {
	// 		sourceCardToken: cardToken
	// 	};

	// 	return this.http.post<void>(`${this.baseURL}contacts/cardinfo`, body);
	// }

	public getChargeBeeLink(): Observable<string> {
		return this.http.get<string>(`${BaseApiUrl.Subscription}PaymentCards/chargebeePortal`);
	}

	public payNow(): Observable<void> {
		return this.http.get<void>(`${BaseApiUrl.SubscriptionSecond}Account/singin/validatePayment`);
	}

	public confirmPaymentCard(): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}PaymentCards/check/withPW`, {}, { observe: 'response' });
	}

	public confirmFreemiumStatus(userId: string): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}PaymentCards/checkFreeUseExpired`, { userId }, { observe: 'response' });
	}

	public updateBillingAccount(billingReq: IBillingInfo): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}accounts`, billingReq, { observe: 'response' });
	}

	// public addCard(cardToken: Card, id: number): Observable<void> {
	// 	const body = {
	// 		sourceCardToken: cardToken
	// 	};

	// 	return this.http.post<void>(`${this.baseURL}contacts/${id}/cardinfo`, body);
	// }

	public attachOffer(offerId: number): Observable<void> {
		return this.http.put<void>(`${this.baseURL}offers/${offerId}`, {});
	}

	public activate(id: number): Observable<boolean> {
		return this.http.post<boolean>(`${this.baseURL}accounts`, { ContactId: id });
	}

	public deactivate(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}contacts/${id}/accounts`);
	}

	public deleteCard(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}contacts/card/${id}`);
	}

	public changePaymentMethodForContact(contactId: number, paymentMethodId: number): Observable<void> {
		return this.http.put<void>(`${this.baseURL}contacts/all/${contactId}/paymentMethod`, {
			clientPaymentMethod: paymentMethodId
		});
	}

	public updateBaseOfferLandingPage(offerId: number, status: boolean): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}baseOffers/${offerId}/landingPageSubscriptionAllowed`, {
			IsLandingPageSubscriptionAllowed: status
		});
	}

	public updateFreemiumOfferLandingPage(offerId: number, status: boolean): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}freemiumOffers/${offerId}/landingPageSubscriptionAllowed`, {
			IsLandingPageSubscriptionAllowed: status
		});
	}

	public editBaseOffer(baseOfferId: number, baseOffer: BaseOffersDto): Observable<boolean> {
		return this.http.put<boolean>(`${this.baseURL}baseOffers/${baseOfferId}`, baseOffer);
	}

	// public addFirstTimeCard(firstTimeCardDto: PaymentMethod): Observable<boolean> {
	// 	return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards/withPW`, firstTimeCardDto);
	// }

	// public addCardFreeExpired(firstTimeCardDto: PaymentMethod): Observable<boolean> {
	// 	return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards/freeUseExpired`, firstTimeCardDto);
	// }

	// public addCardNoPw(firstTimeCardDto: PaymentMethod): Observable<boolean> {
	// 	return this.http.post<boolean>(`${BaseApiUrl.Subscription}paymentCards/noPW`, firstTimeCardDto);
	// }

	// public getBusinessOwnerCards(): Observable<FiledCreditCard[]> {
	// 	return this.http.get<FiledCreditCard[]>(`${this.baseURL}paymentcards/my`);
	// }

	public getAllCountriesList(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	// public getBillingAccountDetails(): Observable<BillingAddress> {
	// 	return this.http.get<BillingAddress>(`${BaseApiUrl.Subscription}accounts`);
	// }

	// public getInvoiceHistory(): Observable<InvoiceHistory[]> {
	// 	return this.http.get<InvoiceHistory[]>(`${BaseApiUrl.Subscription}billings/my/invoices`);
	// }

	// public getMyInvoiceHistory(): Observable<InvoiceHistory[]> {
	// 	return this.http.get<InvoiceHistory[]>(`${BaseApiUrl.Subscription}Billings/my/billingInvoices`);
	// }

	// public getMyInvoiceByTransactionId(transactionId: string): Observable<InvoiceHistory[]> {
	// 	return this.http.get<InvoiceHistory[]>(`${BaseApiUrl.Subscription}Billings/${transactionId}/transaction`);
	// }

	// public getMyInvoiceByBillingId(billingId: string): Observable<InvoiceHistory[]> {
	// 	return this.http.get<InvoiceHistory[]>(`${BaseApiUrl.Subscription}Billings/${billingId}`);
	// }

	// public payInvoice(chargebeeInvoiceId: string): Observable<InvoiceHistory[]> {
	// 	return this.http.put<InvoiceHistory[]>(`${BaseApiUrl.Subscription}billings/my/invoices/pay-invoice`, {
	// 		ChargebeeInvoiceId: chargebeeInvoiceId
	// 	});
	// }

	public getAllCountries(): Observable<Country[]> {
		return this.http.get<Country[]>(`${BaseApiUrl.Subscription}countries`);
	}

	public downloadInvoiceHistoryPdf(chargebeeInvoiceId: string, inlineDisplay: string) {
		return this.http.get(`${BaseApiUrl.Subscription}billings/my/invoices/pdf`, {
			params: {
				chargebeeInvoiceId: chargebeeInvoiceId,
				inlineDisplay: inlineDisplay
			}
		});
	}

	public getUserDetails(): Observable<any> {
		return this.http.get<void>(`${BaseApiUrl.Subscription}Account/user/detail`);
	}

	public updateUserDetails(payload: object): Observable<any> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}Account/user/detail`, payload);
	}

	public updateProfileDetails(payload: object): Observable<any> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}Account/user/s/detail`, payload);
	}

	public sendPasswordLink(userEmail: string): Observable<any> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}Account/send-password-link`, { userEmail }, { observe: 'response' });
	}

	public getSubUsers(): Observable<any> {
		return this.http.get<number>(`${BaseApiUrl.Subscription}Account/all/subUsers`);
	}

	public getIntegrations(): Observable<any> {
		return this.http.get<number>(`${BaseApiUrl.Subscription}Account/integrations`);
	}

	public getNotificationIntegrations(): Observable<any> {
		return this.http.get<number>(`${BaseApiUrl.Subscription}Account/notification/integrations`);
	}

	public addSubUser(payload: object): Observable<any> {
		return this.http.post<void>(`${BaseApiUrl.Subscription}Account/subUser`, payload);
	}

	public editSubUser(payload: object): Observable<any> {
		return this.http.put<number>(`${BaseApiUrl.Subscription}Account/subUser`, payload);
	}

	public deleteSubUser(id: number): Observable<any> {
		return this.http.delete<number>(`${BaseApiUrl.Subscription}Account/subUser/${id}`);
	}

	public getCurrentBilling(): Observable<any> {
		return this.http.get<number>(`${BaseApiUrl.Subscription}Billing/current`);
	}

	public getAllBillings(): Observable<any> {
		return this.http.get<number>(`${BaseApiUrl.Subscription}Billing/all`);
	}

	public updateUserPassword(payload: object): Observable<any> {
		return this.http.put<number>(`${BaseApiUrl.Subscription}Account/user/password`, payload, { observe: 'response' });
	}

	public forgotPassword(payload: object): Observable<any> {
		return this.http.put<number>(`${BaseApiUrl.Subscription}Account/forgot-password`, payload);
	}

	public getUserState(userId: number): Observable<number> {
		return this.http.get<number>(`${BaseApiUrl.Users}Users/${userId}/user-account-status`);
	}

	public subscribeToOfer(details: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}Account/signUp`, details);
	}

	public subscribeTosecondOfer(details: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SubscriptionSecond}Account/signUp`, details);
	}

	public validateEmail(emailid: string): Observable<boolean> {
		return this.http.get<boolean>(`${BaseApiUrl.Subscription}Account/user-email-validate?email=${emailid}`);
	}

	public resendActivationEmail(email: string): Observable<void> {
		return this.http.put<void>(`${this.baseURL}users/resend-first-password`, { userEmail: email });
	}

	public setPassword(setPassword: any): Observable<object> {
		return this.http.put(`${BaseApiUrl.Subscription}Account/set-first-password`, setPassword);
	}

	public checkIfSubUser(): Observable<boolean> {
		return this.http.get<boolean>(`${BaseApiUrl.Users}Users/check-sub-user`);
	}

	public addNewCard(token: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}Billing/user/addCard/token/${token}`, {});
	}

	public getCardDetails(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Billing/user/card`);
	}

	public setDefaultCard(id: string): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Billing/user/defaultCard/${id}`, {});
	}

	public getPaymentHistory(): Observable<string> {
		return this.http.get<string>(`${BaseApiUrl.Subscription}Billing/hostedLink`);
	}

	public deleteUserCard(cardId: string): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.Subscription}Billing/user/card/id/${cardId}`);
	}

	public userPlanDetails(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Subscription/current`);
	}

	public cancelUserAccount(reason: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/cancel`, reason);
	}

	public pauseUserPlan(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/pause/month/1`, {});
	}

	public resumeUserPlan(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/resume`, {});
	}

	public updateShopifyPlan(priceId: string): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}shopify/SBilling/change/plan/price/${priceId}`, {});
	}

	public deleteUserAccount(): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.Subscription}Account`);
	}

	public getAllOffers(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Subscription/limit/30`);
	}

	public getShopifyFree(): Observable<string> {
		return this.http.put<string>(`${BaseApiUrl.Subscription}shopify/SBilling/create/free`, {});
	}

	public checkPayment(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}shopify/SBilling/check/pendingPayment`);
	}

	public getVersion(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Subscription/version`);
	}

	public buyPlan(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/buyNew`, payload);
	}

	public upgradePlan(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/update`, payload);
	}

	public endTrial(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/endNow`, {});
	}

	public buyShopifyPlan(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}shopify/SBilling/payment/link`, payload);
	}

	public chargeBillingId(chargeId: number, isUpgrade = false): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}shopify/SBilling/charge/${chargeId}/detail?upgrade=${isUpgrade}`);
	}

	public validateCharge(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}shopify/SBilling/validate/charge`);
	}

	public updateSubscription(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/update`, payload);
	}

	public renewSubscription(payload: object): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/renew`, payload);
	}

	public endFreeTrial(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/endNow`, {});
	}

	public upcomingInvoice(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Billing/upcomming/invoice`);
	}

	public getBillStatus(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Billing/current/pending`);
	}

	public getProrata(currentPriceId: string, newPriceId: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Subscription/previewProration/current/${currentPriceId}/new/${newPriceId}`);
	}

	public refreshUserToken(): Observable<string> {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token.platform_name == 'shopify') {
			return this.http.get<string>(`${BaseApiUrl.Subscription}Account/refresh-token?platform=shopify`);
		}
		return this.http.get<string>(`${BaseApiUrl.Subscription}Account/refresh-token`);
	}

	public applyDiscount(): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Subscription/applyDiscount`, {});
	}

	public getBackOfficeUsers(start = 1, end = 1000): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}BackOffice/User/fetchAllData/start/${start}/end/${end}`);
	}

	public verifyShopify(hmac: string, host: string, shop: string, timestamp: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}shopify/VerifyApp?hmac=${hmac}&host=${host}&shop=${shop}&timestamp=${timestamp}`);
	}

	public shopifyAuth(code: string, shop: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}shopify/VerifyApp/auth?code=${code}&shop=${shop}`);
	}

	public getOtp(payload: object): Observable<any> {
		return this.http.post<any>('https://eygn5cgeu3.execute-api.us-east-2.amazonaws.com/dev/api/v1/2fa/generateBubbles', payload);
	}

	public verifyOtp(payload: object): Observable<any> {
		return this.http.post<any>('https://eygn5cgeu3.execute-api.us-east-2.amazonaws.com/dev/api/v1/verify/verify-bubbles', payload);
	}

	public sendCodeTemp(code: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}GoogleAccount/accessToken?code=${code}`);
	}

	public defaultSelectedPlatform(platformName: string): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Account/defaultSelectedPlatform/${platformName}`, platformName);
	}

	public getDefaultSelectedPlatform(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Account/defaultSelectedPlatform`);
	}

	// !notifications
	public getIntegrationNotifications(area: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Notification/notificationArea/${area}`);
	}

	public changeIntegrationNotificationStatus(area: string, platform: string, status: boolean): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Subscription}Notification/notificationArea/${area}/platform/${platform}/status/${status}`, {});
	}
	// !end
}
