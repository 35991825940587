<div class="container_main">
	<div class="nav">
		<img class="for-desktop" src="../../../assets/logo/shakespeare-full-logo.svg" />
		<img class="for-mobile" src="../../../assets/logo/shakespeare-icon.svg" />
		<p (click)="signOut()">
			<img src="assets/icons/signout.svg" alt="" />
			Sign out
		</p>
	</div>
	<div class="header">
		<p class="rating">
			<img *ngFor="let x of [1, 1, 1, 1, 1]" src="assets/icons/icon-star.svg" alt="" />
			4.8 out of 5 stars in 1,000+ reviews
		</p>
		<!-- <p class="title" *ngIf="!isShopifyUser">
            <span>Try it free</span>
            for 5 days
        </p>
        <p class="sub-title2">{{ src ? 'Receive 90% off your first month at checkout' : 'A plan for everyone' }}</p>
        <app-button class="shopify-btn" *ngIf="isShopifyUser" (onClick)="getShopifyFree()">Start for free</app-button> -->
	</div>
	<div class="pay-now">
		<app-button class="shopify-btn" (onClick)="payNow()">Pay Now</app-button>
	</div>

	<!--<div class="switch-con" *ngIf="!isShopifyUser">
        <div class="custom-switch">
            <label class="{{ planDuration === planDurationEnum.Monthly ? 'active' : '' }}">Monthly</label>
            <app-toggle-switch [value]="planDuration !== planDurationEnum.Monthly"
                (toggle)="onCheckToggleView($event)"></app-toggle-switch>
            <label class="{{ planDuration === planDurationEnum.Annual ? 'active' : '' }}">Annually</label>
        </div>
        <p [class.annual]="planDuration === planDurationEnum.Annual" class="save">Save 20% with annual plans</p>
    </div>
    <div class="offer-con">
        <div class="offer-box" *ngIf="true" [class.loader-css]="loading" (click)="changePlan(planEnum.standard)"
            [class.selected]="activePlan == planEnum.standard">
            <div class="spinner-wrapper" *ngIf="loading">
                <shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
            </div>
            <div *ngIf="standardPlans && !loading">
                <div class="standard">
                    <p class="plan-name">Standard</p>
                    <p class="offer" *ngIf="offerOn">{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25%
                        OFF' }}</p>
                </div>
                <p class="price" *ngIf="!offerOn" [class.offer-on]="offerOn">
                    ${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price
                    / 12) }}
                    <span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month'
                        }}</span>
                </p>
                <p class="price" *ngIf="offerOn" [class.offer-on]="offerOn">
                    ${{
                    planDuration === planDurationEnum.Monthly
                    ? Math.round(selectedPlan?.price / 2)
                    : Math.round((selectedPlan?.price * (100 - 25)) / 100 / 12)
                    }}
                    <span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month'
                        }}</span>
                </p>
                <p class="striked" *ngIf="offerOn">
                    <del>${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price :
                        Math.round(selectedPlan?.price / 12) }}</del>
                </p>
                <input type="range" id="slider" [style.background]="defaultStyle" (input)="changeBackground($event)"
                    [min]="0" [max]="standardPlans.length - 1" [step]="1" [value]="standardStep"
                    (change)="onSlide($event)" />
                <div class="slider-label">
                    <p>{{ kFormatter(standardPlans[0]?.credit) || '' }}</p>
                    <p>{{ kFormatter(standardPlans[standardPlans.length - 1]?.credit) || '' }}+</p>
                </div>
                <p class="slider-value">{{ sliderValue || '0K' }} Words</p>
                <app-button width="100" style="width: 100%;" (onClick)="submit('standard')"
                    [buttonClass]="activePlan == planEnum.standard ? 'primary' : 'secondary'">
                    {{ isShopifyUser ? 'START NOW' : 'START FREE TRIAL' }}
                </app-button>
                <p class="desc">All the same goodies as our Free Trial, plus….</p>
                <ul class="item-lists">
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        40+ Copywriting Templates
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Target User Personas
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Add More Words Anytime
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Write in 100+ Languages
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Team Functionality
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Priority Support
                    </li>
                </ul>
            </div>
        </div>
        <div class="offer-box" [class.loader-css]="loading" (click)="changePlan(planEnum.pro)"
            [class.selected]="activePlan == planEnum.pro">
            <div class="spinner-wrapper" *ngIf="loading">
                <shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
            </div>
            <div *ngIf="proPlans && !loading">
                <div class="pro">
                    <p class="plan-name">Pro</p>
                    <p class="popular">Most Popular</p>
                    <p class="offer" *ngIf="offerOn">{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25%
                        OFF' }}</p>
                </div>
                <p class="price" *ngIf="!offerOn" [class.offer-on]="offerOn">
                    ${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price :
                    Math.round(selectedProPlan?.price / 12) }}
                    <span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month'
                        }}</span>
                </p>
                <p class="price" *ngIf="offerOn" [class.offer-on]="offerOn">
                    ${{
                    planDuration === planDurationEnum.Monthly
                    ? Math.round(selectedProPlan?.price / 2)
                    : Math.round((selectedProPlan?.price * (100 - 25)) / 100 / 12)
                    }}
                    <span class="duration">{{ planDuration === planDurationEnum.Monthly ? 'per month' : 'per month'
                        }}</span>
                </p>
                <p class="striked" *ngIf="offerOn">
                    <del>${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price :
                        Math.round(selectedProPlan?.price / 12) }}</del>
                </p>
                <input type="range" id="sliderPro" [style.background]="defaultProStyle"
                    (input)="changeProBackground($event)" [min]="0" [max]="proPlans.length - 1" [step]="1"
                    [value]="proStep" (change)="onSlidePro($event)" />
                <div class="slider-label">
                    <p>{{ kFormatter(proPlans[0]?.credit) || '' }}</p>
                    <p>{{ kFormatter(proPlans[proPlans.length - 1]?.credit) || '' }}+</p>
                </div>
                <p class="slider-value">{{ sliderProValue || '0K' }} Words</p>
                <app-button width="100" style="width: 100%;" (onClick)="submit('pro')"
                    [buttonClass]="activePlan == planEnum.pro ? 'primary' : 'secondary'">
                    {{ isShopifyUser ? 'START NOW' : 'START FREE TRIAL' }}
                </app-button>
                <p class="desc">All the same goodies as our Free Trial, plus….</p>
                <ul class="item-lists">
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Access to Shakespeare's Blog Wizard
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        AI Predictive Text
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Expanded Content Creation Tools
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Up to 800K Words Per Month
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Onboarding & Training Sessions
                    </li>
                    <li>
                        <em class="k-icon k-i-check k-i-checkmark"></em>
                        Premium Technical Support
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
	<!-- <div class="info">
		<p>We will run an authorization to verify your card - this charge will be dropped after 2-3 business days</p>
	</div> -->
	<div class="faq">
		<p class="title">
			Frequently
			<br />
			asked questions
		</p>
		<div class="box-con">
			<mat-accordion>
				<!-- <mat-expansion-panel [@.disabled]="true" hideToggle #drp1>
                    <mat-expansion-panel-header [class.opened]="drp1.expanded">
                        <mat-panel-title>
                            What if my credits run out?
                        </mat-panel-title>
                        <em *ngIf="!drp1.expanded" class="k-icon k-i-plus"></em>
                        <em *ngIf="drp1.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
                    </mat-expansion-panel-header>
                    <p>
                        Have no fear, upgrades are here! Just login to your account to get more credits in just a few
                        clicks and keep the Shakespeare magic
                        going.
                    </p>
                </mat-expansion-panel> -->
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp2>
					<mat-expansion-panel-header [class.opened]="drp2.expanded">
						<mat-panel-title>
							What languages can Shakespeare write in?
						</mat-panel-title>
						<em *ngIf="!drp2.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp2.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Shakespeare currently creates world-class copy in over 100+ of the world's most-used languages, like English, Spanish, French, German,
						and others, but we're working on adding more every day!
					</p>
				</mat-expansion-panel>
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp3>
					<mat-expansion-panel-header [class.opened]="drp3.expanded">
						<mat-panel-title>
							What if I want to upgrade?
						</mat-panel-title>
						<em *ngIf="!drp3.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp3.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>Simple! Take a look to see all our plan options and find the features that are best for you.</p>
				</mat-expansion-panel>
				<!-- <mat-expansion-panel [@.disabled]="true" hideToggle #drp4>
                    <mat-expansion-panel-header [class.opened]="drp4.expanded">
                        <mat-panel-title>
                            How much copy can Shakespeare write each month?
                        </mat-panel-title>
                        <em *ngIf="!drp4.expanded" class="k-icon k-i-plus"></em>
                        <em *ngIf="drp4.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
                    </mat-expansion-panel-header>
                    <p>
                        It's all based around your unique copywriting needs! Your monthly word allowance depends on your
                        subscription level, starting from 25K
                        words for the Standard version.
                    </p>
                </mat-expansion-panel> -->
				<mat-expansion-panel [@.disabled]="true" hideToggle #drp5>
					<mat-expansion-panel-header [class.opened]="drp5.expanded">
						<mat-panel-title>
							Can I invite members of my team to use Shakespeare?
						</mat-panel-title>
						<em *ngIf="!drp5.expanded" class="k-icon k-i-plus"></em>
						<em *ngIf="drp5.expanded" class="k-icon k-i-minus k-i-kpi-trend-equalk-i-plus"></em>
					</mat-expansion-panel-header>
					<p>
						Of course! The more the merrier. Shakespeare's paid subscriptions allow you to onboard up to 5 members of your team, granting them
						access to all Shakespeare has to offer.
					</p>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</div>
<div *ngIf="globalLoader" class="global-spinner">
	<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
</div>
