<div class="cancel-container">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title">Are you sure you want to cancel?</p>
		<div class="box">
			<p class="title">
				We'd hate to see you lose access to all of Shakespeare's awesome features
			</p>
			<p class="clear-btn" (click)="navigateToBilling()">Change Plan Instead</p>
		</div>
		<p class="sub-title">
			FYI, your account will remain on your current plan until the next billing cycle. If you change your mind between now and then, we'll be here!
		</p>
	</div>
	<div class="footer">
		<button class="mind" (click)="closeModal()">Changed My Mind</button>
		<button class="no" (click)="proceedToCancel()">Confirm Cancellation</button>
	</div>
</div>
