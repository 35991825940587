<!-- notification input type date card -->
<div class="input-wrapper">
	<div class="input-date-wrapper">
		<form [formGroup]="myForm">
			<div class="section-date">
				<app-input-number
					[control]="monthFormControl"
					autocomplete="off"
					placeholder="02"
					[maxNumber]="2"
					label="MM"
					[decimals]="0"
					[showErrors]="false"
				></app-input-number>
				<p>/</p>
				<app-input-number
					[control]="yearFormControl"
					autocomplete="off"
					placeholder="20"
					[maxNumber]="2"
					label="YY"
					[decimals]="0"
					[showErrors]="false"
				></app-input-number>
			</div>
			<!--Error message -->
			<span class="error-message" *ngIf="control.invalid && control.touched">All fields required</span>
			<span class="error-message" *ngIf="invalidMonth">Invalid month. Please try again</span>
			<span class="error-message" *ngIf="invalidYear">Invalid year. Please try again</span>
		</form>
	</div>
</div>
