import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
	selector: 'app-custom-modal',
	templateUrl: './custom-modal.component.html',
	styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<CustomModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {}

	public closeModal(): void {
		this.dialogRef.close();
	}
}
