import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-cancel-step1',
	templateUrl: './cancel-step1.component.html',
	styleUrls: ['./cancel-step1.component.scss']
})
export class CancelStep1Component implements OnInit {
	constructor(private dialogRef: MatDialogRef<CancelStep1Component>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {}

	public closeModal(): void {
		this.dialogRef.close();
	}

	public pausePlan(): void {
		this.dialogRef.close({ type: 'pause' });
	}

	public proceedToCancel(): void {
		this.dialogRef.close({ cancel: true });
	}
}
