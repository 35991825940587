import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AdsService } from '../ads/ads.service';
import { ShakespeareService } from '../shakespeare.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { CustomModalComponent } from '../settings/modals/custom-modal/custom-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleConnectModelComponent } from '../settings/modals/google-connect-model/google-connect-model.component';
import { KonvaService } from '../ads/creative-studio/project/image-editor/services/konva.service';

@Component({
	selector: 'app-google-global-auth',
	templateUrl: './google-global-auth.component.html',
	styleUrls: ['./google-global-auth.component.scss']
})
export class GoogleGlobalAuthComponent implements OnInit {
	googleRedirectType: string;
	loading = true;

	constructor(
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private toastNotificationService: ToastNotificationService,
		private router: Router,
		private adsService: AdsService,
		private konvaService: KonvaService
	) {
		// this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
		// this.konvaService.hideSideMenu.next(true);
	}

	ngOnInit(): void {
		this.googleRedirectType = this.getCookie('googleRedirectType');
		if (this.googleRedirectType === '' || this.googleRedirectType === undefined || this.googleRedirectType === null) {
			this.googleRedirectType = 'AiIntelligence';
		}
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['code']) {
				this.saveCookie('googleCode', params['code']);
				this.sendToken(params['code']);
			} else if (params['error'] == 'access_denied') {
				// this.router.navigate(['ads/google/auth']);
				localStorage.setItem('googleConnected', 'false');
			}
		});
	}

	sendToken(token: string): void {
		let decodedJwtIo = JSON.parse(localStorage.getItem('decodedJwtIo'));
		this.adsService.sendCodeTemp(token, decodedJwtIo?.filed_email).subscribe({
			next: () => {
				this.adsService.validateAccessToken().subscribe({
					next: (res: any) => {
						if (!res) {
							localStorage.setItem('googleConnected', 'false');
							// this.router.navigate(['ads/google/auth']);
							// this.openCancelModaldummy();
							// this.toastNotificationService.sendErrorToast('Gmail account is already used in Shakespeare');
						}
						if (res) {
							localStorage.setItem('googleConnected', 'true');
							// this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
						}
					}
				});
				this.loading = false;
			},
			error: () => {
				localStorage.setItem('googleConnected', 'false');
				// this.toastNotificationService.sendErrorToast('Gmail account is already used in Shakespeare');
				this.loading = false;
				// this.router.navigate(['ads/google/auth']);
			}
		});
	}

	public openCancelModaldummy(): void {
		let modalConfig;

		modalConfig = {
			width: '503px',
			height: '180px',
			hasBackdrop: true
		};
		const dialogRef = this.dialog.open(GoogleConnectModelComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				existing: true
			}
		});
	}

	goToGoogleAuth(): void {
		this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
	}

	goToCreative(): void {
		// sending localstorage token to devcreative.shakespeare.ai with redirect to /login
		let domain = environment.CREATIVE_URL;
		let token = localStorage.getItem('token');
		if (token && domain) {
			let url = `https://${domain}/authentication/auth?token=${token}`;
			window.open(url, '_blank');
			this.router.navigate(['']);
		}
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// Set it expire in 1 year
		date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}
}
