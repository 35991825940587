<form [formGroup]="generatorForm" [ngClass]="{ expanded: menuExpanded }">
	<app-text-input-with-label
		inputLabel="Product/Brand Name"
		[control]="generatorForm.controls['name']"
		[maxLength]="80"
		[placeholder]="'enter product name'"
		[helperText]="'Product/Brand Name'"
	></app-text-input-with-label>
	<app-custom-textarea-with-label
		[control]="generatorForm.controls['description']"
		[maxLength]="400"
		placeholder="Type your description here and Shakespeare will work its magic...."
		inputLabel="Product Description"
	></app-custom-textarea-with-label>
	<div class="d-part input">
		<div class="input-box">
			<div class="d-flex">
				<div class="label dropdown">
					<label>Output Tone</label>
					<app-tooltip text="You can type your own Tone"></app-tooltip>
				</div>
			</div>
			<app-custom-drpdown-op1
				[data]="outputTone"
				(selectionChange)="changeToneSource.emit($event)"
				[useCustom]="true"
				[dropdownFormControl]="(generatorForm?.controls)['outputTone']"
				[placeHolder]="'Select/Enter new'"
				[searchEnable]="true"
				[noError]="true"
			></app-custom-drpdown-op1>
		</div>
	</div>
	<div class="input toggle">
		<div class="d-flex">
			<div class="label">
				<label>Advance Option</label>
				<app-tooltip [noPlaceBottom]="true" text="Use persona to generate more personalize adcopy"></app-tooltip>
			</div>
		</div>
		<app-switch [value]="advanceOptions" (toggle)="checkToggleValue($event)"></app-switch>
	</div>
	<div *ngIf="advanceOptions" class="advance">
		<div class="parsona input">
			<div class="d-flex align-items-center" style="margin-bottom: 12px; line-height: 19px;">
				<label style="font-size: 16px; color: #121212;">Persona</label>
				<app-tooltip text="Select your target persona to generate tailored ad copy"></app-tooltip>
			</div>
			<div class="box">
				<div class="gender-input input">
					<div class="label">Gender</div>
					<mat-radio-group class="afc-radio-group">
						<mat-radio-button class="afc-radio-button" [value]="'male'" [checked]="true" (click)="onRadioButtonClick('male')">
							<span>Male</span>
						</mat-radio-button>
						<mat-radio-button class="afc-radio-button" [value]="'female'" [checked]="false" (click)="onRadioButtonClick('female')">
							<span>Female</span>
						</mat-radio-button>
						<mat-radio-button class="afc-radio-button" [value]="'nomatter'" [checked]="false" (click)="onRadioButtonClick('nomatter')">
							<span>Doesn't matter</span>
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="age-input">
					<div class="label">Age Group</div>
					<app-custom-drpdown-op1
						[data]="ageArray"
						(selectionChange)="changeAgeSource.emit($event)"
						[dropdownFormControl]="generatorForm?.get('age')"
						[useCustom]="false"
						[defaultSelectedValue]="{ name: 'All', value: 0 }"
						class="age-custom-drp"
						[hideClearButton]="true"
						[placeHolder]="'Select Age'"
					></app-custom-drpdown-op1>
				</div>
			</div>
			<div class="input-lang">
				<div class="label dropdown">
					<label>Output Language (Optional)</label>
				</div>
				<app-custom-drpdown-op1
					[data]="outputLang"
					(selectionChange)="changeLangSource.emit($event)"
					[dropdownFormControl]="generatorForm?.get('outputLang')"
					[useCustom]="false"
					[searchEnable]="true"
					[placeHolder]="'Select Language'"
				></app-custom-drpdown-op1>
			</div>
		</div>
	</div>
</form>
