<section class="overview-container">
	<header>
		<label for="" *ngIf="!viewAllGoogle && !viewAllFacebook" class="d-flex align-items-center">
			<span>
				Account Overview
			</span>
			<app-tooltip
				[noPlaceRight]="true"
				link="Learn more"
				[imageEditorWidth]="'10px'"
				text="Get a quick snapshot of all your Google and Facebook campaigns' performance."
			></app-tooltip>
		</label>

		<div class="left" (click)="goBack()" *ngIf="viewAllGoogle || viewAllFacebook">
			<img src="assets/icons/Icon ionic-ios-arrow-back.svg" alt="" srcset="" />
			<span *ngIf="viewAllGoogle">Google Ad Accounts</span>
			<span *ngIf="viewAllFacebook">Facebook Ad Accounts</span>
		</div>

		<app-primary-calender
			(sendStartDate)="initialiseStartDate($event)"
			(sendEndDate)="initialiseEndDate($event)"
			[defaultStartDate]="calendarStartDate"
			[defaultEndDate]="calendarEndDate"
		></app-primary-calender>
	</header>

	<section class="cards-overview">
		<!-- !google -->
		<ng-container *ngIf="!viewAllFacebook">
			<app-cards-overview
				*ngIf="overallDataList && !showLoader"
				[overallData]="viewAllGoogle ? overallDataList : overallDataList.slice(0, 3)"
				[otherCardData]="otherData"
				[brandLogo]="'google'"
				[fromDate]="fromDate"
				[toDate]="toDate"
				[optimizeLoading]="optimizeLoader"
				[creativeLoading]="creativeLoader"
			></app-cards-overview>
			<div *ngIf="overallDataList?.length && !showLoader && !viewAllGoogle" class="google-viewall" (click)="onViewAll('google')">View All</div>
		</ng-container>

		<!-- !facebook -->
		<ng-container *ngIf="!viewAllGoogle">
			<app-cards-overview
				*ngIf="overviewFacebookDataList && !showLoader"
				[overallData]="viewAllFacebook ? overviewFacebookDataList : overviewFacebookDataList.slice(0, 3)"
				[otherCardData]="otherData"
				[brandLogo]="'facebook'"
				[fromDate]="fromDate"
				[toDate]="toDate"
				[optimizeLoading]="optimizeFbLoader"
				[creativeLoading]="creativeFbLoader"
			></app-cards-overview>
			<div *ngIf="overviewFacebookDataList?.length && !showLoader && !viewAllFacebook" class="facebook-viewall" (click)="onViewAll('facebook')">
				View All
			</div>
		</ng-container>

		<!-- !loader -->
		<ngx-skeleton-loader
			*ngIf="showLoader"
			count="6"
			appearance="line"
			style="height: 105px;"
			[theme]="{ width: '100%', height: '105px' }"
		></ngx-skeleton-loader>
	</section>
</section>
