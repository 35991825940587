<div class="video-player" *ngIf="!hasHistory && generatedData.length <= 0 && !isLoadingData; else notFirstTime">
	<iframe id="video" [src]="videoLinkId" frameborder="0" allow="encrypted-media; picture-in-picture" allowfullscreen></iframe>
</div>
<ng-template #notFirstTime>
	<div *ngIf="isLoadingData" class="before-loading">
		<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
		<div class="text">
			<p class="bold">Please wait...</p>
			<p class="dynamic-test">{{ dynamicText }}</p>
		</div>
	</div>
	<div *ngIf="!noCredits; else noCreditTemplate" [class.none]="isLoadingData">
		<div class="output" *ngIf="category == 'ad-copy'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'adcopy' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content" *ngIf="item?.title">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('adcopy' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.title" [ngClass]="item?.description ? 'boldTitle' : ''"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('adcopy' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('adcopy' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('adcopy' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content desc" *ngIf="item?.description">
					<div class="text-left">
						<div class="actions" *ngIf="!item?.title">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('adcopy' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.description"></div>
					</div>
					<div class="other-actions" *ngIf="!item?.title">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('adcopy' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('adcopy' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('adcopy' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content" *ngIf="item?.cta">
					<div class="text" [innerHTML]="item?.cta"></div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'email'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'email' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item?.subject">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('email' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text boldTitle" [innerHTML]="item?.subject"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('email' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('email' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('email' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content">
					<div class="text-left">
						<div class="actions" *ngIf="!item?.subject">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('email' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.email"></div>
					</div>
					<div class="other-actions" *ngIf="!item?.subject">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('email' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('email' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('email' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'product-description'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'desc' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item?.mainDescription">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('desc' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.mainDescription"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('desc' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('desc' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('desc' + item?.id, item)"></span>
					</div>
				</div>
				<div *ngFor="let subItem of item.uspDescription">
					<div class="content-2" *ngIf="subItem?.description">
						<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
						<span class="text" [innerHTML]="subItem?.description"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'blog' || category == 'essay'">
			<div *ngIf="!fullBlogCase" class="case-default">
				<div *ngFor="let item of generatedData; let i = index" [id]="'blog' + item?.id" class="group" [class.new]="item?.isNew">
					<div class="content desc" *ngIf="item?.para">
						<div class="text-left">
							<div class="actions">
								<span
									(click)="addToFav(i, $event)"
									matTooltip="Add To Favorite"
									[matTooltipPosition]="'above'"
									[ngClass]="item?.isFav ? 'fav' : 'heart'"
								></span>
								<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog' + item?.id, item, i)">
									<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
								</span>
							</div>
							<div class="text" [innerHTML]="item?.para"></div>
						</div>
						<div class="other-actions">
							<span
								[ngClass]="item?.isLiked ? 'liked' : 'like'"
								matTooltip="Like"
								[matTooltipPosition]="'above'"
								(click)="addToLiked('blog' + item?.id, item, i)"
							></span>
							<span
								[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
								matTooltip="Dislike"
								[matTooltipPosition]="'above'"
								(click)="addToDisliked('blog' + item?.id, item, i)"
							></span>
							<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog' + item?.id, item)"></span>
						</div>
					</div>
					<div class="content desc" *ngIf="item.text">
						<div class="text-left">
							<div class="actions">
								<span
									(click)="addToFav(i, $event)"
									matTooltip="Add To Favorite"
									[matTooltipPosition]="'above'"
									[ngClass]="item?.isFav ? 'fav' : 'heart'"
								></span>
								<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog' + item?.id, item, i)">
									<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
								</span>
							</div>
							<div class="text" [innerHTML]="item.text"></div>
						</div>
						<div class="other-actions">
							<span
								[ngClass]="item?.isLiked ? 'liked' : 'like'"
								matTooltip="Like"
								[matTooltipPosition]="'above'"
								(click)="addToLiked('blog' + item?.id, item, i)"
							></span>
							<span
								[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
								matTooltip="Dislike"
								[matTooltipPosition]="'above'"
								(click)="addToDisliked('blog' + item?.id, item, i)"
							></span>
							<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog' + item?.id, item)"></span>
						</div>
					</div>
					<div class="content desc" *ngIf="item.title">
						<div class="text-left">
							<div class="actions">
								<span
									(click)="addToFav(i, $event)"
									matTooltip="Add To Favorite"
									[matTooltipPosition]="'above'"
									[ngClass]="item?.isFav ? 'fav' : 'heart'"
								></span>
								<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog' + item?.id, item, i)">
									<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
								</span>
							</div>
							<div class="text" [innerHTML]="item.title"></div>
						</div>
						<div class="other-actions">
							<span
								[ngClass]="item?.isLiked ? 'liked' : 'like'"
								matTooltip="Like"
								[matTooltipPosition]="'above'"
								(click)="addToLiked('blog' + item?.id, item, i)"
							></span>
							<span
								[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
								matTooltip="Dislike"
								[matTooltipPosition]="'above'"
								(click)="addToDisliked('blog' + item?.id, item, i)"
							></span>
							<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog' + item?.id, item)"></span>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="fullBlogCase" class="full-blog">
				<!-- title, intro, paragraph(title desc) conclusion -->
				<div class="" [class.new]="item?.isNew">
					<div *ngFor="let item of generatedData; let i = index" class="group full-blog-data" [id]="'blog' + item.id">
						<div class="content desc blog-title" *ngIf="item.title">
							<div class="text-left">
								<div class="actions">
									<span
										(click)="addToFav(i, $event)"
										matTooltip="Add To Favorite"
										[matTooltipPosition]="'above'"
										[ngClass]="item?.isFav ? 'fav' : 'heart'"
									></span>
									<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('blog' + item?.id, item, i)">
										<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
									</span>
								</div>
								<div class="text boldTitle" [innerHTML]="item.title"></div>
							</div>
							<div class="other-actions">
								<span
									[ngClass]="item?.isLiked ? 'liked' : 'like'"
									matTooltip="Like"
									[matTooltipPosition]="'above'"
									(click)="addToLiked('blog' + item?.id, item, i)"
								></span>
								<span
									[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
									matTooltip="Dislike"
									[matTooltipPosition]="'above'"
									(click)="addToDisliked('blog' + item?.id, item, i)"
								></span>
								<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('blog' + item?.id, item)"></span>
							</div>
						</div>
						<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
							<div class="text" [innerHTML]="subitem"></div>
						</div>
						<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
							<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
							<div class="text" [innerHTML]="subitem.para"></div>
						</div>
						<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
							<div class="text" [innerHTML]="subitem"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'content'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'content' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item?.improvedContent">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('content' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.improvedContent"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('content' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('content' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('content' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="output" *ngIf="category == 'seo'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'seo' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item.text">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('seo' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item.text"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('seo' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('seo' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('seo' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="output" *ngIf="category == 'bio'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'bio' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item.bio">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('bio' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item.bio"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('bio' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('bio' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('bio' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'social'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'social' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item?.title">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.title"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('social' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('social' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content desc" *ngIf="item?.hashtag">
					<!-- special case for hashtags which is part of social templates -->
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.hashtag"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('social' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('social' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content desc" *ngIf="item?.hashtag">
					<!-- special case for videos which is part of social templates -->
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.hashtag"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('social' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('social' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social' + item?.id, item)"></span>
					</div>
				</div>
				<div class="content desc">
					<div class="text-left">
						<div class="actions" *ngIf="!item?.title && !item?.hashtag">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('social' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.caption"></div>
					</div>
					<div class="other-actions" *ngIf="!item?.title && !item?.hashtag">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('social' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('social' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('social' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'framework'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'framework' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item?.text">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('framework' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.text"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('framework' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('framework' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('framework' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>

		<div class="output" *ngIf="category == 'video'">
			<div *ngFor="let item of generatedData; let i = index" [id]="'video' + item?.id" class="group" [class.new]="item?.isNew">
				<div class="content desc" *ngIf="item?.text">
					<div class="text-left">
						<div class="actions">
							<span
								(click)="addToFav(i, $event)"
								matTooltip="Add To Favorite"
								[matTooltipPosition]="'above'"
								[ngClass]="item?.isFav ? 'fav' : 'heart'"
							></span>
							<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied('video' + item?.id, item, i)">
								<span *ngIf="isCopied && currentCopiedEl == i" class="copy-indicator">copied</span>
							</span>
						</div>
						<div class="text" [innerHTML]="item?.text"></div>
					</div>
					<div class="other-actions">
						<span
							[ngClass]="item?.isLiked ? 'liked' : 'like'"
							matTooltip="Like"
							[matTooltipPosition]="'above'"
							(click)="addToLiked('video' + item?.id, item, i)"
						></span>
						<span
							[ngClass]="item?.isDisliked ? 'disliked' : 'dislike'"
							matTooltip="Dislike"
							[matTooltipPosition]="'above'"
							(click)="addToDisliked('video' + item?.id, item, i)"
						></span>
						<span class="delete" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="delete('video' + item?.id, item)"></span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ng-template #noCreditTemplate>
		<div class="no-credits">
			<img width="13%" src="assets/templates/sad-face.svg" alt="sad_emoji" />
			<p class="title">Oops! Your Credits are Expired.</p>
			<button>Upgrade Plan</button>
		</div>
	</ng-template>
</ng-template>
