<div class="side-container" [ngClass]="{ expanded: menuExpanded }">
	<div class="width-controller" [class.special-case]="isDropdown">
		<div class="head">
			<div class="search-con">
				<div class="search">
					<!-- <input type="text" (input)="searchTemplate($event.target.value)" placeholder="Search by name" />
					<em class="fas fa-search icon-search"></em>
					(keyup)="onSearchEnter($event)" -->
					<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
				</div>
				<div class="expand" *ngIf="!isDropdown" (click)="expandProducts()">
					<img src="assets/icons/expand-page.svg" alt="" />
				</div>
			</div>

			<div class="bottom">
				<div class="total">
					<p class="total-text">
						Total -
						<span>{{ products?.length }}</span>
					</p>
				</div>
				<div class="actions">
					<div class="sort-btn header-btns" mat-button [matMenuTriggerFor]="sort" matTooltipClass="tooltipClass" [matTooltip]="'Sort'">
						<img src="assets/icons/sort-icon.svg" alt="" />
						<span>Sort</span>
					</div>
					<div class="filter-btn header-btns" mat-button [matMenuTriggerFor]="filter" matTooltipClass="tooltipClass" [matTooltip]="'Filter'">
						<img src="assets/icons/filter-table.svg" alt="" />
						<span>Filters</span>
					</div>
					<mat-menu #filter="matMenu">
						<div class="filter-box">
							<p>Product Type</p>
							<app-custom-drpdown-op1
								[data]="types"
								(click)="$event.stopPropagation()"
								[dropdownFormControl]="controlA"
								[useCustom]="false"
								(selectionChange)="getFilteredProducts('product_type', $event)"
								[placeHolder]="'Select'"
								[noError]="true"
							></app-custom-drpdown-op1>
							<p>Vendor</p>
							<app-custom-drpdown-op1
								[data]="vendor"
								(click)="$event.stopPropagation()"
								[dropdownFormControl]="controlB"
								[useCustom]="false"
								(selectionChange)="getFilteredProducts('product_vendor', $event)"
								[placeHolder]="'Select'"
								[noError]="true"
							></app-custom-drpdown-op1>
							<p>Tags</p>
							<app-custom-drpdown-op1
								(click)="$event.stopPropagation()"
								[data]="tags"
								(selectionChange)="getFilteredProducts('collection_id', $event)"
								[useCustom]="false"
								[dropdownFormControl]="controlC"
								[placeHolder]="'Select'"
								[noError]="true"
							></app-custom-drpdown-op1>
							<app-button style="width: 100%;" buttonClass="secondary" (onClick)="clearFilters()">Clear All</app-button>
						</div>
					</mat-menu>
					<mat-menu #sort="matMenu">
						<button mat-menu-item (click)="sortProducts('relevance')">Relevance</button>
						<button mat-menu-item (click)="sortProducts('desc')">Latest Created</button>
						<button mat-menu-item (click)="sortProducts('asc')">Earliest Created</button>
					</mat-menu>
				</div>
			</div>
		</div>
		<div class="products-container">
			<div class="products-wrapper">
				<shakespeare-spinning-loader class="spinner" *ngIf="productsLoading"></shakespeare-spinning-loader>
				<div
					class="product-card"
					[ngClass]="{ active: selectedItemIndex == i }"
					*ngFor="let item of products | paginate: config; let i = index"
					(click)="selectItem(item, i)"
				>
					<div class="info">
						<div class="dot" [ngClass]="{ red: item?.optimizationScore == 0 }"></div>
						<img [src]="(item?.images)[0]" alt="" />
						<p>{{ item?.title }}</p>
					</div>
					<div class="opened" *ngIf="selectedItemIndex == i">
						<div>
							<p class="name">Optimization Score</p>
							<p class="value">{{ item?.optimizationScore }}</p>
						</div>
						<div>
							<p class="name">Inventory</p>
							<p class="value">{{ item?.sku }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="has-text-centered" *ngIf="products?.length > 0">
				<shakespeare-spinning-loader class="spinner" *ngIf="loading"></shakespeare-spinning-loader>
				<!-- <pagination-controls previousLabel="" nextLabel="" (pageChange)="getLibraryData($event)" id="server"></pagination-controls> -->
				<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
					<div class="custom-pagination">
						<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
							<a (click)="p.previous()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/left-icon.svg" alt="" /></a>
						</div>
						<div *ngFor="let page of p.pages" class="page" [class.current]="p.getCurrent() === page.value">
							<a (click)="getData(page.label)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
								<span>{{ page.label }}</span>
							</a>
							<div *ngIf="p.getCurrent() === page.value">
								<span>{{ page.label }}</span>
							</div>
						</div>
						<div class="pagination-next" [class.disabled]="p.isLastPage()">
							<a (click)="p.next()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/right-icon.svg" alt="" /></a>
						</div>
					</div>
				</pagination-template>
			</div>
		</div>
	</div>
</div>
