<div class="form-group-wrapper">
	<div class="label-wrapper">
		<label>
			{{ inputLabel }}
			<app-tooltip [hidden]="true" *ngIf="helperText" [text]="helperText"></app-tooltip>
		</label>
		<div>
			<app-switch (toggle)="switchToggled($event)" [value]="toggleValue"></app-switch>
			<div class="counter">
				<span>{{ control?.value ? control?.value?.length : 0 }}/{{ maxLength }}</span>
			</div>
		</div>
	</div>
	<app-text-input [control]="control" [maxLength]="maxLength" [inputPlaceholder]="placeholder"></app-text-input>
</div>
