import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-text-input-with-radio',
	templateUrl: './text-input-with-radio.component.html',
	styleUrls: ['./text-input-with-radio.component.scss']
})
export class TextInputWithRadioComponent implements OnInit {
	@Input() public control: FormControl | AbstractControl = new FormControl('');
	@Input() public inputLabel: string = '';
	@Input() public maxLength: number = 60;
	@Input() public toggleValue: boolean = true;
	@Input() public placeholder: string = 'Enter here...';
	@Input() public helperText: string = null;

	constructor() {}

	ngOnInit(): void {}
	public switchToggled(event: boolean): void {
		if (!event) {
			this.control.disable({ onlySelf: true, emitEvent: true });
		} else {
			this.control.enable({ onlySelf: true, emitEvent: true });
		}
	}
}
