<div class="mat-field-wrapper {{ class }}">
	<mat-form-field appearance="outline" [hideRequiredMarker]="true">
		<mat-label>{{ label || 'Password' }}</mat-label>
		<input matInput placeholder="" [formControl]="control" [type]="!showPassword ? 'password' : 'text'" />
		<mat-icon matSuffix (click)="showPassword = !showPassword">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
	</mat-form-field>
	<div class="error-container" *ngIf="control.invalid && (control.dirty || control.touched)">
		<span>{{ errorMessage }}</span>
	</div>
</div>
