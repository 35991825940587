import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NewSignupRoutingModule } from './new-signup-routing.module';
import { EmailRegisterComponent } from './email-register/email-register.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { PasswordComponent } from './password/password.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { PaymentConfirmation } from './payment-confirmation/payment-confirmation.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { CountryComponent } from './country/country.component';
import { AwesomeComponent } from './awesome/awesome.component';
import { PaymentConfirmPopupComponent } from './payment-confirm-popup/payment-confirm-popup.component';
import { CompanyDetailsPopupComponent } from './company-details-popup/company-details-popup.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
@NgModule({
	imports: [CommonModule, SharedModule, NewSignupRoutingModule, NgxSkeletonLoaderModule],

	declarations: [
		EmailRegisterComponent,
		PasswordStrengthComponent,
		PasswordComponent,
		EmailVerificationComponent,
		PaymentPageComponent,
		PaymentConfirmation,
		CountryComponent,
		AwesomeComponent,

		PaymentConfirmPopupComponent,
		CompanyDetailsPopupComponent
	],
	exports: [PasswordStrengthComponent, CountryComponent, PaymentConfirmPopupComponent],
	providers: []
})
export class NewSignupModule {}
