import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserState, getFiledId } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ShopifyModalComponent } from '../shopify-modal/shopify-modal.component';
import { ShopifyService } from '../shopify.service';
import { ViewProductComponent } from '../view-product/view-product.component';

@Component({
	selector: 'app-newly-generated',
	templateUrl: './newly-generated.component.html',
	styleUrls: ['./newly-generated.component.scss']
})
export class NewlyGeneratedComponent implements OnInit, OnChanges {
	@Input() products = [];
	@ViewChild('p') pagination;
	@ViewChild('allChecker') allChecker;
	@ViewChild('editDesc') editDesc;
	@Output() public pageNumber: EventEmitter<number> = new EventEmitter();
	@Output() public bulkPayload: EventEmitter<any[]> = new EventEmitter();
	radioForm: FormGroup;
	public config: PaginationInstance = {
		id: '1',
		itemsPerPage: 0,
		currentPage: 1,
		totalItems: 0
	};
	filedId: number;
	private unsubscriber$ = new Subject<void>();
	selectedProducts: any[];
	count: number;
	isEditable = false;
	scrWidth: number;
	editId: any;
	unSelectedProducts = [];

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private shopifyService: ShopifyService,
		private userStore: Store<UserState>,
		private toastNotificationService: ToastNotificationService
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(id => {
			this.filedId = id;
		});
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.scrWidth = window.innerWidth;
		this.radioForm = new FormGroup({});
		for (let i = 0; i < this.products?.length; i++) {
			this.radioForm.addControl('radio' + i, new FormControl(true));
		}
		this.count = this.products.length;
		this.radioForm.valueChanges.subscribe(val => {
			this.count = 0;
			this.selectedProducts = [];
			for (let [key, value] of Object.entries(val)) {
				if (value) {
					this.count++;
					this.selectedProducts.push(key);
				} else {
					this.unSelectedProducts.push(key);
				}
			}
			let flag = true;
			for (let [key, value] of Object.entries(val)) {
				if (value) {
					flag = true;
				} else {
					flag = false;
					break;
				}
			}
			flag ? (this.allChecker.nativeElement.checked = true) : (this.allChecker.nativeElement.checked = false);
		});
		this.selectedProducts = [];
		for (let i = 0; i < this.products?.length; i++) {
			this.selectedProducts.push(`radio${i}`);
		}
	}

	ngOnChanges(): void {
		this.config.totalItems = this.products?.length;
		if (this.products?.length <= 35) {
			this.config.itemsPerPage = this.products?.length;
		} else {
			this.config.itemsPerPage = 35;
		}
		console.log(this.config);
		// console.log(this.pagination.pages)
	}

	confirmBulkSave(): void {
		let modalConfig;
		let isMobile = this.scrWidth < 780 ? true : false;
		if (isMobile) {
			modalConfig = {
				width: '90%',
				height: '328px',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '436px',
				height: '328px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ShopifyModalComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			disableClose: true,
			data: {
				title: 'Please Confirm',
				desc: 'This process will update your current Shopify product description immediately.',
				isCancel: true,
				cta: 'Update'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.confirm) {
				this.saveAll();
			}
		});
	}

	checkAll(event: Event): void {
		let checked = event.currentTarget['checked'];
		if (checked) {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(true);
			});
		} else {
			Object.keys(this.radioForm.controls).forEach(key => {
				this.radioForm.controls[key].setValue(false);
			});
		}
	}

	saveAll(): void {
		let ids = [];
		this.unSelectedProducts.forEach(item => {
			let index = item.slice(-1);
			ids.push(this.products[index].productId);
		});
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				product_ids: ids
			}
		};
		this.shopifyService
			.saveBulk(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Process started...');
					this.router.navigate(['shopify']);
				},
				() => {},
				() => {}
			);
	}

	public previewProduct(item: object): void {
		item['title'] = item['generatedData'].title;
		item['description'] = item['generatedData'].description;
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '85%',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '1053px',
				height: '763px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ViewProductComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		dialogRef.componentInstance.modelDetail = {
			product: item
		};
		dialogRef.afterClosed().subscribe(obj => {
			// console.log(obj);
		});
	}

	public setPage(page: number): void {
		this.config.id = page.toString();
		this.pageNumber.emit(page);
	}

	getData(page): void {
		console.log(page, typeof page);
		this.pageNumber.emit(page);
	}

	openEditor(id: number): void {
		this.router.navigate(['shopify/generate'], { queryParams: { productId: id, type: 'history' } });
	}

	generateDescription(): void {
		let payloadData = [];
		this.selectedProducts.forEach(item => {
			let index = item.slice(-1);
			payloadData.push(this.products[index]);
		});
		payloadData = payloadData.map(item => {
			return { product_id: item.productId, title: item.generatedData.title, description: item.generatedData.description, images: item.images };
		});
		this.bulkPayload.emit(payloadData);
	}

	editDescription(item: any): void {
		this.shopifyService.editNewlyGeneratedDesc$.next(item.generatedData.description);
		this.router.navigate(['shopify/generate'], { queryParams: { productId: item.productId, edit: true } });
	}

	updateDescription(item: object): void {
		const payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				product_id: item['productId'],
				description: item['generatedData'].description
			}
		};
		this.shopifyService
			.editProduct(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Description Saved');
				},
				() => {},
				() => {}
			);
	}
}
