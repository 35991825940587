<div class="grid-section">
	<div class="form-area">
		<div class="container">
			<shakespeare-spinning-loader class="spinner" *ngIf="submitting"></shakespeare-spinning-loader>
			<div *ngIf="!submitting && !emailExist">
				<p class="no-user">User no longer exists</p>
			</div>
			<div *ngIf="!submitting && emailExist">
				<div class="logo">
					<img class="logo-shakespeare" src="assets/logo/shakespeare-full-logo.svg" />
				</div>
				<div class="title">Create your password</div>
				<div class="form">
					<form [formGroup]="passwordForm">
						<div class="text-input-split">
							<div class="left">
								<div class="label">{{ 'First Name' }}</div>
								<app-text-input
									[inputPlaceholder]="'Enter first name'"
									[control]="passwordForm.get('firstName')"
									[errorMessage]="'Enter a valid first name'"
								></app-text-input>
							</div>
							<div class="right">
								<div class="label">{{ 'Last Name' }}</div>
								<app-text-input
									[inputPlaceholder]="'Enter last name'"
									[control]="passwordForm.get('lastName')"
									[errorMessage]="'Enter a valid last name'"
								></app-text-input>
							</div>
						</div>
						<div class="full-text-input l-margin">
							<div class="label">{{ 'Password' }}</div>
							<app-input-field-password
								[showValidIcon]="true"
								[hideError]="false"
								class="title-input"
								[inputPlaceholder]="'Enter your password'"
								[control]="passwordForm.get('password')"
								[showCustomIcon]="passwordValidationIcon"
								[errorMessage]="'Strong password required with 1 upper, 1 lowercase, 1 special character and 1 number'"
								[iconWidth]="'14px'"
								[marginRight]="'8px'"
							></app-input-field-password>
						</div>
					</form>
				</div>
				<div (click)="continueSignup()" class="signin-btn-wrapper">
					<div class="btn">
						<div class="items">
							<div class="text">{{ 'Continue' }}</div>
						</div>
					</div>
				</div>
				<!-- <div class="footer-link">
					Got a question?
					<p>Don't worry. We here to help you</p>
				</div> -->
			</div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
