import { Injectable } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';

@Injectable({
	providedIn: 'root'
})
export class OverviewChartsService {
	currencySymbol = '$';

	//doughnut-charts
	public doughnutChartLabels: string[] = ['Desktop', 'Tablet', 'Mobile'];
	public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
		{
			data: [33, 33, 33],
			label: '', // Label for the dataset
			backgroundColor: ['#006BFF', '#2FCE69', '#FFBB4C'],
			borderWidth: 0,
			borderColor: '#FFFFFF', // Border color for all segments
			hoverBackgroundColor: ['#00A3FF', '#5EDB8A', '#FFD36F'], // Background color on hover
			hoverBorderColor: '#FFFFFF' // Border color on hover
		}
	];

	public doughnutChartOptions: any = {
		cutout: '70%', // Control the size of the hole in the center, specify as a percentage
		hover: {
			mode: 'index' // Use 'index' mode to keep tooltips
		},
		legend: {
			display: true, // Show the legend
			position: 'bottom' // Position of the legend
		},
		title: {
			display: true,
			text: 'My Doughnut Chart Title' // Chart title
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					const label = data.labels[tooltipItem.index];
					const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					return `${label}: ${value}`;
				}
			}
		}
		// You can add more options as needed
	};

	//line-charts
	public lineChartLegend = false;
	public lineChartLegendSecond = false;
	public lineChartDataSecond: ChartConfiguration<'line'>['data'] = {
		labels: ['Dec 20', 'Dec 21', 'Dec 22', 'Dec 23', 'Dec 24', 'Dec 25'],
		datasets: [
			{
				data: [1, 2, 3, 4, 5, 6],
				label: '',
				fill: false,
				tension: 0.4,
				borderColor: '#006bff',
				backgroundColor: '#ffffff',
				pointBackgroundColor: '#006bff'
			},
			{
				data: [6, 5, 4, 3, 2, 1],
				label: '',
				fill: false,
				tension: 0.4,
				borderColor: '#FFBB4C',
				backgroundColor: '#ffffff',
				pointBackgroundColor: '#FFBB4C'
			}
		]
	};
	public lineChartOptionsFirst: ChartOptions<'line'> = {
		responsive: true,
		maintainAspectRatio: false,
		elements: {
			point: {
				radius: 1
			},
			line: {
				borderWidth: 1.7
			}
		},
		plugins: {
			legend: {
				display: false
			},

			tooltip: {
				enabled: true, // Enable tooltips
				mode: 'nearest', // Show tooltips for all datasets at the same index
				intersect: false, // Don't allow tooltips to intersect with each other
				backgroundColor: 'rgba(0, 0, 0, 0.9)', // Tooltip background color

				titleFont: {
					size: 14,
					weight: 'bold'
				},
				bodyFont: {
					size: 14
				}
			}
		},
		scales: {
			x: {
				reverse: false,
				grid: {
					display: false
				},
				ticks: {
					display: false, // Hide y-axis labels
					callback: function (value, index, ticks) {
						return value;
					}
				}
			},
			y: {
				position: 'left',
				beginAtZero: true,
				grid: {
					display: false
				}
			}
		}
	};
	public lineChartOptionsSecond: ChartOptions<'line'> = {
		responsive: true,
		maintainAspectRatio: false,
		elements: {
			point: {
				radius: 0
			},
			line: {
				borderWidth: 1.7
			}
		},
		plugins: {
			legend: {
				display: false
			},

			tooltip: {
				enabled: true, // Enable tooltips
				// mode: 'nearest', // Show tooltips for all datasets at the same index
				intersect: false, // Don't allow tooltips to intersect with each other
				backgroundColor: 'rgba(0, 0, 0, 0.9)', // Tooltip background color

				titleFont: {
					size: 14,
					weight: 'bold'
				},
				bodyFont: {
					size: 14
				}
			}
		},
		scales: {
			x: {
				reverse: false,
				grid: {
					display: false
				}
				// ticks: {
				// 	display: true, // Hide y-axis labels
				// 	callback: function (value, index, ticks) {
				// 		console.log(value,index,ticks)
				// 		return value;
				// 	}
				// }
			},
			y: {
				position: 'left',
				beginAtZero: true,
				grid: {
					display: false
				}
			}
		}
	};

	//horizontalBarCharts
	public horizontalBarChartLegend = false;
	public horizontalBarChartPlugins = [];

	public horizontalBarChartData: ChartConfiguration<'bar'>['data'] = {
		labels: ['Search Terms - Conv - Brand', 'Medical Negligence -AD', 'Medical Negligence - board'],
		datasets: [
			{
				// barThickness: 30,
				data: [0, 0, 0],
				label: 'Clicks',
				backgroundColor: '#006BFF',
				borderRadius: 5
			},
			{
				// barThickness: 30,
				data: [0, 0, 0],
				label: 'Impressions',
				backgroundColor: '#D5F5E1',
				borderRadius: 5
			},
			{
				// barThickness: 30,
				data: [0, 0, 0],
				label: 'Spend',
				backgroundColor: '#FFBB4C',
				borderRadius: 5
			}
		]
	};

	public horizontalBarChartOptions: ChartConfiguration<'bar'>['options'] = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				labels: {
					font: {
						size: 10
					}
				}
			},
			tooltip: {
				enabled: true,
				callbacks: {
					label: context => {
						const label = context.dataset.label || '';
						const value = context.parsed.x;
						return `${label} : ${this.currencySymbol}${value}`;
					}
				}
			}
		},
		layout: {
			// This should be a top-level property, not nested inside plugins
			padding: {
				left: 10 // Adjust the left padding as needed
			}
		},
		scales: {
			x: {
				// max: 30,
				stacked: true // Enable stacking for the X axis (horizontal)
			},
			y: {
				stacked: true, // Enable stacking for the Y axis (vertical)
				grid: {
					display: false
				}

				// ticks: {
				// 	callback: index => {
				// 		if (this.adGrpPerfLabels && this.adGrpPerfLabels.length > 0) {
				// 			// Access the data safely
				// 			const labelValue = this.adGrpPerfLabels[index] || '';
				// 			return labelValue;
				// 		} else {
				// 			// Handle the case where adGrpCPCLabels is undefined or empty
				// 			console.error('adGrpPerfLabels is undefined or empty');
				// 		}
				// 	}
				// }
			}
		},
		indexAxis: 'y'
	};

	constructor() {}
}
