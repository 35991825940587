<div class="in-container">
	<div class="width-controller">
		<div class="header">
			<div class="upper">
				<p class="title">Integrations</p>
				<div class="search">
					<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
				</div>
			</div>
			<div class="d-flex">
				<div class="sub-title">
					<label>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</label>
					<app-tooltip text="You can type your own Tone"></app-tooltip>
				</div>
			</div>
		</div>
		<div class="options">
			<div class="social-cards-holder">
				<div class="social-card">
					<p class="status">Active</p>
					<img src="assets/icons/nav-menu/shopify.svg" alt="" />
					<p class="name">Shopify</p>
					<app-button *ngIf="false" buttonClass="link-secondary">DISCONNECT</app-button>
					<app-button *ngIf="true" buttonClass="link-secondary">CONNECT</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
