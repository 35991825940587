<section class="google-overview-wrapper">
	<header>
		<div class="left" (click)="goBack()">
			<img src="assets/icons/Icon ionic-ios-arrow-back.svg" alt="" srcset="" />
			<span>Google Ad Accounts</span>
		</div>
		<!-- <div class="datepicker">
			<mat-form-field class="custom-mat-form-field">
				<mat-date-range-input [formGroup]="range" [rangePicker]="picker" (click)="picker.open()">
					<input matStartDate formControlName="start" placeholder="Start date" />
					<input matEndDate formControlName="end" placeholder="End date" (dateChange)="hello('end', $event)" />
				</mat-date-range-input>
				<img src="assets/icons/Icon ionic-ios-arrow-down.svg" alt="" />
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div> -->
		<app-primary-calender (sendStartDate)="initialiseStartDate($event)" (sendEndDate)="initialiseEndDate($event)"></app-primary-calender>
	</header>
	<section class="cards-overview">
		<app-cards-overview
			*ngIf="overallDataList && !showLoader"
			[overallData]="overallDataList"
			[fromDate]="fromDate"
			[toDate]="toDate"
			[brandLogo]="'google'"
		></app-cards-overview>
		<div class="skeleton-container">
			<ngx-skeleton-loader
				*ngIf="showLoader"
				count="5"
				appearance="line"
				style="height: 105px;"
				[theme]="{ width: '100%', height: '105px' }"
			></ngx-skeleton-loader>
		</div>
	</section>
</section>
