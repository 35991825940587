import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
	selector: 'app-notifications-list',
	templateUrl: './notifications-list.component.html',
	styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
	@Input() notifications: any[] = [];
	@Output() updateUnread = new EventEmitter<number>();

	constructor(public notificationService: NotificationsService) {}

	ngOnInit(): void {}

	public platformImage(platform: string) {
		const map = {
			facebook: 'assets/settings/notifications/Icon awesome-facebook.svg',
			google: 'assets/settings/notifications/svgexport-1 (13).svg',
			slack: 'assets/settings/integrations/image 6.png'
		};

		return map[platform];
	}

	public actionImage(data: any) {
		const map = {
			connect: 'assets/settings/notifications/Group 54525.png',
			disconnect: 'assets/settings/notifications/Group 54522.png'
		};

		if (!data.isConnect) return map['disconnect'];
		else return map['connect'];
	}

	public markAsRead(notification: any, index: number): void {
		this.notifications[index].unreadRead = false;

		this.notificationService.readNotifications(notification.id).subscribe(
			res => {},
			err => {
				this.notifications[index].unreadRead = true;
			}
		);

		if (this.updateUnread) this.updateUnread.emit();
	}
}
