<div class="cancel-container">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title">Contact us</p>
		<p class="sub-title">
			Let us know how we can help you make the most of Shakespeare - email
			<a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@shakespeare.ai" target="_blank">support@shakespeare.ai</a>
			and one of our representatives will contact you
		</p>
	</div>
	<div class="footer">
		<button class="mind" (click)="changeStepType()">Back</button>
		<button class="no" (click)="proceedToCancel()">Next</button>
	</div>
</div>
