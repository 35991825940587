<div class="container">
	<div class="header">
		<div class="name">
			<img [src]="libraryData['templateData']?.img" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" alt="" />
			<p class="title">{{ libraryData['templateData']?.title || 'Write From Scratch' }}</p>
		</div>
		<div class="actions">
			<p class="show-btn" (click)="toggleInput()">{{ inputToggleBtn }}</p>
			<p class="copy-btn" (click)="addToCopied('outputData')">
				Copy
				<span *ngIf="isCopied" class="copy-indicator">copied</span>
			</p>
		</div>
		<em class="k-icon k-i-close close" (click)="dialogRef.close()"></em>
	</div>
	<div class="body">
		<div class="inputs-con" *ngIf="showInputs">
			<div class="input" *ngFor="let input of libraryData.userInput | keyvalue">
				<p class="name">{{ input.key }}</p>
				<p class="value">{{ input.value }}</p>
			</div>
		</div>
		<div #text id="outputData" class="output-con" contenteditable="true">
			<!-- <p #text class="output-text" contenteditable="true">{{ libraryData.text }}</p> -->

			<!-- special advance generator -->
			<div *ngIf="template == 'advanced completion'" class="title">
				<div *ngFor="let item of extractedData; let i = index" [id]="'advance' + i" class="group">
					<div class="content" *ngIf="item?.advancedCompletion">
						<div class="text" [innerHTML]="item?.advancedCompletion"></div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'ad-copy'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'ad-copy' + i" class="group">
					<div class="content" *ngIf="item?.title">
						<div class="text" [innerHTML]="item?.title" [ngClass]="item?.description ? 'boldTitle' : ''"></div>
					</div>
					<div class="content desc" *ngIf="item?.description">
						<div class="text" [innerHTML]="item?.description"></div>
					</div>
					<div class="content" *ngIf="item?.cta">
						<div class="text" [innerHTML]="item?.cta"></div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'email'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'email' + i" class="group">
					<div class="content desc" *ngIf="item?.subject">
						<div class="text boldTitle" [innerHTML]="item?.subject"></div>
					</div>
					<div class="content">
						<div class="text" [innerHTML]="item?.email"></div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'product-description'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'product-description' + i" class="group">
					<div class="content desc" *ngIf="item?.mainDescription">
						<div class="text" [innerHTML]="item?.mainDescription"></div>
					</div>
					<div *ngFor="let subItem of item.uspDescription">
						<!-- <div class="content" *ngIf="subItem?.subject">
							<div class="text" [innerHTML]="item?.title"></div>
						</div> -->
						<div class="content-2" *ngIf="subItem?.description">
							<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
							<span class="text" [innerHTML]="subItem?.description"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'content'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'content' + i" class="group">
					<div class="content desc" *ngIf="item?.improvedContent">
						<div class="text" [innerHTML]="item?.improvedContent"></div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'blog'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'blog' + item?.id" class="group">
					<div *ngIf="!fullBlogCase" class="case-default">
						<div class="content desc" *ngIf="item?.para" id="para">
							<div class="text" [innerHTML]="item?.para"></div>
						</div>
						<div class="content desc" *ngIf="item.text" id="blog-global">
							<div class="text" [innerHTML]="item.text"></div>
						</div>
						<div class="content desc" *ngIf="item.title">
							<div class="text" [innerHTML]="item.title"></div>
						</div>
					</div>
					<div *ngIf="fullBlogCase" class="full-blog">
						<div class="group">
							<div class="content desc blog-title" *ngIf="item.title">
								<div class="text boldTitle" [innerHTML]="item.title"></div>
								<br />
							</div>
							<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
								<div class="text" [innerHTML]="subitem"></div>
							</div>
							<br />
							<br />
							<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
								<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
								<div class="text" [innerHTML]="subitem.para"></div>
								<br />
							</div>
							<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
								<div class="text" [innerHTML]="subitem"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'essay'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'essay' + item?.id" class="group">
					<div *ngIf="!fullBlogCase" class="case-default">
						<div class="content desc" *ngIf="item?.para" id="para">
							<div class="text" [innerHTML]="item?.para"></div>
						</div>
						<div class="content desc" *ngIf="item.text" id="blog-global">
							<div class="text" [innerHTML]="item.text"></div>
						</div>
						<div class="content desc" *ngIf="item.title">
							<div class="text" [innerHTML]="item.title"></div>
						</div>
					</div>
					<div *ngIf="fullBlogCase" class="full-blog">
						<div class="group">
							<div class="content desc blog-title" *ngIf="item.title">
								<div class="text boldTitle" [innerHTML]="item.title"></div>
								<br />
							</div>
							<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
								<div class="text" [innerHTML]="subitem"></div>
							</div>
							<br />
							<br />
							<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
								<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
								<div class="text" [innerHTML]="subitem.para"></div>
								<br />
							</div>
							<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
								<div class="text" [innerHTML]="subitem"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'social'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'social' + item?.id" class="group">
					<div class="content desc" *ngIf="item?.title">
						<div class="text" [innerHTML]="item?.title"></div>
					</div>
					<div class="content desc" *ngIf="item?.hashtag">
						<!-- special case for hashtags which is part of social templates -->
						<div class="text" [innerHTML]="item?.hashtag"></div>
					</div>
					<div class="content desc">
						<div class="text" [innerHTML]="item?.caption"></div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'framework'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'framework' + item?.id" class="group">
					<div class="content desc" *ngIf="item?.text">
						<div class="text" [innerHTML]="item?.text"></div>
					</div>
				</div>
			</div>

			<div class="output" *ngIf="category == 'video'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'video' + item?.id" class="group">
					<div class="content desc" *ngIf="item?.text">
						<div class="text" [innerHTML]="item?.text"></div>
					</div>
				</div>
			</div>
			<div class="output" *ngIf="category == 'seo'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'seo' + item?.id" class="group">
					<div class="content desc" *ngIf="item?.text">
						<div class="text" [innerHTML]="item?.text"></div>
					</div>
				</div>
			</div>
			<div class="output" *ngIf="category == 'bio'">
				<div *ngFor="let item of extractedData; let i = index" [id]="'bio' + item?.id" class="group">
					<div class="content desc" *ngIf="item?.text">
						<div class="text" [innerHTML]="item?.text"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
