import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subject, Subscription, interval, take, takeUntil } from 'rxjs';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
	private unsubscriber$ = new Subject<void>();
	public notifications: any[] = [];
	private notificationSubscription: Subscription;
	public unreadCount: number = 0;
	public notificationPollInterval: number = 5000;
	public pageSize: number = 10;
	public isNext: boolean = false;
	public loading: boolean = false;
	public intersectionObserver: any;

	constructor(public notificationService: NotificationsService, private location: Location) {}

	ngOnInit(): void {
		this.checkCachedNotifications();
		this.checkInfiniteScroll();
		// this.pollNotifications();
		this.getNotifications();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.removeNotificationPoll();
		this.intersectionObserver.unobserve(document.querySelector('.more'));
	}

	public goBack(): void {
		this.location.back();
	}

	private checkCachedNotifications(): void {
		const cachedNotifications = localStorage.getItem('notifications');
		if (cachedNotifications) {
			this.notifications = JSON.parse(cachedNotifications);
		}
	}

	public pollNotifications(): void {
		interval(this.notificationPollInterval)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(x => {
				this.getNotifications();
			});
	}

	public getNotifications(): void {
		// this.removeNotificationPoll();
		// this.notificationSubscription = this.notificationService
		//   .getAllNotifications(this.pageSize)
		//   .pipe(take(1))
		//   .subscribe(res => {
		//     this.notifications = res;
		//     this.isNext = this.pageSize == this.notifications.length;
		//     this.loading = false;
		//   }, err => {
		//     this.loading = false;
		//   })
		this.notificationService
			.getAllNotifications(this.pageSize)
			.subscribe(res => {
				this.notifications = res;
				this.isNext = this.pageSize == this.notifications.length;
			})
			.add(() => {
				this.loading = false;
			});
	}

	public removeNotificationPoll(): void {
		if (this.notificationSubscription && !this.notificationSubscription.closed) {
			this.notificationSubscription.unsubscribe();
		}
	}

	private checkInfiniteScroll(): void {
		this.intersectionObserver = new IntersectionObserver(entries => {
			if (entries[0].intersectionRatio <= 0) return;
			// load more content;
			if (this.isNext) {
				this.loadMoreNotifications();
			}
		});
		// start observing
		this.intersectionObserver.observe(document.querySelector('.more'));
	}

	private loadMoreNotifications(): void {
		this.loading = true;
		this.pageSize = this.pageSize + 10;
		this.getNotifications();
	}
}
