import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-integrations',
	templateUrl: './integrations.component.html',
	styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}

	searchTemplate(query: string): void {}
}
