import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserDetails } from '../state/user/user.reducer';
import { Modules } from '../permisions/enums/modules';
import { TokenService } from 'src/app/_services/token.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { EditorService } from 'src/app/shakespeare/ads/facebook/editor/editor.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
	constructor(public authService: AuthenticationService, private tokenService: TokenService, public router: Router, public store: Store) {
		// this.authService.getBrand();
		// this.authService.getDefaultSelectedPlatform();
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const isUserLoggedIn$ = of(this.authService.isLoggedIn());

		const hasBusinessOwner$ = this.authService.hasBusinessOwner();
		const hasCreditCard$ = this.authService.hasCreditCard();
		const isAdmin$ = this.authService.isAdmin();
		const isFreemiumExpired$ = this.authService.isFreemiumExpired();
		const isFreeTrialExpired$ = this.authService.isFreeTrialExpired();
		const is7DayFreeTrial$ = this.authService.is7DayFreeTrialUser();
		const isBrandData$ = this.authService.getBrand1();
		const isDefaultPlatform$ = this.authService.getDefaultSelectedPlatform1();

		return forkJoin([
			isUserLoggedIn$,
			hasCreditCard$,
			isAdmin$,
			hasBusinessOwner$,
			isFreemiumExpired$,
			isFreeTrialExpired$,
			isBrandData$,
			isDefaultPlatform$
		]).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			switchMap(
				([[isUserLoggedIn, hasCreditCard, isAdmin, hasBusinessOwner, isFreemiumExpired, isFreeTrialExpired, isBrandData, isDefault], userDetails]) => {
					if (!isUserLoggedIn) {
						this.router.navigate(['/authentication']);
						return of(false);
					}
					if (!hasCreditCard && !isAdmin) {
						this.router.navigate(['/authentication/payment']);
						return of(false);
					} else if (isFreemiumExpired || isFreeTrialExpired) {
						this.router.navigate(['/authentication/trial-end']);
						return of(false);
					} else if (is7DayFreeTrial$ && isUserLoggedIn) {
						let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
						if (token && token.sign_up_version && token.sign_up_version == 'v2') {
							this.router.navigate(['/authentication/enterprise']);
						} else if (token && token.sign_up_version && token.sign_up_version == 'v3') {
							this.router.navigate(['/authentication/v3enterprise']);
						} else {
							this.router.navigate(['/authentication/free-trial']);
						}
						return of(false);
					}
					if (isBrandData?.data?.companyName) {
						this.authService.checkingFirstTimeUser = { data: { companyName: '' } };
						localStorage.setItem('checkingFirstTimeUser', '2');
						if (isDefault !== null) {
							this.authService.defaultSelectedPlatform = isDefault;
							return of(true);
						} else {
							this.authService.defaultSelectedPlatform = null;
							this.router.navigate(['/welcome-shakespeare-form']);
							return of(false);
						}
						// this.authService.getDefaultSelectedPlatform1().pipe(
						// 	map(result => {
						// 		if (result !== null) {
						// 			return of(true);
						// 		} else {
						// 			this.router.navigate(['/welcome-shakespeare-form']);
						// 			return of(false);
						// 		}
						// 	})
						// );
					} else {
						this.authService.checkingFirstTimeUser = null;
						localStorage.setItem('checkingFirstTimeUser', '1');
						this.router.navigate(['/welcome-shakespeare-form']);
						return of(false);
					}

					// return this.authService.getBrand().pipe(
					// 	map(result => {
					// 		if (result?.data?.companyName && this.authService.defaultSelectedPlatform !== null) {
					// 			console.log('if', result);
					// 			return true;
					// 			// The BrandName is available, return true
					// 		} else {
					// 			console.log('else', result);
					// 			this.router.navigate(['/welcome-shakespeare-form']);
					// 			return false;
					// 			// The BrandName is not available, navigation has already been handled, return false
					// 		}
					// 	})
					// );

					// let brand = this.authService.checkingFirstTimeUser;
					// if (brand?.data?.BrandName) {
					// 	return of(true);
					// } else {
					// 	this.router.navigate(['/welcome-shakespeare-form']);
					// 	return of(false);
					// }
				}
			)
		);
	}
}
