import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { TemplateState } from 'src/app/state/shakespeare-common-state/shakespeare-common-reducer';
import { ShopifyService } from '../shopify.service';
import { ViewProductComponent } from '../view-product/view-product.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentInterface, EditorModeEnum, ToggleValueInterface } from '../../documents/document-interface';
import { FormGroup } from '@angular/forms';
import { ShopifyModalComponent } from '../shopify-modal/shopify-modal.component';
import { DocumentService } from '../../documents/document.service';

@Component({
	selector: 'app-shopify-history',
	templateUrl: './shopify-history.component.html',
	styleUrls: ['./shopify-history.component.scss']
})
export class ShopifyHistoryComponent implements OnInit, OnChanges {
	@Input() public formData: object;
	@Input() public template: object;
	@Input() public selectedProduct: any;
	@Output() public callbackData = new EventEmitter<any>();
	@Output() public isDataFound = new EventEmitter<any>();
	@Output() public editorOn = new EventEmitter<any>();
	@Input() public customEdit = new BehaviorSubject<boolean>(false);
	@Input() public customDesc = new BehaviorSubject<string>('');
	public isDeleteAllText$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	// @Output() public isDataFetched = new EventEmitter<boolean>();
	public generatedData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	private unsubscriber$ = new Subject<void>();
	public heartClass = 'far fa-heart';
	public filledHeartClass = 'fas fa-heart filled';
	public filedId: number;
	public historyData: any;
	public category: string;
	public isAdCopyData: boolean;
	public isEmailData: boolean;
	public isDescriptionData: boolean;
	public isBlogData: boolean;
	public isContentData: boolean;
	public isSocialData: boolean;
	public isFrameworkData: boolean;

	public copyHelper = '';
	public isCopied = false;
	public currentCopiedEl: number;
	public fullBlogCase = false;
	public blogType: string;

	public templateName = '';

	public isEditingTitle = false;
	public editedValue: string;
	public editorOutput: any;
	public document: DocumentInterface = {
		createdOn: '1661781115.4748454',
		editedOn: '1661865565.3414576',
		id: 'be5402ec-27a1-11ed-8193-06ddfa98e15f',
		name: 'New Documents',
		text: ''
	};
	public documentId: string;
	public showGrammar = false;
	public aiSelectionProp = { start: 0, end: 0, text: '' };
	public presentEditorText$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	writingText: boolean;
	public toggleValues: ToggleValueInterface = {
		isTitleEnabled: true,
		isDescriptionEnabled: true,
		outputToneEnabled: true,
		isKeywordEnabled: true
	};
	public generatorForm: FormGroup;
	keywordAsArray = [];
	selectedOutputLang: string;
	advanceOptions: any;
	iterator: number;
	public editorMode: EditorModeEnum = EditorModeEnum.Pro;
	public isGeneratingOutput = false;
	isTextEditor: boolean;
	isSaving: boolean;
	scrWidth: number;

	constructor(
		private userServiceApi: UserServiceApi,
		public toastNotificationService: ToastNotificationService,
		private userStore: Store<UserState>,
		private templateStore: Store<TemplateState>,
		private shopifyService: ShopifyService,
		private dialog: MatDialog,
		private documentService: DocumentService
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedProduct?.currentValue) {
			this.getHistory('shopify-product-description');
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.customEdit.subscribe(val => {
			if (val) this.editingMode();
		});
		this.scrWidth = window.innerWidth;
		let url = window.location.href;
		let urlArray = url.split('/');
		this.fullBlogCase = url.split('=')[1] == '21' ? true : false;
		this.category = urlArray[urlArray.length - 1].split('?')[0]; // getting last part of url without any queryParams
		let templateName = this.template['title'];
		this.templateName = templateName;
		if (templateName == 'Shopify Product Description') {
			// this.getProductsHistory()
			this.getHistory('shopify-product-description');
			this.isDescriptionData = true;
		}
	}

	aiAssist(): void {
		this.onSubmitEditor();
	}

	public saveEditorTextToShopify(): void {
		this.isSaving = true;
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				product_id: this.selectedProduct.id,
				description: this.editorOutput?.root?.innerHTML
			}
		};
		this.shopifyService
			.saveProductDescription(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isSaving = false;
						if (response['debugMessage'] === 'Product description saved successfully') {
							this.shopifyService.refreshProducts$.next(true);
							this.toastNotificationService.sendSuccessToast('Product Description saved!');
						}
					}
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	public getHistory(name: string): void {
		let feedbackData = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				template_name: name
			}
		};
		this.userServiceApi
			.getTemplateHistory(feedbackData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					if (response) {
						if (response['body'].debugMessage == 'template history not found') {
							this.isDataFound.emit(false);
						} else {
							this.isDataFound.emit(true);
						}
						this.historyData = response['body'].data;
						this.historyData?.forEach(ele => (ele.isLiked = false));
						this.historyData?.forEach(ele => (ele.isDisliked = false));
						this.historyData = this.historyData.filter(item => {
							return item.projectData.productId == this.selectedProduct?.id || this.selectedProduct?.productId;
						});
						if (this.historyData.length) {
							this.sortData();
						} else {
							this.isDataFound.emit(false);
						}
					}
				},
				error: () => {},
				complete: () => {}
			});
	}

	confirmSave(item: object): void {
		let modalConfig;
		let isMobile = this.scrWidth < 780 ? true : false;
		if (isMobile) {
			modalConfig = {
				width: '90%',
				height: '328px',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '436px',
				height: '328px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ShopifyModalComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			disableClose: true,
			data: {
				title: 'Please Confirm',
				desc: 'This process will update your current Shopify product description immediately.',
				isCancel: true,
				cta: 'Update'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.confirm) {
				this.saveDescription(item);
			}
		});
	}

	saveDescription(item: object): void {
		this.isSaving = true;
		let payload = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				product_id: item['projectData'].productId,
				description: item['projectData']['userOutput'].mainDescription
			}
		};
		this.shopifyService
			.saveProductDescription(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isSaving = false;
						if (response['debugMessage'] === 'Product description saved successfully') {
							this.shopifyService.refreshProducts$.next(true);
							this.toastNotificationService.sendSuccessToast('Product Description saved!');
						}
					}
				},
				() => {
					this.isSaving = false;
				}
			);
	}

	deleteAll(): void {
		this.isDeleteAllText$.next(true);
		setTimeout(() => {
			this.isDeleteAllText$.next(false);
		}, 2000);
	}

	public onSubmitEditor(): void {
		this.isFormSubmitted = true;
		if (this.isGeneratingOutput || this.writingText) {
			return;
		}
		let details = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				userTemplate: {
					template: 'advanced completion'
				},
				userInput: {
					title: this.formData['data'].title || '',
					text: '',
					description: this.formData['data'].description || '',
					tone: this.formData['data'].tone || '',
					input_language: this.formData['data'].outputLanguage || 'en',
					outputLanguage: this.formData['data'].outputLanguage || 'en',
					translate: this.formData['data'].translate || false,
					length: 'medium',
					compeltion_type: 'default',
					keywords: ''
				}
			}
		};
		this.generate(details);
	}

	public generate(details?: any): void {
		this.isGeneratingOutput = true;
		const selection = this.editorOutput.getSelection();
		const previousText = this.editorOutput.getText();
		this.writingStart();
		let selectText = previousText;
		if (selection && selection?.index > 0) {
			selectText = previousText.slice(0, selection?.index);
		}
		details.data.userInput.text = selectText ?? '';
		this.userServiceApi
			.getAutoCompletion(details)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.isGeneratingOutput = false;
					if (response['body'].debugMessage === 'Not enough credit') {
						this.toastNotificationService.sendErrorToast('You have used all your credits, Please upgrade your plan.');
						this.writingEnd();
						return '';
					}
					let autoCompleteResponse = response['body']['output'][0].advancedCompletion;
					this.iterator = 0;
					let typerText = '';
					typerText = autoCompleteResponse;
					var temporalDivElement = document.createElement('div');
					temporalDivElement.innerHTML = typerText;
					this.aiSelectionProp.text = temporalDivElement.innerText;
					this.sendToEditor(true, typerText);
				},
				() => {
					this.writingEnd();
					this.isGeneratingOutput = false;
					this.toastNotificationService.sendErrorToast('There was as error, Please try after some time.');
				}
			);
	}

	handleBackButton(event: boolean): void {
		this.isTextEditor = !event;
		this.editorOn.emit(!event);
	}

	public writingStart(event?: any): void {
		this.writingText = true;
	}

	public writingEnd(event?: any): void {
		this.writingText = false;
	}

	public getEditorControl(event: any): void {
		this.editorOutput = event;
	}

	public saveDocument(_document: DocumentInterface = this.document): void {
		_document.text = this.editorOutput?.root?.innerHTML;
		this.documentService.updateDocument(_document).subscribe(res => {
			this.isEditingTitle = null;
			this.editedValue = null;
		});
	}

	private sendToEditor(aiEditor = false, text = ''): void {
		this.presentEditorText$.next({ aiEditor, text });
	}

	switchToEditor(item: object): void {
		this.isTextEditor = true;
		this.editorOn.emit(true);
		this.presentEditorText$.next({ text: item['mainDescription'] });
	}

	editingMode(): void {
		console.log('editing');
		console.log(this.selectedProduct);
		this.isTextEditor = true;
		this.editorOn.emit(true);
		this.presentEditorText$.next({ text: this.shopifyService.editNewlyGeneratedDesc$.value || '' });
	}

	public previewProduct(item: object): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			isMobile = true;
		}
		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '93%',
				height: '85%',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '1053px',
				height: '763px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ViewProductComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		this.selectedProduct['description'] = item?.['mainDescription'];
		dialogRef.componentInstance.modelDetail = {
			product: this.selectedProduct
		};
		dialogRef.afterClosed().subscribe(obj => {
			// console.log(obj);
		});
	}

	public getDescriptionHistory(name: string): void {
		this.getHistory(name);
		this.isDescriptionData = true;
		// this.templateStore.pipe(select(getDesciptionHistory), takeUntil(this.unsubscriber$)).subscribe(data => {
		// 	if (data['templateData']) {
		// 		this.historyData = data['templateData'];
		// 		if (data['templateData'].length) {
		// 		}
		// 	}
		// });
	}

	getBlogTypeResult(type: string, response: any) {
		// this type is blog_type word.toLowerCase() of selected template
		switch (type) {
			case 'blog':
				return response['body']['data'];
			case 'paragraph':
				return response['body']['data']['paragraphs'];
			case 'outline':
				return response['body']['data']['outline'];
			case 'conclusion':
				return response['body']['data']['conclusion'];
			case 'headlines':
				return response['body']['data']['title'];
			case 'idea':
				return response['body']['data']['blogIdeas'];
			case 'introduction':
				return response['body']['data']['introduction'];
			default:
				break;
		}
	}

	public toObject(array: []) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	public addToFav(idx: number): void {
		if (this.historyData[idx]['isFav'] === true) {
			this.historyData[idx]['isFav'] = false;
		} else {
			this.historyData[idx]['isFav'] = true;
		}
		console.log(this.historyData);
		this.callbackData.emit(this.historyData);
		this.updateToHistory(this.historyData[idx], this.template['title']);
		this.sortData();
	}

	public updateToHistory(data: any, name: string): void {
		// delete data.id;
		let feedbackData = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				template_data: [data],
				template_name: name
			}
		};
		this.userServiceApi
			.addTemplateHistory(feedbackData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						console.log(response);
					}
				},
				() => {},
				() => {}
			);
	}

	public addToLiked(id: string, item: any, idx: number): void {
		if (this.historyData[idx]['isLiked'] === true) {
			this.historyData[idx]['isLiked'] = false;
		} else {
			this.historyData[idx]['isLiked'] = true;
			this.historyData[idx]['isDisliked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			if (!temp.hasOwnProperty('data')) {
				temp['data'] = {};
			}
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'like'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userInput: {},
					userTemplate: {},
					userOutput: temp['data']['userOutput']
				}
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public addToDisliked(id: string, item: any, idx: number): void {
		if (this.historyData[idx]['isDisliked'] === true) {
			this.historyData[idx]['isDisliked'] = false;
		} else {
			this.historyData[idx]['isDisliked'] = true;
			this.historyData[idx]['isLiked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			if (!temp.hasOwnProperty('data')) {
				temp['data'] = {};
			}
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'dislike'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userInput: '',
					userTemplate: '',
					userOutput: temp['data']['userOutput']
				}
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public sortData(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.historyData) {
			if (template?.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.historyData = [...sortedList];
	}

	public async copyWithStyle(element: any): Promise<void> {
		const text = <HTMLElement>document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);

			selection.removeAllRanges();
			selection.addRange(range);
		}
		document.execCommand('copy');
		this.copyHelper = await navigator.clipboard.readText();
		window.getSelection().removeAllRanges();
	}

	public addToCopied(id: string, item: any, index: number): void {
		this.copyWithStyle(id).then(() => {
			this.isCopied = true;
			this.currentCopiedEl = index;
			setTimeout(() => {
				this.isCopied = false;
			}, 3000);
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'copy'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public delete(id: string, item: any) {
		let feedbackData = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: item.id,
				template_name: this.templateName
			}
		};
		this.userServiceApi.deleteTemplateHistoryItem(feedbackData).subscribe(res => {
			this.getHistory(this.templateName);
			this.callbackData.emit(this.historyData);
		});
	}
}
