import { CreateNewModalComponent } from './../../shared/create-new-modal/create-new-modal.component';
import { TEMPLATES } from './../../shared/models/templates.const';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ViewTemplateComponent } from '../view-template/view-template.component';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import moment from 'moment';
import { PaginationInstance } from 'ngx-pagination';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShakespeareService } from '../shakespeare.service';
import { Router } from '@angular/router';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Component({
	selector: 'app-trash',
	templateUrl: './trash.component.html',
	styleUrls: ['./trash.component.scss']
})
export class TrashComponent implements OnInit {
	@ViewChild(SelectContainerComponent) selectContainer: SelectContainerComponent;
	@ViewChild('p') pagination;
	public config: PaginationInstance = {
		id: '1',
		itemsPerPage: 9,
		currentPage: 1,
		totalItems: 0
	};
	public checkForm: FormGroup;
	public libraryData = [];
	public heartClass = 'far fa-heart';
	public filledHeartClass = 'fas fa-heart filled';
	public selectedTemplates: any[] = [];
	public selectedItemTemplates: any[] = [];

	public blogTitleMapper = {
		blog: 'Full Blog',
		headlines: 'Blog Headlines',
		introduction: 'Blog Introduction',
		conclusion: 'Blog Conclusion',
		paragraph: 'Blog Paragraph',
		outline: 'Blog Outline',
		ideas: ' Blog Ideas',
		advanced_outline: 'Blog Outline'
	};

	public essayTitleMapper = {
		blog: 'Full Essay',
		headlines: 'Essay Headlines',
		introduction: 'Essay Introduction',
		conclusion: 'Essay Conclusion',
		paragraph: 'Essay Paragraph',
		outline: 'Essay Outline',
		ideas: ' Essay Ideas',
		advanced_outline: 'Essay Outline'
	};

	public dataLen = 0;
	public isFooter = false;
	public checkedCount = 0;
	public filedId: number;
	private unsubscriber$ = new Subject<void>();

	public pageNumber = 1;
	public totalItems = 100;

	public libraryAlteredData = [];

	public selectedList = [];
	public templates = [...TEMPLATES];
	public fullBlogCase: boolean;
	public blogType: string;
	public copyHelper: string;
	public isCopied: boolean;
	private keys = [];
	public loading: boolean;
	private backupLibrary: any[];
	public notFound: boolean;
	public projectId: string;
	public multiHandler = [];
	public innerWidth: any;
	public copyTemplate = [];
	public checkMob = false;
	menuExpanded: boolean;
	isDataLoading: boolean;
	isBonus: boolean;
	iPhoneMobile: boolean;
	showBonus = true;

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private userStore: Store<UserState>,
		private toastNotificationService: ToastNotificationService,
		private authenticationService: AuthenticationService,
		private clipboard: Clipboard,
		private shakespeareService: ShakespeareService,
		private router: Router
	) {}

	ngOnInit(): void {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		if (token && token.sign_up_version && token.sign_up_version == 'v2') {
			this.showBonus = false;
		}
		this.shakespeareService.isBonusOffer$.subscribe(val => {
			this.isBonus = val;
		});
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.isDataLoading = true;
		this.getLibraryData();
		this.innerWidth = window.innerWidth;

		const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

		toMatch.some(toMatchItem => {
			if (navigator.userAgent.match(toMatchItem)) {
				this.checkMob = true;
			}
		});

		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	public navigateToBilling(): void {
		this.router.navigate(['/settings/billing'], { queryParams: { page: 'upgrade' } });
	}

	changeDet() {
		this.checkForm?.valueChanges.subscribe(() => {
			this.checkedCount = 0;
			let flag = false;
			this.keys = [];
			for (const key of Object.keys(this.checkForm.controls)) {
				console.log(this.checkForm.controls[key].value);
				// check if any one value true, then make footer true and count all checked boxes
				if (this.checkForm.controls[key].value) {
					this.keys.push(key);
					flag = true;
					this.checkedCount++;
				}
				this.isFooter = flag;
			}
		});
	}

	public searchTemplate(_input: string): void {
		let inputVal = _input.toLowerCase();
		this.debounce(this.filterTemplates(inputVal));
	}

	public filterTemplates(value: string) {
		let temp = this.backupLibrary;
		if (value === '') {
			temp = this.backupLibrary;
			this.libraryAlteredData = temp;
			this.notFound = false;
			return;
		}
		let filteredData = temp.filter(template => {
			console.log(template);
			return template['data']?.templateData?.title.toLowerCase().includes(value);
		});
		if (filteredData.length) {
			temp = filteredData;
			this.notFound = false;
		} else {
			temp = [];
			this.notFound = true;
		}
		this.libraryAlteredData = temp;
		// this.sortData();
	}

	public debounce(callback, delay: number = 500) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				callback(...args);
			}, delay);
		};
	}

	changeDetection(): void {}

	public viewTemplate(template): void {
		let isMobile = false;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
			isMobile = true;
		}

		let modalConfig;
		if (isMobile) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '50%',
				height: '75%',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ViewTemplateComponent, {
			...modalConfig,
			data: { template, isMobile }
		});
		// do something after close
	}

	public openCreateModal(): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: false,
			data: {
				existing: true,
				createOnly: false
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'New Project',
			text: 'Enter a name to save.',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			console.log(obj);
			if (obj.value) {
				this.saveProjectHelper(obj.value);
			}
			if (obj.id) {
				this.addToProjectHelper(obj.id);
			}
			if (!obj) {
				this.selectedTemplates = [];
			}
		});
	}

	public saveProjectHelper(name: string): void {
		// console.log(this.keys);
		// this.keys = [...new Set(this.keys)];
		// this.selectedTemplates = [];
		// this.keys.forEach(item => {
		// 	this.selectedTemplates.push(this.libraryAlteredData[item.slice(-1)]);
		// });
		let payload = {};
		let temp = true;
		console.log(this.copyTemplate);
		this.selectedTemplates = this.copyTemplate;
		this.selectedTemplates.forEach(template => {
			payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userTemplate: template.data.userTemplate,
					userInput: template.data.userInput,
					userOutput: template.data.userOutput
				}
			};

			// handling multiple outputs save in one go
			// (first making a creatProject() and then addToProject for rest outputs)
			if (temp) {
				payload['data']['name'] = name || 'untitled-' + moment().format();
			} else {
				payload['data']['id'] = this.projectId;
			}

			temp ? this.saveProject(payload) : this.multiHandler.push(payload);
			temp = false;
		});
	}

	public handleMultiple(): void {
		if (this.multiHandler.length == 0) return;
		this.multiHandler.forEach(payload => {
			payload['data']['id'] = this.projectId;
			this.addToProject(payload);
		});
	}

	public addToProjectHelper(id: string): void {
		let payload = {};
		this.selectedTemplates.forEach(template => {
			payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					id: id,
					userTemplate: template.data.userTemplate,
					userInput: template.data.userInput,
					userOutput: template.data.userOutput
				}
			};
			this.addToProject(payload);
		});
	}

	public saveProject(payload: object): void {
		this.userServiceApi
			.createUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'Project with same name exists already') {
						this.toastNotificationService.sendErrorToast('Operation failed! Project name already exists');
						return;
					}
					this.projectId = response['body'].projectId;
					this.handleMultiple();
					this.toastNotificationService.sendSuccessToast('Project created');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
	}

	public addToProject(payload: object): void {
		this.userServiceApi
			.addToUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'project data already existed') {
						this.toastNotificationService.sendErrorToast('Operation failed! Content already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Output added!');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
	}

	public toggleControl(event: any, data: any): void {
		event.stopPropagation();
		this.selectContainer.toggleItems((xd: any) => xd?.index == data.index);
		const found = this.copyTemplate.find(ele => ele.id === data.temp.id);
		if (found !== undefined) {
			this.copyTemplate.splice(this.copyTemplate.indexOf(found), 1);
		} else {
			return;
		}
	}

	public onSelectTemplates(event: any, paginationId: string): void {
		this.selectedTemplates = this.selectedItemTemplates.map(value => {
			value.temp.page = paginationId;
			this.checkForm.controls['val' + value.index + '-' + this.pageNumber].patchValue(true);
			return value.temp;
		});
		this.selectedTemplates.forEach(ele => {
			if (this.copyTemplate.length > 0) {
				const found = this.copyTemplate.find(item => item.id === ele.id);
				if (found === undefined) {
					this.copyTemplate.push(ele);
				} else {
					return;
				}
			} else {
				this.copyTemplate.push(ele);
			}
		});
		this.isFooter = event.length || this.checkedCount > 0 ? true : false;
		this.checkedCount = this.copyTemplate.length;
	}

	public deselectAll(): void {
		this.selectedTemplates = [];
		this.copyTemplate = [];
		this.checkedCount = 0;
		this.isFooter = false;
		this.selectContainer.clearSelection();
		this.checkForm.reset();
	}

	public initForm(): void {
		this.checkForm = this.fb.group({});
		for (let i = 0; i < this.libraryAlteredData.length; i++) {
			this.checkForm.addControl('val' + i + '-' + this.pageNumber, new FormControl(false));
		}
	}

	public onPageBoundsCorrection(page: number): void {
		this.getLibraryData(page);
	}

	public setPage(page: number): void {
		this.config.id = page.toString();
		this.getLibraryData(page);
	}

	public getLibraryData(page: number = 1): void {
		if (typeof page == 'string') {
			return;
		}
		this.config.currentPage = page;
		this.loading = true;
		this.pageNumber = page;
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				pageNumber: page
			}
		};
		this.userServiceApi
			.getTrashHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.loading = false;
						this.libraryData = response['body']['data']['trash'];
						this.totalItems = response['body']['data']['totalTrashElement'];
						this.config.totalItems = this.totalItems;
						if (this.totalItems < 9) {
							this.config.itemsPerPage = this.totalItems;
						} else {
							this.config.itemsPerPage = 9;
						}
						this.isDataLoading = false;
						this.mappingHelper();
						this.dataLen = this.libraryData.length;
						this.loading = false;
						// this.initForm();
						// this.changeDetection();
					}
				},
				() => {},
				() => {
					this.loading = false;
					// this.toastNotificationService.sendSuccessToast('Project saved successfully');
				}
			);
	}

	public mappingHelper(): void {
		let selectedTemplate;
		this.libraryData.forEach(item => {
			item['since'] = this.timeSince(item.createdOn);
			let filteredTemplate = this.templates.filter(template => {
				return item['data'].userTemplate.template == template.template;
			});
			if (item['data'].userTemplate.platform != '' || item['data'].userTemplate.platform == 'default') {
				selectedTemplate = filteredTemplate.find(template => {
					return item['data'].userTemplate.platform == template.platform;
				});
			} else {
				selectedTemplate = filteredTemplate[0];
			}
			item['data']['templateData'] = selectedTemplate;
			// special case for blog templates
			if (item['data'].userTemplate.template == 'Blog' || item['data'].userTemplate.template == 'Blog Wizard') {
				let type = item['data'].userInput.blogType;
				item['data']['templateData'] = this.templates.find(template => {
					return this.blogTitleMapper[type] == template.title;
				});
				item['data'].userOutput = this.getBlogTypeResult(type, item);
				if (typeof item['data'].userOutput == 'object' && typeof item['data'].userOutput[0] == 'string') {
					item['data'].userOutput = this.toObject(item['data'].userOutput);
				}
			}
			// special case for essay templates
			if (item['data'].userTemplate.template == 'essay') {
				let type = item['data'].userInput.essayType;
				item['data']['templateData'] = this.templates.find(template => {
					return this.blogTitleMapper[type] == template.title;
				});
				item['data'].userOutput = this.getEssayTypeResult(type, item);
				if (typeof item['data'].userOutput == 'object' && typeof item['data'].userOutput[0] == 'string') {
					item['data'].userOutput = this.toObject(item['data'].userOutput);
				}
			}
			if (item['data'].userTemplate?.platform == 'youtube' || item.userTemplate?.platform == 'tiktok') {
				selectedTemplate = this.templates.find(template => {
					return item['data'].userInput.videoType == template.template;
				});
				item['data']['templateData'] = selectedTemplate;
				item['data'].userOutput = this.toObject(item['data'].userOutput[item['data'].templateData.type]);
			}
			if (item['data'].userTemplate.template == 'content') {
				selectedTemplate = this.templates.find(template => {
					return item['data'].userInput.contentType == template.type;
				});
				item['data']['templateData'] = selectedTemplate;
			}
			if (item['data'].userTemplate.template == 'Bio') {
				selectedTemplate = this.templates.find(template => {
					return item['data'].userInput.bioType == template.type;
				});
				item['data']['templateData'] = selectedTemplate;
			}
		});
		// this.libraryData = this.libraryData.filter(item => {
		// 	return item['data'].userInput.blogType != 'advanced_outline' && item['data'].userTemplate.template != 'advanced_completion';
		// });
		console.log(this.libraryData);
		this.backupLibrary = [...this.libraryData];
		this.libraryAlteredData = [...this.libraryData];
		if (this.copyTemplate?.length > 0) {
			this.checkedCount = this.copyTemplate?.length;
			this.libraryData?.forEach(ele => {
				const found = this.copyTemplate?.find(item => item.id === ele.id);
				if (found !== undefined && found.page === this.config.currentPage.toString()) {
					this.checkForm.addControl('val' + this.libraryData?.indexOf(found) + '-' + this.pageNumber, new FormControl(true));
				} else {
					this.checkForm.addControl('val' + this.libraryData?.indexOf(ele) + '-' + this.pageNumber, new FormControl(false));
				}
			});
		} else {
			this.initForm();
		}
		this.retrieveFavorites();
	}

	public timeSince(date) {
		// date is in epoch format (seconds)
		var convertedDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
		convertedDate.setUTCSeconds(date);

		let cal = (new Date() as any) - (convertedDate as any);
		let seconds = Math.floor(cal / 1000);

		let interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) == 1 ? Math.floor(interval) + ' year ago' : Math.floor(interval) + ' years ago';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) == 1 ? Math.floor(interval) + ' month ago' : Math.floor(interval) + ' months ago';
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) == 1 ? Math.floor(interval) + ' day ago' : Math.floor(interval) + ' days ago';
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) == 1 ? Math.floor(interval) + ' hr ago' : Math.floor(interval) + ' hrs ago';
		}
		interval = seconds / 60;
		if (interval > 1) {
			return Math.floor(interval) == 1 ? Math.floor(interval) + ' min ago' : Math.floor(interval) + ' min ago';
		}
		return Math.floor(interval) == 1 ? Math.floor(interval) + ' sec ago' : Math.floor(interval) + ' sec ago';
	}

	public copyWithStyle(element): string {
		const text = document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);
			selection.removeAllRanges();
			selection.addRange(range);
			this.copyHelper = window.getSelection().toString();
			// document.execCommand('copy');
			window.getSelection().removeAllRanges();
			return this.copyHelper;
		}
	}

	public toObject(array) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	getBlogTypeResult(type, arg) {
		this.fullBlogCase = type == 'blog' ? true : false;
		switch (type) {
			case 'blog':
				return [arg['data']['userOutput']];
			case 'paragraph':
				return arg['data']['userOutput']['paragraphs'];
			case 'outline':
			case 'advanced_outline':
				return arg['data']['userOutput']['outline'];
			case 'conclusion':
				return arg['data']['userOutput']['conclusion'];
			case 'headlines':
				return arg['data']['userOutput']['title'];
			case 'ideas':
				return arg['data']['userOutput']['blogIdeas'];
			case 'introduction':
				return arg['data']['userOutput']['introduction'];
			default:
				break;
		}
	}

	getEssayTypeResult(type, arg) {
		this.fullBlogCase = type == 'essay' ? true : false;
		switch (type) {
			case 'essay':
				return [arg['data']['userOutput']];
			case 'paragraph':
				return arg['data']['userOutput']['paragraphs'];
			case 'outline':
			case 'advanced_outline':
				return arg['data']['userOutput']['outline'];
			case 'conclusion':
				return arg['data']['userOutput']['conclusion'];
			case 'headlines':
				return arg['data']['userOutput']['title'];
			case 'ideas':
				return arg['data']['userOutput']['essayIdeas'];
			case 'introduction':
				return arg['data']['userOutput']['introduction'];
			default:
				break;
		}
	}

	public addToCopied(template: any, event: Event): void {
		event.stopPropagation();
		let text = '';
		const outputObj = template.data.userOutput;
		switch (template.data.userTemplate.template) {
			case 'advanced completion':
				outputObj.forEach(ele => {
					text += ele.advancedCompletion + '\n';
				});
				break;
			case 'ad_copy':
				let adCopyTitle = '';
				let adCopyDesc = '';
				let adCopyCta = '';
				outputObj.forEach(ele => {
					if (ele.cta !== undefined) {
						adCopyCta = ele.cta + '\n';
					}
					if (ele.title !== undefined) {
						adCopyTitle = ele.title + '\n';
					}
					if (ele.description !== undefined) {
						adCopyDesc = ele.description + '\n';
					}
					text += adCopyTitle + adCopyDesc + adCopyCta;
				});
				break;
			case 'email':
				outputObj.forEach(ele => {
					if (ele.subject) {
						text += ele.subject + '\n';
					}
					if (ele.email) {
						text += ele.email + '\n';
					}
				});
				break;
			case 'ProductDescription':
				let list = [];
				outputObj.forEach(ele => {
					if (ele.uspDescription.length > 0) {
						ele.uspDescription.forEach(item => {
							let desc = '- ' + item.description + '\n';
							list.push(item.title + ':\n', desc);
						});
						text += ele.mainDescription + '\n\n' + list.join('');
					} else {
						text += ele.mainDescription + '\n';
					}
				});
				break;
			case 'Blog':
				let para = '';
				let blogText = '';
				let blogTitle = '';
				outputObj.forEach(ele => {
					if (ele.para !== undefined) {
						para = ele.para + '\n';
					}
					if (ele.text !== undefined) {
						blogText = ele.text + '\n';
					}
					if (ele.text !== undefined) {
						blogTitle = ele.text + '\n';
					}
					text += para + blogText + blogTitle;
				});
				break;
			case 'content':
				outputObj.forEach(ele => {
					text += ele.improvedContent + '\n';
				});
				break;
			case 'headline':
				let cta = '';
				let desc = '';
				let title = '';
				outputObj.forEach(ele => {
					if (ele.cta !== undefined || ele.cta !== '') {
						cta = ele?.cta + '\n';
					}
					if (ele.description !== undefined || ele.description !== '') {
						desc = ele?.description + '\n';
					}
					if (ele.title !== undefined || ele.title !== '') {
						title = ele?.title + '\n';
					}
					text += cta + desc + title;
				});
				break;
			case 'Framework':
				outputObj.forEach(ele => {
					text += ele.text + '\n';
				});
				break;
			case 'title_description':
				let caption = '';
				let title2 = '';
				outputObj.forEach(ele => {
					if (ele.caption !== undefined) {
						caption = ele.caption + '\n';
					}
					if (ele.title2 !== undefined) {
						title2 = ele.title + '\n';
					}
					text += caption + title2;
				});
				break;
			case 'title':
			case 'description':
				let socialCta = '';
				let socialCaption = '';
				let socialTitle = '';
				let socialDescription = '';
				outputObj.forEach(ele => {
					if (ele.cta !== undefined) {
						socialCta = ele?.cta + '\n';
					}
					if (ele.caption !== undefined) {
						socialCaption = ele?.caption + '\n';
					}
					if (ele.title !== undefined) {
						socialTitle = ele?.title + '\n';
					}
					if (ele.description !== undefined) {
						socialDescription = ele?.description + '\n';
					}
					text += socialCta + socialCaption + socialTitle + socialDescription;
				});
				break;
		}
		this.clipboard.copy(text);
		template.copied = true;
		setTimeout(() => {
			template.copied = false;
		}, 3000);
	}

	public addToFav(idx: number, event: Event): void {
		event.stopPropagation();
		if (this.libraryAlteredData[idx]['isFav'] === true) {
			this.libraryAlteredData[idx]['isFav'] = false;
			if (event.target['classList'].contains('fav')) {
				event.target['classList'].remove('fav');
			}
			event.target['classList'].add('heart');
			this.removeHistoryFromFavorites(this.libraryAlteredData[idx].id);
		} else {
			this.libraryAlteredData[idx]['isFav'] = true;
			if (event.target['classList'].contains('heart')) {
				event.target['classList'].remove('heart');
			}
			event.target['classList'].add('fav');
			this.addHistoryToFavorites(this.libraryAlteredData[idx].id);
		}
		this.sortData();
	}

	public addHistoryToFavorites(id: number): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				heart_data: [{ id: id }],
				type: 'deleted_items'
			}
		};
		this.userServiceApi
			.addFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Added to favorites');
				},
				() => {},
				() => {}
			);
	}

	public removeHistoryFromFavorites(id: number): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				id: id,
				type: 'deleted_items'
			}
		};
		this.userServiceApi
			.removeFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Removed from favorites');
				},
				() => {},
				() => {}
			);
	}

	retrieveFavorites() {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				type: 'deleted_items'
			}
		};
		this.loading = true;
		this.userServiceApi
			.retrieveFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					// this.templates.forEach(item => (item.isFav = false));
					// this.templates.forEach(item => {
					// 	if (item.pro) item.isFav = true;
					// });

					let newArr = [];
					response['data']?.forEach(ele => {
						newArr.push(ele.id);
					});
					this.libraryAlteredData?.forEach(template => {
						if (newArr.includes(template.id)) {
							template.isFav = true;
						} else {
							template.isFav = false;
						}
					});
					// this.userPlanStore.dispatch(new RegisterFavoriteTemplates(response['data']));
					this.sortData();
				},
				() => {
					this.loading = false;
				},
				() => {}
			);

		// this.templateData.forEach(ele => {
		// 	if (ele.isFav) {
		// 		this.favoriteTemplates.push(ele);
		// 	}
		// });
	}

	public openCreate(event: any, data: any): void {
		event.stopPropagation();
		this.selectedTemplates.push(data.temp);
		this.copyTemplate = this.selectedTemplates;
		this.openCreateModal();
	}

	public sortData(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.libraryAlteredData) {
			if (template.isFav) {
				sortedList.unshift(template);
				// sortedList.splice(1, 0, template); // sorting only after pro-templates index
			} else {
				sortedList.push(template);
			}
		}
		this.libraryAlteredData = [...sortedList];
		this.loading = false;
	}

	public restore(id: string, event): void {
		event.stopPropagation();
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				trash_id: id
			}
		};
		this.userServiceApi
			.restoreFromTrash(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				console.log(response);
				if (response['body'].debugMessage == 'Restored to library') {
					this.toastNotificationService.sendSuccessToast('Restored');
					this.getLibraryData();
				} else {
					this.toastNotificationService.sendErrorToast('Something went wrong, please try after some time');
				}
			});
	}
}
