import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ActivationStart, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { KnowledgebaseComponent } from 'src/app/sidenav/component-pieces/knowledgebase/knowledgebase.component';
// import { UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { User } from 'src/app/_models/user-models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-knowledge-header',
	templateUrl: './knowledge-header.component.html',
	styleUrls: ['./knowledge-header.component.scss']
})
export class KnowledgeHeaderComponent implements OnInit {
	@ViewChild(RouterOutlet) outlet: RouterOutlet;
	@Input() public leftBackButton = false;
	@Input() public rightActionButton = false;
	@Input() public showAvatar = false;

	public user: User;

	public unsubscriber$ = new Subject<void>();
	private filedId: number;
	activeAllRoute: string;

	constructor(
		public kbService: KnowledgebaseService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public dialogRef: MatDialogRef<KnowledgebaseComponent>,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		if (this.activatedRoute.outlet === 'primary') {
			this.kbService.knowledgebaseHomeVisited$.next(false);
			this.kbService.knowledgebaseHome$.next(true);
			this.kbService.knowledgebaseShowView$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowTickets$.next(false);
			this.kbService.knowledgebaseHomeVisited$.next(true);
			this.router.navigate([{ outlets: { 'knowledge-base-modal': 'home' } }]);
		}

		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((event: RouterEvent) => {
			this.activeAllRoute = event.url;
		});
	}

	public backToHome(): void {
		if (this.kbService.searchQuery$.value.length > 0 && this.kbService.knowledgebaseShowFrame$.value) {
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
		} else if (this.kbService.knowledgebaseShowFrame$.value) {
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowView$.next(true);
		} else if (this.kbService.knowledgebaseShowSub$.value) {
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowView$.next(true);
		} else {
			this.router.navigate([{ outlets: { 'knowledge-base-modal': 'home' } }]);
			this.kbService.knowledgebaseHome$.next(true);
			this.kbService.knowledgebaseShowView$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowTickets$.next(false);
			this.kbService.knowledgebaseHomeVisited$.next(true);
			this.kbService.articleDataValue$.next([]);
			this.kbService.searchDataValue$.next([]);
			this.kbService.searchValue$.next('');
			this.kbService.frameIndexValue$.next(0);
			this.kbService.searchQuery$.next('');
		}
	}

	public closeModal(): void {
		const fullStringToArr = this.activeAllRoute?.split('');
		const findIndex = fullStringToArr?.findIndex(item => {
			return item === '(';
		});
		if (findIndex) {
			const sliceOther = fullStringToArr?.slice(0, findIndex);
			const getActiveRoute = sliceOther?.join('');
			console.log(getActiveRoute);
			this.router.navigate([{ outlets: { 'knowledge-base-modal': null } }]).then(() => {
				// this.router.navigate([getActiveRoute]);
				this.dialogRef.close();
			});
			this.dialogRef.afterClosed().subscribe(() => {
				this.router.navigate([getActiveRoute]);
			});
		} else {
			this.dialogRef.close();
		}
	}
}
