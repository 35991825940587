<div class="cancel-container" *ngIf="data.type == 'why-options'">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title">Why are you canceling?</p>
		<p class="sub-title">Be honest, we can take it.</p>
		<div class="radio-grp">
			<form [formGroup]="whyForm">
				<input type="radio" id="r1" formControlName="why" value="Too Expensive" checked="true" />
				<label for="r1">Too Expensive</label>
				<br />
				<input type="radio" id="r2" formControlName="why" value="Found a Better Option" />
				<label for="r2">Found a Better Option</label>
				<br />
				<input type="radio" id="r3" formControlName="why" value="Too Complicated" />
				<label for="r3">Too Complicated</label>
				<br />
				<input type="radio" id="r4" formControlName="why" value="Did Not Find the Product Useful" />
				<label for="r4">Did Not Find the Product Useful</label>
				<br />
				<input type="radio" id="r5" formControlName="why" value="Other" />
				<label for="r5">Other</label>
			</form>
		</div>
	</div>
	<div class="footer">
		<button class="mind" (click)="goToStep1()">Back</button>
		<button class="no" (click)="proceedToCancel()">Next</button>
	</div>
</div>

<div class="cancel-container" *ngIf="data.type == 'offer'">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title-stick">
			Stick around and save. How's
			<span>50% off</span>
			sound?
		</p>
		<div class="box">
			<p class="title">
				Save
				<span>50%</span>
				on your
				<br />
				next subscription renewal
			</p>
			<button (click)="acceptOffer('offer-accepted')">Accept this offer</button>
		</div>
	</div>
	<div class="footer">
		<button class="mind" (click)="changeStepType('why-options')">Back</button>
		<button class="no" (click)="cancelStep('cancel-confirm')">Next</button>
	</div>
</div>
