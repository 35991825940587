import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
// import { LoginWelcomePopupComponent } from 'src/app/accounts/components/component-pieces/login-welcome-popup/login-welcome-popup.component';
import { LogIn } from '../../state/authentication.action';

@Component({
	selector: 'app-payment-confirm-popup',
	templateUrl: './payment-confirm-popup.component.html',
	styleUrls: ['./payment-confirm-popup.component.scss']
})
export class PaymentConfirmPopupComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<PaymentConfirmPopupComponent>, private store: Store, @Inject(MAT_DIALOG_DATA) public dialogData: any) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.actionEvent();
		}, 6000);
	}

	public actionEvent(): void {
		this.dialogRef.close();
		this.store.dispatch(new LogIn());
	}
}
