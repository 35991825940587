import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	private unsubscriber$ = new Subject<void>();

	constructor(private http: HttpClient) {}

	public getAllNotifications(pageSize: number = 10, pageNumber: number = 1): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Subscription}Notification/list?pageSize=${pageSize}`);
	}

	// public deleteNotification(notificationId: number): Observable<any> {
	// 	return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}notifications/${notificationId}`);
	// }

	// public muteNotification(notificationType: string): Observable<any> {
	// 	return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}notifications/settings/${notificationType}`, {});
	// }

	// public unmuteNotification(notificationType: string): Observable<any> {
	// 	return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}notifications/settings/${notificationType}`, {});
	// }

	public readNotifications(Id: any): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.Subscription}Notification/mark/read/${Id}`, {});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
