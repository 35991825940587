import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import { ShakespeareCommonAction, ShakespeareCommonActionTypes } from './shakespeare-common-action';

export interface TemplateState {
	templateData: any;
}

export interface UserPlanState {
	amount: string;
	autoRenew: string;
	currency: string;
	from: string;
	interval: string;
	lastInvoiceId: string;
	planName: string;
	status: string;
	to: string;
}

export const TemplateCommonState = {
	templateData: null
};

export const UserPlanCommonState: UserPlanState = {
	amount: null,
	autoRenew: null,
	currency: null,
	from: null,
	interval: null,
	lastInvoiceId: null,
	planName: null,
	status: null,
	to: null
};

export function ShakespeareCommonReducer(state: any, action: ShakespeareCommonAction) {
	switch (action.type) {
		case ShakespeareCommonActionTypes.LoadAdCopyData:
		case ShakespeareCommonActionTypes.LoadEmailData:
		case ShakespeareCommonActionTypes.LoadDescriptionData:
		case ShakespeareCommonActionTypes.LoadBlogData:
		case ShakespeareCommonActionTypes.LoadContentData:
		case ShakespeareCommonActionTypes.LoadSocialData:
		case ShakespeareCommonActionTypes.LoadFrameworkData:
		case ShakespeareCommonActionTypes.LoadWizardData: {
			return {
				...state,
				templateData: action.payload
			};
		}
		case ShakespeareCommonActionTypes.RegisterUserPlan: {
			return {
				...state,
				UserPlanCommonState: action.payload
			};
		}
		case ShakespeareCommonActionTypes.RegisterUserDetails: {
			return {
				...state,
				UserDetailsCommonState: action.payload
			};
		}
		case ShakespeareCommonActionTypes.MostUsedTemplates: {
			return {
				...state,
				MostUsedTemplates: action.payload
			};
		}
		case ShakespeareCommonActionTypes.RecentProjects: {
			return {
				...state,
				RecentProjects: action.payload
			};
		}
		case ShakespeareCommonActionTypes.FavoriteTemplates: {
			return {
				...state,
				FavoriteTemplates: action.payload
			};
		}
		case ShakespeareCommonActionTypes.AllPlansData: {
			return {
				...state,
				AllPlans: action.payload
			};
		}
		default:
			return state;
	}
}

const getTemplateCommonSelector = createFeatureSelector<TemplateState>('shakespeare');

const getUserCommonSelector = createFeatureSelector<UserPlanState>('shakespeare');

export const getAdCopyHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getEmailHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getDesciptionHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getBlogHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getContentHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getSocialHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getFrameworkHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getWizardHistory = createSelector(getTemplateCommonSelector, state => _.cloneDeep(state));

export const getUserPlanDetails = createSelector(getUserCommonSelector, state => _.cloneDeep(state));

export const getUserDetails = createSelector(getUserCommonSelector, state => _.cloneDeep(state));

export const getMostUsedTemplates = createSelector(getUserCommonSelector, state => _.cloneDeep(state));

export const getRecentProjects = createSelector(getUserCommonSelector, state => _.cloneDeep(state));

export const getFavoriteTemplates = createSelector(getUserCommonSelector, state => _.cloneDeep(state));

export const getAllPlansData = createSelector(getUserCommonSelector, state => _.cloneDeep(state));
