import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { TemplateState } from 'src/app/state/shakespeare-common-state/shakespeare-common-reducer';

@Component({
	selector: 'app-generator-history',
	templateUrl: './generator-history.component.html',
	styleUrls: ['./generator-history.component.scss']
})
export class GeneratorHistoryComponent implements OnInit {
	@Input() public formData: object;
	@Input() public template: object;
	@Output() public callbackData = new EventEmitter<any>();
	@Output() public isDataFound = new EventEmitter<any>();
	// @Output() public isDataFetched = new EventEmitter<boolean>();
	public generatedData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	private unsubscriber$ = new Subject<void>();
	public heartClass = 'far fa-heart';
	public filledHeartClass = 'fas fa-heart filled';
	public filedId: number;
	public historyData: any;
	public category: string;
	public isAdCopyData: boolean;
	public isEmailData: boolean;
	public isDescriptionData: boolean;
	public isBlogData: boolean;
	public isContentData: boolean;
	public isSocialData: boolean;
	public isFrameworkData: boolean;

	public copyHelper = '';
	public isCopied = false;
	public currentCopiedEl: number;
	public fullBlogCase = false;
	public blogType: string;

	public templateName = '';
	isVideoData: boolean;
	isSeoData: boolean;
	isBioData: boolean;

	constructor(
		private userServiceApi: UserServiceApi,
		public toastNotificationService: ToastNotificationService,
		private userStore: Store<UserState>,
		private templateStore: Store<TemplateState>
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
	}

	ngOnChanges(): void {}

	ngOnInit(): void {
		let url = window.location.href;
		let urlArray = url.split('/');
		this.fullBlogCase = url.split('=')[1] == '21' ? true : false;
		this.category = urlArray[urlArray.length - 1].split('?')[0]; // getting last part of url without any queryParams
		let templateName = this.template['title'];
		this.templateName = templateName;

		switch (this.category) {
			case 'ad-copy':
				this.getAdCopyHistory(templateName);
				break;
			case 'email':
				this.getEmailHistory(templateName);
				break;
			case 'product-description':
				this.getDescriptionHistory(templateName);
				break;
			case 'blog':
				this.getBlogHistory(templateName);
				break;
			case 'content':
				this.getContentHistory(templateName);
				break;
			case 'social':
				this.getSocialHistory(templateName);
				break;
			case 'framework':
				this.getFrameworkHistory(templateName);
				break;
			case 'video':
				this.getVideoHistory(templateName);
				break;
			case 'essay':
				this.getEssayHistory(templateName);
				break;
			case 'seo':
				this.getSeoHistory(templateName);
				break;
			case 'bio':
				this.getBioHistory(templateName);
				break;
			default:
				break;
		}
	}

	public getHistory(name: string): void {
		let feedbackData = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				template_name: name
			}
		};
		this.userServiceApi
			.getTemplateHistory(feedbackData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						if (response['body'].debugMessage == 'template history not found') {
							this.isDataFound.emit(false);
							return;
						} else {
							this.isDataFound.emit(true);
						}
						this.historyData = response['body'].data;
						this.historyData?.forEach(ele => (ele.isLiked = false));
						this.historyData?.forEach(ele => (ele.isDisliked = false));
						this.callbackData.emit(response['body'].data);
						if (this.historyData) {
							this.sortData();
						}
					}
				},
				() => {},
				() => {}
			);
	}

	public getAdCopyHistory(name: string): void {
		this.getHistory(name);
		this.isAdCopyData = true;
	}

	public getEmailHistory(name: string): void {
		this.getHistory(name);
		this.isEmailData = true;
	}

	public getDescriptionHistory(name: string): void {
		this.getHistory(name);
		this.isDescriptionData = true;
	}

	public getBlogHistory(name: string): void {
		if (this.formData) {
			this.blogType = this.formData['data']['userInput']['blog_type'];
		}
		this.getHistory(name);
		this.isBlogData = true;
	}

	public getEssayHistory(name: string): void {
		if (this.formData) {
			this.blogType = this.formData['data']['user_input']['essay_type'];
		}
		this.getHistory(name);
		this.isBlogData = true;
	}

	public getSeoHistory(name: string): void {
		this.getHistory(name);
		this.isSeoData = true;
	}

	public getBioHistory(name: string): void {
		this.getHistory(name);
		this.isBioData = true;
	}

	public getContentHistory(name: string): void {
		this.getHistory(name);
		this.isContentData = true;
	}

	public getSocialHistory(name: string): void {
		this.getHistory(name);
		this.isSocialData = true;
	}

	public getFrameworkHistory(name: string): void {
		this.getHistory(name);
		this.isFrameworkData = true;
	}

	public getVideoHistory(name: string): void {
		this.getHistory(name);
		this.isVideoData = true;
	}

	getBlogTypeResult(type: string, response: any) {
		// this type is blog_type word.toLowerCase() of selected template
		switch (type) {
			case 'blog':
				return response['body']['data'];
			case 'paragraph':
				return response['body']['data']['paragraphs'];
			case 'outline':
				return response['body']['data']['outline'];
			case 'conclusion':
				return response['body']['data']['conclusion'];
			case 'headlines':
				return response['body']['data']['title'];
			case 'idea':
				return response['body']['data']['blogIdeas'];
			case 'introduction':
				return response['body']['data']['introduction'];
			default:
				break;
		}
	}

	public toObject(array: []) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	public addToFav(idx: number): void {
		if (this.historyData[idx]['isFav'] === true) {
			this.historyData[idx]['isFav'] = false;
		} else {
			this.historyData[idx]['isFav'] = true;
		}
		console.log(this.historyData);
		this.callbackData.emit(this.historyData);
		this.updateToHistory(this.historyData[idx], this.template['title']);
		this.sortData();
	}

	public updateToHistory(data: any, name: string): void {
		// delete data.id;
		let feedbackData = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				template_data: [data],
				template_name: name
			}
		};
		this.userServiceApi
			.addTemplateHistory(feedbackData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						console.log(response);
					}
				},
				() => {},
				() => {}
			);
	}

	public addToLiked(id: string, item: any, idx: number): void {
		if (this.historyData[idx]['isLiked'] === true) {
			this.historyData[idx]['isLiked'] = false;
		} else {
			this.historyData[idx]['isLiked'] = true;
			this.historyData[idx]['isDisliked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			if (!temp.hasOwnProperty('data')) {
				temp['data'] = {};
			}
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'like'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userInput: {},
					userTemplate: {},
					userOutput: temp['data']['userOutput']
				}
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public addToDisliked(id: string, item: any, idx: number): void {
		if (this.historyData[idx]['isDisliked'] === true) {
			this.historyData[idx]['isDisliked'] = false;
		} else {
			this.historyData[idx]['isDisliked'] = true;
			this.historyData[idx]['isLiked'] = false;
		}
		this.copyWithStyle(id).then(() => {
			let temp = Object.assign({}, this.formData);
			if (!temp.hasOwnProperty('data')) {
				temp['data'] = {};
			}
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'dislike'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					userInput: '',
					userTemplate: '',
					userOutput: temp['data']['userOutput']
				}
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public sortData(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.historyData) {
			if (template?.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.historyData = [...sortedList];
	}

	public async copyWithStyle(element: any): Promise<void> {
		const text = <HTMLElement>document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);

			selection.removeAllRanges();
			selection.addRange(range);
		}
		document.execCommand('copy');
		this.copyHelper = await navigator.clipboard.readText();
		window.getSelection().removeAllRanges();
	}

	public addToCopied(id: string, item: any, index: number): void {
		this.copyWithStyle(id).then(() => {
			this.isCopied = true;
			this.currentCopiedEl = index;
			setTimeout(() => {
				this.isCopied = false;
			}, 3000);
			let temp = Object.assign({}, this.formData);
			temp['data']['userOutput'] = {
				outputText: this.copyHelper,
				action: 'copy'
			};
			let feedbackData = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: temp['data']
			};
			this.userServiceApi
				.addLikedFeedback(feedbackData)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {},
					() => {},
					() => {}
				);
		});
	}

	public delete(id: string, item: any) {
		let feedbackData = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				id: item.id,
				template_name: this.templateName
			}
		};
		this.userServiceApi.deleteTemplateHistoryItem(feedbackData).subscribe(res => {
			this.getHistory(this.templateName);
			this.callbackData.emit(this.historyData);
		});
	}
}
