import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditorService } from 'src/app/shakespeare/ads/facebook/editor/editor.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { Store, select } from '@ngrx/store';
import { UserState, getFiledId } from 'src/app/shared/state/user/user.reducer';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
@Component({
	selector: 'app-company-details-popup',
	templateUrl: './company-details-popup.component.html',
	styleUrls: ['./company-details-popup.component.scss']
})
export class CompanyDetailsPopupComponent implements OnInit {
	public companyForm: FormGroup;
	private unsubscriber$ = new Subject<void>();
	industryControl = new FormControl();
	companyError: boolean = false;
	companyDomainError: boolean;
	invalidDomain: boolean;
	logoError: boolean;
	backgroundImage: string;
	loadingCanvas: boolean;
	logoSkeleton: boolean = false;
	filedId: number;
	showLogoContainer: boolean = false;
	industryArr: any = [
		{ name: 'E-commerce' },
		{ name: 'Real Estate' },
		{ name: 'Education' },
		{ name: 'Travel and Tourism' },
		{ name: 'Finance' },
		{ name: 'Automotive' },
		{ name: 'Beauty and Personal Care' },
		{ name: 'Food and Beverage' },
		{ name: 'Technology' },
		{ name: 'Home and Garden' },
		{ name: 'Sports and Fitness' },
		{ name: 'Entertainment' },
		{ name: 'Business Services' },
		{ name: 'Home Services' },
		{ name: 'Legal Services' },
		{ name: 'Non-Profit Organizations' },
		{ name: 'Pet Care' },
		{ name: 'Fashion and Apparel' },
		{ name: 'Art and Culture' },
		{ name: 'B2B Services' },
		{ name: 'Industrial and Manufacturing' },
		{ name: 'Marketing' },
		{ name: 'Software' }
	];
	constructor(
		public dialogRef: MatDialogRef<CompanyDetailsPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private fb: FormBuilder,
		private editorService: EditorService,
		private userStore: Store<UserState>,
		private router: Router
	) {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(fileId => {
			this.filedId = fileId;
		});
	}

	ngOnInit(): void {
		this.initCompanyForm();
	}
	public initCompanyForm() {
		this.companyForm = this.fb.group({
			industry: new FormControl('', [Validators.required]),
			companyName: new FormControl('', [Validators.required]),
			domain: new FormControl('', [Validators.required])
		});
	}
	industryResult(event: any) {
		console.log(this.industryControl.value.name);
	}
	saveBranding() {
		let pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
		let value = false;
		if (pattern.test(this.companyForm.controls.domain.value)) {
			value = false;
		} else {
			value = true;
		}
		if (this.companyForm.controls.companyName.value == '') {
			this.companyError = true;
			return;
		} else if (this.companyForm.controls.domain.value == '') {
			this.companyError = false;
			this.companyDomainError = true;
			return;
		} else if (value) {
			this.invalidDomain = true;
			this.companyDomainError = false;
			this.logoError = false;
			return;
		} else if (this.backgroundImage == '' || !this.backgroundImage) {
			this.companyDomainError = false;
			this.logoError = true;
			this.invalidDomain = false;
			return;
		} else if (this.industryControl.value.name == '') {
			this.companyError = true;
			return;
		} else {
			this.logoError = false;
			this.companyError = false;
			this.invalidDomain = false;
			this.companyDomainError = false;
			const param = {
				user: {
					user_filed_id: this.filedId
				},
				data: {
					logo: this.backgroundImage,
					industry: this.industryControl.value.name,
					company_name: this.companyForm.controls.companyName.value,
					company_domain: this.companyForm.controls.domain.value,
					color: {
						primary: '#FFFFFF',
						secondary: '#FFFFFF'
					}
				}
			};

			this.editorService.saveBranding(param).subscribe(res => {
				this.getBranding();
				// this.toastNotificationService.sendSuccessToast('Company Details Updated!');
			});
		}
	}
	getBranding() {
		this.editorService.getBranding().subscribe(res => {
			if (res.data) {
				this.dialogRef.close();
				this.companyForm.controls.companyName.setValue(res.data.companyName);
				this.companyForm.controls.domain.setValue(res.data.companyDomain);
				this.loadingCanvas = true;
				this.backgroundImage = res.data.logo;
				// this.primaryColor = res.data.color.primary;
				// this.secondaryColor = res.data.color.secondary;
				if (res?.data?.companyName) {
					this.companyForm.patchValue({
						companyName: res.data.companyName.trim()
					});
				} else {
					this.companyForm.patchValue({
						companyName: ''
					});
				}

				if (res?.data?.companyDomain) {
					this.companyForm.patchValue({
						domain: res.data.companyDomain.trim()
					});
				} else {
					this.companyForm.patchValue({
						domain: ''
					});
				}

				// this.companyForm.controls.primarycolor.setValue('res.data.color.primary');
				// this.companyForm.controls.secondarycolor.setValue('res.data.color.secondary');
				let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
				if (token && token.sign_up_version && token.sign_up_version == 'v2') {
					if (
						res.data.color == undefined ||
						res.data.color.primary == undefined ||
						res.data.color.secondary == undefined ||
						res.data.companyName == undefined ||
						res.data.logo == undefined
					) {
						this.companyForm.get('companyName').enable();
						this.companyForm.get('domain').enable();
					}
				}
			}
		});
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate([], {
			queryParamsHandling: 'merge'
		});
	}

	companyInpChange() {
		this.companyError = false;
	}
	domainInpChange() {
		this.companyDomainError = false;
	}

	public uploadFile(event: Event): void {
		this.logoSkeleton = true;
		this.showLogoContainer = true;
		this.backgroundImage = '';
		const element = event.currentTarget as HTMLInputElement;
		let fileList: FileList | null = element.files;
		let imgURl: string;
		if (fileList) {
			const img = fileList[0];
			this.uploadBackground(fileList);
			this.logoError = false;
		}
	}

	public uploadBackground(fileList: any): void {
		//this.loadingCanvas = true;

		let token: any = localStorage.getItem('decodedJwtIo');
		token = JSON.parse(token);
		let userFieldId = token.user_filed_id;

		if (fileList) {
			const formData = new FormData();
			formData.append('file', fileList[0]);
			let body = {
				user: {
					user_filed_id: userFieldId
				}
			};
			formData.append('body', JSON.stringify(body));
			this.editorService.uploadBGImage(formData).subscribe(
				(res: any) => {
					this.loadingCanvas = true;
					this.backgroundImage = res?.imageUrl;
					this.updateBackgroundImage(res?.imageUrl);
					// this.emitBackground();
				},
				err => {
					//this.loadingCanvas = false;
					// this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		}
	}
	public updateBackgroundImage(image: string): void {
		this.backgroundImage = image;
		this.logoSkeleton = false;
	}
}
