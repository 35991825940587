<div class="popup-container">
	<div>
		<div class="close-block">
			<mat-icon mat-dialog-close class="close-btn">close</mat-icon>
		</div>
		<div class="header">
			<div class="header__head">
				Welcome to Shakespeare AI
			</div>
			<div class="header__head">
				Let us show you around
			</div>

			<div class="header__sub">
				Unlock the full potential of your marketing campaigns and take them to new heights.
			</div>
		</div>
	</div>

	<div class="img-container">
		<img src="assets/tour-logo.svg" alt="shakespeare-log" />
	</div>
	<div class="divider">
		<mat-divider></mat-divider>
	</div>
	<div class="footer">
		<div class="text">
			1/6
		</div>
		<div class="btn-container">
			<button class="btn-outline" mat-dialog-close>Skip</button>
			<button class="btn-fill" mat-dialog-close (click)="start()">Start Tour</button>
		</div>
	</div>
</div>
