<div class="bonus-offer" *ngIf="isBonus && showBonus">
	<div class="con">
		<div class="skew">
			<p class="text">Limited Time Offer</p>
		</div>
	</div>
	<div class="button-con">
		<p class="center-text">
			Upgrade Now
			<span>Get 85% off On PRO Plan for only $9</span>
		</p>
		<button (click)="navigateToBilling()">
			View Offer
			<em class="fas fa-arrow-right"></em>
		</button>
	</div>
</div>
<div class="scroll-wrapper" [class.iphone-css]="iPhoneMobile">
	<div class="parent" [ngClass]="{ expanded: menuExpanded, loading: loading }" [class.iphone-css]="iPhoneMobile">
		<div *ngIf="loading" [ngStyle]="{ width: '100%', height: '100%' }">
			<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
		</div>
		<div class="container" *ngIf="!loading">
			<div class="header-wrapper">
				<div class="header">
					<h2>
						{{ labelMapper[currentType] }}
						<app-tooltip large="true" static="true" openModal="true" link="Learn more" [text]="tooltipText"></app-tooltip>
					</h2>
					<div class="search">
						<app-search-input
							[control]="searchCtrl"
							(input)="searchTemplate($event.target.value)"
							inputPlaceholder="Search by name"
						></app-search-input>
					</div>
				</div>
			</div>
			<div class="slider" *ngIf="innerWidth < 541 && favoriteTemplates.length > 0">
				<owl-carousel-o [options]="customOptions">
					<ng-template carouselSlide *ngFor="let item of favoriteTemplates">
						<div class="slide" (click)="generateData(item.id)" [ngClass]="{ disabled: (item.id == 41 || item.id == 44) && !isProUser }">
							<img [src]="item.img" />
						</div>
						<div class="slider-title">
							{{ item.title.split(' ').length > 2 ? item.title.split(' ').slice(0, 2).join(' ') + '...' : item.title }}
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="body-con">
				<div
					*ngFor="let template of templateData; let i = index"
					[ngClass]="{
						isFavCard: template.isFav,
						'card-disabled': (template.id == 41 || template.id == 44) && !isProUser,
						'pro-card': template?.pro
					}"
					class="card"
					(click)="generateData(template.id)"
				>
					<img width="12%" [src]="template.img" />
					<div class="about">
						<div class="title">
							<span>{{ template.title }}</span>
							<span class="pro-template" *ngIf="template.pro">Pro</span>
						</div>
						<p class="sub-title">{{ template.sub }}</p>
					</div>
					<em
						(click)="addToFav(i, $event, template.id)"
						[matTooltip]="template.isFav ? 'Remove from favorite' : 'Add to favorite'"
						[matTooltipPosition]="'above'"
						[ngClass]="template?.isFav ? 'fav' : 'icon-heart'"
					></em>
				</div>
			</div>
			<div class="no-template-section" *ngIf="templateData.length === 0 && noTemplatesFound">
				<h2 class="no-templates">No templates found matching your search input!</h2>
			</div>
		</div>
	</div>
</div>
