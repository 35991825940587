<input
	(keyup)="keyInputEvent.emit($event)"
	class="input-text {{ styleClass }}"
	[maxlength]="maxLength"
	[id]="inputId"
	[type]="inputType"
	enterkeyhint="done"
	[formControl]="control"
	[placeholder]="inputPlaceholder"
/>
<!-- <div class="error-container" *ngIf="errorMessage && control.invalid && control.touched"> -->
<div class="error-container" *ngIf="errorMessage && control.invalid && (control.dirty || control.touched)">
	<span>{{ errorMessage }}</span>
</div>
