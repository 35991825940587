<div class="modalContainer" [class.iphone-css]="iPhoneMobile">
	<div class="header">
		<i class="k-icon k-i-close close" (click)="closeDialog()"></i>
	</div>
	<div class="body" *ngIf="!data.createOnly">
		<mat-tab-group>
			<mat-tab label="Create New">
				<div class="text">
					{{ modelDetail?.title == 'folder' ? 'Enter Folder Name' : 'Enter Project Name' }}
				</div>
				<div class="input">
					<input type="text" class="custom-input" placeholder="Enter here" [ngClass]="{ 'error-input': error }" [(ngModel)]="name" />
					<div class="btns">
						<app-button buttonClass="link-tertiary" (onClick)="closeDialog()">CANCEL</app-button>
						<app-button buttonClass="primary" (onClick)="onSubmit()">CREATE</app-button>
						<!-- <button class="btn-gcb-04 action-button cancel" (click)="closeDialog()">Cancel</button>
						<button class="btn-gcb-04 action-button save-btn" (click)="onSubmit()">Create</button> -->
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Add to existing" *ngIf="data.existing">
				<div class="text">
					{{ modelDetail?.title == 'folder' ? 'Select Folder' : 'Select Project' }}
				</div>
				<div class="input">
					<app-custom-drpdown-op1
						class="max-output-drp"
						[data]="foldersArray"
						[useCustom]="false"
						(selectionChange)="onChange($event)"
						[hideClearButton]="true"
						[dropdownFormControl]="projectForm?.get('project')"
						[placeHolder]="modelDetail.title == 'folder' ? 'Select Folder' : 'Select Project'"
						[noDataFoundLabel]="'No data found'"
					></app-custom-drpdown-op1>
					<div class="btns">
						<app-button buttonClass="link-tertiary" (onClick)="closeDialog()">CANCEL</app-button>
						<app-button buttonClass="primary" (onClick)="onSubmitExisting()">ADD</app-button>
						<!-- <button class="btn-gcb-04 action-button cancel" (click)="closeDialog()">Cancel</button>
						<button class="btn-gcb-04 action-button add-btn" (click)="onSubmitExisting()">Add</button> -->
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>

	<div class="body" *ngIf="data.createOnly">
		<h2>Create Folder</h2>
		<div class="text">
			Enter Folder Name
		</div>
		<div class="input">
			<input type="text" class="custom-input" placeholder="Enter here" [ngClass]="{ 'error-input': error }" [(ngModel)]="name" />
			<div class="btns">
				<app-button buttonClass="link-tertiary" (onClick)="closeDialog()">CANCEL</app-button>
				<app-button buttonClass="primary" (onClick)="onSubmit()">CREATE</app-button>
				<!-- <button class="btn-gcb-04 action-button cancel" (click)="closeDialog()">Cancel</button>
				<button class="btn-gcb-04 action-button save-btn" (click)="onSubmit()">Create</button> -->
			</div>
		</div>
	</div>
</div>
