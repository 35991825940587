import { ActivatedRoute, Params, Router } from '@angular/router';
import { CreateNewModalComponent } from './../../shared/create-new-modal/create-new-modal.component';
import { TEMPLATES } from './../../shared/models/templates.const';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ViewTemplateComponent } from '../view-template/view-template.component';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import moment from 'moment';
import { Location } from '@angular/common';
import { ShakespeareService } from '../shakespeare.service';

@Component({
	selector: 'app-project-data',
	templateUrl: './project-data.component.html',
	styleUrls: ['./project-data.component.scss']
})
export class ProjectDataComponent implements OnInit {
	public searchCtrl = new FormControl('');
	@ViewChild('editorControl') public editorControl: ElementRef;
	public isEditing: string = null;
	public editedValue = null;
	@ViewChild(SelectContainerComponent) selectContainer: SelectContainerComponent;
	public checkForm: FormGroup = new FormGroup({});
	public libraryData = [];
	public heartClass = 'far fa-heart';
	public filledHeartClass = 'fas fa-heart filled';
	public selectedTemplates: any;
	public selectedItemTemplates: any[] = [];

	public blogTitleMapper = {
		blog: 'Full Blog',
		headlines: 'Blog Headlines',
		introduction: 'Blog Introduction',
		conclusion: 'Blog Conclusion',
		paragraph: 'Blog Paragraph',
		outline: 'Blog Outline',
		ideas: ' Blog Ideas'
	};

	public essayTitleMapper = {
		essay: 'Full Essay',
		headlines: 'Essay Headlines',
		introduction: 'Essay Introduction',
		conclusion: 'Essay Conclusion',
		paragraph: 'Essay Paragraph',
		outline: 'Essay Outline',
		ideas: ' Essay Ideas'
	};

	public dataLen = 0;
	public isFooter = false;
	public checkedCount = 0;
	public filedId: number;
	private unsubscriber$ = new Subject<void>();
	private pageNumber = 1;
	public libraryAlteredData = [];
	public folderLibraryAlteredData = [];

	public selectedList = [];
	public templates = [...TEMPLATES];
	public fullBlogCase: boolean;
	public blogType: string;
	public copyHelper: string;
	public isCopied: boolean;
	public projectId: string;
	public folderId: string;
	public projectsData = [];
	public selectedProject: any;
	public scrWidth: number;
	loading: boolean;
	iPhoneMobile: boolean;
	@HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
		this.scrWidth = window.innerWidth;
	}
	public innerWidth: any;
	public foldersArray = [];
	public folderNameCtrl = new FormControl('');

	public hideProject = false;
	public folderName = '';
	public selectedFolder = [];
	public selectedFolderData = [];
	private backupLibrary: any[];
	public notFound: boolean;

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private userStore: Store<UserState>,
		public toastNotificationService: ToastNotificationService,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private router: Router,
		private shakespeareService: ShakespeareService
	) {
		this.getScreenSize();
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.projectId = params.projectId;
			this.folderId = params.folderId;
			this.folderName = params.folderName;

			if (this.folderId !== undefined) {
				this.hideProject = true;
			} else {
				this.hideProject = false;
			}
		});
	}

	ngOnInit(): void {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		// if (!this.hideProject) {
		this.getProjectsData();
		// } else {
		this.getFolderData();
		// }

		this.innerWidth = window.innerWidth;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	public getProjectsData(): void {
		this.loading = true;
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			}
		};
		this.userServiceApi
			.getUserProjects(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.projectsData = [...response['body']['projects']];
					this.findProject();
					this.loading = false;
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	public deleteOutput(event: Event, outputId: string): void {
		event.stopPropagation();
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				projectId: this.projectId,
				outputId: outputId
			}
		};
		this.userServiceApi
			.deleteOutputFromProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.loading = false;
					const found = this.selectedProject?.inputOutputData?.findIndex(ele => ele.id === outputId);
					this.selectedProject?.inputOutputData.splice(found, 1);
					this.libraryAlteredData = [...this.selectedProject?.inputOutputData];
					this.toastNotificationService.sendSuccessToast('Output removed from project');
					// this.initForm();
					this.changeDetection();
					if (this.libraryAlteredData.length == 0) {
						this.isFooter = false;
						this.checkedCount = 0;
					}
				},
				() => {
					// this.loading = false;
					this.toastNotificationService.sendErrorToast('Something went wrong, please try later');
				},
				() => {}
			);
	}

	public deleteOutputFromFolder(event: Event, outputId: string): void {
		event.stopPropagation();
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				project_id: this.projectId,
				folder_id: this.folderId,
				user_input_output_data_id: outputId
			}
		};
		this.userServiceApi
			.deleteFromFolder(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.loading = false;
					const found = this.selectedFolder[0]?.inputOutputData?.findIndex(ele => ele.id === outputId);
					this.selectedFolder[0]?.inputOutputData.splice(found, 1);
					this.folderLibraryAlteredData = [...this.selectedFolder[0]?.inputOutputData];
					this.toastNotificationService.sendSuccessToast('Output removed from project');
					// this.initForm();
					this.changeDetection();
					if (this.libraryAlteredData.length == 0) {
						this.isFooter = false;
						this.checkedCount = 0;
					}
				},
				() => {
					// this.loading = false;
					this.toastNotificationService.sendErrorToast('Something went wrong, please try later');
				},
				() => {}
			);
	}

	public findProject(): void {
		this.selectedProject = this.projectsData.find(item => {
			return item.id === this.projectId;
		});
		this.dataLen = this.selectedProject?.inputOutputData.length;
		this.foldersArray = this.selectedProject?.folder;
		this.foldersArray?.forEach(ele => {
			ele.isFav = false;
			ele.showInput = false;
			ele.renamed = false;
		});
		this.retrieveFavorites('folders');

		this.initForm();
		this.changeDetection();
		setTimeout(() => {
			this.mappingHelper();
		}, 1000);
		this.sortData();
	}

	public changeDetection(): void {
		this.checkForm.valueChanges.subscribe(() => {
			this.checkedCount = 0;
			let flag = false;
			for (const key of Object.keys(this.checkForm.controls)) {
				// check if any one value true, then make footer true and count all checked boxes
				if (this.checkForm.controls[key].value) {
					flag = true;
					this.checkedCount++;
				}
				this.isFooter = flag;
			}
		});
	}

	public viewTemplate(template): void {
		const isMobile = this.scrWidth <= 900;
		let tempConfig = {};
		if (isMobile) {
			tempConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '100vw'
			};
		} else {
			tempConfig = {
				width: '50%',
				height: '75%'
			};
		}
		const dialogRef = this.dialog.open(ViewTemplateComponent, {
			...tempConfig,
			data: { template, isMobile: isMobile }
		});
		// do something after close
	}

	public openCreateModal(): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true,
				createOnly: false
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'New Folder',
			text: 'Enter a name to save.',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.value) {
				this.saveProjectHelper(obj.value);
			}
			if (obj.id) {
				this.addToProjectHelper(obj.id);
			}
		});
	}

	public saveProjectHelper(name: string): void {
		let payload = {};
		this.selectedTemplates.forEach(template => {
			payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					name: name || 'untitled-' + moment().format(),
					userTemplate: template.data.userTemplate,
					userInput: template.data.userInput,
					userOutput: template.data.userOutput
				}
			};
			this.saveProject(payload);
		});
	}

	public addToProjectHelper(id: string): void {
		let payload = {};
		this.selectedTemplates.forEach(template => {
			payload = {
				user: {
					userFiledId: this.filedId.toString()
				},
				data: {
					id: id,
					userTemplate: template.data.userTemplate,
					userInput: template.data.userInput,
					userOutput: template.data.userOutput
				}
			};
			this.addToProject(payload);
		});
	}

	public saveProject(payload: object): void {
		this.userServiceApi
			.createUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'Project with same name exists already') {
						this.toastNotificationService.sendErrorToast('Operation failed! Project name already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Project created');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
	}

	public addToProject(payload: object): void {
		this.userServiceApi
			.addToUserProject(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response['body'].debugMessage == 'project data already existed') {
						this.toastNotificationService.sendErrorToast('Operation failed! Content already exists');
						return;
					}
					this.toastNotificationService.sendSuccessToast('Output added!');
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
	}

	public toggleControl(event: any, data: any): void {
		event.stopPropagation();
		this.selectContainer.toggleItems((xd: any) => xd?.index == data.index);
		if (this.selectedItemTemplates.length > 0) {
			const found = this.selectedItemTemplates.findIndex(ele => ele.id === data.temp.id);
			if (found >= 0) {
				this.selectedItemTemplates.splice(found, 1);
			} else {
				this.selectedItemTemplates.push(data.temp);
			}
		} else {
			this.selectedItemTemplates.push(data.temp);
		}
	}

	public onSelectTemplates(event): void {
		this.selectedTemplates = this.selectedTemplates.map(value => {
			this.checkForm.controls['val' + value.index].patchValue(true);
			return value.temp;
		});
		this.isFooter = event.length ? true : false;
		this.checkedCount = event.length;
	}

	public deselectAll(): void {
		this.selectedTemplates = [];
		this.selectContainer?.clearSelection();
		this.checkForm.reset();
	}

	public openFolderCreateModal(): void {
		let modalConfig;
		if (this.innerWidth > 424 && this.innerWidth <= 578) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '376px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 578 && this.innerWidth <= 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '476px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth > 1280) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '678px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		} else if (this.innerWidth < 420) {
			modalConfig = {
				width: '100%',
				height: '100%',
				maxWidth: '350px',
				maxHeight: '336px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CreateNewModalComponent, {
			...modalConfig,
			disableClose: true,
			data: {
				existing: true,
				createOnly: false,
				selectedProject: this.selectedProject
			},
			backdropClass: 'backdrop-background',
			panelClass: 'custom-dialog'
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'folder',
			text: 'Enter a name to save.',
			closeBtn: true,
			actionBtn: true
		};
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.value) {
				this.createFolder(obj.value);
			}
			if (obj.id) {
				this.addToFolder(obj.id);
			}
			this.foldersArray?.forEach(ele => {
				ele.showInput = false;
			});
		});
	}

	public createFolder(folderName: string): void {
		let selectedOutputs = [];
		this.selectedItemTemplates.forEach(ele => {
			selectedOutputs.push(ele.id);
		});
		const reqObj = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				project_id: this.projectId,
				input_output_id: selectedOutputs,
				name: folderName
			}
		};

		this.userServiceApi.createFolder(reqObj).subscribe((res: any) => {
			this.initForm();
			this.getProjectsData();
		});
		this.selectedItemTemplates = [];
		this.checkedCount = 0;
	}

	public addToFolder(folderId: string): void {
		let selectedOutputs = [];
		this.selectedItemTemplates.forEach(ele => {
			selectedOutputs.push(ele.id);
		});

		const reqObj = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				input_output_id: selectedOutputs,
				project_id: this.projectId,
				folder_id: folderId
			}
		};

		this.userServiceApi.addDataToFolder(reqObj).subscribe(res => {
			this.initForm();
			this.getProjectsData();
		});
		this.selectedItemTemplates = [];
		this.checkedCount = 0;
	}

	public navigateToFolderData(folder: any) {
		const queryParams: Params = { folderId: folder.folderId, folderName: folder.folderName };
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: queryParams,
			queryParamsHandling: 'merge' // remove to replace all query params by provided
		});
		this.loading = true;
		setTimeout(() => {
			this.getFolderData();
		}, 3000);
		// this.loading = false;
	}

	public getFolderData(): void {
		this.searchCtrl.setValue('');
		this.notFound = false;
		// this.initForm();
		// this.deselectAll();

		this.folderName = this.folderName;
		const reqObj = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				project_id: this.projectId,
				folder_id: this.folderId
			}
		};

		this.userServiceApi.getFolderData(reqObj).subscribe((res: any) => {
			this.selectedFolder = res.data;
			this.mappingHelper();
		});
		// const queryParams: Params = { folderId: folder['folderId'] };
		// this.router.navigate([], {
		// 	relativeTo: this.activatedRoute,
		// 	queryParams: queryParams,
		// 	queryParamsHandling: 'merge' // remove to replace all query params by provided
		// });
		// this.router.navigate([`/projects`], { queryParams: { folderId: folder['folderId'], folderName: folder['folderName'] } })
	}

	public searchTemplate(_input: string): void {
		let inputVal = _input.toLowerCase();
		this.debounce(this.filterTemplates(inputVal));
	}

	public debounce(callback, delay: number = 500) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				callback(...args);
			}, delay);
		};
	}

	public filterTemplates(value: string) {
		let temp = this.backupLibrary;
		if (value === '') {
			if (!this.hideProject) {
				temp = this.backupLibrary;
				this.libraryAlteredData = temp;
				this.notFound = false;
				return;
			} else {
				temp = this.backupLibrary;
				this.folderLibraryAlteredData = temp;
				this.notFound = false;
				return;
			}
		}
		let filteredData = temp.filter(template => {
			return template?.templateData?.title.toLowerCase().includes(value);
		});
		if (filteredData.length) {
			temp = filteredData;
			this.notFound = false;
		} else {
			temp = [];
			this.notFound = true;
		}
		if (!this.hideProject) {
			this.libraryAlteredData = temp;
		} else {
			this.folderLibraryAlteredData = temp;
		}
	}

	public initForm(): void {
		this.checkForm = this.fb.group({});
		for (let i = 0; i < this.dataLen; i++) {
			this.checkForm.addControl('val' + i, new FormControl(false));
		}
	}

	public getLibraryData(): void {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				pageNumber: this.pageNumber++
			}
		};
		this.userServiceApi
			.getHistoryData(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.libraryData = response['body']['data']['history'];
						this.dataLen = this.libraryData.length;
						this.mappingHelper();
						// this.retrieveFavorites('library');

						this.initForm();
						this.changeDetection();
					}
				},
				() => {},
				() => {
					// this.toastNotificationService.sendSuccessToast('Project saved successfully');
				}
			);
	}

	public mappingHelper(): void {
		this.loading = true;
		let selectedTemplate;
		if (!this.hideProject) {
			this.selectedProject?.inputOutputData?.forEach(item => {
				item.isFav = false;
				console.log(item);
				let filteredTemplate = this.templates.filter(template => {
					return item.userTemplate.template == template.template;
				});
				if (item.userTemplate.platform != '' || item.userTemplate.platform == 'default') {
					selectedTemplate = filteredTemplate.find(template => {
						return item.userTemplate.platform == template.platform;
					});
				} else {
					selectedTemplate = filteredTemplate[0];
				}
				item['templateData'] = selectedTemplate;
				// special case for blog templates
				if (item.userTemplate.template == 'Blog' || item.userTemplate.template == 'Blog Wizard') {
					let type = item.userInput.blogType;
					if (type == 'advanced_outline') type = 'outline';
					item['templateData'] = this.templates.find(template => {
						return this.blogTitleMapper[type] == template.title;
					});
					this.fullBlogCase = type == 'blog' ? true : false;
					// item.userOutput = this.getBlogTypeResult(type, item);
					if (typeof item.userOutput == 'object' && typeof item.userOutput[0] == 'string') {
						item.userOutput = this.toObject(item.userOutput);
					}
				}
				// special case for essay templates
				if (item.userTemplate.template == 'Essay') {
					let type = item.userInput.essayType;
					if (type == 'advanced_outline') type = 'outline';
					item['templateData'] = this.templates.find(template => {
						return this.essayTitleMapper[type] == template.title;
					});
					this.fullBlogCase = type == 'essay' ? true : false;
					// item.userOutput = this.getBlogTypeResult(type, item);
					if (typeof item.userOutput == 'object' && typeof item.userOutput[0] == 'string') {
						item.userOutput = this.toObject(item.userOutput);
					}
				}
				if (item.userTemplate.platform == 'youtube' || item.userTemplate.platform == 'tiktok') {
					selectedTemplate = this.templates.find(template => {
						return item.userInput.videoType == template.template;
					});
					item['templateData'] = selectedTemplate;
				}
				if (item.userTemplate.template == 'content') {
					selectedTemplate = this.templates.find(template => {
						return item.userInput.contentType == template.type;
					});
					item['templateData'] = selectedTemplate;
				}
				if (item.userTemplate.template == 'Bio') {
					selectedTemplate = this.templates.find(template => {
						return item.userInput.bioType == template.type;
					});
					item['templateData'] = selectedTemplate;
				}
			});
			console.log(this.selectedProject);
			this.backupLibrary = [...this.selectedProject?.inputOutputData];
			this.libraryAlteredData = [...this.selectedProject?.inputOutputData];
		} else {
			this.selectedFolder[0]?.inputOutputData?.forEach(item => {
				item.isFav = false;
				let filteredTemplate = this.templates.filter(template => {
					return item.userTemplate.template == template.template;
				});
				if (item.userTemplate.platform != '' || item.userTemplate.platform == 'default') {
					selectedTemplate = filteredTemplate.find(template => {
						return item.userTemplate.platform == template.platform;
					});
				} else {
					selectedTemplate = filteredTemplate[0];
				}
				item['templateData'] = selectedTemplate;
				// special case for blog templates
				if (item.userTemplate.template == 'Blog' || item.userTemplate.template == 'Blog Wizard') {
					let type = item.userInput.blogType;
					if (type == 'advanced_outline') type = 'outline';
					item['templateData'] = this.templates.find(template => {
						return this.blogTitleMapper[type] == template.title;
					});
					this.fullBlogCase = type == 'blog' ? true : false;
					// item.userOutput = this.getBlogTypeResult(type, item);
					if (typeof item.userOutput == 'object' && typeof item.userOutput[0] == 'string') {
						item.userOutput = this.toObject(item.userOutput);
					}
				}
			});
			this.backupLibrary = [...this.selectedFolder[0]?.inputOutputData];
			this.folderLibraryAlteredData = [...this.selectedFolder[0]?.inputOutputData];
			// console.log(this.selectedFolder[0].inputOutputData)
		}
		this.retrieveFavorites('library');

		this.loading = false;
	}

	public copyWithStyle(element): string {
		const text = document.getElementById(element);
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(text);
			selection.removeAllRanges();
			selection.addRange(range);
			this.copyHelper = window.getSelection().toString();
			// document.execCommand('copy');
			window.getSelection().removeAllRanges();
			return this.copyHelper;
		}
	}

	public toObject(array: []) {
		// add 'type' key if required in future, which will distinguish data among all blog types
		return array.map(text => ({ text }));
	}

	getBlogTypeResult(type, arg) {
		// this type is actual title's last word.toLowerCase() of selected template
		this.fullBlogCase = type == 'blog' ? true : false;
		switch (type) {
			case 'blog':
				return arg['userOutput'];
			case 'paragraph':
				return arg['userOutput']['paragraphs'];
			case 'outline':
			case 'advanced_outline':
				return arg['userOutput']['outline'];
			case 'conclusion':
				return arg['userOutput']['conclusion'];
			case 'headlines':
				return arg['userOutput']['title'];
			case 'ideas':
				return arg['userOutput']['blogIdeas'];
			case 'introduction':
				return arg['userOutput']['introduction'];
			default:
				break;
		}
	}

	public addToCopied(id: string, event: Event): void {
		event.stopPropagation();
		let data = this.copyWithStyle(id);
		navigator.clipboard.writeText(data);
		this.isCopied = true;
		setTimeout(() => {
			this.isCopied = false;
		}, 3000);
	}

	public addToFav(event: Event, idx: number, type: string): void {
		event.stopPropagation();
		if (type === 'folders') {
			if (this.foldersArray[idx]['isFav'] === true) {
				this.foldersArray[idx]['isFav'] = false;
				if (event.target['classList'].contains('fav')) {
					event.target['classList'].remove('fav');
				}
				event.target['classList'].add('heart');
				this.removeItemFromFavorites(this.foldersArray[idx].folderId, 'folders');
			} else {
				this.foldersArray[idx]['isFav'] = true;
				if (event.target['classList'].contains('heart')) {
					event.target['classList'].remove('heart');
				}
				event.target['classList'].add('fav');
				this.addItemToFavorites(this.foldersArray[idx].folderId, 'folders');
			}
			this.sortData();
		} else if (type === 'project-library') {
			if (this.selectedProject?.inputOutputData[idx]?.isFav === true) {
				this.selectedProject.inputOutputData[idx].isFav = false;
				if (event.target['classList'].contains('fav')) {
					event.target['classList'].remove('fav');
				}
				event.target['classList'].add('heart');
				this.removeItemFromFavorites(this.selectedProject?.inputOutputData[idx].id, 'library');
			} else {
				this.selectedProject.inputOutputData[idx]['isFav'] = true;
				if (event.target['classList'].contains('heart')) {
					event.target['classList'].remove('heart');
				}
				event.target['classList'].add('fav');
				this.addItemToFavorites(this.selectedProject?.inputOutputData[idx].id, 'library');
			}
			this.sortProjectData();
		} else {
			if (this.selectedFolder[0]?.inputOutputData[idx]?.isFav === true) {
				this.selectedFolder[0].inputOutputData[idx].isFav = false;
				if (event.target['classList'].contains('fav')) {
					event.target['classList'].remove('fav');
				}
				event.target['classList'].add('heart');
				this.removeItemFromFavorites(this.selectedFolder[0]?.inputOutputData[idx].id, 'library');
			} else {
				this.selectedFolder[0].inputOutputData[idx]['isFav'] = true;
				if (event.target['classList'].contains('heart')) {
					event.target['classList'].remove('heart');
				}
				event.target['classList'].add('fav');
				this.addItemToFavorites(this.selectedFolder[0]?.inputOutputData[idx].id, 'library');
			}
			this.sortFolderData();
		}
	}

	retrieveFavorites(type: string) {
		let payload = {
			user: {
				userFiledId: this.filedId.toString()
			},
			data: {
				type: type
			}
		};
		this.loading = true;
		this.userServiceApi
			.retrieveFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (type === 'folders') {
						let newArr = [];
						response['data']?.forEach(ele => {
							newArr.push(ele.id);
						});
						this.foldersArray?.forEach(ele => {
							if (newArr.includes(ele.folderId)) {
								ele.isFav = true;
							} else {
								ele.isFav = false;
							}
						});
						this.sortData();
					} else {
						if (!this.hideProject) {
							let newArr = [];
							response['data']?.forEach(ele => {
								newArr.push(ele.id);
							});
							this.selectedProject?.inputOutputData?.forEach(ele => {
								if (newArr.includes(ele.id)) {
									ele.isFav = true;
								} else {
									ele.isFav = false;
								}
							});
							this.sortProjectData();
						} else {
							let newArr = [];
							response['data']?.forEach(ele => {
								newArr.push(ele.id);
							});
							this.selectedFolder[0]?.inputOutputData?.forEach(ele => {
								if (newArr.includes(ele.id)) {
									ele.isFav = true;
								} else {
									ele.isFav = false;
								}
							});
							this.sortFolderData();
						}
					}
					// this.userPlanStore.dispatch(new RegisterFavoriteTemplates(response['data']));
					// this.sortData();
				},
				() => {
					this.loading = false;
				},
				() => {}
			);
	}

	public addItemToFavorites(id: number, type: string) {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				heart_data: [{ id: id }],
				type: type
			}
		};
		this.userServiceApi
			.addFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Added to favorites');
				},
				() => {},
				() => {}
			);
	}

	public removeItemFromFavorites(id: string, type: string): void {
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {
				id: id,
				type: type
			}
		};
		this.userServiceApi
			.removeFavHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.toastNotificationService.sendSuccessToast('Removed from favorites');
				},
				() => {},
				() => {}
			);
	}

	public sortData(): void {
		// sort list based on favourite templates
		if (!this.foldersArray) return;
		let sortedList = [];
		for (let template of this.foldersArray) {
			if (template.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.foldersArray = [...sortedList];
	}

	public sortProjectData(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.selectedProject?.inputOutputData) {
			if (template.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.libraryAlteredData = [...sortedList];
	}

	public sortFolderData(): void {
		// sort list based on favourite templates
		let sortedList = [];
		for (let template of this.selectedFolder[0]?.inputOutputData) {
			if (template.isFav) {
				sortedList.unshift(template);
			} else {
				sortedList.push(template);
			}
		}
		this.folderLibraryAlteredData = [...sortedList];
	}

	public onDelete(event: Event, item: object, type: string): void {
		event.stopPropagation();
		// this.loading = true;
		let payload = {
			user: {
				userFiledId: this.filedId
			},
			data: {}
		};
		if (type === 'folders') {
			payload.data['project_id'] = this.selectedProject.id;
			payload.data['folder_id'] = item['folderId'];

			this.userServiceApi
				.deleteFolder(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {
						this.toastNotificationService.sendSuccessToast('Folder Deleted');
						const found = this.foldersArray.findIndex(ele => ele === item);
						if (found >= 0) {
							this.foldersArray.splice(found, 1);
						}
						// this.loading = false;
						// this.isFooter = false;
						// this.showFolderData = false;
						// for (let i = 0; i < this.projectsArray.length; i++) {
						// 	if (this.projectsArray[i].folder === undefined) {
						// 		this.noFolderFound = true;
						// 	}
						// }
					},
					() => {
						this.loading = false;
						this.toastNotificationService.sendErrorToast('Error, Please try again');
					}
				);
		}
	}

	public goBack(): void {
		this.router.navigate(['/projects']);
	}

	public goBackToProject(): void {
		this.shakespeareService.triggerParams$.next('folders');
		this.router.navigate(['projects'], { queryParams: { folders: true } });
	}

	public openMenu(event: Event, index: number) {
		event.stopPropagation();
		this.foldersArray[index].isMenuOpen = true;
	}

	public getMenuValue(event: string, index: number) {
		this.foldersArray[index].isMenuOpen = false;
	}

	public rename(folder: any, initialValue: string, editorId: string): void {
		this.isEditing = folder.folderId;
		folder.showInput = true;
		this.editedValue = initialValue;
		setTimeout(() => {
			var range = document.createRange();
			var sel = window.getSelection();
			this.editorControl.nativeElement.focus();
			document.getElementById(editorId).focus();
		}, 200);
	}

	setTextValue(event: any, id: number, editedText: string): void {
		if (event.keyCode == 13 || event.key === 'Enter') {
			event.preventDefault();
			this.completeRename(id, editedText);
			return;
		}
		this.editedValue = editedText;
	}

	public completeRename(index: number, value: any = '', event?: Event): void {
		if (event) {
			event.stopPropagation();
		}
		let reqObj;
		if (value.length <= 0) {
			return;
		}
		const foundFolder = this.foldersArray.find(d => d.folderId === this.isEditing);
		foundFolder.folderName = this.editorControl.nativeElement.value ?? value;
		// this.isEditing = this.foldersArray[index].folderId;
		reqObj = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				project_id: this.selectedProject?.id,
				folder_id: this.foldersArray[index].folderId,
				name: foundFolder.folderName
			}
		};

		this.userServiceApi.renameProjectOrFolder(reqObj).subscribe((res: any) => {
			if (res.debugMessage) {
				this.isEditing = null;
				this.editedValue = null;
				this.foldersArray[index].showInput = false;
				this.foldersArray[index].renamed = true;
				setTimeout(() => {
					this.foldersArray[index].renamed = false;
				}, 3000);
			}
		});
	}

	// public showNameControl(index: number) {
	// 	setTimeout(() => {
	// 		document.getElementById('nameControl').focus();
	// 	}, 200);
	// 	this.folderNameCtrl.setValue(this.foldersArray[index].folderName);
	// 	this.foldersArray[index].showInput = true;
	// }
}
