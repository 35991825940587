import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BigCommerceRoutingModule } from './big-commerce-routing.module';
import { AuthComponent } from './auth/auth.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BigCommerceComponent } from './big-commerce.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { ProductTableComponent } from './product-table/product-table.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { BcGeneratorComponent } from './bc-generator/bc-generator.component';
import { SideListComponent } from './side-list/side-list.component';
import { ViewProductComponent } from './view-product/view-product.component';
import { ProductHistoryComponent } from './product-history/product-history.component';
import { MobileDropProductsComponent } from './mobile-drop-products/mobile-drop-products.component';
import { NewlyGeneratedComponent } from './newly-generated/newly-generated.component';
import { BcOutputComponent } from './bc-output/bc-output.component';
import { BcHistoryComponent } from './bc-history/bc-history.component';
import { BcModalComponent } from './bc-modal/bc-modal.component';
import { BcGeneratorFormComponent } from '../components/bc-generator-form/bc-generator-form.component';

@NgModule({
	declarations: [
		AuthComponent,
		BigCommerceComponent,
		AllProductsComponent,
		ProductTableComponent,
		ProductCardComponent,
		BcGeneratorComponent,
		BcGeneratorFormComponent,
		SideListComponent,
		ViewProductComponent,
		BcOutputComponent,
		BcHistoryComponent,
		ProductHistoryComponent,
		MobileDropProductsComponent,
		NewlyGeneratedComponent,
		BcModalComponent
	],
	imports: [CommonModule, SharedModule, NgxPaginationModule, BigCommerceRoutingModule]
})
export class BigCommerceModule {}
