<div class="table-container">
	<div class="table-wrapper">
		<form [formGroup]="radioForm">
			<table>
				<thead>
					<tr>
						<th>
							<input type="checkbox" #allChecker (change)="checkAll($event)" />
						</th>
						<th></th>
						<th>Products - {{ products?.length }}</th>
						<th class="with-tooltip">
							Optimization Score
							<app-tooltip text="Optimization Score for shopify product"></app-tooltip>
						</th>
						<th>Preview</th>
						<th>Inventory</th>
					</tr>
				</thead>
				<tbody *ngIf="products?.length">
					<tr *ngFor="let item of products | paginate: config; let i = index">
						<td>
							<input type="checkbox" formControlName="radio{{ i }}" />
						</td>
						<td>
							<div class="dot" [ngClass]="{ red: item?.optimizationScore == 0 }"></div>
						</td>
						<td (click)="openEditor(item?.id)">
							<div class="product">
								<img [src]="(item?.images)[0]" alt="" />
								<p>{{ item?.title }}</p>
							</div>
						</td>
						<td>{{ item?.optimizationScore }}</td>
						<td>
							<img (click)="previewProduct(item)" src="assets/icons/icon-eye.svg" alt="" />
						</td>
						<td>{{ item?.sku }}</td>
					</tr>
				</tbody>
			</table>
			<div class="footer" *ngIf="count">
				<app-button buttonClass="tertiary" (onClick)="clearSelected()" icon="fas fa-times">{{ count }} Selected</app-button>
				<app-button buttonClass="primary" (onClick)="generateDescription()">Generate Descriptions</app-button>
			</div>
		</form>
	</div>
	<div class="has-text-centered" *ngIf="products?.length > 0">
		<shakespeare-spinning-loader class="spinner" *ngIf="loading"></shakespeare-spinning-loader>
		<!-- <pagination-controls previousLabel="" nextLabel="" (pageChange)="getLibraryData($event)" id="server"></pagination-controls> -->
		<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
			<div class="custom-pagination">
				<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
					<a (click)="p.previous()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/left-icon.svg" alt="" /></a>
				</div>
				<div *ngFor="let page of p.pages" class="page" [class.current]="p.getCurrent() === page.value">
					<a (click)="getData(page.label)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
						<span>{{ page.label }}</span>
					</a>
					<div *ngIf="p.getCurrent() === page.value">
						<span>{{ page.label }}</span>
					</div>
				</div>
				<div class="pagination-next" [class.disabled]="p.isLastPage()">
					<a (click)="p.next()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/right-icon.svg" alt="" /></a>
				</div>
			</div>
		</pagination-template>
	</div>
</div>
