<div class="circle-container">
	<div class="outer">
		<div class="inner">
			<p>{{ value }}%</p>
		</div>
	</div>
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="220px" height="220px">
		<defs>
			<!-- <linearGradient id="GradientColor">
				<stop offset="0%" stop-color="#e91e63" />
				<stop offset="100%" stop-color="#673ab7" />
			</linearGradient> -->
		</defs>
		<!-- stroke-linecap="round"  -->
		<circle cx="110" cy="110" r="103" [style.stroke]="color" [style.stroke-dashoffset]="Math.round(647 - 647 * (value / 100))" />
	</svg>
</div>
