<div class="container">
	<div class="header">
		<button class="back-button" (click)="sendFeedback.emit(false)">
			<img src="assets/mobile-back-button.svg" alt="back button" class="icon" />
			<p>Back</p>
		</button>
	</div>
	<section class="pricing-header">
		<div class="section-container">
			<div id="pricing-header-wrap">
				<div id="pricing-header-text">
					<h1>
						You reached the limits of your plan !
					</h1>
					<p class="p11">Please upgrade now to get more stuff</p>
				</div>
			</div>
		</div>
	</section>

	<section class="pricing-header" style="padding-bottom: 12px !important;">
		<div class="section-container">
			<div id="pricing-header-wrap">
				<div id="pricing-header-text">
					<span class="pricing-header-extra">
						Your Existing Plan :
					</span>
					<span class="pricing-header-extra" style="font-weight: 600;">{{ userCurrentPlan?.planName }} - ${{ userCurrentPlan?.amount }}</span>
				</div>
			</div>
		</div>
	</section>

	<section id="pricing_btn" class="">
		<div class="section-container">
			<div class="button_gropu_tab">
				<div class="btn_group_inner">
					<button class="btn_monthly custom_button_bg" (click)="onCheckToggleViewMonthly(true)" [class.active]="isMonthlyActive">
						Pay Monthly
					</button>
					<button class="btn_yearly custom_button_bg" (click)="onCheckToggleViewYearly(false)" [class.active]="isYearlyActive">
						Pay Yearly (Save 20%)
					</button>
				</div>
			</div>
		</div>
	</section>
	<div class="offer-con">
		<!-- !basic -->
		<div class="offer-box" *ngIf="true" [class.loader-css]="loading" (click)="changePlan(planEnum.standard)">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div *ngIf="filteredStandardPlans && !loading">
				<div class="standard">
					<p class="plan-name">{{ isIndiaPrice ? 'Standard' : 'Basic' }}</p>
					<p class="offer" *ngIf="offerOn">
						{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25%OFF' }}
					</p>
				</div>
				<div class="offer-sub-title" id="standard-text">
					<p class="box-sub-title">For individuals & small businesses</p>
					<p class="box-sub-text">Everything you need to improve your marketing using Ai</p>
				</div>
				<div class="offer-value-wrap" *ngIf="!offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
						</span>
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<div class="offer-value-wrap" *ngIf="offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{
								planDuration === planDurationEnum.Monthly
									? Math.round(selectedPlan?.price / 2)
									: Math.round((selectedPlan?.price * (100 - 25)) / 100 / 12)
							}}
						</span>
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<p class="striked" *ngIf="offerOn">
					<del>${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}</del>
				</p>
				<button
					type="submit"
					form="form-offer-0"
					value="Submit"
					class="secondary-btn cta-btn"
					(click)="submit('standard')"
					style="width: 100%; margin-top: 0px;"
				>
					<span>{{ isShopifyUser ? 'START NOW' : 'UPGRADE YOUR PLAN' }}</span>
				</button>

				<div class="offer-slider">
					<input
						type="range"
						id="slider"
						[style.background]="defaultStyle"
						(input)="changeBackground($event)"
						[min]="0"
						[max]="filteredStandardPlans.length - 1"
						[step]="1"
						[value]="standardStep"
						(change)="onSlide($event)"
					/>
					<p class="ad_detail ad_detail1">
						Monthly Ad Spend
						{{ getAdDetailText(planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12), 'basic') }}
					</p>
				</div>
				<div>
					<div class="ad-spend">
						<p class="desc">
							<strong style="font-weight: 700;">Ad Spend Fees</strong>
						</p>
						<ul class="percentage_mk">
							<li>5% of Monthly Ad Spend</li>
						</ul>
					</div>
					<p class="desc2"><strong style="font-weight: 700;">What's included on Basic</strong></p>
					<ul class="item-lists">
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							1 Platform Either Google or Facebook
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							AI Optimization
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							AI Creative Studio
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							Basic Email Support
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- !pro -->
		<div class="offer-box" [class.loader-css]="loading" (click)="changePlan(planEnum.pro)" [class.selected]="true" style="position: relative;">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div class="offer-badge">
				<p>Most Popular</p>
			</div>
			<div *ngIf="filteredProPlans && !loading">
				<div>
					<div class="pro">
						<p class="plan-name">{{ isIndiaPrice ? 'Pro' : 'Professional' }}</p>
						<!-- <p class="popular">Most Popular</p> -->
						<p class="offer" *ngIf="offerOn">
							{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}
						</p>
					</div>
					<div class="offer-sub-title">
						<p class="box-sub-title">For small businesses</p>
						<p class="box-sub-text">Level up your marketing with professional Ai to automate and optimize your work</p>
					</div>
				</div>
				<div class="offer-value-wrap" *ngIf="!offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
						</span>
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<div class="offer-value-wrap" *ngIf="offerOn" [class.offer-on]="offerOn">
					<h3 id="offer-value-2" class="number offer-value">
						<span class="text-6xl font-bold price_val price_val1">
							${{
								planDuration === planDurationEnum.Monthly
									? Math.round(selectedProPlan?.price / 2)
									: Math.round((selectedProPlan?.price * (100 - 25)) / 100 / 12)
							}}
						</span>
					</h3>
					<p class="offer-term">&nbsp; per month</p>
				</div>
				<p class="striked" *ngIf="offerOn">
					<del>${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}</del>
				</p>
				<button
					type="submit"
					form="form-offer-0"
					value="Submit"
					class="secondary-btn cta-btn"
					(click)="submit('pro')"
					style="width: 100%; margin-top: 0px;"
				>
					<span>{{ isShopifyUser ? 'START NOW' : 'UPGRADE YOUR PLAN' }}</span>
				</button>
				<div class="offer-slider">
					<input
						type="range"
						id="sliderPro"
						[style.background]="defaultProStyle"
						(input)="changeProBackground($event)"
						[min]="0"
						[max]="filteredProPlans.length - 1"
						[step]="1"
						[value]="proStep"
						(change)="onSlidePro($event)"
					/>
					<p class="ad_detail ad_detail1">
						Monthly Ad Spend
						{{
							getAdDetailText(planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12), 'pro')
						}}
					</p>
				</div>
				<div>
					<div class="ad-spend">
						<p class="desc">
							<strong style="font-weight: 700;">Ad Spend Fees</strong>
						</p>
						<ul class="percentage_mk">
							<li *ngIf="isIndiaPrice">5% of Monthly Ad Spend</li>
							<li *ngIf="!isIndiaPrice">3% of Monthly Ad Spend</li>
						</ul>
					</div>
					<p class="desc2"><strong style="font-weight: 700;">What's included on Professional</strong></p>
					<ul class="item-lists">
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							2 Platform : Google & Facebook
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							AI Optimization
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							AI Creative Studio
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							Priority Ticket Support
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							5 Users
						</li>
						<li>
							<img src="assets\icons\ick-circle-filled.svg" />
							Notifications
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- !enterprise -->
		<div class="offer-box" [class.loader-css]="loading">
			<div class="spinner-wrapper" *ngIf="loading">
				<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
			</div>
			<div class="offer-box-inner" *ngIf="!loading">
				<div class="standard">
					<p class="plan-name">Enterprise</p>
					<p class="offer" *ngIf="offerOn">
						{{ planDuration === planDurationEnum.Monthly ? '50% OFF' : '25% OFF' }}
					</p>
				</div>
				<div class="offer-sub-title">
					<p class="box-sub-title">For medium and large businesses</p>
					<p class="box-sub-text">Get the best of Shakespeare Ai with custom tools and dedicated support</p>
				</div>
				<div class="customize-plan">
					<p></p>
					<p class="custmize_plan">Customised Plan</p>
					<p></p>
					<a href="https://www.shakespeare.ai/lp/enterprise-contact-us/">
						<button type="submit" form="form-offer-0" value="Submit" class="secondary-btn cta-btn">
							<span style="font-size: 16px;">Contact Us</span>
						</button>
					</a>
				</div>
			</div>
		</div>
	</div>
	<section class="compair_plan">
		<div class="section-container">
			<div class="compair_plan_btn" (click)="onClickComparePlan()">
				<p class="compair_plan_button">Compare Plans</p>
				<img src="assets/icons/arrow-down (2).svg" alt="" [ngClass]="comparePlan ? 'close-plan' : 'open-plan'" />
			</div>
			<div class="plan_main" id="plan_main" [ngStyle]="comparePlan ? { display: 'block' } : { display: 'none' }">
				<div class="plan_inner_main">
					<div class="plan_inner no_bdr desktop">
						<fieldset class="radio-switch">
							<input type="radio" value="monthly" id="monthly" checked="" [(ngModel)]="selectedOption" (click)="onCheckToggleViewMonthly(true)" />
							<label for="monthly" class="lb_monthly">
								Monthly
							</label>

							<input type="radio" value="annually" id="annually" [(ngModel)]="selectedOption" (click)="onCheckToggleViewYearly(false)" />
							<label for="annually" class="lb_annually">
								Annually
							</label>
						</fieldset>
						<h4>Features</h4>
					</div>
					<div class="plan_inner no_bdr mobile">
						<h4>Features</h4>
					</div>
					<div class="plan_inner monthly-1" *ngIf="selectedOption === 'monthly'">
						<h3>Basic</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('standard')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner clr_perpl monthly-2" *ngIf="selectedOption === 'monthly'">
						<h3>Professional</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('pro')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner monthly-3" *ngIf="selectedOption === 'monthly'">
						<h3>Enterprise</h3>
						<p class="table-heading-value">Custom</p>
						<a href="https://www.shakespeare.ai/lp/sign-up-for-a-demo/" target="blank">Contact Us</a>
					</div>
					<div class="plan_inner annually-1" *ngIf="selectedOption === 'annually'">
						<h3>Basic</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedPlan?.price : Math.round(selectedPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('standard')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner clr_perpl annually-2" *ngIf="selectedOption === 'annually'">
						<h3>Professional</h3>
						<p class="table-heading-value">
							${{ planDuration === planDurationEnum.Monthly ? selectedProPlan?.price : Math.round(selectedProPlan?.price / 12) }}
							<span>/mo</span>
						</p>
						<a (click)="submit('pro')" target="blank">Start free 7-day trial</a>
					</div>
					<div class="plan_inner annually-3" *ngIf="selectedOption === 'annually'">
						<h3>Enterprise</h3>
						<p>Custom</p>
						<a href="https://www.shakespeare.ai/lp/sign-up-for-a-demo/" target="blank">Contact Us</a>
					</div>
				</div>
				<div class="pricing_table">
					<table style="width: 100%;">
						<tbody>
							<tr>
								<td>Platform: Google, Facebook</td>
								<td>Any one</td>
								<td>Both</td>
								<td>Both</td>
							</tr>
							<tr>
								<td>Number of platforms</td>
								<td>1</td>
								<td>2</td>
								<td>2</td>
							</tr>
							<tr>
								<td>Number of team users</td>
								<td>1</td>
								<td>5</td>
								<td>20</td>
							</tr>
							<tr>
								<td>Slack Notifications</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Expanded content creation tools</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Priority Support</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Onboarding & Training Sessions</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Team Functionality</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>24 hr ticket support</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Dedicated Customer Sucess</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>AI Predictive Text</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Premium Technical Support</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
							</tr>
							<tr>
								<td>Language Translations</td>
								<td>&nbsp;</td>
								<td>&nbsp;</td>
								<td><i class="tick_icon"></i></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>
</div>
<div *ngIf="globalLoader" class="global-spinner">
	<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
</div>
