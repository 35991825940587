<div class="main-container">
	<div *ngIf="loader" [ngStyle]="{ width: '100%', height: '100%' }">
		<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
	</div>
	<div class="main-cards">
		<mat-card *ngFor="let item of categories" (click)="showKnowledgeView(item.categoryId)">
			<div class="section">
				<img [src]="imgMap[item.name]" />
				<div class="section-description">
					<h5>{{ item.name }}</h5>
					<div class="des-text">
						{{ item.description }}
					</div>
				</div>
			</div>
		</mat-card>
	</div>
</div>
