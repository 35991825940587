import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<ContactUsComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {}

	public closeModal(): void {
		this.dialogRef.close();
	}

	public changeStepType(): void {
		this.dialogRef.close({ type: 'why-options' });
	}

	public proceedToCancel(): void {
		this.dialogRef.close({ cancel: true });
	}
}
