import { PaymentService } from './../../user-management/components/payment.service';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { Subject } from 'rxjs';
import { CURRENCIES } from 'src/app/shared/models/currencies.const';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { LogInSuccess } from 'src/app/authentication/state/authentication.action';
import { AuthenticationState } from 'src/app/authentication/state/authentication.reducer';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { OptiomizationAiService } from 'src/app/shakespeare/OptimizationAI/optiomization-ai.service';
import { PlanDurationEnum, PlanEnum } from 'src/app/shakespeare/settings/model/billing-plan-interface';

@Component({
	selector: 'app-upgrade-plans',
	templateUrl: './upgrade-plans.component.html',
	styleUrls: ['./upgrade-plans.component.scss']
})
export class UpgradePlansComponent implements OnInit {
	@Output() public sendFeedback: EventEmitter<any> = new EventEmitter();
	public comparePlan: boolean = true;
	public planDurationEnum = PlanDurationEnum;
	private unsubscriber$ = new Subject<void>();
	public planDuration: PlanDurationEnum = PlanDurationEnum.Monthly;
	private allPlans = [];
	public proPlans = [];
	public standardPlans = [];
	private userCurrency: string;
	public currencySymbol = '';
	public currencies = [...CURRENCIES];
	public monthlyStandard = [];
	public monthlyPro = [];
	public yearlyStandard = [];
	public yearlyPro = [];
	public sliderValue: string;
	public sliderProValue: string;
	public Math = Math;
	public selectedPlan: any;
	public selectedProPlan: any;
	public loading = false;
	public activePlan: PlanEnum = PlanEnum.pro;
	public planEnum = PlanEnum;
	public productId: string;
	public globalLoader = false;
	isIndiaPrice = false;
	src: any = '';
	defaultStyle: string;
	defaultProStyle: string;
	isShopifyUser: boolean;
	standardStep = 1;
	proStep = 1;
	haveToggled = false;
	offerOn: boolean;
	slideHandler: number;
	slideProHandler: number;

	isMonthlyActive: boolean = true;
	isYearlyActive: boolean = false;
	selectedPlanPrice: any;
	selectedProPlanPrice: any;
	sortedPlans: any;
	selectedOption: string = 'monthly';
	userCurrentPlan: any;
	filteredProPlans = [];
	filteredStandardPlans = [];
	indianStandardProductId = null;
	indianProProductId = null;

	constructor(
		public optiomizationAiService: OptiomizationAiService,
		private authenticationService: AuthenticationService,
		private backOfficeApiService: BackOfficeService,
		private paymentService: PaymentService,
		private cdRef: ChangeDetectorRef,
		private authStore: Store<AuthenticationState>,
		private toastNotificationService: ToastNotificationService,
		private router: Router
	) {
		let loginParam = this.getCookie('loginSrc');
		if (loginParam == 'santa2022') {
			this.offerOn = true;
		}
	}

	ngOnInit(): void {
		this.backOfficeApiService
			.userPlanDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				// console.log(res);
				this.userCurrentPlan = res;
			});

		window['dataLayer'].push({
			event: 'on_freetrial_screen',
			pageUrl: window.location.href,
			pageTitle: 'Free Trial'
		}); // invoking google tag manager code
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.src = localStorage.getItem('urlSrc');
		if (token.platform_name == 'shopify') {
			this.globalLoader = true;
			this.checkPendingPayment();
		}
		if (token.bigcommerce_detail_available == 'true') {
			this.authenticationService.signupVersion$.next('quickSignup1');
			this.getPlans();
			return;
		}
		this.getVersion();
	}

	checkPendingPayment(): void {
		this.loading = true;
		this.backOfficeApiService
			.checkPayment()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res: any) => {
					if (res == '') {
						this.globalLoader = false;
						this.isShopifyUser = true;
						// this.router.navigate(['authentication/shopify-plans']);
					} else if (res == 'refresh') {
						this.refreshToken();
					} else {
						window.open(res, '_self');
					}
				},
				error: () => {
					this.globalLoader = false;
				}
			});
	}

	getVersion(): void {
		this.loading = true;
		this.backOfficeApiService
			.getVersion()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(val => {
				if (val == 'quickSignup2') {
					this.planDuration = this.planDurationEnum.Annual;
				}
				this.authenticationService.signupVersion$.next(val);
				this.getPlans();
			});
	}

	public signOut(): void {
		this.authenticationService.logout();
	}

	public onCheckToggleViewMonthly(event: boolean): void {
		this.selectedOption = 'monthly';
		this.isMonthlyActive = true;
		this.isYearlyActive = false;
		this.planDuration = event ? PlanDurationEnum.Monthly : PlanDurationEnum.Annual;
		this.filterData();
	}

	public onCheckToggleViewYearly(event: boolean): void {
		this.selectedOption = 'annually';
		this.isYearlyActive = true;
		this.isMonthlyActive = false;
		this.planDuration = event ? PlanDurationEnum.Monthly : PlanDurationEnum.Annual;
		this.filterData();
	}

	public changePlan(plan: PlanEnum): void {
		this.activePlan = plan;
		if (this.isShopifyUser) {
			this.allPlans.forEach(plan => {
				if (this.activePlan == PlanEnum.pro) {
					if (plan.productName.toString().toLowerCase().includes('pro') && !plan.isTrial) this.productId = plan.productId;
				} else {
					if (plan.productName.toString().toLowerCase().includes('standar') && !plan.isTrial) this.productId = plan.productId;
				}
			});
		} else {
			this.allPlans.forEach(plan => {
				if (this.activePlan == PlanEnum.pro) {
					if (plan.productName.toString().toLowerCase().includes('pro') && plan.isTrial) this.productId = plan.productId;
				} else {
					if (plan.productName.toString().toLowerCase().includes('standar') && plan.isTrial) this.productId = plan.productId;
				}
			});
		}
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	public submit(type: string): void {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		let plan = null;
		this.globalLoader = true;
		setTimeout(() => {
			if (type == 'standard') {
				plan = this.selectedPlan;
			} else {
				plan = this.selectedProPlan;
			}
			if (this.isShopifyUser) {
				this.allPlans.forEach(plan => {
					if (type == 'pro') {
						if (plan.productName.toString().toLowerCase().includes('pro') && !plan.isTrial) this.productId = plan.productId;
					} else {
						if (plan.productName.toString().toLowerCase().includes('standar') && !plan.isTrial) this.productId = plan.productId;
					}
				});
			} else if (token.sign_up_version == 'v3') {
				this.allPlans.forEach(plan => {
					if (type == 'pro') {
						if (plan.productName.toString().toLowerCase().includes('pro') && plan.isTrial) this.productId = plan.productId;
					} else {
						if (plan.productName.toString().toLowerCase().includes('basic') && plan.isTrial) this.productId = plan.productId;
					}
				});
			} else {
				this.allPlans.forEach(plan => {
					if (type == 'pro') {
						if (plan.productName.toString().toLowerCase().includes('pro') && plan.isTrial) this.productId = plan.productId;
					} else {
						if (plan.productName.toString().toLowerCase().includes('standar') && plan.isTrial) this.productId = plan.productId;
					}
				});
			}
			// let loginParam = this.getCookie('loginSrc');

			if (this.isIndiaPrice) {
				if (type == 'pro') {
					this.productId = this.indianProProductId;
				} else {
					this.productId = this.indianStandardProductId;
				}
			}
			let payload = {
				productId: this.productId,
				priceId: plan.priceId,
				currentPriceId: this.userCurrentPlan.priceId
			};

			console.log(payload);
			// let src = 'v3enterprise';
			// if (src) {
			// 	payload['src'] = src;
			// }

			// if (loginParam !== '') {
			// 	payload['src'] = loginParam;
			// }

			this.backOfficeApiService
				.upgradePlan(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: response => {
						if (response) {
							if (response?.sessionId) {
								this.paymentService.redirectToCheckout(response.sessionId);
							} else if (response.isSuccess) {
								this.sendFeedback.emit(true);
							} else {
								this.toastNotificationService.sendErrorToast('Something went wrong, please try again');
								this.globalLoader = false;
								this.loading = false;
							}
						}
					},
					error: () => {
						this.toastNotificationService.sendErrorToast('Something went wrong, please try again');
						this.globalLoader = false;
						this.loading = false;
					}
				});
		}, 500);
	}

	public changeBackground(event): void {
		let slider = <HTMLInputElement>document.getElementById('slider');
		let value = (((slider.value as any) - (slider.min as any)) / ((slider.max as any) - (slider.min as any))) * 100;
		slider.style.background = 'linear-gradient(to right, #006bff 0%, ' + value + '%,#ECECEC ' + value + '%, #ECECEC 100%)';
	}

	public changeProBackground(event): void {
		let slider = <HTMLInputElement>document.getElementById('sliderPro');
		let value = (((slider.value as any) - (slider.min as any)) / ((slider.max as any) - (slider.min as any))) * 100;
		slider.style.background = 'linear-gradient(to right, #006bff 0%, ' + value + '%,#ECECEC ' + value + '%, #ECECEC 100%)';
	}

	public getShopifyFree(): void {
		this.backOfficeApiService
			.getShopifyFree()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: () => this.refreshToken(),
				error: () => {
					this.toastNotificationService.sendErrorToast('Something went wrong!');
				}
			});
	}

	public refreshToken(): void {
		this.backOfficeApiService
			.refreshUserToken()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.globalLoader = false;
				this.authStore.dispatch(new LogInSuccess(result));
			});
	}

	public getPlans(): void {
		let token = JSON.parse(localStorage.getItem('decodedJwtIo'));
		this.loading = true;
		this.backOfficeApiService
			.getAllOffers()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						const userPlanName = this.userCurrentPlan.planName;
						const userPlanAmount = this.userCurrentPlan.amount;
						this.loading = false;
						if (this.isShopifyUser) {
							response.forEach(plan => {
								if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('pro')) {
									this.proPlans = plan.productPrices;
									this.filteredProPlans = this.proPlans.filter(filteredPlan => {
										return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
									});
									this.allPlans.push(plan);
								}
								if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('standard')) {
									this.standardPlans = plan.productPrices;
									this.filteredStandardPlans = this.standardPlans.filter(filteredPlan => {
										return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
									});
									this.allPlans.push(plan);
								}
							});
						} else if (token.sign_up_version == 'v3' || token.sign_up_version == 'v2') {
							console.log('in v3');
							response.forEach(plan => {
								this.checkIndianPrice(plan);
								if (plan.isTrial && plan.productName.toString().toLowerCase().includes('professional')) {
									this.proPlans = plan.productPrices;
									this.filteredProPlans = this.proPlans.filter(filteredPlan => {
										return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
									});
									console.log(this.filteredProPlans);
									this.allPlans.push(plan);
								}
								if (plan.isTrial && plan.productName.toString().toLowerCase().includes('basic')) {
									this.standardPlans = plan.productPrices;
									this.filteredStandardPlans = this.standardPlans.filter(filteredPlan => {
										return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
									});
									console.log(this.filteredStandardPlans, this.standardPlans);
									this.allPlans.push(plan);
								}
								if (this.isIndiaPrice) {
									console.log('in india');
									if (plan.isTrial && plan.productName.toString().toLowerCase() == 'pro') {
										this.proPlans = plan.productPrices;
										this.allPlans.push(plan);
									}
									if (plan.isTrial && plan.productName.toString().toLowerCase() == 'standard') {
										this.standardPlans = plan.productPrices;
										this.allPlans.push(plan);
									}
								}
							});
						} else {
							console.log('in else');
							response.forEach(plan => {
								this.checkIndianPrice(plan);
								if (plan.isTrial && plan.productName.toString().toLowerCase().includes('pro')) {
									this.proPlans = plan.productPrices;
									this.filteredProPlans = this.proPlans.filter(filteredPlan => {
										return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
									});
									this.allPlans.push(plan);
								}
								if (plan.isTrial && plan.productName.toString().toLowerCase().includes('standard')) {
									this.standardPlans = plan.productPrices;
									this.filteredStandardPlans = this.standardPlans.filter(filteredPlan => {
										return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
									});
									this.allPlans.push(plan);
								}
								if (this.isIndiaPrice) {
									console.log('in india');
									if (plan.isTrial && plan.productName.toString().toLowerCase() == 'pro') {
										this.proPlans = plan.productPrices;
										this.allPlans.push(plan);
									}
									if (plan.isTrial && plan.productName.toString().toLowerCase() == 'standard') {
										this.standardPlans = plan.productPrices;
										this.allPlans.push(plan);
									}
								}
							});
						}
						this.filterData();
						this.changePlan(PlanEnum.pro);
						this.userCurrency = response[0].currency;
						this.currencySymbol = this.currencies.find(cur => this.userCurrency.toUpperCase() == cur.name).symbol;
					}
				},
				() => {
					this.loading = false;
				}
			);
	}

	public kFormatter(num: number): string {
		try {
			let formatter = Intl.NumberFormat(
				'en',
				// @ts-ignore,
				{ notation: 'compact' }
			);
			return formatter.format(num);
		} catch (e) {
			console.warn(e);
			Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1) as any) + 'K' : Math.sign(num) * Math.abs(num) + 'K';
		}
	}

	public filterData(): void {
		let userPlanName = this.userCurrentPlan.planName;
		let userPlanAmount = this.userCurrentPlan.amount;
		console.log(this.allPlans, 'all plans');
		if (this.isShopifyUser) {
			this.allPlans.forEach(plan => {
				if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('pro')) {
					this.proPlans = plan.productPrices;
					this.filteredProPlans = this.proPlans.filter(filteredPlan => {
						return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
					});
					this.allPlans.push(plan);
				}
				if (!plan.isTrial && plan.productName.toString().toLowerCase().includes('standard')) {
					this.standardPlans = plan.productPrices;
					this.filteredStandardPlans = this.standardPlans.filter(filteredPlan => {
						return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
					});
					this.allPlans.push(plan);
				}
			});
		} else {
			this.allPlans.forEach(plan => {
				if (plan.isTrial && plan.productName.toString().toLowerCase().includes('professional')) {
					this.proPlans = plan.productPrices;
					this.filteredProPlans = this.proPlans.filter(filteredPlan => {
						return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
					});
					this.allPlans.push(plan);
				}
				if (plan.isTrial && plan.productName.toString().toLowerCase().includes('basic')) {
					this.standardPlans = plan.productPrices;
					this.filteredStandardPlans = this.standardPlans.filter(filteredPlan => {
						return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
					});
					this.allPlans.push(plan);
				}
			});
		}
		console.log(this.filteredProPlans, this.filteredStandardPlans, this.standardPlans);
		// Checking if user is Indian or not?
		if (this.allPlans.length) {
			if (this.isIndiaPrice) {
				console.log('in india');
				this.allPlans.forEach(plan => {
					if (plan.isTrial && plan.productName.toString().toLowerCase() == 'pro') {
						this.proPlans = plan.productPrices;
						this.filteredProPlans = this.proPlans.filter(filteredPlan => {
							return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
						});
						this.indianProProductId = plan.productId;
						// this.allPlans.push(plan);
					}
					if (plan.isTrial && plan.productName.toString().toLowerCase() == 'standard') {
						this.standardPlans = plan.productPrices;
						this.filteredStandardPlans = this.standardPlans.filter(filteredPlan => {
							return filteredPlan.productName != userPlanName || filteredPlan.price != userPlanAmount;
						});
						this.indianStandardProductId = plan.productId;

						// this.allPlans.push(plan);
					}
				});
				console.log(this.standardPlans, this.proPlans, 'indian plans');
			}
		}

		this.monthlyStandard = [];
		this.yearlyStandard = [];
		this.monthlyPro = [];
		this.yearlyPro = [];
		if (this.isShopifyUser) {
			this.filteredStandardPlans.forEach(plan => {
				if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && !plan.isTrial) {
					this.monthlyStandard.push(plan);
				}
				if (plan.interval == PlanDurationEnum.Annual && !plan.isTrial) {
					this.yearlyStandard.push(plan);
				}
			});
			this.filteredProPlans.forEach(plan => {
				if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && !plan.isTrial) {
					this.monthlyPro.push(plan);
				}
				if (plan.interval == PlanDurationEnum.Annual && !plan.isTrial) {
					this.yearlyPro.push(plan);
				}
			});
		} else {
			this.filteredStandardPlans.forEach(plan => {
				if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && plan.isTrial) {
					this.monthlyStandard.push(plan);
				}
				if (plan.interval == PlanDurationEnum.Annual && plan.isTrial) {
					this.yearlyStandard.push(plan);
				}
			});
			this.filteredProPlans.forEach(plan => {
				if ((plan.interval == PlanDurationEnum.Monthly || plan.interval == 'day') && plan.isTrial) {
					this.monthlyPro.push(plan);
				}
				if (plan.interval == PlanDurationEnum.Annual && plan.isTrial) {
					this.yearlyPro.push(plan);
				}
			});
		}
		this.filteredStandardPlans = [];
		this.filteredProPlans = [];
		if (this.planDuration == this.planDurationEnum.Monthly) {
			this.filteredStandardPlans = this.monthlyStandard;
			this.filteredProPlans = this.monthlyPro;
		} else {
			this.filteredStandardPlans = this.yearlyStandard;
			this.filteredProPlans = this.yearlyPro;
		}

		this.sortPlans(this.filteredStandardPlans);
		this.sortPlans(this.filteredProPlans);
		let signupVersion = this.authenticationService.signupVersion$.value;

		if (signupVersion == 'quickSignup3') {
			this.sliderValue = this.kFormatter(this.filteredStandardPlans[1]?.credit);
			this.standardStep = 1;
			this.proStep = 1;
			this.selectedPlan = this.filteredStandardPlans[1];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * 100;
			let defaultSelectedProVal = ((1 - 0) / (this.proPlans.length - 1) - 0) * 100;
			console.log('v3');
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
			this.selectedProPlan = this.filteredProPlans[1];
			this.defaultProStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedProVal + '%, #ECECEC ' + defaultSelectedProVal + '%, #ECECEC 100%)';
			this.sliderProValue = this.kFormatter(this.filteredProPlans[1]?.credit);
		} else if (signupVersion == 'quickSignup2') {
			this.sliderValue = this.kFormatter(this.filteredStandardPlans[1]?.credit);
			this.standardStep = 1;
			this.proStep = 0;
			console.log('v2');
			this.selectedPlan = this.filteredStandardPlans[1];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * 100;
			let defaultSelectedProVal = ((1 - 0) / (this.proPlans.length - 1) - 0) * 0;
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
			this.selectedProPlan = this.filteredProPlans[0];
			this.defaultProStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedProVal + '%, #ECECEC ' + defaultSelectedProVal + '%, #ECECEC 100%)';
			this.sliderProValue = this.kFormatter(this.filteredProPlans[0]?.credit);
		} else {
			this.sliderValue = this.kFormatter(this.filteredStandardPlans[1]?.credit);
			this.standardStep = 0;
			this.proStep = 0;
			this.selectedPlan = this.filteredStandardPlans[0];
			let defaultSelectedStandardVal = 0;
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
			this.selectedProPlan = this.filteredProPlans[0];
			this.sliderProValue = this.kFormatter(this.filteredProPlans[0]?.credit);
			console.log('v1');
		}

		// slider retains state after toggle
		if (this.slideHandler) {
			// for standard plans
			this.standardStep = this.slideHandler;
			this.sliderValue = this.kFormatter(this.filteredStandardPlans[this.slideHandler]?.credit);
			this.selectedPlan = this.filteredStandardPlans[this.slideHandler];
			let defaultSelectedStandardVal = ((1 - 0) / (this.standardPlans.length - 1) - 0) * (this.slideHandler * 100);
			this.defaultStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedStandardVal + '%, #ECECEC ' + defaultSelectedStandardVal + '%, #ECECEC 100%)';
		}
		if (this.slideProHandler) {
			// for pro plans
			this.proStep = this.slideProHandler;
			this.sliderProValue = this.kFormatter(this.filteredProPlans[this.slideProHandler]?.credit);
			this.selectedProPlan = this.filteredProPlans[this.slideProHandler];
			let defaultSelectedProStandardVal = ((1 - 0) / (this.proPlans.length - 1) - 0) * (this.slideProHandler * 100);
			this.defaultProStyle =
				'linear-gradient(to right, #006bff 0%, ' + defaultSelectedProStandardVal + '%, #ECECEC ' + defaultSelectedProStandardVal + '%, #ECECEC 100%)';
		}
	}

	public sortPlans(plan): void {
		plan.sort((a, b) => {
			return a.price - b.price;
		});
		this.cdRef.detectChanges();
	}

	public onSlide(event): void {
		let value = event.target.value;
		this.slideHandler = value;
		this.activePlan = this.planEnum.standard;
		this.sliderValue = this.kFormatter(this.filteredStandardPlans[value].credit);
		this.selectedPlan = this.filteredStandardPlans[value];
		this.selectedPlanPrice = this.selectedPlan.price;
	}

	public onSlidePro(event): void {
		let value = event.target.value;
		this.slideProHandler = value;
		this.activePlan = this.planEnum.pro;
		this.sliderProValue = this.kFormatter(this.filteredProPlans[value].credit);
		this.selectedProPlan = this.filteredProPlans[value];
		this.selectedProPlanPrice = this.selectedProPlan.price;
	}

	toggleMonthly() {
		this.isMonthlyActive = true;
		this.isYearlyActive = false;
	}

	toggleYearly() {
		this.isMonthlyActive = false;
		this.isYearlyActive = true;
	}

	getAdDetailText(sliderValue: number, sliderName: string): string {
		if (this.isIndiaPrice) {
			// console.log(sliderName, this.selectedOption);
			if (sliderName === 'basic' && this.selectedOption == 'monthly') {
				switch (sliderValue) {
					case 29:
						return '$0 - $5,000';
					case 99:
						return '$5,000 - $20,000';
					case 245:
						return '$20,000 - $50,000';
					case 395:
						return '$50,000+';
					default:
						return ''; // Handle other cases or return an empty string
				}
			} else if (sliderName == 'basic' && this.selectedOption == 'annually') {
				switch (sliderValue) {
					case 23:
						return '$0 - $5,000';
					case 79:
						return '$5,000 - $20,000';
					case 196:
						return '$20,000 - $50,000';
					case 316:
						return '$50,000+';
					default:
						return ''; // Handle other cases or return an empty string
				}
			} else if (sliderName === 'pro' && this.selectedOption == 'monthly') {
				switch (sliderValue) {
					case 49:
						return '$0 - $5,000';
					case 125:
						return '$5,000 - $20,000';
					case 275:
						return '$20,000 - $50,000';
					case 445:
						return '$50,000+';
					default:
						return ''; // Handle other cases or return an empty string
				}
			} else if (sliderName === 'pro' && this.selectedOption == 'annually') {
				switch (sliderValue) {
					case 39:
						return '$0 - $5,000';
					case 100:
						return '$5,000 - $20,000';
					case 220:
						return '$20,000 - $50,000';
					case 356:
						return '$50,000+';
					default:
						return ''; // Handle other cases or return an empty string
				}
			}
			return;
		}
		if (sliderName === 'basic' && this.selectedOption == 'monthly') {
			switch (sliderValue) {
				case 149:
					return '$0 - $5,000';
				case 295:
					return '$5,000 - $20,000';
				case 595:
					return '$20,000 - $50,000';
				case 795:
					return '$50,000+';
				default:
					return ''; // Handle other cases or return an empty string
			}
		} else if (sliderName == 'basic' && this.selectedOption == 'annually') {
			switch (sliderValue) {
				case 119:
					return '$0 - $5,000';
				case 236:
					return '$5,000 - $20,000';
				case 476:
					return '$20,000 - $50,000';
				case 636:
					return '$50,000+';
				default:
					return ''; // Handle other cases or return an empty string
			}
		} else if (sliderName === 'pro' && this.selectedOption == 'monthly') {
			switch (sliderValue) {
				case 295:
					return '$0 - $5,000';
				case 595:
					return '$5,000 - $20,000';
				case 995:
					return '$20,000 - $50,000';
				case 1495:
					return '$50,000+';
				default:
					return ''; // Handle other cases or return an empty string
			}
		} else if (sliderName === 'pro' && this.selectedOption == 'annually') {
			switch (sliderValue) {
				case 236:
					return '$0 - $5,000';
				case 476:
					return '$5,000 - $20,000';
				case 796:
					return '$20,000 - $50,000';
				case 1199:
					return '$50,000+';
				default:
					return ''; // Handle other cases or return an empty string
			}
		}
	}

	onClickComparePlan() {
		this.comparePlan = !this.comparePlan;
	}
	checkIndianPrice(plan) {
		this.isIndiaPrice = plan.country.toLowerCase() == 'india';
		console.log(this.isIndiaPrice, 'isIndiaPrice');
	}
}
