<div class="main-container">
	<div class="search">
		<tb06 [disabled]="false" [control]="searchControl" [icon]="'fa-search'" [placeHolderTextBox]="'Search the Knowledge Base'"></tb06>
	</div>

	<div class="knowledge-header">
		<h3>Knowledge Base</h3>
		<button class="primary" [matMenuTriggerFor]="menu" #menuTrigger>Ticket Support</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item (click)="moveToModalRoute('submitTicket')">Submit a Ticket</button>
			<button mat-menu-item (click)="moveToModalRoute('viewTickets')">View your Tickets</button>
		</mat-menu>
	</div>
	<app-knowledge-list style="width: 100%;" *ngIf="kbService.knowledgebaseHome$.value"></app-knowledge-list>

	<app-knowledge-view *ngIf="kbService.knowledgebaseShowView$.value"></app-knowledge-view>
	<app-knowledge-sub *ngIf="kbService.knowledgebaseShowSub$.value"></app-knowledge-sub>
	<app-knowledge-frame *ngIf="kbService.knowledgebaseShowFrame$.value"></app-knowledge-frame>

	<app-view-tickets *ngIf="kbService.knowledgebaseShowTickets$.value"></app-view-tickets>
</div>
