<img src="assets/icons/new-tooltip.svg" alt="" placement="bottom" class="i-icon" [ngbTooltip]="tipContent" />
<ng-template #tipContent>
	<span class="text-trns">
		{{ text }}
		<br />
		<span class="link" *ngIf="link !== ''" (click)="goTo()">{{ link }}</span>
	</span>
	<!-- <p [ngStyle]="{ left: imageEditorWidth }" [style.max-width]="width" class="tooltip"
			[class.move-right]="noPlaceLeft" [class.move-left]="noPlaceRight" [class.move-up]="noPlaceBottom"
			[class.auto-width]="text?.length <= 25" [class.active]="makeActive" [class.no-link]="link == ''"
			[class.move-top]="text == 'A tool that uses AI to generate ad copy and creative elements (for Google).'"
			[style.color]="textColor">
			<span class="text-trns">
				{{ text }} &nbsp;
				<span class="link" *ngIf="link !== ''" (click)="goTo()">{{ link }}</span>
			</span>
		</p> -->
</ng-template>
