<div class="icon-wrapper {{ styleClass }}">
	<app-text-input
		styleClass="icon-right {{ inputStyleClass }}"
		[control]="control"
		[maxLength]="maxLength"
		[inputPlaceholder]="inputPlaceholder"
		[inputType]="inputType"
		[errorMessage]="errorMessage"
	></app-text-input>
	<button [disabled]="" (click)="toggleView($event)">
		<img src="assets/icons/eye-{{ !this.toggleEye ? '' : 'slash-' }}icon.svg" alt="hide" />
	</button>
</div>
<!--<div class="error-container" *ngIf="errorMessage"><span>{{errorMessage}}</span></div>-->
