import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ButtonTypeEnum } from './button-type.enum';
import { ButtonClassEnum } from './button-class.enum';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements AfterViewInit {
	@ViewChild('content') content: ElementRef;
	@Input() buttonType = ButtonTypeEnum.Simple;
	@Input() public buttonClass = ButtonClassEnum.Primary;
	@Input() disabled: boolean;
	@Input() public loading: boolean = false;
	@Input() waiting: boolean;
	@Input() icon = '';
	@Input() img = '';
	@Input() iconPlace = 'after'; // can be 'before'
	@Input() selected: boolean;
	@Input() width: string;
	@Input() buttonWidth: any;

	@Output() onClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() dropdownIconPressed: EventEmitter<void> = new EventEmitter<void>();

	public buttonTypeEnum = ButtonTypeEnum;
	public buttonClassEnum = ButtonClassEnum;
	buttonText: string;

	constructor() {}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.buttonText = this.content?.nativeElement?.innerHTML.trim();
	}

	ngAfterViewInit(): void {
		this.buttonText = this.content?.nativeElement?.innerHTML.trim();
	}

	public buttonClickedHandler(event: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (!this.disabled) {
			this.onClick.emit(event);
		}
	}

	public dropdownIconPressedHandler(event: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (!this.disabled) {
			this.dropdownIconPressed.emit();
		}
	}
}
