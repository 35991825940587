import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'searchText',
	pure: false
})
export class FilterTextPipe implements PipeTransform {
	defaultFilterProperty: string;

	constructor() {
		this.defaultFilterProperty = 'name';
	}

	transform(items: any[], filter: string, filterProperty?: string): any {
		if (!items || !filter) {
			return items;
		}

		filterProperty = !filterProperty ? this.defaultFilterProperty : filterProperty;

		const startingWith = items.filter(item => new RegExp('^' + filter, 'i').test(item[filterProperty]));
		const containing = items.filter(
			item => new RegExp(filter, 'i').test(item[filterProperty]) && !startingWith.find(other => item[filterProperty] === other[filterProperty])
		);

		return startingWith.concat(containing);
	}
}
