import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-custom-textarea-with-label',
	templateUrl: './custom-textarea-with-label.component.html',
	styleUrls: ['./custom-textarea-with-label.component.scss']
})
export class CustomTextareaWithLabelComponent implements OnInit {
	@Input() public control: FormControl | AbstractControl = new FormControl();
	@Input() public inputLabel: string = '';
	@Input() public maxLength: number = 80;
	@Input() public placeholder: string = '';
	@Input() public helperText: string = '';
	constructor() {}

	ngOnInit(): void {}
}
