import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdsService } from '../../ads.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CancelModalService } from 'src/app/shakespeare/settings/modals/cancel-modal.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
declare const FB: any;
@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
	activeStep = 1;
	selectedcount = 0;
	googleInput = new FormControl();
	inputError = false;
	loading = true;
	showButton = true;
	connecting = true;
	fbCustomerArray: { id: any; name: string; selected: boolean }[];
	backup: { id: string; name: string; selected: boolean }[];
	extensionValues: { adAccount: string; aiCategory: string } = { adAccount: '', aiCategory: '' };
	fromExtension = false;
	selectedGoogleId: string;
	categories: any;
	navigateToWelcomePage: boolean;
	isLoggedIn: boolean = false;
	buttonClicks: boolean = false;
	setloading: boolean = false;
	platformDefault: string;
	clickedPlatform: any;
	hideUpgrade: boolean = false;
	loadingPlatform: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private cancelModalService: CancelModalService,
		private adsService: AdsService,
		private router: Router,
		private authService: AuthenticationService,
		private backOffice: BackOfficeService
	) {
		// this.fbCustomerArray = [
		// 	{
		// 		name: 'Abstract E',
		// 		id: '7020708821',
		// 		selected: false
		// 	},
		// 	{
		// 		name: 'Abstract E',
		// 		id: '7020708821',
		// 		selected: false
		// 	},
		// ]
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params?.redirect) this.connectFacebook();

			if (params['connected'] == 'true') {
				this.getFbAdIDs();
				this.activeStep = 2;
				this.loading = false;
				this.connecting = false;
				this.navigateToWelcomePage = true;
				this.buttonClicks = true;
			} else {
				this.adsService.validateFbAccessToken().subscribe({
					next: (res: any) => {
						if (!res) {
							this.loading = false;
							this.connecting = false;
							this.adChannel();
							// this.activeStep = 1;
						} else {
							this.router.navigate(['/ads/facebook/dashboard']);
						}
					},
					error: err => {
						this.loading = false;
						this.connecting = false;
						if (err.error[0].code == 'Lolly__Domain__NA__User_Bill__IsNullOrEmpty') {
							this.cancelModalService.openCancelModaldummy('Your plan has been expired, Please contact your product manager.');
						}
					}
				});
			}
		});

		this.loadingPlatform = true;
		this.backOffice.getDefaultSelectedPlatform().subscribe(res => {
			this.platformDefault = res;
			// this.clickedPlatform = localStorage.getItem('connectChoice');
			this.clickedPlatform = 'facebook';
			setTimeout(() => {
				this.loadingPlatform = false;
			}, 3000);
		});
	}

	public connectFacebook() {
		FB.login(
			(response: any) => {
				this.isLoggedIn = true;
				if (response.status === 'connected') {
					// this.validateFbAccessToken(response);
					this.statusChangeCallback(response);
					// this.isLoggedIn = true;
				}
			},
			// {
			// 	scope: 'catalog_management,ads_management,business_management,pages_manage_ads'
			// }
			{
				scope: 'catalog_management,ads_management,ads_read,business_management,email'
			}
		);
	}

	upgradePlan() {
		this.router.navigate(['settings/billing']);
	}

	public fbLogout() {
		FB.getLoginStatus((response: any) => {
			if (response.authResponse === null) {
				this.isLoggedIn = false;
				return;
			}
			FB.logout((response: any) => {
				this.isLoggedIn = false;
			});
		});
	}

	getAdCategory() {
		this.adsService.getFbCategoriesIDs().subscribe({
			next: (res: any) => {
				this.connecting = false;
				if (res) {
					if (res.data.length == 0) {
						this.navigateToWelcomePage = true;
						// this.router.navigate(['/ads/facebook/welcome']);
						// this.router.navigate(['/ads/ai-category?page=facebook']);
					} else {
						this.categories = res.data;
						if (localStorage.getItem('FbAccountId')) {
							this.router.navigate(['/ads/facebook/dashboard']);
						}
					}
				}
			}
		});
	}

	validateFbAccessToken(response): void {
		this.connecting = true;
		this.adsService.validateFbAccessToken().subscribe({
			next: (res: any) => {
				if (!res) {
					this.loading = false;
					this.connecting = false;
					this.activeStep = 1;
				} else {
					this.activeStep = 2;
					this.loading = false;
					this.connecting = false;
					this.getAdCategory();
					this.getFbAdIDs();
				}
			}
		});
	}

	adaccountPagination(payload) {
		this.adsService.addFbAccountspg(payload).subscribe(res => {
			let dummyArray = [];
			if (res.data) {
				res.data.forEach(item => {
					dummyArray.push({
						id: item.id.slice(4),
						name: item.name
					});
				});
				this.fbCustomerArray = [...this.fbCustomerArray, ...dummyArray];
				setTimeout(() => {
					this.adaccountPagination(res.paging.cursors.after);
				}, 100);
			}
		});
	}

	adLoading: boolean = false;
	getFbAdIDs(): void {
		this.loading = true;
		this.adLoading = true;
		this.adsService
			.addFbAccounts()
			.subscribe({
				next: res => {
					// this.adaccountPagination(res.paging.cursors.after);
					this.fbCustomerArray = [];
					let dummyArray = [];

					if (res.data) {
						res.data.forEach(item => {
							dummyArray.push({
								id: item.id.slice(4),
								name: item.name
							});
						});
					}
					this.fbCustomerArray = dummyArray;
					this.fbCustomerArray.forEach(item => {
						item['selected'] = false;
					});
					dummyArray.forEach(item => {
						item['selected'] = false;
					});
					this.backup = dummyArray;
					this.loading = false;
				},
				error: err => {
					if (err.error[0].code == 'Lolly__Api__User_Subscription__AlreadyCanceled') {
						this.cancelModalService.openCancelModaldummy(
							'In order to use this function please speak to your account manager to upgrade your plan.'
						);
						this.router.navigate(['/settings/billing']);
					}
					console.error(err);
				}
			})
			.add(() => {
				this.adLoading = false;
			});
	}

	navigateToNextPage(): void {
		let checkboxes = document.querySelectorAll('.checkbox');
		let selected = [];
		checkboxes.forEach((checkbox: any) => {
			if (checkbox.checked) {
				selected.push(checkbox.id);
			}
		});
		this.selectedGoogleId = selected[0]; // selecting 1st id as there is no option to select multiple ids
		localStorage.setItem('FbAccountId', this.selectedGoogleId);
		localStorage.setItem('FbCategoryId', '');
		localStorage.setItem('FbGroudId', '');
		this.adsService.fbUserAdId$.next(this.selectedGoogleId);
		if (this.navigateToWelcomePage) {
			// this.router.navigate(['/ads/facebook/welcome']);
			this.router.navigate(['/ads/ai-category?page=facebook']);

			return;
		}
		this.router.navigate(['/ads/facebook/connect']);
	}

	public getCookie(name: string) {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = `${name}=`;
		let c: string;

		for (let i: number = 0; i < caLen; i += 1) {
			c = ca[i].replace(/^\s+/g, '');
			if (c.indexOf(cookieName) == 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return '';
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}

	onManualConnect(): void {
		if (this.googleInput.value === '' || this.googleInput.value === null) {
			this.inputError = true;
			return;
		}
		if (!RegExp(/\d{3}\-\d{3}\-\d{4}/).test(this.googleInput.value)) {
			this.inputError = true;
			return;
		}
		this.inputError = false;
		this.activeStep = 2;
		this.getFbAdIDs();
	}

	addHyphen() {
		let text = this.googleInput.value;
		text = text.replace(/([^\d])/g, '').replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '$1$2$3-$4$5$6-$7$8$9$10');
		this.googleInput.setValue(text);
	}

	onSearch(input: string): void {
		this.fbCustomerArray = this.backup.filter((item: { id: string; name: string; selected: boolean }) => {
			return item.name.toLowerCase().includes(input.toLowerCase()) || item.id.toLowerCase().includes(input.toLowerCase());
		});
		this.fbCustomerArray.forEach(item => {
			item.selected = false;
		});
	}

	public goToDashboard() {
		this.router.navigate(['/ads/facebook/connect']);
	}

	private statusChangeCallback(response: any) {
		this.activeStep = 2;
		if (response.authResponse === null) {
			this.isLoggedIn = false;
			return;
		}
		this.isLoggedIn = true;
		if (response.status !== 'connected') return;
		let payload = {
			AccessToken: response.authResponse.accessToken,
			ExpireAt: response.authResponse.expiresIn,
			SignedRequest: response.authResponse.signedRequest,
			UserId: response.authResponse.userID
		};

		this.adsService.sendFbCode(payload).subscribe({
			next: (res: any) => {
				if (res) {
					this.router.navigateByUrl('ads/ai-category?page=facebook');
				} else {
					console.log('send fb code api fails', res);
				}
				// this.adsService.validateFbAccessToken().subscribe({
				// 	next: (res: any) => {
				// 		if (!res) {
				// 			this.loading = false;
				// 			this.connecting = false;
				// 			this.adChannel();
				// 			// this.activeStep = 1;
				// 		} else {
				// 			this.router.navigate(['/ads/ai-category?page=facebook']);
				// 			// this.getAdCategory();
				// 			// this.getFbAdIDs();
				// 		}
				// 	},
				// 	error: err => {
				// 		this.loading = false;
				// 		this.connecting = false;
				// 		if (err.error[0].code == 'Lolly__Domain__NA__User_Bill__IsNullOrEmpty') {
				// 			this.cancelModalService.openCancelModaldummy('Your plan has been expired, Please contact your product manager.');
				// 		}
				// 		console.log(err);
				// 	}
				// });
			},
			error: err => {
				this.loading = false;
				this.connecting = false;

				console.log(err);
			}
		});
	}

	adChannel() {
		this.adsService.adChannel('facebook').subscribe({
			next: (res: any) => {
				if (res) {
					this.showButton = true;
					this.activeStep = 1;
				} else {
					this.showButton = false;
					this.activeStep = 1;
				}
			}
		});
	}

	checkCheckboxs(id) {
		this.selectedcount = 0;
		this.fbCustomerArray.forEach(item => {
			if (item.id == id) {
				item.selected = true;
				this.selectedcount = this.selectedcount + 1;
			} else {
				item.selected = false;
			}
		});
	}
	buttonclick() {
		this.setloading = true;
		setTimeout(() => {
			this.buttonClicks = !this.buttonClicks;
		}, 3000);
	}
}
