import { Action } from '@ngrx/store';

export enum ShakespeareCommonActionTypes {
	LoadAdCopyData = '[Templates Common State] Load AdCopy',
	LoadAdCopyDataSuccess = '[Templates Common State] Load AdCopy Success',
	LoadAdCopyDataFailure = '[Templates Common State] Load AdCopy Failure',

	LoadEmailData = '[Templates Common State] Load Email',
	LoadEmailDataSuccess = '[Templates Common State] Load Email Success',
	LoadEmailDataFailure = '[Templates Common State] Load Email Failure',

	LoadDescriptionData = '[Templates Common State] Load Email',
	LoadDescriptionDataSuccess = '[Templates Common State] Load Email Success',
	LoadDescriptionDataFailure = '[Templates Common State] Load Email Failure',

	LoadBlogData = '[Templates Common State] Load Blog',
	LoadBlogDataSuccess = '[Templates Common State] Load Blog Success',
	LoadBlogDataFailure = '[Templates Common State] Load Blog Failure',

	LoadContentData = '[Templates Common State] Load Content',
	LoadContentDataSuccess = '[Templates Common State] Load Content Success',
	LoadContentDataFailure = '[Templates Common State] Load Content Failure',

	LoadSocialData = '[Templates Common State] Load Social',
	LoadSocialDataSuccess = '[Templates Common State] Load Social Success',
	LoadSocialDataFailure = '[Templates Common State] Load Social Failure',

	LoadFrameworkData = '[Templates Common State] Load Framework',
	LoadFrameworkDataSuccess = '[Templates Common State] Load Framework Success',
	LoadFrameworkDataFailure = '[Templates Common State] Load Framework Failure',

	LoadWizardData = '[Templates Common State] Load Wizard',
	LoadWizardDataSuccess = '[Templates Common State] Load Wizard Success',
	LoadWizardDataFailure = '[Templates Common State] Load Wizard Failure',

	RegisterUserPlan = '[Register Settings] Register User Plan Details',

	RegisterUserDetails = '[Register Profile] Register User Details',

	MostUsedTemplates = '[Register Profile] Register Most Used templates',
	RecentProjects = '[Register Profile] Register Recent Projects',
	FavoriteTemplates = '[Register Profile] Register Favorite Templates',

	AllPlansData = '[Register Plans] Register All Plans'
}

export class LoadAdCopyHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadAdCopyData;
	constructor(public payload) {}
}

export class LoadAdCopyHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadAdCopyDataSuccess;
	constructor(public payload) {}
}

export class LoadAdCopyHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadAdCopyDataFailure;
	constructor(public payload) {}
}

export class LoadEmailHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadEmailData;
	constructor(public payload) {}
}

export class LoadEmailHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadEmailDataSuccess;
	constructor(public payload) {}
}

export class LoadEmailHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadEmailDataFailure;
	constructor(public payload) {}
}

export class LoadDescriptionHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadDescriptionData;
	constructor(public payload) {}
}

export class LoadDescriptionHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadDescriptionDataSuccess;
	constructor(public payload) {}
}

export class LoadDescriptionHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadDescriptionDataFailure;
	constructor(public payload) {}
}

export class LoadBlogHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadBlogData;
	constructor(public payload) {}
}

export class LoadBlogHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadBlogDataSuccess;
	constructor(public payload) {}
}

export class LoadBlogHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadBlogDataFailure;
	constructor(public payload) {}
}

export class LoadContentHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadContentData;
	constructor(public payload) {}
}

export class LoadContentHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadContentDataSuccess;
	constructor(public payload) {}
}

export class LoadContentHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadContentDataFailure;
	constructor(public payload) {}
}

export class LoadSocialHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadSocialData;
	constructor(public payload) {}
}

export class LoadSocialHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadSocialDataSuccess;
	constructor(public payload) {}
}

export class LoadSocialHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadSocialDataFailure;
	constructor(public payload) {}
}

export class LoadFrameworkHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadFrameworkData;
	constructor(public payload) {}
}

export class LoadFrameworkHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadFrameworkDataSuccess;
	constructor(public payload) {}
}

export class LoadFrameworkHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadFrameworkDataFailure;
	constructor(public payload) {}
}

export class LoadWizardHistory implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadWizardData;
	constructor(public payload) {}
}

export class LoadWizardHistorySuccess implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadWizardDataSuccess;
	constructor(public payload) {}
}

export class LoadWizardHistoryFailure implements Action {
	public readonly type = ShakespeareCommonActionTypes.LoadWizardDataFailure;
	constructor(public payload) {}
}

export class RegisterUserPlan implements Action {
	public readonly type = ShakespeareCommonActionTypes.RegisterUserPlan;
	constructor(public payload) {}
}

export class RegisterUserDetails implements Action {
	public readonly type = ShakespeareCommonActionTypes.RegisterUserDetails;
	constructor(public payload) {}
}

export class RegisterMostUsedTemplates implements Action {
	public readonly type = ShakespeareCommonActionTypes.MostUsedTemplates;
	constructor(public payload) {}
}

export class RegisterRecentProjects implements Action {
	public readonly type = ShakespeareCommonActionTypes.RecentProjects;
	constructor(public payload) {}
}

export class RegisterFavoriteTemplates implements Action {
	public readonly type = ShakespeareCommonActionTypes.FavoriteTemplates;
	constructor(public payload) {}
}

export class RegisterAllPlans implements Action {
	public readonly type = ShakespeareCommonActionTypes.AllPlansData;
	constructor(public payload) {}
}

export type ShakespeareCommonAction =
	| LoadAdCopyHistory
	| LoadAdCopyHistorySuccess
	| LoadAdCopyHistoryFailure
	| LoadEmailHistory
	| LoadEmailHistorySuccess
	| LoadEmailHistoryFailure
	| LoadDescriptionHistory
	| LoadDescriptionHistorySuccess
	| LoadDescriptionHistoryFailure
	| LoadBlogHistory
	| LoadBlogHistorySuccess
	| LoadBlogHistoryFailure
	| LoadContentHistory
	| LoadContentHistorySuccess
	| LoadContentHistoryFailure
	| LoadSocialHistory
	| LoadSocialHistorySuccess
	| LoadSocialHistoryFailure
	| LoadFrameworkHistory
	| LoadFrameworkHistorySuccess
	| LoadFrameworkHistoryFailure
	| LoadWizardHistory
	| LoadWizardHistorySuccess
	| LoadWizardHistoryFailure
	| RegisterUserPlan
	| RegisterUserDetails
	| RegisterMostUsedTemplates
	| RegisterRecentProjects
	| RegisterFavoriteTemplates
	| RegisterAllPlans;
