<div class="cancel-container">
	<div class="body">
		<em (click)="closeModal()" class="k-icon k-i-close close"></em>
		<p class="title">
			{{
				data.content !== 'You do not have any AdAccount in it. Please use another Gmail Account.'
					? data.content
						? 'Upgrade Your Plan'
						: 'Cancel Subscription'
					: 'No Access'
			}}
		</p>
		<p class="sub-title">{{ data.content ? data.content : 'To cancel your subscription please reach out your account
			manager.' }}</p>
	</div>
</div>
