<div class="bonus-offer" *ngIf="isBonus && showBonus">
	<div class="con">
		<div class="skew">
			<p class="text">Limited Time Offer</p>
		</div>
	</div>
	<div class="button-con">
		<p class="center-text">
			Upgrade Now
			<span>Get 85% off On PRO Plan for only $9</span>
		</p>
		<button (click)="navigateToBilling()">
			View Offer
			<em class="fas fa-arrow-right"></em>
		</button>
	</div>
</div>
<div class="scroll-wrapper" [class.iphone-css]="iPhoneMobile" style="padding-bottom: env(safe-area-inset-bottom); padding-top: env(safe-area-inset-top);">
	<div class="parent" [ngClass]="{ mobile: checkMob && innerWidth < 578, 'selected-con': isFooter && checkedCount !== 0 }">
		<div class="container" [ngClass]="{ expanded: menuExpanded }">
			<dts-select-container
				(select)="onSelectTemplates($event, config.id)"
				[dragOverItems]="true"
				[selectOnDrag]="true"
				[selectOnClick]="false"
				[disableRangeSelection]="false"
				[(selectedItems)]="selectedItemTemplates"
				[disabled]="false"
			>
				<div class="header-con">
					<h2>
						History
						<app-tooltip
							large="true"
							static="true"
							openModal="true"
							link="Learn more"
							[text]="'Everything you and Shakespeare have created together'"
						></app-tooltip>
					</h2>
					<div class="search">
						<app-search-input (input)="searchTemplate($event.target.value)" inputPlaceholder="Search by name"></app-search-input>
					</div>
					<!-- <p class="hide-mobile">Long press to active selection state.</p> -->
				</div>
				<div *ngIf="libraryData.length === 0 && !isDataLoading" class="no-history">
					<h2 class="no-templates">No history found</h2>
				</div>
				<div *ngIf="notFound && libraryData.length > 0" class="no-history">
					<h2 class="no-templates">No template found matching your search input!</h2>
				</div>
				<div *ngIf="isDataLoading" [ngStyle]="{ width: '100%' }">
					<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
				</div>
				<div class="body-con" [ngClass]="libraryAlteredData.length > 3 ? 'scroll' : 'no-scroll'">
					<div
						class="card"
						[dtsSelectItem]="{ temp: template, index: i }"
						[ngClass]="{ selected: checkForm.controls['val' + 0 + '-' + 1]?.value }"
						[class.selected]="checkForm?.get('val' + i + '-' + pageNumber)?.value"
						*ngFor="let template of libraryAlteredData | paginate: config; let i = index"
						(click)="viewTemplate(template)"
					>
						<form [formGroup]="checkForm" class="check-form">
							<div class="header">
								<div class="name" *ngIf="template.data?.userTemplate.template !== 'Blog Wizard'">
									<img [src]="template.data?.templateData?.img" alt="" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" />
									<p class="title">{{ template.data?.templateData?.title || 'Write From Scratch' }}</p>
								</div>
								<div class="name" *ngIf="template.data?.userTemplate.template === 'Blog Wizard'">
									<img src="assets/templates/wizard.svg" alt="" onerror="this.src='../../../assets/logo/shakespeare-icon.svg'" />
									<p class="title">Blog Wizard ({{ template.data?.templateData?.title || 'Write From
										Scratch' }})</p>
								</div>
								<mat-checkbox
									formControlName="val{{ i }}-{{ pageNumber }}"
									[id]="'toggle' + i + '-' + pageNumber"
									(click)="toggleControl($event, { temp: template, index: i })"
								></mat-checkbox>
							</div>
						</form>
						<div class="about" [id]="'selector' + i">
							<!-- special advance generator -->
							<div
								*ngIf="
									template.data?.userTemplate?.template == 'advanced_completion' ||
									template.data?.userTemplate?.template == 'advanced completion'
								"
								class="title"
							>
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content" *ngIf="item?.advancedCompletion">
										<div class="text" [innerHTML]="item?.advancedCompletion"></div>
									</div>
								</div>
							</div>

							<!-- ad-copy -->
							<div *ngIf="template.data?.templateData?.category == 'ad-copy'" class="title">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content" *ngIf="item?.title">
										<div class="text" [innerHTML]="item?.title" [ngClass]="item?.description ? 'boldTitle' : ''"></div>
									</div>
									<div class="content desc" *ngIf="item?.description">
										<div class="text" [innerHTML]="item?.description"></div>
									</div>
									<div class="content" *ngIf="item?.cta">
										<div class="text" [innerHTML]="item?.cta"></div>
									</div>
								</div>
							</div>

							<!-- email -->
							<div *ngIf="template.data?.templateData?.category == 'email'" class="title">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.subject">
										<div class="text boldTitle" [innerHTML]="item?.subject"></div>
									</div>
									<div class="content">
										<div class="text" [innerHTML]="item?.email"></div>
									</div>
								</div>
							</div>

							<!-- product-desc -->
							<div *ngIf="template.data?.templateData?.category == 'product-description'" class="title">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.mainDescription">
										<div class="text" [innerHTML]="item?.mainDescription"></div>
									</div>
									<div *ngFor="let subItem of item.uspDescription">
										<div class="content-2" *ngIf="subItem?.description">
											<span class="text boldTitle">{{ subItem?.title }}:{{ ' ' }}</span>
											<span class="text" [innerHTML]="subItem?.description"></span>
										</div>
									</div>
								</div>
							</div>

							<!-- blogs -->
							<div *ngIf="template.data?.templateData?.category == 'blog'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div *ngIf="template['data'].userInput?.blogType !== 'blog'" class="case-default">
										<div class="content desc" *ngIf="item?.para" id="para">
											<div class="text" [innerHTML]="item?.para"></div>
										</div>
										<div class="content desc" *ngIf="item.text" id="blog-global">
											<div class="text" [innerHTML]="item.text"></div>
										</div>
										<div class="content desc" *ngIf="item.title">
											<div class="text" [innerHTML]="item.title"></div>
										</div>
									</div>
									<div *ngIf="template['data'].userInput?.blogType === 'blog'" class="full-blog">
										<div class="group">
											<div class="content desc blog-title" *ngIf="item.title">
												<div class="text boldTitle" [innerHTML]="item.title"></div>
											</div>
											<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
											<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
												<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
												<div class="text" [innerHTML]="subitem.para"></div>
											</div>
											<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- content -->
							<div *ngIf="template.data?.templateData?.category == 'content'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.improvedContent">
										<div class="text" [innerHTML]="item?.improvedContent"></div>
									</div>
								</div>
							</div>

							<!-- social -->
							<div *ngIf="template.data?.templateData?.category == 'social'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.title">
										<div class="text" [innerHTML]="item?.title"></div>
									</div>
									<div class="content desc" *ngIf="item?.hashtag">
										<!-- special case for hashtags which is part of social templates -->
										<div class="text" [innerHTML]="item?.hashtag"></div>
									</div>
									<div class="content desc">
										<div class="text" [innerHTML]="item?.caption"></div>
									</div>
								</div>
							</div>

							<!-- framework -->
							<div *ngIf="template.data?.templateData?.category == 'framework'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>

							<!-- video -->
							<div *ngIf="template.data?.templateData?.category == 'video'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>
							<!-- bio -->
							<div *ngIf="template.data?.templateData?.category == 'bio'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.bio">
										<div class="text" [innerHTML]="item?.bio"></div>
									</div>
								</div>
							</div>
							<!-- seo -->
							<div *ngIf="template.data?.userInput?.category == 'seo'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>
							<!-- write from scratch -->
							<div *ngIf="template.data?.templateData?.category == 'seo'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div class="content desc" *ngIf="item?.text">
										<div class="text" [innerHTML]="item?.text"></div>
									</div>
								</div>
							</div>

							<!-- essay -->
							<div *ngIf="template.data?.templateData?.category == 'essay'" class="title blogs">
								<div *ngFor="let item of template.data?.userOutput; let i = index" class="group">
									<div *ngIf="template['data'].userInput?.essayType !== 'essay'" class="case-default">
										<div class="content desc" *ngIf="item?.para" id="para">
											<div class="text" [innerHTML]="item?.para"></div>
										</div>
										<div class="content desc" *ngIf="item.text" id="blog-global">
											<div class="text" [innerHTML]="item.text"></div>
										</div>
										<div class="content desc" *ngIf="item.title">
											<div class="text" [innerHTML]="item.title"></div>
										</div>
									</div>
									<div *ngIf="template['data'].userInput?.essayType === 'essay'" class="full-blog">
										<div class="group">
											<div class="content desc blog-title" *ngIf="item.title">
												<div class="text boldTitle" [innerHTML]="item.title"></div>
											</div>
											<div class="content desc blog-intro" *ngFor="let subitem of item.introduction">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
											<div class="content content-3 desc blog-para" *ngFor="let subitem of item.paragraphs">
												<div class="text semi-boldTitle" [innerHTML]="subitem.title"></div>
												<div class="text" [innerHTML]="subitem.para"></div>
											</div>
											<div class="content desc blog-conc" *ngFor="let subitem of item.conclusion">
												<div class="text" [innerHTML]="subitem"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span class="copy-content" *ngIf="template?.copied">copied</span>
						<div class="actions">
							<div class="icons">
								<span
									(click)="addToFav(i, $event)"
									[matTooltip]="template?.isFav ? 'Remove From Favorite' : 'Add To Favorite'"
									[matTooltipPosition]="'above'"
									[ngClass]="template?.isFav ? 'fav' : 'heart'"
								></span>
								<span class="copy" matTooltip="Copy" [matTooltipPosition]="'above'" (click)="addToCopied(template, $event)"></span>
								<span
									class="project"
									matTooltip="Add To Project"
									[matTooltipPosition]="'above'"
									(click)="openCreate($event, { temp: template, index: i })"
								></span>
								<span class="trash" matTooltip="Delete" [matTooltipPosition]="'above'" (click)="deleteItem(template.id, $event)"></span>

								<!-- <em (click)="openCreate($event, { temp: template, index: i })" class="fas fa-plus-square project"></em>
								<em class="far fa-trash-alt trash" (click)="deleteItem(template.id, $event)"></em> -->
							</div>
							<div class="time-holder">
								{{ template?.since }}
							</div>
							<!-- <p class="time">{{ template.timeStamp }}</p> -->
						</div>
					</div>
					<!--
					<div *ngIf="templateData.length === 0 && noTemplatesFound">
						<h2 class="no-templates">No templates found matching your search input!</h2>
					</div>
					-->
				</div>
			</dts-select-container>
		</div>

		<div class="has-text-centered" *ngIf="libraryAlteredData.length > 0 && this.innerWidth < 1024">
			<shakespeare-spinning-loader class="spinner" *ngIf="loading"></shakespeare-spinning-loader>
			<!-- <pagination-controls previousLabel="" nextLabel="" (pageChange)="getLibraryData($event)" id="server"></pagination-controls> -->
			<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
				<div class="custom-pagination">
					<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
						<a (click)="p.previous()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/left-icon.svg" alt="" /></a>
					</div>
					<div *ngFor="let page of p.pages" class="page" [class.current]="p.getCurrent() === page.value">
						<a (click)="getLibraryData(page.label)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
							<span>{{ page.label }}</span>
						</a>
						<div *ngIf="p.getCurrent() === page.value">
							<span>{{ page.label }}</span>
						</div>
					</div>
					<div class="pagination-next" [class.disabled]="p.isLastPage()">
						<a (click)="p.next()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/right-icon.svg" alt="" /></a>
					</div>
				</div>
			</pagination-template>
		</div>
	</div>
	<div class="has-text-centered" *ngIf="libraryAlteredData.length > 0 && this.innerWidth > 1024">
		<shakespeare-spinning-loader class="spinner" *ngIf="loading"></shakespeare-spinning-loader>
		<!-- <pagination-controls previousLabel="" nextLabel="" (pageChange)="getLibraryData($event)" id="server"></pagination-controls> -->
		<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
			<div class="custom-pagination">
				<div class="pagination-previous" [class.disabled]="p.isFirstPage()">
					<a (click)="p.previous()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/left-icon.svg" alt="" /></a>
				</div>
				<div *ngFor="let page of p.pages" class="page" [class.current]="p.getCurrent() === page.value">
					<a (click)="getLibraryData(page.label)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
						<span>{{ page.label }}</span>
					</a>
					<div *ngIf="p.getCurrent() === page.value">
						<span>{{ page.label }}</span>
					</div>
				</div>
				<div class="pagination-next" [class.disabled]="p.isLastPage()">
					<a (click)="p.next()" (click)="setPage(p.getCurrent())"><img src="../../../assets/icons/right-icon.svg" alt="" /></a>
				</div>
			</div>
		</pagination-template>
	</div>
	<div class="footer" *ngIf="checkedCount > 0">
		<div class="footer-con">
			<div class="left">
				<app-button buttonClass="tertiary" (onClick)="deselectAll()" icon="k-icon k-i-close">{{ checkedCount }} Selected</app-button>
			</div>
			<div class="right">
				<div class="move">
					<app-button buttonClass="primary" (onClick)="openCreateModal()">+ Create Project</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
