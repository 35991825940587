import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class ShopifyService {
	public canGenerate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public editNewlyGeneratedDesc$: BehaviorSubject<string> = new BehaviorSubject('');
	public refreshProducts$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private http: HttpClient) {}

	public getStoreProducts(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-retrive`, request);
	}

	public seacrchProducts(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-search-retrive`, request);
	}

	public getSortedProducts(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-retrive-sort`, request);
	}

	public saveProductDescription(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-description-save`, request);
	}

	public getFilterValues(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-filter-values`, request);
	}

	public getOptimizedProducts(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-optimized-count`, request);
	}

	public downloadBulk(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-bulk-download`, request);
	}

	public uploadBulk(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-bulk-upload`, request);
	}

	public getPerformingProducts(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-performance-metric`, request);
	}

	public getProductsHistory(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-history-retrive`, request);
	}

	public getNewlyGenerated(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-newly-generated`, request);
	}

	public generateBulk(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-bulk-generate`, request);
	}

	public saveBulk(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-bulk-save`, request);
	}

	public bulkFlagRetrive(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/bulk-processing-flag-retrieve`, request);
	}

	public bulkFlagReset(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/bulk-processing-popup-reset`, request);
	}

	public editProduct(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Creator}ecommerce/shopify/product-generated-edit`, request);
	}
}
