<div class="main-container">
	<div class="chevron-links">
		<h6>{{ articleName }}</h6>
	</div>
	<div class="iframe-view">
		<div class="spin-holder" *ngIf="loading">
			<shakespeare-spinning-loader class="spinner"></shakespeare-spinning-loader>
		</div>
		<div #frameparent *ngIf="kBaseIFrame.length > 0" class="iframe-div" [innerHTML]="kBaseIFrame | sanitizeHtml"></div>
	</div>
</div>
