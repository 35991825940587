import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdsService } from '../../ads/ads.service';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditorService } from '../../ads/facebook/editor/editor.service';
import { Subject, map, takeUntil } from 'rxjs';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { GoogleAdsPopupComponent } from './google-ads-popup/google-ads-popup.component';
import { FacebookAdsPopupComponent } from './facebook-ads-popup/facebook-ads-popup.component';
import { CommonPopupWelcomeComponent } from './common-popup-welcome/common-popup-welcome.component';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { PaymentConfirmPopupComponent } from 'src/app/authentication/new-signup/payment-confirm-popup/payment-confirm-popup.component';
import { V3enterpriseService } from 'src/app/authentication/v3enterprise/v3enterprise.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { environment } from 'src/environments/environment';
import { CancelModalService } from '../../settings/modals/cancel-modal.service';
import { ShakespeareService } from '../../shakespeare.service';
declare const FB: any;

@Component({
	selector: 'app-welcome-shakespeare-form',
	templateUrl: './welcome-shakespeare-form.component.html',
	styleUrls: ['./welcome-shakespeare-form.component.scss']
})
export class WelcomeShakespeareFormComponent implements OnInit, OnDestroy {
	file: any;
	url = '';
	stepPage = 1;
	companyDetails: any;
	public companyForm: FormGroup;
	private unsubscriber$ = new Subject<void>();
	// industryControl = new FormControl();
	companyError: boolean = false;
	companyDomainError: boolean;
	invalidDomain: boolean;
	logoError: boolean;
	backgroundImage: string;
	loadingCanvas: boolean;
	logoSkeleton: boolean = false;
	filedId: string;
	showLogoContainer: boolean = false;
	showOtherIndustry = false;
	src: any = '';
	otherCompanyError;
	industryArr: any = [
		{ name: 'Legal' },
		{ name: 'Financial Services' },
		{ name: 'Healthcare' },
		{ name: 'Software' },
		{ name: 'Business Services' },
		{ name: 'Utilities' },
		{ name: 'Professional Services' },
		{ name: 'Food Industry' },
		{ name: 'Fintech' },
		{ name: 'Ai/ML' },
		{ name: 'Aerospace' },
		{ name: 'Real Estate' },
		{ name: 'Other (Please Specify)' }
	];
	// controlA = new FormControl();
	// controlAValue = '';
	budgetInp1Flag: boolean = false;
	companyName: string = '';
	inputFocusState: { [key: string]: boolean } = {};
	websiteDomain: string = '';
	uploadInput: FormGroup;
	loading: boolean = false;

	constructor(
		private router: Router,
		private userStore: Store<UserState>,
		private dialog: MatDialog,
		private adsService: AdsService,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private editorService: EditorService,
		private toastNotificationService: ToastNotificationService,
		private v3enterpriseService: V3enterpriseService,
		private authenticationService: AuthenticationService,
		private userServiceApi: UserServiceApi,
		private backOfficeService: BackOfficeService,
		private cancelModalService: CancelModalService,
		private shakespeareService: ShakespeareService,
		private ngzone: NgZone
	) {
		// this.uploadInput = this.fb.group({
		// 	brandLogo: ['', Validators.required]
		// });

		if (this.authenticationService.checkingFirstTimeUser == undefined || this.authenticationService.checkingFirstTimeUser == null) {
			const cb = () => {
				const platform = this.authenticationService.defaultSelectedPlatform;
				// this.loading = false;
				if (platform == 'facebook') {
					this.router.navigate(['facebook/auth-connect']);
				} else if (platform == 'google') {
					this.router.navigate(['google/auth-connect']);
				} else if (platform == 'fullAccess') {
					this.router.navigate(['overview']);
				} else {
					this.loading = false;
				}
			};

			this.loading = true;
			const brand = this.authenticationService.getBrand(cb);
			brand.subscribe(
				res => {
					// console.log(res);
					if (Object.keys(res.data)?.length) {
						localStorage.setItem('checkingFirstTimeUser', '2');
					} else {
						this.loading = false;
					}

					this.stepPage = parseInt(localStorage.getItem('checkingFirstTimeUser'));
				},
				err => {
					this.loading = false;
				}
			);
		} else {
			this.stepPage = parseInt(localStorage.getItem('checkingFirstTimeUser'));
		}

		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(fileId => {
			this.filedId = fileId.toString();
		});

		// if (this.authenticationService.checkingFirstTimeUser?.data && Object.keys(this.authenticationService.checkingFirstTimeUser?.data).length == 0) {
		// 	this.stepPage = 1;
		// } else if (
		// 	this.authenticationService.checkingFirstTimeUser?.data &&
		// 	Object.keys(this.authenticationService.checkingFirstTimeUser?.data).length != 0 &&
		// 	this.authenticationService.defaultSelectedPlatform == null
		// ) {
		// 	this.stepPage = 2;
		// }

		// if(!this.authenticationService.checkingFirstTimeUser){
		// 	this.stepPage = 1
		// }else{
		// 	this.stepPage = 2
		// }

		// this.stepPage = parseInt(localStorage.getItem('checkingFirstTimeUser'));
		// console.log(this.stepPage);
	}

	ngOnInit(): void {
		this.initCompanyForm();
		this.company();
		this.openPaymentModel();
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.src = localStorage.getItem('urlSrc');
		if (token.platform_name == 'shopify') {
		}
		if (token.bigcommerce_detail_available == 'true') {
			this.authenticationService.signupVersion$.next('quickSignup1');
			return;
		}
		// this.controlA.setValue(this.industryArr[0]);
	}

	openPaymentModel() {
		// let welcomeData = this.v3enterpriseService.welcomeModelData;
		// let dialogRef = this.dialog.open(PaymentConfirmPopupComponent, {
		// 	height: '444px',
		// 	width: '762px',
		// 	data: welcomeData,
		// 	disableClose: true
		// });
		// dialogRef.afterClosed().subscribe(res => {
		// 	// console.log('received data from palette==>', res);
		// });
	}

	connectKeyStorage(clickedButton: string) {
		localStorage.setItem('connectChoice', clickedButton);
	}

	onFileChange(event: any) {
		if (event.target.files) {
			// this.loading = true;
			this.showLogoContainer = true;
			this.backgroundImage = '';
			this.file = event.target.files[0];
			const element = event.currentTarget as HTMLInputElement;
			let fileList: FileList | null = element.files;
			const reader = new FileReader();
			reader.readAsDataURL(this.file);
			reader.onload = (e: any) => {
				this.gf.brandLogo.setValue(e.target.result);
				this.url = e.target.result;
				if (fileList) {
					this.uploadBackground(fileList);
					this.logoError = false;
				}
			};
		}
	}
	onDeleteLogo() {
		this.gf.brandLogo.setValue('');
	}

	company() {
		// this.adsService.company().subscribe((res: any) => {
		// 	this.companyDetails = res.data;
		// 	console.log(this.companyDetails);
		// });
	}

	sendPlatform1(event) {
		// this.controlAValue = event.id + '';
	}

	// !google
	openGooglePopup() {
		this.connectKeyStorage('google');
		// let dialogRef = this.dialog.open(GoogleAdsPopupComponent, {
		// 	height: '400px',
		// 	width: '360px',
		// 	disableClose: true
		// });
		// dialogRef.afterClosed().subscribe(res => {
		// 	if (res) {
		// 		this.router.navigate(['google/auth-connect']);
		// 	}
		// });
		this.backOfficeService.getDefaultSelectedPlatform().subscribe(res => {
			if (res == 'fullAccess' || res == 'google' || res === null) this.openCommonGooglePopup();
			else {
				this.toastNotificationService.sendWarningToast("Sorry you don't have access to connect google");
			}
		});
	}

	openCommonGooglePopup() {
		let payload = 'google';
		this.backOfficeService.defaultSelectedPlatform(payload).subscribe(res => {
			this.connectToGoogle();
		});
	}

	googleRedirectUrl = environment.Flags.production ? 'app.shakespeare.ai' : 'dev.shakespeare.ai';
	// googleRedirectUrl = 'localhost:4200';
	connectToGoogle(): void {
		this.saveCookie('googleRedirectType', 'AiIntelligence');
		const url = this.googleRedirectUrl;
		const id = environment.GOOGLE_ADS_ACCOUNT_ID;
		if (url && id) {
			// window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`;

			this.createOauthWindow(
				`https://accounts.google.com/o/oauth2/v2/auth?client_id=${id}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=https://${url}/googleAuth`
			);
		}
		localStorage.setItem('GoogleLoader', 'true');
	}

	createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
		if (url == null) {
			return null;
		}
		const CLIENT_URL = this.googleRedirectUrl;
		left = screen.width / 2 - width / 2;
		top = screen.height / 2 - height / 2;
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		const popup = window.open(url, name, options);
		const checkPopup = setInterval(() => {
			// if (popup.closed) {
			// 	clearInterval(checkPopup);
			// }

			let connected = localStorage.getItem('googleConnected');
			if (connected) {
				console.log(connected);
				clearInterval(checkPopup);
				popup.close();
				localStorage.removeItem('googleConnected');

				if (connected == 'true') {
					this.shakespeareService.setShowAiCategoryFlowGoogle();
					this.ngzone.run(() => {
						this.router.navigateByUrl('ads/ai-category?page=google');
					});
					// this.router.navigate(['ads/google/auth'], { queryParams: { connected: true } });
				} else this.toastNotificationService.sendErrorToast('Gmail account connection failed!');
			}
			if (!popup || !popup.closed) return;
			clearInterval(checkPopup);
		}, 1000);
	}

	saveCookie(name: string, value: string): void {
		const date = new Date();
		// set expire in 1 day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		const expires = '; expires=' + date.toUTCString();
		// with domain and path
		document.cookie = name + '=' + (value || '') + expires + '; domain=.shakespeare.ai; path=/';
	}
	// !end

	// !facebook
	isLoggedIn: boolean = false;
	connecting = true;
	activeStep = 1;
	navigateToWelcomePage: boolean;
	categories: any;
	fbCustomerArray: { id: any; name: string; selected: boolean }[];
	backup: { id: string; name: string; selected: boolean }[];
	showButton = true;

	openFacebookPopup() {
		this.connectKeyStorage('facebook');
		// let dialogRef = this.dialog.open(FacebookAdsPopupComponent, {
		// 	height: '400px',
		// 	width: '360px',
		// 	disableClose: true
		// });
		// dialogRef.afterClosed().subscribe(res => {
		// 	if (res) {
		// 		// this.router.navigate(['facebook/auth-connect']);
		// 		this.router.navigate(['facebook/auth-connect'], {
		// 			queryParams: { redirect: true }
		// 		});
		// 	}
		// });
		this.backOfficeService.getDefaultSelectedPlatform().subscribe(res => {
			if (res == 'fullAccess' || res == 'facebook' || res === null) this.openCommonFBPopup();
			else {
				this.toastNotificationService.sendWarningToast("Sorry you don't have access to connect facebook");
			}
		});
	}

	openCommonFBPopup() {
		let payload = 'facebook';
		this.backOfficeService.defaultSelectedPlatform(payload).subscribe(res => {
			this.connectFacebook();
		});
	}

	public connectFacebook() {
		FB.login(
			(response: any) => {
				this.isLoggedIn = true;
				if (response.status === 'connected') {
					// this.validateFbAccessToken(response);
					this.statusChangeCallback(response);
					// this.isLoggedIn = true;
				}
			},
			// {
			// 	scope: 'catalog_management,ads_management,business_management,pages_manage_ads'
			// }
			{
				scope: 'catalog_management,ads_management,ads_read,business_management,email'
			}
		);
	}

	private statusChangeCallback(response: any) {
		this.activeStep = 2;
		if (response.authResponse === null) {
			this.isLoggedIn = false;
			return;
		}
		this.isLoggedIn = true;
		if (response.status !== 'connected') return;
		let payload = {
			AccessToken: response.authResponse.accessToken,
			ExpireAt: response.authResponse.expiresIn,
			SignedRequest: response.authResponse.signedRequest,
			UserId: response.authResponse.userID
		};

		this.adsService.sendFbCode(payload).subscribe({
			next: (res: any) => {
				this.adsService.validateFbAccessToken().subscribe({
					next: (res: any) => {
						if (!res) {
							// this.loading = false;
							this.connecting = false;
							this.adChannel('facebook');

							// this.activeStep = 1;
						} else {
							this.getAdCategory();
							this.getFbAdIDs();
							this.shakespeareService.setShowAiCategoryFlowfb();
							this.ngzone.run(() => {
								this.router.navigateByUrl('ads/ai-category?page=facebook');
							});

							// this.router.navigate(['ads/facebook/auth'], { queryParams: { connected: true } }).then(() => {
							// 	window.location.reload();
							// });
						}
					},
					error: err => {
						// this.loading = false;
						this.connecting = false;
						if (err.error[0].code == 'Lolly__Domain__NA__User_Bill__IsNullOrEmpty') {
							this.cancelModalService.openCancelModaldummy('Your plan has been expired, Please contact your product manager.');
						}
						console.log(err);
					}
				});
			}
		});
	}

	adChannel(type) {
		this.adsService.adChannel(type).subscribe({
			next: (res: any) => {
				if (res) {
					this.showButton = true;
					this.activeStep = 1;
				} else {
					this.showButton = false;
					this.activeStep = 1;
				}
			}
		});
	}

	goToFbDashboard() {
		this.router.navigate(['/ads/facebook/dashboard']);
	}

	getAdCategory() {
		this.adsService.getFbCategoriesIDs().subscribe({
			next: (res: any) => {
				this.connecting = false;
				if (res) {
					if (res.data.length == 0) {
						this.navigateToWelcomePage = true;
						// this.router.navigate(['/ads/facebook/welcome']);
						// this.router.navigate(['/']);
					} else {
						this.categories = res.data;
						if (localStorage.getItem('FbAccountId')) {
							this.router.navigate(['/ads/facebook/dashboard']);
						}
					}
				}
			}
		});
	}

	getFbAdIDs(): void {
		// this.loading = true;
		this.adsService.addFbAccounts().subscribe({
			next: res => {
				// this.adaccountPagination(res.paging.cursors.after);
				this.fbCustomerArray = [];
				let dummyArray = [];

				if (res.data) {
					res.data.forEach(item => {
						dummyArray.push({
							id: item.id.slice(4),
							name: item.name
						});
					});
				}
				this.fbCustomerArray = dummyArray;
				this.fbCustomerArray.forEach(item => {
					item['selected'] = false;
				});
				dummyArray.forEach(item => {
					item['selected'] = false;
				});
				this.backup = dummyArray;
				// this.loading = false;
			},
			error: err => {
				if (err.error[0].code == 'Lolly__Api__User_Subscription__AlreadyCanceled') {
					this.cancelModalService.openCancelModaldummy('In order to use this function please speak to your account manager to upgrade your plan.');
					this.router.navigate(['/settings/billing']);
				}
				console.error(err);
			}
		});
	}
	// !end

	openCreativePopup() {
		this.dialog.open(CommonPopupWelcomeComponent, {
			height: '320px',
			width: '900px',
			disableClose: false
		});
	}

	public initCompanyForm() {
		this.companyForm = this.fb.group({
			industry: [null, [Validators.required]],
			otherIndustry: ['', Validators.pattern('[A-Za-z\\s]+')],
			// companyName: ['', [Validators.required, Validators.pattern('[A-Za-z0-9.-\\s]+')]],
			companyName: ['', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])[A-Za-z0-9.-\\s]*$')]],
			domain: [''],
			brandLogo: ['']
		});
	}
	get gf() {
		return this.companyForm.controls;
	}
	industryResult(event: any) {
		// console.log(event);
		if (event.name.toLowerCase().includes('other')) {
			this.showOtherIndustry = true;
			this.companyForm.get('otherIndustry').addValidators(Validators.required);
		} else {
			this.companyForm.get('otherIndustry').clearValidators();
			this.companyForm.controls.otherIndustry.setValue('');
			this.showOtherIndustry = false;
		}
		this.gf.industry.patchValue(event);
	}

	saveBranding() {
		// console.log('saveBranding');
		// this.loading = true;
		if (this.companyForm.controls.companyName.value == '') {
			this.companyError = true;
			return;
		}
		//  else if (this.companyForm.controls.domain.value == '') {
		// 	this.companyError = false;
		// 	// this.companyDomainError = true;
		// 	return;
		// }
		else if (this.gf.industry.value.name == '') {
			this.companyError = true;
			return;
		} else if (this.showOtherIndustry) {
			if (this.companyForm.controls.otherIndustry.value == '') {
				// console.log(this.companyForm.controls.otherIndustry.value, 'aksd');
				this.otherCompanyError = true;
				return;
			}
		}
		this.loading = true;
		this.logoError = false;
		this.companyError = false;
		this.invalidDomain = false;
		this.companyDomainError = false;
		this.otherCompanyError = false;
		const param: any = {
			user: {
				user_filed_id: this.filedId
			},
			data: {
				logo: this.backgroundImage,
				industry: this.gf.industry.value.name,
				company_name: this.companyForm.controls.companyName.value,
				company_domain: this.companyForm.controls.domain.value,

				color: {
					primary: '#FFFFFF',
					secondary: '#FFFFFF'
				}
			}
		};
		if (this.showOtherIndustry) {
			param.data.industry_details = this.companyForm.controls.otherIndustry.value;
		}

		this.editorService.saveBranding(param).subscribe(
			res => {
				// this.getBranding();

				// this.toastNotificationService.sendSuccessToast('Company Details Updated!', 1000);

				this.loading = false;
				this.stepPage++;
			},
			err => {
				this.toastNotificationService.sendErrorToast('Internal server error!', 1000);
			}
		);
	}
	otherCompanyChange() {
		this.otherCompanyError = false;
	}
	companyInpChange() {
		this.companyError = false;
	}
	// domainInpChange() {
	// 	this.companyDomainError
	// }

	checkDomain(domain: string) {
		if (domain.length === 0) {
			this.companyDomainError = false;
		} else {
			// let regEx = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,}|\.in|\.com|\.ai)+/;
			const regEx = /^([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
			this.companyDomainError = !regEx.test(domain);
		}
	}
	MyCustomValidator(control: AbstractControl) {
		// returns null if value is valid, or an error message otherwise
		let isValid = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g.test(
			control.value
		);
		// reg.test(control.value);
		return isValid ? null : { myCustomError: 'Pleae enter the valid domain' };
	}

	public uploadFile(event: Event): void {
		this.logoSkeleton = true;
		this.showLogoContainer = true;
		this.backgroundImage = '';
		const element = event.currentTarget as HTMLInputElement;
		let fileList: FileList | null = element.files;
		let imgURl: string;
		if (fileList) {
			const img = fileList[0];
			this.uploadBackground(fileList);
			this.logoError = false;
		}
	}

	public uploadBackground(fileList: any): void {
		this.loadingCanvas = true;

		let token: any = localStorage.getItem('decodedJwtIo');
		token = JSON.parse(token);
		let userFieldId = token.user_filed_id;

		if (fileList) {
			const formData = new FormData();
			formData.append('file', fileList[0]);
			let body = {
				user: {
					user_filed_id: userFieldId
				}
			};
			formData.append('body', JSON.stringify(body));
			this.editorService.uploadBGImage(formData).subscribe(
				(res: any) => {
					this.loadingCanvas = false;
					this.backgroundImage = res?.imageUrl;
					this.updateBackgroundImage(res?.imageUrl);
					// this.emitBackground();
				},
				err => {
					//this.loadingCanvas = false;
					// this.toastNotificationService.sendErrorToast('Something went wrong');
				}
			);
		}
	}
	public updateBackgroundImage(image: string): void {
		this.backgroundImage = image;
		this.loading = false;
		this.logoSkeleton = false;
	}

	public signOut(): void {
		this.authenticationService.logout();
	}

	ngOnDestroy(): void {
		localStorage.setItem('showTourPopup', 'true');
		localStorage.setItem('showAiCategoryTourPopup', 'true');
	}

	navigateToOverview() {
		console.log('this is working');
		this.router.navigateByUrl('overview');
	}
}
