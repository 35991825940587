import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageKey } from '../../../_models/local-storage-key';

@Component({
	selector: 'app-tour-overlayer-card',
	templateUrl: './tour-overlayer-card.component.html',
	styleUrls: ['./tour-overlayer-card.component.scss']
})
export class TourOverlayerCardComponent implements OnInit, AfterViewInit {
	userFName: string = '';
	userLName: string = '';
	constructor(public dialogRef: MatDialogRef<TourOverlayerCardComponent>) {}

	ngOnInit(): void {
		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userLName = storageJwt['user_lastname'];
	}

	ngAfterViewInit() {}
	closeDialog(response: boolean = false) {
		this.dialogRef.close(response);
	}
}
