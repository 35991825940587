<form [formGroup]="generatorForm">
	<app-text-input-with-label
		[control]="generatorForm.controls['name']"
		[maxLength]="80"
		placeholder="Enter here..."
		inputLabel="Product Name"
	></app-text-input-with-label>
	<div class="input">
		<app-custom-textarea-with-label
			[control]="generatorForm.controls['description']"
			[maxLength]="1200"
			placeholder="Write an article about..."
			inputLabel="Product Description"
		></app-custom-textarea-with-label>
	</div>
	<div class="d-part input showMobile">
		<div class="input-box">
			<div class="label dropdown">
				<label>No. of Outputs</label>
			</div>
			<app-custom-drpdown-op1
				[data]="maxOutputArray"
				[dropdownFormControl]="generatorForm?.get('numOutputs')"
				[useCustom]="false"
				[hideClearButton]="true"
				[placeHolder]="'Output'"
			></app-custom-drpdown-op1>
		</div>
	</div>
	<div class="d-part input output-tone">
		<div class="input-box">
			<div class="d-flex">
				<div class="label dropdown">
					<label>Output Tone</label>
					<app-tooltip text="You can type your own Tone"></app-tooltip>
				</div>
			</div>
			<app-custom-drpdown-op1
				[data]="outputTone"
				(selectionChange)="changeToneSourceEvent.emit($event)"
				[useCustom]="true"
				[dropdownFormControl]="(generatorForm?.controls)['outputTone']"
				[placeHolder]="'Select/Enter new'"
				[noError]="true"
				[searchEnable]="true"
			></app-custom-drpdown-op1>
		</div>
	</div>
	<div class="input-lang">
		<div class="label dropdown">
			<label>Output Language (Optional)</label>
		</div>
		<app-custom-drpdown-op1
			[data]="outputLang"
			(selectionChange)="changeLangSource($event)"
			[dropdownFormControl]="generatorForm?.get('outputLang')"
			[useCustom]="false"
			[attachTo]="'ng-select'"
			[placeHolder]="'Select Language'"
			[searchEnable]="true"
		></app-custom-drpdown-op1>
	</div>
	<div class="input toggle">
		<div class="d-flex align-item-center">
			<div class="label">
				<label>Add More Details (Optional)</label>
			</div>
			<app-tooltip noPlaceBottom="true" text="Describe more about the product for better output"></app-tooltip>
		</div>
		<app-switch (toggle)="checkToggleView($event)" [value]="advanceOptions"></app-switch>
	</div>
	<div *ngIf="advanceOptions" class="advance">
		<div class="input usp">
			<div class="d-flex align-item-center my-1">
				<div class="label">
					<label>USP</label>
				</div>
				<app-tooltip>Add all the features of your product</app-tooltip>
			</div>
			<app-input-with-add-more
				[inputPlaceholders]="uspPlaceHolders"
				[control]="generatorForm.get('usps')"
				errorMessage="Please fill this field."
				[maxInput]="5"
			></app-input-with-add-more>
		</div>
		<div class="input spec">
			<div class="d-flex align-item-center my-1">
				<div class="label">
					<label>Specification</label>
				</div>
				<app-tooltip>Add Basic Details about your product</app-tooltip>
			</div>
			<app-input-with-add-more
				[inputPlaceholders]="specPlaceHolders"
				[control]="generatorForm.get('specifications')"
				errorMessage="Please fill this field."
				[maxInput]="5"
			></app-input-with-add-more>
		</div>
	</div>
</form>
