<form [formGroup]="generatorForm">
	<app-text-input-with-label
		[control]="generatorForm.controls['name']"
		[maxLength]="80"
		placeholder="Nike..."
		inputLabel="Product Name"
	></app-text-input-with-label>

	<div class="input">
		<app-custom-textarea-with-label
			[control]="generatorForm.controls['description']"
			[maxLength]="400"
			placeholder="Men's Classic Cortez Leather Running Shoes which provides extra comfort to your feet with a perfect grip"
			inputLabel="Product Description"
		></app-custom-textarea-with-label>
	</div>
	<div class="input toggle">
		<div class="d-flex">
			<div class="label">
				<label>Advance Option</label>
			</div>
			<app-tooltip noPlaceBottom="true" text="Describe more about the product for better output" style="margin-top: 2px;"></app-tooltip>
		</div>
		<app-switch (toggle)="checkToggleView($event)" [value]="advanceOptions"></app-switch>
	</div>
	<div *ngIf="advanceOptions" class="advance">
		<div class="input usp">
			<div class="d-flex align-items-center title">
				<div class="label">
					<label>USP</label>
				</div>
				<app-tooltip text="Add all the features of your product" [noPlaceLeft]="true" style="margin-top: 2px;"></app-tooltip>
			</div>
			<app-input-with-add-more
				[inputPlaceholders]="uspPlaceHolders"
				[control]="generatorForm.get('usps')"
				errorMessage="Please fill this field."
				[maxInput]="5"
			></app-input-with-add-more>
		</div>
		<div class="input spec">
			<div class="d-flex align-items-center title">
				<div class="label">
					<label>Specification</label>
				</div>
				<app-tooltip text="Add Basic Details about your product" style="margin-top: 2px;"></app-tooltip>
			</div>
			<app-input-with-add-more
				[inputPlaceholders]="specPlaceHolders"
				[control]="generatorForm.get('specifications')"
				errorMessage="Please fill this field."
				[maxInput]="5"
			></app-input-with-add-more>
		</div>
		<div class="input">
			<div class="input-lang">
				<div class="label dropdown">
					<label>Output Language (Optional)</label>
				</div>
				<app-custom-drpdown-op1
					[data]="outputLang"
					(selectionChange)="changeLangSource($event)"
					[dropdownFormControl]="generatorForm?.get('outputLang')"
					[useCustom]="false"
					[attachTo]="'ng-select'"
					[placeHolder]="'Select Language'"
					[searchEnable]="true"
				></app-custom-drpdown-op1>
			</div>
		</div>
	</div>
</form>
