import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const Stripe: (arg0: string) => any;

@Injectable({
	providedIn: 'root'
})
export class PaymentService {
	private static STRIPE_KEY = environment.STRIPE_KEY;

	constructor() {}

	redirectToCheckout(sessionId: string) {
		// type casting the key is mandatory, as stripe() accepts only string
		const stripe = Stripe(`${PaymentService.STRIPE_KEY}`);
		stripe.redirectToCheckout({ sessionId });
	}
}
