import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
	@Input() public control: FormControl | AbstractControl = new FormControl('');
	@Input() public inputId: string = '';
	@Input() public inputType: string = 'text';
	@Input() public inputPlaceholder: string = 'enter text';
	@Input() public styleClass: string;
	@Input() public errorMessage: string = null;
	@Input() public maxLength: number = 600;
	@Output() public keyInputEvent: EventEmitter<any> = new EventEmitter();
	constructor() {}
	ngOnInit(): void {}
}
