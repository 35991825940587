<div class="cancel-container">
	<div class="body">
		<em (click)="proceedToCancel()" class="k-icon k-i-close close"></em>
		<div class="circle">
			<em class="k-icon k-i-check k-i-checkmark tick"></em>
		</div>
		<p class="title">Your subscription has been canceled.</p>
		<p class="sub-title">
			Parting is such sweet sorrow. We do hope to see you again soon.
		</p>
		<p class="sub-title-mobile">
			Parting is such sweet sorrow.
			<br />
			We do hope to see you again soon.
		</p>
	</div>
	<div class="footer">
		<button class="mind" (click)="proceedToCancel()">Go to Account</button>
	</div>
</div>
