import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getShopifyId, UserState } from 'src/app/shared/state/user/user.reducer';
import { ShakespeareService } from '../shakespeare.service';
import { BigCommerceService } from './big-commerce.service';
import { BcModalComponent } from './bc-modal/bc-modal.component';

@Component({
	selector: 'app-big-commerce',
	templateUrl: './big-commerce.component.html',
	styleUrls: ['./big-commerce.component.scss']
})
export class BigCommerceComponent implements OnInit, OnDestroy {
	historyData = [];
	otherItems = [];
	menuExpanded: boolean;
	private unsubscriber$ = new Subject<void>();
	shopifyId: string;
	totalNonPerforming: number;
	totalPerforming: number;
	totalProducts = 1; // to overcome divide by zero error in initial state
	optimizedProducts = 0;
	unOptimizedProducts = 0;
	historyLoading: boolean;
	prodLoading: boolean;
	optLoading: boolean;
	allProducts = [];
	newlyLoading: boolean;
	perfLoading: boolean;
	Math = Math;
	flagLoading: boolean;
	scrWidth: number;
	newItems = [];
	popupFlag: boolean;

	constructor(
		private router: Router,
		private shakespeareService: ShakespeareService,
		private bigCommerceService: BigCommerceService,
		private userStore: Store<UserState>,
		private dialog: MatDialog
	) {
		this.shakespeareService.shopifyActive$.next(true);
		this.userStore.pipe(select(getShopifyId), takeUntil(this.unsubscriber$)).subscribe(id => {
			this.shopifyId = id;
		});
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?): void {
		this.scrWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.scrWidth = window.innerWidth;
		this.getOptimizedProducts();
		this.getPerformingProducts();
		this.getProductsHistory();
		this.getAllProducts();
		this.getFlags();
	}

	getOptimizedProducts(): void {
		this.optLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {}
		};
		this.bigCommerceService
			.getOptimizedProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					this.optLoading = false;
					this.optimizedProducts = response['data']['optimizedProducts'];
					this.unOptimizedProducts = response['data']['unoptimizedProducts'];
				},
				error: () => {
					this.optLoading = false;
				}
			});
	}

	getAllProducts(page = 1): void {
		this.prodLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {
				limit: 4,
				page: page,
				// availability: "",
				sort: '',
				order: ''
				// category_id: 0,
				// brand_id: 0,
			}
		};
		this.bigCommerceService
			.getStoreProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					if (response['data'][0]['debugMessage'] == 'Page not found') {
						return;
					}
					this.allProducts = response['data'];
					this.allProducts.forEach(item => {
						item.optimizationScore = Math.round(item.optimizationScore);
					});
					this.prodLoading = false;
				},
				error: () => {
					this.prodLoading = false;
				}
			});
	}

	getNewlyGenerated(page = 1): void {
		this.newlyLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {
				limit: 4,
				page: page
			}
		};
		this.bigCommerceService
			.getNewlyGenerated(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					this.newItems = response['data'].generatedData;
					this.newlyLoading = false;
				},
				error: () => {
					this.newlyLoading = false;
				}
			});
	}

	getPerformingProducts(): void {
		this.perfLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {}
		};
		this.bigCommerceService
			.getPerformingProducts(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					this.totalNonPerforming = response['data']['totalNonPerforming'];
					this.totalPerforming = response['data']['totalPerforming'];
					this.totalProducts = this.totalPerforming + this.totalNonPerforming || 1;
					this.perfLoading = false;
				},
				error: () => {
					this.perfLoading = false;
				}
			});
	}

	getProductsHistory(): void {
		this.historyLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {
				page_number: 1
			}
		};
		this.bigCommerceService
			.getProductsHistory(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					this.historyLoading = false;
					this.historyData = response['data'].history;
				},
				error: () => {
					this.historyLoading = false;
				}
			});
	}

	getFlags(): void {
		this.flagLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {}
		};
		this.bigCommerceService
			.bulkFlagRetrive(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					this.flagLoading = response['flags'].generatedFlag;
					this.popupFlag = response['flags'].popUpFlag;
					this.bigCommerceService.canGenerate$.next(!this.flagLoading);
					if (this.popupFlag) {
						this.openSuccessModal();
					}
					if (!this.flagLoading) {
						this.getNewlyGenerated();
					} else {
						setTimeout(() => {
							this.getFlags();
						}, 20000);
					}
				}
			});
	}

	openSuccessModal(): void {
		let modalConfig;
		let isMobile = this.scrWidth < 780 ? true : false;
		if (isMobile) {
			modalConfig = {
				width: '90%',
				height: '316px',
				maxWidth: '100%',
				hasBackdrop: false
			};
		} else {
			modalConfig = {
				width: '436px',
				height: '316px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(BcModalComponent, {
			...modalConfig,
			panelClass: 'modal-wrapper',
			disableClose: true,
			data: {
				title: 'Successful!',
				desc: 'Your new descriptions are generated successfully!',
				isCancel: false,
				img: 'assets/icons/big-check.svg',
				cta: 'View Descriptions'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.confirm) {
				this.viewAllNewProducts();
			}
			this.resetFlag();
		});
	}

	resetFlag(): void {
		this.flagLoading = true;
		const payload = {
			user: {
				user_filed_id: this.shopifyId
			},
			data: {}
		};
		this.bigCommerceService
			.bulkFlagReset(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: response => {
					this.flagLoading = response['flags'].generatedFlag;
				},
				error: () => {
					this.flagLoading = false;
				}
			});
	}

	viewAllProducts(): void {
		this.router.navigate(['big-commerce/all-products']);
	}

	viewAllNewProducts(): void {
		this.router.navigate(['big-commerce/all-products'], { queryParams: { type: 'newly-generated' } });
	}

	viewProducts(type: string): void {
		this.router.navigate(['big-commerce/all-products'], { queryParams: { type: type } });
	}

	showHistory(): void {
		this.router.navigate(['big-commerce/all-products'], { queryParams: { type: 'history' } });
	}

	openProduct(id: number): void {
		this.router.navigate(['big-commerce/generate'], { queryParams: { productId: id } });
	}

	ngOnDestroy(): void {
		this.unsubscriber$.unsubscribe();
	}
}
