<div class="expandable-wrapper">
	<div class="expandable-value" (click)="toggle()">
		<div class="option">
			<img width="28" height="28" alt="logo" src="{{ selectedOption?.img }}" />
			<span>{{ selectedOption?.title }}</span>
		</div>
		<img class="caret" src="assets/shakespeare/dropdown-caret.svg" width="10" height="5" />
	</div>
	<div class="expandable-options" [ngClass]="{ expanded: expanded }">
		<div class="search">
			<app-text-input
				styleClass="search icon"
				[control]="searchControl"
				inputPlaceholder="Search by name"
				(keydown)="filterTemplates($event.target.value)"
			></app-text-input>
		</div>
		<div class="option" *ngFor="let template of filteredTemplate" (click)="changeOptions.emit(template); toggle()">
			<img width="28" height="28" alt="logo" src="{{ template.img }}" />
			<span>{{ template.title }}</span>
		</div>
	</div>
</div>
