export const CURRENCIES = [
	{
		symbol: '$',
		name: 'USD',
		value: 'USD'
	},
	{
		symbol: '£',
		name: 'GBP',
		value: 'USD'
	},
	{
		symbol: '₹',
		name: 'INR',
		value: 'INR'
	},
	{
		symbol: '€',
		name: 'EUR',
		value: 'EUR'
	}
];
