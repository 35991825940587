<!-- <div class="google-auth-container" *ngIf="clickedPlatform === 'google' || platformDefault === 'google'"> -->
<div class="google-auth-container" *ngIf="platformDefault === 'google' || platformDefault === 'fullAccess'">
	<div class="stepper" *ngIf="activeStep">
		<div class="step step-1" [ngClass]="{ active: activeStep == 0 || activeStep == 1 || activeStep == 2 }"></div>
		<div class="step step-2" [ngClass]="{ active: activeStep == 2 }"></div>
	</div>

	<!-- <shakespeare-spinning-loader style="height: 100%;" *ngIf="connecting"></shakespeare-spinning-loader>-->

	<!-- !step 1 -->
	<div *ngIf="activeStep == 1 && !connecting" class="step1-content">
		<div class="header" *ngIf="showButton">
			<p class="title">Let's analyse your account...</p>
			<p class="sub-title">Connect your Google Ad account to improve ad campaign performance</p>
		</div>
		<div class="header" *ngIf="!showButton">
			<p class="sub-title">In order to use this function please speak to your account manager to upgrade your plan.</p>
		</div>
		<div class="connect-card-holder" *ngIf="showButton">
			<div class="connect-card auto">
				<p class="title">Connect with Google</p>
				<p class="sub-title">Link your email and in the following step, sync the relevant Google Ads account</p>
				<div class="cta" (click)="connectToGoogle()">
					<!-- <img src="assets/images/rec-google-btn.png" /> -->
					<div class="google_img">
						<img src="assets/images/fav-gog-icon.svg" />
					</div>
					<div class="google_txt">
						<p class="m-0">Link with Google</p>
					</div>
				</div>
			</div>
			<span *ngIf="false">or</span>
			<div *ngIf="false" class="connect-card manual">
				<p class="title">Link manually</p>
				<p class="sub-title">Submit your Google Ads account or MCC and access request to get connected</p>
				<div class="cta">
					<input
						type="text"
						(keyup)="addHyphen()"
						(keyup.enter)="onManualConnect()"
						[ngClass]="{ error: inputError || (googleInput.invalid && googleInput.touched) }"
						[formControl]="googleInput"
						placeholder="123-456-7890"
					/>
					<app-button (onClick)="onManualConnect()" buttonClass="primary google-ad-btn-style">Connect Google Ads Account</app-button>
				</div>
			</div>
		</div>
	</div>

	<!-- !step 2 -->
	<div *ngIf="activeStep == 2" class="step2-content">
		<div class="header">
			<p class="title">Select your Google Ads ID</p>
		</div>
		<div class="content">
			<div class="search-box">
				<app-search-input (input)="onSearch($event.target.value)" inputPlaceholder="Search Google Ads ID..."></app-search-input>
			</div>

			<!-- <shakespeare-spinning-loader *ngIf="loading"></shakespeare-spinning-loader> -->

			<!-- <div class="list-con" *ngIf="!loading"> -->
			<div class="list-con">
				<div class="list-box">
					<!-- *skeleton loader -->
					<ngx-skeleton-loader
						count="2"
						appearance="line"
						[theme]="{ width: '100%', height: '112px' }"
						*ngIf="googleAdLoading"
						style="width: '100%'; height: '112px';"
					></ngx-skeleton-loader>

					<ng-container *ngIf="!googleAdLoading">
						<div class="list-item" *ngFor="let item of googleCustomerIds">
							<input
								(click)="checkbox(item)"
								[(ngModel)]="item.checked"
								[id]="item.id"
								[disabled]="!item?.readAccess"
								class="checkbox"
								type="checkbox"
							/>
							<div class="details">
								<p class="name">{{ item.name }}</p>
								<p class="id">{{ item.id }}</p>
							</div>
							<img src="assets/shakespeare/google-ads-icon.svg" alt="" />
						</div>
					</ng-container>
				</div>
			</div>
			<app-button [disabled]="!selected" (onClick)="navigateToNextPage()" [width]="100" buttonClass="primary">Connect</app-button>
		</div>
	</div>
</div>

<!-- !not connected -->
<div
	class="connect-container"
	*ngIf="(platformDefault === 'fullAccess' && clickedPlatform === 'facebook') || (platformDefault === 'fullAccess' && !clickedPlatform)"
>
	<!-- <div class="connect-container"
	*ngIf="((platformDefault === 'fullAccess' && clickedPlatform === 'facebook') || (platformDefault === 'fullAccess' && !clickedPlatform)) && !loadingPlatform"> -->
	<ng-container *ngIf="!loadingPlatform">
		<div class="connect-video-div">
			<video src="assets/googlebounce-1.mp4" type="video/mp4" width="502" height="200" loop autoplay></video>
		</div>
		<div class="connect-span-1">
			<span>You haven’t connected Google yet</span>
		</div>
		<div class="connect-span-2">
			<span>With Shakespeare AI you can easily start improving your Google Ads performance.</span>
		</div>
		<div class="connect-button-div">
			<button class="connect-button" (click)="connectToGoogle()">
				<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/google-nav.svg" alt="google logo" />
				<span>Connect Google</span>
			</button>
		</div>
	</ng-container>
	<!-- <shakespeare-spinning-loader *ngIf="loadingPlatform"></shakespeare-spinning-loader> -->
</div>

<!-- !upgrade plan -->
<div class="connect-container" *ngIf="platformDefault !== 'fullAccess' && platformDefault !== 'google'">
	<!-- <div class="connect-container"
	*ngIf="platformDefault !== 'fullAccess' && platformDefault !== 'google' && !loadingPlatform"> -->
	<ng-container *ngIf="!loadingPlatform">
		<div class="connect-video-div">
			<video src="assets/googlebounce-1.mp4" type="video/mp4" width="502" height="200" loop autoplay></video>
		</div>
		<div class="connect-span-1">
			<span>Upgrade to activate Google.</span>
		</div>
		<div class="connect-span-2">
			<span>To connect Google Ads and start improving performance upgrade to the Pro Plan</span>
		</div>
		<div class="connect-button-div" (click)="upgradePlan()">
			<button class="connect-button">
				<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/google-nav.svg" alt="google logo" />
				<span>Upgrade Plan</span>
			</button>
		</div>
	</ng-container>
</div>
<div class="loader-container">
	<shakespeare-spinning-loader *ngIf="loadingPlatform"></shakespeare-spinning-loader>
</div>
<!-- <shakespeare-spinning-loader *ngIf="loadingPlatform"></shakespeare-spinning-loader> -->
