<!-- <h2 mat-dialog-title></h2> -->

<div class="error-container">
	<div class="design"></div>
	<div>
		<img src="assets/browser.png" height="100px" width="100px" alt="" />
	</div>

	<div class="heading">
		<ng-container>
			{{ data.status }}
		</ng-container>
	</div>
	<div class="sub-heading">
		<ng-container *ngIf="data?.status == 500">
			Oops, Something went wrong.
		</ng-container>
		<ng-container *ngIf="data?.status == 400">
			{{ data?.status }}
		</ng-container>
	</div>
	<div class="third-heading">
		<ng-container *ngIf="data?.status == 500">
			Please try to reload the page
		</ng-container>
	</div>
</div>

<mat-dialog-actions align="end">
	<button [mat-dialog-close]="true" (click)="reload()" class="outline-btn">Reload</button>
</mat-dialog-actions>
