<div class="grid-section">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-gap-left"></div>
		<div class="grid-section-two-inner container">
			<div class="outside-form">
				<div class="shakespeare-logo">
					<img class="logo-shakespeare" src="assets/logo/shakespeare-full-logo.svg" />
					<!-- <img src="assets/logo/lolly-logo.svg" /> -->
				</div>
				<div class="text-description">
					<h2>{{ pageType }}</h2>
					<p>
						Please enter a new password for your account
					</p>
				</div>

				<form [formGroup]="passwordForm">
					<div class="full-text-input">
						<div class="label">{{ 'Password' }}</div>
						<app-input-field-password
							[showValidIcon]="true"
							[hideError]="false"
							class="title-input"
							[inputPlaceholder]="'Enter your password'"
							[control]="passwordForm.get('password')"
							[showCustomIcon]="passwordValidationIcon"
							[errorMessage]="'Strong password required with 1 upper, 1 lowercase, 1 special character and 1 number'"
							[iconWidth]="'14px'"
							[marginRight]="'8px'"
						></app-input-field-password>
					</div>
					<div class="full-text-input l-margin">
						<div class="label c-p">{{ 'Confirm Password' }}</div>
						<app-input-field-password
							[showValidIcon]="true"
							[hideError]="false"
							class="title-input"
							[inputPlaceholder]="'Enter password again'"
							[control]="passwordForm.get('confirmPassword')"
							[showCustomIcon]="confirmPasswordValidationIcon"
							[errorMessage]="'Confirm password is not same as password'"
							[iconWidth]="'12px'"
							[marginRight]="'8px'"
						></app-input-field-password>
					</div>

					<div (click)="onSubmit()" class="signin-btn-wrapper">
						<div class="btn">
							<div class="items">
								<div class="text">{{ 'Continue' }}</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="grid-gap-right"></div>
	</div>
</div>
