import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'shakespeare-spinning-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class ShakespeareSpinningLoaderComponent implements OnInit {
	@Input() public rotating: boolean;
	@Input() public background: string;

	constructor() {}

	ngOnInit(): void {}
}
