import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-toggle-switch',
	templateUrl: './toggle-switch.component.html',
	styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
	@Input() public value: boolean = false;
	@Output() public toggle: EventEmitter<boolean> = new EventEmitter();
	constructor() {}

	ngOnInit(): void {}
}
