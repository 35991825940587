import { CancelledComponent } from './cancelled/cancelled.component';
import { takeUntil } from 'rxjs/operators';
import { FeedbackComponent } from './feedback/feedback.component';
import { WhyCancellingComponent } from './why-cancelling/why-cancelling.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancelStep1Component } from './cancel-step1/cancel-step1.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CancelOfferComponent } from './cancel-offer/cancel-offer.component';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ShakespeareService } from '../../shakespeare.service';
import { CustomModalComponent } from './custom-modal/custom-modal.component';

@Injectable({
	providedIn: 'root'
})
export class CancelModalService {
	isMobile: boolean;
	private unsubscriber$ = new Subject<void>();
	public isCancelled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public cancelReason$: BehaviorSubject<string> = new BehaviorSubject('');
	public isOfferAccepted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	constructor(
		private dialog: MatDialog,
		private backOfficeApiService: BackOfficeService,
		private toastNotificationService: ToastNotificationService,
		private shakespeareService: ShakespeareService
	) {
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			this.isMobile = true;
		}
	}

	public openCancelModal(): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '647px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '655px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CancelStep1Component, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				existing: true
			}
		});
		dialogRef.afterClosed().subscribe(obj => {
			if (obj && obj.cancel) {
				this.whyCancellingModal('why-options');
			}
			if (obj.type == 'pause') {
				this.pausePlan();
			}
		});
	}

	public openCancelModaldummy(data?): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '647px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '250px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CustomModalComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				existing: true,
				content: data
			}
		});
	}

	private whyCancellingModal(type: string): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '503px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '503px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(WhyCancellingComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				type: type
			}
		});
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.type == 'feedback') {
				this.feedbackModal();
			}
			if (obj.type == 'offer-accepted') {
				this.acceptOffer();
			}
			if (obj.type == 'contact-us') {
				this.contactUsModal();
			}
			if (obj.type == 'cancel-confirm') {
				this.cancelLastStepModal();
			}
		});
	}

	private contactUsModal(): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '356px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '339px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(ContactUsComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				type: ''
			}
		});
		dialogRef.afterClosed().subscribe(obj => {
			if (obj && obj.cancel) {
				this.cancelLastStepModal();
			}
			if (obj.type == 'why-options') {
				this.whyCancellingModal(obj.type);
			}
		});
	}

	private feedbackModal(): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '406px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '385px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(FeedbackComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				type: ''
			}
		});
		dialogRef.afterClosed().subscribe(obj => {
			if (obj.type == 'why-options') {
				this.whyCancellingModal(obj.type);
			}
			if (obj.type == 'cancel-confirm') {
				this.cancelLastStepModal();
			}
		});
	}

	private cancelLastStepModal(): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '542px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '514px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CancelOfferComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				type: ''
			}
		});
		dialogRef.afterClosed().subscribe(cancelConfirm => {
			if (cancelConfirm) {
				console.log(cancelConfirm);
				this.cancelUserAccount();
			}
		});
	}

	private openCancelledModal(): void {
		let modalConfig;
		if (this.isMobile) {
			modalConfig = {
				width: '93%',
				height: '436px',
				maxWidth: '100%',
				hasBackdrop: true
			};
		} else {
			modalConfig = {
				width: '503px',
				height: '450px',
				hasBackdrop: true
			};
		}
		const dialogRef = this.dialog.open(CancelledComponent, {
			...modalConfig,
			panelClass: 'cancel-modal-wrapper',
			disableClose: true,
			data: {
				type: ''
			}
		});
		// dialogRef.afterClosed().subscribe();
	}

	public cancelUserAccount(): void {
		let reason = this.cancelReason$.value;
		this.backOfficeApiService
			.cancelUserAccount({ reason })
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isCancelled$.next(true);
						this.shakespeareService.accountCancelled$.next(true);
						this.openCancelledModal();
						// this.toastNotificationService.sendSuccessToast('Your subscription has been Cancelled');
					}
				},
				err => {
					if (err.error[0].code == 'Lolly__Api__User__IsNotAllowed') {
						this.openCancelModaldummy('You do not have permission to cancel the subscription. Please reach out to your Account Admin.');
					}

					if (err.error[0].code == 'Lolly__Api__User_Subscription__AlreadyCanceled') {
						this.openCancelModaldummy('Your subscription is already canceled.');
					}
				}
			);
	}

	public pausePlan(): void {
		this.backOfficeApiService
			.pauseUserPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isCancelled$.next(true);
						this.shakespeareService.accountPaused$.next(true);
						this.toastNotificationService.sendSuccessToast('Your Plan Paused');
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast('Something went wrong, Please try again after sometime');
				}
			);
	}

	public acceptOffer(): void {
		this.backOfficeApiService
			.applyDiscount()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				console.log(response);
				if (response) {
					this.toastNotificationService.sendSuccessToast('Discount offer accepted!');
					this.isOfferAccepted$.next(true);
				} else {
					this.toastNotificationService.sendInfoToast('You already accepted the offer!');
				}
			});
	}
}
