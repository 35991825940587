import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-seo-form',
	templateUrl: './seo-form.component.html',
	styleUrls: ['./seo-form.component.scss']
})
export class SeoFormComponent implements OnInit {
	@Input() public generatorForm: FormGroup = new FormGroup({});
	@Output() public changeToneSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public changeLangSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public advanceOptionChanged: EventEmitter<any> = new EventEmitter();
	@Input() public resettingForm: Subject<boolean> = new Subject<boolean>();
	@Input() public validate: Subject<boolean> = new Subject<boolean>();
	@Output() public validateChange: EventEmitter<boolean> = new EventEmitter();
	@ViewChild('scroll') private myScrollContainer: ElementRef;
	@ViewChild('output') public outputWrapper: ElementRef;
	@Input() public selectedTemplate: TemplatesModel;
	public outputData: any[] = [];
	templates: TemplatesModel[] = [...TEMPLATES];
	public error = false;
	public errorForType = false;
	public errorText = false;
	public ToneError = false;
	public numberError = false;
	private unsubscriber$ = new Subject<void>();
	public favouriteData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	public payload: object;
	public advanceOptions = false;
	public isDataFetched = false;
	public isSaveAllowed = false;

	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max3Output = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max1Output = [{ name: '1 Output', value: 1 }];

	public outputLang = [...LANGUAGES];
	public translate = false;
	public userFName: string;
	public min = 1;
	public max = 70;
	public largeStep = 1;
	public smallStep = 14;
	public sliderValue = [15, 29];
	public formButtonText = 'GENERATE';
	public selectedOutputLang = 'en';
	public selectedOutputTone: string;
	public oneTimeScroll = true;
	public maxOutputs = 3;
	public filedId: number;
	iPhoneMobile: boolean;
	isHistoryFound: any;
	isMargin: boolean;
	innerWidth: number;
	public isOutput = true;
	public isHistory = false;
	menuExpanded: any;

	constructor(private authenticationService: AuthenticationService, public activatedRoute: ActivatedRoute) {
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.getActivatedRoute();
		this.validate.subscribe(res => {
			if (res) {
				this.updateValidators();
			}
		});
		this.resettingForm.subscribe(res => {
			if (res) {
				this.resetForm();
			}
		});
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['templateId']) {
				this.selectedTemplate = this.templates.find(template => {
					return template.id == params['templateId'];
				});
				if (this.selectedTemplate.template == 'headline') {
					this.maxOutputs = 5;
				}
			}
		});
	}

	public scrollBottom(): void {
		if (this.oneTimeScroll) {
			try {
				this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
			} catch {}
		}
	}

	public checkToggleView(checked: boolean): void {
		this.advanceOptionChanged.emit(checked);
		this.advanceOptions = checked;
		if (checked) {
			this.maxOutputs = 1;
			this.generatorForm.patchValue({
				numOutputs: { name: '1 Output', value: 1 }
			});
			this.maxOutputArray = this.max1Output;
			setTimeout(() => {
				this.scrollBottom();
			}, 700);
		} else {
			this.maxOutputs = 3;
			this.maxOutputArray = this.max3Output;
			this.generatorForm.patchValue({
				numOutputs: { name: '3 Outputs', value: 3 }
			});
		}
	}

	public changeToneSource(event: any): void {
		this.changeToneSourceEvent.emit(event);
	}

	public changeLangSource(event): void {
		this.changeLangSourceEvent.emit(event);
	}

	public updateValidators() {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: [Validators.required, Validators.maxLength(80)]
			})
		);

		this.generatorForm.setControl(
			'description',
			new FormControl(this.generatorForm.controls.description.value, {
				validators: [Validators.required, Validators.maxLength(400)]
			})
		);
		this.generatorForm.markAllAsTouched();
		this.generatorForm.updateValueAndValidity();
		this.validateChange.emit(this.generatorForm.valid);
	}
	public removeValidators(): void {
		this.generatorForm.setControl(
			'name',
			new FormControl(this.generatorForm.controls.name.value, {
				validators: []
			})
		);

		this.generatorForm.setControl(
			'description',
			new FormControl(this.generatorForm.controls.description.value, {
				validators: []
			})
		);
	}
	public resetForm(): void {
		this.removeValidators();
		this.advanceOptions = false;
		this.generatorForm.reset();
	}
}
