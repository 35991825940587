import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LANGUAGES } from 'src/app/shared/models/languages.const';
import { TEMPLATES } from 'src/app/shared/models/templates.const';
import { TemplatesModel } from 'src/app/shared/models/templates.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-shopify-generator-form',
	templateUrl: './shopify-generator-form.component.html',
	styleUrls: ['./shopify-generator-form.component.scss']
})
export class ShopifyGeneratorFormComponent implements OnInit {
	@Input() public generatorForm: FormGroup = new FormGroup({});
	@Input() resetValues$ = new BehaviorSubject<boolean>(false);
	@Output() public changeToneSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public changeLangSourceEvent: EventEmitter<any> = new EventEmitter();
	@Output() public onCheckToggleViewEvent: EventEmitter<any> = new EventEmitter();
	@ViewChild('scroll') private myScrollContainer: ElementRef;
	@ViewChild('output') public outputWrapper: ElementRef;
	public outputData: any[] = [];
	templates: TemplatesModel[] = [...TEMPLATES];
	selectedTemplate: TemplatesModel;
	public error = false;
	public errorForType = false;
	public errorText = false;
	public ToneError = false;
	public numberError = false;
	private unsubscriber$ = new Subject<void>();
	public favouriteData = [];
	public isFormSubmitted = false;
	public isLoadingData = false;
	public payload: object;
	public advanceOptions = false;
	public isDataFetched = false;
	public isSaveAllowed = false;

	public outputTone = [
		{ name: 'Excited', id: 1 },
		{ name: 'Friendly', id: 2 },
		{ name: 'Happy', id: 3 },
		{ name: 'Sad', id: 4 },
		{ name: 'Witty', id: 5 },
		{ name: 'Relaxed', id: 6 },
		{ name: 'Professional', id: 7 },
		{ name: 'Bold', id: 8 },
		{ name: 'Persuasive', id: 9 },
		{ name: 'Serious', id: 10 }
	];

	public maxOutputArray = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max5Output = [
		{ name: '5 Outputs', value: 5 },
		{ name: '4 Outputs', value: 4 },
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max3Output = [
		{ name: '3 Outputs', value: 3 },
		{ name: '2 Outputs', value: 2 },
		{ name: '1 Output', value: 1 }
	];

	max1Output = [{ name: '1 Output', value: 1 }];

	public outputLang = [...LANGUAGES];
	public numberOfOutputs = [
		{ name: 1, id: 1 },
		{ name: 3, id: 3 },
		{ name: 5, id: 5 }
	];
	public translate = false;
	public userFName: string;
	public min = 1;
	public max = 70;
	public largeStep = 1;
	public smallStep = 14;
	public sliderValue = [15, 29];
	public formButtonText = 'GENERATE';
	public selectedOutputLang = 'en';
	public selectedOutputTone: string;
	public oneTimeScroll = true;
	public maxOutputs = 3;
	public filedId: number;
	public emailType: string;
	public uspHelper = [{ placeholder: 'Light Weight' }];
	public specHelper = [{ placeholder: 'Size- UK8, UK10, UK11' }];
	public emailObj = {
		'Email Subject': 'subject',
		'Email Description': 'email',
		'Email Subject & Description': 'subject_email'
	};
	public uspPlaceHolders = ['Light Weight', 'Waterproof', 'Trendy Design', 'Breathable Fabric', 'Air Cushion'];
	public specPlaceHolders = ['Size- UK8, UK10, UK11', 'Color- Red', 'Weight- 500gm', 'Closure: Lace-Up', 'Material: Textile Rubber'];
	iPhoneMobile: boolean;
	isHistoryFound: any;
	isMargin: boolean;
	innerWidth: number;
	public isOutput = true;
	public isHistory = false;
	menuExpanded: any;

	constructor(private authenticationService: AuthenticationService, public activatedRoute: ActivatedRoute) {
		this.authenticationService.expandMenu$.subscribe(res => {
			this.menuExpanded = res;
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		if (navigator.userAgent.match(/iPhone/i)) {
			this.iPhoneMobile = true;
		}
		this.resetValues$.subscribe(val => {
			if (val) this.advanceOptions = false;
		});
		this.getActivatedRoute();
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['templateId']) {
				this.selectedTemplate = this.templates.find(template => {
					return template.id == params['templateId'];
				});
				if (this.selectedTemplate.template == 'headline') {
					this.maxOutputs = 5;
				}
			}
		});
	}

	public removeError(index: number, event, errorClass: string): void {
		if (event.target.value.length > 0) {
			document.getElementById(errorClass + index).style.display = 'none';
		}
	}

	public addMoreHandler(isLast: boolean, index: number, type: string, errorClass: string): void {
		if (isLast) {
			let element = <HTMLInputElement>document.getElementById(type + index);
			if (this.uspPlaceHolders[index + 1] == undefined) {
				return;
			}
			element.value
				? type == 'usp'
					? this.uspHelper.push({ placeholder: this.uspPlaceHolders[index + 1] })
					: this.specHelper.push({ placeholder: this.specPlaceHolders[index + 1] })
				: (document.getElementById(errorClass + index).style.display = 'block');

			setTimeout(() => {
				let nextElement = <HTMLInputElement>document.getElementById(type + (index + 1));
				nextElement.focus();
			}, 200); // few mili-seconds to wait for dom to build element
		} else {
			type == 'usp' ? this.uspHelper.pop() : this.specHelper.pop();
		}
	}

	public scrollBottom(): void {
		if (this.oneTimeScroll) {
			try {
				this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
			} catch {}
		}
	}

	public checkToggleView(checked: boolean): void {
		this.advanceOptions = checked;
		if (checked) {
			this.maxOutputs = 1;
			this.generatorForm.patchValue({
				numOutputs: { name: '1 Output', value: 1 }
			});
			this.maxOutputArray = this.max1Output;
			setTimeout(() => {
				this.scrollBottom();
			}, 700);
		} else {
			this.maxOutputs = 3;
			this.maxOutputArray = this.max3Output;
			this.generatorForm.patchValue({
				numOutputs: { name: '3 Outputs', value: 3 }
			});
		}
	}

	public changeToneSource(event: any): void {
		this.changeToneSourceEvent.emit(event);
	}

	public changeLangSource(event): void {
		this.changeLangSourceEvent.emit(event);
	}
}
