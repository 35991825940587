import { Component, OnInit } from '@angular/core';
import { OverviewApiService } from '../../services/overview-api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { OverviewDataService } from '../../services/overview-data.service';

@Component({
	selector: 'app-facebook-overview-ad-accounts',
	templateUrl: './facebook-overview-ad-accounts.component.html',
	styleUrls: ['./facebook-overview-ad-accounts.component.scss']
})
export class FacebookOverviewAdAccountsComponent implements OnInit {
	localStorageValue: any = JSON.parse(localStorage.getItem('decodedJwtIo'));
	overviewFacebookDataList: any;
	facebookADaccountData: any;
	fromDate: any;
	toDate: any;
	showLoader: boolean = false;
	// range: FormGroup;
	constructor(
		private overviewApiService: OverviewApiService,
		private router: Router,
		private fb: FormBuilder,
		private overviewDataService: OverviewDataService
	) {
		// const oneMonthAgo = new Date();
		// oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
		// this.range = this.fb.group({
		// 	start: [oneMonthAgo],
		// 	end: [new Date()]
		// });
		// this.range.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
		// 	this.getFacebookOverViewCards();
		// });
	}

	ngOnInit(): void {
		if (this.overviewDataService.overviewFacebookDataList) {
			this.overviewFacebookDataList = JSON.parse(JSON.stringify(this.overviewDataService.overviewFacebookDataList));
			this.facebookADaccountData = JSON.parse(JSON.stringify(this.overviewDataService.facebookAdAccount));
		} else {
			this.getFacebookAdAccount();
		}
	}

	getFacebookOverViewCards() {
		this.showLoader = true;
		let payload = {
			user: {
				user_filed_id: this.localStorageValue.user_filed_id
			},
			data: {
				num_accounts: 'ALL',
				date_range: {
					from_date: this.fromDate,
					to_date: this.toDate
				}
			}
		};
		this.overviewApiService.getFBCardsData(payload).subscribe(
			res => {
				this.overviewFacebookDataList = res.data;
				console.log('fb', this.overviewFacebookDataList);
				this.mergeFacebookAdAccountwithoverallDataList();
			},
			error => {
				this.showLoader = false;
			}
		);
	}

	getFacebookAdAccount() {
		this.overviewApiService.getFacebookAdAccount().subscribe(res => {
			this.facebookADaccountData = res.data;
			for (let item of this.facebookADaccountData) {
				item.id = item.id.replace('act_', '');
			}
			console.log(this.facebookADaccountData);
			this.mergeFacebookAdAccountwithoverallDataList();
		});
	}

	mergeFacebookAdAccountwithoverallDataList() {
		if (this.overviewFacebookDataList && this.facebookADaccountData) {
			for (let ad of this.facebookADaccountData) {
				for (let ovl of this.overviewFacebookDataList) {
					if (ad.id == ovl.adAccountId) {
						ovl.adAccountName = ad.name;
						ovl.currency = ad.currency;
						ovl.currencySymbol = this.overviewDataService.currencySymbol[ad.currency];
					}
				}
			}
			this.showLoader = false;
			this.overviewDataService.overviewFacebookDataList = JSON.parse(JSON.stringify(this.overviewFacebookDataList));
		}
	}

	goBack() {
		this.router.navigate(['overview']);
	}

	// formatDate(date: Date): string {
	// 	// Get the date components in the "YYYY-MM-DD" format
	// 	const year = date.getFullYear();
	// 	const month = String(date.getMonth() + 1).padStart(2, '0');
	// 	const day = String(date.getDate()).padStart(2, '0');

	// 	return `${year}-${month}-${day}`;
	// }

	// hello(type: any, event: any) {
	// 	if (this.range.value.start && this.range.value.end) {
	// 		this.getFacebookOverViewCards();
	// 	}
	// }

	initialiseStartDate(startDate: any) {
		console.log('fromOverView=>StartDate', startDate);
		this.fromDate = startDate;
	}

	initialiseEndDate(endDate: any) {
		console.log('fromOverView=>endDate', endDate);
		this.toDate = endDate;
		this.dateChange();
	}

	dateChange() {
		if (this.fromDate != localStorage.getItem('fromDate') && this.toDate != localStorage.getItem('fromDate')) {
			localStorage.setItem('fromDate', this.fromDate);
			localStorage.setItem('toDate', this.toDate);
			this.getFacebookOverViewCards();
			this.overviewDataService.insideCardComponentData = [];
		}

		if (!this.overviewDataService.overviewFacebookDataList) {
			if (this.fromDate && this.toDate) {
				this.getFacebookOverViewCards();
			}
		}
	}
}
