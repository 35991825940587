<div class="buttons-row">
	<div class="wrapper">
		<div (click)="onButtonClick(button)" *ngFor="let button of buttons" [ngClass]="{ active: button.active, selected: button.selected }" class="button">
			<div *ngIf="showCount" [ngClass]="{ active: button.active, selected: button.selected }" class="count-container">{{ button.count }}</div>

			<div class="button-label">
				{{ button.label }}
			</div>
		</div>
	</div>
</div>
