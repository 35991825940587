<div class="search-wrapper {{ styleClass }}">
	<app-text-input
		styleClass="icon-right search {{ inputStyleClass }}"
		[control]="control"
		[maxLength]="maxLength"
		[inputPlaceholder]="inputPlaceholder"
		(keydown.enter)="searchClick($event)"
	></app-text-input>
	<button (click)="searchClick($event)">
		<img [src]="searchIconUrl || 'assets/shakespeare/search-icon.svg'" alt="search" />
	</button>
</div>
