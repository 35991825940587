import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { Component, Injector, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { getUserDetails } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { LogIn, LogInSuccess } from '../../state/authentication.action';
import { AuthenticationState } from '../../state/authentication.reducer';
import { PaymentConfirmPopupComponent } from '../payment-confirm-popup/payment-confirm-popup.component';
import { StorageKey } from 'src/app/_models/local-storage-key';
@Component({
	selector: 'app-payment-confirmation',
	templateUrl: './payment-confirmation.component.html',
	styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmation implements OnInit, OnDestroy {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: FormGroup;
	public expirationDate: FormControl;
	public cardNumber: FormControl;

	public submitting: boolean;

	public currentRoute: string;
	public title: string;
	public subtitle: string;
	public errorMessage: string;
	public buttonStatus: boolean = false;
	@ViewChild('ccNumber') ccNumberField!: ElementRef;

	protected sharedStore: Store<SharedState>;
	constructor(
		private injector: Injector,
		private formBuilder: FormBuilder,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private router: Router,
		private authStore: Store<AuthenticationState>,
		private backOfficeService: BackOfficeService,
		public activeRoute: ActivatedRoute,
		public matDialog: MatDialog,
		private userServiceApi: UserServiceApi
	) {
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
	}

	ngOnInit(): void {
		this.submitting = true;
		setTimeout(() => {
			this.getTarrifDetails();
		}, 100);
	}

	public logout(): void {
		localStorage.clear();
		this.router.navigate(['/authentication']);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		this.backOfficeService.signUpGetStartedActive$.next('idle');
	}
	public noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return { whitespace: true };
	}
	get getPlanAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.amount || 0);
		return cur + ' ' + amount;
	}

	get getCurrentlyChargingAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.currentlyCharging && !this.planDetails?.isFreeTrial ? this.planDetails?.currentlyCharging : 0 || 0);
		return cur + ' ' + amount;
	}

	public formatExpiryDate(value: any): void {
		value = this.expirationDate.value;
		if (!value.includes('/')) {
			value = value
				.replace(/\W/gi, '')
				.replace(/(.{2})/g, '$1/')
				.replace(/\/+$/, '');
			this.expirationDate.setValue(value);
		}

		const expires = value.split('/');
		this.cardDetails.controls['expirationMonth'].setValue(expires[0]);
		let yearValue = expires[1] || expires[0];
		if (expires[1] && expires[1].length == 3) {
			this.expirationDate.setErrors({ incorrect: true });
		}
		this.cardDetails.controls['expirationYear'].setValue(yearValue);
	}

	public creditCardNumberSpacing(value: any): void {
		value = this.cardDetails.get('cardNumber').value;
		value = value
			.replace(/\W/gi, '')
			.replace(/(.{4})/g, '$1 ')
			.replace(/\s+$/, '');
		this.cardDetails.get('cardNumber').setValue(value);
	}
	public onFormNameChange(): void {
		this.cardDetails.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {});
	}

	public getTarrifDetails(): void {
		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.userServiceApi
			.refreshUserToken()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(token => {
				this.authStore.dispatch(new LogInSuccess(token));
				setTimeout(() => {
					this.getBillingDetail();
				}, 300);
			});
	}

	public setCookie(cname, cvalue) {
		// far future expiry date
		let expires = 'expires=' + new Date(2147483647 * 1000).toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	}

	public getBillingDetail(): void {
		let token = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.setCookie('loginSrc', '');
		let email = token.filed_email;
		this.billingService
			.getPlanDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						// hard coded check using amount and credits, bcs no key to check for trialing users in billing/current api
						let isTrialing = details.amount == 0 && details.contentCredit == 10000 ? true : false;
						if (isTrialing) {
							window['dataLayer'].push({
								event: 'freetrial_user_subscribed_successfully',
								userEmail: email,
								pageUrl: window.location.href
							}); // invoking google tag manager code
						}
						this.submitting = false;
						this.matDialog
							.open(PaymentConfirmPopupComponent, {
								height: '400px',
								width: '400px',
								data: {
									price: details['amount'],
									reference: details['transactionId'],
									isTrial: isTrialing
								},
								disableClose: true
							})
							.afterClosed();
						// =================================== @deprecated ======================================
						// this.billingService
						// 	.setVersionEmail()
						// 	.pipe(takeUntil(this.unsubscriber$))
						// 	.subscribe(() => {
						// 		this.submitting = false;
						// 		this.matDialog
						// 			.open(PaymentConfirmPopupComponent, {
						// 				height: '444px',
						// 				width: '762px',
						// 				data: {
						// 					price: details['amount'],
						// 					reference: details['transactionId'],
						// 					isTrial: isTrialing
						// 				},
						// 				disableClose: true
						// 			})
						// 			.afterClosed();
						// 	});
					}
					this.planDetails = details;
				},
				() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.authStore.dispatch(new LogIn());
				},
				() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public getPlanDetail(details = {}): void {
		this.submitting = true;
		this.backOfficeService
			.userPlanDetails()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				value => {
					this.submitting = false;
				},
				() => {
					this.submitting = false;
				}
			);
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	public continueToAwesome(): void {
		this.router.navigate(['/authentication/awesome']);
	}
}
