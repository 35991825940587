import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-input-field-password',
	templateUrl: './input-field-password.component.html',
	styleUrls: ['./input-field-password.component.scss']
})
export class InputFieldPasswordComponent implements OnInit {
	@Input('inputPlaceholder') public inputPlaceholder: string = 'Enter text here';
	@Input('control') public control: FormControl = new FormControl();
	@Input() public disabled: boolean = false;
	@Input('readOnly') public readOnly: boolean = false;
	@Input('errorMessage') public errorMessage: string = '';
	@Input('maxLength') public maxLength: number = 180;
	@Input('hideError') public hideError: boolean = false;
	@Input('showValidIcon') public showValidIcon: boolean = false;
	@Input('showCustomIcon') public showCustomIcon: string = null;
	@Input() iconWidth: string = null;
	@Input() marginRight: string = null;
	@Output() public kendoInputEvent = new EventEmitter<any>();
	@Input() public styleClass: string = '';
	@Input() public inputStyleClass: string = '';
	public showPassword: boolean = false;
	public inputType = 'password';
	public toggleEye = false;
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	public ngOnInit(): void {}

	public ngAfterViewInit(): void {}
	public toggleView(event: any): void {
		event.preventDefault();
		event.stopPropagation();
		this.toggleEye = !this.toggleEye;
		this.showPassword = !this.showPassword;
		this.inputType = !this.toggleEye ? 'password' : 'text';
	}
	public showIconStyle(): Object {
		let styles = {};
		if (this.iconWidth && this.marginRight) {
			styles = {
				width: this.iconWidth,
				'margin-right': this.marginRight
			};
		}
		return styles;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
