<div class="popup-card" [class.hide]="loading">
	<div class="popup-content">
		<div class="icon-div">
			<button mat-dialog-close aria-label="Close">
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class="popup-icon">
			<!-- <img src="assets/popupIcon.svg" alt="Sorting Arrow" /> -->
			<img class="logo-img" src="assets/icons/nav-menu/submenu-icons/google-nav.svg" alt="google logo" />
		</div>
		<p class="heading">Confirm Your Choice</p>
		<p class="sub-text">Are you sure you're ready to connect Google Ads?</p>
		<div class="popup-buttons">
			<div class="ai-opt-next-button">
				<button class="ai-opt-appy-cls" (click)="openCommonPopup()">
					<!-- <img class="logo-img" src="assets/icons/nav-menu/submenu-icons/google-nav.svg" alt="google logo" />
					<span>Connect Google Ads</span> -->
					Continue
				</button>
			</div>
			<div class="ai-opt-next-button">
				<button class="ai-opt-cancel-cls" (click)="closeGooglePopup()">Cancel</button>
			</div>
			<!-- Add another button for your specific action-->
			<!-- <button class="confirm-button" (click)="confirmAction()">Confirm</button> -->
		</div>
	</div>
</div>

<div [class.hide]="!loading" class="spinner-wrapper" *ngIf="loading">
	<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
</div>
