import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NoCreditModalComponent } from '../../templates/no-credit-modal/no-credit-modal.component';
import { BigCommerceService } from '../big-commerce.service';

@Component({
	selector: 'app-view-product',
	templateUrl: './view-product.component.html',
	styleUrls: ['./view-product.component.scss']
})
export class ViewProductComponent implements OnInit, AfterViewChecked {
	@Input() public modelDetail: any;
	@ViewChild('mobile') mobile: ElementRef;
	view = 'desktop';
	product: any;
	desktopImg = 'assets/icons/shopify-desktop.png';
	mobileImg = 'assets/icons/shopify-mobile.png';
	newly: boolean;
	mobileWidth: number;
	mobileHeight: any;
	radius: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<NoCreditModalComponent>,
		private cdRef: ChangeDetectorRef,
		private bigCommerceService: BigCommerceService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.product = this.modelDetail.product;
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Tablet/i)) {
			this.view = 'mobile';
		}
	}

	ngAfterViewChecked(): void {
		let width = this.mobile.nativeElement.width;
		let height = this.mobile.nativeElement.height;
		this.calculateHeight(height);
		this.calculateWidth(width);
		this.cdRef.detectChanges();
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(): void {
		let width = this.mobile.nativeElement.width;
		let height = this.mobile.nativeElement.height;
		this.mobileHeight = height - 54;
		this.calculateWidth(width);
	}

	calculateHeight(height: number): void {
		if (height < 170) {
			this.mobileHeight = height - 36;
		} else if (height < 288) {
			this.radius = 18;
			this.mobileHeight = height - 39;
		} else if (height < 294) {
			this.mobileHeight = height - 42;
			this.radius = 18;
		} else if (height < 302) {
			this.mobileHeight = height - 45;
			this.radius = 18;
		} else if (height < 310) {
			this.mobileHeight = height - 48;
			this.radius = 18;
		} else if (height < 320) {
			this.mobileHeight = height - 50;
			this.radius = 18;
		} else if (height < 330) {
			this.mobileHeight = height - 53;
			this.radius = 18;
		} else if (height < 430) {
			this.mobileHeight = height - 55;
			this.radius = 18;
		} else if (height < 445) {
			this.mobileHeight = height - 60;
			this.radius = 18;
		} else if (height < 460) {
			this.mobileHeight = height - 68;
			this.radius = 18;
		} else if (height < 470) {
			this.mobileHeight = height - 75;
			this.radius = 18;
		} else if (height < 500) {
			this.radius = 18;
			this.mobileHeight = height - 80;
		} else {
			this.mobileHeight = height - 107;
		}
	}

	calculateWidth(width: number): void {
		if (width < 170) {
			this.mobileWidth = width - 18;
		} else if (width < 188) {
			this.mobileWidth = width - 20;
		} else if (width < 194) {
			this.mobileWidth = width - 22;
		} else if (width < 202) {
			this.mobileWidth = width - 24;
		} else if (width < 210) {
			this.mobileWidth = width - 28;
		} else if (width < 220) {
			this.mobileWidth = width - 30;
		} else if (width < 230) {
			this.mobileWidth = width - 32;
		} else if (width < 254) {
			this.mobileWidth = width - 35;
		} else {
			this.mobileWidth = width - 46;
		}
	}

	changeView(view: string): void {
		this.view = view;
	}

	editDesc(): void {
		this.dialogRef.close();
		this.bigCommerceService.editNewlyGeneratedDesc$.next(this.product['description']);
		let id = this.product?.id || this.product['productId'];
		this.router.navigate(['big-commerce/generate'], { queryParams: { productId: id, edit: true } });
	}
}
