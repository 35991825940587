<div class="main-container" style="display: none;">
	<div class="chevron-links">
		<h6>
			{{ kbService.searchValue$.value.charAt(0).toUpperCase() + kbService.searchValue$.value.slice(1).toLowerCase() }} >
			{{
				subArticlesData[0]?.subcategory
					? subArticlesData[0]?.subcategory
					: kbService.searchValue$.value.charAt(0).toUpperCase() + kbService.searchValue$.value.slice(1).toLowerCase() + ' articles'
			}}
		</h6>
	</div>
	<div class="main-cards">
		<ng-container *ngFor="let subArticle of subArticlesData; let i = index">
			<mat-card (click)="showFrame(i)">
				<div class="section">
					<div class="section-description">
						<h5><div [innerHTML]="subArticle.title"></div></h5>
						<div [innerHTML]="shortenDescription(subArticle.description)"></div>
					</div>
				</div>
			</mat-card>
		</ng-container>

		<div *ngIf="!subArticlesData || subArticlesData?.length < 1">
			<h6>There are no sub articles under this article</h6>
		</div>
	</div>
</div>
