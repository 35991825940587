<mat-card class="d-flex d-j-c-flex-row">
	<div class="box-connect">
		<div class="text-center width-140 m-b-30">
			<!-- <img alt="homepage" src="/assets/logo/lolly-logo.svg" /> -->
			<img class="logo-shakespeare" alt="homepage" src="assets/logo/shakespeare-full-logo.svg" />
		</div>
		<div fxFlex="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100">
			<div class="title m-t-30 m-b-30">{{ 'WELCOME_MESSAGE' | translate: { userName: userName } }}</div>
		</div>
		<!--Button-->
		<div class="btn-wrapper" style="display: flex; flex-direction: column;">
			<app-button
				(buttonClicked)="createNewCampaign()"
				[buttonClass]="buttonClassEnum.Tertiary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="CREATE A NEW CAMPAIGN"
			></app-button>
			<app-button
				(buttonClicked)="viewAnExistingCampaign()"
				[buttonClass]="buttonClassEnum.Primary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="VIEW AN EXISTING CAMPAIGN"
			></app-button>
		</div>
	</div>
</mat-card>
