<div class="container-fluid">
	<div class="left-dock">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="right-dock">
		<div [class.hide]="!loading" class="spinner-wrapper" *ngIf="loading">
			<shakespeare-spinning-loader [rotating]="true"></shakespeare-spinning-loader>
		</div>
		<form [class.hide]="loading" [formGroup]="signInForm">
			<div class="wrapper" [class.hide]="loading">
				<!-- hiding this div to stop re-rendering below elements which causes Google-Signup iframe to be removed -->
				<div class="logo">
					<img class="logo-shakespeare" src="assets/logo/shakespeare-full-logo.svg" />
					<!-- <div class="logo-text">Shakespeare</div> -->
				</div>
				<div class="welcome-text">{{ 'Welcome Back' }}</div>

				<div class="continue-btn-wrapper">
					<div id="google-btn"></div>
					<!-- <div class="btn">
						<div class="items">
							<img src="assets/images/rec-google-btn.png" />
						</div>
					</div> -->
					<div class="btn_google">
						<div class="cta">
							<!-- <img src="assets/images/rec-google-btn.png" /> -->
							<div class="google_img">
								<img src="assets/images/fav-gog-icon.svg" />
							</div>
							<div class="google_txt">
								<p class="m-0">Sign in with Google</p>
							</div>
						</div>
					</div>
				</div>
				<div class="rule-a-text">
					<hr />
					<div class="text">{{ 'Or Login with your email' }}</div>
				</div>

				<div class="full-text-input">
					<!-- <div class="label">{{ 'Email Address' }}</div>
					<app-email-input-field
						[showValidIcon]="true"
						[hideError]="false"
						class="title-input"
						[inputPlaceholder]="'Enter your email'"
						[errorMessage]="'Enter a valid email'"
						[control]="signInForm.get('email')"
					></app-email-input-field> -->
					<app-mat-input-text
						[label]="'Email Address'"
						[errorMessage]="'Enter a valid email'"
						[control]="signInForm.get('email')"
					></app-mat-input-text>
				</div>

				<div class="full-text-input l-margin">
					<!-- <div class="label">{{ 'Password' }}</div>
					<app-input-field-password [showValidIcon]="true" [hideError]="true" class="title-input"
						[inputPlaceholder]="'Enter your password'"
						[control]="signInForm.get('password')"></app-input-field-password> -->

					<app-mat-input-password
						[label]="'Password'"
						[control]="signInForm.get('password')"
						[errorMessage]="'Enter a valid password'"
						[class]="'short'"
						(keyup.enter)="onSubmit()"
					></app-mat-input-password>
				</div>

				<div class="margin-bottom-spacing-ps l-margin">
					<a (click)="onForgotPasswordClick()" class="link password_forgot">Forgot password?</a>
				</div>
				<div (click)="onSubmit()" class="signin-btn-wrapper">
					<div class="btn">
						<div class="items">
							<div class="text">{{ 'Login' }}</div>
						</div>
					</div>
				</div>
				<div class="bottom-text-action">
					<!-- <div class="dark">{{ "Don't have an account?" }}</div>
					<div class="theme">
						<a href="https://shakespeare.ai/pricing/">{{ 'Signup' }}</a>
					</div> -->
				</div>
				<div class="bottom-text-action change-display">
					<span class="dark">{{ "By continuing, you agree to Shakespeare's" }}</span>
					<span class="theme">
						<a href="https://www.shakespeare.ai/terms-of-service/">{{ 'Terms of Service.' }}</a>
					</span>
					<span class="dark">&nbsp; {{ 'Read our ' }}</span>
					<span class="theme">
						<a href="https://www.shakespeare.ai/privacy-policy/">{{ 'Privacy Policy.' }}</a>
					</span>
				</div>
			</div>
		</form>
	</div>
</div>

<app-toast-notification></app-toast-notification>
