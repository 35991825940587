import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TemplateInterface } from './interfaces/template.interface';
import { BaseApiUrl } from 'src/app/shared/base-api-urls';

@Injectable()
export class EditorService {
	@Output() public sendImageData: EventEmitter<any> = new EventEmitter();
	@Output() public textData: EventEmitter<any> = new EventEmitter();
	public elements = [];
	moduleName: any;
	private fontFamilies = [];
	constructor(private http: HttpClient) {
		this.moduleName = 'creative';
	}

	public removeBackground(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/remove-background`, request);
	}

	public getImageData(data: any) {
		this.sendImageData.emit(data);
	}

	public textDelete(data: any) {
		this.textData.emit(data);
	}

	public getElements(): Observable<any> {
		if (this.elements.length) {
			return new Observable(observer => {
				observer.next(this.elements);
			});
		}

		return new Observable(observer => {
			this.getElementsHelper().subscribe(elements => {
				this.elements = elements.data;
				observer.next(this.elements);
			});
		});
	}

	public getElementsHelper(): Observable<any> {
		switch (this.moduleName) {
			case 'google':
				return this.http.get<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/elements`);
				break;
			default:
				return this.http.get<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/elements`);
		}
	}

	public addAdditionalImage(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/additional-images`, request);
				break;
			default:
				return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/additional-image`, request);
		}
	}

	public editAdditionalImage(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.put<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/additional-images`, request);
				break;
			default:
				return this.http.patch<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/additional-image`, request);
		}
	}

	public sendTextDetails(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/text`, request);
				break;
			default:
				return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/text-image`, request);
		}
	}

	public deleteText(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.delete<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/text`, { body: request });
				break;
			default:
				return this.http.delete<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/text-image`, { body: request });
		}
	}

	public deleteAdditionalImage(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.delete<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/additional-images`, { body: request });
				break;
			default:
				return this.http.delete<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/additional-image`, { body: request });
		}
	}

	public addElement(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/elements`, request);
				break;
			default:
				return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/elements`, request);
		}
	}

	public deleteElement(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.delete<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/elements`, { body: request });
				break;
			default:
				return this.http.delete<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/elements`, { body: request });
		}
	}

	public generateImageFromText(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/generate-background`, request);
	}

	public createImageWithText(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/generate-ai-image`, request);
	}

	public saveToDevice(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/retrieve-image`, request);
	}

	public getImageLink(request: object): Observable<string> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/retrieve-image-link`, request);
	}

	public retrieveTemplate(userId: string, templateId: string): Observable<any> {
		switch (this.moduleName) {
			case 'google':
				return this.http.get<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/templates/retrive?user=${userId}&template_id=${templateId}`);
				break;
			default:
				return this.http.get<any>(`${BaseApiUrl.Dreams}templates/retrive?user=${userId}&template_id=${templateId}`);
		}
	}

	public updateShadow(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/shadow`, request);
	}

	public generateThumbnail(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/thumbnail-generate`, request);
				break;
			default:
				return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/generate-thumbnail`, request);
		}
	}

	public publish(request: object): Observable<object> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/publish/image`, request);
	}

	public getFontFamiliesHelper(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/fonts`);
	}

	public getFontFamilies(): Observable<any> {
		if (this.fontFamilies.length) {
			return new Observable(observer => {
				observer.next(this.fontFamilies);
			});
		}

		return new Observable(observer => {
			this.getFontFamiliesHelper().subscribe(elements => {
				this.fontFamilies = elements.fonts;
				observer.next(this.fontFamilies);
			});
		});
	}

	public saveThumbnail(request: object): Observable<object> {
		switch (this.moduleName) {
			case 'google':
				return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/thumbnail-save`, request);
				break;
			default:
				return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/thumbnail-save`, request);
		}
	}

	public uploadBGImage(request: object): Observable<any> {
		// return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/upload-image`, request);
		return this.http.post<any>(`https://py-filed-messenger-api.dev3.filed.com/shke/api/v1/dexter-dreams/single-image/upload-image`, request);
	}
	public fetchStockImage(key: string, output = 10): Observable<any> {
		const payload = {
			key,
			output
		};
		return this.http.get<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/retrive-stock-image`, { params: payload });
	}

	public uploadImage(request: object): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}single-image/upload-image`, request);
	}

	public uploadDynamicImage(request: object): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}creative-dynamics/google/upload-image`, request);
	}

	public customBackground(request: object): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/background`, request);
	}

	public saveBranding(params: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/user/branding`, params);
	}
	public getBranding(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative-ai/user/branding`);
	}

	public removeadditonimage(image): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.Dreams}ad-intelligence/creative/remove-background?file_link=${image}`);
	}
}
